import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import { Base64 } from "js-base64";
import { v4 as uuidv4 } from "uuid";
import html2canvas from "html2canvas";
import Compressor from "compressorjs";
import axios from "axios";
import toast from "react-hot-toast";
import SignatureCanvas from "react-signature-canvas";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Preloader from "../../../Helpers/Preloader";
import { origin } from "../../../Config/Api-constants";
import { GetUTCTime } from "../../../Helpers/GetUTCTime";
import {
  getInspectionData,
  updateInspectionDataCall,
} from "../../../Redux/Actions/CommonActions";
import SignLogo from "../../../../Assets/Images/icon-signature.svg";
import "../../../../Assets/Styles/Containers/_digitalSignature.scss";
import { useSelector } from "react-redux";
import DeleteConfirmationDialog from "../../../Common/Alert/DeleteConfirmationDialog";
import { filterObjectsUptoTemplate } from "../../../Helpers/HelperFunctions";
import { UpdateTemplateDataSpecific } from "../../../Helpers/ApiCall";

function DigitalSignature(props) {
  const dispatch = useDispatch();
  const {
    element,
    templateId,
    templateGuid,
    templateData,
    elementType,
    pdfCategoriesType,
  } = props;
  const baseUrl = templateData?.bucket_url;
  const signatureData = templateData?.pdf_data.find(
    (obj) => obj.merge_id === element?.merge_id
  );
  const storeData = useSelector((state) => state.CommonReducer);
  const inspectionData = storeData?.inspectionData?.data;
  const inspectionDataParsed = JSON.parse(JSON.stringify(inspectionData));
  const templateIndex = inspectionData?.inspectiontemplates?.findIndex(
    (obj) => obj.template_id === templateId
  );
  const pdfTemplate = inspectionData?.inspectiontemplates[templateIndex];
  const selectedElementIndex = pdfTemplate?.pdf_data?.findIndex(
    (obj) => obj.merge_id === element?.merge_id
  );

  const [state, setState] = useState({
    tabValue:
      signatureData?.value?.typeOfSign === "touchPad"
        ? 0
        : signatureData?.value?.typeOfSign === "photo"
        ? 1
        : 2 || 0,
    signPic:
      signatureData?.value?.typeOfSign === "photo"
        ? `${baseUrl}${signatureData?.value?.onlineUrl}`
        : null,
    signDraw:
      signatureData?.value?.typeOfSign === "touchPad"
        ? `${baseUrl}${signatureData?.value?.onlineUrl}`
        : null,
    fullName: "",
    signDraw: null,
    tapToSign: true,
    acceptedPic: signatureData?.value?.onlineUrl
      ? `${baseUrl}${signatureData?.value?.onlineUrl}`
      : null,
    signatureImages: [],
    selectedImgIndex: null,
    fontStyles: [
      "Dancing Script",
      "Parisienne",
      "Meddon",
      "Herr Von Muellerhoff",
      "Square Peg",
    ],
    isLoader: false,
    isEdited: false,
    openDeleteDialog: null,
    openReplaceDialog: false,
    tempData: null,
  });

  const {
    tabValue,
    signPic,
    fullName,
    signDraw,
    tapToSign,
    acceptedPic,
    fontStyles,
    isLoader,
    isEdited,
    signatureImages,
    selectedImgIndex,
    openDeleteDialog,
    openReplaceDialog,
    tempData,
  } = state;

  var signatureRef = useRef(tempData);

  const onDrop = (acceptedFiles) => {
    if (
      acceptedFiles.length > 0 &&
      (acceptedFiles[0]?.name?.toLowerCase()?.endsWith(".png") ||
        acceptedFiles[0]?.name?.toLowerCase()?.endsWith(".jpeg") ||
        acceptedFiles[0]?.name?.toLowerCase()?.endsWith(".jpg"))
    ) {
      setState((prevState) => ({
        ...prevState,
        signPic: URL.createObjectURL(acceptedFiles[0]),
        acceptedPic: acceptedFiles[0],
        isEdited: true,
      }));
    } else {
      toast.error(
        "File format should be PNG, JPEG and JPG and maximum file size is 50 MB."
      );
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize: 50000000,
    maxFiles: 1,
  });

  const handleChange = (event, newValue) => {
    setState((prevState) => ({ ...prevState, tabValue: newValue }));
    setTimeout(() => {
      signatureRef.current.fromData(tempData.toData());
    });
  };

  const handleEnd = () => {
    if (signatureRef?.current) {
      setState((prevState) => ({
        ...prevState,
        isEdited: true,
        tempData: signatureRef?.current,
      }));
    }
  };

  const handleSignDrawAccept = async () => {
    if (signatureRef?.current && !signatureRef?.current?.isEmpty()) {
      if (isEdited) {
        const dataURL = signatureRef.current.toDataURL("image/png");
        setState((prevState) => ({ ...prevState, signDraw: dataURL }));
        const response = await fetch(dataURL);
        const arrayBuffer = await response.arrayBuffer();
        const uint8Array = await new Uint8Array(arrayBuffer);
        const blob = await new Blob([uint8Array], { type: "image/png" });
        blob.name = "Signature Draw";
        setState((prevState) => ({
          ...prevState,
          isLoader: true,
          acceptedPic: blob,
        }));
        await handleSubmit(blob, "touchPad");
      } else {
        setState((prevState) => ({ ...prevState, isLoader: false }));
        toast.error("Please make changes or cancel the updation.");
      }
    } else {
      setState((prevState) => ({ ...prevState, isLoader: false }));
      toast.error("Signature cannot be empty.");
    }
  };

  const handleSignImageAccept = async () => {
    if (signPic) {
      if (isEdited) {
        setState((prevState) => ({ ...prevState, isLoader: true }));
        await handleSubmit(acceptedPic, "photo");
      } else {
        setState((prevState) => ({ ...prevState, isLoader: false }));
        toast.error("Please make changes or cancel the updation.");
      }
    } else {
      setState((prevState) => ({ ...prevState, isLoader: false }));
      toast.error("Signature cannot be empty.");
    }
  };

  const callInitiateAPI = async () => {
    let sessionAssociateCompanyKey = await Base64.decode(
      sessionStorage.getItem("AC")
    );
    let guid = await sessionStorage.getItem("guid");
    let sessionUserId = await Base64.decode(sessionStorage.getItem("UI"));
    await dispatch(
      getInspectionData({
        guid: guid,
        associate_company: sessionAssociateCompanyKey,
        user_id: sessionUserId,
      })
    );
  };

  async function compressImage(file) {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.6,
        success(result) {
          resolve(result);
        },
        error(err) {
          reject(err);
        },
      });
    });
  }

  const handleSubmit = async (file, typeOfSign) => {
    let formData = new FormData();
    let compressedFile;
    const compressedFilee = await compressImage(file);
    compressedFile = new File([compressedFilee], `${compressedFilee.name}`, {
      type: "image/png",
    });
    const imgObj = {
      id: uuidv4(),
      url: null,
      name: compressedFile?.name,
      size: compressedFile?.size,
      type: compressedFile?.type,
      edited: false,
      newUrl: null,
      online: "online",
      filetype: "image",
      localUrl: null,
      OriginUrl: "",
      editCount: 0,
      onlineUrl: null,
      orginName: null,
      thumbnail: null,
      is_deleted: 0,
      updated_at: await GetUTCTime(),
      description: null,
      OriginlocalUrl: null,
      typeOfSign: typeOfSign,
      fullName: fullName,
    };
    let img_obj_string = JSON.stringify(imgObj);
    const params = {
      image: compressedFile,
      video: null,
      file: null,
      inspection_guid: sessionStorage.getItem("guid"),
      template_id: templateId,
      comment_id: "",
      img_object: img_obj_string,
      img_type: "sign",
      template_guid: templateGuid,
      is_pdf_template: 1,
      merge_id: element?.merge_id,
      type: element?.type,
    };
    Object.keys(params).forEach((key) => formData.append(key, params[key]));
    let baseUrl = await origin();
    let sessionAPIKey = sessionStorage.getItem("CK");
    let sessionSecretKey = sessionStorage.getItem("SK");
    try {
      const response = await axios.post(
        `${baseUrl}upload_media_for_inspection_template`,
        params,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Base64.decode(sessionSecretKey)}`,
            "api-key": `${Base64.decode(sessionAPIKey)}`,
            platform: "web",
          },
        }
      );
      if (response.data.success) {
        toast.success("Signature Uploaded successfully");
        await callInitiateAPI();
        setState((prevState) => ({
          ...prevState,
          tapToSign: true,
          isLoader: false,
          isEdited: false,
          signDraw: typeOfSign === "touchPad" ? signDraw : null,
          signPic: typeOfSign === "photo" ? signPic : null,
          fullName: typeOfSign === "keyboard" ? fullName : "",
        }));
      }
    } catch (error) {
      toast.error(error?.message);
      setState((prevState) => ({ ...prevState, isLoader: false }));
    }
  };

  const clearSignature = () => {
    let data = inspectionData;
    if (openDeleteDialog === "touchPad" || tabValue === 0) {
      signatureRef?.current?.clear();
      setState((prevState) => ({
        ...prevState,
        signDraw: null,
        isLoader: true,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        signPic: null,
        isLoader: true,
      }));
    }
    if (signatureData?.value?.onlineUrl) {
      data.inspectiontemplates[templateIndex].pdf_categories=[];
      data.inspectiontemplates[templateIndex].pdf_data[selectedElementIndex] = {
        ...data.inspectiontemplates[templateIndex].pdf_data[
          selectedElementIndex
        ],
        updated_at: new Date(),
        value: null,
      };
      let filteredPdfData = data.inspectiontemplates[templateIndex].pdf_data.filter(el=> el.merge_id === element.merge_id)
      data.inspectiontemplates[templateIndex].pdf_data = filteredPdfData;
      let filteredObject = filterObjectsUptoTemplate(inspectionData, templateIndex);
      UpdateTemplateDataSpecific(dispatch,inspectionDataParsed,filteredObject);
      setState((prevState) => ({
        ...prevState,
        acceptedPic: null,
        isLoader: false,
        tapToSign: openDeleteDialog === "replace" ? false : true,
        openDeleteDialog: null,
        openReplaceDialog: false,
        tabValue: 0,
        fullName: "",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        acceptedPic: null,
        isLoader: false,
        openDeleteDialog: null,
        openReplaceDialog: false,
        fullName: "",
      }));
    }
  };

  const generateSignatureImages = async () => {
    setState((prevState) => ({ ...prevState, isLoader: true }));
    if (fullName && fullName !== "") {
      if (fullName.trim() !== "") {
        if (isEdited) {
          const generateImage = async () => {
            const images = [];
            for (let index = 0; index < fontStyles.length; index++) {
              const container = document.createElement("div");
              const fontStyle = fontStyles[index];
              container.style.position = "absolute";
              container.style.fontWeight = 400;
              container.style.left = "-100000";
              container.style.width = "600px";
              container.style.justifyContent = "center";
              container.style.display = "flex";
              container.style.font = `64px ${fontStyle}`;
              container.innerText = fullName.trim();
              document.body.appendChild(container);
              const canvas = await html2canvas(container);
              const base64Url = canvas.toDataURL("image/png");
              document.body.removeChild(container);
              const response = await fetch(base64Url);
              const arrayBuffer = await response.arrayBuffer();
              const uint8Array = new Uint8Array(arrayBuffer);
              const blob = new Blob([uint8Array], { type: "image/png" });
              blob.name = "Signature Name";
              images.push({ image: base64Url, imgObj: blob });
            }
            return images;
          };
          const signatureImages = await generateImage();
          setState((prevState) => ({
            ...prevState,
            signatureImages: signatureImages,
            isLoader: false,
          }));
        } else {
          setState((prevState) => ({ ...prevState, isLoader: false }));
          toast.error("Please make changes or cancel the updation.");
        }
      } else {
        setState((prevState) => ({ ...prevState, isLoader: false }));
        toast.error("Full name should not be empty.");
      }
    } else {
      setState((prevState) => ({ ...prevState, isLoader: false }));
      toast.error("Full name should not be empty.");
    }
  };

  const acceptGeneratedSignature = async () => {
    if (selectedImgIndex !== null) {
      setState((prevState) => ({
        ...prevState,
        isLoader: true,
        acceptedPic: signatureImages[selectedImgIndex]?.image,
      }));
      await handleSubmit(signatureImages[selectedImgIndex]?.imgObj, "keyboard");
    } else {
      toast.error("Please select the image.");
    }
  };

  const handleSignAccept = (index) => {
    setState((prevState) => ({
      ...prevState,
      selectedImgIndex: selectedImgIndex === index ? null : index,
    }));
  };

  const renderTabValue = () => {
    setState((prevState) => ({
      ...prevState,
      fullName:
        signatureData?.value?.typeOfSign === "keyboard"
          ? signatureData?.value?.fullName
          : "",
      tabValue:
        signatureData?.value?.typeOfSign === "keyboard"
          ? 2
          : signatureData?.value?.typeOfSign === "photo"
          ? 1
          : 0,
      signPic:
        signatureData?.value?.typeOfSign === "photo"
          ? `${baseUrl}${signatureData?.value?.onlineUrl}`
          : signPic,
      signDraw:
        signatureData?.value?.typeOfSign === "touchPad"
          ? `${baseUrl}${signatureData?.value?.onlineUrl}`
          : signDraw,
      selectedImgIndex: null,
      signatureImages: [],
      openReplaceDialog: true,
    }));
  };

  const handleCloseDialog = () => {
    setState((prevState) => ({ ...prevState, openDeleteDialog: false }));
  };

  const renderConfirmationDialog = () => {
    return (
      <DeleteConfirmationDialog
        open={openDeleteDialog}
        handleClose={handleCloseDialog}
        title={"Clear Signature"}
        contentText={
          openDeleteDialog === "replace"
            ? `This has already been signed. Do you want to clear the existing signature?`
            : `Are you sure you want to clear the signature?`
        }
        cancelBtnTitle={"Cancel"}
        cancelBtnAction={handleCloseDialog}
        submitBtnTitle={openDeleteDialog === "replace" ? "Clear" : "Confirm"}
        submitBtnAction={clearSignature}
      />
    );
  };

  const renderReplacePic = () => {
    return (
      <>
        <Grid container display={"flex"}>
          <Grid item xs={9} sm={10.5} md={10.6} lg={11}>
            <Typography mt={0.7} fontSize={16}>
              {"Signature Preview"}
            </Typography>
          </Grid>
          <Grid item justifyContent={"flex-end"} mr={2}>
            <IconButton
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  openReplaceDialog: false,
                }))
              }
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container display={"flex"} justifyContent={"center"}>
          <Grid
            item
            xs={12}
            sm={9}
            md={10}
            lg={7}
            className="replace_pic"
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <img
              src={
                signatureData
                  ? `${baseUrl}${signatureData?.value?.onlineUrl}`
                  : tabValue === 1
                  ? signPic
                  : tabValue === 0 && signDraw
                  ? signDraw
                  : acceptedPic
              }
              alt="Sign"
              className="img_width_height"
            />
          </Grid>
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <Button
              className="clear_button"
              variant="contained"
              startIcon={<CloseIcon />}
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  openDeleteDialog: "replace",
                }))
              }
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Preloader showPreloader={isLoader} />
      {renderConfirmationDialog()}
      {tapToSign ? (
        <Grid
          container
          className={`${
            elementType === "inner" || elementType === "layout"
              ? "pdf_container"
              : "pdf_inner_container"
          }`}
        >
          <Grid item xs={12}>
            {pdfCategoriesType == 1 ? (
              (elementType !== "inner" && elementType !== "layout") ||
              signPic ||
              signDraw ||
              acceptedPic === null ? null : (
                <DoneIcon sx={{ marginLeft: "-28px", position: "absolute" }} />
              )
            ) : null}
            <Typography
              className={
                pdfCategoriesType == 1
                  ? (elementType !== "inner" && elementType !== "layout") ||
                    signPic ||
                    signDraw ||
                    acceptedPic === null
                    ? "inner_details_title"
                    : "inner_details_title_after_selection"
                  : "inner_details_title"
              }
            >
              {element?.label}
            </Typography>
          </Grid>
          {openReplaceDialog ? (
            renderReplacePic()
          ) : (!acceptedPic &&
              !signDraw &&
              !signPic &&
              !signatureData?.value) ||
            signatureData?.value?.length === 0 ? (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              className="tap_here_box"
              display={"flex"}
              justifyContent={"center"}
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  tapToSign: false,
                  acceptedPic: null,
                  selectedImgIndex: null,
                  tabValue: 0,
                }))
              }
            >
              <Grid container>
                <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                  <Icon>
                    <img src={SignLogo} alt="Sign Icon" />
                  </Icon>
                </Grid>
                <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                  <Typography className="tap_here_typo">
                    Tap here to sign
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Box
              xs={12}
              sm={9}
              md={9}
              lg={7}
              className="tap_here_box"
              onClick={() => renderTabValue()}
            >
              <img
                src={
                  signatureData
                    ? `${baseUrl}${signatureData?.value?.onlineUrl}`
                    : tabValue === 1
                    ? signPic
                    : tabValue === 0 && signDraw
                    ? signDraw
                    : acceptedPic
                }
                alt="Sign"
                className="img_width_height"
              />
            </Box>
          )}
        </Grid>
      ) : (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              scrollButtons="auto"
              variant="scrollable"
            >
              {["Touchpad", "Photo", "Keyboard"].map((obj, index) => (
                <Tab key={index} label={obj} className="sign_tab" />
              ))}
              <Grid
                container
                justifyContent={"flex-end"}
                display={"flex"}
                alignItems={"center"}
              >
                <Grid item justifyContent={"flex-end"} mr={2}>
                  <IconButton
                    onClick={() =>
                      setState((prevState) => ({
                        ...prevState,
                        tapToSign: true,
                        signPic: null,
                        signDraw: null,
                        acceptedPic: null,
                        fullName: "",
                        signatureImages: [],
                      }))
                    }
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Tabs>
          </Box>
          {tabValue === 0 ? (
            <Grid container>
              <Typography className="draw_typo">
                Draw your signature into the box below.
              </Typography>
              <Grid
                item
                sm={12}
                display={"flex"}
                justifyContent={"center"}
                my={2}
              >
                {signDraw ? (
                  <img src={signDraw} alt="Sign" className="img_width_height" />
                ) : (
                  <SignatureCanvas
                    id="signatureCanvas"
                    penColor="black"
                    canvasProps={{
                      className: "draw_sign_box",
                    }}
                    ref={signatureRef}
                    clearOnResize={false}
                    onEnd={handleEnd}
                  />
                )}
              </Grid>
              <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"center"}
                my={2}
                gap={2}
              >
                <Button
                  variant="outlined"
                  className={"clear_button"}
                  startIcon={<CloseIcon />}
                  onClick={() =>
                    !signatureRef?.current?.isEmpty() || signDraw
                      ? setState((prevState) => ({
                          ...prevState,
                          openDeleteDialog: "touchPad",
                        }))
                      : null
                  }
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  className="accept_button"
                  onClick={() => handleSignDrawAccept()}
                >
                  Accept
                </Button>
              </Grid>
            </Grid>
          ) : tabValue === 1 ? (
            <Grid
              container
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              style={{ border: isDragActive ? "2px dashed #4282e2" : "" }}
            >
              {!signPic ? (
                <Grid item lg={2.5} className="upload_box">
                  <Grid container display={"flex"} justifyContent={"center"}>
                    <div {...getRootProps()}>
                      <Grid
                        item
                        xs={12}
                        display={"flex"}
                        justifyContent={"center"}
                        my={2}
                      >
                        <AddCircleIcon className="add_media_icon" />
                      </Grid>
                      <input
                        {...getInputProps()}
                        type="file"
                        accept="image/*"
                      />
                      <Grid
                        item
                        xs={12}
                        display={"flex"}
                        justifyContent={"center"}
                        my={1}
                        gap={1}
                      >
                        <Grid
                          container
                          display={"flex"}
                          justifyContent={"center"}
                        >
                          <Grid item xs={12}>
                            <Typography className="add_media_typo1">
                              Click to add media
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography className="add_media_typo2">
                              or drag and drop
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              ) : (
                <Box xs={6} className="tap_here_box">
                  <img src={signPic} alt="Sign" className="img_width_height" />
                </Box>
              )}
              <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"center"}
                my={2}
                gap={2}
              >
                {signPic ? (
                  <Button
                    variant="outlined"
                    className="clear_button"
                    startIcon={<CloseIcon />}
                    onClick={() =>
                      setState((prevState) => ({
                        ...prevState,
                        openDeleteDialog: "photo",
                      }))
                    }
                  >
                    Clear
                  </Button>
                ) : null}
                <Button
                  variant="contained"
                  className="accept_button"
                  onClick={() => handleSignImageAccept()}
                >
                  Accept
                </Button>
              </Grid>
            </Grid>
          ) : tabValue === 2 ? (
            <Box p={2}>
              <Grid container>
                <Typography>Full name</Typography>
                <Grid item xs={12} mt={0.5}>
                  <TextField
                    name="fullName"
                    value={fullName}
                    fullWidth={true}
                    onChange={(event) =>
                      setState((prevState) => ({
                        ...prevState,
                        fullName: event.target.value,
                        isEdited: true,
                      }))
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  mt={2}
                  display={"flex"}
                  justifyContent={"center"}
                >
                  <Button
                    variant="outlined"
                    onClick={() => generateSignatureImages()}
                  >
                    Generate Signature
                  </Button>
                </Grid>
              </Grid>
              <div>
                <Grid
                  container
                  gap={2}
                  mt={2}
                  display={"flex"}
                  justifyContent={"center"}
                >
                  {signatureImages.map((image, index) => (
                    <Grid
                      item
                      lg={3.8}
                      sm={5.8}
                      xs={12}
                      key={index}
                      className="sign_images"
                      sx={{
                        position: "relative",
                        border: `1px solid ${
                          selectedImgIndex !== index ? "#bebebe" : "#4282E2"
                        }`,
                      }}
                      onClick={() => handleSignAccept(index)}
                    >
                      <img
                        src={image.image}
                        alt={`Signature ${index}`}
                        width={"90%"}
                        height={100}
                      />
                      {selectedImgIndex === index ? (
                        <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            backgroundColor: "#EAF5FF",
                            height: "100%",
                            width: "100%",
                            opacity: "0.3",
                            border: "1px solid #4282E2",
                          }}
                        ></Box>
                      ) : null}
                    </Grid>
                  ))}
                </Grid>
              </div>
              {signatureImages?.length > 0 ? (
                <Grid
                  container
                  display={"flex"}
                  justifyContent={"center"}
                  mt={2}
                >
                  <Button
                    variant="contained"
                    className="accept_button"
                    onClick={() => acceptGeneratedSignature()}
                  >
                    Accept
                  </Button>
                </Grid>
              ) : null}
            </Box>
          ) : null}
        </Box>
      )}
    </>
  );
}

export default DigitalSignature;
