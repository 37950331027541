import { TextField } from '@mui/material';
import React from 'react'
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ReorderIcon from "@mui/icons-material/Reorder";

function ReorderList({ id, item, index, moveCard,updateDescription,errorIndex,editDescriptionError ,editDescriptionErrorMessage,deletematerial,setInputChanged }) {
    const ref = useRef(null);
    const [{ handlerId }, drop] = useDrop({
      accept: "card",
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId()
        };
      },
      hover(item, monitor) {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }
        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }
        // Time to actually perform the action
        moveCard(dragIndex, hoverIndex);
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        setInputChanged(true)
        item.index = hoverIndex;
      }
    });
    const [{ isDragging }, drag] = useDrag({
      type: 'card',
      item: () => {
        return { id, index };
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));
  return (
    <div
      // className="col-6 "
      ref={ref}
      style={{  opacity }}
      data-handler-id={handlerId}
    >
      <>
                {item.is_deleted == 0 && (
                  <div  style={{ display: "flex",gap:5,marginRight:45}}>
                    <span> <ReorderIcon sx={{marginTop:"5px"}}/></span>
                    <TextField
                      id="modelnumber"
                      multiline={true}
                      size="small"
                      onChange={(e) => {updateDescription(item.uuid, e.target.value)}}
                      sx={{marginBottom: "25px",minWidth:"140px !important",":hover":{cursor: "pointer"}}}
                      type="text"
                      value={item?.value}
                      error={errorIndex.includes(item.uuid) ? editDescriptionError : ""}
                      helperText={errorIndex.includes(item.uuid) ? editDescriptionErrorMessage : ""}
                    />
                    <span style={{ color: "red",":hover":{cursor: "pointer"}  }}>
                      <RemoveCircleOutlineIcon
                        sx={{ marginTop: "5px",":hover":{cursor: "pointer"} }}
                        onClick={() => deletematerial(index)}
                      />
                    </span>
                  </div>
                )}
              </>
    </div>
  )
}

export default ReorderList