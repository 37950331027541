import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from "@mui/icons-material/Close";

const SendReportConfirmModal = ({openSendReportModal,setOpenSendReportModal,sendReport,rwApiData,paymentEnable,agreementEnable}) => {   
    const handleClose = () => {
        setOpenSendReportModal(false);
      };
  return (
    <Dialog open={openSendReportModal} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="send-report-modal">
    <div className='close-icon-container'> <CloseIcon className="close-icon" onClick={handleClose} /></div>
    <DialogTitle id="alert-dialog-title">
      {"Send and publish report? "}
     
    </DialogTitle>
    <DialogContent className='send-report-content'>
      <DialogContentText id="alert-dialog-description">
        {`
        This inspection ${paymentEnable && agreementEnable && !rwApiData?.is_agreement_signed && !rwApiData?.paid ? 'is unpaid and has an unsigned agreement.'
        :paymentEnable && agreementEnable && !rwApiData?.is_agreement_signed && rwApiData?.paid? 'has an unsigned agreement.'
        :paymentEnable && agreementEnable && rwApiData?.is_agreement_signed && !rwApiData?.paid? 'is unpaid.'
        :!paymentEnable && agreementEnable && !rwApiData?.is_agreement_signed ?'has an unsigned agreement.'
        :paymentEnable && !agreementEnable && !rwApiData?.paid? 'is unpaid.'
        :''}
         Sending the report will 
         ${paymentEnable && agreementEnable && !rwApiData?.is_agreement_signed && !rwApiData?.paid ? 'waive the payment and agreement.'
         :paymentEnable && agreementEnable && !rwApiData?.is_agreement_signed && rwApiData?.paid? 'waive the agreement.'
         :paymentEnable && agreementEnable && rwApiData?.is_agreement_signed && !rwApiData?.paid? 'waive the payment.'
         :!paymentEnable && agreementEnable && !rwApiData?.is_agreement_signed ?'waive the agreement.'
         :paymentEnable && !agreementEnable && !rwApiData?.paid? 'waive the payment.'
         :''}
          No further reminders will be sent to the client.
        `}</DialogContentText>
    </DialogContent>
    <DialogActions className='send-report-btn-container'>      
      <Button
        className="send-report-btn"
        variant="contained"
        color="error"
        onClick={sendReport}
        autoFocus
      >
        Send and publish
      </Button>
    </DialogActions>
  </Dialog>
  )
}

export default SendReportConfirmModal