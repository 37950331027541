import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import "../../../Assets/Styles/Containers/_htmlEditor.scss";

function HtmlEditor(props) {
  const { showAddButton, style, setContent, content, id } = props;
  const refdiv = useRef(null);
  let rte;
  const [state, setState] = useState({
    editor: null,
    open: false,
    selectedDropdown: null,
    newOption: "",
    hoverOption: null,
    textfield: false,
    editedOption: null,
    dialogOptionList: [],
  });
  const { open, selectedDropdown, newOption, editor, hoverOption, textfield, editedOption, dialogOptionList } = state;

  function transformTextWithSelect(inputText) {
    let newDropdownList = content?.dropdownList;
    const pattern = /\[([^:\]]*):selected;?([^\]]*)\]/g;
    const transformedText = inputText.replace(pattern, (match, selectedOption, options) => {
      const uuid = uuidv4();
      let optionList = options ? options.split(";") : [];
      optionList = optionList.filter((option) => option.trim() !== "");
      const formattedArray = selectedOption ? selectedOption.trim().split(";") : [];
      const optionSelected = formattedArray[formattedArray.length - 1];
      optionList = [...new Set([...formattedArray, ...optionList])];
      optionList = optionList.filter((option) => option.trim() !== "");
      newDropdownList.push({ dropdown_uuid: uuid, dropdowns_values: optionList, selected_value: optionSelected });
      const selectElement = `<span contenteditable="false" id=" [dropdown ${uuid}] ">
            <select id="${uuid}"
            style="width: 96px;
            background-color: #f1f9fe;
            height: 24px;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding: 0px 6px 0px 6px;
            background-position: right 4px center;
            background-repeat: no-repeat;
            background-size: 22px 20px;
            border-radius: 4px;
            border: 1px solid #0F75BC;
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03IDEwTDEyIDE1TDE3IDEwSDdaIiBmaWxsPSIjNzc3Nzc3Ii8+CjxtYXNrIGlkPSJtYXNrMF81MDUzXzIwMTUiIHN0eWxlPSJtYXNrLXR5cGU6bHVtaW5hbmNlIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSI3IiB5PSIxMCIgd2lkdGg9IjEwIiBoZWlnaHQ9IjUiPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTcgMTBMWDEyIDE1TDE3IDEwSDdaIiBmaWxsPSJ3aGl0ZSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazBfNTA1M18yMDE1KSI+CjxyZWN0IHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0iIzI1MjUyNSIvPgo8L2c+Cjwvc3ZnPgo=');
            " >
            ${optionList.map((option) => `<option ${option === optionSelected ? "selected" : ""}>${option}</option>`).join("")}
            </select>
            </span>
        `;
      return selectElement.trim();
    });
    setContent((prevState) => ({ ...prevState, dropdownList: newDropdownList }));
    return transformedText;
  }

  let typedValue = "";

  const handleEditorChange = () => {
    const container = document.getElementById(id);
    const iframe = container.querySelector(".rte-editable");
    let iframeDocument;
    const newText = rte.getHTMLCode();
    const transformedText = transformTextWithSelect(newText);
    if (iframe) {
      iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
      iframeDocument.addEventListener("input", function (event) {
        if (event.data && event.data.length === 1) {
          typedValue += event.data;
        } else {
          typedValue = iframeDocument.body.textContent || iframeDocument.body.innerText;
        }
      });
    }
    if (transformedText.search("<select") !== -1 && typedValue === "") {
      rte.setHTMLCode(transformedText);
    } else if (transformedText.search("<select") === -1 && typedValue !== "") {
      typedValue = "";
    } else {
      editor?.insertHTML(transformedText);
    }
    setContent((prevState) => ({ ...prevState, htmlText: transformedText }));
  };

  const handleDelete = (event, option) => {
    event.stopPropagation();
    let options = dialogOptionList || [];
    let newOptionList = options.filter((obj) => obj !== option);
    setState((prevState) => ({
      ...prevState,
      dialogOptionList: newOptionList,
      textfield: false,
    }));
  };

  const handleEdit = (event, option) => {
    event.stopPropagation();
    setState((prevState) => ({
      ...prevState,
      newOption: option,
      editedOption: option,
    }));
  };

  const handleEditSave = (event, option) => {
    event.stopPropagation();
    let options = dialogOptionList || [];
    const optionIndex = options.findIndex((obj) => obj === option);
    options[optionIndex] = newOption;
    setState((prevState) => ({
      ...prevState,
      dialogOptionList: options,
      editedOption: null,
      newOption: "",
    }));
  };

  const handleAdd = (event) => {
    event.stopPropagation();
    let newArray = dialogOptionList || [];
    if (newOption.trim() !== "" && !newArray.includes(newOption)) {
      newArray.push(newOption);
    }
    setState((prevState) => ({
      ...prevState,
      dialogOptionList: newArray,
      newOption: "",
      textfield: false,
    }));
  };

  useEffect(() => {
    const editor2cfg = {
      toolbar: "mytoolbar",
      toolbar_mytoolbar: "{bold,italic,underline}|{insertunorderedlist,insertorderedlist}|{fontsize}",
      editorResizeMode: "none",
      bottom: "none",
      footer: "none",
    };
    setTimeout(() => {
      rte = new window.RichTextEditor(refdiv?.current, editor2cfg);
      setState((prevState) => ({ ...prevState, editor: rte }));
      rte.setHTMLCode(content?.htmlText || "");
      rte.attachEvent("change", () => handleEditorChange(rte));
      const rteBottom = refdiv.current.querySelector("rte-bottom");
      if (rteBottom) {
        rteBottom.style.display = "none";
      }
    }, 0);
  }, []);

  const handleOpenDialog = (event, id) => {
    event.stopPropagation();
    const dropdown = content?.dropdownList.find((obj) => obj?.dropdown_uuid === id);
    setState((prevState) => ({
      ...prevState,
      dialogOptionList: dropdown?.dropdowns_values || [],
      open: true,
      selectedDropdown: id,
    }));
  };

  const handleCloseDialog = () => {
    setState((prevState) => ({ ...prevState, open: false, dialogOptionList: [], textfield: false, editedOption: null }));
  };

  const btnclick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let newList = content?.dropdownList;
    const uuid = uuidv4();
    editor?.insertHTML(`
        <span contenteditable="false" id=" [dropdown ${uuid}] ">
            <select
                id="${uuid}"
                style="width: 96px;
                background-color: #f1f9fe;
                height: 24px;
                white-space: pre-wrap; overflow-wrap: break-word;
                padding: 8px 6px 6px 6px;
                background-position: right 4px center;
                background-repeat: no-repeat;
                background-size: 22px 20px;
                border-radius: 4px 0px 0px 4px;
                border: 1px solid #0F75BC;
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03IDEwTDEyIDE1TDE3IDEwSDdaIiBmaWxsPSIjNzc3Nzc3Ii8+CjxtYXNrIGlkPSJtYXNrMF81MDUzXzIwMTUiIHN0eWxlPSJtYXNrLXR5cGU6bHVtaW5hbmNlIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSI3IiB5PSIxMCIgd2lkdGg9IjEwIiBoZWlnaHQ9IjUiPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTcgMTBMWDEyIDE1TDE3IDEwSDdaIiBmaWxsPSJ3aGl0ZSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazBfNTA1M18yMDE1KSI+CjxyZWN0IHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0iIzI1MjUyNSIvPgo8L2c+Cjwvc3ZnPgo=');
                " >
                <option disabled selected value="-------"></option>
            </select>
            <button
                style="
                background-color: #f1f9fe;
                width: 24px;
                height: 24px;
                padding: 4px;
                border-radius: 0px 4px 4px 0px;
                border: 1px solid #0F75BC;
                margin-left: -4px;"
                contenteditable="false"
                id="myButton${uuid}" name="${uuid}" onchange="myFunction${uuid}();"  type="button"
            >
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_5053_2021" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="2" y="1" width="13" height="13">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 11.5V14H4.5L11.8733 6.62662L9.37333 4.12662L2 11.5ZM13.8067 4.69329C14.0667 4.43329 14.0667 4.01329 13.8067 3.75329L12.2467 2.19329C11.9867 1.93329 11.5667 1.93329 11.3067 2.19329L10.0867 3.41329L12.5867 5.91329L13.8067 4.69329Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_5053_2021)">
                <rect id="myButton${uuid}" name="${uuid}" newProp="bhavya"  x="-8.66663" y="-8.66675" width="33.3333" height="33.3333" fill="#0F75BC"/>
                <mask id="mask1_5053_2021" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="-9" y="-9" width="34" height="34">
                <rect id="myButton${uuid}" name="${uuid}"  newProp="bhavya" x="-8.66663" y="-8.66675" width="33.3333" height="33.3333" fill="white"/>
                </mask>
                <g mask="url(#mask1_5053_2021)">
                </g>
                </g>
            </svg>
            </button>
        </span>
    `);
    window[`myFunction${uuid}`] = function (event) {
      event.stopPropagation();
      const nameMatch = event.target.outerHTML.match(/name="([^"]*)"/);
      const nameValue = nameMatch ? nameMatch[1] : null;
      handleOpenDialog(event, nameValue);
    };
    const button = editor.getDocument().querySelector(`#myButton${uuid}`);
    button.addEventListener("click", window[`myFunction${uuid}`]);
    newList.push({
      dropdown_uuid: uuid,
      name: `dropdown ${uuid}`,
      selected_value: "",
      dropdowns_values: [],
    });
    setContent((prevState) => ({ ...prevState, dropdownList: newList }));
  };
  const handleSave = () => {
    const index = content?.dropdownList.findIndex((obj) => obj?.dropdown_uuid === selectedDropdown);
    const container = document.getElementById(id);
    const iframe = container.querySelector(".rte-editable");
    if (iframe) {
      const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
      const selectElement = iframeDocument.querySelector(`select[id='${selectedDropdown}']`);
      if (selectElement) {
        const optionElements = dialogOptionList.map((option) => `<option>${option}</option>`);
        const optionsString = optionElements.join("");
        const newSelectHtml = `<select id="${selectedDropdown}" style="width: 96px;
        background-color: #f1f9fe;
        height: 24px;
        white-space: pre-wrap; overflow-wrap: break-word;
        padding: 8px 6px 0px 6px;
        background-position: right 4px center;
        background-repeat: no-repeat;
        background-size: 22px 20px;
        border-radius: 4px 0px 0px 4px;
        border: 1px solid #0F75BC;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03IDEwTDEyIDE1TDE3IDEwSDdaIiBmaWxsPSIjNzc3Nzc3Ii8+CjxtYXNrIGlkPSJtYXNrMF81MDUzXzIwMTUiIHN0eWxlPSJtYXNrLXR5cGU6bHVtaW5hbmNlIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSI3IiB5PSIxMCIgd2lkdGg9IjEwIiBoZWlnaHQ9IjUiPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTcgMTBMWDEyIDE1TDE3IDEwSDdaIiBmaWxsPSJ3aGl0ZSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazBfNTA1M18yMDE1KSI+CjxyZWN0IHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0iIzI1MjUyNSIvPgo8L2c+Cjwvc3ZnPgo=');
        "><option disabled selected value="-------"></option>
        ${optionsString} </select>`;
        selectElement.outerHTML = newSelectHtml;
      }
    }
    let newArray = content?.dropdownList;
    if (newArray.length === 0) {
      newArray.push({ dropdown_uuid: selectedDropdown, selected_value: "", dropdowns_values: dialogOptionList });
    } else {
      newArray[index] = {
        ...newArray[index],
        dropdowns_values: dialogOptionList,
      };
    }
    setContent((prevState) => ({ ...prevState, dropdownList: newArray }));
    setState((prevState) => ({ ...prevState, open: false, dialogOptionList: [] }));
  };
  return (
    <div className="htmlEditorBox">
      <div
        id={id}
        ref={refdiv}
        contentEditable={false}
        spellcheck={true}
        className="rteEditor"
        style={{
          ...style,
        }}
      ></div>
      {/* {showAddButton ? (
        <div className="addButtonPaper">
          <Button fullWidth className="addDropdownButton" onClick={(event) => btnclick(event)}>
            <AddCircleOutlineOutlinedIcon sx={{ fontSize: "18px" }} />
            Add dropdown
          </Button>
        </div>
      ) : null} */}
      <Dialog
        open={open}
        sx={{
          "& .MuiDialog-paper": {
            height: "300px",
            width: "350px",
            borderRadius: "7px",
          },
        }}
        onClose={() => handleCloseDialog()}
        maxWidth="md"
      >
        <DialogTitle>Dialog</DialogTitle>
        <DialogContent>
          {textfield ? (
            <Grid container display={"flex"} alignItems={"center"} mb={1} gap={1.4}>
              <Grid item xs={9}>
                <TextField
                  fullWidth={true}
                  value={newOption}
                  size="small"
                  onChange={(e) =>
                    setState((prevState) => ({
                      ...prevState,
                      newOption: e.target.value,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={1} display={"flex"} justifyContent={"center"}>
                <IconButton onClick={(event) => handleAdd(event)}>
                  <DoneOutlinedIcon sx={{ color: "green" }} />
                </IconButton>
              </Grid>
              <Grid item xs={1} display={"flex"} justifyContent={"center"}>
                <IconButton
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      textfield: false,
                      newOption: "",
                    }))
                  }
                >
                  <CloseOutlinedIcon sx={{ color: "red" }} />
                </IconButton>
              </Grid>
            </Grid>
          ) : null}
          {dialogOptionList.map((option, index) => (
            <Box
              key={index}
              onMouseEnter={() =>
                setState((prevState) => ({
                  ...prevState,
                  hoverOption: option,
                }))
              }
              onMouseLeave={() => setState((prevState) => ({ ...prevState, hoverOption: null }))}
            >
              <Grid
                container
                sx={{
                  backgroundColor: hoverOption === option ? "#f1f9fe" : "",
                  paddingY: "10px",
                  paddingX: "20px",
                }}
              >
                {editedOption === option ? (
                  <Grid container display={"flex"} alignItems={"center"} mb={1} gap={1}>
                    <Grid item xs={9}>
                      <TextField
                        fullWidth={true}
                        value={newOption}
                        size="small"
                        onChange={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setState((prevState) => ({
                            ...prevState,
                            newOption: e.target.value,
                          }));
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} display={"flex"} justifyContent={"center"}>
                      <IconButton onClick={(event) => handleEditSave(event, option)}>
                        <DoneOutlinedIcon sx={{ color: "green" }} />
                      </IconButton>
                    </Grid>
                    <Grid item xs={1} display={"flex"} justifyContent={"center"}>
                      <IconButton
                        onClick={() =>
                          setState((prevState) => ({
                            ...prevState,
                            editedOption: null,
                            newOption: "",
                          }))
                        }
                      >
                        <CloseOutlinedIcon sx={{ color: "red" }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={9} display={"flex"} alignItems={"center"}>
                      <Typography>{option}</Typography>
                    </Grid>
                    {hoverOption === option && (
                      <>
                        <Grid item xs={1.5} display={"flex"} alignItems={"center"}>
                          <IconButton
                            sx={{
                              padding: "4px",
                              borderRadius: "2px",
                              "&:hover": { borderRadius: "2px" },
                            }}
                            onClick={(event) => handleEdit(event, option)}
                          >
                            <EditIcon
                              sx={{
                                fontSize: "16px",
                                "&:hover": { color: "black" },
                              }}
                            />
                          </IconButton>
                        </Grid>
                        <Grid item xs={1.5} display={"flex"} alignItems={"center"}>
                          <IconButton
                            sx={{
                              padding: "4px",
                              borderRadius: "2px",
                              "&:hover": { borderRadius: "2px" },
                            }}
                            onClick={(event) => handleDelete(event, option)}
                          >
                            <DeleteIcon
                              sx={{
                                fontSize: "16px",
                                "&:hover": { color: "red" },
                              }}
                            />
                          </IconButton>
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </Grid>
            </Box>
          ))}
          <Button
            fullWidth
            id="add_new_client"
            sx={{
              marginTop: "10px",
              padding: "0px",
              display: "flex",
              justifyContent: "flex-start",
              gap: "8px",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "24px",
              textAlign: "left",
              textTransform: "none",
              color: "#4282E2",
              "&:hover": { backgroundColor: "transparent" },
            }}
            onClick={() => setState((prevState) => ({ ...prevState, textfield: true }))}
          >
            <AddCircleOutlineOutlinedIcon sx={{ fontSize: "18px" }} />
            Add option
          </Button>
        </DialogContent>
        <DialogActions>
          <Grid display={"flex"} justifyContent={"center"} gap={2}>
            <Button variant="contained" onClick={() => handleSave()}>
              Save
            </Button>
            <Button onClick={() => handleCloseDialog()}>Cancel</Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default HtmlEditor;
