import {useState,useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {  Grid, Typography } from '@mui/material';
import { useDispatch,useSelector } from "react-redux";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import FlagIcon from '@mui/icons-material/Flag';
import { v4 as uuidv4 } from 'uuid';
import '../../../../Assets/Styles/Containers/_button.scss';
import '../../../../Assets/Styles/Containers/_inspection.scss';
import '../../../../Assets/Styles/Containers/_lines.scss';
import { GetUTCTime } from '../../../Helpers/GetUTCTime';
import { UpdateTemplateData, UpdateTemplateDataSpecific } from '../../../Helpers/ApiCall';
import { filterObjectsUptoLine } from '../../../Helpers/HelperFunctions';

const AddRatingModal = ({showRatingModal,setShowRatingModal,ratings,templateId,categoryId,lineUuid,commentUuid}) => {
    const [selectedRatings,setSelectedRatings] = useState([]);
    const [selectedRatingNames,setSelectedRatingNames]= useState([])
    const [inspectionResponse,setInspectionResponse] = useState(); 
    const [ratingPresent,setRatingPresent] = useState(false);
    const templateData = useSelector((state) => state.CommonReducer);
    // const ratingData = templateData?.inspectionData?.data?.inspectiontemplates[0]?.rating_data;
    const [refresh,setRefresh] = useState(false)
    let templateIndex =  inspectionResponse?.inspectiontemplates?.findIndex(itm => itm.template_id === templateId);
    const ratingData = templateData?.inspectionData?.data?.inspectiontemplates[templateIndex]?.rating_data;
    
    // redux
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.CommonReducer);
    const handleClose=()=>{
        setShowRatingModal(false)
    }
    const handleRatingSelection=(rating)=>{      
      setSelectedRatings(prev=>{
            let index = prev.findIndex(el=>el.Name ===rating.Name);
            if(index === -1) prev.push(rating)           
            else prev.splice(index,1)           
            return prev
        })
        setSelectedRatingNames(selectedRatings.map(rating=>rating.Name))
        setRefresh(prev=>!prev)
        if(selectedRatings?.length === 1 && !ratingPresent) saveRatings()
    }
    const clearRatingHandle=()=>{
      setSelectedRatings([]);
      setSelectedRatingNames([])
      setRefresh(prev=>!prev)
    }
    const saveRatings=async()=>{
    let templateIndex =  inspectionResponse?.inspectiontemplates?.findIndex(itm => itm.template_id === templateId);
    let categoryIndex = inspectionResponse?.inspectiontemplates[templateIndex]?.categories?.findIndex(itm => itm.id === categoryId);
    let lineIndex = inspectionResponse?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines?.findIndex(line => line.uuid === lineUuid) 
    let linIpIdx = inspectionResponse?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines[lineIndex]?.line_input_controls?.findIndex(lineInpt=>lineInpt.type === "Location")
    let prevData = inspectionResponse;
    let commentIndex = inspectionResponse?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines[lineIndex]?.comments?.findIndex(
        (itm) => itm.uuid == commentUuid
      );
      let ratingIndex = inspectionResponse?.inspectiontemplates[templateIndex].categories[
        categoryIndex
      ].lines[lineIndex].comments[
        commentIndex
      ].comment_input_controls.findIndex((itm) => itm.type == "Rating");
      if (ratingIndex < 0) {
        let ratingObject = {
          comment_input_control_id: null,
          uuid: uuidv4(),
          prompt: "Rating",
          values: [],
          type: "Rating",
          data: selectedRatings,
          updated_at: await GetUTCTime(),
          created_at: await GetUTCTime(),
        };
        prevData.inspectiontemplates[templateIndex].categories[
          categoryIndex
        ].lines[lineIndex].comments[
          commentIndex
        ].comment_input_controls.push(ratingObject);
      } else {
        prevData.inspectiontemplates[templateIndex].categories[
          categoryIndex
        ].lines[lineIndex].comments[commentIndex].comment_input_controls[
          ratingIndex
        ].data = selectedRatings;
        prevData.inspectiontemplates[templateIndex].categories[
          categoryIndex
        ].lines[lineIndex].comments[commentIndex].comment_input_controls[
          ratingIndex
        ].updated_at = await GetUTCTime();
      }
      let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
      UpdateTemplateDataSpecific(dispatch,prevData,filteredObject);
      setShowRatingModal(false);
    }
    useEffect(()=>{
      setSelectedRatings(prev=>prev);
    },[refresh])

    useEffect(() => {
      if(storeData.inspectionData){
           setInspectionResponse(storeData.inspectionData?.data)
      }
  }, [storeData?.inspectionData])

    useEffect(()=>{
      let currentRatings = ratings?.map(rating=>rating)
      if(currentRatings?.length !== 0) setRatingPresent(true)
      else setRatingPresent(false);
      setSelectedRatings(currentRatings);
      setSelectedRatingNames(currentRatings.map(rating=>rating.Name))
    },[showRatingModal])
  return (
    <Dialog className='dialog-container-new-category'
    open={showRatingModal}
    onClose={handleClose}
    aria-labelledby="category-modal"
    aria-describedby="category-modal-description"
  >
   <div className="close-wrapper-new-line">
      <CloseIcon onClick={handleClose} />
    </div>
    <DialogTitle className='editor-dialog-title'>{"Add Rating"}</DialogTitle>  
    <span className='category-dialog-subtitle'>Flagged ratings will go to summary.  </span>
    <DialogContent className="dialog-content-new-category">
      <Grid container className="input-controll">
      <Grid item xs={12} >
                
                {/* <Typography className='in_inspection'>In inspection</Typography> */}
                {
                  ratingData?.map((rating,index)=>(
                    <Typography onClick={()=>handleRatingSelection(rating)} key={index} 
                    className={`bordered__text ${selectedRatingNames?.includes(rating.Name) && 'cat_selected'}`}
                    ><span className="rating_square_container"><span className="rating_square" style={{background:`${rating.Color}`}}>{rating.include_in_summary == "1" && <FlagIcon/>}</span>
                      <span className='rating_bordered__text__content'>{rating.Name}</span></span>
                      <DoneIcon className='cat_seleted_icon'/>
                    </Typography>
                   ))
                }
              </Grid>      
       {ratingPresent && <p className="clear_rating" onClick={()=>{clearRatingHandle()}}><span>Clear rating</span></p>}
      </Grid>
    </DialogContent>
    {ratingPresent ?<DialogActions className='new-category-modal-action'>          
      <Button className="add-category-btn" 
      onClick={()=>saveRatings()}
       >
        Done
      </Button>
    </DialogActions>:""}
  </Dialog>
  )
}

export default AddRatingModal