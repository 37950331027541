import { Button } from "@mui/material"
import '../../../Assets/Styles/Containers/_button.scss'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

const AddItemButton = ({text,width,id}) => {
  return (
    <Button className="outlined_btn" variant="outlined" startIcon={<AddOutlinedIcon />} style={{width}} id={id}>
    {text}
  </Button>
  )
}

export default AddItemButton