// import
import SearchIcon from "@mui/icons-material/Search";
import { Grid, Paper, InputBase, IconButton } from "@mui/material";
import React from "react";
import CustomDropdown from "./CustomDropdown";
import parse from "html-react-parser";
import ReactDOMServer from "react-dom/server";

export const EnhancedTableToolbar = (props) => {
  return (
    <Grid sx={{ flexGrow: 1 }} container spacing={2}>
      <Grid item xs={12} sx={{ width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" } }} className="inspection-toolbar-main">
        <Paper
          elevation={0}
          className={"inspection-search-field"}
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: { xs: "241px", sm: "29.64%", md: "29.64%", lg: "29.64%" },
          }}
        >
          <IconButton sx={{ p: "10px" }} aria-label="search">
            <SearchIcon className="inspection-search-icon" />
          </IconButton>
          <InputBase sx={{ ml: 1, flex: 1, fontSize: "16px" }} placeholder="Search template library" inputProps={{ "aria-label": "search" }} onChange={props.handleSearchFilter} />
        </Paper>
      </Grid>
      <Grid item xs={12}></Grid>
    </Grid>
  );
};
export function descendingComparator(a, b, orderBy) {
  function stripHtmlTags(str) {
    return str.replace(/<[^>]*>?/gm, "");
  }
  if (orderBy === "rating") {
    if (a["summaries"][0]?.summary?.name === null || a["summaries"][0]?.summary?.name === undefined) return 1;
    if (b["summaries"][0]?.summary?.name === null || b["summaries"][0]?.summary?.name === undefined) return -1;
    if (b["summaries"][0]?.summary?.name?.toLowerCase() < a["summaries"][0]?.summary.name?.toLowerCase()) {
      return -1;
    }
    if (b["summaries"][0]?.summary?.name?.toLowerCase() > a["summaries"][0]?.summary?.name?.toLowerCase()) {
      return 1;
    }
    return 0;
  } else {
    if (a[orderBy] === null) return 1;
    if (b[orderBy] === null) return -1;
    const aValue = stripHtmlTags(String(a[orderBy])).toLowerCase();
    const bValue = stripHtmlTags(String(b[orderBy])).toLowerCase();
    const aIsNumber = !isNaN(parseFloat(aValue)) && isFinite(aValue);
    const bIsNumber = !isNaN(parseFloat(bValue)) && isFinite(bValue);

    if ((aIsNumber && bIsNumber) || (!aIsNumber && !bIsNumber)) {
      if (bValue < aValue) {
        return -1;
      }
      if (bValue > aValue) {
        return 1;
      }
      return 0;
    }

    if (aIsNumber && !bIsNumber) {
      return -1;
    }
    if (!aIsNumber && bIsNumber) {
      return 1;
    }
  }
}
export function stableSort(commentListArray, comparator) {
  const stabilizedThis = commentListArray?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

export const replacePlaceholdersWithDropdowns = (htmlString, dropdowns, type, commentUUid) => {
  const regexSpecialSymbol = /Ã|Â|ÃÂ/g;
  function decodeEntities(encodedString) {
    if (encodedString) {
      const textarea = document.createElement("textarea");
      textarea.innerHTML = encodedString;
      return textarea?.value || "";
    } else return "";
  }
  if (Array?.isArray(dropdowns) && dropdowns?.length > 0) {
    dropdowns.forEach((dropdown) => {
      const placeholder = `\\[dropdown ${dropdown.dropdown_uuid}\\]`;
      const regex = new RegExp(placeholder, "g");
      const selectElement = document.createElement("select");
      const option = document.createElement("option");
      option.text = "-------------";
      option.value = "";
      selectElement.appendChild(option);
      dropdown.dropdowns_values.forEach((value) => {
        const option = document.createElement("option");
        if (value?.length > 40) option.text = `${value.substring(0, 40)}...`;
        else option.text = value;
        option.value = value;
        option.title = value;
        selectElement.appendChild(option);
        if (dropdown.selected_value === value) {
          option.setAttribute("selected", "selected");
        }
        selectElement.classList.add(`${type}-cl-detail-dropdown`);
        selectElement.setAttribute("id", `${dropdown.dropdown_uuid}`);
        selectElement.setAttribute("dropType", type);
        selectElement.setAttribute("comment_uuid", commentUUid);
      });
      htmlString = htmlString?.replace(regex, selectElement.outerHTML);
    });
  } else {
    const regexPlainDropdown = /\[dropdown [a-f0-9\-]+\]/g;
    htmlString = htmlString?.replace(regexPlainDropdown, (match, selectId) => {
      return `<select id="${selectId}" class="${type}-cl-dropdown"><option>--------------</option></select>`;
    });
  }
  return parse(decodeEntities(htmlString?.replace(regexSpecialSymbol, "")));
};

export function preprocessHTMLContent(htmlContent) {
  const textContent = htmlContent?.replace(/<[^>]+>/g, "");
  const normalizedText = textContent?.replace(/&nbsp;/g, " ");
  return normalizedText?.trim();
}

export function filterSelectTag(htmlText) {
  if (!htmlText) return "";
  var tempContainer = document.createElement("div");
  var container = document.createElement("p");
  container.innerHTML = htmlText;
  while (tempContainer.firstChild) {
    container.appendChild(tempContainer.firstChild);
  }
  var spans = container.getElementsByTagName("span");
  for (var i = spans.length - 1; i >= 0; i--) {
    var span = spans[i];
    if (span.id) {
      var spanId = span.id;
      var newContent = spanId;
      span.outerHTML = newContent;
    }
  }
  container.innerHTML = container.innerHTML.replace(/\s+/g, " ").trim();
  return container.innerHTML;
}

export function filterSelectTagIntro(htmlText) {
  if (!htmlText) return "";
  var container = document.createElement("div");
  container.innerHTML = htmlText || "";
  var selects = container.getElementsByTagName("select");
  for (var i = selects.length - 1; i >= 0; i--) {
    var select = selects[i];
    if (select.id) {
      var selectId = select.id;
      var placeholder = `[dropdown ${selectId}]`;
      select.outerHTML = placeholder;
    }
  }
  container.innerHTML = container.innerHTML.replace(/\s+/g, " ").trim();
  return container.innerHTML || "";
}

export const decodeEntities = (encodedString) => {
  const textarea = document.createElement("textarea");
  textarea.innerHTML = encodedString;
  return textarea.value;
};
