import "../../../../Assets/Styles/Containers/_commentLibraryModal.scss";

const CommentTags = ({ tags }) => {
  /**
   * Returns text color based on the specified background color.
   *
   * @param {string} bgColor - The background color as a hex string
   * @returns {string} The text color as a hex string
   */
  const getTextColor = (bgColor) => {
    const luminance = getLuminance(bgColor);
    return luminance > 0.179 ? "#000000" : "#ffffff";
  };

  /**
   * Calculates the relative luminance of a color.
   *
   * @param {string} hexColor - The color as a hex string
   * @returns {number} The relative luminance in the range [0, 1]
   */
  function getLuminance(hexColor) {
    const r = parseInt(hexColor.substr(1, 2), 16);
    const g = parseInt(hexColor.substr(3, 2), 16);
    const b = parseInt(hexColor.substr(5, 2), 16);

    const Y = (r * 299 + g * 587 + b * 114) / 1000;
    return Y <= 0.03928 ? Y / 12.92 : ((Y + 0.055) / 1.055) ** 2.4;
  }

  return (
    <div className="comment_tags_container">
      {tags?.map((tag, index) => (
        <div
          key={index}
          className="comment_tag"
          style={{
            backgroundColor: tag?.tag?.color,
            color: getTextColor("#000000"),
          }}
        >
          {tag?.tag?.name}
        </div>
      ))}
    </div>
  );
};

export default CommentTags;
