import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Divider from "@mui/material/Divider";
import { Base64 } from "js-base64";
import "../../../Assets/Styles/Containers/_lines.scss";
import "../../../Assets/Styles/Containers/_lineDetails.scss";
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
// tabs
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Description from "../../Components/LineDetails/Description/Description";
import Media from "../../Components/LineDetails/Media/Media";
import Location from "../../Components/LineDetails/Location/Location";
import Information from "../../Components/LineDetails/Information/Information";
import NamePlate from "../../Components/LineDetails/NamePlate/NamePlate";
import CommentDetails from "../../Components/LineDetails/CommentDetails/CommentDetails";
import AddItemButton from "../../Components/Button/AddItemButton";
import AddCommentModal from "../../Components/LineDetails/CommentDetails/AddCommentModal";
import { toast } from "react-hot-toast";
import { activateLines, clearUpdatedInspectionData, getInspectionData, getInspectionDataWithOutLoader, setShowCommentLibraryModal } from "../../Redux/Actions/CommonActions";
import AdditionalInput from "../../Components/LineDetails/AdditionalInput/AdditionalInput";
import CreativeEditorSDKComponent from "../../Components/LineDetails/CreativeEditorSDK";
import CommentLibraryModal from "../../Components/LineDetails/CommentDetails/CommentLibraryModal";
import CommentDetailsNarrative from "../../Components/LineDetails/CommentDetails/CommentDetailsNarrative";
import ReportLibrarySideBar from "../ReportLibrary/ReportLibrarySideBar";
import useMediaQuery from "@mui/material/useMediaQuery";
import EditClCommentModal from "../../Components/LineDetails/CommentDetails/EditClCommentModal";

const LineDetails = ({ onDragtoggle, setOnDragToggle, selectedMediaFiles, setSelectedMediaFiles, selectedImages, setSelectedImages }) => {
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [lineData, setLineData] = useState();
  const [categoryData, setCategoryData] = useState();
  const [additionalInfoData, setAdditionalInfoData] = useState();
  const [refresh, setRefresh] = useState(false);
  const [editComment, setEditComment] = useState(false);
  const [editemodelNum, seteditemodelNum] = useState(false);
  const [disableCommentBtn, setDisableCommentBtn] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const location = useLocation();
  const commentSectionRef = useRef(null);
  const detailsSectionRef = useRef(null);
  const containerRef = useRef(null);
  const uuidRef = useRef(null);
  const isMobile = useMediaQuery("(max-width:900px)");
  // redux
  const dispatch = useDispatch();
  const isNarrative = location?.state?.isNarrative ? true : false;
  let templateId = location?.state?.template_id;
  let categoryId = location?.state?.category_id;
  let categoryUuid = location?.state?.category_uuid;
  let lineUuid = location?.state?.line_uuid;
  let target = location?.state?.target;
  let commentUuid = location?.state?.commentUuid;
  let addCommentFromLine = location?.state?.add_comment_from_line ? true : false;
  const storeData = useSelector((state) => state.CommonReducer);
  let templateData = storeData?.inspectionData?.data?.inspectiontemplates?.find((template) => template.template_id === templateId)
  let templateGuid = templateData?.template_guid

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    commentUuid = null;
    setValue(newValue);
    scrollToSection(newValue === 0 ? detailsSectionRef : commentSectionRef, newValue === 0 ? 100 : -120);
  };

  const handleTabChangeFromLine = (event, newValue) => {
    if (newValue === 1 && commentSectionRef.current) {
      setActiveTab(newValue);
      setTimeout(() => {
        commentSectionRef.current.scrollIntoView({ behavior: "smooth" });
        setDisableCommentBtn(false);
      }, 500);
    } else {
      setActiveTab(newValue);
    }
  };

  const handleAddComment = () => {
    setShowCommentModal(true);
    setDisableCommentBtn(false);
  };
  const handleCommentLibrary = () => {
    if (isNarrative) dispatch(setShowCommentLibraryModal(true));
    setDisableCommentBtn(false);
  };

  const getInspectionDataAfterClChanges = (guid) => {
    let sessionAssociateCompanyKey = Base64.decode(sessionStorage.getItem("AC"));
    let sessionUserId = Base64.decode(sessionStorage.getItem("UI"));
    dispatch(
      getInspectionData({
        guid: guid,
        associate_company: sessionAssociateCompanyKey,
        user_id: sessionUserId,
      })
    );
  };

  // useEffect(() => {
  //   if (!isNarrative) {
  //     if (target === "comment") {
  //       handleChange("", 1);
  //     } else {
  //       handleChange("", 0);
  //     }
  //   }
  // }, [target]);

  useEffect(() => {
    if (isNarrative && addCommentFromLine) {
      handleTabChangeFromLine(undefined, 1);
    }
  }, []);

  useEffect(() => {
    if (storeData.inspectionData) {
      setLineData(
        storeData?.inspectionData?.data?.inspectiontemplates
          ?.find((template) => template.template_id === templateId)
          ?.categories?.find((category) => category.id === categoryId)
          .lines?.find((line) => line.uuid === lineUuid)
      );
      let lineData = storeData?.inspectionData?.data?.inspectiontemplates
        ?.find((template) => template.template_id === templateId)
        ?.categories?.find((category) => category.id === categoryId)
        .lines?.find((line) => line.uuid === lineUuid);
      setAdditionalInfoData(lineData?.line_input_controls?.filter((data) => data.type === "Additional Input"));
    }
  }, [storeData.inspectionData, storeData.loader, refresh]);

  useEffect(() => {
    setLineData(
      storeData?.inspectionData?.data?.inspectiontemplates
        ?.find((template) => template.template_id === templateId)
        ?.categories?.find((category) => category.id === categoryId)
        .lines?.find((line) => line.uuid === lineUuid)
    );
    let lineData = storeData?.inspectionData?.data?.inspectiontemplates
      ?.find((template) => template.template_id === templateId)
      ?.categories?.find((category) => category.id === categoryId)
      .lines?.find((line) => line.uuid === lineUuid);
    setCategoryData(storeData?.inspectionData?.data?.inspectiontemplates?.find((template) => template.template_id === templateId)?.categories?.find((category) => category.id === categoryId));
    setAdditionalInfoData(lineData?.line_input_controls?.filter((data) => data.type === "Additional Input"));
    dispatch(activateLines(""));
    dispatch(activateLines(location?.state?.line_uuid));
  }, [location?.state?.category_id, location?.state?.line_uuid]);

  useEffect(() => {
    if (storeData.updatedInspectionData && storeData.updatedInspectionData !== "") {
      dispatch(clearUpdatedInspectionData());
    }
  }, [storeData.updatedInspectionData]);

  useEffect(() => {
    if (storeData?.mediaUpdate) {
      let sessionAssociateCompanyKey = Base64.decode(sessionStorage.getItem("AC"));
      let guid = sessionStorage.getItem("guid");
      let sessionUserId = Base64.decode(sessionStorage.getItem("UI"));
      dispatch(
        getInspectionData({
          guid: guid,
          associate_company: sessionAssociateCompanyKey,
          user_id: sessionUserId,
        })
      );
    }
  }, [storeData?.mediaUpdate]);

  useEffect(() => {
    if (storeData?.addCommentResponse !== "" && storeData?.addCommentResponse !== undefined) {
      toast.success(storeData?.addCommentResponse?.message);
    }
    if (storeData?.narrativeCommentAdded !== "" && storeData?.narrativeCommentAdded !== undefined) {
      toast.success(storeData?.addNarrativeCommentResponse?.message);
    }
  }, [storeData?.addCommentResponse, storeData?.narrativeCommentAdded]);

  useEffect(() => {
    if (storeData?.clCommentAdded || storeData?.clCommentStatusChanged || storeData?.narrativeCommentAdded) {
      getInspectionDataAfterClChanges(sessionStorage.getItem("guid"));
    }
  }, [storeData?.clCommentAdded, storeData?.clCommentStatusChanged, storeData?.narrativeCommentAdded]);

  const scrollToSection = (sectionRef, additionalOffset = 0) => {
    if (sectionRef.current && containerRef.current) {
      const container = containerRef.current;
      const headerHeight = document.getElementById("sticky_header") ? document.getElementById("sticky_header").offsetHeight : 0;
      const tabHeight = document.getElementById("details_tab") ? document.getElementById("details_tab").offsetHeight : 0;
      const totalOffset = headerHeight + tabHeight + additionalOffset;
      const containerTop = container.getBoundingClientRect().top;
      const sectionTop = sectionRef.current.getBoundingClientRect().top;
      const scrollY = sectionTop - containerTop - totalOffset;
      if (scrollY < container.scrollTop || scrollY > container.scrollTop + container.clientHeight) {
        container.scrollTo({
          top: container.scrollTop + scrollY,
          behavior: "smooth",
        });
      }
    }
  };

  const scrollToComment = (commentUuid) => {
    const commentElement = document.getElementById(commentUuid);
    if (commentElement) {
      commentElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    if (value === 0) {
      scrollToSection(detailsSectionRef, 100);
    } else if (value === 1 && commentUuid) {
      scrollToSection(uuidRef, -120);
    }
  }, [value]);

  useEffect(() => {
    if (!isNarrative) {
      if (target === "comment" && commentUuid) {
        // Scroll to the comment section after a delay of 2 seconds
        const timeout = setTimeout(() => {
          scrollToSection(uuidRef);
          scrollToComment(commentUuid);
          setValue(1);
        }, 3000);

        // Clear the timeout if the component unmounts or the dependencies change
        return () => clearTimeout(timeout);
      } else if (target === "comment" && (commentUuid === undefined || commentUuid === null)) {
        const timeout = setTimeout(() => {
          scrollToSection(commentSectionRef, -120);
          setValue(1);
        }, 3000);
        // Clear the timeout if the component unmounts or the dependencies change
        return () => clearTimeout(timeout);
      } else {
        // Scroll to the details section
        scrollToSection(detailsSectionRef);

        // Activate the details tab
        setValue(0);
      }
    }
  }, [target, commentUuid, isNarrative]);

  useEffect(() => {
    const handleScroll = () => {
      if (detailsSectionRef.current && commentSectionRef.current && containerRef.current) {
        const containerTop = containerRef.current.getBoundingClientRect().top;
        const sectionTop = detailsSectionRef.current.getBoundingClientRect().top;
        if (sectionTop === containerTop) {
          setValue(0);
        }
      }
    };
    const container = containerRef.current;
    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <motion.div className="h_100 w_bg" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      {storeData?.scrolPage ? window.scrollTo(0, 0) : ""}
      <div
        id="editor"
        style={{
          width: `${width}vw`,
          height: `${height}vh`,
          position: "fixed",
          left: "0",
          right: "0",
          top: "0",
          bottom: "0",
          zIndex: "2",
        }}
      ></div>
      <AddCommentModal
        showCommentModal={showCommentModal}
        setShowCommentModal={setShowCommentModal}
        templateId={templateId}
        categoryId={categoryId}
        lineUuid={lineUuid}
        editComment={editComment}
        setEditComment={setEditComment}
        disableCommentBtn={disableCommentBtn}
        setDisableCommentBtn={setDisableCommentBtn}
      />
      {isNarrative && (
        <CommentLibraryModal
          lineData={lineData}
          templateId={templateId}
          categoryId={categoryId}
          lineUuid={lineUuid}
          templateGuid={templateGuid}
          categoryUuid={categoryUuid}
          existingCommentUuids={lineData?.comments?.filter((comment) => comment.is_deleted === 0).map((comment) => comment.uuid)}
          categoryName={categoryData?.prompt}
        />
      )}
      <div style={{ boxSizing: "border-box", display: "box" }}>
        <div className="line-details-parent-div">
          <div className="line-details-child-div" style={{ borderRight: !isMobile ? "1px solid #BEBEBE" : "none" }}>
            <Header title={lineData?.prompt} action="line details" stickyHeader={true} />
            <Box sx={{ width: "100%" }}>
              {!isNarrative && (
                <Box className="sticky_lineDetails__tab" sx={{ borderBottom: 1, borderColor: "divider" }}>
                  {!isNarrative ? (
                    <Tabs value={value} onChange={handleChange} aria-label="disabled tabs example" sx={{ height: "30px" }}>
                      <Tab id="details_tab" className="tab__label" onClick={() => scrollToSection(detailsSectionRef, 100)} label="Details" />
                      <Tab id="comments_tab" className="tab__label" onClick={() => scrollToSection(commentSectionRef, -120)} label="Comments" />
                    </Tabs>
                  ) : null}
                </Box>
              )}
              {isMobile ? (
                <ReportLibrarySideBar
                  selectedImages={selectedImages}
                  setSelectedImages={setSelectedImages}
                  onDragtoggle={onDragtoggle}
                  setOnDragToggle={setOnDragToggle}
                  selectedMediaFiles={selectedMediaFiles}
                  setSelectedMediaFiles={setSelectedMediaFiles}
                />
              ) : (
                ""
              )}
              <Box className={!isNarrative ? "tabs_container" : "tabs_container1"} ref={containerRef}>
                {/* Details tab */}
                {!isNarrative && (
                  <Box id="details_section" ref={detailsSectionRef} value={value} index={0} className="tab_panel">
                    {/* Description */}
                    <Description data={lineData?.line_input_controls?.find((data) => data.type === "Description")} action="line" templateId={templateId} categoryId={categoryId} lineUuid={lineUuid} />
                    <Divider style={{ width: "100%" }} />
                    {/* ====================================================================================== */}

                    {/* Additional Information */}
                    {additionalInfoData?.map((additionalInfo) => (
                      <>
                        <AdditionalInput data={additionalInfo} action="line" templateId={templateId} categoryId={categoryId} lineUuid={lineUuid} />
                        <Divider style={{ width: "100%" }} />
                      </>
                    ))}
                    {/* ====================================================================================== */}

                    {/* Media */}
                    <Media
                      data={lineData?.line_input_controls?.find((data) => data.type === "Media")}
                      title="Media"
                      action="line"
                      templateId={templateId}
                      categoryId={categoryId}
                      categoryUuid={categoryUuid}
                      toggle={onDragtoggle}
                      setOnDragToggle={setOnDragToggle}
                      selectedMediaFiles={selectedMediaFiles}
                      setSelectedMediaFiles={setSelectedMediaFiles}
                      setSelectedImages={setSelectedImages}
                      lineUuid={lineUuid}
                      setRefresh={setRefresh}
                      width={setWidth}
                      setWidth={setWidth}
                      height={height}
                      setHeight={setHeight}
                      isNarrative={isNarrative}
                      addCommentFromLine={addCommentFromLine}
                    />
                    <Divider style={{ width: "100%" }} />

                    {/* ====================================================================================== */}
                    {/* Location */}
                    <Location
                      data={lineData?.line_input_controls?.find((data) => data.type === "Location")}
                      title="Location"
                      action="line"
                      templateId={templateId}
                      categoryId={categoryId}
                      lineUuid={lineUuid}
                      type="line"
                    />
                    <Divider style={{ width: "100%" }} />
                    {/* ====================================================================================== */}

                    {/* Information */}
                    <Information data={lineData?.line_input_controls?.find((data) => data.type === "Information")} action="line" templateId={templateId} categoryId={categoryId} lineUuid={lineUuid} />
                    <Divider style={{ width: "100%" }} />
                    {/* ====================================================================================== */}

                    {/* Nameplate */}
                    <NamePlate
                      toggle={onDragtoggle}
                      selectedImages={selectedImages}
                      setSelectedImages={setSelectedImages}
                      selectedMediaFiles={selectedMediaFiles}
                      setSelectedMediaFiles={setSelectedMediaFiles}
                      data={lineData?.line_input_controls?.find((data) => data.type === "Nameplate")}
                      namePlatedata={lineData?.line_input_controls}
                      action="line"
                      templateId={templateId}
                      categoryId={categoryId}
                      lineUuid={lineUuid}
                      categoryUuid={categoryUuid}
                      editemodelNum={editemodelNum}
                      seteditemodelNum={seteditemodelNum}
                    />
                  </Box>
                )}
                {/* Comments tab */}
                {!isNarrative ? (
                  <Box id="comment_section" ref={commentSectionRef}>
                    <Divider style={{ width: "100%" }} sx={{ borderBottomWidth: 8 }} />
                    <Grid id="comment_section" container className="cl_comment_title_section">
                      <Grid item xs={12} className="line_detail_des_title mb_0">
                        <span className="details_title">Comments</span>
                        <span className="add_comment_btn" onClick={handleAddComment}>
                          <AddItemButton text="New comment" width="100%" />
                        </span>
                      </Grid>
                    </Grid>
                    <Grid container className="comment__container_divider">
                      <Divider style={{ width: "100%", marginTop: "15px" }} />
                    </Grid>
                    {lineData?.comments.length !== 0 &&
                      lineData?.comments?.map((comment, index) => (
                        <div id={comment.uuid} key={comment?.uuid} ref={uuidRef?.current?.id === comment?.uuid ? uuidRef : null}>
                          <CommentDetails
                            {...comment}
                            toggle={onDragtoggle}
                            setSelectedImages={setSelectedImages}
                            templateId={templateId}
                            categoryId={categoryId}
                            categoryUuid={categoryUuid}
                            setSelectedMediaFiles={setSelectedMediaFiles}
                            selectedMediaFiles={selectedMediaFiles}
                            lineUuid={lineUuid}
                            width={setWidth}
                            setWidth={setWidth}
                            height={height}
                            setHeight={setHeight}
                            commentIndex={index}
                          />
                          <Divider style={{ width: "100%" }} sx={{ borderBottomWidth: 8 }} />
                        </div>
                      ))}
                  </Box>
                ) : (
                  <>
                    <Divider style={{ width: "100%" }} sx={{ borderBottomWidth: 8 }} ref={commentSectionRef} />
                    <Grid container className="cl_comment_title_section">
                      <Grid item xs={12} className="line_detail_des_title mb_0">
                        <span className="details_title">Comments</span>
                        <span className="add_comment_btn" onClick={() => handleCommentLibrary()}>
                          <AddItemButton text="New comment" width="100%" />
                        </span>
                      </Grid>
                    </Grid>
                    <Grid container className="comment__container_divider">
                      <Divider style={{ width: "100%", marginTop: "15px" }} />
                    </Grid>
                    {lineData?.comments.filter((comment) => comment?.is_deleted === 1).length === lineData?.comments?.length || lineData?.comments?.length === 0 ? (
                      <p className="empty_comment_section">No Comments</p>
                    ) : (
                      lineData?.comments?.map((comment, index) =>
                        !isNarrative ? (
                          <div key={index}>
                            <CommentDetails
                              {...comment}
                              toggle={onDragtoggle}
                              setOnDragToggle={setOnDragToggle}
                              templateId={templateId}
                              setSelectedImages={setSelectedImages}
                              selectedMediaFiles={selectedMediaFiles}
                              setSelectedMediaFiles={setSelectedMediaFiles}
                              categoryId={categoryId}
                              categoryUuid={categoryUuid}
                              lineUuid={lineUuid}
                              width={setWidth}
                              setWidth={setWidth}
                              height={height}
                              setHeight={setHeight}
                              commentIndex={index}
                            />
                            <Divider style={{ width: "100%" }} sx={{ borderBottomWidth: 8 }} />
                          </div>
                        ) : comment?.is_deleted !== 1 ? (
                          <div key={index}>
                            <CommentDetailsNarrative
                              key={index}
                              {...comment}
                              toggle={onDragtoggle}
                              setSelectedImages={setSelectedImages}
                              templateId={templateId}
                              categoryId={categoryId}
                              selectedMediaFiles={selectedMediaFiles}
                              setSelectedMediaFiles={setSelectedMediaFiles}
                              categoryUuid={categoryUuid}
                              lineUuid={lineUuid}
                              commentIndex={index}
                              lineData={lineData}
                              isNarrative={isNarrative}
                              categoryName={categoryData?.prompt}
                              setLineData={setLineData}
                            />
                            <Divider
                              style={{
                                width: "94%",
                                margin: "0px 18px",
                                justifyContent: "center !important",
                                alignItems: "center",
                                display: "flex",
                              }}
                              sx={{ borderBottomWidth: 1 }}
                            />
                          </div>
                        ) : null
                      )
                    )}
                  </>
                )}
              </Box>
            </Box>
          </div>
          {!isMobile ? (
            <div style={{ minHeight: "100vh", padding: "13px 3px 10px 3px" }}>
              <div style={{ position: "sticky", top: "23px" }}>
                <ReportLibrarySideBar
                  selectedImages={selectedImages}
                  setSelectedImages={setSelectedImages}
                  onDragtoggle={onDragtoggle}
                  setOnDragToggle={setOnDragToggle}
                  selectedMediaFiles={selectedMediaFiles}
                  setSelectedMediaFiles={setSelectedMediaFiles}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default LineDetails;
