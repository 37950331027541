import axios from "axios";
import { Base64 } from "js-base64";
// import Environment from "../../env";
// import { getReportWriterBaseUrl } from "../../Utilities/GlobalUrl";
import { origin } from "./Api-constants";
const instance = axios.create({
  // baseURL: '',
  //  baseURL: 'https://nextgendevelopphpreportwriter.yourinspection.report/public/index.php/api/', 
  timeout: 500000,
});

instance.interceptors.request.use(
  async (config) => {
    // console.log()
    try {
      config.baseURL = await origin(); 
      // config.headers["api-key"] = "Palmtech"; 
      let sessionAPIKey = sessionStorage.getItem('CK');
      config.headers["api-key"] = `${Base64.decode(sessionAPIKey)}`;  
      config.headers["platform"] = 'web';  
      // config.headers.put["Content-Type"] = "multipart/form-data";
      // config.headers["Content-Type"] = "multipart/form-data";
      // config.headers.common["Accept"] = "multipart/form-data";
      // config.headers.post["Content-Type"] = "multipart/form-data";
      // config.headers.Authorization = 'Bearer f0b2f376-ed87-11ec-bdf6-34e6d7007df8';
      let sessionSecretKey = sessionStorage.getItem('SK')
      config.headers.Authorization = `Bearer ${Base64.decode(sessionSecretKey)}`;
     
    } catch (error) {
      console.log(" *************** Request error ***************** ", error);
    }
    return config;
  },
  (error) => {
    console.log(
      " *************** Error Request *****************",
      JSON.stringify(error)
    );
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  async (response) => {
    // console.log(response);
    return response;
    //return Promise.reject(response);
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
