import { motion } from "framer-motion";
import React, { useState, useEffect } from 'react';
import FilterIcon from '@mui/icons-material/Filter';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from '@mui/material/CircularProgress';
import { Grid, Paper, IconButton, Typography } from '@mui/material';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
/**
 * Renders a gallery of images from the report library
 * @param {Object[]} reportLibraryData Array of image data objects
 * @param {string} bucketUrl Bucket url of the report library
 */
const Gallery = ({ reportLibraryData, bucketUrlWRW, bucketUrlPortal, selectedImages, setSelectedImages, selectedMediaFiles, setSelectedMediaFiles, setOnDragToggle }) => {
    const [paperColor, setPaperColor] = useState(true); //paper color
    const [maxHeight, setMaxHeight] = useState('100vh'); // Height of the gallery
    const [showGallery, setShowGallery] = useState(false); // Flag to show/hide the gallery

    //selected media 
    useEffect(() => {
        setSelectedMediaFiles(reportLibraryData?.filter((_, index) => selectedImages?.includes(index)))
    }, [selectedImages])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        /**
         * Calculates and sets the max height of the gallery
         * The height is calculated based on the current window height
         */
        const updateMaxHeight = () => {
            // Calculate and set the max height of the gallery
            setMaxHeight(`${window.innerHeight}px`);
        };
        // Update max height when window is resized
        window.addEventListener('resize', updateMaxHeight);
        // Initial max height calculation
        updateMaxHeight();
        // Cleanup event listener
        return () => window.removeEventListener('resize', updateMaxHeight);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Handle image click event
     * @param {number} index Index of the image in the array of images
     */
    const handleImageClick = (index) => {
        // Get the index of the image in the selected images array
        const selectedIndex = selectedImages.indexOf(index);
        // Init empty array to store new selected images
        let newSelected = [];

        // If the image is not in selected images array, add it
        if (selectedIndex === -1) {
            // Concatenate new image index to the selected images array
            newSelected = newSelected.concat(selectedImages, index);

            // If the image is in the first position, remove it
        } else if (selectedIndex === 0) {
            // Slice the selected images array from the second element
            newSelected = newSelected.concat(selectedImages.slice(1));

            // If the image is in the last position, remove it
        } else if (selectedIndex === selectedImages.length - 1) {
            // Slice the selected images array from 0 to the last element
            newSelected = newSelected.concat(selectedImages.slice(0, -1));

            // If the image is in between selected images, remove it
        } else if (selectedIndex > 0) {
            // Slice the selected images array from 0 to the selected image index
            newSelected = newSelected.concat(
                selectedImages.slice(0, selectedIndex),
            );
            // Slice the selected images array from the selected image index to the last element
            newSelected = newSelected.concat(
                selectedImages.slice(selectedIndex + 1),
            );
        }
        // Set new selected images array
        setSelectedImages(newSelected);
    };

    const isSelected = (index) => selectedImages?.indexOf(index) !== -1;

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowGallery(true);
        }, 400); // Adjust the delay as needed

        return () => clearTimeout(timer);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const imageStyles = {
        paper: {
            color: '#F6F6F6',
            cursor: 'pointer',
            minHeight: "139px",
            textAlign: 'center',
            borderRadius: '5px',
            position: 'relative',
            objectFit: "contain",
            backgroundColor: paperColor ? "#BEBEBE" : "#FFFFFF",
        },
        loadingPaper: {
            color: '#F6F6F6',
            cursor: 'pointer',
            minHeight: "139px",
            textAlign: 'center',
            borderRadius: '5px',
            position: 'relative',
            objectFit: "contain",
            backgroundColor: "#BEBEBE",
        },
        selected: {
            border: '2px solid #4282E2',
        },
        checkIcon: {
            position: 'absolute',
            top: '10px',
            right: '10px',
            color: '#4282E2',
            padding: "13px",
            height: "20px",
            width: "20px",
            backgroundColor: "#FFFFFFCC",
        },
        imageContainer: {
            position: 'relative',
            width: '100%',
            paddingTop: '100%', // Aspect ratio: 1:1 (square)
            overflow: 'hidden',
        },
        image: {
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            borderRadius: "2px",
            objectFit: 'cover',
        },
        captionBar: {
            left: 0,
            bottom: 0,
            width: '100%',
            color: '#fff',
            padding: '7px',
            position: 'absolute',
            background: '#FFFFFFCC',
            borderRadius: "0px 0px 4px 4px",
        },
        videoIcon: {
            left: 4,
            bottom: '31%',
            width: '100%',
            color: '#fff',
            padding: '7px',
            position: 'absolute',
            borderRadius: "0px 0px 4px 4px",
        },
        imageIcon: {
            left: 4,
            bottom: '31%',
            width: '100%',
            color: '#fff',
            padding: '7px',
            position: 'absolute',
            borderRadius: "0px 0px 4px 4px",
        },
        circularProgress: {
            top: 0,
            right: 0,
            color: '#fff',
            padding: "5px",
            position: 'absolute',

        },
    };

    const handleMedia = (data) => {
        let BucketURL;
        let MediaURL;
        if (data?.is_portal === 1) {
            BucketURL = bucketUrlPortal;
        }
        else BucketURL = bucketUrlWRW;
        if (data?.thumbnail) {
            MediaURL = data?.thumbnail;
        }
        else if (data?.onlineUrl) {
            MediaURL = data?.onlineUrl
        }
        else if (data?.thumbnail_url) {
            MediaURL = data?.thumbnail_url
        }
        else if (data?.online_url) {
            MediaURL = data?.online_url
        }
        return `${BucketURL}${MediaURL}`
    }

    return (
        <div className="rml-container">
            <Grid container sx={{ p: "5px", position: "relative", mt: -1 }}>
                {reportLibraryData?.map((imageData, index) => (
                    <Grid item xs={6} key={index} sx={{ p: "10px" }}>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            onAnimationComplete={() => setPaperColor(false)}
                            transition={{ duration: 1 }}
                        >
                            {/* Title will come up in v2 */}
                            {/* {showGallery && <div style={{ textAlign: "left", padding: "5px" }}>
                                <Typography className="caption-title">{imageData?.name ? imageData?.name : imageData?.filename}</Typography>
                            </div>} */}
                            {imageData.online_url || imageData.onlineUrl ? <Paper
                                style={isSelected(index) ? { ...imageStyles.paper, ...imageStyles.selected } : imageStyles.paper}
                                onClick={() => handleImageClick(index)}
                                elevation={0}
                                draggable
                                onDragStart={() => { setSelectedMediaFiles([...selectedMediaFiles, imageData]); setSelectedImages([...selectedImages, index]); setOnDragToggle(true) }}
                                onDragEnterCapture={() => setOnDragToggle(true)} onDragEnd={() => { setOnDragToggle(false); setSelectedImages([]); setSelectedMediaFiles([]) }}
                            >
                                {showGallery && <div style={imageStyles.imageContainer}>
                                    <img draggable onDragStart={() => { setSelectedMediaFiles([...selectedMediaFiles, imageData]); setSelectedImages([...selectedImages, index]); setOnDragToggle(true) }}
                                        onDragEnterCapture={() => setOnDragToggle(true)} onDragEnd={() => { setOnDragToggle(false); setSelectedImages([]); setSelectedMediaFiles([]) }} src={handleMedia(imageData)} alt={`Tags ${index}`} style={imageStyles.image} />
                                    {/* caption div is not in Phase 1 */}
                                    {/* <div style={imageStyles.captionBar}>
                                        <Typography className="caption-text">Caption for Image {index + 1}</Typography>
                                    </div> */}
                                </div>}
                                {isSelected(index) && (
                                    <IconButton style={imageStyles.checkIcon}>
                                        <CheckCircleIcon />
                                    </IconButton>
                                )}
                                {(imageData?.media_type?.startsWith("video/") || imageData?.type?.startsWith("video/")) && (
                                    <IconButton draggable style={imageStyles.videoIcon} disableRipple>
                                        <PlayCircleFilledIcon className={'check_icon_gallery'} />
                                    </IconButton>
                                )}
                            </Paper> :
                                <Paper style={imageStyles.loadingPaper}
                                    elevation={0}>
                                    {showGallery &&
                                        <div style={imageStyles.imageContainer} >
                                            <div style={imageStyles.circularProgress}>
                                                <CircularProgress sx={{ height: "15px !important", width: "15px !important" }} />
                                            </div>
                                        </div>}
                                    <IconButton style={imageStyles.imageIcon} disableRipple>
                                        <FilterIcon className={'check_icon_gallery'} />
                                    </IconButton>
                                </Paper>}

                        </motion.div>
                    </Grid>
                ))}
                {reportLibraryData?.length === 0 && <Grid item xs={12} sx={{ mt: '3px' }}><div className="no_media_found_container ">
                    <DoDisturbAltIcon className="no_media_found_icon" />
                    <p className="mo_media_found">No images or videos are in the<br /> report library.</p>
                </div>
                </Grid>}
            </Grid >
        </div >
    );
};

export default Gallery;