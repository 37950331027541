import React from 'react';
import { Box, Grid, Modal, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

function ImageViewer({ imageviewer, imageLink, imageLineName, imageCategoryName, setImageviewer }) {
  const handleClose = () => {
    setImageviewer(false);
  };

  return (
    <Modal
      keepMounted
      open={imageviewer}
      onClose={handleClose}
      className="carousel-modal"
      sx={{ backgroundColor: "rgb(25,25,25,0.8)" }}
    >
      <Box className="carousel-modal-layout">
        <Grid container sx={{ p: 2 }}>
          <Grid item xs={12}>
            <CloseIcon className="carousel-close-icon" onClick={handleClose} />
          </Grid>
          <Grid item xs={12}>
            <TransformWrapper>
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <Grid container sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "90vh" }}>
                  <Grid item xs={2} sx={{ verticalAlign: "middle" }}>
                    <IconButton sx={{ float: "right !important" }}></IconButton>
                  </Grid>
                  <Grid item xs={8}>
                    <div style={{ width: "100%", justifyContent: "center", display: "flex" }}>
                        <TransformComponent>
                          <img src={imageLink} style={{ maxHeight: "90vh", width: "auto", maxWidth: "100%"}} />
                        </TransformComponent>
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton></IconButton>
                    <button style={{ margin: "25px", backgroundColor: "rgb(25,25,25,0.8)", height: "0px",display:"list-item",marginBottom: "40px" }} onClick={() => zoomIn()}><ZoomInIcon sx={{ color: "white", fontSize: "23px" }} /></button>
                    <button style={{ margin: "25px", backgroundColor: "rgb(25,25,25,0.8)", height: "0px",display:"list-item",marginBottom: "40px"  }} onClick={() => zoomOut()}><ZoomOutIcon sx={{ color: "white", fontSize: "23px" }} /></button>
                    <button style={{ margin: "25px", backgroundColor: "rgb(25,25,25,0.8)", color: "white", fontSize: "23px", height: "0px" ,display:"list-item",marginBottom: "40px" }} onClick={() => resetTransform()}>Reset</button>
                  </Grid>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="flex-end"
                    sx={{ position: "absolute", bottom: 0, left: 0 }}
                  >
                    <Typography variant="h6" sx={{ color: "#fff", marginLeft: "15px", marginBottom: "15px" }}>
                      {imageLineName !== "" && imageLineName !== undefined ? `${imageCategoryName}: ${imageLineName}` : imageCategoryName}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </TransformWrapper>
          </Grid>
          {/* Top left corner with the name */}

        </Grid>
      </Box>
    </Modal>
  );
}

export default ImageViewer;
