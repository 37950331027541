import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../Components/Header/Header";
import { motion } from "framer-motion";
import { FormControl, Grid, TextField, Typography } from "@mui/material";
import { BorderLinearProgress } from "../../Common/MuiStyles/MuiStyles";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  categoryPercentCalculate,
  filterObjectsUptoCategory,
  filterObjectsUptoTemplate,
  getNonNarrativeTemplatesGuid,
} from "../../Helpers/HelperFunctions";
import "../../../Assets/Styles/Containers/_inspection.scss";
import AddItemButton from "../../Components/Button/AddItemButton";
import { useDispatch, useSelector } from "react-redux";
import AddCategoryModal from "../../Components/Category/AddCategoryModal";
import CreateNewCategoryModal from "../../Components/Category/CreateNewCategoryModal";
import {
  getDefaultCategories,
  UpdateTemplateDataSpecific,
} from "../../Helpers/ApiCall";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Box, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../../../Assets/Styles/Containers/_modal.scss";
import { v4 as uuidv4 } from "uuid";
import OutlinedButton from "../../Components/Button/OutlinedButton";
import FilledButton from "../../Components/Button/FilledButton";
import { GetUTCTime } from "../../Helpers/GetUTCTime";
import Preloader from "../../Helpers/Preloader";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import ReorderIcon from "@mui/icons-material/Reorder";
import PdfCategories from "../../Components/PdfFormFill/PdfCategories";
import toast from "react-hot-toast";
import DeleteConfirmationDialog from "../../Common/Alert/DeleteConfirmationDialog";

const Inspection = () => {
  const [mainStoreData, setMainStoreData] = useState();
  const [inspectionResponse, setInspectionResponse] = useState();
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const IsPdf = location?.state?.isPDF;
  const navigate = useNavigate();
  // redux
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.CommonReducer);
  useEffect(() => {
    setMainStoreData(storeData);
  }, [storeData]);
  let templateId = location?.state?.template_id;
  let tempIndex = location?.state?.tempIndex;
  let templateData = inspectionResponse?.inspectiontemplates?.find(
    (template) => template.template_id === templateId
  );
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [editCategory, setEditCategory] = useState(false);
  const [deleteCategory, setDeleteCategory] = useState(false);
  const [reorderCategory, setReorderCategory] = useState(false);
  const [showCreateCategoryModal, setShowCreateCategoryModal] = useState(false);
  const [activeDeleteCategory, setActiveDeleteCategory] = useState();
  const [deleteCategoryModal, setShowDeleteModal] = useState(false);
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
  const [activeEditCategory, setActiveEditCategory] = useState(false);
  const [editCategoryName, setEditCategoryName] = useState("");
  // const [newCategoryList, setNewCategoryList] = useState([
  //   { label: "Crawl Space",selected:false },
  //   { label: "Outbuilding",selected:false },
  //   { label: "Fireplace/Wood stove",selected:false },
  //   { label: "Pool",selected:false },
  //   { label: "Well",selected:false },
  //   { label: "Kitchen",selected:false },
  //   { label: "Septic",selected:false },
  //   { label: "Bedroom",selected:false },
  //   { label: "Garden",selected:false },
  //   { label: "Parking",selected:false },
  // ]);
  const [newCategoryList, setNewCategoryList] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  // reorder
  const [catoutput, setCatOutput] = useState([]);
  const [catreorder, setCatReorder] = useState(false);
  useEffect(() => {
    setCatOutput(templateData?.categories);
  }, [templateData]);
  useEffect(() => {
    if (templateData?.categories) {
      setTimeout(function () {
        setLoader(false);
      }, 2500);
    }
  }, [templateData]);

  const handleAddCategory = () => {
    setNewCategoryList([]);
    getDefaultCategories(
      dispatch,
      storeData.inspectionData?.data?.inspectiontemplates?.find(
        (template) => template.template_id === templateId
      )?.template_guid
    );
    setShowCategoryModal(true);
    setSelectedCategories([]);
  };

  const handleCategorySelection = (category) => {
    if (category.is_selected === false) category.is_selected = true;
    // else category.is_selected = false;
    if (selectedCategories.length === 0) {
      setSelectedCategories([category]);
    } else {
      setSelectedCategories((prev) => {
        let catIndex = prev.findIndex((item) => item.uuid === category.uuid);
        if (catIndex !== -1) {
          return prev.filter(
            (prevCategory) => prevCategory.uuid !== category.uuid
          );
        } else return [...prev, category];
      });
    }
  };

  const handleDeleteCategory = (category) => {
    setShowDeleteModal(true);
    setActiveDeleteCategory(category);
  };

  const handleEditCategory = (category) => {
    setShowEditCategoryModal(true);
    setActiveEditCategory(category);
    setEditCategoryName(category.prompt);
  };

  const handleCloseDeleteAlert = () => {
    setShowDeleteModal(false);
  };
  const handleCloseEditAlert = () => {
    setShowEditCategoryModal(false);
  };

  const handleConfirmDeleteCategory = async () => {
    let templateIndex = inspectionResponse?.inspectiontemplates?.findIndex(
      (itm) => itm.template_id === templateId
    );
    let categoryIndex = inspectionResponse?.inspectiontemplates[
      templateIndex
    ]?.categories?.findIndex((itm) => itm.uuid === activeDeleteCategory.uuid);
    let prevData = inspectionResponse;
    prevData.inspectiontemplates[templateIndex].categories[
      categoryIndex
    ].is_deleted = 1;
    prevData.inspectiontemplates[templateIndex].categories[
      categoryIndex
    ].updated_at = await GetUTCTime();

    let filteredObject = filterObjectsUptoCategory(
      prevData,
      templateIndex,
      categoryIndex
    );
    setDeleteCategory(true);
    UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
    setShowDeleteModal(false);
  };

  const handleConfirmEditCategory = async (editedName) => {
    let templateIndex = inspectionResponse?.inspectiontemplates?.findIndex(
      (itm) => itm.template_id === templateId
    );
    let categoryIndex = inspectionResponse?.inspectiontemplates[
      templateIndex
    ]?.categories?.findIndex((itm) => itm.uuid === activeEditCategory.uuid);
    let prevData = inspectionResponse;
    prevData.inspectiontemplates[templateIndex].categories[
      categoryIndex
    ].prompt = editedName;
    prevData.inspectiontemplates[templateIndex].categories[
      categoryIndex
    ].updated_at = await GetUTCTime();

    setEditCategory(true);
    let filteredObject = filterObjectsUptoCategory(
      prevData,
      templateIndex,
      categoryIndex
    );
    UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
    setShowEditCategoryModal(false);
  };
  // comment added
  // useEffect(() => {
  //   getDefaultCategories(dispatch,{template_guid:templateData?.template_guid})
  // }, [location?.state?.template_id])

  // useEffect(() => {
  //   getDefaultCategoriesData(dispatch)

  //   }, [location?.state?.template_id])
  useEffect(() => {
    if (storeData?.loader) setLoader(true);
    else {
      setLoader(false);
    }
  }, [storeData?.loader]);

  useEffect(() => {
    if (storeData?.loader) setLoader(true);
    else {
      setLoader(false);
    }
  }, [storeData?.loader]);

  useEffect(() => {
    if (storeData.inspectionData) {
      setMainStoreData(storeData);
    }
  }, [storeData.editCategory]);

  useEffect(() => {
    if (storeData.inspectionData) {
      setInspectionResponse(storeData.inspectionData?.data);
      //  getDefaultCategories(dispatch,storeData.inspectionData?.data?.inspectiontemplates?.find(template=>template.template_id === templateId)?.template_guid)
    }
  }, [storeData.inspectionData]);

  // optional and standard categories
  useEffect(() => {
    if (storeData.defaultCategoryList) {
      setNewCategoryList(
        storeData.defaultCategoryList?.length !== 0
          ? storeData.defaultCategoryList?.data
          : []
      );
    }
  }, [storeData.defaultCategoryList]);

  //  fixed default categories
  // useEffect(() => {
  //   if(storeData.defaultCategoryListData && storeData.defaultCategoryListData.length !== 0){
  //    setNewCategoryList(storeData.defaultCategoryListData?.data?.categories);
  //  }
  //  }, [storeData.defaultCategoryListData])

  // useEffect(() => {
  //   getDefaultCategories(dispatch,storeData.inspectionData?.data?.inspectiontemplates?.find(template=>template.template_id === templateId)?.template_guid)
  //  }, [location?.state?.template_id])

  // reorder
  const reorder = (data, startIndex, endIndex) => {
    const result = Array.from(data);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async (result, templateId) => {
    if (!result.destination) {
      return;
    }
    setLoader(true);

    const reorderedItems = reorder(
      catoutput,
      result.source.index,
      result.destination.index
    );

    reorderedItems.forEach((x, i) => {
      x.order = i + 1;
      return x;
    });
    setCatOutput(reorderedItems);
    let prevData = inspectionResponse;
    reorderedItems.map(async (item) => {
      item.updated_at = await GetUTCTime();
    });
    const index = prevData.inspectiontemplates.findIndex((item) => {
      return item.template_id == templateId;
    });
    prevData.inspectiontemplates[index].categories = reorderedItems;
    prevData.inspectiontemplates[index].updated_at = await GetUTCTime();
    setReorderCategory(true);
    let filteredObject = filterObjectsUptoTemplate(prevData, index);
    UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
  };

  useEffect(() => {
    if (storeData?.updatedInspectionData?.success === true && editCategory) {
      toast.success("Category name has been updated.");
      setEditCategory(false);
    }
    if (storeData?.updatedInspectionData?.success === true && deleteCategory) {
      toast.success("Category has been deleted.");
      setDeleteCategory(false);
    }
    if (storeData?.updatedInspectionData?.success === true && reorderCategory) {
      toast.success("The inspection has been updated successfully.");
      setReorderCategory(false);
    }
  }, [handleConfirmDeleteCategory, handleConfirmEditCategory, onDragEnd]);

  return (
    <motion.div
      className="h_100 w_bg"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {storeData.scrolPage ? window.scrollTo(0, 0) : ""}
      <Preloader showPreloader={loader} />
      <AddCategoryModal
        setLoader={setLoader}
        newCategoryList={newCategoryList}
        templateData={templateData}
        showCategoryModal={showCategoryModal}
        setShowCategoryModal={setShowCategoryModal}
        showCreateCategoryModal={showCreateCategoryModal}
        setShowCreateCategoryModal={setShowCreateCategoryModal}
        handleCategorySelection={handleCategorySelection}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
      />
      <CreateNewCategoryModal
        newCategoryList={newCategoryList}
        setNewCategoryList={setNewCategoryList}
        templateData={templateData}
        showCreateCategoryModal={showCreateCategoryModal}
        setShowCreateCategoryModal={setShowCreateCategoryModal}
        showCategoryModal={showCategoryModal}
        setShowCategoryModal={setShowCategoryModal}
        handleCategorySelection={handleCategorySelection}
      />
      <Header title="Inspection" action="categories" />
      {/* reorder */}
      <Box className="inspectionBox">
        <Typography className="inspection__title">
          {templateData?.name}
        </Typography>
        {mainStoreData?.reorderCategory ? (
          <DragDropContext onDragEnd={(data) => onDragEnd(data, templateId)}>
            <Droppable droppableId={String(Math.floor(Math.random() * 100))}>
              {(provided) => (
                <div ref={provided.innerRef}>
                  {catoutput?.map((category, index) => {
                    return (
                      <>
                        {getNonNarrativeTemplatesGuid(
                          inspectionResponse?.inspectiontemplates
                        )?.[0] !== templateData?.template_guid &&
                        category.is_inspection_details === 1
                          ? ""
                          : category?.is_deleted == 0 && (
                              <Draggable
                                draggableId={String(category.uuid)}
                                // isDragDisabled ={actives && items.line_id === ids}
                                key={category.uuid}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    key={index}
                                    ref={provided.innerRef}
                                    {...provided.dragHandleProps}
                                    {...provided.draggableProps}
                                  >
                                    <div
                                      className={`progress__section__category ${
                                        index === 0 ||
                                        (getNonNarrativeTemplatesGuid(
                                          inspectionResponse?.inspectiontemplates
                                        )?.[0] !==
                                          templateData?.template_guid &&
                                          index === 1)
                                          ? "border-top"
                                          : ""
                                      }`}
                                      key={category.uuid}
                                    >
                                      <div
                                        className="edit_category pointer"
                                        id={`reorder_cat_${index}`}
                                        style={{ marginRight: "15px" }}
                                      >
                                        <Typography>
                                          {category.prompt !== null
                                            ? category.prompt
                                            : "Untitled"}
                                        </Typography>
                                        <Typography className="edit_container_icons">
                                          <span>
                                            <ReorderIcon />
                                          </span>
                                        </Typography>
                                        {/* <BorderLinearProgress variant="determinate" value={0} /> */}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            )}
                      </>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <>
            {" "}
            {mainStoreData?.editCategory
              ? templateData?.categories?.map((category, index) =>
                  getNonNarrativeTemplatesGuid(
                    inspectionResponse?.inspectiontemplates
                  )?.[0] !== templateData?.template_guid &&
                  category.is_inspection_details === 1 ? (
                    ""
                  ) : category?.is_deleted !== 1 ? (
                    <div
                      className={`progress__section__category ${
                        index === 0 ||
                        (getNonNarrativeTemplatesGuid(
                          inspectionResponse?.inspectiontemplates
                        )?.[0] !== templateData?.template_guid &&
                          index === 1)
                          ? "border-top"
                          : ""
                      }`}
                      key={category.uuid}
                    >
                      <div
                        className="edit_category pointer"
                        style={{ marginRight: "15px" }}
                      >
                        <Typography>
                          {category.prompt !== null
                            ? category.prompt
                            : "Untitled"}
                        </Typography>

                        <Typography className="edit_container_icons">
                          <span
                            onClick={() => handleEditCategory(category)}
                            id={`edit_cat_${index}`}
                          >
                            <EditIcon className="edit__icon" />
                          </span>
                          <span
                            onClick={() => handleDeleteCategory(category)}
                            id={`delete_cat_${index}`}
                          >
                            <RemoveCircleOutlineIcon className="delete__icon" />
                          </span>
                        </Typography>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                )
              : templateData?.is_pdf_template !== 1
              ? templateData?.categories?.map((category, index) =>
                  getNonNarrativeTemplatesGuid(
                    inspectionResponse?.inspectiontemplates
                  )?.[0] !== templateData?.template_guid &&
                  category.is_inspection_details === 1 ? (
                    ""
                  ) : category?.is_deleted !== 1 && !category?.showEmpty ? (
                    <div
                      className={`progress__section__category ${
                        index === 0 ||
                        (getNonNarrativeTemplatesGuid(
                          inspectionResponse?.inspectiontemplates
                        )?.[0] !== templateData?.template_guid &&
                          index === 1)
                          ? "border-top"
                          : ""
                      }`}
                      key={index}
                    >
                      <div
                        className="new__relative "
                        id={`category_${index}`}
                        onClick={() =>
                          navigate(
                            `/inspection/${
                              category.prompt !== null
                                ? encodeURIComponent(category.prompt)
                                : "Untitled"
                            }`,
                            {
                              state: {
                                category,
                                category_name:category.prompt,
                                template_id: templateData.template_id,
                                category_id: category.id,
                                template_guid: templateData.template_guid,
                                isNarrative: location?.state?.isNarrative
                                  ? true
                                  : false,
                              },
                            }
                          )
                        }
                      >
                        <Typography sx={{ width: "90%" }}>
                          {category.prompt !== null
                            ? category.prompt
                            : "Untitled"}
                        </Typography>
                        <Typography
                          sx={{ width: "10%", textAlign: "end" }}
                          className="category__arrow_right "
                        >
                          <KeyboardArrowRightIcon />
                        </Typography>
                        <BorderLinearProgress
                          variant="determinate"
                          value={categoryPercentCalculate(category)?.percentage}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                )
              : templateData?.pdf_categories?.map((pdfcategory, pdfindex) =>
                  getNonNarrativeTemplatesGuid(
                    inspectionResponse?.inspectiontemplates
                  )?.[0] !== templateData?.template_guid &&
                  pdfcategory.is_inspection_details === 1 ? (
                    ""
                  ) : pdfcategory?.is_deleted !== 1 ? (
                    <div
                      className={`progress__section__category ${
                        pdfindex === 0 ||
                        (getNonNarrativeTemplatesGuid(
                          inspectionResponse?.inspectiontemplates
                        )?.[0] !== templateData?.template_guid &&
                          pdfindex === 1)
                          ? "border-top"
                          : ""
                      }`}
                      key={pdfindex}
                    >
                      <div
                        className={
                          pdfcategory?.type == 3
                            ? "how_to_use_this_form"
                            : "new__relative"
                        }
                        id={`category_${pdfindex}`}
                        onClick={() =>
                          navigate(
                            `/inspection/${
                              pdfcategory.prompt !== null
                                ? encodeURIComponent(pdfcategory.prompt)
                                : "Untitled"
                            }`,
                            {
                              state: {
                                pdfcategory,
                                template_id: templateData?.template_id,
                                category_id: pdfcategory?.id,
                                isPDF: 1,
                                template_guid: templateData.template_guid,
                                pdfcategoryType: pdfcategory?.type,
                                isNarrative: location?.state?.isNarrative
                                  ? true
                                  : false,
                              },
                            }
                          )
                        }
                      >
                        <Typography sx={{ width: "90%" }}>
                          {pdfcategory.prompt !== null
                            ? pdfcategory.prompt
                            : "Untitled"}
                        </Typography>
                        {pdfcategory?.type != 3 ? (
                          <Typography
                            sx={{ width: "10%", textAlign: "end" }}
                            className="category__arrow_right "
                          >
                            <KeyboardArrowRightIcon />
                          </Typography>
                        ) : (
                          <Typography
                            sx={{ width: "10%", textAlign: "start" }}
                            className="how_to_use_this_form__arrow_right"
                          >
                            <KeyboardArrowRightIcon />
                          </Typography>
                        )}
                        {pdfcategory?.type != 3 ? (
                          <BorderLinearProgress
                            variant="determinate"
                            value={0}
                          />
                        ) : (
                          <BorderLinearProgress
                            variant="determinate"
                            value={0}
                          />
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                )}
          </>
        )}
      {!mainStoreData?.editCategory &&
      !mainStoreData?.reorderCategory &&
      templateData?.is_pdf_template !== 1 ? (
        <div className="add__category" onClick={handleAddCategory}>
          <AddItemButton text="Category" width="100%" id="add_new_category" />
        </div>
      ) : (
        <div className="add__category"></div>
      )}
      </Box>
      {/* delete alert dialog */}
      <DeleteConfirmationDialog
        open={deleteCategoryModal}
        handleClose={handleCloseDeleteAlert}
        title={"Delete category"}
        contentText={`Are you sure you want to delete the category ${activeDeleteCategory?.prompt}?`}
        cancelBtnTitle={"Cancel"}
        cancelBtnAction={handleCloseDeleteAlert}
        submitBtnTitle={"Delete"}
        submitBtnAction={handleConfirmDeleteCategory}
      />
      {/* delete dialog end */}
      {/* edit alert dialog */}
      <Modal
        open={showEditCategoryModal}
        onClose={handleCloseEditAlert}
        BackdropProps={{ style: { opacity: "60%" } }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="mark-as-complete-modal-main"
      >
        <Box
          className="mark-as-complete-modal"
          sx={{ minHeight: "201px !important", top: "40% !important" }}
        >
          <Grid container>
            <Grid item xs={12}>
              <CloseIcon
                id="close_edit_cat_modal"
                sx={{
                  float: "right",
                  cursor: "pointer",
                  color: "#A5A5A5",
                  mt: 1,
                  mr: 1,
                }}
                onClick={handleCloseEditAlert}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                textAlign: "left",
                marginBottom: "20px",
                marginLeft: "15px",
              }}
            >
              <p className="modal-heading">Edit category name </p>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl
                variant="standard"
                className="forms-control"
                style={{ paddingLeft: "15px" }}
              >
                <label className="input-form-label" htmlFor="CategoryName">
                  Category Name
                </label>
                <TextField
                  id="category_name"
                  className="input-textfield"
                  size="small"
                  variant="outlined"
                  placeholder="Category name"
                  type="text"
                  value={editCategoryName}
                  onChange={(e) => setEditCategoryName(e.target.value)}
                  style={{ width: "97%" }}
                />
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                textAlign: "center",
                mt: "14px",
                mb: "30px",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <FilledButton
                width="95%"
                text="Save"
                onClick={() => handleConfirmEditCategory(editCategoryName)}
                id="confirm_edit_cat_button"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* edit dialog end */}
    </motion.div>
  );
};

export default Inspection;
