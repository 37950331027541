import { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from 'framer-motion';
import { getDashboardValue,increamentValue,decreamentValue } from "../../Redux/Actions/DashboardActions";
import { Base64 } from "js-base64";
import { toast } from "react-hot-toast";
import { clearTemplateUpdateData, clearUpdatedInspectionData, getInspectionData } from "../../Redux/Actions/CommonActions";
import Attachments from "../../Components/Attachments/Attachments";
import CoverPhotoSection from "../../Components/CoverPhotoSection/CoverPhotoSection";
import Header from "../../Components/Header/Header";
// const Attachments = lazy(() => import("../../Components/Attachments/Attachments"));
// const CoverPhotoSection = lazy(() => import("../../Components/CoverPhotoSection/CoverPhotoSection"));
// const Header = lazy(() => import("../../Components/Header/Header"));

const Dashboard = () => {
  const[inspectionData,setInspectionData] = useState();

  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.CommonReducer);

  
  useEffect(() => {
    if(storeData.inspectionData){ 
      setInspectionData(storeData.inspectionData?.data)
    }
  },[storeData.inspectionData]);

  useEffect(() => {
    if(storeData.attachmentUpdate === true){ 
     if(storeData?.attachmentResponse?.success === true) {
      toast.success(storeData?.attachmentResponse?.message)
      let sessionAssociateCompanyKey = Base64.decode(sessionStorage.getItem('AC'));
      let guid = sessionStorage.getItem('guid')
      let sessionUserId = Base64.decode(sessionStorage.getItem('UI'));
      dispatch(getInspectionData({guid:guid,associate_company:sessionAssociateCompanyKey,user_id:sessionUserId}))
      dispatch(clearTemplateUpdateData())
    }
     else toast.error(storeData?.attachmentResponse?.message)
    }
  },[storeData.attachmentUpdate]);
  useEffect(() => {
    if(storeData.updatedInspectionData && storeData.updatedInspectionData !== '') {
      // if(storeData?.updatedInspectionData?.name === "AxiosError") toast.error(storeData?.updatedInspectionData?.response?.data?.message)
      // else toast.success(storeData?.updatedInspectionData?.message)
      dispatch(clearUpdatedInspectionData())
    }
  }, [storeData.updatedInspectionData])
  return (
    // <Suspense fallback={<Preloader showPreloader={true}/>}>
      <motion.div className="h_100 w_bg" initial={{ opacity: 0 }} animate={{ opacity: 1 }} >
     <Header title="Introduction" action='dashboard'/>      
     <div className="contents">
      <CoverPhotoSection inspectionData={inspectionData} />
      <Attachments inspectionData={inspectionData}/>
     </div>  
    </motion.div>
    // </Suspense>
  );
};

export default Dashboard;
