import { useEffect, useState } from "react";
import { Grid, SvgIcon, Typography, Divider, Modal, LinearProgress } from "@mui/material";
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { palmtechLogo, curve, curveEnd, ReportLibraryIcon } from "../../../Assets/Svg/Svg";
import { BorderLinearProgress, menuData } from "../../Common/MuiStyles/MuiStyles";
import "../../../Assets/Styles/Containers/_sidemenu.scss";
import Avatar from "@mui/material/Avatar";
import { portalUrl } from "../../Config/Api-constants";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import CallMissedOutgoingIcon from "@mui/icons-material/CallMissedOutgoing";
import { useDispatch, useSelector } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import { NavLink, Navigate, useLocation, useNavigate } from "react-router-dom";
import { categoryPercentCalculate, checkLineComplete, filterObjectsUptoCategory, filterObjectsUptoLine, getNonNarrativeTemplatesGuid, totalLineCountCalulate } from "../../Helpers/HelperFunctions";
import { editCategory, editLine, scrollTop, reorderCategory, reorderLines, clearActiveLine, activateLines, getInspectionData, getInspectionDataWithOutLoader } from "../../Redux/Actions/CommonActions";
import { ApiCallDuplicateCategory, ApiCallDuplicateLine, UpdateTemplateDataSpecific, getDefaultCategories, getDefaultLines, getMasterTemplates } from "../../Helpers/ApiCall";
import ISNTemplateModal from "../ISN/ISNTemplateModal";
import { Base64 } from "js-base64";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { GetUTCTime } from "../../Helpers/GetUTCTime";
import DeleteIcon from "@mui/icons-material/Delete";
import OutlinedButton from "../Button/OutlinedButton";
import FilledButton from "../Button/FilledButton";
import CloseIcon from "@mui/icons-material/Close";
import toast from "react-hot-toast";
import DeleteConfirmationDialog from "../../Common/Alert/DeleteConfirmationDialog";
import { v4 as uuidv4 } from "uuid";
import AddLineModal from "../Lines/AddLineModal";
import CreateNewLineModal from "../Lines/CreateNewLineModal";
import AddOptionalNdStandardLineModal from "../Lines/AddOptionalNdStandardLineModal";
import AddCategoryModal from "../Category/AddCategoryModal";
import CreateNewCategoryModal from "../Category/CreateNewCategoryModal";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { GrDuplicate } from "react-icons/gr";
import Skeleton from "@mui/material/Skeleton";
import { StartSharp } from "@mui/icons-material";
import CollectionsIcon from "@mui/icons-material/Collections";

const SideMenu = () => {
  const [active, setActive] = useState("");
  const [loader, setLoader] = useState(false);
  const [activeTemplate, setActiveTemplate] = useState("");
  const [activeLine, setActiveLine] = useState("");
  const [disableCatActive, setDisableCatActive] = useState(false);
  const [inspectionResponse, setInspectionResponse] = useState();
  const [showISNTemplateModal, setShowISNTemplateModal] = useState(false);
  const [showStaticBackground, setShowStaticBackground] = useState(false);
  const [disableSwitch, setDisableSwitch] = useState(false);
  const [staticBG, setStaticBG] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isInspectionDetailsHovered, setIsInspectionDetailsHovered] = useState(false);
  const [isCategoryHovered, setIsCategoryHovered] = useState(false);
  const [isHoveredLineUuid, setIsHoveredLineUuid] = useState("");
  const [isHoveredInspectionDetailsLineUuid, setIsHoveredInspectionDetailsLineUuid] = useState("");
  const [isHoveredCategoryUuid, setIsHoveredCategoryUuid] = useState("");
  const [deleteLineModal, setShowDeleteLineModal] = useState(false);
  const [activeDeleteLine, setActiveDeleteLine] = useState("");
  const [activeDeleteAndDuplicateTemplate, setActiveDeleteAndDuplicateTemplate] = useState("");
  const [activeDeleteAndDuplicateCategory, setActiveDeleteAndDuplicateCategory] = useState("");
  const [deleteCategoryModal, setDeleteCategoryModal] = useState(false);
  const [deleteCategoryToast, setDeleteCategoryToast] = useState(false);
  const [deleteCategoryApiCall, setDeleteCategoryApiCall] = useState(false);
  const [deleteLineToast, setDeleteLineToast] = useState(false);
  const [activeDeleteCategory, setActiveDeleteCategory] = useState("");
  const [activeDeleteCategoryIdx, setActiveDeleteCategoryIdx] = useState("");
  const [activeLineIdx, setActiveLineIdx] = useState("");
  const [showLineModal, setShowLineModal] = useState(false);
  const [showInspLineModal, setShowInspLineModal] = useState(false);
  const [showCreateNewLineModal, setShowCreateNewLineModal] = useState(false);
  const [selectedLines, setSelectedLines] = useState([]);
  const [newLineList, setNewLineList] = useState([]);
  const [categoryData, setCategoryData] = useState();
  const [newCategoryList, setNewCategoryList] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showCreateCategoryModal, setShowCreateCategoryModal] = useState(false);
  const [templateData, setTemplateData] = useState([]);
  const [tempDuplicateCategoryIdx, setTempDuplicateCategoryIdx] = useState("");
  const [tempDuplicateLineIdx, setTempDuplicateLineIdx] = useState("");
  const [duplicatedCategoryTemplateIdx, setDuplicatedCategoryTemplateIdx] = useState("");
  const [duplicateCategoryIdx, setDuplicateCategoryIdx] = useState("");
  const [duplicateCategoryIconClicked, setDuplicateCategoryIconClicked] = useState(false);
  const [duplicateLineIconClicked, setDuplicateLineIconClicked] = useState(false);
  const [categoryHighlighted, setCategoryHighlighted] = useState(false);
  const [lineHighlighted, setLineHighlighted] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state: urlState } = useLocation();
  let templateId = urlState?.template_id;
  let categoryId = urlState?.category_id;
  let templateGuid = urlState?.template_guid;
  const storeData = useSelector((state) => state.CommonReducer);
  const handleDuplicateLine = async (templateId, categoryUuid, line, e, lineIdx, categoryIdx) => {
    let duplicateTemplateData = storeData?.inspectionData?.data?.inspectiontemplates?.find((template) => template.template_id === templateId);
    let CategoryData = duplicateTemplateData?.categories?.find((itm) => itm.id === categoryId);
    let objectToAdd = {
      uuid: uuidv4(),
      prompt: "",
      showLineEmpty: true,
      comments: [],
      optional: 0,
      created_at: GetUTCTime(),
      is_deleted: 0,
      updated_at: "",
      defaultRating_id: 1,
      default_location: null,
      line_input_controls: [],
    };
    CategoryData?.lines.splice(lineIdx + 1, 0, objectToAdd);
    const formData = {
      guid: sessionStorage.getItem("guid"),
      inspection_id: storeData.inspectionData?.data?.inspection_id,
      template_id: templateId,
      category_uuid: categoryUuid,
      line_uuid: line?.uuid,
    };
    await ApiCallDuplicateLine(dispatch, formData);
    setLineHighlighted(true);
    setTempDuplicateLineIdx(lineIdx + 1);
    setDuplicateLineIconClicked(true);
    setDuplicateCategoryIdx(categoryIdx);
  };

  const handleDuplicateCategory = async (templateId, category, e, categoryIdx, templateIdx) => {
    let duplicateTemplateData = storeData?.inspectionData?.data?.inspectiontemplates?.find((template) => template.template_id === templateId);
    const objectToAdd = {
      id: category?.id,
      uuid: uuidv4(),
      template_id: templateId,
      showEmpty: true,
      prompt: null,
      introductory_text: null,
      lines: null,
      parent_uuid: null,
      order: 0,
      is_deleted: 0,
      optional: 0,
      is_inspection_details: 0,
      updated_at: GetUTCTime(),
      is_selected: false,
    };
    duplicateTemplateData?.categories.splice(categoryIdx + 1, 0, objectToAdd);
    const formData = {
      guid: sessionStorage.getItem("guid"),
      inspection_id: storeData.inspectionData?.data?.inspection_id,
      template_id: templateId,
      category_uuid: category.uuid,
    };
    await ApiCallDuplicateCategory(dispatch, formData);
    setCategoryHighlighted(true);
    setTempDuplicateCategoryIdx(categoryIdx + 1);
    setDuplicatedCategoryTemplateIdx(templateIdx);
    setDuplicateCategoryIconClicked(true);
  };

  const handleConfirmDeleteLine = async () => {
    let templateIndex = inspectionResponse?.inspectiontemplates?.findIndex((itm) => itm.template_id === activeDeleteAndDuplicateTemplate.template_id);
    let categoryIndex = inspectionResponse?.inspectiontemplates[templateIndex]?.categories?.findIndex((itm) => itm.id === activeDeleteAndDuplicateCategory?.id);
    let lineIndex = inspectionResponse?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines.findIndex((itm) => itm.uuid == activeDeleteLine.uuid);
    let prevData = inspectionResponse;
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].is_deleted = 1;
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines?.map(async (item, index) => {
      item.order = index + 1;
      item.updated_at = await GetUTCTime();
      return item;
    });
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].updated_at = await GetUTCTime();
    setDeleteLineToast(true);
    let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
    UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
    setShowDeleteLineModal(false);
    activeHandle(activeDeleteAndDuplicateCategory?.uuid);
    setActive(activeDeleteAndDuplicateCategory?.uuid);
    navigate(`/inspection/${activeDeleteAndDuplicateCategory.prompt !== null && activeDeleteAndDuplicateCategory.prompt !== "" ? encodeURIComponent(activeDeleteAndDuplicateCategory.prompt) : "Untitled"}`, {
      state: {
        activeDeleteAndDuplicateCategory,
        template: activeDeleteAndDuplicateTemplate,
        template_id: activeDeleteAndDuplicateTemplate?.template_id,
        category_id: activeDeleteAndDuplicateCategory.id,
        category_uuid: activeDeleteAndDuplicateCategory.uuid,
      },
    });
    setDeleteCategoryApiCall(true);
  };

  const handleConfirmDeleteCategory = async (e) => {
    let templateIndex = inspectionResponse?.inspectiontemplates?.findIndex((itm) => itm.template_id === activeDeleteAndDuplicateTemplate.template_id);
    let categoryIndex = inspectionResponse?.inspectiontemplates[templateIndex]?.categories?.findIndex((itm) => itm.id === activeDeleteCategory?.id);
    let prevData = inspectionResponse;
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].is_deleted = 1;
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].updated_at = await GetUTCTime();
    let syncCategory = [prevData.inspectiontemplates[templateIndex].categories[categoryIndex]];
    let deletedCatArray = JSON.parse(JSON.stringify(prevData));
    deletedCatArray.inspectiontemplates[templateIndex].categories = syncCategory;
    let filteredObject = filterObjectsUptoCategory(prevData, templateIndex, categoryIndex);
    UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
    setDeleteCategoryModal(false);
    setDeleteCategoryToast(true);
    navigate("/inspection", {
      state: {
        template: activeDeleteAndDuplicateTemplate,
        template_id: activeDeleteAndDuplicateTemplate?.template_id,
      },
    });
  };

  const callInitiateAPIWithOutLoader = async () => {
    let sessionAssociateCompanyKey = await Base64.decode(sessionStorage.getItem("AC"));
    let guid = await sessionStorage.getItem("guid");
    let sessionUserId = await Base64.decode(sessionStorage.getItem("UI"));
    await dispatch(
      getInspectionDataWithOutLoader({
        guid: guid,
        associate_company: sessionAssociateCompanyKey,
        user_id: sessionUserId,
      })
    );
  };

  const fetchData = async () => {
    let isMounted = true;
    await callInitiateAPIWithOutLoader()
      .then(() => {
        if (isMounted) {
          setTimeout(() => {
            setDuplicateCategoryIconClicked(false);
            setDuplicateLineIconClicked(false);
            setLineHighlighted(false);
            setCategoryHighlighted(false);
          }, 2000);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    return () => {
      isMounted = false;
    };
  };
  useEffect(() => {
    if ((storeData?.inspectionData && storeData?.duplicateCategoryResponse) || (storeData?.inspectionData && storeData?.duplicateLineResponse)) {
      fetchData();
    }
  }, [storeData?.inspectionData && storeData?.duplicateCategoryResponse, storeData?.inspectionData && storeData?.duplicateLineResponse]);

  const handleCloseDeleteAlert = () => {
    setShowDeleteLineModal(false);
  };

  const handleLineDelete = (line, lineIdx, category, template) => {
    setActiveDeleteAndDuplicateCategory(category);
    setActiveDeleteAndDuplicateTemplate(template);
    setActiveDeleteLine(line);
    setShowDeleteLineModal(true);
    setActiveLineIdx(lineIdx);
  };

  const handleCategoryDelete = (category, e, categoryIdx) => {
    setDeleteCategoryModal(true);
    setActiveDeleteCategory(category);
    setActiveDeleteCategoryIdx(categoryIdx);
  };

  const handleCloseCategoryDeleteAlert = () => {
    setDeleteCategoryModal(false);
  };

  const activeHandle = (currentActive) => {
    if (active === currentActive) {
      setDisableSwitch(true);
      setActive("");
    } else {
      setDisableSwitch(false);
      setActive(currentActive);
    }
    setActiveLine("");
    setDisableCatActive(false);
    setActiveTemplate("");
    dispatch(activateLines(""));
  };
  const activeHandleLine = (currentActive) => {
    setDisableSwitch(false);
    setDisableCatActive(true);
    if (active === currentActive) setActiveLine("");
    else {
      dispatch(activateLines(currentActive));
    }
    setActiveTemplate("");
  };
  const activeTemplateHandle = (active) => {
    setDisableSwitch(false);
    setDisableCatActive(false);
    setActiveTemplate(active);
    setActive("");
    setActiveLine("");
  };

  const clearEditStatuses = () => {
    dispatch(editCategory(false));
    dispatch(reorderCategory(false));
    dispatch(reorderLines(false));
    dispatch(editLine(false));
    dispatch(scrollTop(true));
    dispatch(clearActiveLine());
  };

  const handleAddCategory = (templateGuid, templateid) => {
    setNewCategoryList([]);
    getDefaultCategories(dispatch, templateGuid);
    setShowCategoryModal(true);
    setSelectedCategories([]);
    setTemplateData(storeData?.inspectionData?.data?.inspectiontemplates?.find((template) => template.template_id === templateid));
  };

  const handleCategorySelection = (category) => {
    if (category.is_selected === false) category.is_selected = true;
    if (selectedCategories.length === 0) {
      setSelectedCategories([category]);
    } else {
      setSelectedCategories((prev) => {
        let catIndex = prev.findIndex((item) => item.uuid === category.uuid);
        if (catIndex !== -1) {
          return prev.filter((prevCategory) => prevCategory.uuid !== category.uuid);
        } else return [...prev, category];
      });
    }
  };

  const handleLineSelection = (line, lineType) => {
    line.optional = 0;
    line.uuid = uuidv4();
    if (selectedLines.length === 0) {
      setSelectedLines([line]);
    } else {
      setSelectedLines((prev) => {
        let catIndex = prev.findIndex((item) => item.uuid === line.uuid);
        if (catIndex !== -1) {
          return prev.filter((prevCategory) => prevCategory.uuid !== line.uuid);
        } else return [...prev, line];
      });
    }
  };

  const handleAddLine = (isInspDetails, parentUuid) => {
    if (isInspDetails) {
      setShowInspLineModal(true);
    } else {
      if (parentUuid !== null) {
        getDefaultLines(dispatch, {
          templateGuid: storeData.inspectionData?.data?.inspectiontemplates?.find((template) => template.template_id === templateId)?.template_guid,
          parentUuid: storeData?.inspectionData?.data?.inspectiontemplates?.find((template) => template.template_id === templateId)?.categories?.find((category) => category.id === categoryId)
            ?.parent_uuid,
        });
      } else {
        setNewLineList([]);
      }
      setShowLineModal(true);
    }
    setSelectedLines([]);
  };

  let PORTAL_URL = portalUrl();
  useEffect(() => {
    if (storeData.inspectionData) {
      setInspectionResponse(storeData.inspectionData?.data);
      storeData.inspectionData?.data?.order_id !== undefined && sessionStorage.setItem("orderId", storeData.inspectionData?.data?.order_id);
      storeData.inspectionData?.data?.location_address !== undefined && sessionStorage.setItem("location_address", storeData.inspectionData?.data?.location_address);
      storeData.inspectionData?.data?.location_address_2 !== undefined && sessionStorage.setItem("location_address_2", storeData.inspectionData?.data?.location_address_2);
      storeData.inspectionData?.data?.location_city !== undefined && sessionStorage.setItem("location_city", storeData.inspectionData?.data?.location_city);
      storeData.inspectionData?.data?.location_state !== undefined && sessionStorage.setItem("location_state", storeData.inspectionData?.data?.location_state);
      storeData.inspectionData?.data?.location_zip !== undefined && sessionStorage.setItem("location_zip", storeData.inspectionData?.data?.location_zip);
      storeData.inspectionData?.data?.user_displayname !== undefined && sessionStorage.setItem("user_displayname", storeData.inspectionData?.data?.user_displayname);
      storeData.inspectionData?.data?.user_email !== undefined && sessionStorage.setItem("user_email", storeData.inspectionData?.data?.user_email);
      storeData.inspectionData?.data?.company?.logo !== undefined && sessionStorage.setItem("logo", storeData.inspectionData?.data?.company?.logo);
    }
  }, [storeData.inspectionData]);
  useEffect(() => {
    if (storeData.inspectionData?.data?.get_templates && storeData.inspectionData?.data?.get_templates === 1) {
      getMasterTemplates(dispatch);
      setShowISNTemplateModal(true);
      setShowStaticBackground(true);
    } else setShowStaticBackground(false);
    if (storeData?.staticBG === true || storeData?.staticBG === undefined) setStaticBG(true);
    else setStaticBG(false);
  }, [storeData.inspectionData?.data]);

  useEffect(() => {
    setActiveTemplate(urlState?.template?.template_id);
    if (urlState?.category !== undefined || urlState?.pdfcategory !== undefined)
      disableSwitch ? setActive("") : urlState.isPDF !== 1 ? setActive(urlState?.category?.uuid) : setActive(urlState?.pdfcategory?.uuid);
    setDisableSwitch(false);
  }, [urlState]);

  useEffect(() => {
    if (storeData?.updatedInspectionData?.success === true && deleteLineToast) {
      toast.success("The category has been updated successfully.");
      setDeleteLineToast(false);
    }
    if (storeData?.updatedInspectionData?.success === true && deleteCategoryToast) {
      toast.success("Category has been deleted.");
      setDeleteCategoryToast(false);
    }
  }, [handleConfirmDeleteCategory, handleConfirmDeleteLine]);

  useEffect(() => {
    if (storeData.inspectionData) {
      const tempData = storeData?.inspectionData?.data?.inspectiontemplates?.find((template) => template.template_id === templateId);
      setCategoryData(storeData?.inspectionData?.data?.inspectiontemplates?.find((template) => template.template_id === templateId)?.categories?.find((category) => category.id === categoryId));
    }
  }, [storeData.inspectionData, urlState]);

  // optional and standard categories
  useEffect(() => {
    if (storeData.defaultLineData) {
      setNewLineList(storeData.defaultLineData?.length !== 0 ? storeData.defaultLineData?.data : []);
    }
  }, [storeData.defaultLineData]);

  // optional and standard categories
  useEffect(() => {
    if (storeData.defaultCategoryList) {
      setNewCategoryList(storeData.defaultCategoryList?.length !== 0 ? storeData.defaultCategoryList?.data : []);
    }
  }, [storeData.defaultCategoryList]);

  const validUrl = useLocation()?.pathname;
  const isReportMediaLibraryUrl = validUrl === "/report_media_library";
  let activeLineCondition = !disableCatActive && (storeData?.activeLineUuid === "" || storeData?.activeLineUuid === undefined);
  // let duplicateCategoryClassCondition = duplicateCategoryIdx == categoryIdx && tempDuplicateLineIdx === lineIdx && !line?.showLineEmpty
  let reportMediaPercentage = storeData?.successfulBulkUploadMediaLength == 0 && storeData?.failBulkUploadMediaLength > 0 ? 0 : `${((storeData?.successfulBulkUploadMediaLength / storeData?.bulkUploadMediaLength) * 100)}`

  return (
    <>
      <AddLineModal
        showInspLineModal={showInspLineModal}
        setShowInspLineModal={setShowInspLineModal}
        templateId={templateId}
        categoryId={categoryId}
        isInspDetails={categoryData?.is_inspection_details}
      />
      <CreateNewLineModal
        showCreateNewLineModal={showCreateNewLineModal}
        setShowCreateNewLineModal={setShowCreateNewLineModal}
        setShowLineModal={setShowLineModal}
        templateId={templateId}
        categoryId={categoryId}
        isInspDetails={categoryData?.is_inspection_details}
      />
      <AddOptionalNdStandardLineModal
        showLineModal={showLineModal}
        setShowLineModal={setShowLineModal}
        templateId={templateId}
        categoryId={categoryId}
        isInspDetails={categoryData?.is_inspection_details}
        newLineList={newLineList}
        selectedLines={selectedLines}
        setSelectedLines={setSelectedLines}
        handleLineSelection={handleLineSelection}
        setShowCreateNewLineModal={setShowCreateNewLineModal}
      />
      <AddCategoryModal
        setLoader={setLoader}
        newCategoryList={newCategoryList}
        templateData={templateData}
        showCategoryModal={showCategoryModal}
        setShowCategoryModal={setShowCategoryModal}
        showCreateCategoryModal={showCreateCategoryModal}
        setShowCreateCategoryModal={setShowCreateCategoryModal}
        handleCategorySelection={handleCategorySelection}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
      />
      <CreateNewCategoryModal
        newCategoryList={newCategoryList}
        setNewCategoryList={setNewCategoryList}
        templateData={templateData}
        showCreateCategoryModal={showCreateCategoryModal}
        setShowCreateCategoryModal={setShowCreateCategoryModal}
        showCategoryModal={showCategoryModal}
        setShowCategoryModal={setShowCategoryModal}
        handleCategorySelection={handleCategorySelection}
      />
      <Grid className="component h_100 flex_props sticky">
        <ISNTemplateModal showISNTemplateModal={showISNTemplateModal} setShowISNTemplateModal={setShowISNTemplateModal} />
        <Grid className="sidemenu">
          <div className="company__logo">
            {showStaticBackground || staticBG ? (
              staticBG ? (
                ""
              ) : (
                <img className="dynamic_logo" src="https://cloudfront.reportwriter.palmtech.dev/ISN/ISN-logo.png" alt="logo" />
              )
            ) : sessionStorage.getItem("logo") !== "null" ? (
              <img className="dynamic_logo" src={sessionStorage.getItem("logo")} alt="logo" />
            ) : (
              ""
            )}
          </div>
          <div className="company_adress">
            {showStaticBackground || staticBG ? (
              <>
                <Typography>----------</Typography>
                <Typography>
                  <b>---------------------</b>
                </Typography>
              </>
            ) : (
              <>
                <Typography>Order #{sessionStorage.getItem("orderId")}</Typography>
                <Typography>
                  {sessionStorage.getItem("location_address") !== "null" && sessionStorage.getItem("location_address") !== "" ? `${sessionStorage.getItem("location_address")},` : ""}{" "}
                  {sessionStorage.getItem("location_address_2") !== "null" && sessionStorage.getItem("location_address_2") !== "" ? `${sessionStorage.getItem("location_address_2")},` : ""}{" "}
                  {`${sessionStorage.getItem("location_city")} `}
                  {`${sessionStorage.getItem("location_state")} `?.toUpperCase()} {sessionStorage.getItem("location_zip")}
                </Typography>
              </>
            )}
          </div>
          <div className="progress__section">
            <div className="is__relative">
              <Typography>Total Progress</Typography>
              <Typography>
                {totalLineCountCalulate(inspectionResponse?.inspectiontemplates)?.overallLinesCompleted}/{totalLineCountCalulate(inspectionResponse?.inspectiontemplates)?.overallLinesPresent}
                {/* 54/95 */}
              </Typography>
              <BorderLinearProgress
                variant="determinate"
                value={totalLineCountCalulate(inspectionResponse?.inspectiontemplates)?.overallPrecent}
              />
            </div>
          </div>
          <div className="menu__section">
            <div className="overview__section">
              <Typography sx={{ textTransform: "uppercase" }}>Inspection overview</Typography>
              <Divider style={{ marginBottom: "10px" }} />
              <NavLink className="navlink" to={{ pathname: `/` }} style={{ textDecoration: "none" }} id="introduction_">
                <Typography className="list__item">
                  <HomeOutlinedIcon />
                  <span className="list__text">Introduction</span>
                </Typography>
              </NavLink>
              <NavLink className="navlink" to={{ pathname: `/report_media_library` }} style={{ textDecoration: "none" }}>
                <Grid container className="list__item" sx={{ backgroundColor: isReportMediaLibraryUrl && "#4282e2 !important", display: "flex", }}>
                  <Grid item xs={0.7}>
                    <ReportLibraryIcon validUrl={validUrl} />
                  </Grid>
                  <Grid item xs={6}>
                    <span className="list__text" style={{ color: isReportMediaLibraryUrl ? "white" : "#4282e2" }}>Report Media Library</span>
                  </Grid>
                  <Grid item xs={4} display={"flex"} justifyContent={"flex-end"} gap={1} alignItems={"center"}>
                    {reportMediaPercentage == "NaN" ? undefined : <BorderLinearProgress
                      sx={{
                        width: '80% !important',
                        height: '10px !important',
                        backgroundColor: 'transparent !important', // Ensure transparency
                        opacity: 1,
                        '& .MuiLinearProgress-bar': {
                          backgroundColor: isReportMediaLibraryUrl ? 'white !important' : '#4282e2 !important',
                          opacity: 1,
                        },
                      }}
                      variant="determinate"
                      value={reportMediaPercentage}
                    />}
                    {reportMediaPercentage == "NaN" ? undefined : <div style={{ color: isReportMediaLibraryUrl ? "white" : "#4282e2", marginRight: "-18px", fontWeight: 600 }}>
                      {`${parseInt(reportMediaPercentage)}%`}
                    </div>}
                  </Grid>

                </Grid>



              </NavLink>
              <NavLink className="navlink" to={{ pathname: `/review` }} style={{ textDecoration: "none" }}>
                <Typography className="list__item">
                  <InsertDriveFileOutlinedIcon />
                  <span className="list__text">Review</span>
                </Typography>
              </NavLink>
            </div>
            <div className="report__section">
              <Typography sx={{ textTransform: "uppercase" }}>Report</Typography>
              <Divider style={{ marginBottom: "10px" }} />
            </div>
            <div style={{ overflow: "hidden" }}>
              <Box sx={{ overflow: "auto" }}>
                {showStaticBackground || staticBG ? (
                  <List disablePadding className="menu__items" style={{ marginBottom: "35vh" }}>
                    <NavLink className={`navlink`} style={{ textDecoration: "none" }}>
                      <ListItem disablePadding className="list__item">
                        <ListItemButton disableRipple>
                          <ListItemIcon>
                            <AssignmentOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText className="list__text" primary={"No template added yet"} />
                        </ListItemButton>
                      </ListItem>
                    </NavLink>
                  </List>
                ) : (
                  <>
                    {inspectionResponse?.inspectiontemplates?.map(
                      (template, templateIdx) =>
                        template?.is_pdf_template !== 1 && template?.is_narrative_template !== 1 ? (
                          <div key={templateIdx}>
                            <List key={templateIdx} disablePadding className="menu__items">
                              <NavLink
                                className={`navlink ${activeTemplate === template.template_id ? "active_template" : ""}`}
                                to={{ pathname: `/inspection` }}
                                state={{ template, template_id: template.template_id, tempIndex: templateIdx }}
                                style={{ textDecoration: "none" }}
                                onClick={() => clearEditStatuses()}
                                id={`template_${templateIdx + 1}`}
                              >
                                <ListItem disablePadding className="list__item" onClick={() => activeTemplateHandle(template.template_id)}>
                                  <ListItemButton disableRipple>
                                    <ListItemIcon>
                                      <AssignmentOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText className="list__text" primary={template.name} />
                                  </ListItemButton>
                                </ListItem>
                              </NavLink>
                            </List>
                            <div className="menu__categories__section">
                              {template.categories?.map((category, categoryIdx) =>
                                category.is_deleted !== 1 ? (
                                  <div key={categoryIdx} style={{ marginBottom: "4px" }}>
                                    {category?.is_inspection_details === 1 && getNonNarrativeTemplatesGuid(inspectionResponse?.inspectiontemplates)?.[0] !== template.template_guid ? (
                                      ""
                                    ) : (
                                      <>
                                        <List
                                          key={categoryIdx}
                                          disablePadding
                                          className={duplicatedCategoryTemplateIdx === templateIdx && tempDuplicateCategoryIdx === categoryIdx && !category?.showEmpty && "duplicated_category"}
                                          sx={{
                                            animation:
                                              duplicatedCategoryTemplateIdx === templateIdx &&
                                              categoryHighlighted &&
                                              tempDuplicateCategoryIdx === categoryIdx &&
                                              category?.showEmpty &&
                                              "fadeIn 0.5s ease,increaseHeight 0.5s forwards",
                                          }}
                                        >
                                          <NavLink
                                            className={`navlink ${active === category.uuid && activeLineCondition ? "active_category" : ""
                                              }`}
                                            to={{ pathname: `/inspection/${category.prompt !== null && category?.prompt !== "" ? encodeURIComponent(category.prompt) : "Untitled"}` }}
                                            state={{ category, template_id: template.template_id, category_id: category.id, category_uuid: category.uuid }}
                                            style={{ textDecoration: "none" }}
                                            onClick={() => {
                                              clearEditStatuses();
                                              setActiveDeleteAndDuplicateCategory(category);
                                              setActiveDeleteAndDuplicateTemplate(template);
                                            }}
                                            id={`category_${categoryIdx}`}
                                            onMouseEnter={() => {
                                              if (category.is_inspection_details !== 2 && !category?.showEmpty) {
                                                setIsCategoryHovered(true);
                                                setIsHoveredCategoryUuid(category?.uuid);
                                              }
                                            }}
                                            onMouseLeave={() => {
                                              if (category.is_inspection_details !== 2 && !category?.showEmpty) {
                                                setIsCategoryHovered(false);
                                              }
                                            }}
                                          >
                                            <ListItem disablePadding className="categories__list">
                                              <ListItemButton disableRipple>
                                                {duplicatedCategoryTemplateIdx === templateIdx && categoryHighlighted && tempDuplicateCategoryIdx === categoryIdx ? (
                                                  <Skeleton variant="text" sx={{ width: "100%", paddingBottom: "1.5rem", marginBottom: "1.5rem", marginTop: "-0.75rem" }} />
                                                ) : (
                                                  <>
                                                    <div className="list__content" onClick={() => activeHandle(category.uuid)}>
                                                      <span
                                                        className={
                                                          duplicatedCategoryTemplateIdx === templateIdx &&
                                                          categoryHighlighted &&
                                                          tempDuplicateCategoryIdx === categoryIdx &&
                                                          !category?.showEmpty &&
                                                          "pulse-once"
                                                        }
                                                      >
                                                        {category.prompt !== null && category?.prompt !== "" ? category.prompt : category?.showEmpty ? "" : "Untitled"}
                                                      </span>
                                                      {category.is_inspection_details == 1 ? (
                                                        <span className="progress_count">
                                                          {categoryPercentCalculate(category)?.lineComplete}/{categoryPercentCalculate(category)?.totalLines}
                                                        </span>
                                                      ) : (
                                                        (isHoveredCategoryUuid !== category?.uuid || !isCategoryHovered) && (
                                                          <>
                                                            {category?.showEmpty ? (
                                                              ""
                                                            ) : (
                                                              <span className="progress_count">
                                                                {categoryPercentCalculate(category)?.lineComplete}/{categoryPercentCalculate(category)?.totalLines}
                                                              </span>
                                                            )}
                                                          </>
                                                        )
                                                      )}
                                                    </div>
                                                    {category.is_inspection_details !== 1
                                                      ? isCategoryHovered &&
                                                      isHoveredCategoryUuid === category?.uuid && (
                                                        <>
                                                          {duplicateCategoryIconClicked ? (
                                                            <span
                                                              className="DuplicateIcon"
                                                              onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                              }}
                                                            >
                                                              <GrDuplicate />
                                                            </span>
                                                          ) : (
                                                            <span
                                                              className="DuplicateIcon"
                                                              onClick={(e) => {
                                                                handleDuplicateCategory(template?.template_id, category, e, categoryIdx, templateIdx);
                                                                e.preventDefault();
                                                              }}
                                                            >
                                                              <GrDuplicate />
                                                            </span>
                                                          )}
                                                          <span
                                                            className="DeleteIcon"
                                                            onClick={(e) => {
                                                              handleCategoryDelete(category, e, categoryIdx);
                                                              e.preventDefault();
                                                            }}
                                                          >
                                                            <DeleteIcon />
                                                          </span>
                                                        </>
                                                      )
                                                      : ""}
                                                  </>
                                                )}
                                                {/* <ListItemText primary={category.prompt} /> */}
                                              </ListItemButton>
                                            </ListItem>
                                          </NavLink>
                                        </List>
                                      </>
                                    )}

                                    <List disablePadding className={`${active === category.uuid ? "menu__line__section ul_active" : "menu__line__section"}`}>
                                      {category.lines?.map((line, lineIdx) => {
                                        let duplicateCategoryClassCondition = duplicateCategoryIdx == categoryIdx && tempDuplicateLineIdx === lineIdx && !line?.showLineEmpty
                                        return (line.is_deleted !== 1 ? (
                                          active === category.uuid ? (
                                            category.is_inspection_details !== 1 ? (
                                              <NavLink
                                                className={`navlink ${activeLine === line.uuid || storeData?.activeLineUuid === line.uuid ? "active_line" : ""}`}
                                                to={{
                                                  pathname: `/inspection/${category.prompt !== null  && category?.prompt !== "" ? encodeURIComponent(category.prompt) : "Untitled"}/${line.prompt !== null && line?.prompt !== "" ? encodeURIComponent(line.prompt) : "Untitled"
                                                    }`,
                                                }}
                                                state={{
                                                  line,
                                                  template_id: template.template_id,
                                                  category_id: category.id,
                                                  category_uuid: category.uuid,
                                                  line_uuid: line.uuid,
                                                  template_guid: template.template_guid,
                                                }}
                                                style={{ textDecoration: "none" }}
                                                onClick={() => {
                                                  clearEditStatuses();
                                                  setActiveDeleteAndDuplicateTemplate(template);
                                                  setActiveDeleteAndDuplicateCategory(category);
                                                }}
                                                onMouseEnter={(e) => {
                                                  !line?.showLineEmpty && setIsHovered(true);
                                                  setIsHoveredLineUuid(line.uuid);
                                                }}
                                                onMouseLeave={() => {
                                                  !line?.showLineEmpty && setIsHovered(false);
                                                }}
                                              >
                                                <ListItem
                                                  disablePadding
                                                  className={`${category.lines.length > lineIdx + 1 && "border__list "} is__relative menu__line__list`}
                                                  onClick={() => activeHandleLine(line.uuid)}
                                                >
                                                  <SvgIcon className="curve" viewBox="">
                                                    {category.lines.length > lineIdx + 1 ? curve : curveEnd}
                                                  </SvgIcon>
                                                  {!deleteCategoryModal && (
                                                    <ListItemButton disableRipple>
                                                      {line?.showLineEmpty && duplicateCategoryIdx == categoryIdx && lineHighlighted ? (
                                                        <>
                                                          {lineHighlighted && tempDuplicateLineIdx === lineIdx && line?.showLineEmpty && (
                                                            <Skeleton variant="text" sx={{ width: "100%", height: "2.5rem", display: "block", marginLeft: "14px" }} />
                                                          )}
                                                          <div
                                                            className={
                                                              duplicateCategoryIdx == categoryIdx && lineHighlighted && tempDuplicateLineIdx === lineIdx && line?.showLineEmpty && "animation-line"
                                                            }
                                                            style={{
                                                              animation:
                                                                duplicateCategoryIdx == categoryIdx &&
                                                                lineHighlighted &&
                                                                tempDuplicateLineIdx === lineIdx &&
                                                                line?.showLineEmpty &&
                                                                "fadeIn 0.5s ease,increaseHeight 0.5s forwards",
                                                            }}
                                                          >
                                                            {" "}
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <div
                                                          className={`list__content line__list__padding ${duplicateCategoryClassCondition ? "duplicated_line" : ""
                                                            }`}
                                                          style={{
                                                            animation: lineHighlighted && tempDuplicateLineIdx === lineIdx && line?.showLineEmpty && "fadeIn 0.5s ease,increaseHeight 0.5s forwards",
                                                          }}
                                                        >
                                                          <span style={{ width: "70%" }} className={lineHighlighted && tempDuplicateLineIdx === lineIdx && !line?.showLineEmpty && "pulse-once"}>
                                                            {" "}
                                                            {line.prompt !== null && line?.prompt !== "" ? line.prompt : "Untitled"}
                                                          </span>
                                                          <span className="progress_count" style={{ display: "flex" }}>
                                                            <CheckCircleIcon
                                                              style={{ visibility: (isHoveredLineUuid !== line.uuid || !isHovered) && checkLineComplete(line, "normal") ? "visible" : "hidden" }}
                                                            />
                                                            {isHovered && isHoveredLineUuid === line.uuid && (
                                                              <>
                                                                {duplicateLineIconClicked ? (
                                                                  <span
                                                                    className="DuplicateIcon"
                                                                    style={{ display: "flex" }}
                                                                    onClick={(e) => {
                                                                      e.preventDefault();
                                                                      e.stopPropagation();
                                                                    }}
                                                                  >
                                                                    <GrDuplicate />
                                                                  </span>
                                                                ) : (
                                                                  <span
                                                                    className="DuplicateIcon"
                                                                    style={{ display: "flex" }}
                                                                    onClick={(e) => {
                                                                      handleDuplicateLine(template?.template_id, category?.uuid, line, e, lineIdx, categoryIdx);
                                                                      e.preventDefault();
                                                                    }}
                                                                  >
                                                                    <GrDuplicate />
                                                                  </span>
                                                                )}
                                                                <span
                                                                  style={{ display: "flex" }}
                                                                  className="DeleteIcon"
                                                                  onClick={(e) => {
                                                                    handleLineDelete(line, lineIdx, category, template);
                                                                    e.preventDefault();
                                                                  }}
                                                                >
                                                                  <DeleteIcon />
                                                                </span>
                                                              </>
                                                            )}
                                                          </span>
                                                        </div>
                                                      )}
                                                      {/* <ListItemText primary={line.prompt} /> */}
                                                    </ListItemButton>
                                                  )}
                                                </ListItem>
                                              </NavLink>
                                            ) : (
                                              <ListItem
                                                disablePadding
                                                className={`${category.lines.length > lineIdx + 1 && "border__list "} is__relative menu__line__list`}
                                                onMouseEnter={(e) => {
                                                  setIsInspectionDetailsHovered(true);
                                                  setIsHoveredInspectionDetailsLineUuid(line.uuid);
                                                }}
                                                onMouseLeave={() => setIsInspectionDetailsHovered(false)}
                                              >
                                                <SvgIcon className="curve" viewBox="">
                                                  {category.lines.length > lineIdx + 1 ? curve : curveEnd}
                                                </SvgIcon>
                                                <ListItemButton disableRipple>
                                                  {line?.showLineEmpty && duplicateCategoryIdx == categoryIdx ? (
                                                    <>
                                                      {lineHighlighted && tempDuplicateLineIdx === lineIdx && line?.showLineEmpty && (
                                                        <Skeleton variant="text" sx={{ width: "100%", height: "2.5rem", display: "block", marginLeft: "14px" }} />
                                                      )}
                                                      <div
                                                        className={lineHighlighted && tempDuplicateLineIdx === lineIdx && line?.showLineEmpty && "animation-line"}
                                                        style={{
                                                          animation: lineHighlighted && tempDuplicateLineIdx === lineIdx && line?.showLineEmpty && "fadeIn 0.5s ease,increaseHeight 0.5s forwards",
                                                        }}
                                                      >
                                                        {" "}
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <div
                                                      className={`list__content line__list__padding ${duplicateCategoryClassCondition ? "duplicated_line" : ""
                                                        }`}
                                                      style={{
                                                        animation: lineHighlighted && tempDuplicateLineIdx === lineIdx && line?.showLineEmpty && "fadeIn 0.5s ease,increaseHeight 0.5s forwards",
                                                      }}
                                                    >
                                                      <span>{line.prompt}</span>{" "}
                                                      <span className="progress_count">
                                                        <CheckCircleIcon
                                                          style={{
                                                            visibility:
                                                              (!isInspectionDetailsHovered || isHoveredInspectionDetailsLineUuid !== line.uuid) && checkLineComplete(line, "inspectionDetails")
                                                                ? "visible"
                                                                : "hidden",
                                                          }}
                                                        />
                                                        {isInspectionDetailsHovered && isHoveredInspectionDetailsLineUuid === line.uuid && (
                                                          <>
                                                            {duplicateLineIconClicked ? (
                                                              <span
                                                                className="DuplicateIcon"
                                                                onClick={(e) => {
                                                                  e.preventDefault();
                                                                  e.stopPropagation();
                                                                }}
                                                              >
                                                                <GrDuplicate />
                                                              </span>
                                                            ) : (
                                                              <span
                                                                className="DuplicateIcon"
                                                                onClick={(e) => {
                                                                  handleDuplicateLine(template?.template_id, category?.uuid, line, e, lineIdx, categoryIdx);
                                                                }}
                                                              >
                                                                <GrDuplicate />
                                                              </span>
                                                            )}
                                                            <span
                                                              className="DeleteIcon"
                                                              onClick={(e) => {
                                                                handleLineDelete(line, lineIdx, category, template);
                                                                e.preventDefault();
                                                              }}
                                                            >
                                                              <DeleteIcon />
                                                            </span>
                                                          </>
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}
                                                  {/* <ListItemText primary={line.prompt} /> */}
                                                </ListItemButton>
                                              </ListItem>
                                            )
                                          ) : (
                                            <>
                                              {lineHighlighted && tempDuplicateLineIdx === lineIdx && line?.showLineEmpty && (
                                                <Skeleton variant="text" sx={{ width: "100%", height: "2.5rem", display: "block", marginLeft: "14px" }} />
                                              )}
                                              <div
                                                className={activeLineIdx === lineIdx && "animation-DeleteCategory"}
                                                style={{ animation: activeLineIdx === lineIdx && "fadeIn 0.5s ease, decreseLineHeight 0.5s forwards" }}
                                              >
                                                {" "}
                                              </div>
                                            </>
                                          )
                                        ) : (
                                          <>
                                            {lineHighlighted && tempDuplicateLineIdx === lineIdx && line?.showLineEmpty && (
                                              <Skeleton variant="text" sx={{ width: "100%", height: "2.5rem", display: "block", marginLeft: "14px" }} />
                                            )}
                                            <div
                                              className={activeLineIdx === lineIdx && "animation-DeleteCategory"}
                                              style={{ animation: activeLineIdx === lineIdx && "fadeIn 0.5s ease, decreseLineHeight 0.5s forwards" }}
                                            >
                                              {" "}
                                            </div>
                                          </>
                                        ))
                                      }
                                      )}
                                      {category?.is_inspection_details == 2
                                        ? ""
                                        : active == category?.uuid && (
                                          <div onClick={() => handleAddLine(categoryData?.is_inspection_details, categoryData?.parent_uuid)} className="sidemenu_add_category_button">
                                            <AddCircleIcon /> <span style={{ marginLeft: "4px" }}>New Line</span>{" "}
                                          </div>
                                        )}
                                    </List>
                                  </div>
                                ) : (
                                  <div
                                    className={activeDeleteCategoryIdx === categoryIdx && "animation-DeleteCategory"}
                                    style={{ animation: activeDeleteCategoryIdx === categoryIdx && "fadeIn 0.5s ease, decreseLineHeight 0.5s forwards" }}
                                  >
                                    {" "}
                                  </div>
                                )
                              )}
                              <div onClick={() => handleAddCategory(template?.template_guid, template?.template_id)} className="sidemenu_add_category_button">
                                <AddCircleIcon /> <span style={{ marginLeft: "4px" }}>New Category</span>{" "}
                              </div>
                            </div>
                            {menuData.length > templateIdx + 1 && <Divider style={{ marginBottom: "15px", marginTop: "10px" }} />}
                          </div>
                        ) : template?.is_pdf_template === 1 ? (
                          <div key={templateIdx}>
                            <List key={templateIdx} disablePadding className="menu__items">
                              <NavLink
                                className={`navlink ${activeTemplate === template.template_id ? "active_template" : ""}`}
                                to={{ pathname: `/inspection` }}
                                state={{ template, template_id: template?.template_id, tempIndex: templateIdx, isPDF: 1 }}
                                style={{ textDecoration: "none" }}
                                onClick={() => clearEditStatuses()}
                                id={`template_${templateIdx + 1}`}
                              >
                                <ListItem disablePadding className="list__item" onClick={() => activeTemplateHandle(template.template_id)}>
                                  <ListItemButton disableRipple>
                                    <ListItemIcon>{template?.is_pdf_template !== 1 ? <AssignmentOutlinedIcon /> : <PictureAsPdfOutlinedIcon />}</ListItemIcon>
                                    <ListItemText className="list__text" primary={template.name} />
                                  </ListItemButton>
                                </ListItem>
                              </NavLink>
                            </List>
                            <div className="menu__categories__section">
                              {template.pdf_categories?.map((pdfcategory, pdfcategoryIdx) =>
                                pdfcategory.is_deleted !== 1 ? (
                                  <div key={pdfcategoryIdx} style={{ marginBottom: "4px" }}>
                                    {pdfcategory?.is_inspection_details === 1 && getNonNarrativeTemplatesGuid(inspectionResponse?.inspectiontemplates)?.[0] !== template.template_guid ? (
                                      ""
                                    ) : (
                                      <List key={pdfcategoryIdx} disablePadding>
                                        <NavLink
                                          className={`navlink ${active === pdfcategory.uuid && activeLineCondition ? "active_category" : ""
                                            }`}
                                          to={{ pathname: `/inspection/${pdfcategory.prompt !== null && pdfcategory?.prompt !== "" ? encodeURIComponent(pdfcategory.prompt) : "Untitled"}` }}
                                          state={{
                                            pdfcategory,
                                            template_id: template?.template_id,
                                            category_id: pdfcategory.id,
                                            category_uuid: pdfcategory.uuid,
                                            isPDF: 1,
                                            template_guid: template.template_guid,
                                            pdfcategoryType: pdfcategory?.type,
                                          }}
                                          style={{ textDecoration: "none" }}
                                          onClick={() => clearEditStatuses()}
                                          id={`category_${pdfcategoryIdx}`}
                                        >
                                          <ListItem disablePadding onClick={() => activeHandle(pdfcategory.uuid)} className="categories__list">
                                            <ListItemButton disableRipple>
                                              {pdfcategory?.type != 3 ? (
                                                <div className="list__content">
                                                  <span>{pdfcategory?.prompt !== null ? pdfcategory.prompt : "Untitled"}</span>
                                                </div>
                                              ) : (
                                                <div className="how_to_use_this_form__content">
                                                  <span>{pdfcategory?.prompt !== null ? pdfcategory.prompt : "Untitled"}</span>
                                                </div>
                                              )}
                                            </ListItemButton>
                                          </ListItem>
                                        </NavLink>
                                      </List>
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )
                              )}
                            </div>
                            {menuData.length > templateIdx + 1 && <Divider style={{ marginBottom: "15px", marginTop: "10px" }} />}
                          </div>
                        ) : (
                          // narrative template sidemenu Starts
                          <div key={templateIdx}>
                            <List key={templateIdx} disablePadding className="menu__items">
                              <NavLink
                                className={`navlink ${activeTemplate === template.template_id ? "active_template" : ""}`}
                                to={{ pathname: `/inspection` }}
                                state={{ template, template_id: template.template_id, tempIndex: templateIdx, isNarrative: true }}
                                style={{ textDecoration: "none" }}
                                onClick={() => clearEditStatuses()}
                                id={`template_${templateIdx + 1}`}
                              >
                                <ListItem disablePadding className="list__item" onClick={() => activeTemplateHandle(template.template_id)}>
                                  <ListItemButton disableRipple>
                                    <ListItemIcon>
                                      <AssignmentOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText className="list__text" primary={template?.name} />
                                  </ListItemButton>
                                </ListItem>
                              </NavLink>
                            </List>
                            <div className="menu__categories__section">
                              {template.categories?.map((category, categoryIdx) =>
                                category.is_deleted !== 1 ? (
                                  <div key={categoryIdx} style={{ marginBottom: "4px" }}>
                                    {category?.is_inspection_details === 1 && getNonNarrativeTemplatesGuid(inspectionResponse?.inspectiontemplates)?.[0] !== template.template_guid ? (
                                      ""
                                    ) : (
                                      <>
                                        <List
                                          key={categoryIdx}
                                          disablePadding
                                          className={duplicatedCategoryTemplateIdx === templateIdx && tempDuplicateCategoryIdx === categoryIdx && !category?.showEmpty && "duplicated_category"}
                                          sx={{
                                            animation:
                                              duplicatedCategoryTemplateIdx === templateIdx &&
                                              categoryHighlighted &&
                                              tempDuplicateCategoryIdx === categoryIdx &&
                                              category?.showEmpty &&
                                              "fadeIn 0.5s ease,increaseHeight 0.5s forwards",
                                          }}
                                        >
                                          <NavLink
                                            className={`navlink ${active === category.uuid && activeLineCondition ? "active_category" : ""
                                              }`}
                                            to={{ pathname: `/inspection/${category.prompt !== null && category?.prompt !== "" ? encodeURIComponent(category.prompt) : "Untitled"}` }}
                                            state={{ category, template_id: template.template_id, category_id: category.id, category_uuid: category.uuid, isNarrative: true }}
                                            style={{ textDecoration: "none" }}
                                            onClick={() => {
                                              clearEditStatuses();
                                              setActiveDeleteAndDuplicateCategory(category);
                                              setActiveDeleteAndDuplicateTemplate(template);
                                            }}
                                            id={`category_${categoryIdx}`}
                                            onMouseEnter={() => {
                                              if (category.is_inspection_details !== 2 && !category?.showEmpty) {
                                                setIsCategoryHovered(true);
                                                setIsHoveredCategoryUuid(category?.uuid);
                                              }
                                            }}
                                            onMouseLeave={() => {
                                              if (category.is_inspection_details !== 2 && !category?.showEmpty) {
                                                setIsCategoryHovered(false);
                                              }
                                            }}
                                          >
                                            <ListItem disablePadding className="categories__list">
                                              <ListItemButton disableRipple>
                                                {duplicatedCategoryTemplateIdx === templateIdx && categoryHighlighted && tempDuplicateCategoryIdx === categoryIdx ? (
                                                  <Skeleton variant="text" sx={{ width: "100%", paddingBottom: "1.5rem", marginBottom: "1.5rem", marginTop: "-0.75rem" }} />
                                                ) : (
                                                  <>
                                                    <div className="list__content" onClick={() => activeHandle(category.uuid)}>
                                                      <span
                                                        className={
                                                          duplicatedCategoryTemplateIdx === templateIdx &&
                                                          categoryHighlighted &&
                                                          tempDuplicateCategoryIdx === categoryIdx &&
                                                          !category?.showEmpty &&
                                                          "pulse-once"
                                                        }
                                                      >
                                                        {category.prompt !== null && category?.prompt !== "" ? category.prompt : category?.showEmpty ? "" : "Untitled"}
                                                      </span>
                                                      {category.is_inspection_details === 1 ? (
                                                        <span className="progress_count">
                                                          {categoryPercentCalculate(category)?.lineComplete}/{categoryPercentCalculate(category)?.totalLines}
                                                        </span>
                                                      ) : (
                                                        (isHoveredCategoryUuid !== category?.uuid || !isCategoryHovered) && (
                                                          <>
                                                            {category?.showEmpty ? (
                                                              ""
                                                            ) : (
                                                              <span className="progress_count">
                                                                {categoryPercentCalculate(category)?.lineComplete}/{categoryPercentCalculate(category)?.totalLines}
                                                              </span>
                                                            )}
                                                          </>
                                                        )
                                                      )}
                                                    </div>
                                                    {category.is_inspection_details !== 1
                                                      ? isCategoryHovered &&
                                                      isHoveredCategoryUuid === category?.uuid && (
                                                        <>
                                                          <span
                                                            className="DeleteIcon"
                                                            onClick={(e) => {
                                                              handleCategoryDelete(category, e, categoryIdx);
                                                              e.preventDefault();
                                                            }}
                                                          >
                                                            <DeleteIcon />
                                                          </span>
                                                        </>
                                                      )
                                                      : ""}
                                                  </>
                                                )}
                                              </ListItemButton>
                                            </ListItem>
                                          </NavLink>
                                        </List>
                                      </>
                                    )}

                                    <List disablePadding className={`${active === category.uuid ? "menu__line__section ul_active" : "menu__line__section"}`}>
                                      {category.lines?.map((line, lineIdx) => {
                                        let duplicateCategoryClassCondition = duplicateCategoryIdx == categoryIdx && tempDuplicateLineIdx === lineIdx && !line?.showLineEmpty
                                        return (line.is_deleted !== 1 ? (
                                          active === category.uuid ? (
                                            category.is_inspection_details !== 1 ? (
                                              <NavLink
                                                className={`navlink ${activeLine === line.uuid || storeData?.activeLineUuid === line.uuid ? "active_line" : ""}`}
                                                to={{
                                                  pathname: `/inspection/${category.prompt !== null && category?.prompt !== "" ? encodeURIComponent(category.prompt) : "Untitled"}/${line.prompt !== null && line?.prompt !== "" ? encodeURIComponent(line.prompt) : "Untitled"
                                                    }`,
                                                }}
                                                state={{
                                                  line,
                                                  template_id: template.template_id,
                                                  category_id: category.id,
                                                  category_uuid: category.uuid,
                                                  line_uuid: line.uuid,
                                                  template_guid: template.template_guid,
                                                  isNarrative: true,
                                                }}
                                                style={{ textDecoration: "none" }}
                                                onClick={() => {
                                                  clearEditStatuses();
                                                  setActiveDeleteAndDuplicateTemplate(template);
                                                  setActiveDeleteAndDuplicateCategory(category);
                                                }}
                                                onMouseEnter={(e) => {
                                                  !line?.showLineEmpty && setIsHovered(true);
                                                  setIsHoveredLineUuid(line.uuid);
                                                }}
                                                onMouseLeave={() => {
                                                  !line?.showLineEmpty && setIsHovered(false);
                                                }}
                                              >
                                                <ListItem
                                                  disablePadding
                                                  className={`${category.lines.length > lineIdx + 1 && "border__list "} is__relative menu__line__list`}
                                                  onClick={() => activeHandleLine(line.uuid)}
                                                >
                                                  <SvgIcon className="curve" viewBox="">
                                                    {category.lines.length > lineIdx + 1 ? curve : curveEnd}
                                                  </SvgIcon>
                                                  {!deleteCategoryModal && (
                                                    <ListItemButton disableRipple>
                                                      {line?.showLineEmpty && duplicateCategoryIdx == categoryIdx && lineHighlighted ? (
                                                        <>
                                                          {lineHighlighted && tempDuplicateLineIdx === lineIdx && line?.showLineEmpty && (
                                                            <Skeleton variant="text" sx={{ width: "100%", height: "2.5rem", display: "block", marginLeft: "14px" }} />
                                                          )}
                                                          <div
                                                            className={
                                                              duplicateCategoryIdx == categoryIdx && lineHighlighted && tempDuplicateLineIdx === lineIdx && line?.showLineEmpty && "animation-line"
                                                            }
                                                            style={{
                                                              animation:
                                                                duplicateCategoryIdx == categoryIdx &&
                                                                lineHighlighted &&
                                                                tempDuplicateLineIdx === lineIdx &&
                                                                line?.showLineEmpty &&
                                                                "fadeIn 0.5s ease,increaseHeight 0.5s forwards",
                                                            }}
                                                          >
                                                            {" "}
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <div
                                                          className={`list__content line__list__padding ${duplicateCategoryClassCondition ? "duplicated_line" : ""
                                                            }`}
                                                          style={{
                                                            animation: lineHighlighted && tempDuplicateLineIdx === lineIdx && line?.showLineEmpty && "fadeIn 0.5s ease,increaseHeight 0.5s forwards",
                                                          }}
                                                        >
                                                          <span style={{ width: "70%" }} className={lineHighlighted && tempDuplicateLineIdx === lineIdx && !line?.showLineEmpty && "pulse-once"}>
                                                            {" "}
                                                            {line.prompt !== null && line?.prompt !== "" ? line.prompt : "Untitled"}
                                                          </span>
                                                          <span className="progress_count" style={{ display: "flex" }}>
                                                            <CheckCircleIcon
                                                              style={{ visibility: (isHoveredLineUuid !== line.uuid || !isHovered) && checkLineComplete(line, "normal") ? "visible" : "hidden" }}
                                                            />
                                                            {isHovered && isHoveredLineUuid === line.uuid && (
                                                              <>
                                                                <span
                                                                  style={{ display: "flex" }}
                                                                  className="DeleteIcon"
                                                                  onClick={(e) => {
                                                                    handleLineDelete(line, lineIdx, category, template);
                                                                    e.preventDefault();
                                                                  }}
                                                                >
                                                                  <DeleteIcon />
                                                                </span>
                                                              </>
                                                            )}
                                                          </span>
                                                        </div>
                                                      )}
                                                    </ListItemButton>
                                                  )}
                                                </ListItem>
                                              </NavLink>
                                            ) : (
                                              <ListItem
                                                disablePadding
                                                className={`${category.lines.length > lineIdx + 1 && "border__list "} is__relative menu__line__list`}
                                                onMouseEnter={(e) => {
                                                  setIsInspectionDetailsHovered(true);
                                                  setIsHoveredInspectionDetailsLineUuid(line.uuid);
                                                }}
                                                onMouseLeave={() => setIsInspectionDetailsHovered(false)}
                                              >
                                                <SvgIcon className="curve" viewBox="">
                                                  {category.lines.length > lineIdx + 1 ? curve : curveEnd}
                                                </SvgIcon>
                                                <ListItemButton disableRipple>
                                                  {line?.showLineEmpty && duplicateCategoryIdx == categoryIdx ? (
                                                    <>
                                                      {lineHighlighted && tempDuplicateLineIdx === lineIdx && line?.showLineEmpty && (
                                                        <Skeleton variant="text" sx={{ width: "100%", height: "2.5rem", display: "block", marginLeft: "14px" }} />
                                                      )}
                                                      <div
                                                        className={lineHighlighted && tempDuplicateLineIdx === lineIdx && line?.showLineEmpty && "animation-line"}
                                                        style={{
                                                          animation: lineHighlighted && tempDuplicateLineIdx === lineIdx && line?.showLineEmpty && "fadeIn 0.5s ease,increaseHeight 0.5s forwards",
                                                        }}
                                                      >
                                                        {" "}
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <div
                                                      className={`list__content line__list__padding ${duplicateCategoryClassCondition ? "duplicated_line" : ""
                                                        }`}
                                                      style={{
                                                        animation: lineHighlighted && tempDuplicateLineIdx === lineIdx && line?.showLineEmpty && "fadeIn 0.5s ease,increaseHeight 0.5s forwards",
                                                      }}
                                                    >
                                                      <span>{line.prompt}</span>{" "}
                                                      <span className="progress_count">
                                                        <CheckCircleIcon
                                                          style={{
                                                            visibility:
                                                              (!isInspectionDetailsHovered || isHoveredInspectionDetailsLineUuid !== line.uuid) && checkLineComplete(line, "inspectionDetails")
                                                                ? "visible"
                                                                : "hidden",
                                                          }}
                                                        />
                                                        {isInspectionDetailsHovered && isHoveredInspectionDetailsLineUuid === line.uuid && (
                                                          <>
                                                            <span
                                                              className="DeleteIcon"
                                                              onClick={(e) => {
                                                                handleLineDelete(line, lineIdx, category, template);
                                                                e.preventDefault();
                                                              }}
                                                            >
                                                              <DeleteIcon />
                                                            </span>
                                                          </>
                                                        )}
                                                      </span>
                                                    </div>
                                                  )}
                                                </ListItemButton>
                                              </ListItem>
                                            )
                                          ) : (
                                            <>
                                              {lineHighlighted && tempDuplicateLineIdx === lineIdx && line?.showLineEmpty && (
                                                <Skeleton variant="text" sx={{ width: "100%", height: "2.5rem", display: "block", marginLeft: "14px" }} />
                                              )}
                                              <div
                                                className={activeLineIdx === lineIdx && "animation-DeleteCategory"}
                                                style={{ animation: activeLineIdx === lineIdx && "fadeIn 0.5s ease, decreseLineHeight 0.5s forwards" }}
                                              >
                                                {" "}
                                              </div>
                                            </>
                                          )
                                        ) : (
                                          <>
                                            {lineHighlighted && tempDuplicateLineIdx === lineIdx && line?.showLineEmpty && (
                                              <Skeleton variant="text" sx={{ width: "100%", height: "2.5rem", display: "block", marginLeft: "14px" }} />
                                            )}
                                            <div
                                              className={activeLineIdx === lineIdx && "animation-DeleteCategory"}
                                              style={{ animation: activeLineIdx === lineIdx && "fadeIn 0.5s ease, decreseLineHeight 0.5s forwards" }}
                                            >
                                              {" "}
                                            </div>
                                          </>
                                        ))
                                      }
                                      )}
                                      {category?.is_inspection_details == 2
                                        ? ""
                                        : active == category?.uuid && (
                                          <div onClick={() => handleAddLine(categoryData?.is_inspection_details, categoryData?.parent_uuid)} className="sidemenu_add_category_button">
                                            <AddCircleIcon /> <span style={{ marginLeft: "4px" }}>New Line</span>{" "}
                                          </div>
                                        )}
                                    </List>
                                  </div>
                                ) : (
                                  <div
                                    className={activeDeleteCategoryIdx === categoryIdx && "animation-DeleteCategory"}
                                    style={{ animation: activeDeleteCategoryIdx === categoryIdx && "fadeIn 0.5s ease, decreseLineHeight 0.5s forwards" }}
                                  >
                                    {" "}
                                  </div>
                                )
                              )}
                              <div onClick={() => handleAddCategory(template?.template_guid, template?.template_id)} className="sidemenu_add_category_button">
                                <AddCircleIcon /> <span style={{ marginLeft: "4px" }}>New Category</span>{" "}
                              </div>
                            </div>
                            {menuData.length > templateIdx + 1 && <Divider style={{ marginBottom: "15px", marginTop: "10px" }} />}
                          </div>
                        )
                      // narrative template sidemenu ends
                    )}
                    {/* <PdfSideMenu/>   */}
                  </>
                )}
              </Box>
            </div>
            {showStaticBackground || staticBG ? (
              ""
            ) : sessionStorage.getItem("AC") !== null && sessionStorage.getItem("AC") !== undefined && Base64.decode(sessionStorage.getItem("AC")) === "Palmtech" ? (
              <div className="portal__link">
                <Typography>
                  <a href={PORTAL_URL} target="_blank">
                    Go to portal <CallMissedOutgoingIcon />
                  </a>{" "}
                </Typography>
              </div>
            ) : (
              ""
            )}
          </div>
        </Grid>
        <div className="sidemenu__footer">
          {showStaticBackground || staticBG ? (
            <div>
              <Avatar sx={{ bgcolor: "#BEBEBE" }}></Avatar>
              <div className="footer_name">
                <Typography>------ ----</Typography>
                <Typography>----------------</Typography>
              </div>
            </div>
          ) : (
            <div>
              <Avatar sx={{ bgcolor: "#BEBEBE" }}>{sessionStorage.getItem("user_displayname") !== "" ? sessionStorage.getItem("user_displayname")?.charAt(0) : "A"}</Avatar>
              <div className="footer_name">
                <Typography>{sessionStorage.getItem("user_displayname")}</Typography>
                <Typography>{sessionStorage.getItem("user_email")}</Typography>
              </div>
            </div>
          )}
          {/* <LogoutIcon/> */}
        </div>
      </Grid>
      <DeleteConfirmationDialog
        open={deleteLineModal}
        handleClose={handleCloseDeleteAlert}
        title={"Delete line"}
        contentText={`Are you sure you want to delete the line ${activeDeleteLine?.prompt}?`}
        cancelBtnTitle={"Cancel"}
        cancelBtnAction={handleCloseDeleteAlert}
        submitBtnTitle={"Delete"}
        submitBtnAction={handleConfirmDeleteLine}
      />
      <DeleteConfirmationDialog
        open={deleteCategoryModal}
        handleClose={handleCloseCategoryDeleteAlert}
        title={"Delete category"}
        contentText={`Are you sure you want to delete the category ${activeDeleteAndDuplicateCategory?.prompt}?`}
        cancelBtnTitle={"Cancel"}
        cancelBtnAction={handleCloseCategoryDeleteAlert}
        submitBtnTitle={"Delete"}
        submitBtnAction={handleConfirmDeleteCategory}
      />
    </>
  );
};

export default SideMenu;
