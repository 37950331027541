import { Grid } from "@mui/material";
import { replacePlaceholdersWithDropdowns } from "./CommentLibraryHelper";
import { filterObjectsUptoLine } from "../../../Helpers/HelperFunctions";
import { UpdateTemplateDataSpecific } from "../../../Helpers/ApiCall";
import { GetUTCTime } from "../../../Helpers/GetUTCTime";
import { useDispatch } from "react-redux";
const NarrativeSummary = ({
  summary,
  summaries,
  data,
  action,
  templateId,
  categoryId,
  lineUuid,
  commentUuid,
  summary_comment_dropdowns,
  inspectionData,
}) => {
  const dispatch = useDispatch();

  let summaryDropElements = document.querySelectorAll(
    ".summary-cl-detail-dropdown"
  );
  summaryDropElements?.forEach((element) => {
    if (element !== null && element !== undefined) {
      const commentUUId = element.getAttribute("comment_uuid");
      element.onchange = (event) =>
        handleChangeDropdown(event, element.id, commentUUId);
    }
  });
  const handleChangeDropdown = async (event, dropdownUuid, commentUUId) => {
    const selectedValue = event.target.value;
    let templateIndex = inspectionData?.inspectiontemplates?.findIndex(
      (itm) => itm.template_id === templateId
    );
    let categoryIndex = inspectionData?.inspectiontemplates[
      templateIndex
    ]?.categories?.findIndex((itm) => itm.id === categoryId);
    let lineIndex = inspectionData?.inspectiontemplates[
      templateIndex
    ]?.categories[categoryIndex]?.lines?.findIndex(
      (line) => line.uuid === lineUuid
    );
    let prevData = inspectionData;
    let commentIndex = inspectionData?.inspectiontemplates[
      templateIndex
    ]?.categories[categoryIndex]?.lines[lineIndex]?.comments?.findIndex(
      (itm) => itm.uuid == commentUUId
    );
    let dropdownIndex = prevData.inspectiontemplates[templateIndex].categories[
      categoryIndex
    ].lines[lineIndex].comments[
      commentIndex
    ].summary_comment_dropdowns.findIndex(
      (dropdown) => dropdown.dropdown_uuid === dropdownUuid
    );
    if (dropdownIndex !== -1) {
      prevData.inspectiontemplates[templateIndex].categories[
        categoryIndex
      ].lines[lineIndex].comments[commentIndex].summary_comment_dropdowns[
        dropdownIndex
      ].selected_value = selectedValue;
    }
    prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[
      lineIndex
    ].comments[commentIndex].updated_at = await GetUTCTime();
    let filteredObject = filterObjectsUptoLine(
      prevData,
      templateIndex,
      categoryIndex,
      lineIndex
    );
    UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
  };
  return data?.is_disabled != 1 && ((summary?.length !== 0 && summary !== null && summary !== "<p><br></p>") || summaries?.length !== 0 ) ? (
    <Grid
      container
      className={`${action === "line" ? "container" : "narrative_comment_bg"}`}
    >
      <div>
        <p className="narrative_summary_title">Summary</p>
        <p className="narrative_summary_content">
          {summary !== null &&
            replacePlaceholdersWithDropdowns(
              summary,
              summary_comment_dropdowns,
              "summary",
              commentUuid
            )}
        </p>
        <div className="cl_summary_rating_section">
          {summaries?.length !== 0
            ? summaries?.map((element) => (
                <div className="cl_summary_rating_square_container">
                  <div
                    className="rating_square"
                    style={{ background: `${element?.color}`, padding: "4px" }}
                  ></div>
                  <div className="cl_rating_name_text">{element?.name}</div>
                </div>
              ))
            : ""}
        </div>
      </div>
    </Grid>
  ) : (
    ""
  );
};

export default NarrativeSummary;
