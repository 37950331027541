import { Button } from "@mui/material";
import "../../../Assets/Styles/Containers/_button.scss";

const FilledButton = ({ width, text, onClick, disabled,id }) => {
  return (
    <Button disabled={disabled} onClick={onClick} className="filled_btn" style={{ width }} id={id}>
      {text}
    </Button>
  );
};

export default FilledButton;
