import { FormControl, Grid, TextField } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { GetUTCTime } from "../../../Helpers/GetUTCTime";
import { UpdateTemplateDataSpecific } from "../../../Helpers/ApiCall";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import DoneIcon from '@mui/icons-material/Done';
import { filterPdfTemplateData } from "../../../Helpers/HelperFunctions";

function NumberElement({
  element,
  templateId,
  elementType,
  pdfCategoriesType,
  level
}) {
  const [inspectionResponse, setInspectionResponse] = useState();
  const [apiCallDone, setApiCallDone] = useState(false);
  const [number, setNumber] = useState("");
  const [inputChanged, setInputChanged] = useState(false);
  const [numberError, setNumberError] = useState(false);
  const [numberErrorText, setNumberErrorText] = useState("");

  const storeData = useSelector((state) => state.CommonReducer);
  const dispatch = useDispatch();

  let templateIndex = storeData?.inspectionData?.data?.inspectiontemplates?.findIndex((itm) => itm.template_id === templateId);
  let prevData = storeData?.inspectionData?.data;
  let pdfTemplate = prevData?.inspectiontemplates[templateIndex];
  let mergeId = element?.merge_id;
  let ElementpdfData = pdfTemplate?.pdf_data?.filter((item) => item?.merge_id == mergeId);

  const HandleUpdateNumberApiCall = async () => {
    let formData = {
      type: "Input/Number",
      merge_id: mergeId,
      value: number,
      updated_at: await GetUTCTime(),
    };
    if (
      pdfTemplate?.pdf_data?.length !== 0 &&
      pdfTemplate?.pdf_data?.length !== undefined
    ) {
      pdfTemplate.pdf_data.length = 0;
    }
    pdfTemplate?.pdf_data.push(formData);
    let filteredData = pdfTemplate?.pdf_data?.filter(el=>el?.merge_id != mergeId)
    filteredData.push(formData)
    let filteredObject = filterPdfTemplateData(prevData, templateIndex,filteredData,mergeId);
    UpdateTemplateDataSpecific(dispatch,prevData,filteredObject);
    setNumberError(false);
    setNumberErrorText("");
    setApiCallDone(true)
  };

  const handleNumberChange = (value) => {
    if (value == null || value === undefined || value === "") {
      setNumber("");
      setNumberError(false);
      setNumberErrorText("");
      return;
    }
    const sanitizedValue = value.replace(',', '.').match(/^\d+(\.\d{0,2})?/)[0];
    setNumber(sanitizedValue);
    const isTruncated = sanitizedValue !== value;
    if (isTruncated) {
      setNumberError(true);
      setNumberErrorText("Only numeric values allowed in this field. Decimals allowed up to 2 digits only.");
    } else {
      setNumberError(false);
      setNumberErrorText("");
    }
  };
  
  useEffect(() => {
    if(apiCallDone) setInspectionResponse(storeData);
  }, [apiCallDone]);

  useEffect(() => {
    let value = ElementpdfData?.map((item) => item?.value);
    setNumber(value.toString());
  }, [inspectionResponse]);

  return (
    <>
      <Grid
        container
        className={`${
          elementType === "inner" || elementType === "layout"
            ? "pdf_container"
            : "pdf_inner_container"
        }`}
      >
        <Grid item xs={12}>
          <FormControl variant="standard"  className={`forms-control w-100 ${level==3 ? "level3_class":""}`}>
            <label
             className={pdfCategoriesType==1? (elementType !== "inner" && elementType !== "layout" ) || number ==""  ?"inner_details_title":"inner_details_title_after_selection":"inner_details_title"}
              id="categoryName"
            >
              {pdfCategoriesType==1? (elementType !== "inner" && elementType !== "layout" ) || number ==""  ? "" : <DoneIcon sx={{marginLeft: "-28px",position: "absolute"}}/>:""}
              {element?.label}
            </label>
            <TextField
              id="new_des_input"
              className="input-textfield pdfInputBox"
              size="small"
              variant="outlined"
              type="text"
              sx={{
                position: "relative",
                minWidth: "100%",
                height: "40px",
                marginTop: "9px",
                marginBottom:"9px"
              }}
              onBlur={inputChanged && HandleUpdateNumberApiCall}
              onChange={(e) => {
                // setNumber(e?.target?.value);
                handleNumberChange(e?.target?.value)
                setInputChanged(true);
              }}
              value={number}
              name="categoryName"
              error={numberError}
              helperText={numberErrorText}
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

export default NumberElement;
