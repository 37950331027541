// File imports
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import noImageFound from '../../../Assets/Images/noImageFound.png'
import CircularProgress from '@mui/material/CircularProgress';
import "../../../Assets/Styles/Containers/_showMoreLess.scss";
import { useNavigate } from "react-router-dom";
import ImageCarousel from "./ImageCarousel";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid, Box } from '@mui/material'
import '@pqina/pintura/pintura.css';

const ShowMoreLess = (children) => {
  const { templateId, categoryUuid, lineUuid, commentUuid, categoryId, action, mediaData, matches, isNarrative, addCommentFromLine } = children
  const storeData = useSelector((state) => state.CommonReducer);
  const [inspectionResponse, setInspectionResponse] = useState()
  const [isReadMore, setIsReadMore] = useState(true);
  // media 
  const [videoEditor, setVideoEditor] = useState(false);
  const [videolink, setVideoLink] = useState("")
  const [videoId, SetVideoId] = useState("")
  const [click, setClick] = useState(false)
  const [editVideoObject, setEditVideoObject] = useState("")

  let navigate = useNavigate();
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  }

  const pinturaNavigate = (imageUrl, image) => {
    navigate(
      `/pinturaeditor`, {
        replace: true,
      state: {
        imageUrl,
        image,
        inspectionResponse,
        isPdf: "0",
        templateId,
        categoryId,
        categoryUuid,
        lineUuid,
        commentUuid,
        action,
        isNarrative
      }
    }
    )
  }
  let media = [];

  useEffect(() => {
    if (storeData.inspectionData) {
      setInspectionResponse(storeData)
    }
  }, [storeData.inspectionData])

  return (
    <>
      {children?.body?.length !== 0 && children?.body?.length > 30 ? (
        isReadMore ? (
          <>
            {children?.body?.length !== 0 &&
              children?.body?.slice(0, 30)?.map((image, index) => {
                let imageThumbnailUrl = image.is_portal === 1 ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}` : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`;
                let imageUrl = image.is_portal === 1 ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.thumbnail !== null ? image.onlineUrl : image.onlineUrl}` : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.thumbnail !== null ? image.onlineUrl : image.onlineUrl}`;
                let videoUrl = image.is_portal === 1 ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.onlineUrl}` : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.onlineUrl}`
                let videoThumbnail = image.is_portal === 1 ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}` : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`
                return (
                  image.is_deleted !== 1 ?
                    image.online === "online" &&
                      image.filetype === "image" ?
                      <Grid item xs={12} sm={12} md={storeData?.reportSideBarActive ? 12 : 4} lg={storeData?.reportSideBarActive ? !matches ? 6 : 4 : 3} key={index}>
                        <div style={{ display: 'none' }}>{image.filetype === 'image' ? media.push({ type: 'image', fileUrl: image.onlineUrl }) : media.push({ type: 'video', fileUrl: image.onlineUrl })}</div>
                        <Box
                          onClick={() => pinturaNavigate(imageUrl, image)}
                          component="img"
                          className='image_container'
                          src={imageThumbnailUrl}
                          alt={`Image ${index + 1}`}
                        />
                        {image?.description && <div className='caption_container'><p className='caption_text'>{image?.description}</p></div>}
                      </Grid> : image.online === "offline" ? "" :
                        <Grid item xs={12} sm={12} md={storeData?.reportSideBarActive ? 12 : 4} lg={storeData?.reportSideBarActive ? !matches ? 6 : 4 : 3} >
                          <div style={{ display: 'none' }}>{image.filetype === 'image' ? media.push({ type: 'image', fileUrl: image.onlineUrl }) : media.push({ type: 'video', fileUrl: image.onlineUrl })}</div>
                          <Box className='video_container'>
                            <Box
                              component="img"
                              className='image_container'
                              src={videoThumbnail}
                              alt={`Image ${index + 1}`}
                              onClick={() => { setVideoEditor(true); SetVideoId(image?.id); setEditVideoObject(image); setVideoLink(videoUrl);}}
                            />
                            <PlayCircleFilledIcon
                              onClick={() => { setVideoEditor(true); SetVideoId(image?.id); setEditVideoObject(image); setVideoLink(videoUrl);}}
                              className='video_play_icon'
                            />
                            {image?.description && <div className='caption_container'><p className='caption_text'>{image?.description}</p></div>}
                          </Box>
                        </Grid> : ""
                )
              })
            }
            <Grid item xs={12}>
              <div onClick={toggleReadMore} className="media_showmore__less" variant="none">
                <span>
                  See More <KeyboardArrowDownIcon />
                </span>
              </div>
            </Grid>
          </>
        ) : (
          <>
            {children?.body?.length !== 0 &&
              children?.body?.map((image, index) => {
                let imageThumbnailUrl = image.is_portal === 1 ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}` : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`;
                let imageUrl = image.is_portal === 1 ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.thumbnail !== null ? image.onlineUrl : image.onlineUrl}` : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.thumbnail !== null ? image.onlineUrl : image.onlineUrl}`;
                let videoUrl = image.is_portal === 1 ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.onlineUrl}` : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.onlineUrl}`
                let videoThumbnail = image.is_portal === 1 ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}` : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`
                return (image.is_deleted !== 1 ? image.online === "online" &&
                  image.filetype === "image" ?
                  <Grid item xs={12} sm={12} md={storeData?.reportSideBarActive ? 12 : 4} lg={storeData?.reportSideBarActive ? !matches ? 6 : 4 : 3} key={index}>
                    <div style={{ display: 'none' }}>{image.filetype === 'image' ? media.push({ type: 'image', fileUrl: image.onlineUrl }) : media.push({ type: 'video', fileUrl: image.onlineUrl })}</div>
                    <Box
                      onClick={() => pinturaNavigate(imageUrl, image)}
                      component="img"
                      className='image_container'
                      src={imageThumbnailUrl}
                      alt={`Image ${index + 1}`}
                    />
                  </Grid> : image.online === "offline" ? "" :
                    <Grid item xs={12} sm={12} md={storeData?.reportSideBarActive ? 12 : 4} lg={storeData?.reportSideBarActive ? !matches ? 6 : 4 : 3}>
                      <div style={{ display: 'none' }}>{image.filetype === 'image' ? media.push({ type: 'image', fileUrl: image.onlineUrl }) : media.push({ type: 'video', fileUrl: image.onlineUrl })}</div>
                      <Box
                        className='video_container'
                      >
                        <Box
                          component="img"
                          className='image_container'
                          src={videoThumbnail}
                          alt={`Image ${index + 1}`}
                          onClick={() => { setVideoEditor(true); SetVideoId(image?.id); setEditVideoObject(image); setVideoLink(videoUrl); }}
                        />
                        <PlayCircleFilledIcon
                          onClick={() => { setVideoEditor(true); SetVideoId(image?.id); setEditVideoObject(image); setVideoLink(videoUrl);}}
                          className='video_play_icon'
                        />
                      </Box>
                      {image?.description && <div className='caption_container'><p className='caption_text'>{image?.description}</p></div>}
                    </Grid> : '')
              })
            }
            <Grid item xs={12}>
              <div onClick={toggleReadMore} className="media_showmore__less" variant="none">
                <span>
                  <KeyboardArrowUpIcon /> See less{" "}
                </span>
              </div>
            </Grid>
          </>
        )
      ) :
        <>
          {children?.body?.length !== 0 ? (
            children?.body?.map((image, index) => {
              let imageThumbnailUrl = image.is_portal === 1 ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}` : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`;
              let imageUrl = image.is_portal === 1 ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.thumbnail !== null ? image.onlineUrl : image.onlineUrl}` : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.thumbnail !== null ? image.onlineUrl : image.onlineUrl}`;
              let videoUrl = image.is_portal === 1 ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.onlineUrl}` : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.onlineUrl}`
              let videoThumbnail = image.is_portal === 1 ? `${inspectionResponse?.inspectionData?.data?.bucket_url_2}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}` : `${inspectionResponse?.inspectionData?.data?.bucket_url}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`
              return (image.is_deleted !== 1 ? image.online === "online" &&
                image.filetype === "image" ?
                <Grid item xs={12} sm={6} md={storeData?.reportSideBarActive ? 12 : 4} lg={storeData?.reportSideBarActive ? !matches ? 6 : 4 : 3} key={index}>
                  <div style={{ display: 'none' }}>{image.filetype === 'image' ? media.push({ type: 'image', fileUrl: image.onlineUrl }) : media.push({ type: 'video', fileUrl: image.onlineUrl })}</div>
                  <Box
                    onClick={() => pinturaNavigate(imageUrl, image)}
                    component="img"
                    className='image_container'
                    src={!click ? imageThumbnailUrl : imageUrl}
                    alt={`Image ${index + 1}`}
                  />
                  {image?.description && <div className='caption_container'><p className='caption_text'>{image?.description}</p></div>}
                </Grid> : image.online === "offline" ? "" :
                  <Grid item xs={12} sm={12} md={storeData?.reportSideBarActive ? 12 : 4} lg={storeData?.reportSideBarActive ? !matches ? 6 : 4 : 3}>
                    <div style={{ display: 'none' }}>{image.filetype === 'image' ? media.push({ type: 'image', fileUrl: image.onlineUrl }) : media.push({ type: 'video', fileUrl: image.onlineUrl })}</div>
                    <Box
                      className='video_container'
                    >
                      <Box
                        component="img"
                        className='image_container'
                        src={!videoThumbnail?.includes('undefined') ? videoThumbnail : noImageFound}
                        alt={`Image ${index + 1}`}
                        onClick={() => { setVideoEditor(true); SetVideoId(image?.id); setEditVideoObject(image); setVideoLink(videoUrl);}}
                      />
                      {!videoThumbnail?.includes('undefined') ? <PlayCircleFilledIcon
                        className='video_play_icon'
                        onClick={() => { setVideoEditor(true); SetVideoId(image?.id); setEditVideoObject(image); setVideoLink(videoUrl);}}
                      /> : <CircularProgress className="circular_progress_icon" />}
                    </Box>
                    {image?.description && <div className='caption_container'><p className='caption_text'>{image?.description}</p></div>}
                  </Grid> : '')
            })
          ) : (
            ""
          )}
        </>
      }
      {videoEditor && <ImageCarousel inspectionResponse={inspectionResponse} videolink={videolink} mediaData={mediaData?.filter((item) => item.filetype.includes("video"))} videoEditor={videoEditor} setVideoEditor={setVideoEditor} videoId={videoId} />}
    </>
  )
}

export default ShowMoreLess 