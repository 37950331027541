import ACTION_TYPES from "../Constants/ActionTypes";
const initialState = {
  reorderCategory: false,
  getClListStatus: true,
  bulkUploadMediaLength: 0,
  successfulBulkUploadMediaLength: 0,
  failBulkUploadMediaLength: 0,
};
// eslint-disable-next-line
export default (state = initialState, { type, payload }) => {
  switch (type) {
    // COMMENTS
    case ACTION_TYPES.LOAD_DASHBOARD_RESPONSE:
      return { ...state, dashboardData: payload };
    case ACTION_TYPES.GET_INSPECTION_RESPONSE: {
      const newPayload = payload?.data?.inspectiontemplates?.filter((obj) => obj.is_deleted !== 1);
      return {
        ...state,
        inspectionData: { ...payload, data: { ...payload?.data, inspectiontemplates: newPayload } },
        inspectionDataForBulkMedia: payload,
        loader: false,
        mediaUpdate: false,
        scrolPage: false,
        isnTempateUpdate: "",
        staticBG: false,
        addCommentResponse: "",
        clCommentAdded: false,
        clCommentStatusChanged: false,
        getClListStatus: true,
        narrativeCommentAdded: "",
        isBulkUploaded: false,
      };
    }
    case ACTION_TYPES.GET_INSPECTION_RESPONSE_FOR_BULK_MEDIA:
      return {
        ...state,
        inspectionDataForBulkMedia: payload,
        loader: false,
        mediaUpdate: false,
        scrolPage: false,
        isnTempateUpdate: "",
        staticBG: false,
        addCommentResponse: "",
        clCommentAdded: false,
        clCommentStatusChanged: false,
        getClListStatus: true,
        narrativeCommentAdded: "",
        isBulkUploaded: false,
      };
    case ACTION_TYPES.SEND_DATA_TO_READ_SECTION_OFFLINE: {
      const newPayload = payload?.data?.inspectiontemplates?.filter((obj) => obj.is_deleted !== 1);
      return {
        ...state,
        inspectionData: { ...payload, data: { ...payload?.data, inspectiontemplates: newPayload } },
        loader: true,
        mediaUpdate: false,
        scrolPage: false,
        isnTempateUpdate: "",
        staticBG: false,
      };
    }
    case ACTION_TYPES.GET_INSPECTION_LOADER:
      return { ...state, loader: true, mediaUpdate: false };
    case ACTION_TYPES.GET_ADD_CAT_LOADER:
      return { ...state, addCatLoader: true, mediaUpdate: false };
    case ACTION_TYPES.GET_ADD_LINE_LOADER:
      return { ...state, addLineLoader: true, mediaUpdate: false };
    case ACTION_TYPES.GET_LOADER_FOR_TEMPLATE_UPDATE:
      return { ...state, loader: false, mediaUpdate: false, templateUpdateLoader: true };
    case ACTION_TYPES.GET_INSPECTION_ERROR:
      return { ...state, inspectionDataError: payload, loader: false, mediaUpdate: false, scrolPage: false };
    case ACTION_TYPES.UPDATE_INSPECTION_RESPONSE: {
      const newPayload = payload.data.inspectiontemplates?.filter((obj) => obj.is_deleted !== 1);
      return {
        ...state,
        inspectionData: { ...payload, data: { ...payload?.data, inspectiontemplates: newPayload } },
        updatedInspectionData: { ...payload, data: { ...payload?.data, inspectiontemplates: newPayload } },
        loader: false,
        mediaUpdate: false,
        scrolPage: false,
        addCommentResponse: "",
      };
    }
    case ACTION_TYPES.DELETE_INSPECTION_MEDIA_RESPONSE: {
      const newPayload = payload?.data?.inspectiontemplates?.filter((obj) => obj.is_deleted !== 1);
      return {
        ...state,
        inspectionDataForBulkMedia: payload,
        mediaUpdated: { ...payload, data: { ...payload?.data, inspectiontemplates: newPayload } },
        inspectionData: { ...payload, data: { ...payload?.data, inspectiontemplates: newPayload } },
        updatedInspectionData: { ...payload, data: { ...payload?.data, inspectiontemplates: newPayload } },
        loader: false,
        mediaUpdate: false,
        scrolPage: false,
        addCommentResponse: "",
      };
    }
    case ACTION_TYPES.UPDATE_INSPECTION_RESPONSE_FOR_ADD_INP: {
      const newPayload = payload?.data?.inspectiontemplates?.filter((obj) => obj.is_deleted !== 1);
      return {
        ...state,
        inspectionData: { ...payload, data: { ...payload?.data, inspectiontemplates: newPayload } },
        inspectionDataForBulkMedia: payload,
        updatedInspectionData: { ...payload, data: { ...payload?.data, inspectiontemplates: newPayload } },
        loader: false,
        mediaUpdate: false,
        addInpUpdated: true,
        scrolPage: false,
      };
    }
    case ACTION_TYPES.CLEAR_UPDATED_INSPECTION_DATA:
      return { ...state, updatedInspectionData: "", scrolPage: false };
    case ACTION_TYPES.CLEAR_UPDATED_TEMPLATE_DATA:
      return { ...state, attachmentUpdate: false, attachmentResponse: "", scrolPage: false };
    // case ACTION_TYPES.CLEAR_INSPECTION_DATA_ERROR:
    //   return { ...state,inspectionDataError:'' };
    case ACTION_TYPES.UPDATE_INSPECTION_ERROR:
      return { ...state, updatedInspectionData: payload, scrolPage: false };
    case ACTION_TYPES.UPDATE_INSPECTION_ERROR_FOR_ADD_INP:
      return { ...state, updatedInspectionData: payload, addInpUpdated: true, scrolPage: false };
    // case ACTION_TYPES.REORDER_CATEGORY:
    //   return { ...state, reorderCategory: true };
    // activate categories and lines
    case ACTION_TYPES.ACTIVATE_CURRENT_LINE:
      return { ...state, loader: false, scrolPage: false, activeLineUuid: payload };
    case ACTION_TYPES.CLEAR_ACTIVATED_CURRENT_LINE:
      return { ...state, loader: false, scrolPage: false, activeLineUuid: "" };
    // getcategory and line list
    case ACTION_TYPES.SCROLL_PAGE_TOP:
      return { ...state, loader: false, scrolPage: true };
    case ACTION_TYPES.SET_STATIC_BACKGROUND:
      return { ...state, staticBG: payload };
    case ACTION_TYPES.GET_CATEGORY_LIST_RESPONSE:
      return { ...state, loader: false, defaultCategoryList: payload, addCatLoader: false };
    case ACTION_TYPES.GET_CATEGORY_LIST_ERROR:
      return { ...state, loader: false, defaultCategoryList: [], addCatLoader: false };
    case ACTION_TYPES.GET_CATEGORY_LIST_DATA_SUCCESS:
      return { ...state, loader: false, defaultCategoryListData: payload, scrolPage: false };
    case ACTION_TYPES.GET_CATEGORY_LIST_DATA_ERROR:
      return { ...state, loader: false, defaultCategoryListData: [], scrolPage: false };
    // Add and delete templates api
    case ACTION_TYPES.GET_TEMPLATE_LIST_RESPONSE:
      return { ...state, loader: false, defaultTemplateList: payload, addCatLoader: false };
    case ACTION_TYPES.GET_TEMPLATE_LIST_ERROR:
      return { ...state, loader: false, defaultTemplateList: [], addCatLoader: false };
    case ACTION_TYPES.SWAP_INSPECTION_DETAILS_RESPONSE:
      return { ...state, loader: false, swapInspectionDetails: payload, addCatLoader: false };
    case ACTION_TYPES.SWAP_INSPECTION_DETAILS_ERROR:
      return { ...state, loader: false, swapInspectionDetails: [], addCatLoader: false };
    // DELETED TEMPLATED DATA
    case ACTION_TYPES.GET_DELETED_TEMPLATE_DATA_RESPONSE:
      return { ...state, loader: false, deletedTemplateData: payload, addCatLoader: false };
    case ACTION_TYPES.GET_DELETED_TEMPLATE_DATA_ERROR:
      return { ...state, loader: false, deletedTemplateData: [], addCatLoader: false };
    //default lines
    case ACTION_TYPES.GET_CATEGORY_LINE_LIST_RESPONSE:
      return { ...state, loader: false, defaultLineData: payload, addLineLoader: false, scrolPage: false };
    case ACTION_TYPES.GET_CATEGORY_LINE_LIST_ERROR:
      return { ...state, loader: false, defaultLineData: [], addLineLoader: false, scrolPage: false };
    // media
    case ACTION_TYPES.UPLOAD_MEDIA_RESPONSE:
      return { ...state, loader: false, mediaUpdate: true };
    case ACTION_TYPES.UPLOAD_MEDIA_ERROR:
      return { ...state, loader: false, mediaUpdate: true };
    case ACTION_TYPES.BULK_UPLOAD_RESPONSE:
      return { ...state, loader: false, mediaUpdate: true, isBulkUploaded: true, bulkUploadMediaLength: 0 };
    case ACTION_TYPES.BULK_UPLOAD_ERROR:
      return { ...state, loader: false, mediaUpdate: true, isBulkUploaded: false, bulkUploadMediaLength: 0 };
    case ACTION_TYPES.MARK_COMPLETE_RESPONSE:
      return { ...state, loader: false, markflow: true, markResponse: true };
    case ACTION_TYPES.MARK_COMPLETE_ERROR:
      return { ...state, loader: false, markflow: true, markResponse: false };
    case ACTION_TYPES.PORCH_PDF_GENERATE_RESPONSE:
      return { ...state, loader: false, markflow: true, markResponse: true };
    case ACTION_TYPES.PORCH_PDF_GENERATE_ERROR:
      return { ...state, loader: false, markflow: false, markResponse: false };
    // attachment
    case ACTION_TYPES.UPLOAD_ATTACHMENT_RESPONSE:
      return { ...state, loader: false, attachmentUpdate: true, attachmentResponse: payload };
    case ACTION_TYPES.UPLOAD_ATTACHMENT_ERROR:
      return { ...state, loader: false, attachmentUpdate: true, attachmentResponse: payload };
    // duplicate lines
    case ACTION_TYPES.DUPLICATE_LINES_RESPONSE:
      return { ...state, loader: false, duplicateLineResponse: payload };
    case ACTION_TYPES.DUPLICATE_LINES_ERROR:
      return { ...state, loader: false, duplicateLineResponse: payload };
    // DUPLICATE CATEGORY
    // duplicate lines
    case ACTION_TYPES.DUPLICATE_CATEGORYS_RESPONSE:
      return { ...state, loader: false, duplicateCategoryResponse: payload };
    case ACTION_TYPES.DUPLICATE_CATEGORYS_ERROR:
      return { ...state, loader: false, duplicateCategoryResponse: payload };
    // ISN MARK complete
    case ACTION_TYPES.MARK_COMPLETE_RESPONSE_ISN:
      return { ...state, loader: false, markflow: true, markResponse: true };
    case ACTION_TYPES.MARK_COMPLETE_ERROR_ISN:
      return { ...state, loader: false, markflow: true, markResponse: false };
    case ACTION_TYPES.MARK_COMPLETE_ISN_SEND_REPORT_RESPONSE:
      return { ...state, loader: false, reportSend: true };
    case ACTION_TYPES.MARK_COMPLETE_ISN_SEND_REPORT_ERROR:
      return { ...state, loader: false, reportSend: false };
    // edit lines and categories
    case ACTION_TYPES.EDIT_CAT_STATUS:
      return { ...state, loader: false, markflow: true, markResponse: false, editCategory: payload };
    case ACTION_TYPES.EDIT_LINE_STATUS:
      return { ...state, loader: false, markflow: true, markResponse: false, editCategory: false, editLine: payload };
    //reorder
    case ACTION_TYPES.REORDER_CATEGORY_STATUS:
      return { ...state, loader: false, markflow: true, markResponse: false, reorderCategory: payload };
    case ACTION_TYPES.BULK_UPLOAD_MEDIA_LENGTH_STATUS:
      return { ...state, loader: false, markResponse: false, bulkUploadMediaLength: payload };
    case ACTION_TYPES.SUCCESSFUL_BULK_UPLOAD_MEDIA_LENGTH_STATUS:
      return { ...state, loader: false, markResponse: false, successfulBulkUploadMediaLength: payload };
    case ACTION_TYPES.FAIL_BULK_UPLOAD_MEDIA_LENGTH_STATUS:
      return { ...state, loader: false, markResponse: false, failBulkUploadMediaLength: payload };
    case ACTION_TYPES.FAILED_BULK_UPLOAD_MEDIA_STATUS:
      return { ...state, loader: false, markResponse: false, failedBulkUploadMedia: payload };
    case ACTION_TYPES.BULK_UPLOADING_STAUS:
      return { ...state, loader: false, markResponse: false, isbulkUploading: payload };
    case ACTION_TYPES.REORDER_LINES_STATUS:
      return { ...state, loader: false, markflow: true, markResponse: false, reorderCategory: false, reorderLines: payload };
    // ISN
    case ACTION_TYPES.GET_MASTER_TEMPLATES_SUCCESS:
      return { ...state, loader: false, IsnMasterTemplates: payload?.data };
    case ACTION_TYPES.GET_MASTER_TEMPLATES_ERROR:
      return { ...state, loader: false, IsnMasterTemplates: [] };
    case ACTION_TYPES.UPDATE_ISN_TEMPLATE_RESPONSE:
      return { ...state, loader: false, isnTempateUpdate: "success", templateUpdateLoader: false };
    case ACTION_TYPES.UPDATE_ISN_TEMPLATE_ERROR:
      return { ...state, loader: false, isnTempateUpdate: "fail", templateUpdateLoader: false };
    //comment library
    case ACTION_TYPES.GET_COMMENT_LIBRARY_LIST_LOADER:
      return { ...state, addCommentListLoader: true, mediaUpdate: false };
    case ACTION_TYPES.GET_COMMENT_LIBRARY_LIST_RESPONSE:
      return {
        ...state,
        loader: false,
        commentLibraryListData: payload,
        getClListStatus: true,
        totalListCount: payload?.total,
        addCommentListLoader: false,
        scrolPage: false,
        addCommentResponse: "",
        clCommentAdded: false,
        clCommentStatusChanged: false,
        narrativeCommentAdded: "",
      };
    case ACTION_TYPES.GET_COMMENT_LIBRARY_LIST_ERROR:
      return {
        ...state,
        loader: false,
        commentLibraryListData: payload?.response,
        getClListStatus: false,
        totalListCount: 0,
        addCommentListLoader: false,
        scrolPage: false,
        addCommentResponse: "",
        clCommentAdded: false,
        clCommentStatusChanged: false,
        narrativeCommentAdded: "",
      };
    case ACTION_TYPES.ADD_COMMENT_TOREPORT_FROM_CL_RESPONSE:
      return { ...state, loader: false, scrolPage: false, addCommentResponse: payload, clCommentAdded: true };
    case ACTION_TYPES.ADD_COMMENT_TOREPORT_FROM_CL_ERROR:
      return { ...state, scrolPage: false, addCommentResponse: payload, clCommentAdded: false };
    case ACTION_TYPES.UPDATE_CL_COMMENT_STATUS_RESPONSE:
      return { ...state, loader: false, scrolPage: false, clCommentChangeStatus: payload, clCommentStatusChanged: true };
    case ACTION_TYPES.UPDATE_CL_COMMENT_STATUS_ERROR:
      return { ...state, scrolPage: false, clCommentChangeStatus: payload, clCommentStatusChanged: false };
    case ACTION_TYPES.ADD_NEW_NARRATIVE_COMMENT_RESPONSE:
      return { ...state, loader: false, scrolPage: false, narrativeCommentAdded: true, addNarrativeCommentResponse: payload };
    case ACTION_TYPES.ADD_NEW_NARRATIVE_COMMENT_ERROR:
      return { ...state, scrolPage: false, narrativeCommentAdded: false, addNarrativeCommentResponse: payload };
    case ACTION_TYPES.GET_COMMENT_LIBRARY_TAG_LIST_RESPONSE:
      return { ...state, loader: false, clTag: payload, getClTagStatus: true };
    case ACTION_TYPES.GET_COMMENT_LIBRARY_TAG_LIST_ERROR:
      return { ...state, loader: false, clTag: payload.response, getClTagStatus: true };
    case ACTION_TYPES.GET_COMMENT_LIBRARY_SUMMARY_TAG_LIST_RESPONSE:
      return { ...state, loader: false, clSummaryTag: payload, getClSummaryStatus: true };
    case ACTION_TYPES.GET_COMMENT_LIBRARY_SUMMARY_TAG_LIST_ERROR:
      return { ...state, loader: false, clSummaryTag: payload.response, getClSummaryStatus: true };
    case ACTION_TYPES.UPDATE_DROPDOWNVALUES_RESPONSE: {
      let newData = state.commentLibraryListData.data;
      const commentIndex = newData.findIndex((item) => item.id === parseInt(payload?.comment_id));
      const dropdownIndex = newData[commentIndex]?.comment_dropdowns.findIndex((item) => item.dropdown_uuid === payload?.dropdown_uuid);
      const summaryIndex = newData[commentIndex]?.summary_comment_dropdowns.findIndex((item) => item.dropdown_uuid === payload?.dropdown_uuid);
      newData[commentIndex].comment_dropdowns[dropdownIndex] = { ...newData[commentIndex].comment_dropdowns[dropdownIndex], selected_value: payload?.selected_value };
      newData[commentIndex].summary_comment_dropdowns[summaryIndex] = { ...newData[commentIndex].summary_comment_dropdowns[summaryIndex], selected_value: payload?.selected_value };
      return { ...state, loader: false, dropdownChanged: true, commentLibraryListData: { ...state.commentLibraryListData, data: newData } };
    }
    case ACTION_TYPES.UPDATE_DROPDOWNVALUES_ERROR:
      return { ...state, loader: false, dropdownChanged: false };
    // report library
    case ACTION_TYPES.UPDATE_REPORT_SIDEBAR_STATUS:
      return { ...state, loader: false, scrolPage: false, reportSideBarActive: payload };
    case ACTION_TYPES.SHOW_COMMENT_LIBRARY_MODAL:
      return { ...state, showCommentLibraryModal: payload };
    default:
      return state;
  }
};
