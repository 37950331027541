export const ROUTES = {
    HOME: '/',
    INSPECTION: '/inspection',
    LINE: '/inspection/:categoryName',
    LINE_DETAILS:'/inspection/:categoryName/:lineName',
    REVIEW: '/review',
    REPORT_MEDIA_LIBRARY: '/report_media_library',
    FALLBACK: '*',
    PINTURAEDITOR:'/pinturaeditor',
};

export const colors = {
    SCHEDULED: '#1F2F9C',
    SCHEDULED_BG: '#C7DBF6',
    IN_PROGRESS: '#CB8530',
    IN_PROGRESS_BG: '#FFF9C8',
    COMPLETE: '#5A1F9C',
    COMPLETE_BG: '#F1DDFF',
    PUBLISHED: '#1F9C7B',
    PUBLISHED_BG: '#C7F6E0',
    DRAFT: '#F35F01',
    DRAFT_BG: '#FFECD2',
    CANCLED: '#E30303',
    CANCLED_BG: '#FFD7D2',
    ERROR: '#611A15',
    ERROR_BG: '#fdeceb',
    ERROR_ICON: '#F44336',
};

