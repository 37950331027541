import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { BootstrapTooltip } from '../../Common/MuiStyles/MuiStyles';
import { Grid } from '@mui/material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuidv4 } from 'uuid';
import '../../../Assets/Styles/Containers/_button.scss';
import { useDispatch } from 'react-redux';
import { GetUTCTime } from '../../Helpers/GetUTCTime';
import { toast } from 'react-hot-toast';
import { UpdateAttachment, UpdateTemplateData, UpdateTemplateDataSpecific } from '../../Helpers/ApiCall';
import { useEffect } from 'react';

const AttachmentModal = ({open,setOpen,edit,setEdit,editData,setEditData,attachmentFile,supportedFormat,inspectionData}) => {
   const [fileName, setFileName] = useState('');
   const [nameError, setNameError] = useState(false);
   const dispatch = useDispatch();
  //  const [nameErrorMsg, setNameErrorMsg] = useState('');

    const handleClose = () => {
      setOpen(false);
      setEdit(false);
      setFileName('')
      setNameError("")
    };

    const editAttachment=async()=>{
      let prevData = JSON.parse(JSON.stringify(inspectionData));
      let currentEditIndex = prevData?.attachments?.findIndex(el=>el.id === editData.id)
      prevData.attachments[currentEditIndex].description = fileName;
      prevData.attachments[currentEditIndex].updated_at = await GetUTCTime();
      inspectionData.attachments[currentEditIndex].description = fileName;
      inspectionData.attachments[currentEditIndex].updated_at = await GetUTCTime();
      prevData.inspectiontemplates=[];
      UpdateTemplateDataSpecific(dispatch,inspectionData,prevData);
      setOpen(false);
      setEdit(false);
      setFileName('')
    }

    const saveAttachment= async()=>{
      let fileSizeStatus = [];
      let file = attachmentFile
      const fileSize = file.size
      const sizeInMB = Math.round((fileSize / (1024*1024)));
      if(sizeInMB >= 200) {
        toast.error("File too Big, please select a file less than 200mb.")
        fileSizeStatus.push('false')
      }
      else fileSizeStatus.push('true')
    
      if(fileSizeStatus.every((el) => el === "true")){
        let formData = new FormData();
        let file = attachmentFile
            let fileType = null
            if (!file.type.match(/(image\/(png|jpg|jpeg))|(application\/pdf)/) || file?.name?.toLowerCase()?.endsWith(".jfif")) {
              fileType = "unsupported"
            }
            else if (file?.type === "image/jpeg" || file?.type === "image/png"
            || file?.type === "image/jpg"
            ) {
             fileType = "image"
           }else fileType = "file"

           if(fileType === "unsupported"){
            toast.error("Selected file format is not supported.")
           }
           else{
            let imgObj ={
              id: uuidv4(),
              url: null,
              name: file.name,
              size: file.size,
              type: file.type,
              edited: false,
              newUrl: null,
              online: "online",
              filetype: fileType,
              localUrl: null,
              OriginUrl: null,
              editCount: 0,
              onlineUrl: null,
              orginName: null,
              thumbnail: null,
              is_deleted: 0,
              order:null,
              updated_at: await GetUTCTime(),
              description: fileName,
              OriginlocalUrl: null,
              isOriginUploaded: true
          }
          let img_obj_string = JSON.stringify(imgObj)
          let params ={}                                     
            if(fileType === "image"){
              params = {
                image:file,
                inspection_guid:sessionStorage.getItem('guid'),
                img_object:img_obj_string
              }              
            }else if(fileType === "file"){
              params = {
                file:file,
                inspection_guid:sessionStorage.getItem('guid'),
                img_object:img_obj_string
              }    
            }            
            Object.keys(params).forEach((key) => formData.append(key, params[key]));
            UpdateAttachment(dispatch,formData)
            setFileName('')
            setEditData([])                     
           }
           
    } 
    setOpen(false)
    }

    const validateAttachement=(filename)=>{
      if(filename.trim() === ''){
        setNameError(true)
      }else {
        setNameError(false)
        if(edit) editAttachment()        
        else saveAttachment()
      }
    }

    useEffect(()=>{
      if(edit === true){
        setFileName(editData?.description)
      }
      
    },[edit])
  
    return (
      <div>       
        <Dialog className='dialog-container-attachment'
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
         <div className="close-wrapper-attachment">
            <CloseIcon onClick={handleClose} />
          </div>
          <DialogTitle className='attachment-dialog-title'>{`${edit? "Edit": "Add"} attachment`}</DialogTitle>
          <DialogContent className="dialog-content-attachment">
            <Grid container className="input-controll">
              <Grid item xs={12} >
                <FormControl variant="standard" className="forms-control-attachment">
                  <label className="input-form-label attach_label" htmlFor="Name">
                  File name
                  </label>
                  <TextField
                    disabled={!supportedFormat}
                    id="Name"
                    className="input-textfield"
                    size="small"
                    variant="outlined"
                    placeholder="Enter attachment name"
                    onChange={(e) => setFileName(e.target.value)}
                    error={nameError}
                    helperText={nameError ? "Name cannot be blank" : ""}
                    value={fileName}
                    type="text"
                  />
                </FormControl>
              </Grid>
             
             <Grid item xs={12} >
                {/* {edit && 
                editData.map(data=>(
                     <span className='attachment-lists'>
                        <span>{data.name}</span>
                        <span>
                        <BootstrapTooltip 
                                      title={<h1 className="font-13">Remove document</h1>}>
                                      <DeleteIcon className="delete-icon"  />  
                        </BootstrapTooltip>
                        </span>
                </span>
                ))
                
                } */}
                 <span className='attachment-lists'>
                        <span>{supportedFormat?edit? editData?.name: attachmentFile?.name?.length > 50? `${attachmentFile?.name?.substring(50,0)}...` :attachmentFile?.name:<span className='attachErrorText'>Invalid file format</span>}</span>
                 </span>
              </Grid>
            
              {/* <Grid item xs={12} >
             
      
              <span className="add_section" onClick={() => fileInput.current.click()} >
              <input
                ref={fileInput}
                type="file"
                accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.pdf"
                // onChange={handleClick}
                style={{ display: "none" }}
            />
                <ControlPointIcon className="add-icon" /> Browse file
                </span> */}
               {/* { true
            //    FileError
                && <Typography sx={{fontSize : '12px', color:'red', mt:"5px", textAlign: 'center'}} >Please select a file</Typography>} */}
              {/* </Grid> */}

             
            </Grid>
          </DialogContent>
          <DialogActions className="attach__actions">
          {/* <Button className="back-btn" onClick={handleClose}>
              Cancel
            </Button> */}
            <Button className={`default-btn ${!supportedFormat?'attachbtn_disabled':''}`} disabled={!supportedFormat} onClick={()=>validateAttachement(fileName)}>
              Done
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
}

export default AttachmentModal