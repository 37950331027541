import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Collapse, Divider, Grid, Typography } from "@mui/material";
import "../../../Assets/Styles/Containers/_collapsibleHeader.scss";
import "../../../Assets/Styles/Containers/_review.scss";
import { useEffect, useState } from "react";
import CommentReview from "./CommentReview";
import IncompleteItemReview from "./IncompleteItemReview";
import MediaReview from "./MediaReview";
import SummaryItemsReview from "./SummeryItemsReview";

const CollapsibleHeader = (props) => {
  const { heading, subHeading,inspectionResponse,defaultRating, summeryRating } = props;
  const [expand, setExpand] = useState(true);
  const colors = { grey: "#424242", blue: "#4282E2" };
  return (
    <>
      <Grid
        container
        className="collhead_container"
        onClick={() => setExpand(!expand)}
      >
        <div style={{ display: "block" }}>
          <Typography
            className="sub_heading"
            sx={{ color: expand ? colors.blue : colors.grey }}
          >
            {heading}
          </Typography>
          <Typography
            className="coll_header"
            sx={{ color: expand ? colors.blue : colors.grey }}
          >
            {heading == "Summary items" ? (
              <>
                {JSON?.parse(subHeading)
                  ?.map((el) => " " + el?.count + " " + el?.name)
                  ?.join(", ") == ""
                  ? summeryRating.map(
                      (rating) => "0" + " " + rating?.Name + ", "
                    )
                  : 
                  JSON?.parse(subHeading)
                      ?.map((el) => " " + el?.count + " " + el?.name)
                      ?.join(", ")}
              </>
            ) : (
              subHeading
            )}
          </Typography>
        </div>
        {expand ? (
          <KeyboardArrowUpIcon
            fontSize="large"
            className="updrop_icon"
            onClick={() => setExpand(!expand)}
          />
        ) : (
          <KeyboardArrowDownIcon
            fontSize="large"
            className="drop_icon"
            onClick={() => setExpand(!expand)}
          />
        )}
      </Grid>
      <Collapse in={expand}>
        {heading == "Comments" && (
          <CommentReview
            inspectionResponse={inspectionResponse?.inspectiontemplates}
            inspectionData={inspectionResponse}
            defaultRating={defaultRating}
          />
        )}
        {heading == "Incomplete items" && (
          <IncompleteItemReview
            inspectionResponse={inspectionResponse?.inspectiontemplates}
            inspectionData={inspectionResponse}
          />
        )}
        {heading == "Media" && (
          <MediaReview
            inspectionResponse={inspectionResponse?.inspectiontemplates}
            inspectionData={inspectionResponse}
          />
        )}
        {heading == "Summary items" && (
          <SummaryItemsReview
            inspectionResponse={inspectionResponse?.inspectiontemplates}
            inspectionData={inspectionResponse}
            summeryRating={summeryRating}
          />
        )}
      </Collapse>
      <Divider className="divider" />
    </>
  );
};

export default CollapsibleHeader;
