import { FormControl, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import { useEffect,useState } from 'react';
import { updateInspectionDataForAddInp } from '../../Redux/Saga/Dashboard';

const AdditionalInputLine = ({line,handleEditAdditionalInput,render}) => {
    const [ selectedAdditionalInfo,setSelectedAdditionalInfo] = useState();
    const updateInfo=(info)=>{
      setSelectedAdditionalInfo(info?.data)
    }
    useEffect(() => {
        updateInfo(line)
        
    }, [line,render])
  
  return ( 
    <div className="additionalInput" style={{paddingLeft:'15px'}}>
    <p>
      {line.prompt}
    </p>
   <FormControl sx={{ m: 1,width:'100%',margin:'0'}} variant="outlined">
        <OutlinedInput            
            id="outlined-adornment-weight"
            className='location-input'
            size="small"
            type="text"
            fullWidth
            onClick={()=>handleEditAdditionalInput(line)}
            autoComplete='off'
            sx={{"& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "black",
                },
              }}
            value={selectedAdditionalInfo?.join(", ")}
            aria-describedby="outlined-weight-helper-text"
            placeholder=""
        />
    </FormControl>
    </div>
   
    
  )
}

export default AdditionalInputLine