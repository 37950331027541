import moment from "moment";
import { Base64 } from "js-base64";
import { useState, useEffect } from "react";
import Preloader from "../../Helpers/Preloader";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { markReportComplete,markReportCompleteISN ,porchPdfGenerate} from "../../Helpers/ApiCall";
import "../../../Assets/Styles/Containers/_modal.scss";
import { getInspectionData } from "../../Redux/Actions/CommonActions";
import { Box, Grid, Modal, FormGroup, FormControlLabel, Checkbox, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import LoadButton from "../Button/LoadButton";
import { toast } from "react-hot-toast";

const MarkAsComplete = ({ open, onClose, isPaid, isSigned, orderStatus, userID, roleID, guid, handleModalClose, companySettings,paymentEnable,agreementEnable,agreementCount }) => {
  const dispatch = useDispatch();
  const [mapaid, setMapaid] = useState(false);
  const [masigned, setMasigned] = useState(false);
  const [loader, setLoader] = useState(false);
  const [markCompleteToast, setMarkCompleteToast] = useState(false);
  const storeData = useSelector((state) => state.CommonReducer);
  const handleMAPaid = (event) => setMapaid(event.target.checked);
  const handleMASigned = (event) => setMasigned(event.target.checked);
  useEffect(() => {
    if (storeData?.loader) setLoader(true);
    else setLoader(false);
  }, [storeData?.loader]);

  useEffect(() => {
    if (storeData?.markflow) handleModalClose();
  }, [storeData?.markflow]);
  useEffect(() => {
    if (storeData?.markResponse) {
      let formData
      let sessionAssociateCompanyKey = Base64.decode(sessionStorage.getItem("AC"));
      let guid = sessionStorage.getItem("guid");
      let sessionUserId = Base64.decode(sessionStorage.getItem("UI"));
      dispatch(getInspectionData({ guid: guid, associate_company: sessionAssociateCompanyKey, user_id: sessionUserId }));
      if(Base64.decode(sessionStorage.getItem('AC')) === "Palmtech"){
        formData = {
          guid: guid,
          user_id: userID,
          company_id: companySettings.id,
          status:mapaid && masigned ? "Published" : paymentEnable === 2 && agreementEnable === 1 ?isPaid === 1 && agreementCount === 0?"Published": isPaid === 1 && isSigned === 1?"Published": mapaid && agreementCount === 0? "Published" : mapaid && masigned ? "Published":"Complete" :(paymentEnable === 0 || paymentEnable === 1) && agreementEnable === 0?"Published":paymentEnable === 2 && isPaid?"Published":paymentEnable === 2 && mapaid?"Published": agreementEnable === 1 && agreementCount === 0?"Published": agreementEnable === 1 && isSigned === 1?"Published": agreementEnable === 1 && masigned? "Published": "Complete",
        }
        porchPdfGenerate(dispatch, formData)
      }
      
    }
  }, [storeData?.markResponse]);

  useEffect(() => {
    if (storeData?.markResponse && markCompleteToast) {
      handleModalClose();
     toast.success("Inspection has been marked as complete.")
     setMarkCompleteToast(false)
    }
  }, [storeData?.markResponse]);
  
  const markComplete = () => { 
    let guid = sessionStorage.getItem("guid"); 
    let formData 
    if(Base64.decode(sessionStorage.getItem('AC')) === "Palmtech"){
      formData = {
      guid: guid,
      user_id: userID,
      role_id: roleID,
      company_id: companySettings.id,
      mark_agreement_signed: isSigned?"1":masigned ? "1" : "0",
      // status: mapaid && masigned ? "Published" : "Complete",
      status:mapaid && masigned ? "Published" : paymentEnable === 2 && agreementEnable === 1 ?isPaid === 1 && agreementCount === 0?"Published": isPaid === 1 && isSigned === 1?"Published": mapaid && agreementCount === 0? "Published" : mapaid && masigned ? "Published":"Complete" :(paymentEnable === 0 || paymentEnable === 1) && agreementEnable === 0?"Published":paymentEnable === 2 && isPaid?"Published":paymentEnable === 2 && mapaid?"Published": agreementEnable === 1 && agreementCount === 0?"Published": agreementEnable === 1 && isSigned === 1?"Published": agreementEnable === 1 && masigned? "Published": "Complete",
      status_date: new Date().toISOString()?.replace("T"," ").substring(0, 19),
      mark_as_paid: companySettings?.is_payment_enable === 2 ? isPaid === 1? "1" : !mapaid ? "0" : "1":"0",
    }
    setMarkCompleteToast(true)
    markReportComplete(dispatch, formData)
  }
  else{
      formData = {
      inspection_guid: guid,
      order_id: guid,      
    }
    setMarkCompleteToast(true)
    markReportCompleteISN(dispatch, formData)
  }
  };
  return (
    <>
      {/* <Preloader showPreloader={loader} /> */}
      <Modal open={open} onClose={onClose} BackdropProps={{ style: { opacity: "60%" } }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className="mark-as-complete-modal-main">
        <Box className="mark-as-complete-modal" sx={{ minHeight: "231px !important", top: "40% !important" }}>
          <Grid container>
            <Grid item xs={12}>
              <CloseIcon sx={{ float: "right", cursor: "pointer", color: "#A5A5A5", mt: 1, mr: 1 }} onClick={onClose} />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <p className="modal-heading">Mark this inspection as complete</p>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", textAlign: "center", mt: "14px", justifyContent: "center" }}>
              <p className="modal-content">
                {companySettings?.is_payment_enable === 2 && companySettings?.is_agreement_enable === 1
                  ? isPaid !== 0 && (isSigned !== 0 || agreementCount === 0)
                    ? "This action cannot be undone. This action will send a link containing the report to the client's email based on the notifications settings in your portal."
                    : isPaid === 1 && isSigned === 0
                    ? "This inspection has an unsigned agreement. Marking the inspection complete will trigger automated communications to your client based on the notifications settings in your portal."
                    : isPaid === 0 && (isSigned === 1 || agreementCount === 0)
                    ? "This inspection is unpaid. Marking the inspection complete will trigger automated communications to your client based on the notifications settings in your portal."
                    : "This inspection is unpaid, and has an unsigned agreement. Marking the inspection complete will trigger automated communications to your client based on the notifications settings in your portal."
                  : companySettings?.is_payment_enable !== 2 && companySettings?.is_agreement_enable === 1
                  ? (isSigned === 0 && agreementCount !== 0)
                    ? "This inspection has an unsigned agreement. Marking the inspection complete will trigger automated communications to your client based on the notifications settings in your portal."
                    : isSigned === 1
                    ? "This action cannot be undone. This action will send a link containing the report to the client's email based on the notifications settings in your portal."
                    :  "This action cannot be undone. This action will send a link containing the report to the client's email based on the notifications settings in your portal."
                  : companySettings?.is_payment_enable === 2 && companySettings?.is_agreement_enable === 0
                  ? isPaid === 0
                    ? "This inspection is unpaid. Marking the inspection complete will trigger automated communications to your client based on the notifications settings in your portal."
                    : isPaid === 1
                    ? "This action cannot be undone. This action will send a link containing the report to the client's email based on the notifications settings in your portal."
                    : ""
                  : "This action cannot be undone. This action will send a link containing the report to the client's email based on the notifications settings in your portal."}
              </p>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", mt: "30px" }}>
              <FormGroup sx={{ maxWidth: "425px", paddingLeft: { xs: "0px", md: "30px", lg: "30px" } }}>
                {companySettings?.is_payment_enable === 2 && isPaid === 0 ? <FormControlLabel control={<Checkbox color="default" value={mapaid} onChange={handleMAPaid} />} label={<Typography sx={{ userSelect: "none", fontSize: "14px", fontWeight: "600", lineHeight: "20px", letterSpacing: "0px", color: "#3b3b3b" }}>Also waive the inspection payment (no more payment reminders will be sent to the client).</Typography>} /> : ""}
                {companySettings?.is_agreement_enable === 1 && isSigned === 0 && agreementCount !==0? <FormControlLabel control={<Checkbox color="default" value={masigned} onChange={handleMASigned} />} label={<Typography sx={{ userSelect: "none", fontSize: "14px", fontWeight: "600", lineHeight: "20px", letterSpacing: "0px", color: "#3b3b3b", mt: "21px" }}>Also waive the agreement signature (no more agreement reminders will be sent to the client).</Typography>} /> : ""}
              </FormGroup>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", textAlign: "center", mt: "24px", mb: 3, justifyContent: "center" }}>
                 <LoadButton width="318px" text="mark complete" onClick={markComplete} loading={loader} />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default MarkAsComplete;