import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { useSelector } from "react-redux";
import { categoryMediaCheck, mediaCheck, templateMediaCheck, videoCheck } from "../../Helpers/HelperFunctions";
import ImageCarousel from "../../Components/LineDetails/ImageCarousel";
import { useState } from "react";
import ImageViewer from "./ImageViewer";
// import ImageViewer from 'awesome-image-viewer'

const MediaReview = ({ inspectionResponse }) => {
  const storeData = useSelector((state) => state.CommonReducer);
  const [mediaPlayer, setMediaPlayer] = useState(false);
  const [imageviewer, setImageviewer] = useState(false);
  const [link, setLink] = useState("");
  const [imagelink, setImageLink] = useState("");
  const [videoId, SetVideoId] = useState("");
  const [mediaName, setMediaName] = useState("");
  const [mediaLineName, setMediaLineName] = useState("");
  const [imageLineName, setImageLineName] = useState("");
  const [imageCategoryName, setImageCategoryName] = useState("");
  let filterCat = null;

  const imgPreview = (imgUrl, categoryName, lineName) => {
    setImageLink(imgUrl);
    setImageLineName(lineName);
    setImageCategoryName(categoryName);
    setImageviewer(true);
  };

  const pdfImgPreview = (imgUrl, categoryName, ElmName) => {
    setImageLink(imgUrl);
    setImageLineName("");
    setImageviewer(true);
  };
  function mergeObjectsByCategoryId(data) {
    const mergedObjects = {};
    data?.forEach((item) => {
      if (item) {
        [item].forEach((innerItem) => {
          const categoryId = innerItem.category_id;
          if (!mergedObjects[categoryId]) {
            mergedObjects[categoryId] = { ...innerItem };
          } else {
            mergedObjects[categoryId].value = mergedObjects[categoryId].value.concat(innerItem.value);
          }
        });
      }
    });
    return Object.values(mergedObjects);
  }

  return (
    <>
      {inspectionResponse?.map((template) => {
        return template?.is_pdf_template !== 1 ? (
          templateMediaCheck(template) ? (
            <Grid container className="coll_inner_div">
              <span className="incomplete_temp">{template.name}</span>
              <div className="coll_div right_padding">
                <div className="card_wrapper">
                  {template?.categories?.map(
                    (category) =>
                      category?.is_deleted !== 1 &&
                      categoryMediaCheck(category, "all") && (
                        <>
                          <div className={`coll_heading2 `}>{category.prompt === null ? "Untitled" : category.prompt}</div>
                          <div>
                            <div className="review_media_wrapper">
                              {category?.lines
                                ?.filter((itm) => itm?.is_deleted !== 1)
                                ?.map(
                                  (line) =>
                                    (mediaCheck(line, template?.is_narrative_template === 1 ? true : false)?.length > 0 ||
                                      videoCheck(line, template?.is_narrative_template === 1 ? true : false)?.length > 0) &&
                                    line?.line_input_controls !== null &&
                                    line?.line_input_controls !== undefined &&
                                    line?.line_input_controls.length !== 0 &&
                                    line?.line_input_controls
                                      ?.filter((data) => data.type === "Media")
                                      ?.map((dataInner) =>
                                        dataInner.data.map((image) => {
                                          let thumbnailUrl = image.is_portal == 1
                                              ? `${storeData.inspectionData?.data?.bucket_url_2}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`
                                              : `${storeData.inspectionData?.data?.bucket_url}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`;
                                          let onlineUrl = image.is_portal == 1 
                                            ? `${storeData.inspectionData?.data?.bucket_url_2}${image.onlineUrl}` : `${storeData.inspectionData?.data?.bucket_url}${image.onlineUrl}`;
                                          return image?.online === "online" && image?.is_deleted !== 1 ? (
                                            image?.filetype === "image" ? (
                                              <img
                                                className="review_media"
                                                style={{ cursor: "pointer", objectFit: "scale-down", backgroundColor: "#FAFAFA" }}
                                                src={thumbnailUrl}
                                                onClick={() => {
                                                  setImageCategoryName(category?.prompt);
                                                  imgPreview(onlineUrl, category?.prompt === null ? "Untitled" : category?.prompt, line?.prompt === null ? "Untitled" : line?.prompt);
                                                }}
                                                alt="linemedia"
                                              ></img>
                                            ) : (
                                              <div className="thumb_wrapper_review video_thumb_container">
                                                <img
                                                  className="review_media"
                                                  style={{ cursor: "pointer", objectFit: "scale-down", backgroundColor: "#FAFAFA" }}
                                                  src={thumbnailUrl}
                                                  alt=""
                                                  onClick={() => {
                                                    setMediaPlayer(true);
                                                    setMediaLineName(line?.prompt);
                                                    setMediaName(category?.prompt);
                                                    SetVideoId(image?.id);
                                                    setLink(onlineUrl);
                                                  }}
                                                />
                                                <PlayCircleIcon
                                                  className="play_icon"
                                                  onClick={() => {
                                                    setMediaPlayer(true);
                                                    SetVideoId(image?.id);
                                                    setMediaLineName(line?.prompt);
                                                    setMediaName(category?.prompt);
                                                    setLink(onlineUrl);
                                                  }}
                                                />
                                              </div>
                                            )
                                          ) : (
                                            ""
                                          );
                                        })
                                      )
                                )}
                              {category?.lines
                                ?.filter((itm) => itm?.is_deleted !== 1)
                                ?.map(
                                  (line) =>
                                    (mediaCheck(line, template?.is_narrative_template === 1 ? true : false)?.length > 0 ||
                                      videoCheck(line, template?.is_narrative_template === 1 ? true : false)?.length > 0) &&
                                    line.comments.length !== 0 &&
                                    line?.comments?.map(
                                      (comment) =>
                                        comment.is_selected === 1 &&
                                        comment?.comment_input_controls
                                          ?.filter((data) => data.type === "Media")
                                          ?.map((itm) =>
                                            itm.data?.map((image) => {
                                              let thumbnailUrl =
                                                image.is_portal == 1
                                                  ? `${storeData.inspectionData?.data?.bucket_url_2}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`
                                                  : `${storeData.inspectionData?.data?.bucket_url}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`;
                                              let onlineUrl =
                                                image.is_portal == 1
                                                  ? `${storeData.inspectionData?.data?.bucket_url_2}${image.onlineUrl}`
                                                  : `${storeData.inspectionData?.data?.bucket_url}${image.onlineUrl}`;

                                              return image.online === "online" && image.is_deleted !== 1 ? (
                                                image.filetype === "image" ? (
                                                  <>
                                                    <img
                                                      className="review_media"
                                                      style={{ cursor: "pointer", objectFit: "scale-down", backgroundColor: "#FAFAFA" }}
                                                      src={thumbnailUrl}
                                                      onClick={() => {
                                                        setImageCategoryName(category?.prompt);
                                                        imgPreview(onlineUrl, category?.prompt === null ? "Untitled" : category?.prompt, line?.prompt === null ? "Untitled" : line?.prompt);
                                                      }}
                                                      alt="linemedia"
                                                    ></img>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="thumb_wrapper_review video_thumb_container">
                                                      <img
                                                        className="review_media"
                                                        style={{ cursor: "pointer", objectFit: "scale-down", backgroundColor: "#FAFAFA" }}
                                                        src={thumbnailUrl}
                                                        alt=""
                                                        onClick={() => {
                                                          setMediaPlayer(true);
                                                          setMediaName(category?.prompt);
                                                          setMediaLineName(line?.prompt);
                                                          SetVideoId(image?.id);
                                                          setLink(onlineUrl);
                                                        }}
                                                      />
                                                      <PlayCircleIcon
                                                        className="play_icon"
                                                        onClick={() => {
                                                          setMediaPlayer(true);
                                                          setMediaName(category?.prompt);
                                                          setMediaLineName(line?.prompt);
                                                          SetVideoId(image?.id);
                                                          setLink(onlineUrl);
                                                        }}
                                                      />
                                                    </div>
                                                  </>
                                                )
                                              ) : (
                                                ""
                                              );
                                            })
                                          )
                                    )
                                )}
                            </div>
                          </div>
                        </>
                      )
                  )}
                </div>
              </div>
              {mediaPlayer && <ImageCarousel link={link} mediaPlayer={mediaPlayer} setMediaPlayer={setMediaPlayer} videoId={videoId} mediaName={mediaName} mediaLineName={mediaLineName} />}
              {imageviewer && <ImageViewer imageviewer={imageviewer} setImageviewer={setImageviewer} imageLink={imagelink} imageLineName={imageLineName} imageCategoryName={imageCategoryName} />}
            </Grid>
          ) : (
            ""
          )
        ) : templateMediaCheck(template) ? (
          <Grid container className="coll_inner_div">
            <span className="incomplete_temp">{template.name}</span>
            <div className="coll_div right_padding">
              <div className="card_wrapper">
                {mergeObjectsByCategoryId(
                  template?.pdf_data?.filter((category) => {
                    return category?.type === "MediaUploader" && category;
                  })
                )?.map((item) => {
                  if (item.value.length !== 0) {
                    return (
                      <>
                        <div className={`coll_heading2 `}>{item?.category_name}</div>
                        <div className="review_media_wrapper">
                          {item?.value?.map((image) => {
                            let thumbnailUrl =
                              image.is_portal == 1
                                ? `${storeData.inspectionData?.data?.bucket_url_2}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`
                                : `${storeData.inspectionData?.data?.bucket_url}${image.thumbnail !== null ? image.thumbnail : image.onlineUrl}`;
                            let onlineUrl = image.is_portal == 1 ? `${storeData.inspectionData?.data?.bucket_url_2}${image.onlineUrl}` : `${storeData.inspectionData?.data?.bucket_url}${image.onlineUrl}`;
                            return (
                              <>
                                {image.online === "online" && image.is_deleted !== 1 ? (
                                  image.filetype === "image" ? (
                                    <>
                                      <img
                                        className="review_media"
                                        style={{ cursor: "pointer", objectFit: "scale-down", backgroundColor: "#FAFAFA" }}
                                        src={thumbnailUrl}
                                        onClick={() => {
                                          setImageCategoryName(item?.category_name);
                                          pdfImgPreview(onlineUrl, filterCat, item);
                                        }}
                                        alt="linemedia"
                                      ></img>
                                    </>
                                  ) : (
                                    <>
                                      <div className="thumb_wrapper_review video_thumb_container">
                                        <img
                                          className="review_media"
                                          style={{ cursor: "pointer", objectFit: "scale-down", backgroundColor: "#FAFAFA" }}
                                          src={thumbnailUrl}
                                          alt=""
                                          onClick={() => {
                                            setMediaPlayer(true);
                                            setMediaLineName("");
                                            setMediaName(item?.category_name);
                                            SetVideoId(image?.id);
                                            setLink(onlineUrl);
                                          }}
                                        />
                                        <PlayCircleIcon
                                          className="play_icon"
                                          onClick={() => {
                                            setMediaPlayer(true);
                                            setMediaName(item?.category_name);
                                            setMediaLineName("");
                                            SetVideoId(image?.id);
                                            setLink(onlineUrl);
                                          }}
                                        />
                                      </div>
                                    </>
                                  )
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          })}
                        </div>
                      </>
                    );
                  } else return null;
                })}
              </div>
            </div>
            {mediaPlayer && <ImageCarousel link={link} mediaPlayer={mediaPlayer} setMediaPlayer={setMediaPlayer} videoId={videoId} mediaName={mediaName} mediaLineName={mediaLineName} />}
            {imageviewer && <ImageViewer imageviewer={imageviewer} setImageviewer={setImageviewer} imageLink={imagelink} imageLineName={imageLineName} imageCategoryName={imageCategoryName} />}
          </Grid>
        ) : (
          ""
        );
      })}
    </>
  );
};

export default MediaReview;
