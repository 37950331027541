import useMediaQuery from "@mui/material/useMediaQuery";
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import Resizer from "react-image-file-resizer";
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
// import VideoTrimmer from 'react-video-trimmer';

export function GetCurrentResolution() {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const isTablet = useMediaQuery("(max-width:900px)");
  const isLaptop = useMediaQuery("(max-width:1200px)");
  const isDesktop = useMediaQuery("(max-width:1536px)");

  if (isMobile) {
    return "mobile";
  } else if (isTablet) {
    return "tablet";
  } else if (isLaptop) {
    return "laptop";
  } else if (isDesktop) {
    return "desktop";
  }
}

// for table component

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,

  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
  },
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  'color': 'inherit',
  '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
          width: '20ch',
      },
  },
}));

// compress image
export const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

  // compress video

// export const compressVideo = async (videoFile, quality) => {
//   const ffmpeg = require('ffmpeg-static');
//   const ffprobe = require('ffprobe-static');
//   const { createFFmpeg, fetchFile } = require('@ffmpeg/ffmpeg');

//   const ffmpegInstance = createFFmpeg({ log: true });
//   await ffmpegInstance.load();

//   ffmpegInstance.FS('writeFile', 'input.mp4', await fetchFile(videoFile));
//   await ffmpegInstance.run('-i', 'input.mp4', '-codec', 'copy', '-preset', `ultrafast`, '-crf', quality, 'output.mp4');

//   const data = ffmpegInstance.FS('readFile', 'output.mp4');
//   const compressedFile = new File([data.buffer], 'output.mp4', { type: 'video/mp4' });

//   return compressedFile;
// };