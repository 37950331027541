import { useEffect, useState, useRef } from "react";
import { Divider, Grid, Typography } from "@mui/material";
// import { attachmentData } from "../../Common/MuiStyles/MuiStyles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import "../../../Assets/Styles/Containers/_attachments.scss";
import AttachmentModal from "./AttachmentModal";
import { Box, Modal } from "@mui/material";
import { GetUTCTime } from "../../Helpers/GetUTCTime";
import { useDispatch, useSelector } from "react-redux";
import { UpdateTemplateDataSpecific } from "../../Helpers/ApiCall";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-hot-toast";
import FilledButton from "../Button/FilledButton";
import DeleteConfirmationDialog from "../../Common/Alert/DeleteConfirmationDialog";

const Attachments = ({ inspectionData }) => {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editData, setEditData] = useState([]);
  const [attachmentFile, setAttachmentFile] = useState("");
  const [attachmentData, setAttachmentData] = useState([]);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [supportedFormat, setSupportedFormat] = useState(false);
  const [attachmentDeleteToast, setAttachmentDeleteToast] = useState(false);
  const [currentDeleteAttachData, setCurrentDeleteAttachData] = useState();

  const fileInput = useRef();
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.CommonReducer);

  const handleEdit = (attachment) => {
    setEdit(true);
    setOpen(true);
    setEditData(attachment);
    setSupportedFormat(true);
  };

  const handleCloseDeleteAlert = () => {
    setDeleteAlert(false);
    setEdit(false);
  };

  const handleConfirmDelete = async () => {
    let prevData = JSON.parse(JSON.stringify(inspectionData));
    let currentDelIndex = prevData?.attachments?.findIndex(
      (el) => el.id === currentDeleteAttachData.id
    );
    prevData.attachments[currentDelIndex].is_deleted = 1;
    prevData.attachments[currentDelIndex].updated_at = await GetUTCTime();
    prevData.inspectiontemplates=[];
    setAttachmentDeleteToast(true);
    UpdateTemplateDataSpecific(dispatch,inspectionData,prevData);
    setDeleteAlert(false);
  };

  const handleDelete = (attachment) => {
    setEditData([]);
    setDeleteAlert(true);
    setCurrentDeleteAttachData(attachment);
  };
  const handleSelectAttachment = (e) => {
    let fileSizeStatus = [];
    let file = e.target.files[0];
    const fileSize = file.size;
    const sizeInMB = Math.round(fileSize / (1024 * 1024));
    if (sizeInMB >= 200) {
      toast.error("File too Big, please select a file less than 200mb.");
      fileSizeStatus.push("false");
    } else fileSizeStatus.push("true");

    if (fileSizeStatus.every((el) => el === "true")) {
      let file = e.target.files[0];
      let fileType = null;
      if (!file.type.match(/(image\/(png|jpg|jpeg))|(application\/pdf)/) || file?.name?.toLowerCase()?.endsWith(".jfif")) {
        fileType = "unsupported";
        setAttachmentFile(e.target.files[0]);
        setOpen(true);
        setSupportedFormat(false);
      } else {
        if (
          file?.type === "image/jpeg" ||
          file?.type === "image/png" ||
          file?.type === "image/jpg"
        ) {
          fileType = "image";
        } else fileType = "file";
        setAttachmentFile(e.target.files[0]);
        setOpen(true);
        setSupportedFormat(true);
      }
    }
  };

  useEffect(() => {
    if (
      inspectionData?.attachments !== null ||
      inspectionData?.attachments !== ""
    )
      setAttachmentData(inspectionData?.attachments);
    else setAttachmentData([]);
  }, [inspectionData]);

  useEffect(() => {
    if (storeData?.inspectionData?.success === true && attachmentDeleteToast) {
      toast.success("Attachment has been removed.");
      setAttachmentDeleteToast(false);
    }
  }, [handleConfirmDelete]);
  return (
    <div>
      <AttachmentModal
        open={open}
        setOpen={setOpen}
        editData={editData}
        setEditData={setEditData}
        edit={edit}
        setEdit={setEdit}
        attachmentFile={attachmentFile}
        supportedFormat={supportedFormat}
        inspectionData={inspectionData}
      />
      <Typography className="title">Attachments</Typography>
      <Typography className="subtitle">
        Attachments will appear in the inspection report.
      </Typography>
      <Divider />
      <Grid container className="attachment__wrapper">
        {attachmentData?.length !== 0 ? (
          attachmentData?.map((attachment, index) =>
            attachment.is_deleted !== 1 ? (
              <>
                <Grid
                  item
                  xs={12}
                  className="attachement__content__container"
                  key={index}
                >
                  <div className="attachment__content">
                    <Typography className="attachement__name">
                      {attachment.description}
                    </Typography>
                    <Typography className="attachement__image">
                      {attachment.name}
                    </Typography>
                  </div>
                  <div className="attachment__actions">
                    <EditIcon
                      className="edit__icon"
                      onClick={() => handleEdit(attachment)}
                      id={`edit_attachment_${index}`}
                    />
                    <DeleteIcon
                      className="delete__icon"
                      onClick={() => handleDelete(attachment)}
                      id={`delete_attachment_${index}`}
                    />
                  </div>
                </Grid>
                <Divider style={{ width: "100%", marginTop: "10px" }} />
              </>
            ) : (
              ""
            )
          )
        ) : (
          <Grid item xs={12} className="attachement__content__container">
            No attachments added
          </Grid>
        )}
      </Grid>
      <div
        className="add_attachment"
        onClick={() => fileInput.current.click()}
        // onClick={handleAdd} // old implementation
        id="add_attachment"
      >
        <AddCircleIcon id="add_attachment_plus" />{" "}
        <span id="add_attachment_text">Add attachment</span>
        <input
          ref={fileInput}
          type="file"
          accept="image/*,.pdf"
          onChange={(e) => handleSelectAttachment(e)}
          style={{ display: "none" }}
        />
      </div>
      {/* delete modal */}
      <DeleteConfirmationDialog
        open={deleteAlert}
        handleClose={handleCloseDeleteAlert}
        title={"Are you sure to delete this file?"}
        contentText={`${currentDeleteAttachData?.name}`}
        cancelBtnTitle={"Cancel"}
        cancelBtnAction={handleCloseDeleteAlert}
        submitBtnTitle={"Delete"}
        submitBtnAction={handleConfirmDelete}
      />
    </div>
  );
};

export default Attachments;
