import { Checkbox, Collapse, Divider, FormControlLabel, FormGroup, Grid } from "@mui/material";
import React from "react";
import { useState } from "react";
import NumberElement from "./NumberElement";
import DateElement from "./DateElement";
import PlanTextElement from "./PlanTextElement";
import YearElement from "./YearElement";
import TextElement from "./TextElement";
import CheckBoxElement from "./CheckBoxElement";
import DigitalSignature from "./DigitalSignature";
import RadioElement from "./RadioElement";
import InformationalTextHTMLElement from "./InformationalTextHTMLElement";
import MultiLinesInputElement from "./MultiLinesInputElement";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { GetUTCTime } from "../../../Helpers/GetUTCTime";
import { UpdateTemplateDataSpecific } from "../../../Helpers/ApiCall";
import { useEffect } from "react";
import { stringify } from "json5";
import MediaUploadElement from "./MediaUploadElement";
import TableElement from "./TableElement";
import LayoutGroupElement from "./LayoutGroupElement";
import { addCheckFlagToPdfData, filterPdfTemplateData, getMergeIds } from "../../../Helpers/HelperFunctions";

function ExpandingCheckbox({
  element,
  elementType,
  nestingLevel = 0,
  templateId,
  width,
  setWidth,
  height,
  setHeight,
  pdfCategoriesType,
  templateGuid,
  templateData,
  pdfCategoryData,
  level,
  orderForMedia,
  onDragtoggle,
  setOnDragToggle,
  selectedMediaFiles,
  setSelectedMediaFiles,
  selectedImages,
  setSelectedImages,
}) {
  const [expendingCheckBoxSelected, setExpendingCheckBoxSelected] = useState(0);
  const storeData = useSelector((state) => state.CommonReducer);
  const dispatch = useDispatch();

  let templateIndex = storeData?.inspectionData?.data?.inspectiontemplates?.findIndex((itm) => itm.template_id === templateId);
  let prevData = storeData?.inspectionData?.data;
  let pdfTemplate = prevData?.inspectiontemplates[templateIndex];
  let mergeId = element?.merge_id;
  let ElementpdfData = pdfTemplate?.pdf_data?.filter((item) => item?.merge_id == mergeId);
  let MediaPrevData = JSON?.parse(JSON?.stringify(storeData?.inspectionData?.data));
  let MediaElementpdfData = MediaPrevData?.inspectiontemplates[templateIndex];

  const handleCheckboxChange = () => {
    setExpendingCheckBoxSelected((prevValue) => (prevValue == 0 ? 1 : 0));
    HandleUpdateCheckBoxApiCall(expendingCheckBoxSelected == 0 ? 1 : 0);
  };

  const HandleUpdateCheckBoxApiCall = async (val) => {
    let newPrevData = JSON.parse(JSON.stringify(storeData?.inspectionData?.data));
    let templateIndex = storeData?.inspectionData?.data?.inspectiontemplates?.findIndex((itm) => itm.template_id === templateId);
    let newPdfTemplate = newPrevData?.inspectiontemplates[templateIndex];
    let formData = {
      type: "Layout/ExpandingCheckBox",
      merge_id: mergeId,
      value: stringify(val),
      updated_at: await GetUTCTime(),
    };
    const mergeIdsArray = getMergeIds(element);
    const pdfDataWithCheckFlag = await addCheckFlagToPdfData(newPdfTemplate?.pdf_data, mergeIdsArray, val === 1 ? true : false);
    let formDataArray = [...pdfDataWithCheckFlag, formData];
    pdfTemplate.pdf_data = formDataArray;
    let filtereddata = MediaElementpdfData.pdf_data.filter((el) => el.merge_id !== mergeId);
    filtereddata.push(formData);
    MediaElementpdfData.pdf_data = filtereddata;
    let filteredObject = filterPdfTemplateData(prevData, templateIndex, filtereddata, mergeId);
    UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
  };

  useEffect(() => {
    let value = ElementpdfData?.[0]?.value;
    setExpendingCheckBoxSelected(value == undefined || value == null ? "0" : value);
  }, [pdfCategoryData]);

  return (
    <>
      <Grid container className={`${elementType === "outer" ? "pdf__container" : "pdf_inner__container"}`}>
        <Grid item xs={12}>
          <Grid className={`comment_detail_title ${level == 3 ? "level3_checkBoxclass" : ""}`}>
            <span className={`comment_title ${Number(expendingCheckBoxSelected) ? "box_checked" : "box_unchecked"}`}>
              <FormGroup>
                <FormControlLabel
                  className="checkbox"
                  control={
                    <Checkbox
                      sx={{
                        height: "19.5px !important",
                        width: "19.5px !important",
                        "& .MuiSvgIcon-root": { fontSize: "20px !important" },
                      }}
                      checked={parseInt(expendingCheckBoxSelected)}
                      onClick={() => {
                        handleCheckboxChange();
                      }}
                    />
                  }
                />
              </FormGroup>
              <span
                className={
                  pdfCategoriesType == 1
                    ? (elementType !== "inner" && elementType !== "layout") || expendingCheckBoxSelected == 0
                      ? "comment_title_single checkbox_label"
                      : "comment_title_single_after_selecting checkbox_label"
                    : "comment_title_single checkbox_label"
                }
                onClick={() => {
                  handleCheckboxChange();
                }}
              >
                {element?.label}
              </span>
            </span>
          </Grid>
          <Collapse in={parseInt(expendingCheckBoxSelected) && nestingLevel < 3}>
            {element?.elements?.map((elm) => (
              <>
                {elm?.type === "Input/Number" && (
                  <>
                    <Grid container sx={{ mt: "17px" }}>
                      <NumberElement element={elm} elementType="inner" templateId={templateId} pdfCategoriesType={pdfCategoriesType} pdfCategoryData={pdfCategoryData} level={level + 1} />
                    </Grid>
                  </>
                )}
                {elm?.type === "Input/Text" && (
                  <>
                    <Grid container sx={{ mt: "17px" }}>
                      <TextElement element={elm} elementType="inner" templateId={templateId} pdfCategoriesType={pdfCategoriesType} pdfCategoryData={pdfCategoryData} level={level + 1} />
                    </Grid>
                  </>
                )}
                {elm?.type === "Input/Year" && (
                  <>
                    <Grid container sx={{ mt: "17px" }}>
                      <YearElement element={elm} elementType="inner" templateId={templateId} pdfCategoriesType={pdfCategoriesType} pdfCategoryData={pdfCategoryData} level={level + 1} />
                    </Grid>
                  </>
                )}
                {elm?.type === "Input/Date" && (
                  <>
                    <Grid container sx={{ mt: "17px" }}>
                      <DateElement element={elm} elementType="inner" templateId={templateId} pdfCategoriesType={pdfCategoriesType} pdfCategoryData={pdfCategoryData} level={level + 1} />
                    </Grid>
                  </>
                )}
                {(elm?.type === "Signature/Any" || elm?.type === "Signature/Inspector") && (
                  <>
                    <Grid container sx={{ mt: "17px" }}>
                      <DigitalSignature
                        element={elm}
                        elementType="inner"
                        templateId={templateId}
                        templateGuid={templateGuid}
                        templateData={templateData}
                        pdfCategoriesType={pdfCategoriesType}
                        pdfCategoryData={pdfCategoryData}
                        level={level + 1}
                      />
                    </Grid>
                  </>
                )}
                {elm?.type === "CheckBox" && (
                  <>
                    <Grid container sx={{ mt: "17px" }}>
                      <CheckBoxElement element={elm} elementType="inner" templateId={templateId} pdfCategoriesType={pdfCategoriesType} pdfCategoryData={pdfCategoryData} level={level + 1} />
                    </Grid>
                  </>
                )}
                {elm?.type === "InformationText/Plain" && (
                  <>
                    <Grid container sx={{ mt: "17px" }}>
                      <PlanTextElement element={elm} elementType="inner" />
                    </Grid>
                  </>
                )}
                {elm?.type === "MediaUploader" && (
                  <>
                    <Grid container sx={{ mt: "17px" }}>
                      <MediaUploadElement
                        element={elm}
                        elementType="inner"
                        templateId={templateId}
                        width={setWidth}
                        setWidth={setWidth}
                        height={height}
                        setHeight={setHeight}
                        onDragtoggle={onDragtoggle}
                        setOnDragToggle={setOnDragToggle}
                        selectedMediaFiles={selectedMediaFiles}
                        setSelectedMediaFiles={setSelectedMediaFiles}
                        selectedImages={selectedImages}
                        setSelectedImages={setSelectedImages}
                        pdfCategoriesType={pdfCategoriesType}
                        pdfCategoryData={pdfCategoryData}
                        level={level + 1}
                        orderForMedia={`${orderForMedia}.${elm?.order === undefined ? "" : elm?.order}`}
                      />
                    </Grid>
                  </>
                )}
                {elm?.type === "Layout/Table" && (
                  <>
                    <Grid container sx={{ mt: "17px" }}>
                      <TableElement element={elm} elementType="inner" level={level + 1} templateId={templateId} pdfCategoryData={pdfCategoryData} />
                    </Grid>
                  </>
                )}
                {elm?.type === "Layout/Group" && (
                  <>
                    <Grid container sx={{ mt: "17px" }}>
                      <LayoutGroupElement
                        element={elm}
                        onDragtoggle={onDragtoggle}
                        setOnDragToggle={setOnDragToggle}
                        selectedMediaFiles={selectedMediaFiles}
                        setSelectedMediaFiles={setSelectedMediaFiles}
                        selectedImages={selectedImages}
                        setSelectedImages={setSelectedImages}
                        elementType="inner"
                        templateId={templateId}
                        pdfCategoryData={pdfCategoryData}
                        level={level + 1}
                        orderForMedia={element.order === undefined ? "" : element.order}
                      />
                    </Grid>
                  </>
                )}
                {elm?.type == "Divider" && (
                  <>
                    <Grid container>
                      <Divider
                        style={{
                          width: "100%",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      />
                    </Grid>
                  </>
                )}
                {elm?.type === "Radio" && (
                  <>
                    <Grid container sx={{ mt: "17px" }}>
                      <RadioElement element={elm} elementType="inner" templateId={templateId} level={level + 1} pdfCategoryData={pdfCategoryData} pdfCategoriesType={pdfCategoriesType} />
                    </Grid>
                  </>
                )}
                {elm?.type === "InformationText/Html" && (
                  <>
                    <Grid container sx={{ mt: "17px" }}>
                      <InformationalTextHTMLElement element={elm} elementType="inner" />
                    </Grid>
                  </>
                )}
                {elm?.type === "Input/Multiline" && (
                  <>
                    <Grid container sx={{ mt: "17px" }}>
                      <MultiLinesInputElement element={elm} elementType="inner" templateId={templateId} pdfCategoriesType={pdfCategoriesType} pdfCategoryData={pdfCategoryData} level={level + 1} />
                    </Grid>
                  </>
                )}
                {elm?.type === "Layout/ExpandingCheckBox" && (
                  <>
                    <Grid container sx={{ mt: "17px" }}>
                      <ExpandingCheckbox
                        element={elm}
                        onDragtoggle={onDragtoggle}
                        selectedMediaFiles={selectedMediaFiles}
                        selectedImages={selectedImages}
                        nestingLevel={nestingLevel + 1}
                        templateGuid={templateGuid}
                        templateData={templateData}
                        elementType="inner"
                        templateId={templateId}
                        pdfCategoriesType={pdfCategoriesType}
                        pdfCategoryData={pdfCategoryData}
                        level={level + 1}
                        orderForMedia={element.order === undefined ? "" : element.order}
                      />
                    </Grid>
                  </>
                )}
              </>
            ))}
          </Collapse>
        </Grid>
      </Grid>
    </>
  );
}

export default ExpandingCheckbox;
