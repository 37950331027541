import { FormControl, IconButton, InputAdornment, OutlinedInput, useMediaQuery } from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import MessageIcon from '@mui/icons-material/Message';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import {useState,useEffect,useRef } from 'react';
import { useNavigate} from 'react-router-dom';
import AddLineDescriptionModal from './AddLineDescriptionModal';
import CreateNewDescriptionModal from './CreateNewDescriptionModal';
import { GetUTCTime } from '../../Helpers/GetUTCTime';
import AdditionalInputLine from './AdditionalInputLine';
// import {UpdateMedia} from '../../Helpers/ApiCall';
import { useDispatch,useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {generateVideoThumbnails} from "@rajesh896/video-thumbnails-generator";
import { toast } from 'react-hot-toast';
import { Base64 } from "js-base64";
import { origin } from '../../Config/Api-constants';
import axios from 'axios';
import { activateLines, getInspectionData } from '../../Redux/Actions/CommonActions';
import Compressor from 'compressorjs';
import { checkDescriptionDisabled, getImageDimensions, resizeImage } from '../../Helpers/HelperFunctions';

const LineDescription = ({line,templateId,categoryId,categoryData,template_id,category_id,category_uuid,lineUuid,
  render,action='line',loader,setLoader,setLoaderText,setLoader2,lineIndex,isNarrative}) => {
    // states
    const [descriptionArray,setDescriptionArray] = useState([]);
    const [descriptionArrayCopy,setDescriptionArrayCopy] = useState([]);
    const [selectedDescription,setSelectedDescription] = useState([])
    const [showAddDescriptionModal,setShowAddDescriptionModal] = useState(false);
    const [tempSelectedDescription,setTempSeletedDescrition] = useState([])
    const [showCreateDescriptionModal,setShowCreateDescriptionModal] = useState(false);
    const [additionalInputs,setAdditionalInputs] = useState([])
    const [editAadditionalInp,setEditAadditionalInp] = useState(false)
    const [currentAdditionalInputID,setCurrentAdditionalInputID] = useState();
    const [descriptionPresent, setDescriptionPresent] = useState(false);
    const [addInputPresent, setAddInputPresent] = useState(false);
    const [addInputChanged,setAddInputChanged] = useState(false);
    const [showTemperatureModal,setShowTemperatureModal] = useState(false);
    const [showTextBoxModal,setShowTextBoxModal] = useState(false);
    const [is_saved,setISSaved] = useState(false);
    const [lineMediaData,setLineMediaData] = useState('');
    const [newEmptyDescription,setNewEmptyDescription] = useState(false);
    const [refresh,setRefresh] = useState(false);
    const [image,setImage] = useState('');
    const [descriptionData, setDescriptionData] = useState();
    const [descriptionDisable, setDescriptionDisable]= useState()
    let responseCount = 0;
    let videoUploadCount = 0;
    let imageuploadCount = 0;
    let successCount = 0 ;
    let errorCount = 0;
    let sizeErrorCount = 0;
    const matches = useMediaQuery("(max-width:900px)");
    const navigate = useNavigate();
    const fileInputLine = useRef();
     // redux
    const dispatch = useDispatch();
    const storeData = useSelector((state) => state.CommonReducer);

    const getLineDesValue=(line)=>{
        let selected = line?.line_input_controls?.filter(lineData=>(
          lineData.type === "Description"
        ))[0]?.data     
        setSelectedDescription(selected === undefined?[]:selected) 
        let additionalInp = line?.line_input_controls?.filter(lineData=>(
          lineData.type === "Additional Input"
        ))
       
        if(selected?.length !== 0) setDescriptionPresent(true)
        else setDescriptionPresent(false);
        setAdditionalInputs(additionalInp)
        let DescData = line?.line_input_controls?.find(lineData=>(lineData.type === "Description"))
        setDescriptionData(DescData)
      }

      const getAdditionalInputvalues=(item)=>{
        return item.data
      }
    // add media
    async function convertBase64ToFile(base64String, filename,file,formData,fileType,currentMediaLength,orderVal,e,videoCount) {
      setLoaderText(`uploading video ${videoUploadCount+1} of ${videoCount}`)
      const arr = base64String[0].split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      
      let thumbVideoFile = new File([u8arr], filename, {type:mime})
      let thumbFile;
      const { imgWidth, imgHeight } = await getImageDimensions(thumbVideoFile);
      const maxWidth = 320; 
      const maxHeight = 320;
      const quality = 1;
      let newWidth, newHeight;
      if (imgWidth > imgHeight) {
        // Landscape orientation
        newHeight = maxHeight;
        newWidth = (maxHeight / imgHeight) * imgWidth;
      } else {
        // Portrait orientation
        newWidth = maxWidth;
        newHeight = (maxWidth / imgWidth) * imgHeight;            
      }
      let thumbnailImage = await resizeImage(thumbVideoFile, newWidth, newHeight, quality);
      thumbFile = new File([thumbnailImage], `${thumbnailImage.name}`, { type: 'image/jpeg' });
            let imgObj ={
              id: uuidv4(),
              url: null,
              name: file.name,
              size: file.size,
              type: file.type,
              edited: false,
              newUrl: null,
              online: "online",
              filetype: fileType,
              localUrl: null,
              OriginUrl: null,
              editCount: 0,
              onlineUrl: null,
              orginName: null,
              thumbnail: null,
              is_deleted: 0,
              order:currentMediaLength+orderVal,
              updated_at: await GetUTCTime(),
              description: null,
              OriginlocalUrl: null,
              isOriginUploaded: true
          }
          let img_obj_string = JSON.stringify(imgObj)
          let params ={}
          if(action === "line"){
            params = {
              video:file,
              inspection_guid:sessionStorage.getItem('guid'),
              template_id:templateId,
              video_thumbnail:thumbFile,
              category_id:category_uuid,
              line_id:lineUuid,
              img_object:img_obj_string,
              img_type:'details',
            }
          }     
          Object.keys(params).forEach((key) => formData.append(key, params[key]));
         
           // sync API call
           let baseUrl = await origin();
           let sessionAPIKey = sessionStorage.getItem('CK');
           let sessionSecretKey = sessionStorage.getItem('SK')
           try {
             const response = await axios.post(`${baseUrl}upload_media_for_inspection_template`, formData, {
               headers: {
                 'Content-Type': 'multipart/form-data',
                 'Authorization': `Bearer ${Base64.decode(sessionSecretKey)}`,
                 'api-key': `${Base64.decode(sessionAPIKey)}`,
                 'platform':'web',
               }
             });                 
             const result = response.data;
             responseCount++;
             successCount++;
             videoUploadCount++;
             } catch (error) {
               console.error(`Error posting data for user:`, error);
               toast.error(error?.message)
               responseCount++;
               errorCount++;
               videoUploadCount++;
             }            
             if(responseCount === e.target.files.length) {
              if(successCount>0) toast.success(`${successCount} ${successCount ===1?'media item has':'media items have'} been uploaded successfully. `,{
                duration: 4000, 
              })
              if(errorCount>0) toast.error(`${(errorCount - sizeErrorCount) > 0 ?`${errorCount - sizeErrorCount}  media ${(errorCount - sizeErrorCount) === 1?'item was':'items were'} not uploaded, the file format is not supported.`:''} ${sizeErrorCount > 0? `${sizeErrorCount} Media ${sizeErrorCount===1?'item was':'items were'} not uploaded because the media file is greater than 1024MB.`:""}`,{
                duration: 5000, // Set the timeout duration to 3000 milliseconds (3 seconds)
              })
              callInitiateAPI()
              fileInputLine.current.value = null;
              responseCount=0;
              videoUploadCount=0;
              imageuploadCount=0;
              errorCount = 0;
              sizeErrorCount = 0;
              successCount = 0;
             }
    }
      
    const callInitiateAPI=()=>{ 
      setLoader2(false)   
      setLoaderText('') 
        let sessionAssociateCompanyKey = Base64.decode(sessionStorage.getItem('AC'));
        let guid = sessionStorage.getItem('guid')
        let sessionUserId = Base64.decode(sessionStorage.getItem('UI'));
        dispatch(getInspectionData({guid:guid,associate_company:sessionAssociateCompanyKey,user_id:sessionUserId}))
        setLoader(false)
    }

    async function compressImage(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.6,
          success(result) {
            resolve(result);
          },
          error(err) {
            reject(err);
          },
        });
      });
    }
    const activateLine=(uuid)=>{
      dispatch(activateLines(uuid))
    }

    const handleAddMedia=async(e)=>{    
      let imageCount = 0;
      let videoCount = 0;
      let currentMediaLength = lineMediaData?.length;
      let fileSizeStatus = [];
      let formatStatus = []
      setImage(e.target.files);
      for (let i = 1; i <= e.target.files.length; i++) {    
        let formData = new FormData();
        let file = e.target.files.item(i-1)
              
        const fileSize = file.size
        const sizeInMB = Math.round((fileSize / (1024*1024)));
                let fileTypeCheck = null
                if (file?.type === "image/jpeg" || file?.type === "image/png"
                || file?.type === "image/jpg") {
                 fileTypeCheck = "image"
               }else if (file?.type === "image/tiff" || file?.type === "image/bmp"
               || file?.type === "image/gif" || file?.type === "image/svg"
               || file?.type === "image/ico" || file?.type === "image/x-icon") {
                 fileTypeCheck = "unsupported"
               }
               else {
                 fileTypeCheck = "video"
               } 
               if(fileTypeCheck === "image" || fileTypeCheck === "unsupported") imageCount++
               if(fileTypeCheck === "video") videoCount++ 
        } 
        // new v1 

     
          setLoader2(true)
          if(e.target.files.length === 0) setLoader2(false)
          for (let i = 1; i <= e.target.files.length; i++) {    
            let formData = new FormData();          
            let file = e.target.files.item(i-1)
                let fileType = null
                if (file?.type === "image/jpeg" || file?.type === "image/png"
                || file?.type === "image/jpg") {
                 fileType = "image"
               }else if (file?.type === "image/tiff" || file?.type === "image/bmp"
               || file?.type === "image/gif" || file?.type === "image/svg"
               || file?.type === "image/ico" || file?.type === "image/x-icon"){
                 fileType = "unsupported"
               }
               else {
                 fileType = "video"
               } 
                const fileSize = file.size
                const sizeInMB = Math.round((fileSize / (1024*1024)));
              if(sizeInMB >= 1024) {
                // toast.error("File size too Big, please select a file less than 500 MB");
                errorCount++;
                responseCount++;
                sizeErrorCount++;
                if(responseCount === e.target.files.length) {
                  if(successCount>0) toast.success(`${successCount} ${successCount ===1?'media item has':'media items have'} been uploaded successfully. `,{
                    duration: 4000, 
                  })
                  if(errorCount>0) toast.error(`${(errorCount - sizeErrorCount) > 0 ?`${errorCount - sizeErrorCount}  media ${(errorCount - sizeErrorCount) === 1?'item was':'items were'} not uploaded, the file format is not supported.`:''} ${sizeErrorCount > 0? `${sizeErrorCount} Media ${sizeErrorCount===1?'item was':'items were'} not uploaded because the media file is greater than 1024MB.`:""}`,{
                    duration: 5000, // Set the timeout duration to 3000 milliseconds (3 seconds)
                  })
                  callInitiateAPI()
                  fileInputLine.current.value = null;
                  responseCount=0;
                  videoUploadCount=0;
                  imageuploadCount=0;
                  errorCount = 0;
                  sizeErrorCount = 0;
                  successCount = 0;
                }
              } else if(!file.type.match(/(image\/(png|jpg|jpeg))|(video\/(mp4|mov|quicktime))/) || file?.name?.toLowerCase()?.endsWith(".jfif")){
                errorCount++;
                responseCount++
                if(responseCount === e.target.files.length) {
                  if(successCount>0) toast.success(`${successCount} ${successCount ===1?'media item has':'media items have'} been uploaded successfully. `,{
                    duration: 4000, 
                  })
                  if(errorCount>0) toast.error(`${(errorCount - sizeErrorCount) > 0 ?`${errorCount - sizeErrorCount}  media ${(errorCount - sizeErrorCount) === 1?'item was':'items were'} not uploaded, the file format is not supported.`:''} ${sizeErrorCount > 0? `${sizeErrorCount} Media ${sizeErrorCount===1?'item was':'items were'} not uploaded because the media file is greater than 1024MB.`:""}`,{
                    duration: 5000, // Set the timeout duration to 3000 milliseconds (3 seconds)
                  })
                  callInitiateAPI()
                  fileInputLine.current.value = null;
                  responseCount=0;
                  videoUploadCount=0;
                  imageuploadCount=0;
                  errorCount = 0;
                  sizeErrorCount = 0;
                  successCount = 0;
                }
              }  
              else{
                if(fileType === "image"){
                  setLoaderText(`compressing image ${imageuploadCount+1} of ${imageCount}`)
                  let compressedFile;
                  let thumbImage;
                  const { imgWidth, imgHeight } = await getImageDimensions(file);
                  const maxWidth = 320; 
                  const maxHeight = 320;
                  const quality = 0.8;
                  let newWidth, newHeight;
                  if (imgWidth > imgHeight) {
                    // Landscape orientation
                    newHeight = maxHeight;
                    newWidth = (maxHeight / imgHeight) * imgWidth;
                  } else {
                    // Portrait orientation
                    newWidth = maxWidth;
                    newHeight = (maxWidth / imgWidth) * imgHeight;            
                  }
                  let thumbnailImage = await resizeImage(file, newWidth, newHeight, quality);
                  thumbImage = new File([thumbnailImage], `${thumbnailImage.name}`, { type: 'image/jpeg' });
                 if(file?.size > 512000){
                    // =====
                    try {
                      const compressedFilee = await compressImage(file);
                      // console.log(compressedFile);
                        // Handle the compressed file
                          // Create a new file object with the compressed image file
                          compressedFile = new File([compressedFilee], `${compressedFilee.name}`, { type: 'image/jpeg' });
                          setLoaderText(`uploading image ${imageuploadCount+1} of ${imageCount}`)
                  let imgObj ={
                    id: uuidv4(),
                    url: null,
                    name: compressedFile.name,
                    size: compressedFile.size,
                    type: compressedFile.type,
                    edited: false,
                    newUrl: null,
                    online: "online",
                    filetype: fileType,
                    localUrl: null,
                    OriginUrl: null,
                    editCount: 0,
                    onlineUrl: null,
                    orginName: null,
                    thumbnail: null,
                    is_deleted: 0,
                    order:currentMediaLength+i,
                    updated_at: await GetUTCTime(),
                    description: null,
                    OriginlocalUrl: null,
                    isOriginUploaded: true
                }
                  let img_obj_string = JSON.stringify(imgObj)
                  let params ={}
                  if(action === "line"){
                    params = {
                      image:compressedFile,
                      video_thumbnail:thumbImage,
                      inspection_guid:sessionStorage.getItem('guid'),
                      template_id:templateId,
                      category_id:category_uuid,
                      line_id:lineUuid,
                      img_object:img_obj_string,
                      img_type:'details',
                    }
                  }
                  
                  Object.keys(params).forEach((key) => formData.append(key, params[key]));
                
                 
                  // sync API call
                  let baseUrl = await origin();
                  let sessionAPIKey = sessionStorage.getItem('CK');
                  let sessionSecretKey = sessionStorage.getItem('SK')
                  try {
                    const response = await axios.post(`${baseUrl}upload_media_for_inspection_template`, formData, {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${Base64.decode(sessionSecretKey)}`,
                        'api-key': `${Base64.decode(sessionAPIKey)}`,
                        'platform':'web',
                      }
                    });                 
                    const result = response.data;
                      responseCount++;
                      successCount++;
                      imageuploadCount++;
                      // if(i === e.target.files.length) callInitiateAPI()
                    } catch (error) {
                      responseCount++;
                      errorCount++;
                      imageuploadCount++;
                      toast.error(error?.message);
                      console.error(`Error posting data for user:`, error);
                      // if(i === e.target.files.length) callInitiateAPI()
                    }
                  
                    if(responseCount === e.target.files.length) {
                      if(successCount>0) toast.success(`${successCount} ${successCount ===1?'media item has':'media items have'} been uploaded successfully. `,{
                        duration: 4000, 
                      })
                      if(errorCount>0) toast.error(`${(errorCount - sizeErrorCount) > 0 ?`${errorCount - sizeErrorCount}  media ${(errorCount - sizeErrorCount) === 1?'item was':'items were'} not uploaded, the file format is not supported.`:''} ${sizeErrorCount > 0? `${sizeErrorCount} Media ${sizeErrorCount===1?'item was':'items were'} not uploaded because the media file is greater than 1024MB.`:""}`,{
                        duration: 5000, // Set the timeout duration to 3000 milliseconds (3 seconds)
                      })
                      callInitiateAPI()
                      fileInputLine.current.value = null;
                      responseCount=0;
                      videoUploadCount=0;
                      imageuploadCount=0;
                      errorCount = 0;
                      sizeErrorCount = 0;
                      successCount = 0;
                    }
                    } catch (error) {
                      console.error('Error compressing image:', error);
                    }
                    // =====
                  }
                  // compressedFile = await resizeFile(e.target.files.item(i-1));                  
                  else {
                    compressedFile = file;
                    setLoaderText(`uploading image ${imageuploadCount+1} of ${imageCount}`)
                    let imgObj ={
                      id: uuidv4(),
                      url: null,
                      name: compressedFile.name,
                      size: compressedFile.size,
                      type: compressedFile.type,
                      edited: false,
                      newUrl: null,
                      online: "online",
                      filetype: fileType,
                      localUrl: null,
                      OriginUrl: null,
                      editCount: 0,
                      onlineUrl: null,
                      orginName: null,
                      thumbnail: null,
                      is_deleted: 0,
                      order:currentMediaLength+i,
                      updated_at: await GetUTCTime(),
                      description: null,
                      OriginlocalUrl: null,
                      isOriginUploaded: true
                  }
                    let img_obj_string = JSON.stringify(imgObj)
                    let params ={}
                    if(action === "line"){
                      params = {
                        image:compressedFile,
                        video_thumbnail:thumbImage,
                        inspection_guid:sessionStorage.getItem('guid'),
                        template_id:templateId,
                        category_id:category_uuid,
                        line_id:lineUuid,
                        img_object:img_obj_string,
                        img_type:'details',
                      }
                    }
                    
                    Object.keys(params).forEach((key) => formData.append(key, params[key]));
                  
                   
                    // sync API call
                    let baseUrl = await origin();
                    let sessionAPIKey = sessionStorage.getItem('CK');
                    let sessionSecretKey = sessionStorage.getItem('SK')
                    try {
                      const response = await axios.post(`${baseUrl}upload_media_for_inspection_template`, formData, {
                        headers: {
                          'Content-Type': 'multipart/form-data',
                          'Authorization': `Bearer ${Base64.decode(sessionSecretKey)}`,
                          'api-key': `${Base64.decode(sessionAPIKey)}`,
                          'platform':'web',
                        }
                      });                 
                      const result = response.data;
                        responseCount++;
                        imageuploadCount++;
                        successCount++;
                        // if(i === e.target.files.length) callInitiateAPI()
                      } catch (error) {
                        responseCount++
                        errorCount++
                        imageuploadCount++;
                        toast.error(error?.message);
                        console.error(`Error posting data for user:`, error);
                        // if(i === e.target.files.length) callInitiateAPI()
                      }
                    
                      if(responseCount === e.target.files.length) {
                        if(successCount>0) toast.success(`${successCount} ${successCount ===1?'media item has':'media items have'} been uploaded successfully. `,{
                          duration: 4000, 
                        })
                        if(errorCount>0) toast.error(`${(errorCount - sizeErrorCount) > 0 ?`${errorCount - sizeErrorCount}  media ${(errorCount - sizeErrorCount) === 1?'item was':'items were'} not uploaded, the file format is not supported.`:''} ${sizeErrorCount > 0? `${sizeErrorCount} Media ${sizeErrorCount===1?'item was':'items were'} not uploaded because the media file is greater than 1024MB.`:""}`,{
                          duration: 5000, // Set the timeout duration to 3000 milliseconds (3 seconds)
                        })
                        callInitiateAPI()
                        fileInputLine.current.value = null;
                        responseCount=0;
                        videoUploadCount=0;
                        imageuploadCount=0;
                        errorCount = 0;
                        sizeErrorCount = 0;
                        successCount = 0;
                      }
                  }    
                // sync api call
                // handleMediaUpload(formData)
                }
                else if(fileType === "video"){ 
                  setLoader2(true)
                  setLoaderText(`generating thumbnail for video ${videoUploadCount+1} of ${videoCount}`)
                  await generateVideoThumbnails(file, 10).then(async(thumbs) => {
                   
               
                  await convertBase64ToFile(thumbs, 'name',file,formData,fileType,currentMediaLength,i,e,videoCount)
                  });
                 
                }   
                     
              }

        }      

        // new v1
      
    }
    // const handleMediaUpload=(formData)=>{
    //   UpdateMedia(dispatch,formData)
    //   }
    
    const handleEditDescription=(line)=>{
      setAddInputChanged(false)
      setEditAadditionalInp(false)
        setShowAddDescriptionModal(true);
        let descriptionValues = line?.line_input_controls?.find(data=>(
            data.type === "Description"
        ))?.values
        setDescriptionArray(descriptionValues !== undefined?descriptionValues:[] )
        setDescriptionArrayCopy(descriptionValues !== undefined?descriptionValues:[] )
    }

    const handleEditAdditionalInput=(line)=>{
      setTempSeletedDescrition(selectedDescription)
      setAddInputPresent(true)
      setAddInputChanged(true);
      setEditAadditionalInp(true)
      setCurrentAdditionalInputID(line.line_input_control_id)
      setShowAddDescriptionModal(true);
      let descriptionValues = line?.values
      setDescriptionArray(descriptionValues !== undefined?descriptionValues:[] )
      setDescriptionArrayCopy(descriptionValues !== undefined?descriptionValues:[] )
      setSelectedDescription(line.data)
      if(line?.data?.length !== 0) setAddInputPresent(true)
      else setAddInputPresent(false);
  }

    const handleDescriptionSelection=async(description)=>{
      let tempData;
      if(selectedDescription.length === 0){
         tempData = descriptionArray;
      }else{
        tempData = JSON.parse(JSON.stringify(descriptionArray));
      }
      
     
      if(selectedDescription.length === 0){
        setSelectedDescription([description])
      } else {
      
        let sltdDesc = selectedDescription;
        let sltd_desc = [];
  
        let desIndex = sltdDesc.findIndex(item=> item === description)
        if(desIndex !== -1){
          sltd_desc =  sltdDesc.filter(prevDescription=>prevDescription !== description)
        } else sltd_desc = [...sltdDesc , description]
  

        setSelectedDescription(sltd_desc)
      }
          
            let idx = tempData.findIndex(itm=> itm.value === description)
            if(idx !== -1){
              tempData[idx].is_selected = tempData[idx].is_selected === true || tempData[idx].is_selected === 'true'? false:true;
              tempData[idx].updated_at = await GetUTCTime();
              setDescriptionArray(tempData) 
              setRefresh(prev=>!prev)
            }
            else{
              if(tempData.findIndex((x) => x !== description)) {
                tempData.push({
                  uuid: uuidv4(),
                  value: description,
                  is_deleted: 0,
                  updated_at:await GetUTCTime(),
                  is_selected: true,
                  created_at:await GetUTCTime()
                });
                setDescriptionArray(tempData) 
                setRefresh(prev=>!prev)
              }
            }          
    }

    const getMediaData=(line)=>{
      let medData = line?.line_input_controls?.find(data=>data.type === "Media")?.data
      setLineMediaData(medData);
    }

    useEffect(() => {
        getLineDesValue(line)
        let disableCheck = line?.line_input_controls?.filter(lineData=>(lineData.type === "Description"))[0]?.is_disabled   
        setDescriptionDisable(disableCheck)
    }, [is_saved,categoryData])

    useEffect(()=>{
      getMediaData(line)
    },[])

    useEffect(() => {
      setLoader2(false) ;
      setLoaderText('')
      setLoader(false)
    }, [storeData.updatedInspectionData])
   
    
  return (
    <>
    <AddLineDescriptionModal 
      descriptionData={descriptionData}
      showAddDescriptionModal={showAddDescriptionModal} 
      setShowAddDescriptionModal={setShowAddDescriptionModal} 
      setShowCreateDescriptionModal={setShowCreateDescriptionModal} 
      descriptionArray={descriptionArray} 
      setDescriptionArray={setDescriptionArray}
      selectedDescription={selectedDescription} 
      setSelectedDescription={setSelectedDescription} 
      handleDescriptionSelection={handleDescriptionSelection}
      showTemperatureModal={showTemperatureModal} 
      setShowTemperatureModal={setShowTemperatureModal}
      showTextBoxModal={showTextBoxModal} 
      setShowTextBoxModal={setShowTextBoxModal}
      is_saved={is_saved}
      setISSaved={setISSaved} 
      templateId={templateId} 
      categoryId={categoryId} 
      lineUuid={lineUuid}
      editAadditionalInp={editAadditionalInp}
      currentAdditionalInputID={currentAdditionalInputID}
      descriptionPresent={descriptionPresent}
      addInputChanged={addInputChanged}
      addInputPresent={addInputPresent}
      setAddInputChanged={setAddInputChanged}
      showCreateDescriptionModal={showCreateDescriptionModal}
      newEmptyDescription={newEmptyDescription}
      setNewEmptyDescription={setNewEmptyDescription}
    /> 
    <CreateNewDescriptionModal showCreateDescriptionModal={showCreateDescriptionModal} setShowCreateDescriptionModal={setShowCreateDescriptionModal} showAddDescriptionModal={showAddDescriptionModal} setShowAddDescriptionModal={setShowAddDescriptionModal} descriptionArray={descriptionArray} setDescriptionArray={setDescriptionArray} descriptionArrayCopy={descriptionArrayCopy} handleDescriptionSelection={handleDescriptionSelection} selectedDescription={selectedDescription} descriptionData={descriptionData} templateId={templateId} categoryId={categoryId} lineUuid={lineUuid} newEmptyDescription={newEmptyDescription} setNewEmptyDescription={setNewEmptyDescription} editAadditionalInp={editAadditionalInp} currentAdditionalInputID={currentAdditionalInputID} />
    <div className='linecontent_body' style={{display:checkDescriptionDisabled(line) || isNarrative ?"none":"flex"}}>
    <FormControl sx={{ m: 1,width:'100%',margin:'0'}} variant="outlined">
        <OutlinedInput            
            id={`description_${lineIndex+1}`}
            className={`${descriptionDisable == 1 || isNarrative ? 'lineVisibility' : 'location-input'}`}
            size="small"
            type="text"
            fullWidth
            autoComplete='off'
            onClick={()=>handleEditDescription(line)}
            sx={{"& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "black",
                },
              }}
            value={addInputChanged?tempSelectedDescription?.join(", "):selectedDescription?.join(", ")}
            endAdornment={
                <InputAdornment position="end">
                <IconButton    
                    disableRipple               
                    aria-label="edit decription"
                    onClick={()=>handleEditDescription(line)}
                    edge="end"
                    id={`description_icon_${lineIndex+1}`}
                >
                    {<EditIcon/>} 
                </IconButton>
                </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
            // placeholder="Select Description"
            placeholder={descriptionData === undefined ? "Description" : (descriptionData?.prompt === null ? "" : descriptionData?.prompt)}
        />
    </FormControl>

    {categoryData?.is_inspection_details !==1 &&
    <> 
     <p className={`line_content_comment ${matches ? 'responsive':''}`} id={`add_comment_btn_${lineIndex+1}`}
     onClick={()=>{navigate(`/inspection/${categoryData.prompt !== null?encodeURIComponent(categoryData.prompt):"Untitled"}/${line.prompt !== null?encodeURIComponent(line.prompt):'Untitled'}`, { state: { line,template_id,category_id,category_uuid,line_uuid:lineUuid,target:'comment',add_comment_from_line:true, } });activateLine(line.uuid) }}
     ><MessageIcon/>{!matches && <span>Add comment</span>} </p>
    {line?.line_input_controls?.find(data=>data?.type === "Media")?.is_disabled != 1 ?  <p className={`line_content_photo ${matches ? "responsive" : ""}`} onClick={()=>fileInputLine.current.click()} id={`add_line_image_btn_${lineIndex+1}`}>
            <input
                  ref={fileInputLine}
                    type="file"
                    accept="image/*,video/*"
                    onChange={handleAddMedia}
                    style={{ display: "none" }}
                    multiple
                    />
              <CameraAltIcon /> {!matches && <span>Add media</span>}
            </p>:""}
     </>
    }
   
  </div>
   {/* additional inputs */}
   { !isNarrative?
    additionalInputs?.map((item,index)=>(
      <AdditionalInputLine line={item} handleEditAdditionalInput={handleEditAdditionalInput} key={index} render={render} />
    )):''
   }
   
    {/* additional inputus */}
  
  
  </>
  )
}

export default LineDescription