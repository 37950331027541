import { Dialog } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, Box, SvgIcon, Button, Checkbox, Divider, FormControl, Grid, Paper, TextField, Typography, Chip, FormControlLabel, FormGroup } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import SunEditor from "suneditor-react";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { MdOutlineUploadFile } from "react-icons/md";
import { FaRegCopy } from "react-icons/fa6";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { arrowDown, copyIcon } from "../../../../Assets/Svg/Svg";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { ClipboardItem, write } from "clipboard-polyfill";
import { preprocessHTMLContent, replacePlaceholdersWithDropdowns } from "./CommentLibraryHelper";
import { UpdateTemplateDataSpecific, getCommentLibrarySummaryTagList, getCommentLibraryTagList } from "../../../Helpers/ApiCall";
import { filterObjectsUptoLine } from "../../../Helpers/HelperFunctions";
import { GetUTCTime } from "../../../Helpers/GetUTCTime";
import CopySummaryModal from "./CopySummaryModal";
import HtmlEditor from "../../../Common/HtmlEditor/HtmlEditor";
import FroalaEditorComponent from "../../../Common/HtmlEditor/FroalaEditorComponent";
const EditClCommentModal = ({
  comment_text,
  uuid,
  comment_dropdowns,
  summaries,
  summary_comment_text,
  summary_comment_dropdowns,
  title,
  templateId,
  categoryId,
  lineUuid,
  showEditCLModal,
  setShowEditCLModal,
  tags,
  inspectionData,
  categoryName,
  setShowDeleteModal,
  lineData,
}) => {
  const editor = useRef();
  const commentEditor = useRef();
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.CommonReducer);
  const [commentTitle, setCommentTitle] = useState("");
  const [commentTag, setCommentTag] = useState([]);
  const [summaryTag, setSummaryTag] = useState([]);
  const [commentText, setCommentText] = useState({ innerHTML: "" });
  const [summaryText, setSummaryText] = useState({ innerHTML: "" });
  const [copied, setCopied] = useState(false);
  const [open, setOpen] = useState(false);
  const [tagOpen, setTagOpen] = useState(false);
  const [summaryDropDowns, setSummaryDropDowns] = useState("");
  const [commentDropDowns, setCommentDropDowns] = useState("");
  const [summaryCopyModalOpen, setSummaryCopyModalOpen] = useState(false);
  const [tag, setTag] = useState(storeData?.clTag !== undefined ? storeData?.clTag : []);
  const [optionsOfSummaryTag, setOptionsOfSummaryTag] = useState(storeData?.clSummaryTag !== undefined ? storeData?.clSummaryTag : []);
  const commentData = lineData?.comments?.find((data) => data.uuid === uuid);
  let prevData = storeData.inspectionData?.data;
  const templateIndex = prevData?.inspectiontemplates?.findIndex((itm) => itm?.template_id === templateId);
  const categoryIndex = prevData?.inspectiontemplates[templateIndex]?.categories?.findIndex((itm) => itm?.id === categoryId);
  const lineIndex = prevData?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines?.findIndex((line) => line?.uuid === lineUuid);
  const commentIndex = prevData?.inspectiontemplates[templateIndex]?.categories[categoryIndex]?.lines[lineIndex]?.comments?.findIndex((itm) => itm?.uuid === uuid);

  const handleCloseCLEditModal = () => {
    setCommentTitle(title);
    setCommentTag(tags);
    setSummaryTag(summaries);
    setCommentText({ innerHTML: comment_text });
    setSummaryText({ innerHTML: summary_comment_text });
    setShowEditCLModal(false);
  };
  const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      fontSize: "14px",
    },
  }));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleTagOpen = () => {
    setTagOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTagClose = () => {
    setTagOpen(false);
  };

  const replaceSelectWithDropdown = (content, className) => {
    let tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;
    // Find all select elements with the specified class within the content
    tempDiv.querySelectorAll(`select.${className}`).forEach(select => {
      // Get the id of the select element
      const selectId = select.id;
      // Replace the select element with the corresponding dropdown tag
      select.replaceWith(`[dropdown ${selectId}]`);
    });
    // Get the final HTML content without select tags
    return tempDiv.innerHTML;
  }

  const handleChangeComment = (id, content) => {
    setCommentText({ innerHTML: content });
  };

  const handleChangeSumary = (id, content) => {
    setSummaryText({ innerHTML: content });
    setCopied(false);
  };

  const handleCopyButton = () => {
    if (commentText?.innerHTML?.length === 0 || commentText?.innerHTML === "<p><br></p>") {
      return null;
    } else if (summaryText?.innerHTML?.length === 0 || summaryText?.innerHTML === "<p><br></p>") {
      handleCopyComment();
    } else {
      setSummaryCopyModalOpen(true);
    }
  };

  async function handleCopyComment() {
    setSummaryText({ innerHTML: commentText?.innerHTML });
    setSummaryDropDowns(comment_dropdowns);
    setSummaryCopyModalOpen(false);
    setCopied(true);
  }

  const handleEditClComment = async () => {
    let copiedConvertedSummaryText = replaceSelectWithDropdown(summaryText?.innerHTML, "comment-cl-dropdown");
    let convertedCommentText = replaceSelectWithDropdown(commentText?.innerHTML, "comment-cl-dropdown");
    let summartConvertedText = replaceSelectWithDropdown(summaryText?.innerHTML, "summary-cl-dropdown");
    if (commentIndex !== -1) {
      let comment = prevData.inspectiontemplates[templateIndex].categories[categoryIndex].lines[lineIndex].comments[commentIndex];
      Object.assign(comment, {
        comment_dropdowns: commentDropDowns?.length ? commentDropDowns : [],
        comment_text: convertedCommentText,
        title: commentTitle,
        tags: commentTag,
        summaries: summaryTag,
        summary_comment_text: copied ? copiedConvertedSummaryText : summartConvertedText,
        summary_comment_dropdowns: summaryDropDowns?.length ? summaryDropDowns : [],
        updated_at: await GetUTCTime(),
        is_selected_updated_at: await GetUTCTime(),
      });
      let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
      UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
    }
    setShowEditCLModal(false);
  };

  const handleChangeDropdown = async (event, dropdownUuid, type, commentUUId, commentDisabled) => {
    const selectedValue = event.target.value;
    if (type === "comment") {
      let commentDropdowns = prevData?.inspectiontemplates[templateIndex]?.categories[categoryIndex].lines[lineIndex].comments[commentIndex].comment_dropdowns;
      let commentDropdownIndex = commentDropdowns.findIndex((dropdown) => dropdown.dropdown_uuid === dropdownUuid);
      commentDropdowns[commentDropdownIndex] = {
        ...commentDropdowns[commentDropdownIndex],
        selected_value: selectedValue,
      };
      setCommentDropDowns(commentDropdowns);
    } else {
      let summaryDropdowns = prevData?.inspectiontemplates[templateIndex]?.categories[categoryIndex].lines[lineIndex].comments[commentIndex].summary_comment_dropdowns;
      let summaryDropdownIndex = summaryDropdowns.findIndex((dropdown) => dropdown.dropdown_uuid === dropdownUuid);
      summaryDropdowns[summaryDropdownIndex] = {
        ...summaryDropdowns[summaryDropdownIndex],
        selected_value: selectedValue,
      };
      setSummaryDropDowns(summaryDropdowns);
    }
  };
  let commentDropElements = document.querySelectorAll(".comment-cl-dropdown");
  commentDropElements.forEach((element) => {
    const commentUUId = element.getAttribute("comment_uuid");
    const commentDisabled = element.getAttribute("disabled");
    if (element) {
      element.onchange = (event) => handleChangeDropdown(event, element.id, "comment", commentUUId, commentDisabled);
    }
  });
  let summaryDropElements = document.querySelectorAll(".summary-cl-dropdown");
  summaryDropElements.forEach((element) => {
    const commentUUId = element.getAttribute("comment_uuid");
    const commentDisabled = element.getAttribute("disabled");
    if (element) {
      element.onchange = (event) => handleChangeDropdown(event, element.id, "summary", commentUUId, commentDisabled);
    }
  });

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  useEffect(() => {
    if (showEditCLModal) {
      getCommentLibraryTagList(dispatch);
      getCommentLibrarySummaryTagList(dispatch);
    }
    const dropdownPattern = /\[dropdown ([a-f0-9-]+)\]/g;
    const updatedCommentText = comment_text?.replace(dropdownPattern, (match, id) => {
      return `<span contenteditable="false">${match}</span>`;
    });
    const updatedSummaryText = summary_comment_text?.replace(dropdownPattern, (match, id) => {
      return `<span contenteditable="false">${match}</span>`;
    });
    setCommentTitle(title);
    setCommentTag(tags);
    setSummaryTag(summaries);
    setCommentText({ innerHTML: updatedCommentText });
    setSummaryText({ innerHTML: updatedSummaryText });
    setSummaryDropDowns(summary_comment_dropdowns);
    setCommentDropDowns(comment_dropdowns);
  }, [showEditCLModal, inspectionData]);

  useEffect(() => {
    setTag(storeData?.clTag !== undefined ? storeData?.clTag : []);
    setOptionsOfSummaryTag(storeData?.clSummaryTag !== undefined ? storeData?.clSummaryTag : []);
  }, [storeData?.clTag, storeData?.clSummaryTag]);

  return (
    <>
      <Dialog
        className="dialog-container-comment-library edit_cl_comment_container"
        open={showEditCLModal}
        onClose={handleCloseCLEditModal}
        aria-labelledby="category-modal"
        aria-describedby="category-modal-description"
      >
        <div className="close-wrapper-new-category-right">
          <CloseIcon onClick={handleCloseCLEditModal} />
        </div>
        <DialogTitle className="edit_cl_dialog_title">{categoryName} - Edit comment</DialogTitle>
        <Divider />
        <DialogContent>
          <Grid item xs={12}>
            <FormControl variant="standard" className="forms-control">
              <label className="cl-new-comment-input-form-label" htmlFor="title">
                TITLE
              </label>
              <TextField
                id="cl_title"
                className="input-textfield"
                size="small"
                variant="outlined"
                value={commentTitle}
                onChange={(e) => setCommentTitle(e.target.value)}
                type="text"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#ccc",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#ccc",
                    },
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6.5} className="cl-add-comment-sections cl-editor cl_comment_editor">
              <Grid container className="input-controll">
                <Grid item xs={12} sm={12}>
                  <FormControl variant="standard" className="forms-control">
                    <label className="cl-new-comment-input-form-label" htmlFor="title">
                      TAGS
                    </label>
                    <Autocomplete
                      className="forms-control-tag custom-color autocompleteCustom"
                      id="tags-outlined"
                      multiple
                      size="small"
                      options={tag}
                      onClick={!tagOpen ? handleTagOpen : handleTagClose}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option?.name}
                      open={tagOpen}
                      value={commentTag}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#ccc",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#ccc",
                          },
                        },
                      }}
                      onChange={(e, value, reason) => {
                        const selectedTag = value[value?.length - 1];
                        // If the `reason` is "removeOption", directly update the state
                        if (reason === 'removeOption') {
                          setCommentTag(value);
                          return;
                        }
                        const isTagSelected = commentTag?.some(tag => tag?.id === selectedTag?.id);
                        if (isTagSelected) {
                          // If the tag is already selected, remove it from the state
                          setCommentTag(prevTags => prevTags?.filter(tag => tag?.id !== selectedTag?.id));
                        } else {
                          // If the tag is not selected, add it to the state
                          setCommentTag(prevTags => [...prevTags, selectedTag]);
                        }
                      }}
                      renderTags={(value, getTagProps) => value.map((option, index) => <Chip variant="outlined" deleteIcon={<CloseOutlinedIcon />} label={option?.name} {...getTagProps({ index })} />)}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox icon={icon} checkedIcon={checkedIcon} checked={commentTag?.some((tag) => tag.name === option.name)} />
                          <Box
                            sx={{ width: "12px", height: "12px", backgroundColor: option?.color, borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}
                            style={{
                              marginRight: 8,
                            }}
                          />
                          {option?.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder={commentTag?.length === 0 ? "Add tags" : ""}
                          onClick={!tagOpen ? handleTagOpen : handleTagClose}
                          onBlur={handleTagClose}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontFamily: "Open Sans!important", fontSize: "14px", fontWeight: 400, paddingLeft: "20px" },
                            endAdornment: (
                              <>
                                <SearchIcon className="customSearch" style={{ color: "#9b9b9b", marginRight: "8px" }} />
                                <SvgIcon className={`customArrowDown ${tagOpen ? "arrowActive" : ""}`} viewBox="" onClick={!tagOpen ? handleTagOpen : handleTagClose}>
                                  {arrowDown}
                                </SvgIcon>
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl variant="standard" className="forms-control">
                    <label className="cl-new-comment-input-form-label" htmlFor="Recommendation" style={{ marginBottom: "9px" }}>
                      COMMENT
                    </label>
                    <FroalaEditorComponent editorData={commentText} editor={commentEditor} id={"commentText"} onChange={handleChangeComment} minHeight={100} maxHeight={110} commentData={commentData} />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={5.5} className="cl-add-comment-sections-summary cl-editor">
              <Grid container className="input-controll">
                <Grid item xs={12} sm={12}>
                  <FormControl variant="standard" className="forms-control">
                    <label className="cl-new-comment-input-form-label" htmlFor="title">
                      SUMMARY SECTION
                    </label>
                    <Autocomplete
                      className="forms-control1 custom-color autoHover autocompleteSummaryCustom"
                      id="tags-outlined"
                      multiple
                      size="small"
                      options={optionsOfSummaryTag}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option?.name}
                      openOnFocus
                      open={open}
                      value={summaryTag}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#ccc",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#ccc",
                          },
                        },
                      }}
                      onChange={(e, value, reason) => {
                        const selectedTag = value[value?.length - 1];
                        // If the `reason` is "removeOption", directly update the state
                        if (reason === 'removeOption') {
                          setSummaryTag(value);
                          return;
                        }
                        const isTagSelected = summaryTag?.some(tag => tag?.id === selectedTag?.id);
                        if (isTagSelected) {
                          // If the tag is already selected, remove it from the state
                          setSummaryTag(prevTags => prevTags?.filter(tag => tag?.id !== selectedTag?.id));
                        } else {
                          // If the tag is not selected, add it to the state
                          setSummaryTag(prevTags => [...prevTags, selectedTag]);
                        }
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            className="custom-chip"
                            variant="outlined"
                            deleteIcon={<CloseOutlinedIcon />}
                            label={
                              <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Box
                                  sx={{
                                    width: "12px",
                                    height: "12px",
                                    backgroundColor: option?.color,
                                    borderRadius: "50%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginRight: "8px",
                                  }}
                                />
                                <div style={{ fontSize: "14px" }}>{option?.name}</div>
                              </Box>
                            }
                            {...getTagProps({ index })}
                            sx={{
                              padding: "2px 2px 2px 10px !important",
                              fontSize: "10px !important",
                            }}
                          />
                        ))
                      }
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox style={{ borderRadius: "50%" }} icon={icon} checkedIcon={checkedIcon} checked={summaryTag.some((tag) => tag.name === option.name)} />
                          <Box
                            sx={{ width: "12px", height: "12px", backgroundColor: option?.color, borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}
                            style={{
                              marginRight: 8,
                            }}
                          />
                          {option?.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder={summaryTag?.length === 0 ? "No summary selected" : ""}
                          onClick={!open ? handleOpen : handleClose}
                          onBlur={handleClose}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontFamily: "Open Sans!important", fontSize: "14px", fontWeight: 400 },
                            endAdornment: (
                              <>
                                <SvgIcon className={`customArrowDown ${open ? "arrowActive" : ""}`} viewBox="" onClick={!open ? handleOpen : handleClose}>
                                  {arrowDown}
                                </SvgIcon>
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl variant="standard" className="forms-control">
                    <label className="cl-new-comment-input-form-label summary-section-copy-icon" htmlFor="Recommendation">
                      SUMMARY COMMENT
                      <IconButton onClick={handleCopyButton}>
                        <SvgIcon className="copyIcon" viewBox="">
                          {copyIcon}
                        </SvgIcon>
                      </IconButton>
                    </label>
                    <FroalaEditorComponent editorData={summaryText} editor={editor} id={"summaryText"} onChange={handleChangeSumary} minHeight={100} maxHeight={110} copy={copied} commentData={commentData} />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions className="comment-library-action" style={{ height: "64px" }}>
          <div className={`comment-library-checkbox-container hidden`}>
            <FormGroup>
              <FormControlLabel className="cl_checkbox" control={<Checkbox />} label="Push to template" />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                className="cl_checkbox"
                control={
                  <Checkbox
                    sx={{
                      "&.Mui-checked": {
                        color: "#4282E2",
                      },
                    }}
                  />
                }
                label="Push to library"
              />
            </FormGroup>
          </div>
          <div className="comment-library-action-btn">
            <div className="cl-modal-delete-comment" onClick={() => setShowDeleteModal(true)} style={{ cursor: "pointer" }}>
              delete comment
            </div>
            <Button
              disabled={
                commentTag?.length === 0 || (commentTitle?.trim() === "" && (commentText?.innerHTML?.trim() === "" || commentText?.innerHTML?.trim() === "<p><br></p>" || preprocessHTMLContent(commentText?.innerHTML) === ""))
                  ? true
                  : false
              }
              className="cl-edit-comment-btn"
              onClick={
                commentTag?.length === 0 || (commentTitle?.trim() === "" && (commentText?.innerHTML?.trim() === "" || commentText?.innerHTML?.trim() === "<p><br></p>" || preprocessHTMLContent(commentText?.innerHTML) === ""))
                  ? undefined
                  : handleEditClComment
              }
            >
              Save comment
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      {summaryCopyModalOpen && <CopySummaryModal summaryCopyModalOpen={summaryCopyModalOpen} setSummaryCopyModalOpen={setSummaryCopyModalOpen} handleCopyComment={handleCopyComment} />}
    </>
  );
};

export default EditClCommentModal;