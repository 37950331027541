import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { FormControl, Grid, IconButton, TextField } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { GetUTCTime } from "../../../Helpers/GetUTCTime";
import { UpdateTemplateDataSpecific } from "../../../Helpers/ApiCall";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import moment from "moment";
import DoneIcon from "@mui/icons-material/Done";
import { filterPdfTemplateData } from "../../../Helpers/HelperFunctions";

function DateElement({
  element,
  elementType,
  templateId,
  pdfCategoriesType,
  level,
  pdfCategoryData
}) {
  const [inspectionResponse, setInspectionResponse] = useState();
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const datepickerRef = useRef();
  const storeData = useSelector((state) => state.CommonReducer);
  const dispatch = useDispatch();

  let templateIndex = storeData?.inspectionData?.data?.inspectiontemplates?.findIndex((itm) => itm.template_id === templateId);
  let prevData = storeData?.inspectionData?.data;
  let pdfTemplate = prevData?.inspectiontemplates[templateIndex];
  let mergeId = element?.merge_id;
  let ElementpdfData = pdfTemplate?.pdf_data?.filter((item) => item?.merge_id == mergeId);
  let newPrevData = JSON.parse(JSON.stringify(storeData?.inspectionData?.data))
  let newPdfTemplate = newPrevData?.inspectiontemplates[templateIndex];

  const HandleUpdateDateApiCall = async (date) => {
    let formData = {
      type: "Input/Date",
      merge_id: mergeId,
      value: date,
      updated_at: await GetUTCTime(),
    };
    if (
      pdfTemplate?.pdf_data?.length !== 0 &&
      pdfTemplate?.pdf_data?.length !== undefined
    ) {
      pdfTemplate.pdf_data.length = 0;
    }
    pdfTemplate?.pdf_data?.push(formData);
    newPdfTemplate?.pdf_data?.push(formData);
    let filteredData = newPdfTemplate?.pdf_data?.filter(el=>el?.merge_id != mergeId)
    filteredData.push(formData)
    let filteredObject = filterPdfTemplateData(prevData, templateIndex,filteredData,mergeId);
    UpdateTemplateDataSpecific(dispatch,prevData,filteredObject);
  };

  const handleDateChange = (date) => {
    let newDate = new Date(date).setHours(0);
    newDate = moment.utc(newDate).local().format("YYYY-MM-DD HH:mm:ss");
    setSelectedDate(date);
    setOpen(false);
    HandleUpdateDateApiCall(newDate);
  };
  const handleInputClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onKeyDown = (e) => {
    e.preventDefault();
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClearDate = (event) => {
    event?.stopPropagation();
    setSelectedDate(null);
    setOpen(false); // Close the DatePicker when the cross icon is clicked
    HandleUpdateDateApiCall(null);
  };
  useEffect(() => {
    const handleClickAway = (event) => {
      const isClickInsideInput = event.target.tagName === "BUTTON"; // Check if the click is inside the input field
      const isClickInsideDatePicker =
        datepickerRef.current && datepickerRef.current.contains(event.target);

      if (isClickInsideInput && !isClickInsideDatePicker) {
        setOpen(false);
      }
    };
    document.addEventListener("click", handleClickAway);
    return () => {
      document.removeEventListener("click", handleClickAway);
    };
  }, []);
  
  useEffect(() => {
    if (ElementpdfData?.[0]?.value) {
    let value = ElementpdfData?.[0]?.value;
    if (value) {
      setSelectedDate(moment(value));
    }}
  }, [ElementpdfData?.[0]?.value]);
  
  // useEffect(() => {
  //   setInspectionResponse(storeData);
  // }, []);
  return (
    <>
      <Grid
        container
        className={`${
          elementType === "inner" || elementType === "layout"
            ? "pdf_container"
            : "pdf_inner_container"
        }`}
      >
        <FormControl
          variant="standard"
          className={`forms-control w-100 ${level == 3 ? "level3_class" : ""}`}
          onClick={handleInputClick}
          onKeyDown={onKeyDown}
        >
          <label
            className={
              pdfCategoriesType == 1
                ? (elementType !== "inner" && elementType !== "layout") ||
                  selectedDate == "" ||
                  selectedDate == null
                  ? "inner_details_title"
                  : "inner_details_title_after_selection"
                : "inner_details_title"
            }
            id="categoryName"
          >
            {pdfCategoriesType == 1 ? (
              (elementType !== "inner" && elementType !== "layout") ||
              selectedDate == "" ||
              selectedDate == null ? (
                ""
              ) : (
                <DoneIcon sx={{ marginLeft: "-28px", position: "absolute" }} />
              )
            ) : (
              ""
            )}
            {element?.label}
          </label>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <div style={{ marginTop: "9px" }}>
              {selectedDate && (
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    zIndex: 10,
                    position: "absolute",
                    right: "30px",
                  }}
                >
                  <IconButton
                    onClick={handleClearDate}
                    sx={{
                      ":hover": { backgroundColor: "transparent" },
                      paddingTop: "9px",
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              )}
              <DatePicker
                ref={datepickerRef}
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
                className="dateClass"
                sx={{ display: "flex", flex: 1 }}
                renderInput={(params) => <TextField {...params} />}
                value={selectedDate}
                onChange={handleDateChange}
              />
            </div>
          </LocalizationProvider>
        </FormControl>
      </Grid>
    </>
  );
}

export default DateElement;
