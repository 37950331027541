import moment from "moment";
const GetUTCTime = async (passingDate, returnType = "timeDate") => {
  return new Promise(async (resolve, reject) => {
    try {
      // var toUTCString = '';
      var date = "";
      // passingDate='2023-01-16 00:17:03'
      // if (passingDate) {
      //   toUTCString = new Date(passingDate).toUTCString();
      //   date = new Date(passingDate);
      // } else {
      //   toUTCString = new Date().toUTCString();
      //   date = new Date();
      // }

      // let convertedUTCDate = new moment(new Date(toUTCString))
      //   .format('YYYY-MM-DD')
      //   .toString();

      // let convertedUTCTime =
      //   date.getUTCHours().toString().padStart(2, '0') +
      //   ':' +
      //   date.getUTCMinutes().toString().padStart(2, '0') +
      //   ':' +
      //   date.getUTCSeconds().toString().padStart(2, '0');
      // console.log('passing ddate : ', passingDate);
      if (passingDate) {
        date = new Date(passingDate).toString();
      } else {
        date = new Date().toString();
      }
      // console.log('Date : ',date.split('T'))
      const dateArr = date.split("T");
      const timeArr = dateArr[1].split(".")[0];
      // console.log('tiome  : ',timeArr)
      // let utc=date.toISOString()
      if (returnType == "timeDate") {
        // let utcDate = convertedUTCDate + ' ' + convertedUTCTime;
        let utcDate = dateArr[0] + timeArr;

        // console.log('get utc : ',date)
        let utcFinal = moment
          .utc(new Date(date).toUTCString())
          .format("YYYY-MM-DD HH:mm:ss");

        resolve(utcFinal);
      } else if (returnType == "date") {
        // let utcDate = convertedUTCDate;
        let utcDate = dateArr[0];
        let utcFinal = moment
          .utc(new Date(date).toUTCString())
          .format("YYYY-MM-DD");
        resolve(utcFinal);
      }
    } catch (err) {
      console.log("get utc time error", err);
      reject(err);
    }
  });
};
export { GetUTCTime };
