import { Grid, Typography, Divider, FormControl, OutlinedInput, InputAdornment, IconButton } from '@mui/material'
import EditIcon from "@mui/icons-material/Edit";
import FlagIcon from '@mui/icons-material/Flag';
import React, { useState, useEffect } from 'react'
import AddRatingModal from './AddRatingModal';

const CommentRating = ({ title, data, action, templateId, categoryId, lineUuid, commentUuid,commentIndex }) => {
  const [ratings, setRatings] = useState([])
  const [showRatingModal, setShowRatingModal] = useState(false)
  useEffect(() => {
    setRatings(data !== null && data !== undefined && data.data !== null && data.data.length !== 0 ? data.data : [])
  }, [data])
  return (
    <>
      <AddRatingModal
        showRatingModal={showRatingModal}
        setShowRatingModal={setShowRatingModal}
        ratings={ratings}
        templateId={templateId}
        categoryId={categoryId}
        lineUuid={lineUuid}
        commentUuid={commentUuid}
      />
      <Grid
        container
        className={`${action === "line" ? "container" : "inner_container"}`}
      >
        <Grid item xs={12} className="line_detail_des_title" id={`edit_rating_title_${commentIndex+1}`}  onClick={() => setShowRatingModal(true)} sx={{  ":hover": { cursor: "pointer !important" },}}>
          <span
            className={`${action === "line" ? "details_title" : "inner_details_title"
              }`}
          >
            {title}
          </span>
          {/* <span className={`${action === "line"?"details_action":"inner_details_action"}`} onClick={()=>setShowRatingModal(true)}><EditIcon/>Edit</span> */}
        </Grid>
        {/* <div className="rating__contents">
       {ratings.length!== 0 ? ratings?.map(rating=>(
        <div className="rating_square_container" key={rating.Id}>
          <span className="rating_square" style={{background:`${rating.Color}`}}>{rating.include_in_summary == "1" && <FlagIcon/>}</span>
          <span className="rating_name_text">{rating.Name}</span>

        </div>
      )) :<div>No ratings selected. </div>}
      </div> */}
        <Typography
          size="large"
          type="text"
          multiline
          id={`edit_rating_${commentIndex+1}`}
          onClick={() => setShowRatingModal(true)}
          sx={{
            outline: "1px solid #c1c1c1",
            padding: "5px",
            position: "relative",
            minWidth: "100%",
            borderRadius: "4px",
            // height:"19px",
            ":hover": { cursor: "pointer !important" },
          }}
        >
          <div
            className="rating__contents"
            style={{
              display: "flex",
              gap: "7px",
              alignItems: "center",
              flexWrap: "wrap",
              padding: "0px 10px",
              minHeight: "24px",
              ":hover": { cursor: "pointer" },
              // marginTop: "-3px",
              // marginBottom: "-3px",
            }}
            onClick={() => setShowRatingModal(true)}
          >
            {ratings.length !== 0 ? (
              ratings?.map((rating) => (
                <div
                  className="rating_square_container"
                  key={rating.Id}
                  style={{ display: "flex", alignItems: "center" 
                  // ,  height:"20px"
                }}
                >
                  <div
                    className="rating_square"
                    style={{ background: `${rating.Color}`, padding: "4px" }}
                  >
                    {rating.include_in_summary === "1" && <FlagIcon />}
                  </div>
                  <div className="rating_name_text" style={{ marginRight: "20px" ,
                  // height:"20px"
                  }}>{rating.Name}</div>
                </div>
              ))
            ) : (""
              // <div style={{height:"20px"}}>No rating.</div>
            )}
            <span className="edit-text"></span>
            <EditIcon
            id={`edit_rating_icon_${commentIndex+1}`}
              onClick={() => setShowRatingModal(true)}
              sx={{
                color: "#252525 !important",
                cursor: "pointer",
                marginRight: "12px",
              }}
              style={{
                position: "absolute",
                right: "0",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            />
          </div>
        </Typography>
      </Grid>
    </>
  );
}

export default CommentRating