import React from 'react'
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import ReorderIcon from '@mui/icons-material/Reorder';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';

function PdfReorderMedia({id, item, index, moveCard,reOrderMedia,inspectionData,reorderApicall,handleCaption,afterReodermediaData}) {
    const ref = useRef(null);
    const [{ handlerId }, drop] = useDrop({
      accept: "card",
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId()
        };
      },
      hover(item, monitor) {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }
        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }
        // Time to actually perform the action
        moveCard(dragIndex, hoverIndex);
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        // setInputChanged(true)
        item.index = hoverIndex;
        // reorderApicall()  
      },
      drop(item,monitor){
        if(!monitor.didDrop()){
          reorderApicall(afterReodermediaData);
        }
      }
    });
    const [{ isDragging }, drag] = useDrag({
      type: 'card',
      item: () => {
        return { id, index };
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));
    return (
        <div
            // className="add-media-content"
            ref={ref}
            style={{ opacity }}
            data-handler-id={handlerId}
        >
            {item.is_deleted !== 1 ?
                item.online === "online" &&
                    item.filetype === "image" ?
                    <div className='galley_image' key={index}>
                        {reOrderMedia && <ReorderIcon sx={{ position: "absolute", backgroundColor: "black", color: "white", paddingLeft: "1px", marginLeft: "10px", marginTop: "10px", borderRadius: "2px" }} />}
                        <img
                            style={{ width: '100%', height: '100%', objectFit: 'contain', backgroundColor: '#FAFAFA' }}
                            src={item.filetype === 'image' ? item.is_portal == 1 ? `${inspectionData?.bucket_url_2}${item.thumbnail !==null?item.thumbnail:item.onlineUrl}` : `${inspectionData?.bucket_url}${item.thumbnail !==null?item.thumbnail:item.onlineUrl}` : item.is_portal == 1 ? `${inspectionData?.bucket_url_2}${item.thumbnail !==null?item.thumbnail:item.onlineUrl}` : `${inspectionData?.bucket_url}${item.thumbnail !==null?item.thumbnail:item.onlineUrl}`}
                            alt="image"></img>
                        <p className="image_description_content">
                            {item?.description !== null && item?.description !== undefined &&
                                item?.description?.length > 45 ?
                                <span onClick={() => handleCaption(item)}> {`${item.description?.substring(45, 0)}...`}</span>
                                :
                                <span onClick={() => handleCaption(item)}>{item.description}</span>
                            }
                        </p>
                        {item?.description === null || item?.description === undefined || item?.description === '' ? <span className="image_description" onClick={() => handleCaption(item)} ><AddCircleIcon />{item?.description !== null && item?.description !== undefined && item?.description !== '' ? "Edit Caption" : "Caption"} </span> : ''}
                    </div> : item?.online === "offline" ? "" :
                        <div className='galley_image' key={index}>
                            {reOrderMedia && <ReorderIcon sx={{ position: "absolute", backgroundColor: "black", color: "white", paddingLeft: "1px", marginLeft: "10px", marginTop: "10px", borderRadius: "2px" }} />}
                            <img
                                style={{ width: '100%', height: '100%', cursor: 'pointer', objectFit: 'contain', backgroundColor: '#FAFAFA' }}
                                src={item.filetype === 'image' ? item.is_portal == 1 ? `${inspectionData?.bucket_url_2}${item.thumbnail !==null?item.thumbnail:item.onlineUrl}` : `${inspectionData?.bucket_url}${item.thumbnail !==null?item.thumbnail:item.onlineUrl}` : item.is_portal == 1 ? `${inspectionData?.bucket_url_2}${item.thumbnail !==null?item.thumbnail:item.onlineUrl}` : `${inspectionData?.bucket_url}${item.thumbnail !==null?item.thumbnail:item.onlineUrl}`}
                                alt="image"></img>
                            <PlayCircleIcon className="play_icon" sx={{ top: "50% !important" }} />
                            <p className="image_description_content">
                                {item?.description !== null && item?.description !== undefined &&
                                    item?.description?.length > 45 ?

                                    <span onClick={() => handleCaption(item)}>{`${item.description?.substring(45, 0)}...`}</span>

                                    :
                                    <span onClick={() => handleCaption(item)}>{item.description}</span>
                                }
                            </p>
                            {item?.description === null || item?.description === undefined || item?.description === '' ? <span className="image_description" onClick={() => handleCaption(item)} ><AddCircleIcon />{item?.description !== null && item?.description !== undefined && item?.description !== '' ? "Edit Caption" : "Caption"} </span> : ''}
                        </div> : ""}
        </div>
    )
}

export default PdfReorderMedia
