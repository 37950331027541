// import React from 'react'

// function PlanTextElement() {
//   return (
//     <div>
//       PlanTextElement
//     </div>
//   )
// }

// export default PlanTextElement

import { FormControl, Grid } from '@mui/material'
import React from 'react'

function PlanTextElement({element,elementType}) {
  return (
    <Grid container className={`${(elementType === "inner" || elementType === "layout") ? 'pdf_container' : 'pdf_inner_container'}`}>
    <FormControl variant="standard" className="forms-control w-100">
      <Grid item xs={12} sx={{ lineHeight: "22px" }}>
        <span style={{ fontSize: "16px" }}>
          {element?.value}
        </span>
      </Grid>
    </FormControl>
  </Grid>
  )
}

export default PlanTextElement
