// import { API_CONSTANTS, API_METHODS } from "../config/api-constants";
import { fetchCall } from "./ajax";
import { API_METHODS, API_CONSTANTS } from "../Config/Api-constants";
import { portalServerUrl } from "../Config/Api-constants";

// post methord call
export const apiCall = (callback, payload, end_point_key) => {
  const url = `${API_CONSTANTS[end_point_key]}`;
  const method = API_METHODS.POST;
  return fetchCall((response) => callback(response), url, method, payload);
};

export const sendReportCall = (callback, payload, end_point_key) => {
  const url = `${portalServerUrl()}/${API_CONSTANTS.SEND_REPORT}`;
  const method = API_METHODS.POST;
  return fetchCall((response) => callback(response), url, method, payload);
};

// for pagination
export const apiCallPaginated = (callback, payload, end_point_key, pageCount) => {
  const url = `${API_CONSTANTS[end_point_key]}?page=${pageCount}`;
  const method = API_METHODS.POST;
  return fetchCall((response) => callback(response), url, method, payload);
};

// get method call
export const apiCallGet = (callback, payload, end_point_key) => {
  const url = `${API_CONSTANTS[end_point_key]}`;
  const method = API_METHODS.GET;
  return fetchCall((response) => callback(response), url, method, payload);
};
