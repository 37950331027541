import { Collapse, Divider, Grid } from "@mui/material";
import React from "react";
import NumberElement from "./NumberElement";
import TextElement from "./TextElement";
import YearElement from "./YearElement";
import DateElement from "./DateElement";
import CheckBoxElement from "./CheckBoxElement";
import PlanTextElement from "./PlanTextElement";
import ExpandingCheckbox from "./ExpandingCheckbox";
import DigitalSignature from "./DigitalSignature";
import RadioElement from "./RadioElement";
import InformationalTextHTMLElement from "./InformationalTextHTMLElement";
import MultiLinesInputElement from "./MultiLinesInputElement";
import MediaUploadElement from "./MediaUploadElement";
import TableElement from "./TableElement";

function LayoutGroupElement({
  element,
  elementType,
  templateId,
  nestingLevel = 0,
  width,
  setWidth,
  height,
  setHeight,
  pdfCategoriesType,
  templateGuid,
  templateData,
  pdfCategoryData,
  level,
  orderForMedia,
  onDragtoggle, 
  setOnDragToggle, 
  selectedMediaFiles, 
  setSelectedMediaFiles, 
  selectedImages, 
  setSelectedImages,
}) {
  return (
    <>
      <Grid
        container
        className="pdf__container"
      // className={`${elementType === "outer" ? "comment__container" : "pdf_comment__container"
      // }`}
      >
        <Grid item xs={12}>
          <Grid className="comment_detail_title">
            <span
              className="comment_title"
            //  id={`comment_${commentIndex}`}  onClick={(e)=>{commentSelected && checkComment(e,"outside")}}
            >
              <span
                className="comment_title_single"
              // id={`comment_title_${commentIndex}`}
              >
                {element?.label}
              </span>
            </span>
          </Grid>
          {element?.elements?.map((elm) => (
            <>
              {elm?.type === "Input/Number" && (
                <>
                  <Grid container sx={{ mt: "17px" }}>
                    <NumberElement
                      element={elm}
                      elementType="layout"
                      templateId={templateId}
                      pdfCategoriesType={pdfCategoriesType}
                      pdfCategoryData={pdfCategoryData}
                      level={level + 1}
                    />
                  </Grid>
                </>
              )}
              {elm?.type === "Input/Text" && (
                <>
                  <Grid container sx={{ mt: "17px" }}>
                    <TextElement
                      element={elm}
                      elementType="layout"
                      templateId={templateId}
                      pdfCategoriesType={pdfCategoriesType}
                      pdfCategoryData={pdfCategoryData}
                      level={level + 1}
                    />
                  </Grid>
                </>
              )}
              {elm?.type === "Input/Year" && (
                <>
                  <Grid container sx={{ mt: "17px" }}>
                    <YearElement
                      element={elm}
                      elementType="layout"
                      templateId={templateId}
                      pdfCategoriesType={pdfCategoriesType}
                      pdfCategoryData={pdfCategoryData}
                      level={level + 1}
                    />
                  </Grid>
                </>
              )}
              {elm?.type === "Input/Date" && (
                <>
                  <Grid container sx={{ mt: "17px" }}>
                    <DateElement
                      element={elm}
                      elementType="layout"
                      templateId={templateId}
                      pdfCategoriesType={pdfCategoriesType}
                      pdfCategoryData={pdfCategoryData}
                      level={level + 1}
                    />
                  </Grid>
                </>
              )}
              {elm?.type === "CheckBox" && (
                <>
                  <Grid container sx={{ mt: "17px" }}>
                    <CheckBoxElement
                      element={elm}
                      elementType="layout"
                      templateId={templateId}
                      pdfCategoriesType={pdfCategoriesType}
                      pdfCategoryData={pdfCategoryData}
                      level={level + 1}
                    />
                  </Grid>
                </>
              )}
              {elm?.type === "InformationText/Plain" && (
                <>
                  <Grid container sx={{ mt: "17px" }}>
                    <PlanTextElement element={elm} elementType="layout" level={level + 1} />
                  </Grid>
                </>
              )}
              {elm?.type == "Divider" && (
                <>
                  <Grid container>
                    <Divider
                      style={{
                        width: "100%",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    />
                  </Grid>
                </>
              )}
              {elm?.type === "Radio" && (
                <>
                  <Grid container sx={{ mt: "17px" }}>
                    <RadioElement
                      element={elm}
                      elementType="layout"
                      templateId={templateId}
                      level={level + 1}
                      pdfCategoryData={pdfCategoryData}
                      pdfCategoriesType={pdfCategoriesType}
                    />
                  </Grid>
                </>
              )}
              {elm?.type === "MediaUploader" && (
                <>
                  <Grid container sx={{ mt: "17px" }}>
                    <MediaUploadElement
                      element={elm}
                      elementType="layout"
                      templateId={templateId}
                      width={setWidth}
                      setWidth={setWidth}
                      height={height}
                      setHeight={setHeight}
                      onDragtoggle={onDragtoggle}
                      setOnDragToggle={setOnDragToggle}
                      selectedMediaFiles={selectedMediaFiles}
                      setSelectedMediaFiles={setSelectedMediaFiles}
                      selectedImages={selectedImages}
                      setSelectedImages={setSelectedImages}
                      pdfCategoriesType={pdfCategoriesType}
                      pdfCategoryData={pdfCategoryData}
                      level={level + 1}
                      orderForMedia={`${orderForMedia}.${elm?.order === undefined ? '' : elm?.order}`}
                    />
                  </Grid>
                </>
              )}
              {elm?.type === "Layout/Table" && (
                <>
                  <Grid container sx={{ mt: "17px" }}>
                    <TableElement
                      element={elm}
                      elementType="layout"
                      templateId={templateId}
                    />
                  </Grid>
                </>
              )}
              {elm?.type === "InformationText/Html" && (
                <>
                  <Grid container sx={{ mt: "17px" }}>
                    <InformationalTextHTMLElement
                      element={elm}
                      elementType="layout"
                    />
                  </Grid>
                </>
              )}
              {(elm?.type === "Signature/Any" ||
                elm?.type === "Signature/Inspector") && (
                  <>
                    <Grid container sx={{ mt: "17px" }}>
                      <DigitalSignature
                        element={elm}
                        elementType="layout"
                        templateId={templateId}
                        templateGuid={templateGuid}
                        pdfCategoriesType={pdfCategoriesType}
                        level={level + 1}
                        templateData={templateData}
                      />
                    </Grid>
                  </>
                )}
              {elm?.type === "Input/Multiline" && (
                <>
                  <Grid container sx={{ mt: "17px" }}>
                    <MultiLinesInputElement
                      element={elm}
                      elementType="layout"
                      templateId={templateId}
                      pdfCategoriesType={pdfCategoriesType}
                      level={level + 1}
                    />
                  </Grid>
                </>
              )}
              {elm?.type === "Layout/ExpandingCheckBox" && (
                <>
                  <Grid container sx={{ mt: "17px" }}>
                    <ExpandingCheckbox
                      element={elm}
                      onDragtoggle={onDragtoggle}
                      selectedMediaFiles={selectedMediaFiles}
                      selectedImages={selectedImages}
                      templateGuid={templateGuid}
                      elementType="inner"
                      templateId={templateId}
                      templateData={templateData}
                      pdfCategoriesType={pdfCategoriesType}
                      level={level + 1}
                      orderForMedia={element.order === undefined ? '' : element.order}
                    />
                  </Grid>
                </>
              )}
              {elm?.type === "Layout/Group" && (
                <>
                  <Grid container sx={{ mt: "17px" }}>
                    <LayoutGroupElement
                      element={elm}
                      onDragtoggle={onDragtoggle}
                        setOnDragToggle={setOnDragToggle}
                        selectedMediaFiles={selectedMediaFiles}
                        setSelectedMediaFiles={setSelectedMediaFiles}
                        selectedImages={selectedImages}
                        setSelectedImages={setSelectedImages}
                      nestingLevel={nestingLevel + 1}
                      elementType="inner"
                      templateId={templateId}
                      pdfCategoriesType={pdfCategoriesType}
                      orderForMedia={`${orderForMedia}.${elm?.order === undefined ? '' : elm?.order}`}
                    />
                  </Grid>
                </>
              )}
            </>
          ))}
        </Grid>
      </Grid>
    </>
  );
}

export default LayoutGroupElement;
