import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Base64 } from "js-base64";
import { Box, Divider, Grid,Typography } from '@mui/material';
import { clearUpdatedInspectionData, getInspectionData } from '../../Redux/Actions/CommonActions';
import { useDispatch,useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import CircularProgress from '@mui/material/CircularProgress';
// tabs
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// css import
import '../../../Assets/Styles/Containers/_button.scss';
import '../../../Assets/Styles/Containers/_inspection.scss';
import { checkCategoryPresent } from '../../Helpers/HelperFunctions';
import { GetUTCTime } from '../../Helpers/GetUTCTime';
import { UpdateTemplateData, UpdateTemplateDataSpecificWithLoader  } from '../../Helpers/ApiCall';
import { useEffect, useState } from 'react';
import toast from "react-hot-toast";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AddCategoryModal = (props) => {
  let {templateData,showCategoryModal,setShowCategoryModal,showCreateCategoryModal,
    setShowCreateCategoryModal,newCategoryList,selectedCategories,setSelectedCategories,handleCategorySelection,setLoader} = props 
  // states
  const [value, setValue] = useState(0);
  const [addNewCatToast, setAddNewCatToast] = useState(false)


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // redux
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.CommonReducer);
  // functions
  const handleClose=()=>{
        setShowCategoryModal(false);
        setValue(0)
    }
    const handleCreateNewCategory=()=>{
        setShowCreateCategoryModal(true);
        setShowCategoryModal(false);
    }
    const addSelectedCategoriesToTemplate=async ()=>{
      // setLoader(true)
      let newAddedCatArray = []
       let indexValue = storeData?.inspectionData?.data?.inspectiontemplates?.findIndex(itm => itm.template_id == templateData.template_id);
       let prevData = storeData?.inspectionData?.data;
       for (let i = 0;i < selectedCategories.length;i += 1)
       { 
       let newCategory = {
        uuid: uuidv4(),
        prompt: selectedCategories[i]?.prompt,
        introductory_text: null,
        order: storeData?.inspectionData?.data?.inspectiontemplates[indexValue]?.categories?.length,
        is_deleted: 0,
        lines: selectedCategories[i]?.lines !== null ? selectedCategories[i]?.lines:[],
        is_inspection_details: 0,
        updated_at: await GetUTCTime(),
        created_at: await GetUTCTime(),        
      };
      // storeData?.inspectionData?.data?.inspectiontemplates[indexValue]?.categories.push(newCategory)
      storeData?.inspectionData?.data?.inspectiontemplates[indexValue]?.categories.push(selectedCategories[i])
      newAddedCatArray.push(selectedCategories[i])
    } 
    let filteredData = JSON.parse(JSON.stringify(prevData));
    let filteredTemplateObject = filteredData?.inspectiontemplates[indexValue];
    filteredTemplateObject.categories = newAddedCatArray;
    filteredData.inspectiontemplates = [filteredTemplateObject];
    if(selectedCategories.length !== 0 ) {
      setAddNewCatToast(true)
      UpdateTemplateDataSpecificWithLoader(dispatch,prevData,filteredData);
    setShowCategoryModal(false);
    // timeout function to disable loader as category addition response taking some time from api  
    // setTimeout(function() {
    //   setLoader(false)
    //   }, 4200);
    }else {
      setShowCategoryModal(false);
      setLoader(false)
    }
    setValue(0)
    }
    useEffect(() => {
      if(storeData.updatedInspectionData && storeData.updatedInspectionData !== '') {
        if(storeData.updatedInspectionData.success === true){
          // toast.success(storeData?.updatedInspectionData?.message)
        }
        else {
          toast.error(storeData?.updatedInspectionData?.response?.data?.message)
          let sessionAssociateCompanyKey = Base64.decode(sessionStorage.getItem('AC'));
          let guid = sessionStorage.getItem('guid')
          let sessionUserId = Base64.decode(sessionStorage.getItem('UI'));
          dispatch(getInspectionData({guid:guid,associate_company:sessionAssociateCompanyKey,user_id:sessionUserId}))
        }
        dispatch(clearUpdatedInspectionData())
      }
    }, [storeData.updatedInspectionData])

    useEffect(()=>{
      if(storeData?.updatedInspectionData?.success === true && addNewCatToast){
        toast.success("The inspection has been updated successfully.")
        setAddNewCatToast(false)
      }
    },[addSelectedCategoriesToTemplate])
    
  return (
        <Dialog className='dialog-container-category'
          open={showCategoryModal}
          onClose={handleClose}
          aria-labelledby="category-modal"
          aria-describedby="category-modal-description"
        >
         <div className="close-wrapper-category">
            <CloseIcon onClick={handleClose} />
          </div>
          <DialogTitle className='category-dialog-title'>{"Add categories"}</DialogTitle>
          <span className='category-dialog-subtitle'>Choose one or more categories from the list below.</span>
          <DialogContent className="dialog-content-category">
            <Grid container className="input-controll">
              <Grid item xs={12} >
              
                <Box className="lineDetails__tab" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange} aria-label="line_details_tab">
                    <Tab className="tab__label" label="Optional" {...a11yProps(0)} />
                    <Tab className="tab__label" label={`Standard`} {...a11yProps(1)} />
                  </Tabs>
                </Box>
                {/* <Typography className='in_inspection'>In inspection</Typography> */}
                <br/>
                <br/>
                
               
                {/* optional tab */}
                <TabPanel value={value} index={0} className="tab_panel">
                  <div className="tab_container"> 
                  {value === 0  && <Typography className='bordered__text catIsFlex' onClick={()=>handleCreateNewCategory()}><AddCircleIcon/><span className='bordered__text__content2'> Create new category </span> </Typography>}                  
                  {storeData?.addCatLoader === true ?
                <Box className="line_progress" sx={{ display: 'flex' }}>
                <CircularProgress />
                </Box>
            : (newCategoryList.length === 0 || newCategoryList?.optional.length === 0)?
                <p style={{textAlign:'center'}}>No optional category found</p>
                :newCategoryList?.optional?.map((category,index)=>(                   
                    <Typography 
                        onClick={()=>handleCategorySelection(category)}
                        key={index} className={`bordered__text ${selectedCategories.map(cat=>cat.uuid).includes(category.uuid) && 'cat_selected'}`
                      }>  
                        <span className='bordered__text__content'>{category.prompt !== null ? category.prompt : "Untitled"}</span>
                        <DoneIcon className='cat_seleted_icon'/>
                    </Typography>
                  ))}
                  </div>  
                </TabPanel>
                {/* standard tab */}
                <TabPanel value={value} index={1} className="tab_panel">
                <div className="tab_container">
                {storeData?.addCatLoader === true ?
                <Box className="line_progress" sx={{ display: 'flex' }}>
                <CircularProgress />
                </Box>
                : (newCategoryList.length === 0 || newCategoryList?.standard.length === 0)?
                <p style={{textAlign:'center'}}>No standard category found</p>
                :newCategoryList?.standard?.map((category,index)=>(                   
                        <Typography 
                          onClick={()=>handleCategorySelection(category)}
                          key={index} className={`bordered__text ${selectedCategories.map(cat=>cat.uuid).includes(category.uuid) && 'cat_selected'}`
                        }>  
                          <span className='bordered__text__content'>{category.prompt !== null ? category.prompt : "Untitled"}</span>
                          <DoneIcon className='cat_seleted_icon'/>
                        </Typography>
                      ))}
                      </div>
                </TabPanel >
                {/* {
                  templateData.categories.map(category=>(
                    category.is_inspection_details !== 1 &&
                    <Typography onClick={()=>handleCategorySelection(category)} key={category.uuid} className={`bordered__text ${checkCategoryPresent(category,selectedCategories) && 'cat_selected'}`}>  <span className='bordered__text__content'>{category.prompt}</span>
                      <DoneIcon className='cat_seleted_icon'/>
                    </Typography>
                  ))
                } */}
                {/* {
                  newCategoryList.map((category,index)=>(                   
                    <Typography 
                    onClick={()=>handleCategorySelection(category)}
                     key={index} className={`bordered__text ${selectedCategories.map(cat=>cat.prompt).includes(category.prompt) && 'cat_selected'}`
                     }>  <span className='bordered__text__content'>{category.prompt}</span>
                      <DoneIcon className='cat_seleted_icon'/>
                    </Typography>
                  ))
                } */}
              </Grid>
 
            </Grid>
          </DialogContent>
          <Divider style={{width:'100%'}}/>
          <DialogActions className='category-modal-action'>          
            <Button className="add-category-btn" 
            onClick={()=>addSelectedCategoriesToTemplate()}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>   
  )
}

export default AddCategoryModal