import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

function DeleteConfirmationDialog(props) {
  const {
    open,
    handleClose,
    title,
    contentText,
    cancelBtnTitle,
    cancelBtnAction,
    submitBtnTitle,
    submitBtnAction,
    submitBtnParam,
  } = props;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth={true}>
      <DialogTitle sx={{ display: "flex", justifyContent: "flex-end", pb: 0 }}>
        <IconButton>
          <CloseIcon className="dialog_close_btn" onClick={handleClose} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
        <Grid
          container
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <Typography fontSize={18} fontWeight={600} color={"#252525"}>
              {title}
            </Typography>
          </Grid>
          <DialogContentText
            fontSize={14}
            fontWeight={400}
            my={2}
            color={"#252525"}
          >
            {contentText}
          </DialogContentText>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          display={"flex"}
          mb={1.5}
          justifyContent={"center"}
          gap={2}
        >
          <Button
            variant="outlined"
            className="cancel_btn"
            onClick={cancelBtnAction}
          >
            {cancelBtnTitle}
          </Button>
          <Button
            className="clear_btn"
            variant="contained"
            onClick={() => submitBtnAction(submitBtnParam)}
          >
            {submitBtnTitle}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteConfirmationDialog;
