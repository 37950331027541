import { createStore, applyMiddleware, compose } from "redux";
import reducers from "../Reducers/Index";
import createSagaMiddleware from "redux-saga";
 
// Saga root file import
import rootSagaChild from "../Saga/Saga";
 
// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();
 
export default function configureStore() {
  // Configure Redux DevTools
  const composeEnhancers =
    (process.env.NODE_ENV == 'develop' || process.env.NODE_ENV == 'integration') &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) :
      compose;
 
  // Apply middleware and enhancers
  const enhancers = [applyMiddleware(sagaMiddleware)];
  const store = createStore(reducers, composeEnhancers(...enhancers));
 
  // Run the root saga
  sagaMiddleware.run(rootSagaChild);
 
  return store;
}