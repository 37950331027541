import { FormControl, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import parse from "html-react-parser";

function InformationalTextHTMLElement({ element, elementType }) {
  const [htmlText, setHtmlText] = useState("");

  useEffect(() => {
    const valueAsString = String(element?.value || "");
    setHtmlText(valueAsString);
  }, [element]); // Add element as a dependency

  const renderHtmlContent = () => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlText;
    const ulElements = tempDiv.getElementsByTagName("ul");
    const olElements = tempDiv.getElementsByTagName("ol");
    for (const ulElement of ulElements) {
      ulElement.style.marginLeft = "16px";
    }
    for (const olElement of olElements) {
      olElement.style.marginLeft = "12px";
    }
    return parse(tempDiv.innerHTML);
  };

  return (
    <>
      <Grid
        container
        className={`${
          elementType === "inner" || elementType === "layout"
            ? "pdf_container"
            : "pdf_inner_container"
        }`}
      >
        <FormControl variant="standard" className="forms-control w-100">
          <Grid item xs={12} sx={{ lineHeight: "22px" }}>
            <span style={{ fontSize: "16px" }}>{renderHtmlContent()}</span>
          </Grid>
        </FormControl>
      </Grid>
    </>
  );
}

export default InformationalTextHTMLElement;
