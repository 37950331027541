import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function PreloaderWithText(props) {
  return (
    <div>
      {
        props.showPreloader ? 
        <Backdrop
          sx={{ color: '#fff', zIndex: "99999" }}
          open={true}
          className="loader_with_text"
        >
          <CircularProgress color="inherit" />
          {props.loadText!== '' && <p className="loading_text">{props.loadText}</p>}
        </Backdrop> : ''
      }
    </div>
  );
}
