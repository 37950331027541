import ACTION_TYPES from "../Constants/ActionTypes";
const initialState = {

};
 // eslint-disable-next-line
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ACTION_TYPES.GET_INSPECTION_RESPONSE:
        return { ...state, rw_api_inspectionData: payload };
    case ACTION_TYPES.GET_INSPECTION_ERROR:
        return { ...state, rw_api_inspectionDataError: payload };
    default:
      return state;
  }
};
