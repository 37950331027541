import React from "react";
import DeleteConfirmationDialog from "../../Common/Alert/DeleteConfirmationDialog";

const DeleteCoverPhoto = (props) => {
  const { showDeleteModal, setShowDeleteModal, deleteCoverPhoto } = props;

  const handleClose = () => {
    setShowDeleteModal(false);
  };

  return (
    <DeleteConfirmationDialog
      open={showDeleteModal}
      handleClose={handleClose}
      title={"Delete confirmation"}
      contentText={"Are you sure you want to delete the cover photo?"}
      cancelBtnTitle={"Cancel"}
      cancelBtnAction={handleClose}
      submitBtnTitle={"Confirm"}
      submitBtnAction={deleteCoverPhoto}
    />
  );
};

export default DeleteCoverPhoto;
