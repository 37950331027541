import { useState, useRef, useEffect, useCallback } from 'react';
import { Grid, Typography, useMediaQuery } from '@mui/material'
import { useDispatch, useSelector } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ShowMoreLess from '../ShowMoreLess';
import MediaGalleryModal from './MediaGalleryModal';
import DeviceDetector from "device-detector-js";
import { filterObjectsUptoLine } from '../../../Helpers/HelperFunctions';
import { UpdateTemplateDataSpecific } from '../../../Helpers/ApiCall';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Base64 } from "js-base64";
import { v4 as uuidv4 } from 'uuid';
import { toast } from "react-hot-toast";
import { GetUTCTime } from '../../../Helpers/GetUTCTime';
import { UpdateMedia } from '../../../Helpers/ApiCall';
import Preloader from '../../../Helpers/Preloader';
import {
  generateVideoThumbnails,
  importFileandPreview
} from "@rajesh896/video-thumbnails-generator";
import { getInspectionData } from '../../../Redux/Actions/CommonActions';
import PreloaderWithText from '../../../Helpers/PreloaderWithText';
import { origin } from '../../../Config/Api-constants';
import axios from 'axios';
import Compressor from 'compressorjs';
import { useDropzone } from 'react-dropzone';
import { getImageDimensions, resizeImage } from '../../../Helpers/HelperFunctions';

const Media = ({ data, title, action, templateId, categoryUuid, lineUuid, selectedMediaFiles, setSelectedImages, setSelectedMediaFiles, toggle, setOnDragToggle, commentUuid, categoryId, setRefresh, width, setWidth, height, setHeight, commentIndex, isNarrative, addCommentFromLine }) => {
  const [showMediaGalleryModal, setShowMediaGalleyModal] = useState(false);
  const [mediaData, setMediaData] = useState('')
  const [anchorEl, setAnchorEl] = useState(null);
  const [image, setImage] = useState("");
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loaderText, setLoaderText] = useState('')
  const [inspectionResponse, setInspectionResponse] = useState();
  const open = Boolean(anchorEl);
  const matches = useMediaQuery('(min-width:1400px)');

  const fileInput = useRef(null);
  const editFileInput = useRef(null);
  const commonReducer = useSelector((state) => state.CommonReducer);
  const deviceDetector = new DeviceDetector();
  const userAgent = "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/69.0.3497.81 Safari/537.36";
  const device = deviceDetector.parse(userAgent);
  // redux
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.CommonReducer);

  let responseCount = 0;
  let videoUploadCount = 0;
  let imageuploadCount = 0;
  let successCount = 0;
  let errorCount = 0;
  let sizeErrorCount = 0;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMediaUpload = (formData) => {
    // let imageLength = image.length
    // alert("imageLength",imageLength)
    //   for(let i=0;i<imageLength;i++){
    //     console.log("images",image[i])
    //     alert('i here')
    //   }
    UpdateMedia(dispatch, formData)
  }
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const callInitiateAPI = () => {
    setLoader2(false)
    setLoaderText('')
    let sessionAssociateCompanyKey = Base64.decode(sessionStorage.getItem('AC'));
    let guid = sessionStorage.getItem('guid')
    let sessionUserId = Base64.decode(sessionStorage.getItem('UI'));
    dispatch(getInspectionData({ guid: guid, associate_company: sessionAssociateCompanyKey, user_id: sessionUserId }))
    setLoader(false)
  }

  async function convertBase64ToFile(base64String, filename, file, formData, fileType, currentMediaLength, orderVal, e, videoCount, type) {
    setLoaderText(`uploading video ${videoUploadCount + 1} of ${videoCount}`)
    const arr = base64String[0].split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let uploadfile
    if (type == "drag") {
      uploadfile = e
    } else {
      uploadfile = e.target.files
    }
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let thumbVideoFile = new File([u8arr], filename, { type: mime })
    let thumbFile;
    const { imgWidth, imgHeight } = await getImageDimensions(thumbVideoFile);
    const maxWidth = 320;
    const maxHeight = 320;
    const quality = 1;
    let newWidth, newHeight;

    if (imgWidth > imgHeight) {
      // Landscape orientation
      newHeight = maxHeight;
      newWidth = (maxHeight / imgHeight) * imgWidth;
    } else {
      // Portrait orientation
      newWidth = maxWidth;
      newHeight = (maxWidth / imgWidth) * imgHeight;
    }
    let thumbnailImage = await resizeImage(thumbVideoFile, newWidth, newHeight, quality);
    thumbFile = new File([thumbnailImage], `${thumbnailImage.name}`, { type: 'image/jpeg' });
    let imgObj = {
      id: uuidv4(),
      url: null,
      name: file.name,
      size: file.size,
      type: file.type,
      edited: false,
      newUrl: null,
      online: "online",
      filetype: fileType,
      localUrl: null,
      OriginUrl: null,
      editCount: 0,
      onlineUrl: null,
      orginName: null,
      thumbnail: null,
      is_deleted: 0,
      order: currentMediaLength + orderVal,
      updated_at: await GetUTCTime(),
      description: null,
      OriginlocalUrl: null,
      isOriginUploaded: true
    }
    let img_obj_string = JSON.stringify(imgObj)
    let params = {}
    if (action === "comment") {
      params = {
        video: file,
        inspection_guid: sessionStorage.getItem('guid'),
        template_id: templateId,
        video_thumbnail: thumbFile,
        category_id: categoryUuid,
        line_id: lineUuid,
        comment_id: commentUuid,
        img_object: img_obj_string,
        img_type: 'comment',
      }
    }
    else {
      params = {
        video: file,
        inspection_guid: sessionStorage.getItem('guid'),
        template_id: templateId,
        video_thumbnail: thumbFile,
        category_id: categoryUuid,
        line_id: lineUuid,
        img_object: img_obj_string,
        img_type: 'details',
      }
    }
    Object.keys(params).forEach((key) => formData.append(key, params[key]));
    // handleMediaUpload(formData)

    // sync API call
    let baseUrl = await origin();
    let sessionAPIKey = sessionStorage.getItem('CK');
    let sessionSecretKey = sessionStorage.getItem('SK')
    try {
      const response = await axios.post(`${baseUrl}upload_media_for_inspection_template`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${Base64.decode(sessionSecretKey)}`,
          'api-key': `${Base64.decode(sessionAPIKey)}`,
          'platform': 'web',
        }
      });
      const result = response.data;
      responseCount++
      videoUploadCount++;
      successCount++;
    } catch (error) {
      responseCount++;
      videoUploadCount++;
      errorCount++;
      toast.error(error?.message)
    }
    if (responseCount === uploadfile?.length) {
      if (successCount > 0) toast.success(`${successCount} ${successCount === 1 ? 'media item has' : 'media items have'} been uploaded successfully. `, {
        duration: 4000,
      })
      if (errorCount > 0) toast.error(`${(errorCount - sizeErrorCount) > 0 ? `${errorCount - sizeErrorCount}  media ${(errorCount - sizeErrorCount) === 1 ? 'item was' : 'items were'} not uploaded, the file format is not supported` : ''} ${sizeErrorCount > 0 ? `${sizeErrorCount} Media ${sizeErrorCount === 1 ? 'item' : 'items'} not uploaded because the media file is greater than 1024MB.` : ""}`, {
        duration: 5000, // Set the timeout duration to 3000 milliseconds (3 seconds)
      })
      callInitiateAPI()
      fileInput.current.value = null;
      editFileInput.current.value = null;
      responseCount = 0;
      videoUploadCount = 0;
      imageuploadCount = 0;
      errorCount = 0;
      sizeErrorCount = 0;
      successCount = 0;
    }

    // return new File([u8arr], filename, {type:mime});
  }

  async function compressImage(file) {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.6,
        success(result) {
          resolve(result);
        },
        error(err) {
          reject(err);
        },
      });
    });
  }

  const handleAddMedia = async (e, type) => {
    let imageCount = 0;
    let videoCount = 0;
    let currentMediaLength = mediaData?.length;
    let fileSizeStatus = [];
    let formatStatus = []
    let uploadfile
    if (type == "drag") {
      uploadfile = e
    } else {
      uploadfile = e.target.files
    }
    setImage(uploadfile);
    for (let i = 1; i <= uploadfile.length; i++) {
      let formData = new FormData();
      let file = uploadfile[i - 1]
      const fileSize = file.size
      const sizeInMB = Math.round((fileSize / (1024 * 1024)));
      console.log("sizeInMB", sizeInMB)
      let fileTypeCheck = null
      if (file?.type === "image/jpeg" || file?.type === "image/png"
        || file?.type === "image/jpg") {
        fileTypeCheck = "image"
      } else if (file?.type === "image/tiff" || file?.type === "image/bmp"
        || file?.type === "image/gif" || file?.type === "image/svg"
        || file?.type === "image/ico" || file?.type === "image/x-icon") {
        fileTypeCheck = "unsupported"
      }
      else {
        fileTypeCheck = "video"
      }
      if (fileTypeCheck === "image" || fileTypeCheck === "unsupported") imageCount++
      if (fileTypeCheck === "video") videoCount++
    }

    setLoader2(true);
    if (uploadfile.length === 0) setLoader2(false)
    for (let i = 1; i <= uploadfile.length; i++) {
      let formData = new FormData();
      let file = uploadfile[i - 1]
      let fileType = null
      if (file?.type === "image/jpeg" || file?.type === "image/png"
        || file?.type === "image/jpg") {
        fileType = "image"
      } else if (file?.type === "image/tiff" || file?.type === "image/bmp"
        || file?.type === "image/gif" || file?.type === "image/svg"
        || file?.type === "image/ico" || file?.type === "image/x-icon") {
        fileType = "unsupported"
      }
      else {
        fileType = "video"
      }
      const fileSize = file.size
      const sizeInMB = Math.round((fileSize / (1024 * 1024)));
      console.log("sizeInMB", sizeInMB)
      if (sizeInMB >= 1024) {
        // toast.error("File size too Big, please select a file less than 500 MB");
        errorCount++;
        responseCount++;
        sizeErrorCount++;
        if (responseCount === uploadfile.length) {
          if (successCount > 0) toast.success(`${successCount} ${successCount === 1 ? 'media item has' : 'media items have'} been uploaded successfully. `, {
            duration: 4000,
          })
          if (errorCount > 0) toast.error(`${(errorCount - sizeErrorCount) > 0 ? `${errorCount - sizeErrorCount}  media ${(errorCount - sizeErrorCount) === 1 ? 'item was' : 'items were'} not uploaded, the file format is not supported` : ''} ${sizeErrorCount > 0 ? `${sizeErrorCount} Media ${sizeErrorCount === 1 ? 'item was' : 'items were'} not uploaded because the media file is greater than 1024MB.` : ""}`, {
            duration: 5000, // Set the timeout duration to 5000 milliseconds (3 seconds)
          })
          callInitiateAPI()
          fileInput.current.value = null;
          editFileInput.current.value = null;
          responseCount = 0;
          videoUploadCount = 0;
          imageuploadCount = 0;
          errorCount = 0;
          sizeErrorCount = 0;
          successCount = 0;
        }
      } else if (!file.type.match(/(image\/(png|jpg|jpeg))|(video\/(mp4|mov|quicktime))/) || file?.name?.toLowerCase()?.endsWith(".jfif")) {
        errorCount++;
        responseCount++;
        if (responseCount === uploadfile.length) {
          if (successCount > 0) toast.success(`${successCount} ${successCount === 1 ? 'media item has' : 'media items have'} been uploaded successfully.`, {
            duration: 4000,
          })
          if (errorCount > 0) toast.error(`${(errorCount - sizeErrorCount) > 0 ? `${errorCount - sizeErrorCount}  media ${(errorCount - sizeErrorCount) === 1 ? 'item was' : 'items were'} not uploaded, the file format is not supported.` : ''} ${sizeErrorCount > 0 ? `${sizeErrorCount} Media ${sizeErrorCount === 1 ? 'item was' : 'items were'} not uploaded because the media file is greater than 1024MB.` : ""}`, {
            duration: 5000, // Set the timeout duration to 5000 milliseconds (3 seconds)
          })
          callInitiateAPI()
          fileInput.current.value = null;
          editFileInput.current.value = null;
          responseCount = 0;
          videoUploadCount = 0;
          imageuploadCount = 0;
          errorCount = 0;
          sizeErrorCount = 0;
          successCount = 0;
        }
      } else {
        if (fileType === "image") {
          setLoaderText(`compressing image ${imageuploadCount + 1} of ${imageCount}`)
          let compressedFile;
          let thumbImage;
          const { imgWidth, imgHeight } = await getImageDimensions(file);
          const maxWidth = 320;
          const maxHeight = 320;
          const quality = 0.8;
          let newWidth, newHeight;
          if (imgWidth > imgHeight) {
            // Landscape orientation
            newHeight = maxHeight;
            newWidth = (maxHeight / imgHeight) * imgWidth;
          } else {
            // Portrait orientation
            newWidth = maxWidth;
            newHeight = (maxWidth / imgWidth) * imgHeight;
          }
          let thumbnailImage = await resizeImage(file, newWidth, newHeight, quality);
          thumbImage = new File([thumbnailImage], `${thumbnailImage.name}`, { type: 'image/jpeg' });
          if (file?.size > 512000) {
            // =======
            try {
              const compressedFilee = await compressImage(file);
              compressedFile = new File([compressedFilee], `${compressedFilee.name}`, { type: 'image/jpeg' });
              setLoaderText(`uploading image ${imageuploadCount + 1} of ${imageCount}`)
              let imgObj = {
                id: uuidv4(),
                url: null,
                name: compressedFile.name,
                size: compressedFile.size,
                type: compressedFile.type,
                edited: false,
                newUrl: null,
                online: "online",
                filetype: fileType,
                localUrl: null,
                OriginUrl: null,
                editCount: 0,
                onlineUrl: null,
                orginName: null,
                thumbnail: null,
                is_deleted: 0,
                order: currentMediaLength + i,
                updated_at: await GetUTCTime(),
                description: null,
                OriginlocalUrl: null,
                isOriginUploaded: true
              }
              let img_obj_string = JSON.stringify(imgObj)
              let params = {}
              if (action === "comment") {
                params = {
                  image: compressedFile,
                  video_thumbnail: thumbImage,
                  inspection_guid: sessionStorage.getItem('guid'),
                  template_id: templateId,
                  category_id: categoryUuid,
                  line_id: lineUuid,
                  comment_id: commentUuid,
                  img_object: img_obj_string,
                  img_type: 'comment',
                }
              }
              else {
                params = {
                  image: compressedFile,
                  video_thumbnail: thumbImage,
                  inspection_guid: sessionStorage.getItem('guid'),
                  template_id: templateId,
                  category_id: categoryUuid,
                  line_id: lineUuid,
                  img_object: img_obj_string,
                  img_type: 'details',
                }
              }

              Object.keys(params).forEach((key) => formData.append(key, params[key]));

              // sync API call
              let baseUrl = await origin();
              let sessionAPIKey = sessionStorage.getItem('CK');
              let sessionSecretKey = sessionStorage.getItem('SK')
              try {
                const response = await axios.post(`${baseUrl}upload_media_for_inspection_template`, formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${Base64.decode(sessionSecretKey)}`,
                    'api-key': `${Base64.decode(sessionAPIKey)}`,
                    'platform': 'web',
                  }
                });
                const result = response.data;
                responseCount++;
                imageuploadCount++;
                successCount++;
              } catch (error) {
                toast.error(error?.message)
                responseCount++
                errorCount++;
                imageuploadCount++
              }
              if (responseCount === uploadfile.length) {
                if (successCount > 0) toast.success(`${successCount} ${successCount === 1 ? 'media item has' : 'media items have'} been uploaded successfully. `, {
                  duration: 4000,
                })
                if (errorCount > 0) toast.error(`${(errorCount - sizeErrorCount) > 0 ? `${errorCount - sizeErrorCount}  media ${(errorCount - sizeErrorCount) === 1 ? 'item was' : 'items were'} not uploaded, the file format is not supported.` : ''} ${sizeErrorCount > 0 ? `${sizeErrorCount} Media ${sizeErrorCount === 1 ? 'item was' : 'items were'} not uploaded because the media file is greater than 1024MB.` : ""}`, {
                  duration: 5000, // Set the timeout duration to 5000 milliseconds (3 seconds)
                })
                callInitiateAPI()
                fileInput.current.value = null;
                editFileInput.current.value = null;
                responseCount = 0;
                videoUploadCount = 0;
                imageuploadCount = 0;
                errorCount = 0;
                sizeErrorCount = 0;
                successCount = 0;
              }
              // Do something with the compressed file
            } catch (error) {
              console.error('Error compressing image:', error);
            }

            // ======
          }
          else {
            compressedFile = file;
            setLoaderText(`uploading image ${imageuploadCount + 1} of ${imageCount}`)
            let imgObj = {
              id: uuidv4(),
              url: null,
              name: compressedFile.name,
              size: compressedFile.size,
              type: compressedFile.type,
              edited: false,
              newUrl: null,
              online: "online",
              filetype: fileType,
              localUrl: null,
              OriginUrl: null,
              editCount: 0,
              onlineUrl: null,
              orginName: null,
              thumbnail: null,
              is_deleted: 0,
              order: currentMediaLength + i,
              updated_at: await GetUTCTime(),
              description: null,
              OriginlocalUrl: null,
              isOriginUploaded: true
            }
            let img_obj_string = JSON.stringify(imgObj)
            let params = {}
            if (action === "comment") {
              params = {
                image: compressedFile,
                video_thumbnail: thumbImage,
                inspection_guid: sessionStorage.getItem('guid'),
                template_id: templateId,
                category_id: categoryUuid,
                line_id: lineUuid,
                comment_id: commentUuid,
                img_object: img_obj_string,
                img_type: 'comment',
              }
            }
            else {
              params = {
                image: compressedFile,
                video_thumbnail: thumbImage,
                inspection_guid: sessionStorage.getItem('guid'),
                template_id: templateId,
                category_id: categoryUuid,
                line_id: lineUuid,
                img_object: img_obj_string,
                img_type: 'details',
              }
            }

            Object.keys(params).forEach((key) => formData.append(key, params[key]));

            // sync API call
            let baseUrl = await origin();
            let sessionAPIKey = sessionStorage.getItem('CK');
            let sessionSecretKey = sessionStorage.getItem('SK')
            try {
              const response = await axios.post(`${baseUrl}upload_media_for_inspection_template`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'Authorization': `Bearer ${Base64.decode(sessionSecretKey)}`,
                  'api-key': `${Base64.decode(sessionAPIKey)}`,
                  'platform': 'web',
                }
              });
              const result = response.data;
              responseCount++;
              imageuploadCount++;
              successCount++;
            } catch (error) {
              responseCount++
              errorCount++
              imageuploadCount++;
              toast.error(error?.message);
            }
            if (responseCount === uploadfile.length) {
              if (successCount > 0) toast.success(`${successCount} ${successCount === 1 ? 'media item has' : 'media items have'} been uploaded successfully. `, {
                duration: 4000,
              })
              if (errorCount > 0) toast.error(`${(errorCount - sizeErrorCount) > 0 ? `${errorCount - sizeErrorCount}  media ${(errorCount - sizeErrorCount) === 1 ? 'item was' : 'items were'} not uploaded, the file format is not supported.` : ''} ${sizeErrorCount > 0 ? `${sizeErrorCount} Media ${sizeErrorCount === 1 ? 'item was' : 'items were'} not uploaded because the media file is greater than 1024MB.` : ""}`, {
                duration: 5000, // Set the timeout duration to 5000 milliseconds (3 seconds)
              })
              callInitiateAPI()
              fileInput.current.value = null;
              editFileInput.current.value = null;
              responseCount = 0;
              videoUploadCount = 0;
              imageuploadCount = 0;
              errorCount = 0;
              sizeErrorCount = 0;
              successCount = 0;
            }
          }
          // sync api call

          // handleMediaUpload(formData)
        }
        else if (fileType === "video") {
          setLoader2(true)
          setLoaderText(`generating thumbnail for video ${videoUploadCount + 1} of ${videoCount}`)
          await generateVideoThumbnails(file, 10).then(async (thumbs) => {
            await convertBase64ToFile(thumbs, 'name', file, formData, fileType, currentMediaLength, i, e, videoCount, type)
          });
        }
      }
    }

  }

  useEffect(() => {
    setMediaData(data !== null && data !== undefined && data.data !== null && data.data.length !== 0 ? data.data : '')
  }, [data])

  useEffect(() => {
    if (storeData?.loader) setLoader(true)
    else {
      // loader && toast.success(storeData?.inspectionData?.message)
      setLoader2(false)
      setLoader(false)
    }
  }, [storeData?.loader])

  useEffect(() => {
    if (storeData.inspectionData?.data) {
      setLoader(false);
      setLoader2(false);
      setInspectionResponse(storeData);
    }
  }, [storeData.inspectionData?.data])

  useEffect(() => {
    setLoader(false);
    setLoader2(false);
  }, [storeData.updatedInspectionData])

  const onDrop = useCallback(acceptedFiles => {
    handleAddMedia(acceptedFiles, "drag")
  }, [handleAddMedia])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const HandleMediaDescription = async () => {
    selectedMediaFiles.forEach(async (item) => {
      item.is_deleted = 0;
      item.updated_at = await GetUTCTime();
      item.edited = false;
      item.editCount = 0;
    })
    if (action === "comment") {
      let templateIndex =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates?.findIndex(
          (item) => item.template_id === templateId
        );
      let categoryIndex =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories?.findIndex((item) => item.id === categoryId);
      let lineIndex =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories[categoryIndex]?.lines?.findIndex(
          (line) => line.uuid === lineUuid
        );
      let lineInputControlIndex =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories[categoryIndex]?.lines[
          lineIndex
        ]?.line_input_controls?.findIndex(
          (lineInput) => lineInput.type === "Media"
        );
      let commentIndex =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories[categoryIndex]?.lines[lineIndex]?.comments?.findIndex(
          (item) => item.uuid === commentUuid
        );
      let mediaIndex =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories[categoryIndex]?.lines[lineIndex]?.comments[
          commentIndex
        ]?.comment_input_controls?.findIndex((item) => item.type === "Media");
      if (mediaIndex !== -1) {
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories[categoryIndex]?.lines[lineIndex]?.comments[
          commentIndex
        ]?.comment_input_controls[mediaIndex]?.data?.push(...selectedMediaFiles);

        let prevData = inspectionResponse?.inspectionData?.data;
        prevData.inspectiontemplates[templateIndex].categories[
          categoryIndex
        ].lines[lineIndex].comments[commentIndex].comment_input_controls[
          mediaIndex
        ].updated_at = await GetUTCTime();
        let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
        UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
        setSelectedMediaFiles([]);
        setSelectedImages([]);
      }
      if (mediaIndex === -1) {
        let comment_input_data = {
          "type": "Media",
          "data": selectedMediaFiles,
          "prompt": "Media",
          "updated_at": await GetUTCTime(),
          "uuid": uuidv4(),
        }
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories[categoryIndex]?.lines[lineIndex]?.comments[
          commentIndex
        ]?.comment_input_controls?.push(comment_input_data);
        let prevData = inspectionResponse?.inspectionData?.data;
        let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
        UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
        setSelectedMediaFiles([]);
        setSelectedImages([]);
      }
    } else {
      let templateIndex =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates?.findIndex(
          (item) => item.template_id === templateId
        );
      let categoryIndex =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories?.findIndex((item) => item.id === categoryId);
      let lineIndex =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories[categoryIndex]?.lines?.findIndex(
          (line) => line.uuid === lineUuid
        );
      let linIpIdx =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories[categoryIndex]?.lines[
          lineIndex
        ]?.line_input_controls?.findIndex(
          (lineInput) => lineInput.type === "Media"
        );
      if (linIpIdx === -1) {
        let line_input_data = {
          "type": "Media",
          "data": selectedMediaFiles,
          "prompt": "Media",
          "updated_at": await GetUTCTime(),
          "uuid": uuidv4(),
        }
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories[categoryIndex]?.lines[lineIndex]?.line_input_controls?.push(line_input_data);
        let prevData = inspectionResponse?.inspectionData?.data;
        let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
        UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
        setSelectedMediaFiles([]);
        setSelectedImages([]);
      }
      if (linIpIdx !== -1) {
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories[categoryIndex]?.lines[lineIndex]?.line_input_controls[
          linIpIdx
        ]?.data?.push(...selectedMediaFiles);
        let prevData = inspectionResponse?.inspectionData?.data;
        prevData.inspectiontemplates[templateIndex].categories[
          categoryIndex
        ].lines[lineIndex].line_input_controls[linIpIdx].updated_at =
          await GetUTCTime();
        let filteredObject = filterObjectsUptoLine(prevData, templateIndex, categoryIndex, lineIndex);
        UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
        setSelectedMediaFiles([]);
        setSelectedImages([]);
      }
    }
  };

  const onDropFn = useCallback(
    (acceptedFiles, type) => {
      if (type === "outside") {
        if (selectedMediaFiles?.length > 0) {
          HandleMediaDescription()
        }
      }
    },
    [handleAddMedia]
  );

  return (
    <>
      <Preloader showPreloader={loader} />
      <PreloaderWithText showPreloader={loader2} loadText={loaderText} />
      <MediaGalleryModal showMediaGalleryModal={showMediaGalleryModal} setShowMediaGalleyModal={setShowMediaGalleyModal} data={data?.data} dataName={data} action={action} templateId={templateId} categoryId={categoryId} categoryUuid={categoryUuid} lineUuid={lineUuid} commentUuid={commentUuid} setRfresh={setRefresh} setImage={setImage} loader={loader} setLoader={setLoader} setRefresh={setRefresh} handleAddMedia={handleAddMedia} setLoader2={setLoader2} editFileInput={editFileInput} title={title} />
      <div style={{ margin: "0px 0px 0px 10px", width: "98.5%" }}>
        {data?.is_disabled !== 1 ?
          <Grid container spacing={1}
            {...getRootProps({
              onDrop: (event) => onDropFn(event.dataTransfer.files, "outside"),
            })}
            style={{ border: isDragActive || toggle ? "2px dashed #4282e2" : "" }}
            className={`${action === "line" ? 'container' : 'inner_container'}`}
            onClick={(e) => e?.stopPropagation()}>
            <Grid item xs={12} className="line_detail_des_title">
              <span className={`${action === "line" ? 'details_title' : 'inner_details_title'}`}>{action == "line" ? data?.prompt !== undefined ? data?.prompt : title : title}</span>
              <span className={`${action === "line" ? "details_action" : "inner_details_action"}`} id={`${action === "comment" ? `edit_comment_media_${commentIndex + 1}` : 'edit_media'}`} onClick={() => setShowMediaGalleyModal(true)}>Manage<ArrowForwardIosIcon sx={{ fontSize: "13px !important" }} /></span>
            </Grid>
            <Grid item xs={12} sm={6} md={storeData?.reportSideBarActive ? 12 : 4} lg={storeData?.reportSideBarActive ? !matches ? 6 : 4 : 3}>
              <Typography className='add-media-content_for_line_details' id={`${action === "comment" ? `add_comment_media_box_${commentIndex + 1}` : 'add_media_box'}`} onClick={() => fileInput.current.click()}>
                <input
                  ref={fileInput}
                  type="file"
                  accept="image/*,video/*"
                  onChange={handleAddMedia}
                  style={{ display: "none" }}
                  multiple
                />
                <div style={{ marginTop: "30px" }}><AddCircleIcon /></div>
                <div style={{ textDecoration: "underline", color: "#4282e2" }}>Click to add media</div>
                <div>or drag and drop</div>
              </Typography>
            </Grid>
            {(mediaData !== undefined && mediaData.length !== 0) ?
              <ShowMoreLess body={mediaData} templateId={templateId} categoryId={categoryId} categoryUuid={categoryUuid}
                matches={matches} lineUuid={lineUuid} commentUuid={commentUuid} action={action} width={width} setWidth={setWidth} height={setHeight} setHeight={setHeight} mediaData={mediaData} isNarrative={isNarrative} addCommentFromLine={addCommentFromLine} /> :
              <div>
              </div>
            }

          </Grid> : ""}
      </div>
    </>
  )
}

export default Media