import { Grid } from '@mui/material'
import CallMissedOutgoingIcon from "@mui/icons-material/CallMissedOutgoing";
import { checkCategoryComplete, checkLineComplete, checkTemplateComplete } from '../../Helpers/HelperFunctions';
import { useNavigate } from 'react-router-dom';

const IncompleteItemReview = ({inspectionResponse}) => {
    let navigate = useNavigate();
  return (
    <>
      {inspectionResponse?.map((template) =>
        !checkTemplateComplete(template) ? (
          <Grid container className="coll_inner_div" id={template?.template_id}>
            <span className="incomplete_temp">{template.name}</span>
            <div className="coll_div right_padding">
              <div className="card_wrapper">
                {template?.categories?.filter(itm=>itm.is_inspection_details !== 1)?.map((category, categoryIdx) =>
                  category.is_deleted !==1 && !checkCategoryComplete(category) ? (
                    <>
                      <div
                        className={`coll_heading ${categoryIdx > 0 ? "spacing" : ""}`}
                      >
                        {category.prompt === null
                          ? "Untitled"
                          : category.prompt}
                      </div>
                      {category?.lines?.map((line, lineIdx) =>
                        line?.is_deleted!==1 && !checkLineComplete(line) ? (
                          <div
                            className={`incomplete_linename border_bottom`}
                            // ${
                            //   template?.categories?.length ===
                            //     categoryIdx + 1 &&
                            //   category?.lines?.length === lineIdx + 1
                            //     ? ""
                            //     : "border_bottom"
                            // }
                            // `}
                            onClick={() => {
                              navigate(
                                `/inspection/${
                                  category.prompt !== null
                                    ? encodeURIComponent(category.prompt)
                                    : "Untitled"
                                }/${
                                  line.prompt !== null
                                    ? encodeURIComponent(line.prompt)
                                    : "Untitled"
                                }`,
                                {
                                  state: {
                                    line,
                                    template_id: template.template_id,
                                    category_id: category.id,
                                    category_uuid: category.uuid,
                                    line_uuid: line.uuid,
                                    isNarrative:template?.is_narrative_template === 1? true:false,
                                  },
                                }
                              )
                            //   activateLine(line.uuid);
                            }}
                          >
                            <span>
                              {line.prompt === null ? "Untitled" : line.prompt}
                            </span>
                            <CallMissedOutgoingIcon />
                          </div>
                        ) : (
                          ""
                        )
                      )}
                    </>
                  ) : (
                    ""
                  )
                )}
              </div>
            </div>
          </Grid>
        ) : (
          ""
        )
      )}
    </>
  );
}

export default IncompleteItemReview