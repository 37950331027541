import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.scss';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './App/Helpers/ErrorBoundary';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <DndProvider backend={HTML5Backend}>
    <ErrorBoundary fallback="Something went wrong..">
      <App />
    </ErrorBoundary>
    </DndProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
