import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 30,
    borderRadius: 6,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: !theme.palette.mode === 'light' ? '#F3F4F5' : '#4282E2',
      mixBlendMode: 'normal',
    opacity: '0.14'
    },
    progressLabel: {
        position: "absolute",
        width: "100%",
        height: "100%",
        zIndex: 1,
        maxHeight: "75px", // borderlinearprogress root.height
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        "& span": {
          width: "100%"
        }
      }
  }));

  export const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));


  export const attachmentData = [
    {
        id:1,
        name:"Contract",
        image:'Jones_contract_v2.jpg'
    },
    {
        id:2,
        name:"porchview",
        image:'porchview.jpg'
    },
    {
        id:3,
        name:"Inspection process",
        image:'inspection_process_34566.pdf'
    },
  ]
  
  export const menuData = [
    {
        "inspection_id": 97,
        "template_id": 129,
        uuid: "55174518-5442-4d2a-b20d-3edbec31feaf",
        name: "House Variants Test",
        "rating_data": [
            {
                Id: "1",
                name: "Good",
                Color: "#1CDBD7",
                "Definition": "Good Rating Explanation",
                "is_default": 0,
                "include_in_summary": 0
            },
            {
                Id: "2",
                name: "Medium",
                Color: "#035701",
                "Definition": "Medium rating explanation",
                "is_default": 0,
                "include_in_summary": "1"
            },
            {
                Id: "3",
                name: "Bad",
                Color: "#B628F9",
                "Definition": "Bad rating explananation",
                "is_default": 0,
                "include_in_summary": 1
            },
            {
                Id: "4",
                name: "Average",
                Color: "#82E01F",
                "Definition": null,
                "is_default": false,
                "include_in_summary": "1"
            }
        ],
        "location_data": [
            {
                "col": "1",
                "order": "1",
                "value": "North"
            },
            {
                "col": "2",
                "order": "1",
                "value": "Kitchen"
            },
            {
                "col": "3",
                "order": "1",
                "value": "1st Floor"
            },
            {
                "col": "1",
                "order": "2",
                "value": "South"
            },
            {
                "col": "2",
                "order": "2",
                "value": "Bedroom"
            },
            {
                "col": "3",
                "order": "2",
                "value": "2nd Floor"
            },
            {
                "col": "1",
                "order": "3",
                "value": "East"
            },
            {
                "col": "2",
                "order": "3",
                "value": "Dinning room"
            },
            {
                "col": "3",
                "order": "3",
                "value": "3rd Floor"
            },
            {
                "col": "1",
                "order": "4",
                "value": "West"
            },
            {
                "col": "2",
                "order": "4",
                "value": "Garage"
            },
            {
                "col": "3",
                "order": "4",
                "value": "Living room"
            },
            {
                "col": "1",
                "order": "5",
                "value": "Northwest"
            },
            {
                "col": "2",
                "order": "5",
                "value": "Closet"
            },
            {
                "col": "3",
                "order": "5",
                "value": "Bathroom"
            },
            {
                "col": "1",
                "order": "6",
                "value": "Northeast"
            },
            {
                "col": "2",
                "order": "6",
                "value": "Master"
            },
            {
                "col": "3",
                "order": "6",
                "value": "Attic"
            },
            {
                "col": "1",
                "order": "7",
                "value": "Southwest"
            },
            {
                "col": "2",
                "order": "7",
                "value": "Basement"
            },
            {
                "col": "3",
                "order": "7",
                "value": "Utility room"
            },
            {
                "col": "1",
                "order": "8",
                "value": "Southeast"
            },
            {
                "col": "2",
                "order": "8",
                "value": "Crawlspace"
            },
            {
                "col": "3",
                "order": "8",
                "value": "Laundry"
            }
        ],
        categories: [
            {
                uuid: "ae8763fb-906c-4083-a927-311c31a03b67",
                prompt: "Inspection Details",
                "introductory_text": null,
                lines: [
                    {
                        "order": 1,
                        prompt: "Inspection Details",
                        comments: [],
                        "line_uuid": "800c8d22-d550-4ba3-9b70-473ad2090e16",
                        "is_deleted": 0,
                        "defaultRating_id": 1,
                        "default_location": null,
                        "line_input_controls": [
                            {
                                "type": "CheckBox",
                                prompt: "Others Present",
                                "values": [
                                    {
                                        "value": "Buyer",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Seller",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Buyer\\'s Agent",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Seller\\'s Agent",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    }
                                ],
                                "lookup_id": null,
                                "input_type_id": 8,
                                "line_input_control_uuid": "0d3b48e2-d769-4893-8f64-135b70ee2c78"
                            },
                            {
                                "type": "CheckBox",
                                prompt: "Property Occupied",
                                "values": [
                                    {
                                        "value": "Occupied",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vacant",
                                        "is_selected": false
                                    }
                                ],
                                "lookup_id": null,
                                "input_type_id": 8,
                                "line_input_control_uuid": "7df8544b-142f-4864-b921-aa6eee531093"
                            },
                            {
                                "type": "CheckBox",
                                prompt: "Building Type",
                                "values": [
                                    {
                                        "value": "Single Family",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Condo",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Townhouse",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Duplex",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Multi-Family",
                                        "is_selected": false
                                    }
                                ],
                                "lookup_id": null,
                                "input_type_id": 8,
                                "line_input_control_uuid": "4f573c6b-4f8c-4829-b1c8-77f9adb7c5cf"
                            },
                            {
                                "type": "CheckBox",
                                prompt: "Weather",
                                "values": [
                                    {
                                        "value": "Clear",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Sunny",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Partly Cloudy",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rain",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Sleet",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Snow",
                                        "is_selected": false
                                    }
                                ],
                                "lookup_id": null,
                                "input_type_id": 8,
                                "line_input_control_uuid": "0afec1f1-61dc-4753-871b-bf99594ae87b"
                            },
                            {
                                "type": "Temperature",
                                prompt: "Temperature",
                                "values": [],
                                "lookup_id": null,
                                "input_type_id": 7,
                                "line_input_control_uuid": "d9478c58-c10b-4b36-8033-33269b149c48"
                            },
                            {
                                "type": "TextBox",
                                prompt: "Year Built",
                                "values": [],
                                "lookup_id": null,
                                "input_type_id": 9,
                                "line_input_control_uuid": "c6938607-747c-4b4d-bd0e-8693cee5dfff"
                            },
                            {
                                "type": "CheckBox",
                                prompt: "Water Source",
                                "values": [
                                    {
                                        "value": "City",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Well",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Cistern",
                                        "is_selected": false
                                    }
                                ],
                                "lookup_id": null,
                                "input_type_id": 8,
                                "line_input_control_uuid": "44097934-84d6-4e4a-a3bb-7636bb701619"
                            },
                            {
                                "type": "CheckBox",
                                prompt: "Sweage/Disposal",
                                "values": [
                                    {
                                        "value": "City",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Septic",
                                        "is_selected": false
                                    }
                                ],
                                "lookup_id": null,
                                "input_type_id": 8,
                                "line_input_control_uuid": "9ec1e872-214d-43f3-85f5-82509b4b4ead"
                            }
                        ]
                    }
                ],
                "order": 1,
                "is_deleted": 0,
                "created_by": 686,
                "updated_by": 686,
                "created_at": "2022-11-08T18:05:40.000000Z",
                "updated_at": "2022-11-08T18:05:40.000000Z"
            },
            {
                uuid: "6e6bb6a6-a42a-440f-8624-ff55488f0b04",
                prompt: "Lots and Grounds",
                "introductory_text": "<p>This is the Lots and Grounds disclaimer. This is the Lots and Grounds disclaimer. This is the Lots and Grounds disclaimer. This is the Lots and Grounds disclaimer. This is the Lots and Grounds disclaimer. This is the Lots and Grounds disclaimer. This is the Lots and Grounds disclaimer. This is the Lots and Grounds disclaimer. \r\n\r\nThis is the Lots and Grounds disclaimer. This is the Lots and Grounds disclaimer. This is the Lots and Grounds disclaimer. This is the Lots and Grounds disclaimer. This is the Lots and Grounds disclaimer. This is the Lots and Grounds disclaimer. This is the Lots and Grounds disclaimer. This is the Lots and Grounds disclaimer. This is the Lots and Grounds disclaimer. This is the Lots and Grounds disclaimer. This is the Lots and Grounds disclaimer. This is the Lots and Grounds disclaimer. This is the Lots and Grounds disclaimer. This is the Lots and Grounds disclaimer. This is the Lots and Grounds disclaimer. This is the Lots and Grounds disclaimer. </p>",
                lines: [
                    {
                        "order": 1,
                        prompt: "Driveway:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "304b02b6-2d6d-42e9-b67b-7802e83d81ee",
                                "recommendation": "<p>Damaged or deteriorated, recommend estimate for repair or replacement by a licensed contractor</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "850ea089-8d0c-47b4-9230-18d998d00a9c",
                                "recommendation": "<p>Heavy cracks in surface</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7a0372b6-a0ad-4097-9405-303d66456cef",
                                "recommendation": "<p>Heaves in concrete causing trip hazard</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5a8ea752-c74b-4d1d-8201-f74139c035f6",
                                "recommendation": "<p>Uneven settling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d5b7c1bd-acce-499f-9b0e-ea5ff5e2a90f",
                                "recommendation": "<p>Concrete pitted and chipped</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3a9e752e-4277-43eb-907b-77254bf64d7f",
                                "recommendation": "<p>Due to snow or ice cover the comments reflect only the visible portion of the driveway</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "41e65acb-4794-44c3-acec-802437780848",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "81f0544a-0dc1-49cf-abe4-d31eaa0d2434",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Concrete",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Asphalt",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Brick",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Paver",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Pebble top",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Stone",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Earth",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Gravel",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Poured stepping stone",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood",
                                        "is_selected": false
                                    }
                                ],
                                data:["data1"],
                                "line_input_control_uuid": "806ff433-7a9b-4aed-bf74-88db40f384c3"
                            }
                        ]
                    },
                    {
                        "order": 2,
                        prompt: "QA1 Test",
                        comments: [],
                        "line_uuid": "ea19ebed-31b3-4534-ad66-d2c816295c7e",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Desc 1001",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Desc 1002",
                                        "is_selected": false
                                    }
                                ],
                                data:["data1"],
                                "line_input_control_uuid": "3b913b66-2e27-4c5c-81ad-255bebce2e5a"
                            }
                        ]
                    },
                    {
                        "order": 3,
                        prompt: "QA1 Test with Descriptions",
                        comments: [],
                        "line_uuid": "d8d17130-3481-43e2-8aec-28e841394655",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "QA1 Test Desc 1",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "QA1 Test Desc 2",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "QA1 Test Desc 3",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "QA1 Test Desc 4",
                                        "is_selected": false
                                    }
                                ],
                                data:["data1"],
                                "line_input_control_uuid": "eff42ddc-e8f5-4d09-b908-38ca526613b1"
                            }
                        ]
                    },
                    {
                        "order": 4,
                        prompt: "QA1 Test with Descriptions w/ Answer",
                        comments: [],
                        "line_uuid": "d2a313ae-e48c-426e-a714-45b4fcbdcb30",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "QA1 Test with Descriptions w/ Answer Desc #1",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "QA1 Test with Descriptions w/ Answer Desc #2",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Different Description",
                                        "is_selected": true
                                    }
                                ],
                                "line_input_control_uuid": "835ff4f3-d52f-4139-a4a1-2edd74af1784"
                            }
                        ]
                    },
                    {
                        "order": 5,
                        prompt: "QA1 With Note",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8f31ca22-b457-4935-a62c-ddc64c139025",
                                "recommendation": "<p>QA1 With Note Note 1</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bdab4209-f1d7-4262-a1fd-bfd0b28908fa",
                                "recommendation": "<p>QA1 With Note Note 2</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "015f256c-05f3-42c0-85d6-26cc473304c4",
                                "recommendation": "<p>QA1 With Note Note 3</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "cf827442-90a4-42f6-b4a2-c80f0f174cfb",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "QA1 With Note Desc 1",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "QA1 With Note Desc 2",
                                        "is_selected": true
                                    },
                                    {
                                        "value": "QA1 With Note Desc 3",
                                        "is_selected": false
                                    }
                                ],
                                data:["data1"],
                                "line_input_control_uuid": "420f80ce-f34c-429d-9c2d-942668d5999e"
                            }
                        ]
                    },
                    {
                        "order": 6,
                        prompt: "QA2 P1",
                        comments: [],
                        "line_uuid": "aeeff172-c4da-460b-bb28-5e3f5765a6cc",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Desc 1011",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Desc 1012",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "64f78e30-d82e-4462-862e-21c0a520ed8e"
                            },
                            {
                                "type": "Additional input",
                                prompt: "QA2 P2",
                                "values": [
                                    {
                                        "value": "Desc 1021",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Desc 1022",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "ec2e549c-ef65-410c-897b-d4c4280fdc00"
                            }
                        ]
                    },
                    {
                        "order": 7,
                        prompt: "QA3 P1",
                        comments: [],
                        "line_uuid": "72ce7ec1-7bf3-4bd6-94d4-3f154ec636f7",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Desc 1031",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Desc 1032",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "0601481f-2bf6-4db7-a0e5-9bce5b966f04"
                            },
                            {
                                "type": "Additional input",
                                prompt: "QA3 P2",
                                "values": [
                                    {
                                        "value": "Desc 1041",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Desc 1042",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "7b078f7a-ee18-4f33-97ed-a5b44835d58f"
                            },
                            {
                                "type": "Additional input",
                                prompt: "QA3 P3",
                                "values": [
                                    {
                                        "value": "Desc 1051",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "d7b8f5d6-23f8-4c0a-adb8-2b3fd9ac1b52"
                            }
                        ]
                    },
                    {
                        "order": 8,
                        prompt: "QA1 Test",
                        comments: [],
                        "line_uuid": "b734055d-8e1f-4c80-a1de-71b246cd9e7e",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Desc 1001",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Desc 1002",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "QA1 A1",
                                        "is_selected": true
                                    }
                                ],
                                "line_input_control_uuid": "2b55b257-34fc-4a99-ac9f-c655b0f104ed"
                            }
                        ]
                    },
                    {
                        "order": 9,
                        prompt: "QA2 P1",
                        comments: [],
                        "line_uuid": "07a9f256-3cef-499d-83b0-328ce098190c",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Desc 1011",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Desc 1012",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "QA2 A1",
                                        "is_selected": true
                                    }
                                ],
                                "line_input_control_uuid": "0c4f9b7e-0815-4846-a95b-f8b4addc62bc"
                            },
                            {
                                "type": "Additional input",
                                prompt: "QA2 P2",
                                "values": [
                                    {
                                        "value": "Desc 1021",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Desc 1022",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "QA2 A2",
                                        "is_selected": true
                                    }
                                ],
                                "line_input_control_uuid": "1b8a8fc2-30c4-4c11-a334-aefbedcfce8d"
                            }
                        ]
                    },
                    {
                        "order": 10,
                        prompt: "QA3 P1",
                        comments: [],
                        "line_uuid": "33429aab-265a-41eb-a5dd-876955b7b53c",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Desc 1031",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Desc 1032",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "QA3 A1",
                                        "is_selected": true
                                    }
                                ],
                                "line_input_control_uuid": "4baa54c0-0d45-4ca4-8d9e-8ada1338a682"
                            },
                            {
                                "type": "Additional input",
                                prompt: "QA3 P2",
                                "values": [
                                    {
                                        "value": "Desc 1041",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Desc 1042",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "QA3 A2",
                                        "is_selected": true
                                    }
                                ],
                                "line_input_control_uuid": "327c0776-9e93-4d5f-a828-53c6e68f5f45"
                            },
                            {
                                "type": "Additional input",
                                prompt: "QA3 P3",
                                "values": [
                                    {
                                        "value": "Desc 1051",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "QA3 A3",
                                        "is_selected": true
                                    }
                                ],
                                "line_input_control_uuid": "6b390893-7f8f-4f3c-a0b0-142fbacd1798"
                            }
                        ]
                    },
                    {
                        "order": 11,
                        prompt: "Checkbox List Line with 3",
                        comments: [],
                        "line_uuid": "4de46e2c-c206-46fb-b8ad-d35e77e335b5",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Chk 1",
                                        "is_selected": true
                                    },
                                    {
                                        "value": "Chk 2",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Chk 3",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "332d7a1d-b412-46ef-8020-5a17b7bb1fae"
                            }
                        ]
                    },
                    {
                        "order": 12,
                        prompt: "Checkbox List Line with 3 with note",
                        comments: [],
                        "line_uuid": "52064919-84ec-4b26-8da0-7f8af68b6d1c",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Chk 1*",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Chk 2*",
                                        "is_selected": true
                                    },
                                    {
                                        "value": "Chk 3*",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "227c1191-65b4-42f6-a385-0569145dfc6f"
                            }
                        ]
                    },
                    {
                        "order": 13,
                        prompt: "Checkbox List Line with 3 with note with entered text",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1c3669c8-8e32-4505-ba43-7245cebef547",
                                "recommendation": "<p>Checkbox note 1</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bc50f524-68fc-4fd9-b8c1-36ca2be2bbaa",
                                "recommendation": "<p>Checkbox note 2</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7a9df8b0-64ec-43ed-be4a-e8740f6fc2e2",
                                "recommendation": "<p>Checkbox note 3</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "12031ece-c320-44af-993c-ef9d0f159532",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Chk 1==",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Chk 2==",
                                        "is_selected": true
                                    },
                                    {
                                        "value": "Chk 3==",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "3b543d55-2230-48ed-b438-0cad03ea7b78"
                            }
                        ]
                    },
                    {
                        "order": 14,
                        prompt: "Checkbox List Line with 3 with rating and note",
                        comments: [],
                        "line_uuid": "6ac4e030-8a26-4986-b88b-a3807b1082d9",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Chk 1---",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Chk 2---",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Chk 3---",
                                        "is_selected": true
                                    }
                                ],
                                "line_input_control_uuid": "d5f44ed7-b141-4ed3-98dd-31369a553cf2"
                            }
                        ]
                    },
                    {
                        "order": 15,
                        prompt: "Option list with 3",
                        comments: [],
                        "line_uuid": "111e65e4-2ec9-41de-a173-ab317df87921",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Option 1",
                                        "is_selected": true
                                    },
                                    {
                                        "value": "Option 2",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Option 3",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "6c4268b9-4e21-4d91-ac3f-45f2b453192f"
                            }
                        ]
                    },
                    {
                        "order": 16,
                        prompt: "Option list with 5 and note",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ed88728e-7349-44c7-bb8a-977bda8bc27e",
                                "recommendation": "<p>5 Option list note 1</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "df194299-cc46-426f-b009-595259645721",
                                "recommendation": "<p>5 Option list note 2</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "985fb4c4-29bd-4111-a38b-1a264aafec70",
                                "recommendation": "<p>5 Option list note 3</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "497643b6-028b-4b39-b56f-3ee350982c83",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Option 1",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Option 2",
                                        "is_selected": true
                                    },
                                    {
                                        "value": "Option 3",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Option 4",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Option 5",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "7b1eaf63-4c9a-4cb9-8727-ccf54681c426"
                            }
                        ]
                    },
                    {
                        "order": 17,
                        prompt: "Walks:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "663c09f0-b742-4dac-99bf-cd4df75d571c",
                                "recommendation": "<p>Due to snow or ice cover, the comments reflect only the visible portion of the walks</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "85207233-3a5e-40dc-97c5-a44021c7fd7c",
                                "recommendation": "<p>Concrete pitted and chipped</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fef00b5c-866f-421d-8d8c-0dbc75acbd92",
                                "recommendation": "<p>Damaged or deteriorated, recommend estimate for repair or replacement by a licensed contractor</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6a060e32-69e9-4a6f-90e0-1396ff7a613e",
                                "recommendation": "<p>Cracked</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b85dfada-571a-4ee2-b18a-a66af7f2872a",
                                "recommendation": "<p>Trip hazard</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "9cf79622-3b77-4c5b-9e02-a099d06613bb",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Concrete",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Asphalt",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Brick",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Paver",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Pebble top",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Stone",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Earth",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Gravel",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Poured stepping stone",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "1feeb1d8-5520-4e26-bc98-122e300be658"
                            }
                        ]
                    },
                    {
                        "order": 18,
                        prompt: "Steps/Stoops:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3a55305c-d92d-4715-a0b4-f16f3712cac6",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7b2c5907-9198-4476-b11d-91478a168ea1",
                                "recommendation": "<p>Concrete pitted and chipped</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1e20506c-efb2-4729-9d29-bcdb33835c87",
                                "recommendation": "<p>Damaged or deteriorated, recommend estimate for repair or replacement by a licensed contractor</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ea423489-1e6a-4578-80e6-b0c716884a6c",
                                "recommendation": "<p>Due to snow cover, the comments reflect only the visible portion of the steps</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "05163687-620e-4d6d-9edc-52e9895d07f0",
                                "recommendation": "<p>Missing handrails</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "be215cd6-018c-427b-8f09-012020292c36",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Concrete",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Asphalt",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Brick",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Paver",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Pebble top",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Stone",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Earth",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Gravel",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Poured stepping stone",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "884cd036-6bc8-420e-93ba-9dc8f8bd4ae9"
                            }
                        ]
                    },
                    {
                        "order": 19,
                        prompt: "Porch:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "00fa022e-5a3e-449e-a0b4-4e359bc493b6",
                                "recommendation": "<p>Due to snow or ice cover, the comments reflect only the visible portion of the porch</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b09a5864-fe95-4b5e-90d5-2af5a58714bd",
                                "recommendation": "<p>Heavily cracked</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "29d4629e-9c66-412e-a97d-15a8a9e6943d",
                                "recommendation": "<p>Missing handrails</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c28f9d01-d7a3-4c76-ad62-34eead201afb",
                                "recommendation": "<p>Moisture damage to surface</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e8221fb6-2841-4917-9000-1ab83908141a",
                                "recommendation": "<p>Screens torn or need to be replaced</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "dd835be2-db97-49b1-918a-aade60c297d8",
                                "recommendation": "<p>Settling away from foundation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4f3a1b61-3d2c-4641-bc13-f2bce9c04f4d",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "23d8295a-2a06-4c0b-93c1-c589baa9048e",
                                "recommendation": "<p>Baluster spacing too wide</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2b7dfd56-e9f4-4818-aae0-8197250f8577",
                                "recommendation": "<p>Concrete pitted and chipped</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "7870cb32-d8e9-4952-9a2e-d2604b40001f",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Concrete",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Asphalt",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Brick",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Paver",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Pebble top",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Stone",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Earth",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Gravel",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Poured stepping stone",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "41d64058-20f2-4055-a6e7-95722c0cf083"
                            }
                        ]
                    },
                    {
                        "order": 20,
                        prompt: "Patio:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "43256ab6-0141-48e0-9d94-59e97ac2b908",
                                "recommendation": "<p>Due to snow or ice cover, the comments reflect only the visible portion of the patio</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2d1253aa-b817-4438-9b8c-e35c1a1f4bca",
                                "recommendation": "<p>Evidence of poor drainage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2e05c13c-482f-44fe-b710-789505bf2b18",
                                "recommendation": "<p>Concrete pitted and chipped</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "85d204f4-11c3-4581-90d8-7c6987e70373",
                                "recommendation": "<p>Heavily cracked and unevenly settled causing trip hazard</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "50015fd9-f30b-4b15-be55-19643d76bed9",
                                "recommendation": "<p>The patio has settled towards the foundation causing a negative slope into the foundation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ecd8627e-97e9-413f-9c5a-d5a368f56cf9",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "59b2050e-d242-4ea9-b22c-e548b3a19758",
                                "recommendation": "<p>The patio slab is damaged or displacement has occurred</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "eb7b5422-6dea-4696-aff6-2a22ac3a4180",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Concrete",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Asphalt",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Brick",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Paver",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Pebble top",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Stone",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Earth",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Gravel",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Poured stepping stone",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "3a32fabd-a709-4ae0-8dbe-cf46bf56efda"
                            }
                        ]
                    },
                    {
                        "order": 21,
                        prompt: "Deck:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "553b7617-6c5f-4a40-894d-fb117ee51588",
                                "recommendation": "<p>The deck has been built below acceptable building standards and is not safe in it's current state.  Recommend further evaluation and estimate to bring deck up to an acceptable building standard.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "01c43b10-773a-4907-bd17-a7bc60d97010",
                                "recommendation": "<p>Baluster openings too wide</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ba15ed6d-832f-4fdd-9a78-2b6ce271d10d",
                                "recommendation": "<p>Deck built on grade, unable to inspect the underside</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4ae20ba8-97c6-4bd9-9956-2c2953c2a7f4",
                                "recommendation": "<p>Moisture damage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1e7e28f7-1afe-4838-99fb-9cb96976da7d",
                                "recommendation": "<p>Clean and reseal</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cbd88c29-d7f5-4417-8545-a3dd7fb15a65",
                                "recommendation": "<p>Uneven rise or run on steps</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f233f2bc-b4a9-42ca-a903-a75669ab7d20",
                                "recommendation": "<p>Handrails missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d7eb49f9-b430-435b-b96b-8881e442df68",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "658fa53e-31e0-418d-8269-8df3c55ec5d7",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Stained wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Treated wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Untreated wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Concrete",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "438a9671-7145-4177-8429-8a4d56141051"
                            }
                        ]
                    },
                    {
                        "order": 22,
                        prompt: "Balcony:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1f82ee05-8071-4b27-8b38-701ffd390e35",
                                "recommendation": "<p>Baluster spacing too wide</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8a79ac17-16e7-4163-af15-087b24fa8b8e",
                                "recommendation": "<p>Loose flooring</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "89379ce2-bae5-48d0-bb31-528b2de4554e",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "39455960-c30d-432b-bb37-03a138aff6fa",
                                "recommendation": "<p>Due to snow or ice cover, the comments reflect only the visible portion of the balcony</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "087bf366-b855-465e-b03c-44730a0fcdc8",
                                "recommendation": "<p>Missing or loose handrails</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0d83e5df-3f8c-4854-9e72-f77c05369edb",
                                "recommendation": "<p>Unsafe</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9c8e5608-68f7-4b17-9b33-7cd6fcbc407a",
                                "recommendation": "<p>Unstable</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "a9184c5f-6883-4f0b-88c2-48b3aee3ba4b",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Stained wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Treated wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Untreated wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Concrete",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "83365999-afb9-4a18-ab71-c16b1098cda7"
                            }
                        ]
                    },
                    {
                        "order": 23,
                        prompt: "Grading:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3c91770f-36b5-4069-83ee-28f8ed2fd5e1",
                                "recommendation": "<p>Grading has negative slope and water is pooling against the foundation.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "27665838-3c56-4178-9a52-c960c2e7da1d",
                                "recommendation": "<p>Improper soil slope towards foundation, recommend the addition of fill dirt to improve grade</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "08c94ee1-3d07-4627-84eb-c52fa1316578",
                                "recommendation": "<p>An evaluation by a civil engineer is recommended to assess drainage problems</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "ddac16c0-19f2-4ff5-8571-920795877d62",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Flat",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Minor slope",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Moderate slope",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Stair stepped",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steep slope",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Negative slope",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "ee92af4c-32c2-4b6e-8ba9-be8bc1c507a0"
                            }
                        ]
                    },
                    {
                        "order": 24,
                        prompt: "Swale:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c16485a1-be10-4c92-8300-94baccdf24e8",
                                "recommendation": "<p>Swale does not have adequate depth or slope for proper drainage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7a41f287-f596-487c-b6ea-b9f12a1cd43c",
                                "recommendation": "<p>An evaluation by a civil engineer is recommended to assess drainage problems</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ca077da5-f830-40ea-9f52-9ae4af3eef32",
                                "recommendation": "<p>Improper soil slope towards foundation, recommend the addition of fill dirt to improve grade</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3a4f70bb-7430-4297-b6fa-8358deea92ed",
                                "recommendation": "<p>No swale present, water pooling due to flat grade and lack of swale</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "67fbb383-7b3a-4542-ae5e-a462a22c8480",
                                "recommendation": "<p>Recommend improvements be made to the grade, swale slope and depth to improve water control</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "da2723df-2803-4f2e-8d03-ac744e285420",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Adequate slope and depth for drainage",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Flat or negative slope",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Pooling due to improper drainage",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "9df1af61-e198-4c0b-9d54-94b7fbbf3d7d"
                            }
                        ]
                    },
                    {
                        "order": 25,
                        prompt: "Vegetation:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "513641c5-be16-4819-8cdd-6081ab10b0aa",
                                "recommendation": "<p>Tree limbs over hang the roof and should be cut back</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e6f7a44c-1ed1-40d2-be9b-dd8efbe19568",
                                "recommendation": "<p>Tree planted too near the foundation and roots may cause damage to the foundation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "206e2a4c-0638-4549-9463-661176ec7867",
                                "recommendation": "<p>An evaluation by a qualified arborist is recommended to estimate services required to repair this problem</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a5cc0c5e-c3a3-4286-8520-9f20102b7c7e",
                                "recommendation": "<p>Trees planted too close to structure, removal may be required</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "354555a7-3934-4c43-8ac7-7778e8807209",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Trees",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Ivy",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Shrubs",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vines",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Shrubs/Weeds",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "60f3fd89-3e7e-4621-9f73-7e97e859bf31"
                            }
                        ]
                    },
                    {
                        "order": 26,
                        prompt: "Window Wells:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "49f66062-2235-46b7-b859-d414291f4200",
                                "recommendation": "<p>Too small to use as an emergency escape</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "787184a2-d540-492c-aaa2-926a2826edf3",
                                "recommendation": "<p>Could not inspect due to covering</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6d7778e7-8f50-4069-8cf9-61285d0f58e5",
                                "recommendation": "<p>Unstable</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4db63537-23ec-4f19-b15b-44c393ccead8",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f925c755-076b-413c-8b41-643d0e12a155",
                                "recommendation": "<p>Could not inspect due to build up of debris</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "bfa35838-cdf6-4604-b118-1fac1dfdc269",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Covered",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Drain not present",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Drain present",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not visible",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "e93375da-571e-4227-90b9-dd25793397b0"
                            }
                        ]
                    },
                    {
                        "order": 27,
                        prompt: "Retaining Walls:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "95a5d83c-b9fc-4a34-a176-b7e025233527",
                                "recommendation": "<p>Wall is unsound due to poor drainage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "314ed7d9-be24-489d-bcf6-3f84038f909f",
                                "recommendation": "<p>Wall is unstable</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d100a2a6-49fc-4635-880e-000e9c282552",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "ba951192-6e5a-4653-9bb8-c329c57d2d49",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Stone",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Concrete",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Brick",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Block",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Railroad ties",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "2f1f0831-5c25-439f-b0e6-9aad300d60a0"
                            }
                        ]
                    },
                    {
                        "order": 28,
                        prompt: "Basement Stairwell:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "41ebbc87-7171-48db-83ff-200ceab95aff",
                                "recommendation": "<p>Damaged or broken</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cec56aea-9eb8-4eae-b27d-c8bfcb1561cd",
                                "recommendation": "<p>Stairs unsound or unsafe</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "919b688e-3438-45a3-8fca-dfac4317d2c4",
                                "recommendation": "<p>Railings missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d00e6792-22d5-48c2-a892-b59d68911cca",
                                "recommendation": "<p>Uneven riser or run on steps</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e79143a3-8057-4b14-8e27-258049aa3c09",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "e68ae000-1cf1-424e-aad8-1038d846d87a",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Concrete",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Masonry Block",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood Timbers",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "31d681e0-60d5-447a-98a5-90a240f6b6fa"
                            }
                        ]
                    },
                    {
                        "order": 29,
                        prompt: "Basement Stairwell Drain:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "146e6df9-e0ad-4b03-871f-feb94cf890f4",
                                "recommendation": "<p>Blocked by debris</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "dcc9291a-d8a8-45b3-a4eb-0077e8a8fb7b",
                                "recommendation": "<p>Evidence of poor drainage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "003af737-5fad-466f-ad6b-b31d4da56109",
                                "recommendation": "<p>Water pooling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5291da95-2b31-4666-b75f-76aa86cfb689",
                                "recommendation": "<p>Undersized for surface area</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5f3f1a19-e6cc-4b83-925b-2ba74f675e1a",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "eccb6815-0aed-4b21-8ec5-b3b6863c5bb1",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "French drain",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "No drain",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Surface drain",
                                        "is_selected": false
                                    }
                                ],
                                data:["data1"],
                                "line_input_control_uuid": "c8d0b2d3-9b91-4ad1-8e34-c20cc4b4ed23"
                            }
                        ]
                    },
                    {
                        "order": 30,
                        prompt: "Exterior Surface Drain:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9f057bb2-a574-4eac-87f5-ab74265549ff",
                                "recommendation": "<p>Blocked by debris</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cde90a33-d5bf-4360-8906-05e6bb76d2a5",
                                "recommendation": "<p>Evidence of poor drainage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "889a9a52-10c6-4597-806c-59846b227d16",
                                "recommendation": "<p>Water pooling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5ff91f4a-ad17-49a5-bc15-1da38bbbfe64",
                                "recommendation": "<p>Undersized for surface area</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "883f82fe-ceaa-419b-b259-dccf591f80ac",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Surface drain",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Covered drain",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Open drain pipe",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Curtain drain",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Culvert drain",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "adf56bf6-5405-49cb-ac36-cfa294a0f10e"
                            }
                        ]
                    },
                    {
                        "order": 31,
                        prompt: "Fences:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b3e2cd00-d353-479a-b3ae-d40edc41acf1",
                                "recommendation": "<p>Damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a934d41f-52b8-468b-9eb8-843a4b9cd0da",
                                "recommendation": "<p>Needs replacement</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "51069a4d-1707-40a8-b4f4-fef6074d76d3",
                                "recommendation": "<p>Heavy rust</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8553b39d-cac6-4380-b6c2-987b0c7f1a37",
                                "recommendation": "<p>Wood rot or pest damage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "03800788-f175-4eab-a7d5-60f03277fc5d",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "3e20ed99-e605-4845-afce-2a59b2dd986b",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Chain link",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Split rail",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Picket",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Block",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Brick",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wrought iron",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Stone",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "ca52c2ba-80d8-4708-9722-3cc753372dde"
                            }
                        ]
                    },
                    {
                        "order": 32,
                        prompt: "Lawn Sprinklers:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "91139332-d1cb-4bc0-9c7d-dead3c8fccc0",
                                "recommendation": "<p>Improper spray pattern</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0882fa7d-d5ba-41ea-80ad-edc0475eb59b",
                                "recommendation": "<p>Leakage noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e0c4549c-695a-48fc-b42b-ae01527fa8d6",
                                "recommendation": "<p>Not tested due to season</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e569d89c-87d3-4c68-b3a2-0c776bded441",
                                "recommendation": "<p>Sprinkler head damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "9ca9f935-88c1-4259-a921-cc05e55f96dc",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Front and back yard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Front yard only",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Back yard only",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "63cacc88-7bc3-42b9-8378-e68c9889ddd5"
                            }
                        ]
                    }
                ],
                "order": 1,
                "is_deleted": 0,
                "created_by": 686,
                "updated_by": 686,
                "created_at": "2022-11-08T18:05:40.000000Z",
                "updated_at": "2022-11-08T18:05:40.000000Z"
            },
            {
                uuid: "183881e2-4e40-4dab-8d9c-5989f3d14f31",
                prompt: "Exterior",
                "introductory_text": null,
                lines: [
                    {
                        "order": 1,
                        prompt: "Type:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c45b37c8-e77f-49a3-a56a-ad1aadd189ba",
                                "recommendation": "<p>Gaps not properly sealed at</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2579e534-e635-4aa3-a590-43d9e97cc955",
                                "recommendation": "<p>EIFS exterior finishes are prone to water infiltration and may cause hidden damage to the structure. There is usually no visible evidence of such damage. We recommend a detailed inspection by a qualified specialist.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "91ffaa97-3d31-42d9-978b-0027ea6d3515",
                                "recommendation": "<p>Visible settling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0e5f318a-fc4d-4626-8e4d-1784ff1125b3",
                                "recommendation": "<p>Improper siding</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1885acc6-4ae6-4115-a239-e6bfbbb0e6e5",
                                "recommendation": "<p>Moisture stains</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "dd7fe737-ac7a-4aae-9403-63a18e30e4f5",
                                "recommendation": "<p>Paint peeling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e3cccfbf-de83-4b9f-8e1d-54aeb5680cad",
                                "recommendation": "<p>Rot noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "899bbda5-4087-492d-9a90-b973ed06049a",
                                "recommendation": "<p>Stress cracks</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "7a769c74-3390-4599-a72d-370816c86c24",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Brick veneer",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Block",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Aluminum siding",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl siding",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "EIFS stucco",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fibrous",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Metal",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Stucco",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "52e077cd-3fa1-4642-be96-7b1ba0372a9d"
                            }
                        ]
                    },
                    {
                        "order": 2,
                        prompt: "Trim:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "776e5c04-0936-4ed0-8ad7-e64ea6f2b3ab",
                                "recommendation": "<p>Damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7346e880-cac2-445f-802a-2229d1b0a88d",
                                "recommendation": "<p>Missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d1806269-eb97-4d09-b36e-5757806e2999",
                                "recommendation": "<p>Wood rot</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "07f919e7-558b-43b4-8ad4-59f52289eade",
                                "recommendation": "<p>Loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "a079c7c8-bf3f-4572-9f83-a2a46175f503",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Plywood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Particle board",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Composite material",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Aluminum",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fiberglass",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Metal",
                                        "is_selected": false
                                    }
                                ],
                                data:["data1"],
                                "line_input_control_uuid": "0561f0f1-202e-40f5-a217-43a07067c1ed"
                            }
                        ]
                    },
                    {
                        "order": 3,
                        prompt: "Fascia:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2c747b59-4976-4449-aa5c-5b4702675706",
                                "recommendation": "<p>Paint peeling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0d4cce04-8055-4237-987b-ab2af3942b88",
                                "recommendation": "<p>Wood rot</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f8f10ba4-6f91-4329-beef-1fce105be412",
                                "recommendation": "<p>Loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1787c13f-ef43-4eeb-9b5f-1d30d46741a4",
                                "recommendation": "<p>Penetrations</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6fb8f25a-4635-4a9c-a07b-6abe55d55af4",
                                "recommendation": "<p>Needs to be replaced</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2ed4e1e8-cf9d-4d68-9cd9-d3f76c1e3a77",
                                "recommendation": "<p>Covered by siding, unable to inspect the underlying wood</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d21fd4e3-6b28-4cd9-86dc-8f8ed9e3036f",
                                "recommendation": "<p>Damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7c2647ce-7de8-49a7-8cfe-db58c9561192",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "1d79046e-5c10-48c8-9fa8-3cb94e494879",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Plywood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Particle board",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Composite material",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Aluminum",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fiberglass",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Metal",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "d330b7e7-1782-4573-a2a6-0a6a29cefd80"
                            }
                        ]
                    },
                    {
                        "order": 4,
                        prompt: "Soffits:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "83cef293-e275-483d-85d4-e9b717e60df3",
                                "recommendation": "<p>Damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "10fd395e-f749-4ed0-8156-ef9ed69b3b38",
                                "recommendation": "<p>Missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ac8cff9d-d657-43c9-b37e-15b8d1f5b2fb",
                                "recommendation": "<p>Wood rot</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ccf35de1-79bf-4a24-9e07-7a5b430294bd",
                                "recommendation": "<p>Loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "860377f0-0760-4c29-b7fa-537349e2e396",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Plywood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Particle board",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Composite material",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Aluminum",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fiberglass",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Metal",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "e64fd269-20d0-4ec6-9f38-a71fbeaf7510"
                            }
                        ]
                    },
                    {
                        "order": 5,
                        prompt: "Door Bell:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ff5e9e36-e22c-4cd8-ba50-e5f74b198771",
                                "recommendation": "<p>Cover broken</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3e0d1f6d-f3c8-4f20-b878-43d833da36f6",
                                "recommendation": "<p>Exposed wires</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c00a6298-299e-42e7-8cb0-4a43ec9c9258",
                                "recommendation": "<p>Inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "8148d406-14a3-40b1-88fa-00670a5b4d65",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Hard wired",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Battery operated",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "56c7edcc-44bc-4258-8d34-e3fae5adb786"
                            }
                        ]
                    },
                    {
                        "order": 6,
                        prompt: "Entry Doors:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9ecd00fa-601e-4474-a790-b8b59dbf2979",
                                "recommendation": "<p>Loose or missing hardware at the rear door.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "745368b9-7523-4046-8bac-d36bf7003bd1",
                                "recommendation": "<p>Add closing mechanism to entry door</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "94094e81-0315-4cbd-9aca-c0a00267503a",
                                "recommendation": "<p>Hinges are loose or damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4b553296-46f4-41aa-bbf3-a1110d53ce44",
                                "recommendation": "<p>Improper installation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2d60ed1a-ed31-4d20-a24d-bd3171d51b30",
                                "recommendation": "<p>Paint peeling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e925074f-026d-404c-9102-3f0941d77ad4",
                                "recommendation": "<p>Wood rot</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5ec50ce0-945e-40ab-903e-da8d1247d411",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6982fabc-6343-42f7-b67f-dccc6a1e2f47",
                                "recommendation": "<p>Loose or missing threshold</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e4eb9cd2-04aa-4a3c-babc-bd49463000a5",
                                "recommendation": "<p>Missing seal</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8390c514-73b1-43bd-918d-634cddcb101d",
                                "recommendation": "<p>Missing threshold seal</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "07976fb9-029b-4ece-a48a-5586e0218a6e",
                                "recommendation": "<p>Non-fire rated entry door</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f99bd0ff-a358-4a32-ba46-dc4c612096ac",
                                "recommendation": "<p>Surface laminate cracking and peeling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "afd75262-7c9b-4c7d-bcae-98df5dc93760",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Metal",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "53c4a05b-0914-40a8-b4a4-a3f13403ad09"
                            }
                        ]
                    },
                    {
                        "order": 7,
                        prompt: "Patio Door:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "54303c66-1920-4e51-ae6f-4f6d805b64c0",
                                "recommendation": "<p>Sliding door off track</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5883e3c9-3205-4cdb-85cd-fcb21f8ca53b",
                                "recommendation": "<p>Screen door missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0d956e54-80a5-4df8-bf96-e8ab4a446ca7",
                                "recommendation": "<p>Rollers need to be replaced</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "401ac80e-1b84-42d5-89ee-8a233f49095f",
                                "recommendation": "<p>Needs repair</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "67cd3c60-8fd0-42cb-842e-19876a86ac3f",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "baabe0b3-5f72-43f6-86b2-3c3278007c76",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Metal sliding",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood sliding",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl sliding",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "French door",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Metal entry door",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "ba7f649d-7df8-4574-b9b6-4a9234dcf86f"
                            }
                        ]
                    },
                    {
                        "order": 8,
                        prompt: "Windows:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b05c61b1-4c67-48fd-97f9-46918a27e66b",
                                "recommendation": "<p>Window seal broken or damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a2ff0ecf-fb0f-4a10-b23d-60f97a9a0990",
                                "recommendation": "<p>Window lintels rusting</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6ddfb4d8-d64b-4f1c-a70a-9f5645aa92b3",
                                "recommendation": "<p>Cracked, broken or missing glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "42064083-c9d4-4d31-933c-61f2dc3b9f1d",
                                "recommendation": "<p>Will not open</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e1fa6445-22d8-40da-8521-80f9dffd1c71",
                                "recommendation": "<p>Hard operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "110aaecd-2fb6-4e31-a380-cac97cb5b15d",
                                "recommendation": "<p>Sash cords broken or missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f390ea56-109e-4df4-b55e-5b6f84667932",
                                "recommendation": "<p>A qualified glazier is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "1b04a7fe-00f1-4d9c-addb-e4a0a2489af5",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Aluminum slider",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Aluminum double hung",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Non-opening",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood double hung",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Aluminum casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl double hung",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl slider",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steel casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steel frame",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood slider",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "717d981d-c0dc-4e3e-8862-dad16d778b4a"
                            }
                        ]
                    },
                    {
                        "order": 9,
                        prompt: "Storm Windows:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e0c5912a-d2be-43f4-a40a-0e992371878d",
                                "recommendation": "<p>Storm window is cracked</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e3a9a75e-45e1-4883-bad2-e48863141ce2",
                                "recommendation": "<p>Missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "af036aef-f150-4d2e-9e8d-c402a268b5ab",
                                "recommendation": "<p>Broken</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "add10827-ef5b-4ed8-839f-5ed842ea4007",
                                "recommendation": "<p>A qualified glazier is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "8632f1d7-1be5-4381-91b9-a18fd8402698",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Aluminum framed tempered glass",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steel framed tempered glass",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood framed tempered glass",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "90d2b19e-bfd2-4851-a8db-359a049c72d7"
                            }
                        ]
                    },
                    {
                        "order": 10,
                        prompt: "Window Screens:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e274df4a-ed09-4cdf-a92a-d04e91678782",
                                "recommendation": "<p>Screen is torn and will need repair</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c26612ca-67ef-4c24-b466-04a851347436",
                                "recommendation": "<p>Missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "51f46592-a47f-4a91-823a-46e64a8dc188",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "8b5b1a58-af3b-4f9c-804e-20a842e7226d",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Metal",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl mesh",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "71f5b999-9e2e-4010-badd-921c5faec4f7"
                            }
                        ]
                    },
                    {
                        "order": 11,
                        prompt: "Basement Windows:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6e90e3e9-0798-444c-b991-66ef73af9044",
                                "recommendation": "<p>Window seal broken or damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7cc4779e-80eb-48a1-879d-3c26b99a25c6",
                                "recommendation": "<p>Window lintels rusting</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "da631bc7-4f60-4895-81ee-107d17a143f8",
                                "recommendation": "<p>Cracked, broken or missing glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c4bce734-0a0d-4612-a2f6-2c81324ac556",
                                "recommendation": "<p>Will not open</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "405e83ef-af97-4c7c-975f-5232fe1e56aa",
                                "recommendation": "<p>Hard operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5e3857e8-d7d4-4767-bc0b-16f90b6530f8",
                                "recommendation": "<p>Sash cords broken or missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "63ab97f9-901c-4a2b-a388-2728877e2be0",
                                "recommendation": "<p>A qualified glazier is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "8510bd33-5ab9-4d2a-b7c2-9f34e5b70bbc",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Aluminum casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Aluminum double hung",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood double hung",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl double hung",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Aluminum slider",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood slider",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl slider",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steel casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Non-opening",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Glass block",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "293ef2a5-b6d1-4975-b821-60b4db68ba28"
                            }
                        ]
                    },
                    {
                        "order": 12,
                        prompt: "Exterior Lighting:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "519caca9-7e8b-478d-bd57-33ea22827039",
                                "recommendation": "<p>Bulbs burned out at time of inspection</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f5e338f1-2ef6-4e8b-a743-686b89a7be70",
                                "recommendation": "<p>Exposed wiring</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f72c1e0f-ce28-4445-baff-68a485b6d0a5",
                                "recommendation": "<p>Improperly mounted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "df00ee23-857e-4a5a-bfe5-4ae3684c3ec0",
                                "recommendation": "<p>Light bulb missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a20ac047-06f2-47b6-89d9-6b02846d9973",
                                "recommendation": "<p>Loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "d8ae45cc-2948-4477-a8da-1e2a7de48751",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Pole light",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Surface mount",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Surface mounted lamps front and rear",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "33bff56c-924d-4901-8357-7c3ece613817"
                            }
                        ]
                    },
                    {
                        "order": 13,
                        prompt: "Exterior Electric Outlets:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4bebb855-2437-47df-a4cc-d9d42efd30af",
                                "recommendation": "<p>Loose or missing moisture cover</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bbc0861d-d2f7-43a6-87c1-b7cb24a174ab",
                                "recommendation": "<p>Exposed wiring present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e572d3f2-9cdd-4154-86b4-a64f36322ed2",
                                "recommendation": "<p>Outlets have visible corrosion</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ba2e7b35-e334-45f3-8bf8-fe6ab1113cf3",
                                "recommendation": "<p>Inoperative at time of inspection</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4eef660c-2a52-42cd-9a71-3d7d06077e1b",
                                "recommendation": "<p>Non-GFCI outlet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "621b5e76-4777-492a-9517-89dc1cb8e89d",
                                "recommendation": "<p>Improper wiring for exterior application</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "8845b6f9-9ddd-486a-801b-88ccfd84aaaf",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "110 VAC GFCI",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "71401364-3664-48ff-8a06-bd3f9228f887"
                            }
                        ]
                    },
                    {
                        "order": 14,
                        prompt: "Hose Bibs:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f729eb69-0dee-4ec5-bb58-89533625d6c4",
                                "recommendation": "<p>Signs of past or present leakage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2f60d1b2-23d9-4ac5-880d-613698be9498",
                                "recommendation": "<p>Missing handle</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5e370a60-5344-4c15-8aa3-4d44c22a1008",
                                "recommendation": "<p>Frozen or inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "eec567c4-4849-4a2b-97eb-1064c4ce0d05",
                                "recommendation": "<p>Note: Valves are not operated as part of this inspection, any reference to any valve inspected is visual only unless otherwise noted.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6ab61be5-d391-4334-ad04-b9c58347bb52",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "ae6d0e4d-072a-4192-81e9-4f2af0796000",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Gate",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rotary",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Ball",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Multi-port",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "c9519ed6-67d4-4ce9-903f-76144137093b"
                            }
                        ]
                    },
                    {
                        "order": 15,
                        prompt: "Gas Meter:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "79fcb01a-2b26-40ee-8ea8-3c5ec20c9229",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "481b1358-151c-4e76-ba35-a948e857d88f",
                                "recommendation": "<p>Mounting brackets loose or broken</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8a63eb1b-3146-40d3-8836-34a2eec6d06f",
                                "recommendation": "<p>Not properly mounted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ac8a0910-fb43-4297-973b-dae216a4135d",
                                "recommendation": "<p>Severe rust present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "48c02c3b-9c84-4945-81cc-1f6064f88fdb",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Front of house",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Basement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Recessed in wall",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not accessible",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Garage",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Exterior surface mount at side of home",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "e7d4bc7e-d967-4c27-8c58-8e34f720fa2c"
                            }
                        ]
                    },
                    {
                        "order": 16,
                        prompt: "Main Gas Valve:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a5abe6b1-0619-43f9-a9c2-477ca3c655a2",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ac455935-f0f4-4022-871f-14875e5a5dc1",
                                "recommendation": "<p>Severe rust</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ed27fdb8-c9eb-4a1a-8033-73ffb20f3b44",
                                "recommendation": "<p>Leakage noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "65730202-bf0f-4867-8581-85d167a0d93c",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Located at gas meter",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Located at main line",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not visible or not present",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "1e306743-ce80-4d40-a87b-cc3e340ff852"
                            }
                        ]
                    }
                ],
                "order": 1,
                "is_deleted": 0,
                "created_by": 686,
                "updated_by": 686,
                "created_at": "2022-11-08T18:05:40.000000Z",
                "updated_at": "2022-11-08T18:05:40.000000Z"
            },
            {
                uuid: "04562320-4bb2-4965-b9c4-0008fce30f72",
                prompt: "Outbuilding",
                "introductory_text": null,
                lines: [
                    {
                        "order": 1,
                        prompt: "Exterior Surface:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e2038127-540f-4e06-8eaf-966907de9a88",
                                "recommendation": "<p>Loose or missing siding</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "707137da-c2d8-4a3d-8d7e-057365a87c26",
                                "recommendation": "<p>Cracks</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "98c6a007-c2bc-48e9-8487-d34eb13705d4",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "7457cc4b-4648-4555-95fd-95780fffac71",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Brick veneer",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Block",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl siding",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fibrous",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Aluminum siding",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Metal",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "EIFS Stucco",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Stucco",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "74ce2ff3-ba60-4d91-90fa-addb0f333b85"
                            }
                        ]
                    },
                    {
                        "order": 2,
                        prompt: "Roof:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fbdc47d0-8940-4ce2-8137-20f5d80d83e1",
                                "recommendation": "<p>Curled shingles</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9e5ec91d-06e8-4f6a-b949-33e56d5d0d0d",
                                "recommendation": "<p>Loose or damaged shingles</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8fedb699-8097-461c-940e-3ef7e654bfa4",
                                "recommendation": "<p>A qualified roofing contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "9165ba85-4b9a-4183-b3ac-0edd4c56f5bb",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Asphalt shingle",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Clay tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Built-up",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Cement tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Asbestos",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fiberglas shingle",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rolled roof material",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Slate",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tar and gravel",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tin",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood shake",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "7ffe138e-73d3-47fd-ab3c-4fdc84dc9f44"
                            }
                        ]
                    },
                    {
                        "order": 3,
                        prompt: "Roof Structure:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b6af3df2-f639-4aab-860f-96385aedcda7",
                                "recommendation": "<p>Damaged by water penetration</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0c7f680d-52c4-40ea-be74-7eeb78c6ffe1",
                                "recommendation": "<p>Undersized for load</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d932b018-1fc8-4e75-9163-3ffd7240dc20",
                                "recommendation": "<p>A structural engineer is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "7f1f8fbf-19a8-4f7d-a9ac-8b40efabbfa3",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Rafter",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood truss",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "0a902f67-baf7-4a9e-b812-eacb3b340613"
                            }
                        ]
                    },
                    {
                        "order": 4,
                        prompt: "Ceiling:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bf09272d-00a3-459d-8cd8-af4bc9a6f3a7",
                                "recommendation": "<p>Cracks present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ce84d8aa-2166-48ba-a998-bfc50035cab6",
                                "recommendation": "<p>Loose or peeling paint</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1557310d-661d-4558-815f-05c1900c0829",
                                "recommendation": "<p>Loose paneling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "90e9bce4-bfbf-4391-b7a8-22b2185c8e17",
                                "recommendation": "<p>Loose plaster</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "02d5d910-4841-4ef9-8617-b9ea02cb6a5b",
                                "recommendation": "<p>Water stains present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6c51ab61-f8f4-4284-af0b-97d2b57ff28b",
                                "recommendation": "<p>Stress cracking, see structural notes</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "17993a29-4e9f-4e1c-9eab-290339527d3c",
                                "recommendation": "<p>Evidence of possible truss uplift-repair as required, monitor for further movement</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "44887b36-970f-4cb1-94ce-89b81ce4292c",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "229d301b-5b4a-45a2-b81c-a580712c0410",
                                "recommendation": "<p>Mold is present and is a potential health concern. Removal and/or correction is required.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "df11a6f3-0dee-49d9-95f1-69dbe151dacc",
                                "recommendation": "<p>Evidence of past or present water staining</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "74104235-816b-4ec9-b274-d2271c3a23d1",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Paint",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Exposed framing",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Mirrors",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Texture paint",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Suspended ceiling",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "f4b6abf8-fd37-4e60-8c99-8a33bf850955"
                            }
                        ]
                    },
                    {
                        "order": 5,
                        prompt: "Walls:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "80b1cee4-fcd4-4aab-ad40-126b06be0437",
                                "recommendation": "<p>Cracks present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8a2a04f7-9685-471f-b187-6b5ea604f680",
                                "recommendation": "<p>Evidence of past or present water leakage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "17238400-3c80-4765-b5ff-ee840cc0909f",
                                "recommendation": "<p>Evidence of possible truss uplift</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8a26658f-bd2e-4660-9b02-19ad6edb520d",
                                "recommendation": "<p>Loose or peeling paint</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "98c67acd-1eb2-4fd0-85fc-d2fe5461ce08",
                                "recommendation": "<p>Loose paneling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "49fa000b-0af3-4dcf-ac9a-fbccee2d8286",
                                "recommendation": "<p>Water stains present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "31d30eb0-64dd-494a-8b14-2d6bcd54641d",
                                "recommendation": "<p>Evidence of past or present water staining</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "033e46ca-2c01-438a-9cc9-5460cce237d7",
                                "recommendation": "<p>Loose or missing tile</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "868e8050-9e45-40dd-9111-879b4fd0d9a7",
                                "recommendation": "<p>Mold present-potential health concern-needs removal/correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bcb4cec4-0d19-4257-9ef7-34ba190e3612",
                                "recommendation": "<p>Paneling loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "dd3d2d9a-9086-48d8-8302-dc753a024f76",
                                "recommendation": "<p>Some ceiling pads damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "093deaa6-3add-4a8a-866c-3d02f77a468b",
                                "recommendation": "<p>Some evidence of previous truss uplift</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fa921603-9b0f-4ae1-a080-159f41094e16",
                                "recommendation": "<p>Inspection does not cover any damage concealed by rugs, carpeting, wall paneling, furniture</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d6cc699b-c0b0-4811-b40a-a1a5fe66f5c7",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "6a9dfa7a-bdf9-454a-9ffc-a884833bff6b",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Paint and paneling",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Exposed framing",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Paint and tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wallpaper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Paint",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Concrete",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wallpaper and tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fire rated",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "4db4ef3d-c516-4b47-8ad1-81b3106f7c31"
                            }
                        ]
                    },
                    {
                        "order": 6,
                        prompt: "Floor:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "94eae49e-356c-42cf-bf22-4f5db62b1efb",
                                "recommendation": "<p>Broken floorboard</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "335cc087-0555-4c89-ac9b-29cb8debe8d0",
                                "recommendation": "<p>Damaged carpet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ec5e0628-ad43-4bdc-97d5-393a81446b64",
                                "recommendation": "<p>Loose carpet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b92225a2-c1db-4966-ab4f-841110c59d88",
                                "recommendation": "<p>Loose flooring</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3bdaef92-d7d7-4ef8-affb-4dfeab711a40",
                                "recommendation": "<p>The floor squeaks when walked on</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3dae9974-637d-4eb4-8633-1d4def88725a",
                                "recommendation": "<p>This inspection does not cover any damage concealed by carpeting, rugs or furniture</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ddf9e394-dc69-4119-b358-77b7c2ce019c",
                                "recommendation": "<p>Water damage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7dac1f81-a803-4618-b2cd-f92277dcf596",
                                "recommendation": "<p>Noted uneven floor due to settlement</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6b33dd84-fa5b-4149-a7ef-80eb1a825aa8",
                                "recommendation": "<p>Carpet Stained/soiled</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fcd8401b-6eca-464d-b203-c70fe677f624",
                                "recommendation": "<p>Worn wood floor finish</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cc37aea6-91e0-42a1-83fb-de1db08e26cf",
                                "recommendation": "<p>Cuts or minor damage in flooring, repair/replace as required. Restretch carpets- trip hazard</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "aa2fa84d-7220-4257-8445-c35757123a2a",
                                "recommendation": "<p>Floor shows evidence of previous water penetration/stains</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "93336532-db94-427b-9445-e18c197d7685",
                                "recommendation": "<p>Worn carpets</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "665dc2e4-b7ac-4d0e-8301-3833e3ea217c",
                                "recommendation": "<p>Termite damage noted in wood flooring, repairs required</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "40648a36-b624-4d15-8147-211150498b7a",
                                "recommendation": "<p>Gapping in hardwood flooring noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "18d94f52-7176-4414-9eed-96c055f9d347",
                                "recommendation": "<p>Floor squeaks noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4177881c-f024-4920-89ee-76ee473a18e9",
                                "recommendation": "<p>Raised seams in vinyl flooring-repair</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "60b5e1a5-d1fb-4e41-89cb-8753a6faa828",
                                "recommendation": "<p>Uneven wood floor finish (difference under carpets/rugs) due to sunlight</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ae8e51b6-a0e4-4734-a2a6-787c227276b5",
                                "recommendation": "<p>Loose tile/grout, repair as required</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "ee72dbd7-3201-4145-b3fb-bf6873dee708",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Hardwood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Ceramic tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Linoleum",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Carpet",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl floor covering",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Poured",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "73a15da7-36c1-4ba2-98b0-9f2a9ef70f8d"
                            }
                        ]
                    },
                    {
                        "order": 7,
                        prompt: "Foundation:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6ddb7249-fdae-4120-a7b8-b9dfeb5a7ce0",
                                "recommendation": "<p>Cracked with displacement</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "de3875d2-09d5-47aa-b922-59a87bfa9063",
                                "recommendation": "<p>Stair step cracks</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ecd85d25-ac05-4a59-ad32-2cbc237eaab5",
                                "recommendation": "<p>Floor settled</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4a825326-2588-4154-b14f-fa6c47392969",
                                "recommendation": "<p>Floor heaving</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "60719e92-4a52-4f05-a770-cca9754d34e3",
                                "recommendation": "<p>A structural engineer is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "940794e7-3b6e-4477-b9b2-0d270bd2f845",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Block",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Poured",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Stone",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Earth",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Poured slab",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "2534d998-6241-4321-a7ea-be626f46917b"
                            }
                        ]
                    },
                    {
                        "order": 8,
                        prompt: "Doors:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "20e80187-e6d2-4b23-8665-904bc77bf3be",
                                "recommendation": "<p>Loose hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c00a7167-3b8f-4380-b8cd-5882a5fe4e87",
                                "recommendation": "<p>Missing hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9f2ed1d5-f3ff-4400-a69e-7201434f39b9",
                                "recommendation": "<p>The door has moisture damage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "41104ff3-9304-4e34-ad60-974618b57c9c",
                                "recommendation": "<p>The door is sticking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "40813d5b-2703-4b36-a525-514ad3f234ad",
                                "recommendation": "<p>The surface laminate is peeling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f2ea6316-73d6-4801-bd52-378823fd1285",
                                "recommendation": "<p>Wood rot</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "42bd16fd-84d7-4e35-8117-524b6154e7fc",
                                "recommendation": "<p>Lockset(s) not latching-requires adjustment</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "efbb69f9-3510-4c18-8ca5-a0db24aa7ad9",
                                "recommendation": "<p>Door(s) has minor damage from door stop-repair/replace</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4c12845b-9170-4317-8799-27a37f9fe9f1",
                                "recommendation": "<p>Lockset(s) not functioning-repair</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b1f5f219-98cf-4c2f-baec-d52fff537d16",
                                "recommendation": "<p>Replace missing door(s)</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e72e1dd8-30fc-46f4-87a9-9041be2abbf4",
                                "recommendation": "<p>Door damaged by moisture</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3c5716fb-96c4-4087-9871-d6a12bdffeae",
                                "recommendation": "<p>Doors need trimming/adjustment</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c0f79dbc-7c57-47af-bc18-bebe2ba86a02",
                                "recommendation": "<p>Replace missing bypass hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4243f071-3ad2-4b48-abbe-36a6c75eb1b9",
                                "recommendation": "<p>Lockset hardware installed opposite with locking knob to outside of room-needs correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b183ca5b-0d4e-4d30-a28b-c26913fc4167",
                                "recommendation": "<p>Align/adjust closet doors</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "daef62f8-4d5c-4324-8c82-062e53e33b09",
                                "recommendation": "<p>Undercut where rubbing on carpet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "9d5e6b85-88f7-481a-bf7c-4191f04753c0",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Hollow wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "French",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Bi-fold",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Solid wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Dutch",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "2798b2b2-9034-4d28-ad7b-60c294c2a66c"
                            }
                        ]
                    },
                    {
                        "order": 9,
                        prompt: "Windows:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3787b252-f4e0-47af-a329-0c0640b9c627",
                                "recommendation": "<p>Broken glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "20146964-f774-4938-b437-961c6b6ef8aa",
                                "recommendation": "<p>Repair broken sash cords</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3bb224d1-6470-4d82-b9fe-0d5cd4b86fef",
                                "recommendation": "<p>Cracked glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "84b9d81c-80c5-4caf-b806-b125751f9ce8",
                                "recommendation": "<p>Hard operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c8a1a7a9-de19-427e-8d7c-7c1684ed53f8",
                                "recommendation": "<p>Moisture present inside the layers of glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bf4c11fe-32c6-4f5c-879e-aba259311e02",
                                "recommendation": "<p>The window will not open</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6f97874d-d395-4244-b885-8ed801b84e40",
                                "recommendation": "<p>Window has fogging between the layers of glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5ef9664e-a4e8-4ae0-a966-efde4953d278",
                                "recommendation": "<p>Window seal broken</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4976dc72-15e4-4d72-ac41-3f52cca6fef7",
                                "recommendation": "<p>Window sticks</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "28ffd23c-a360-414e-b9cb-931cb12293f5",
                                "recommendation": "<p>Replace missing lock hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e5b0786b-8481-41c9-97ce-f221e4c12e57",
                                "recommendation": "<p>Exterior glazing repairs required</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "24e5a6e5-dfab-4a6e-962f-693b824b35ee",
                                "recommendation": "<p>Painted shut</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0ab95fb0-f867-484c-99bc-123e1c9a9c77",
                                "recommendation": "<p>Window sticks-improve operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "29e1ea40-fc6d-4531-941b-6568d0ab9fd6",
                                "recommendation": "<p>Will not open-requires correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "728ea556-4c6c-48cc-ad4e-79a86729654e",
                                "recommendation": "<p>Repair faulty operating hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0397600a-06d7-40f9-98b8-55176a2ffdee",
                                "recommendation": "<p>Locks do not align/lock-need correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5572bb22-fef6-4efb-8576-2cca01cb9e86",
                                "recommendation": "<p>Replace missing casement crank hardware handles</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3d454917-3892-4857-80f0-c5e42a9812a3",
                                "recommendation": "<p>A qualified glazier is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0caa725d-be8c-4976-ba67-278c81cd78d0",
                                "recommendation": "<p>Replace weather stripping</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d6eed222-9757-44f5-b7d3-6ba09dfd4a1d",
                                "recommendation": "<p>Replace missing pull hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "eb7d486b-5bbb-44f2-a9c4-cdd92cdddf86",
                                "recommendation": "<p>Hard operation-needs improvements for ease of operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5ef43e02-521c-4f07-8ccb-e83f66628b4c",
                                "recommendation": "<p>Repair/replace damaged colonial grids</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "dfc5cdad-1a4c-4330-85da-283eaf8a4090",
                                "recommendation": "<p>Crack in marble sill noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "fc60e057-a582-41d0-bda9-aefc5a5827ba",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Aluminum slider",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Aluminum double hung",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Non-opening",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood double hung",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Aluminum casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl double hung",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl slider",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steel casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steel frame",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood slider",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "ba9fdec6-b086-4682-bcae-373511b72e74"
                            }
                        ]
                    },
                    {
                        "order": 10,
                        prompt: "Electrical:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7dd95ae0-974b-47d1-9e10-08812626f359",
                                "recommendation": "<p>Open or missing ground</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c1876a04-8a5f-4b3c-a7cc-3abf6c2fea70",
                                "recommendation": "<p>Suggest evaluation by licensed electrician</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "580c2f5e-201f-495a-93d3-4845640bff13",
                                "recommendation": "<p>Non-GFCI circuit</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "922edfd7-73e3-405c-a0c6-7fecdbbd5ee6",
                                "recommendation": "<p>Reversed polarity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "963f9ec3-341f-4fb6-8881-dd9a9be46654",
                                "recommendation": "<p>Install missing junction box cover plates</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "19f47cc6-bd47-4336-ada2-5852e20e887f",
                                "recommendation": "<p>Install missing junction boxes and cover plates at open splice connections</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5411a070-1c99-4bf0-8ee6-6c9503b8ba2f",
                                "recommendation": "<p>Protect exposed surface mounted wiring in conduit to 7'-0' above floor</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b7bf992d-759e-4e1a-afee-ef2ec1afee94",
                                "recommendation": "<p>Faulty GFCI outlet-replace</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ace4736e-23e6-4ae8-bffb-f3e33b58e25e",
                                "recommendation": "<p>Reversed polarity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "80e9d2ce-28bb-4e67-9d48-1870adb3bde6",
                                "recommendation": "<p>Missing outlet/switch cover plates</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "67dfa992-9e4c-41d4-afbd-f80f686493ed",
                                "recommendation": "<p>Loose switch or outlet should be resecured</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f6732896-d453-4a9d-b85f-aa6a31ad6a12",
                                "recommendation": "<p>Ceiling fan inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "967ababe-14b8-432f-a64b-a4fa6bed26ef",
                                "recommendation": "<p>Worn outlets creating intermittent ground- replace outlets</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e9a200d6-dce0-424c-ba75-5827bf4ed5c1",
                                "recommendation": "<p>Light fixture not functional-repair/replace</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7be90974-bee9-421c-9df7-15a55d4c3c4d",
                                "recommendation": "<p>Open grounds noted at 3 prong outlets</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "68ca901c-249f-4e21-9338-0ebab8c6d605",
                                "recommendation": "<p>Remove temporary wiring running through walls -amateur installation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6f0692c7-ab63-4e72-a1a6-ebb2724223a0",
                                "recommendation": "<p>Outlets not working</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "42227428-c4f5-48f6-9003-2823ba16abb3",
                                "recommendation": "<p>Non-GFCI circuit -recommend GFCI circuit be installed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "49a28b0c-4d21-4b81-b2e4-97dca903d1d0",
                                "recommendation": "<p>Insufficient outlets for space</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "63f793b1-a7d9-49c3-bd83-b2f736a356df",
                                "recommendation": "<p>A licensed electrician is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b0148b49-63ff-4881-aa44-209ed2aa75b2",
                                "recommendation": "<p>Replace damaged outlet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a1d41e35-31e7-4f0a-8916-07a1f4fa6ba5",
                                "recommendation": "<p>110 VAC Outlets, Lighting Circuit and ceiling fan</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "62d24414-ed69-4f4f-92d7-d2abd2c51e93",
                                "recommendation": "<p>Install missing outlet/switch cover plate(s)</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "acc189ab-0b80-4a0d-88b8-328ab1eac608",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "110 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC GFCI",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC/220 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "220 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC outlets and lighting circuits",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "6c9eefeb-5e6d-482e-80ac-a2a09097beef"
                            }
                        ]
                    },
                    {
                        "order": 11,
                        prompt: "Plumbing:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ff97efcd-ed4c-4d62-93be-b5f21163ea80",
                                "recommendation": "<p>Evidence of previous leakage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cf8ae8d5-e1a8-40bb-99f2-6e51b4576957",
                                "recommendation": "<p>Leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "857bcb3a-ce80-4f59-9566-4528cafed86e",
                                "recommendation": "<p>Not properly supported in hangers</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "71c534fa-487d-4d82-adc1-1a62b6db2df3",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "cbfc0031-0a3d-42be-9d94-94d169f1f23d",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "ABS",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Cast iron",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "PVC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Lead",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Galvanized",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "PVC and copper",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "e9cc9131-720e-4dc7-a17a-7fdf78318be2"
                            }
                        ]
                    },
                    {
                        "order": 12,
                        prompt: "HVAC Source:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3f2a466d-ffbf-4a01-afea-98a49d3f936b",
                                "recommendation": "<p>Heating system inoperable</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c6edf984-d42c-4c37-a7fa-823d0bac47c3",
                                "recommendation": "<p>Low air flow</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1e3f848e-87a6-4ac1-8550-fbffa694e8aa",
                                "recommendation": "<p>No heat duct</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9b708c72-bf3e-4f91-9e55-39d3418235dd",
                                "recommendation": "<p>Register cover damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "446cd1fa-0c01-46b2-9b4e-8d347254bab2",
                                "recommendation": "<p>Register cover missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "0ab07936-c096-4260-8fae-cde5f9d309b5",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Heating system register",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Convection baseboard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Air exchange ventilation",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Radiant heat",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "2556d71b-b435-450f-990a-2142aa488c58"
                            }
                        ]
                    },
                    {
                        "order": 13,
                        prompt: "Gutters:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6950e05f-b15d-4c8f-8d31-3aa96e86a224",
                                "recommendation": "<p>Gutters loose, spikes or straps missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "01cfeb00-c29b-48cb-86e9-94b8819029fe",
                                "recommendation": "<p>Gutters have negative flow and are holding water</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "84e11479-68f3-4bb0-96be-a573c67aef13",
                                "recommendation": "<p>Loose nails</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9e74da25-7eed-49cd-9baa-79ce7fbc6e85",
                                "recommendation": "<p>Need cleaning</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cb16f591-a0b4-4617-a72e-8819638b15df",
                                "recommendation": "<p>Gutters appear to be undersized for roof pitch or conditions</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e271bd68-1c98-4c3b-ab17-8bb26b2c5e3a",
                                "recommendation": "<p>Leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fc78b8da-1a21-41bd-a86f-c617fbde1552",
                                "recommendation": "<p>Loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8841df46-cb2f-4867-8645-81f4159270c1",
                                "recommendation": "<p>Missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "b162b4ef-540a-4f4c-aec2-d49da3d9cf0a",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Aluminum",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Boxed",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Galvanized",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Splash blocks",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Plastic",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steel",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "fa0a06c6-327a-4b71-995c-7c1cbe930be6"
                            }
                        ]
                    },
                    {
                        "order": 14,
                        prompt: "Downspouts:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f8ee32cc-dec3-490e-a3f6-c0b6e41bb8b5",
                                "recommendation": "<p>Loose or missing clamps</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1b72dd1a-4116-4deb-85a5-6b23773891c2",
                                "recommendation": "<p>Missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c5e6ee4d-7155-416e-bb2a-31f01c56fd28",
                                "recommendation": "<p>Leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "565f7b3d-7a50-42da-8e76-1aab7f16ec06",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Aluminum",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Boxed",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Galvanized",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Splash blocks",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Plastic",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steel",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "7bb60ce5-3b2d-4815-ad3a-6f5edb3e5b5d"
                            }
                        ]
                    },
                    {
                        "order": 15,
                        prompt: "Leader/Extension:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cf1275b3-0113-499a-843c-28f5736888a9",
                                "recommendation": "<p>Damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "118b8116-d173-405f-a28a-a8a8674d6f96",
                                "recommendation": "<p>Not properly draining/clogged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a8a661b6-491c-4807-aca4-001a9c432a75",
                                "recommendation": "<p>Leaders do not extend 6 feet beyond the foundation perimeter</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1ceaeffb-1ca4-47a4-a124-392c3e3313ec",
                                "recommendation": "<p>Splash block(s) missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1ed4c7e6-65a3-487d-ab11-280e9482d97c",
                                "recommendation": "<p>Extend runoff drains to move water away from foundation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b14d4263-4cab-4a2c-ad18-f53fbf079a49",
                                "recommendation": "<p>Splash block(s) not properly installed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cba7dc60-bf72-4abf-ac7a-53445b36407c",
                                "recommendation": "<p>Reconnect to downspout where pulling loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "763770cc-0f5d-4f6b-8a62-076f0174f74c",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Loose",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Leaking",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Missing",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "5c42b4c4-990b-4d8e-8048-131e40b2a811"
                            }
                        ]
                    }
                ],
                "order": 1,
                "is_deleted": 0,
                "created_by": 686,
                "updated_by": 686,
                "created_at": "2022-11-08T18:05:40.000000Z",
                "updated_at": "2022-11-08T18:05:40.000000Z"
            },
            {
                uuid: "cfe235bc-6659-4a3a-9bf8-e44048a6a628",
                prompt: "Roof",
                "introductory_text": null,
                lines: [
                    {
                        "order": 1,
                        prompt: "Method of Inspection:",
                        comments: [],
                        "line_uuid": "0723c2bc-16cf-4176-ade9-53a9eeb06b68",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Ladder at eaves",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Ground level",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "On roof",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "54a3d2ae-c34a-47be-8136-7353de0f9ce6"
                            }
                        ]
                    },
                    {
                        "order": 2,
                        prompt: "Unable to Inspect:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "df1db74d-527c-4d15-9bed-8c48a25814e5",
                                "recommendation": "<p>Snow</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2ea02bf4-c1f7-46d1-b5b8-a4778959d32c",
                                "recommendation": "<p>Height of roof</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "849033f0-487c-4aac-9900-a2178937d88a",
                                "recommendation": "<p>Leaves</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bd46b901-e24f-4b10-a148-abb55370e707",
                                "recommendation": "<p>N/A</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "e0169748-fa85-4b18-ac3c-030c5f234153",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "10%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "15%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "20%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "25%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "30%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "40%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "45%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "50%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "55%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "60%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "65%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "70%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "75%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "80%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "85%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "90%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "95%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "100%",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "ef1dfe9a-a33a-48ad-b6de-fbaac49d138b"
                            }
                        ]
                    },
                    {
                        "order": 3,
                        prompt: "Material:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "74cf6e97-64ca-456c-85dc-5dbdd850739d",
                                "recommendation": "<p>Loose or damaged shingles</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3cb055e7-b7db-472d-9c10-9e0814213ddc",
                                "recommendation": "<p>Shingles nailed improperly</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "143bff3c-e50b-475b-b686-1bc045054d2a",
                                "recommendation": "<p>Pitch of roof too low for shingles</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0565c78a-5f24-403f-a52e-f883957e2c80",
                                "recommendation": "<p>Shingles not sealing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0fc4b76f-8f35-4b35-92cc-549faf837be9",
                                "recommendation": "<p>Roof shows signs of deterioration</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "20ef4fbb-9bb5-4ba2-bb71-c74923b9f08d",
                                "recommendation": "<p>Signs of cracking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "454ef4c4-5f92-47f3-95fb-1e93fbf7e8d4",
                                "recommendation": "<p>Roof spongy to walk on</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "70281816-ebd2-4660-94b7-ce719dbb7d52",
                                "recommendation": "<p>Roof at end of useful life</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "df057034-611d-403d-b68f-96ea46ca30f6",
                                "recommendation": "<p>Roof near end of useful life</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "89ad44da-626d-486a-9ee0-5fb1ea3e2901",
                                "recommendation": "<p>Roof existing beyond design life</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "847cb5a9-2a43-4e01-9e47-786914ce7f9e",
                                "recommendation": "<p>A qualified roofing contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f8f7912a-2544-4abf-b7cb-67bd1f991cc0",
                                "recommendation": "<p>Signs of curling or cupping</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "3eea3da0-b47e-431a-a590-2064d57df39f",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Asphalt shingle",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Clay tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Built-up",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Cement tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tar and gravel",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Asbestos",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fiberglass shingle",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rolled roof material",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Slate",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tin",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood shake",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "42099e56-e2a8-4f7d-b240-a84eb4d5b03d"
                            }
                        ]
                    },
                    {
                        "order": 4,
                        prompt: "Type:",
                        comments: [],
                        "line_uuid": "ee0e3f75-8ccc-4dca-9fc7-6920868bb75f",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Gable",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Gambrel",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hip",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Shed",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Mansard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Flat",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "e042cdc8-d777-4d0d-b9bf-00e024a92ccc"
                            }
                        ]
                    },
                    {
                        "order": 5,
                        prompt: "Approximate Age:",
                        comments: [],
                        "line_uuid": "baa0d375-d8e9-4d21-b580-826f6a538e4f",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "0-3 years",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "4-7 years",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "8-10 years",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "10-15 years",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "15+ years",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Unknown",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "New",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "e95e0468-62ee-4c4d-bb84-9d723bcb4fd0"
                            }
                        ]
                    },
                    {
                        "order": 6,
                        prompt: "Flashing:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0387734a-6f7b-4184-8616-64682495b99f",
                                "recommendation": "<p>Prone to possible leaks</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "57034b22-dea2-4fc1-85f1-2880c05e9c62",
                                "recommendation": "<p>Loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "937d8a21-501a-4f66-a6f6-be605d0dad9f",
                                "recommendation": "<p>Improper installation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4de7eb94-1e42-4e7e-bff9-e65c3e08835c",
                                "recommendation": "<p>A qualified roofing contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "ea07aa37-a343-4bde-abea-3b651bb1cd22",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Aluminum",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Galvanized",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "1220ed67-bd1e-494b-8470-3764f11c162b"
                            }
                        ]
                    },
                    {
                        "order": 7,
                        prompt: "Valleys:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f0394537-06cf-41e3-98ba-2f5d6ef056f6",
                                "recommendation": "<p>Valley shingles not trimmed and sealed properly</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d088f427-26cb-4d97-ae8a-adc3fa476a16",
                                "recommendation": "<p>A qualified roofing contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "d8c6ae80-aec4-411e-b7ba-8618b5061181",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Asphalt shingle",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rolled roof material",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Asbestos shingle",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Preformed metal",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "f4f63a3d-5960-469c-a6da-c0aadd968a1e"
                            }
                        ]
                    },
                    {
                        "order": 8,
                        prompt: "Skylights:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "68c700f8-05e0-4ed5-a7d8-142eeac27773",
                                "recommendation": "<p>Moisture stains at drywall near skylight</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9374363a-91ed-4e79-af0f-7b2e6ce61f69",
                                "recommendation": "<p>Water leakage present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e9b2fa7a-a215-486a-b390-efdb63f1b11d",
                                "recommendation": "<p>Evidence of past water leakage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "96eec778-b124-490b-9445-303a91b96d56",
                                "recommendation": "<p>Moisture present in the glass due to broken seal</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "042c45c7-6ff2-49fc-a460-e27e47c4cd4b",
                                "recommendation": "<p>A qualified roofing contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "6117c321-bf4a-42fd-abd5-5c5e1569c405",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Insulated glass",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Plastic",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "07155547-92b5-4f34-afb9-0da24bf63a38"
                            }
                        ]
                    },
                    {
                        "order": 9,
                        prompt: "Plumbing Vents:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "75cf1c0b-9f01-49cf-8224-fbcc46f5a27d",
                                "recommendation": "<p>Improper or blocked vent</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "787bd0c9-2b37-49ba-87aa-4a46a7bc8e51",
                                "recommendation": "<p>System not vented</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "822d2cb3-6e68-40b8-b39c-36e4f549aca5",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "cfd3e1c6-1c53-49cb-91ac-82d751a75f05",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "PVC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "ABS",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Galvanized",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Cast Iron",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "73fd4d47-c0e8-46a8-9f6c-f5dd0117823a"
                            }
                        ]
                    },
                    {
                        "order": 10,
                        prompt: "Electrical Mast:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "31c635ce-6c44-4f88-9978-7bf48ed46afa",
                                "recommendation": "<p>Missing tieback</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6f5ee886-574c-4735-9219-d12522250d3c",
                                "recommendation": "<p>Mast loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ca099653-a6c8-48d2-8b78-c06c39ee88dc",
                                "recommendation": "<p>No drip loop for wires entering masthead</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "317499ad-314a-40f6-8a90-4aee6ccf41cb",
                                "recommendation": "<p>Weather head not properly secured</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2042f723-c7b5-4f2b-b7ea-92c1bcc6fe28",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "730e42d8-28e2-4a88-95ce-dfcf525bafe5",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Mast with tie back at roof",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Surface mount",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Underground utilities",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "162afb94-0e61-46e1-9d02-2b134119f4e9"
                            }
                        ]
                    },
                    {
                        "order": 11,
                        prompt: "Gutters:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1feb66e4-acac-4872-aa59-a9b79878268f",
                                "recommendation": "<p>Gutters loose, spikes or straps missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "38804d82-d93a-4669-b668-e7f36c3e1a33",
                                "recommendation": "<p>Gutters have negative flow and are holding water</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8ca7fe0d-a98c-4e1a-9736-1f480de50426",
                                "recommendation": "<p>Loose nails</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "529045d2-0ba8-4c68-aa51-e61a5ffa03f4",
                                "recommendation": "<p>Need cleaning</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "126d1da8-3011-4e72-9b7f-c708591515ba",
                                "recommendation": "<p>Gutters appear to be undersized for roof pitch or conditions</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "394ac90d-1c14-42d7-a497-647308fc2891",
                                "recommendation": "<p>Leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2cc8f6e1-677b-44bb-92f9-772bce8a3a2f",
                                "recommendation": "<p>Loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fca0c77f-5312-45b1-9c2a-378c50eb2c82",
                                "recommendation": "<p>Missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "50201205-d073-43ae-b0e5-9b8fb1282ea6",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Aluminum",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Boxed",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Galvanized",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Splash blocks",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Plastic",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steel",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "336f670c-a1df-4c42-b8bf-f9e2cae5efc1"
                            }
                        ]
                    },
                    {
                        "order": 12,
                        prompt: "Downspouts:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9c81d218-b5bb-4ca2-945f-3df312c467dd",
                                "recommendation": "<p>Loose or missing clamps</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "efc55956-8615-4af7-b92c-38d96ef12b0d",
                                "recommendation": "<p>Missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3e4118de-05e8-48c1-819a-57f03072d4f4",
                                "recommendation": "<p>Leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "3b7366d3-83c1-48ad-91e0-effd273cd6e0",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Aluminum",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Boxed",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Galvanized",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Splash blocks",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Plastic",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steel",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "073e5159-2793-446a-9e07-c6ffd7c54c57"
                            }
                        ]
                    },
                    {
                        "order": 13,
                        prompt: "Leader/Extension:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6f1c77a6-c7d3-4b95-8ec7-cb09512886f6",
                                "recommendation": "<p>Damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "872c7c83-3ae8-4a2b-8603-cc9a0bb17ea7",
                                "recommendation": "<p>Not properly draining/clogged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "92739e81-e259-4cb8-b821-80b73dac4200",
                                "recommendation": "<p>Leaders do not extend 6 feet beyond the foundation perimeter</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d00b07e2-06dd-4441-8a3c-ade712304329",
                                "recommendation": "<p>Splash block(s) missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3473da2e-8890-4aad-bd02-1bbd6b3881a4",
                                "recommendation": "<p>Extend runoff drains to move water away from foundation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "09abb315-abfe-4b29-b6a8-96a47b899dfb",
                                "recommendation": "<p>Splash block(s) not properly installed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d0079737-8bc4-4a80-972c-b9586025ca95",
                                "recommendation": "<p>Reconnect to downspout where pulling loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "94b53746-cebd-4be8-95a4-18e1fb0b673f",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Loose",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Leaking",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Missing",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "82f46ccf-2954-470d-970c-c30b84e6d8ab"
                            }
                        ]
                    },
                    {
                        "order": 14,
                        prompt: "Chimney:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "43b499b0-8137-4ad8-acb0-1e6f184c0806",
                                "recommendation": "<p>Chimney requires tuck point repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fdacb8eb-1d1d-422d-a81f-4a944baaeb17",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "657cb450-5f8d-49e2-b9f0-b56d9d41514a",
                                "recommendation": "<p>Bricks have broken or popped surfaces- repairs required</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7cc7955e-7c7a-4b8f-a832-ae89187e31e0",
                                "recommendation": "<p>Damaged by lightning strike- requires repair</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d8b1b923-82a5-43f3-8136-6b93005d71d7",
                                "recommendation": "<p>Stair step cracks in chimney</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "70cccd50-8fd6-46f1-8c76-75629fba979d",
                                "recommendation": "<p>Unable to view interior of chimney due to height</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fc374866-5393-42a5-9368-638ad6d51aac",
                                "recommendation": "<p>Unable to view interior of chimney due to rain cap</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "464aa602-8100-4109-8676-39c7915bcb02",
                                "recommendation": "<p>Efflorescence noted at chimney brick (white discoloring)</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "654ba64e-530f-4771-a869-494aab756f20",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Brick",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Framed",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Stone",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Metal pipe",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Stucco & frame covered 3 wall pipe",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl & frame covered 3 wall pipe",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Aluminum siding & frame covered 3 wall pipe",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Metal surface mount direct vent",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Block",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "c867c071-8a36-4fbc-a81a-c8084d3dc940"
                            }
                        ]
                    },
                    {
                        "order": 15,
                        prompt: "Flue/Flue Cap:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "aa4a1913-92ba-4ca7-a13c-97d3e902a1a0",
                                "recommendation": "<p>Crown cracked , Repair chimney mortar crown</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9e7cdd8d-bc5e-4a1e-933d-e6340f5a9b91",
                                "recommendation": "<p>Recommend adding  rain/snow cap</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3ca40f61-69a4-45a0-9b87-29940d72f7ea",
                                "recommendation": "<p>Repair/rebuild chimney mortar crown</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fd8de160-0822-4fc4-a3e1-890f46ce3bfe",
                                "recommendation": "<p>Seal chimney crown/cap to minimize deterioration</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "059359f5-39a0-416a-ba8e-9876a51fbf4d",
                                "recommendation": "<p>Noted crack(s) in clay flue</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f20e4530-2fb5-4210-99b1-b48d55369212",
                                "recommendation": "<p>Unlined chimney flue,noted deteriorated interior mortar joints-not recommended for use</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "82c3a934-1e7f-47ef-8612-a6945216ef5c",
                                "recommendation": "<p>Metal crown rusting-require painting</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a089b30d-1ed3-4ec4-99d1-1bb5b9fe410b",
                                "recommendation": "<p>Not all of the chimney could be inspected due to the height of the roof</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fbfed649-2b58-422a-98fa-6ccb0a4760d9",
                                "recommendation": "<p>Chimney crown rusted-needs primed and painted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a3c59a5b-c957-47d5-a43f-3bde15acbb01",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "426e8b0c-2378-464a-883c-01707a2bd22c",
                                "recommendation": "<p>Chimney flue has been sealed off-not in use</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9ec7b498-26ab-41e1-a0f6-cac3cebc93fc",
                                "recommendation": "<p>Standing water atop chimney crown</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "da8a444f-b960-4247-8115-512fd73bda6b",
                                "recommendation": "<p>Metal crown concave and rusting-requires pitching and painting</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "25ac8a07-c302-47e2-877c-15db9b5dde73",
                                "recommendation": "<p>Chimney/lining does not meet current height requirements</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c80ccf9b-e345-4e2e-b33e-f0ec7860652f",
                                "recommendation": "<p>Amateur field made metal crown, not pitched for proper drainage-requires correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8f76f546-f1ca-49bf-a3a7-663e0677a575",
                                "recommendation": "<p>Chimney lining  could not be inspected due to rain cap</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "40a339cd-2c95-413d-be34-c4b3fb520f99",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Mortar",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Metal",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Concrete",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Metal surface mount direct vent",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Unable to view due to height",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Unable to view due to caps",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Metal/Mortar",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Stone",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Clay",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "5b02caed-23e2-4dd5-baa5-0f6e7b55b37f"
                            }
                        ]
                    },
                    {
                        "order": 16,
                        prompt: "Chimney Flashing:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9070b80a-15ee-4167-9299-ba4a5e26dfc3",
                                "recommendation": "<p>Loose counter flashing- repair</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fdafc947-4980-4694-90bf-4cb41ae1c108",
                                "recommendation": "<p>Missing counter flashing- replace required flashing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "709d81a4-e8de-4ab9-857b-ff77f69bb552",
                                "recommendation": "<p>Improper installation- counter flashing is not recessed into mortar joints- requires corrections</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "586763ff-47d2-4e36-8a26-7538a9be3565",
                                "recommendation": "<p>Rusted flashing- repaint</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "9e022727-a982-4049-b3db-0403cec8af85",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Aluminum",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Galvanized",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Lead",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Metal",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tar covered",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "b91019ef-6de8-4cb0-bdca-67ad34289cd8"
                            }
                        ]
                    }
                ],
                "order": 1,
                "is_deleted": 0,
                "created_by": 686,
                "updated_by": 686,
                "created_at": "2022-11-08T18:05:40.000000Z",
                "updated_at": "2022-11-08T18:05:40.000000Z"
            },
            {
                uuid: "4894684e-a0d7-4a81-95ef-ace1442358f9",
                prompt: "Garage/Carport",
                "introductory_text": null,
                lines: [
                    {
                        "order": 1,
                        prompt: "Type of Structure:",
                        comments: [],
                        "line_uuid": "8f7bed8f-abb3-4fa7-a3a0-06bc5f68e4a5",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Attached",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Detached",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Carport",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tuck under",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "0b485a7f-0de6-45f6-8c1b-40570ca6d11e"
                            },
                            {
                                "type": "Additional input",
                                prompt: "Car Spaces:",
                                "values": [
                                    {
                                        "value": "1",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "2",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "3",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "4",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "5",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "6",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "7",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "8",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "4031db38-d866-4226-954a-967e147472ee"
                            }
                        ]
                    },
                    {
                        "order": 2,
                        prompt: "Garage Doors:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a779e466-e138-4c92-bfed-f759bca72f97",
                                "recommendation": "<p>Rollers damaged or missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e8ba306d-2965-4cd4-8c1e-00712a39db91",
                                "recommendation": "<p>Broken door springs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "df72ed74-60f3-43e5-90ea-7f87d4d58117",
                                "recommendation": "<p>Consider adding safety cables with the overhead door springs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3b9cc9c7-1814-4ba3-ad12-9d4f20bf76c1",
                                "recommendation": "<p>Door panels have water damage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b83e4aab-dea1-4640-8869-14af073eff9a",
                                "recommendation": "<p>Door panels need to be replaced</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "71b3cef6-6836-4551-8b85-ffcc03023ed4",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "050da78b-620c-4eda-8915-d8db95d344da",
                                "recommendation": "<p>Water stains on door panels</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "81b0adca-f90b-4da6-853a-e146d02faa35",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Metal",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Insulated aluminum",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fiberglass",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hardboard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steel",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "0f8d5497-c9c6-43c9-bf54-993093154c61"
                            }
                        ]
                    },
                    {
                        "order": 3,
                        prompt: "Door Operation:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "afe05400-f7ae-4dbb-8c11-38174639bd2c",
                                "recommendation": "<p>Door binds due to broken cable</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b1fd45de-fdac-46b1-b871-47cd9f02672c",
                                "recommendation": "<p>Broken springs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "60d7d2b3-673f-490a-86c8-f19a7f895bcc",
                                "recommendation": "<p>Loose or missing hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b6f90d8e-f508-4517-8593-56d6032ff895",
                                "recommendation": "<p>Rollers missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "69c61837-849e-447c-acbf-35e434fe58fc",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "6abfdd4b-5d06-4d76-b7b5-740cea5ad46f",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Mechanized",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Manual",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "e963c323-ec23-4289-82f7-0f6258bc6387"
                            }
                        ]
                    },
                    {
                        "order": 4,
                        prompt: "Door Opener:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7997c6b2-9c45-4964-9ebd-f43d93ecd737",
                                "recommendation": "<p>Contact safety switch is out of adjustment</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6f8e074c-0de4-4d6c-b5a0-3128594762c8",
                                "recommendation": "<p>Light beam safety inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c3300060-0a7d-4cb9-b7bb-75b571d102da",
                                "recommendation": "<p>No safety reverse system in operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "92863967-d7b0-41de-a7eb-65dd2207573d",
                                "recommendation": "<p>Missing belt</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5c95b51c-9a31-4595-825f-07ff803d6ef1",
                                "recommendation": "<p>Inoperative at time of inspection</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b16d4fb2-6718-46ff-ae37-1db7d9c0378a",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5fed8163-c7a2-44cf-a280-d7baea2f6efa",
                                "recommendation": "<p>Cross bracing missing at garage door opener</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "116b0da9-b1f6-4198-9dbd-b59cd632906e",
                                "recommendation": "<p>Improper installation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "c8a144aa-ee30-47b5-af67-790ef1b2ecc9",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Sears",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Lift Master",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Overhead Door",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Craftsman",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Stanley",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Genie",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "55b80b28-4c35-40c3-9598-0126203125b1"
                            }
                        ]
                    },
                    {
                        "order": 5,
                        prompt: "Exterior Surface:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "66758db4-8f5b-4e9c-be54-b0b886ed220b",
                                "recommendation": "<p>Gaps not properly sealed at</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "57cdbc35-b7f9-4d58-854a-213a499cf6d4",
                                "recommendation": "<p>EIFS exterior finishes are prone to water infiltration and may cause hidden damage to the structure. There is usually no visible evidence of such damage. We recommend a detailed inspection by a qualified specialist.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "15fc5798-aa44-4545-bc98-a9af32bf39b3",
                                "recommendation": "<p>Visible settling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a636ce52-fe07-4b6a-81c5-3fe80884a21f",
                                "recommendation": "<p>Improper siding</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e0f9a9e8-3d66-45da-8dfc-ca9df2d3597e",
                                "recommendation": "<p>Moisture stains</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cd0bb30a-7795-4dc5-b46b-8153514f5277",
                                "recommendation": "<p>Paint peeling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c1c07799-11c5-4f3e-868e-4dab1e6b50ad",
                                "recommendation": "<p>Rot noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "70e4f534-7fe4-445f-8131-bc19929e6e8e",
                                "recommendation": "<p>Stress cracks</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "89550bbd-6e51-4bfc-9334-d1a5a444725d",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Brick veneer",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Block",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Aluminum siding",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl siding",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "EIFS stucco",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fibrous",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Metal",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Stucco",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "8d4b26ff-c86f-4756-8a8b-62a3d7617b32"
                            }
                        ]
                    },
                    {
                        "order": 6,
                        prompt: "Roof:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6bb10ac6-2c17-4dc9-aa1f-90d16ea6f092",
                                "recommendation": "<p>Loose or damaged shingles</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3a2a49b3-18a7-427f-9150-6c155965184e",
                                "recommendation": "<p>Shingles nailed improperly</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "19c58d88-9aa8-4281-ad08-7036086be594",
                                "recommendation": "<p>Roof existing beyond design life</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "347bd8d9-9b83-4b14-a9d3-ee6e7e8d31b6",
                                "recommendation": "<p>Signs of cracking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "dd36ee83-fab6-4739-aae9-a24a765d97cf",
                                "recommendation": "<p>A qualified roofing contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2104b3e5-af3f-46e5-a34e-ae924af2a606",
                                "recommendation": "<p>Roof at end of useful life</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "898ee248-40a0-482b-a477-2835a9119ec2",
                                "recommendation": "<p>Roof near end of useful life</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "29b92868-12fb-47a6-acc9-a549c3e96f9e",
                                "recommendation": "<p>Pitch of roof too low for shingles</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "866c702c-1ce3-4c22-8c88-c1958e17cba5",
                                "recommendation": "<p>Roof shows signs of deterioration</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "91ed91bd-9315-4b36-865e-87cbed9c4e6b",
                                "recommendation": "<p>Roof spongy to walk on</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "29273a68-91f0-4da2-b400-b566d9fe756d",
                                "recommendation": "<p>Shingles not sealing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "82b5f9e5-03d1-45be-82e1-465bd9f41408",
                                "recommendation": "<p>Signs of curling or cupping</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "a14ad6fe-a4fc-458c-be2d-5335257b67b1",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Asphalt shingle",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Clay tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Built-up",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Cement tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tar and gravel",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Asbestos",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fiberglas shingle",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rolled roof material",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Slate",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tin",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood shake",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "0b893a81-858d-437f-952c-25c4e2d87c93"
                            }
                        ]
                    },
                    {
                        "order": 7,
                        prompt: "Roof Structure:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c0e99f31-4c4b-471b-a7f4-00d2d86bdd65",
                                "recommendation": "<p>Water damage present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "dd5694ce-c38d-4f97-bb2a-1257a0130856",
                                "recommendation": "<p>Broken truss or rafter</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "df4e3e55-20e0-4a95-adc1-b207517c51d8",
                                "recommendation": "<p>Water stains present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9b9e9627-36c9-4cfa-a289-0d81af6e3d82",
                                "recommendation": "<p>A structural engineer is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "eaf0b6fe-e118-434a-ab38-3c51934ebeb5",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "2x4 Truss",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "2x10 Truss",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "2x12 Truss",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "2x4 Rafter",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "2x10 Rafter",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "2x12 Rafter",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "2x6 Truss",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "2x6 Rafter",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rafter",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Truss",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "b552d576-0559-4d3e-8657-441992eca0dd"
                            }
                        ]
                    },
                    {
                        "order": 8,
                        prompt: "Service Doors:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1c2b4f5a-d26c-457a-bf60-299069f34209",
                                "recommendation": "<p>Hinges are loose or damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4bde53a0-732d-4e93-bd0f-efcf18a229d3",
                                "recommendation": "<p>Loose or missing hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8fbd55cc-410c-48aa-8483-78b30d285e09",
                                "recommendation": "<p>Improper installation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "652531bf-c4ec-4f91-aa16-2abcb4379f79",
                                "recommendation": "<p>Paint peeling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9376c1dc-dd29-45b5-b082-82c2e55dfb93",
                                "recommendation": "<p>Wood rot</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ac822a12-c494-4978-b209-8914b58f4b54",
                                "recommendation": "<p>Surface laminate cracking and peeling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "72d99627-f9de-4d04-912d-8f3d7f5562ff",
                                "recommendation": "<p>Missing seal</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "db101875-ea28-4e4c-aada-96f131d686a2",
                                "recommendation": "<p>Missing threshold seal</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5e9be8e5-45fa-40b6-add3-94ecd215e75d",
                                "recommendation": "<p>Loose or missing threshold</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2b133300-919b-41e2-a832-be85de44c28d",
                                "recommendation": "<p>Add closing mechanism to entry door</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0512b2b2-8e4e-45f7-81fb-764841d065ce",
                                "recommendation": "<p>Entry door not rated for fire</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b950815c-73c3-4477-8521-2eec959ae8c6",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "ce2f832a-3b02-4f9a-80af-264067e40ef8",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Metal",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fire rated",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "7e369d45-3b03-4865-8aac-87f80a4af663"
                            }
                        ]
                    },
                    {
                        "order": 9,
                        prompt: "Ceiling:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "98fb39ae-5322-4b3f-ae22-986f43672854",
                                "recommendation": "<p>Cracks present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a0387c63-fb8e-48f9-a7f5-ec9f4b8f7f90",
                                "recommendation": "<p>Loose or peeling paint</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a4ac9739-ac5b-4ada-91c4-80943c2f4034",
                                "recommendation": "<p>Loose paneling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fdbd403f-4d65-40c9-b0c4-5321682fa7a4",
                                "recommendation": "<p>Loose plaster</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "05a8d614-f6ea-4b3a-8c63-68f04330fde8",
                                "recommendation": "<p>Water stains present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9c45cbf8-3150-4ec5-8a17-66e3ae504599",
                                "recommendation": "<p>Stress cracking, see structural notes</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7a5b29e6-7d4c-4da7-88be-15f01fa66050",
                                "recommendation": "<p>Evidence of possible truss uplift-repair as required, monitor for further movement</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3aee9020-c842-4f65-a881-f7d99822680f",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d364bcc9-2254-4165-b5b0-2bc5d0217f6c",
                                "recommendation": "<p>Mold is present and is a potential health concern. Removal and/or correction is required.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "35a21061-b040-4e97-957a-6021bf307940",
                                "recommendation": "<p>Evidence of past or present water staining</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "c43b1c15-804a-4d79-a3fd-8e68a1a1c850",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Paint",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Exposed framing",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Mirrors",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Texture paint",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Suspended ceiling",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "7b0a76e9-e6d9-4294-9737-048a73cb4ad5"
                            }
                        ]
                    },
                    {
                        "order": 10,
                        prompt: "Walls:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7d71de56-ca01-40a5-881d-fcb177e6468c",
                                "recommendation": "<p>Cracks present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b148f55b-12f4-4464-a110-9e224adeeb9c",
                                "recommendation": "<p>Evidence of past or present water leakage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9738d135-4003-4749-8c32-ec47c9aa73e0",
                                "recommendation": "<p>Evidence of possible truss uplift</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fab0ed79-8bd7-4883-8fe1-64a37a5c6386",
                                "recommendation": "<p>Loose or peeling paint</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "08391eed-a59d-4cad-be34-24e60e1dc69d",
                                "recommendation": "<p>Loose paneling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "03b31146-385f-4072-8449-7b93fba00cb5",
                                "recommendation": "<p>Water stains present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e36a119b-9396-407b-a9f8-d1a4f3335e9a",
                                "recommendation": "<p>Evidence of past or present water staining</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e91c807b-92ca-4de6-8ce1-389678a4ef2c",
                                "recommendation": "<p>Loose or missing tile</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "40f3b2b5-e882-4f33-b8fa-63c26c195515",
                                "recommendation": "<p>Mold present-potential health concern-needs removal/correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1d45c8e3-6786-4fca-a7e2-90e249aafdee",
                                "recommendation": "<p>Paneling loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4179ae5e-0af2-44db-a8dc-03d366862f88",
                                "recommendation": "<p>Some ceiling pads damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "50713127-7c68-49d2-8ec1-2ff535b85a71",
                                "recommendation": "<p>Some evidence of previous truss uplift</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d728394a-966c-4987-bbcb-04c5a948242b",
                                "recommendation": "<p>Inspection does not cover any damage concealed by rugs, carpeting, wall paneling, furniture</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f56a55b4-4565-4065-b3f1-5c8f70833c48",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "6293a594-192d-4181-8a67-9494e94b1e0c",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Paint and paneling",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Exposed framing",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Paint and tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wallpaper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Paint",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Concrete",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wallpaper and tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fire rated",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "f3b69557-2c2a-40e7-baec-cac326e34db4"
                            }
                        ]
                    },
                    {
                        "order": 11,
                        prompt: "Floor/Foundation:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5e43174d-bbc3-4110-a078-645713e7330d",
                                "recommendation": "<p>Minor floor cracks noted-seal cracks</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "863b3fda-e7c5-4ae2-9895-f48c49153e8b",
                                "recommendation": "<p>Floor settled</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "84e3ffbe-44ca-4ece-b03c-a4878733adf7",
                                "recommendation": "<p>Cracked with displacement</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7a498c74-e431-424f-875d-55e1e74e9852",
                                "recommendation": "<p>Floor heaving</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ee2b15fd-9199-48ee-be5e-b4b10dc83fcc",
                                "recommendation": "<p>Spalled foundation corners</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fa681485-97f3-4cbc-b664-dab39ebd5728",
                                "recommendation": "<p>A structural engineer is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "99d1de03-0e40-4531-8760-0c325f9aaa69",
                                "recommendation": "<p>Stair step cracks noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f99a9f5a-f6de-4a23-a201-234091ea0814",
                                "recommendation": "<p>Noted floor settlement at overhead garage door. Door will not seal along floor.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9a039e29-8412-4b14-9a3c-d7439920e444",
                                "recommendation": "<p>Some puddle stains noted on garage floor due to low spots in concrete placement</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "52d7e391-bc25-4690-b215-8af11f9f14e9",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Concrete block",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Poured concrete",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Stone",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Earth",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Poured slab",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Monolithic poured slab",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "dacfe1a9-7078-43eb-a2e8-6f6019e70eee"
                            }
                        ]
                    },
                    {
                        "order": 12,
                        prompt: "Hose Bibs:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "eeb508af-f4de-4f55-8ae3-1de03c38870b",
                                "recommendation": "<p>Signs of past or present leakage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a747557b-f6b2-4181-b7a6-3d2a5aacc52b",
                                "recommendation": "<p>Missing handle</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c04f20c2-b59a-428e-974c-f8102445e3c7",
                                "recommendation": "<p>Frozen or inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "877b2b1a-0f19-4f5e-aeaf-e3c74473997c",
                                "recommendation": "<p>Note: Valves are not operated as part of this inspection, any reference to any valve inspected is visual only unless otherwise noted.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e8e9f678-f191-4ea0-b759-61aa89e2fc0a",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "ef132dff-e6e9-46cb-b46c-b06c5160009e",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Gate",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rotary",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Ball",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Multi-port",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "412d680a-9594-46e3-b5ab-d400bfd516b7"
                            }
                        ]
                    },
                    {
                        "order": 13,
                        prompt: "Electrical:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "89b76ebe-efea-4413-9f4e-8f92befcfed3",
                                "recommendation": "<p>Open or missing ground</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e6ee63da-4793-4959-a1e7-9f3e6f7d73d6",
                                "recommendation": "<p>Suggest evaluation by licensed electrician</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "338e70e6-efc7-455f-a298-7a4c5b101cb4",
                                "recommendation": "<p>Non-GFCI circuit</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "880dc48d-272a-444e-982f-966399c14739",
                                "recommendation": "<p>Reversed polarity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "eed70744-4ad4-4e43-9e2c-aa90f02eb593",
                                "recommendation": "<p>Install missing junction box cover plates</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e6220e6c-41ea-4c79-8e5e-c7bdaf56921e",
                                "recommendation": "<p>Install missing junction boxes and cover plates at open splice connections</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f0b7745d-c0b4-4a75-8186-279d5b7da50f",
                                "recommendation": "<p>Protect exposed surface mounted wiring in conduit to 7'-0' above floor</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "aaf71bdf-0689-484a-9953-3a56e7004039",
                                "recommendation": "<p>Faulty GFCI outlet-replace</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b7eed969-00d6-47ed-ad7d-5799a1c75f77",
                                "recommendation": "<p>Reversed polarity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b26f17bb-2218-45ed-bab8-a19516205903",
                                "recommendation": "<p>Missing outlet/switch cover plates</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d2f80766-b9de-40a2-8f9e-defd8bdcca55",
                                "recommendation": "<p>Loose switch or outlet should be resecured</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "99925786-066e-426a-aab1-f813fc791760",
                                "recommendation": "<p>Ceiling fan inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "05a93175-8c33-4836-a4e2-3ad5c7dd0bb1",
                                "recommendation": "<p>Worn outlets creating intermittent ground- replace outlets</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d92b299c-ed74-4d0e-b509-968d6048771d",
                                "recommendation": "<p>Light fixture not functional-repair/replace</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5c8ef31e-3b13-452a-b1fb-775a7f877d27",
                                "recommendation": "<p>Open grounds noted at 3 prong outlets</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3ab4f463-9a62-48ed-9551-eab628ce2abc",
                                "recommendation": "<p>Remove temporary wiring running through walls -amateur installation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "49808b87-90f0-41c7-9c9e-03bf74fefe54",
                                "recommendation": "<p>Outlets not working</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ffe579bb-c8a9-41f8-9ce7-57bb41348b02",
                                "recommendation": "<p>Non-GFCI circuit -recommend GFCI circuit be installed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c3d29697-d4dc-489a-8971-b657b336f2cc",
                                "recommendation": "<p>Insufficient outlets for space</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4a7c938a-536f-4990-b558-39568393267d",
                                "recommendation": "<p>A licensed electrician is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "20ce22c2-0435-43d5-b681-934e8cfbf3fc",
                                "recommendation": "<p>Replace damaged outlet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "10c284b6-37c7-40a1-b523-a06fd5e857db",
                                "recommendation": "<p>110 VAC Outlets, Lighting Circuit and ceiling fan</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "af1da114-d0a0-4e5a-9ea4-bafc1d01f80d",
                                "recommendation": "<p>Install missing outlet/switch cover plate(s)</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "146f8fd5-b9b2-4e72-9502-2c3f2ac196c2",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "110 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC GFCI",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC/220 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "220 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC outlets and lighting circuits",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "b04e3c6d-bda7-4472-bc43-2fb8cd56c2c8"
                            }
                        ]
                    },
                    {
                        "order": 14,
                        prompt: "Smoke Detector:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6968c591-8a70-49c4-8d6c-d59a7fb04b4e",
                                "recommendation": "<p>Evaluation by a fire alarm certified licensed electrician is recommended</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "adc2998c-f614-4cb1-9505-48860e2e7b0a",
                                "recommendation": "<p>Not tested due to lack of electricity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f1db0fe9-14c9-4a37-b47c-6e312d356f31",
                                "recommendation": "<p>Battery missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d2f8a43f-057b-4b0e-812a-0820f52c8423",
                                "recommendation": "<p>Improperly wired</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8039d896-a98f-4205-92a1-8df2a271a458",
                                "recommendation": "<p>Inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "4204c543-89a0-471a-90a3-665b5a63077d",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Battery operated with light",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Battery operated",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hard wired with battery back up and light",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hard wired",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hard wired with battery back up",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "3bb72e03-21dd-4fe6-9601-1ad516366617"
                            }
                        ]
                    },
                    {
                        "order": 15,
                        prompt: "Heating:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5044877c-88ce-475b-958e-f9784dcc2eef",
                                "recommendation": "<p>Heating system inoperable</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1be5e710-97e1-4a30-86ed-f4669691023d",
                                "recommendation": "<p>Low air flow</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "18429f58-6343-4f67-860a-acd1948d9f6d",
                                "recommendation": "<p>No heat duct</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6af3ad19-c5a0-46dc-9c87-58a689ae5052",
                                "recommendation": "<p>Register cover damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "99daa14e-1cff-4f6e-b034-4f98c1c44288",
                                "recommendation": "<p>Register cover missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "cc3d59af-b527-46c5-ba7f-9a55a8e776c8",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Heating system register",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Convection baseboard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Air exchange ventilation",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Radiant heat",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "7ede4958-ba02-4c38-b012-c86dc2f5a44f"
                            }
                        ]
                    },
                    {
                        "order": 16,
                        prompt: "Windows:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f80dc7a0-f4a2-4edc-beef-c417939d5b88",
                                "recommendation": "<p>Broken glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "42567963-a932-4d72-97f2-31fef2083fe5",
                                "recommendation": "<p>Repair broken sash cords</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2d263728-97fd-49b7-a97a-6bd8a60f69aa",
                                "recommendation": "<p>Cracked glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "842dbe85-3298-480e-8728-a5d0659f61bc",
                                "recommendation": "<p>Hard operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c1b4b326-7c19-431d-841d-e7f0bbc793e5",
                                "recommendation": "<p>Moisture present inside the layers of glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6839061f-aadd-41ba-b4bc-fe3c91f1a41a",
                                "recommendation": "<p>The window will not open</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8fdec349-69cf-44dc-af5a-3d1496075aad",
                                "recommendation": "<p>Window has fogging between the layers of glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e2aaf427-358d-49d1-a617-4251f1a63d34",
                                "recommendation": "<p>Window seal broken</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "711ed38e-ed40-4157-830f-62a509d502ea",
                                "recommendation": "<p>Window sticks</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "51644d50-cc69-4379-8399-d952c7647f78",
                                "recommendation": "<p>Replace missing lock hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ba02c9ec-265c-4a29-a567-555dcfb6b065",
                                "recommendation": "<p>Exterior glazing repairs required</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4618be9b-5eb0-4934-9008-dec1c101723d",
                                "recommendation": "<p>Painted shut</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "88e0e389-9fca-4c93-ab35-05c0c7c62170",
                                "recommendation": "<p>Window sticks-improve operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "76f957c9-29e7-44e2-9822-382ed8702d4b",
                                "recommendation": "<p>Will not open-requires correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7009300e-c769-4d05-81f1-10175c49b855",
                                "recommendation": "<p>Repair faulty operating hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f8ce51b1-3e4e-4a85-9219-6b138e1484cd",
                                "recommendation": "<p>Locks do not align/lock-need correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3d571387-0db0-4ebc-b2f2-c28646de807b",
                                "recommendation": "<p>Replace missing casement crank hardware handles</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e80190c7-2e9c-45f3-a93b-17d2a90f5dd9",
                                "recommendation": "<p>A qualified glazier is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fce6f6c6-0fca-41fc-8e7c-002435e9d28f",
                                "recommendation": "<p>Replace weather stripping</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "528a509f-2e1f-4b10-b396-b3414d85ed67",
                                "recommendation": "<p>Replace missing pull hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "35fce01f-25f5-41bd-9415-dde98392e79d",
                                "recommendation": "<p>Hard operation-needs improvements for ease of operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "794c8f12-f549-4a68-b6cd-7e25cc968915",
                                "recommendation": "<p>Repair/replace damaged colonial grids</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "42a2fc8a-7ae7-43c6-802d-6f7c39ee0e80",
                                "recommendation": "<p>Crack in marble sill noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "21692182-8a03-4d18-bb86-9a0c04d64000",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Aluminum slider",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Aluminum double hung",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Non-opening",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood double hung",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Aluminum casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl double hung",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl slider",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steel casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steel frame",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood slider",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "d1ba444e-9def-48f0-8fbe-22bffad8eac6"
                            }
                        ]
                    },
                    {
                        "order": 17,
                        prompt: "Gutters:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9b226bd5-3deb-436d-9f33-55cfc3cc6c9b",
                                "recommendation": "<p>Gutters loose, spikes or straps missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "25dc9e2f-26b2-450b-bd8d-9ed959b7317a",
                                "recommendation": "<p>Gutters have negative flow and are holding water</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7992e921-8e57-44c1-b1a3-e7e1b352aae0",
                                "recommendation": "<p>Loose nails</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d843db04-36f1-492e-b90f-a0256bd848f2",
                                "recommendation": "<p>Need cleaning</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e2af26e7-a0f7-4388-aabb-d2da870fe08f",
                                "recommendation": "<p>Gutters appear to be undersized for roof pitch or conditions</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ba629750-07d6-4c8c-8cda-653f018155f5",
                                "recommendation": "<p>Leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fcf60ded-b524-4645-8973-e6ea750c5ad0",
                                "recommendation": "<p>Loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "228002d7-7a30-42d5-b885-e80726f057eb",
                                "recommendation": "<p>Missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "0a326bae-b792-4192-a58d-db15a2e5061f",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Aluminum",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Boxed",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Galvanized",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Splash blocks",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Plastic",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steel",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "a64374bc-076f-4cea-83df-ab14b6d5f876"
                            }
                        ]
                    },
                    {
                        "order": 18,
                        prompt: "Downspouts:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "84836652-2649-4792-bc49-092e93207530",
                                "recommendation": "<p>Loose or missing clamps</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d10e86b7-d27a-47b7-af0e-38f89fcd37df",
                                "recommendation": "<p>Missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "60bd9868-31ba-4219-87a3-16919de59341",
                                "recommendation": "<p>Leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "ccf11cd8-0a8f-4f93-a3e6-6485ba7b8a46",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Aluminum",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Boxed",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Galvanized",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Splash blocks",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Plastic",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steel",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "fdb27218-e8c5-43b5-a712-19c3a2618d40"
                            }
                        ]
                    },
                    {
                        "order": 19,
                        prompt: "Leader/Extensions:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9def1242-f708-4207-b154-b0a3b3c0ec83",
                                "recommendation": "<p>Damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f5896d03-e1a0-4abb-9453-10cfdd98d0a1",
                                "recommendation": "<p>Not properly draining/clogged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a035442d-dc78-4d2e-945e-a78600b4bfd6",
                                "recommendation": "<p>Leaders do not extend 6 feet beyond the foundation perimeter</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8a9055a0-f539-4d14-8162-3ae341e9c9fe",
                                "recommendation": "<p>Splash block(s) missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "960e0de3-57c6-4c7a-8f9b-ecc253d4277d",
                                "recommendation": "<p>Extend runoff drains to move water away from foundation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "901ed606-fed5-4d79-b5f6-2cf4e8122d7c",
                                "recommendation": "<p>Splash block(s) not properly installed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "80edc90a-c526-469b-b4db-782d2f11332a",
                                "recommendation": "<p>Reconnect to downspout where pulling loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "521fe923-d652-4c01-92c2-52381973ceb3",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Loose",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Leaking",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Missing",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "ffd75fe8-7933-4139-a535-82afaacbb098"
                            }
                        ]
                    }
                ],
                "order": 1,
                "is_deleted": 0,
                "created_by": 686,
                "updated_by": 686,
                "created_at": "2022-11-08T18:05:40.000000Z",
                "updated_at": "2022-11-08T18:05:40.000000Z"
            },
            {
                uuid: "865ab361-09f5-4cfc-b405-d6a31fddcb09",
                prompt: "Electrical",
                "introductory_text": null,
                lines: [
                    {
                        "order": 1,
                        prompt: "Service Size Amps:",
                        comments: [],
                        "line_uuid": "4d92e064-60ff-49cf-9786-dc7002b85ec4",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "60",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "100",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "125",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "150",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "175",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "200",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "40",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "3dce52bf-de99-4a64-a85d-835844f448d9"
                            },
                            {
                                "type": "Additional input",
                                prompt: "Volts:",
                                "values": [
                                    {
                                        "value": "110 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "220 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "220-240 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110-120 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110-240 VAC",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "130d2e6c-120c-45dc-b3a0-c9060de91f80"
                            }
                        ]
                    },
                    {
                        "order": 2,
                        prompt: "Service:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8a206b0a-25ff-4b61-b998-fdb7a3dba998",
                                "recommendation": "<p>Surface coating of service cable is cracked and may allow water to penetrate inside the cable.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bba55173-403f-4282-9eea-3d422b449005",
                                "recommendation": "<p>Trim trees for clearance around entrance cable</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fef25c77-4c5e-4446-8a92-599415a79db4",
                                "recommendation": "<p>No drip loop present for wires entering masthead</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "069a909c-d800-4729-ab67-43603a6d9fba",
                                "recommendation": "<p>Weather head not properly secured</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ade639aa-98e9-4e43-9880-ecc18db8faa8",
                                "recommendation": "<p>Evaluation by a licensed electrician is recommended</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "b46777bd-c413-4de9-b17f-7cf1102ab200",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Aluminum",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper and aluminum",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "936e4217-3bfa-43ab-9437-a862297def58"
                            }
                        ]
                    },
                    {
                        "order": 3,
                        prompt: "120 VAC Branch Circuits:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1a5e300f-8d89-48c2-a0d6-c84ea9325005",
                                "recommendation": "<p>Covers missing from boxes</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d060f962-0cb4-4154-a8ec-e1edf7b32c8e",
                                "recommendation": "<p>Evaluation by a licensed electrician is recommended</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3e108f5d-d4ae-488d-be42-19688c72da28",
                                "recommendation": "<p>Improper routing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9fe65eac-3db9-4f54-942e-1d1ee544e5d0",
                                "recommendation": "<p>Open ground circuit</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f209352b-b684-4431-b41e-f1f98c2b2f18",
                                "recommendation": "<p>Pigtails added for aluminum wiring</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0a63ff59-0efc-413b-8244-0e1c8a08d858",
                                "recommendation": "<p>Reversed polarity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b3abd35b-f3c0-43f9-bdbf-94fd41143d04",
                                "recommendation": "<p>Wires terminate outside protective box</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "42fb11b1-be00-4570-9ae6-c3d7330ab754",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Aluminum",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper and aluminum",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "fed65c9a-4cc7-4bbc-b2b0-4af70b386bb3"
                            }
                        ]
                    },
                    {
                        "order": 4,
                        prompt: "240 VAC Branch Circuits:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b8ef60d0-e108-445c-ad99-d15f7672ca98",
                                "recommendation": "<p>Covers missing from boxes</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b06d3868-300b-484c-8d6e-ed82a2f57812",
                                "recommendation": "<p>Evaluation by a licensed electrician is recommended</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1e8b299d-11ea-445e-8e23-e6c8e6e2fb22",
                                "recommendation": "<p>Improper routing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f39b9edb-48d6-41b1-ac15-a0bfc296d129",
                                "recommendation": "<p>Open ground circuit</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9d2b43b4-2a62-4830-ae01-e389e81306ad",
                                "recommendation": "<p>Pigtails added for aluminum wiring</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fbb05822-542b-4482-a498-0e604c3255fb",
                                "recommendation": "<p>Reversed polarity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4670a466-e43a-4830-9825-fe07e05d8040",
                                "recommendation": "<p>Wires terminate outside protective box</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "e346e03b-c231-4090-b912-2eefc0355928",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Aluminum",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper and aluminum",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "4aba9192-4f1a-4a6e-9bff-9cf7b7473b0d"
                            }
                        ]
                    },
                    {
                        "order": 5,
                        prompt: "Aluminum Wiring:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0e3a31ca-3283-474a-9780-8af4b8486004",
                                "recommendation": "<p>Circuit has been upgraded with ALU/CU breakers and pigtails at the receptacles</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "69539598-47c9-4252-8895-fe0befe76986",
                                "recommendation": "<p>Recommend wiring update for aluminum wiring by a licensed electrician</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "9fc7c286-289e-4ac7-b4df-2b0022c3e514",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Present through out the home",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Localized circuits 1st Floor",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Localized circuits 2nd floor",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Old wiring not presently in use",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not present",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "bc7356e9-c0e6-4c03-aff6-45ed8db0d859"
                            }
                        ]
                    },
                    {
                        "order": 6,
                        prompt: "Conductor Type:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6b415a04-3723-4070-a9c4-544ad4c14a38",
                                "recommendation": "<p>Improper routing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "162c47ee-883a-4d0b-9be3-ca6fd5400fd3",
                                "recommendation": "<p>Conduit not secured</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3e350e22-9a1a-46d6-ad95-1aafc0e3402f",
                                "recommendation": "<p>Frayed covering</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "20937665-9dc7-47aa-966e-7886650b4686",
                                "recommendation": "<p>Improper application</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2ce168e7-37c4-4330-815c-fb946aebd10d",
                                "recommendation": "<p>Evaluation by a licensed electrician is recommended</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "14a52f31-c10c-4d68-b55f-d72eaa227dc4",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Armored cable",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Non-metallic sheathed cable",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "BX",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Knob and tube",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Romex",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Romex, armored cable and knob and tube",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Romex and aluminum cloth wrapped",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "521c0c0c-841b-448a-ae7d-801aa3ff5a01"
                            }
                        ]
                    },
                    {
                        "order": 7,
                        prompt: "Ground:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b7b47041-bdaf-4c62-bfc9-56d79054dda4",
                                "recommendation": "<p>Missing ground strap at water meter</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "04955a86-a299-467e-8f05-a9dc2e03c3a3",
                                "recommendation": "<p>Open ground circuit</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b0290ad7-fc1b-46ae-ac4f-82143e9b829a",
                                "recommendation": "<p>Ground wire loose or broken</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d6b25b4e-0789-4ccc-b095-7711605e8a8c",
                                "recommendation": "<p>Evaluation by a licensed electrician is recommended</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "d2273031-b423-4915-b03b-8c43491f1405",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Plumbing and rod in ground",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rod in ground only",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Plumbing ground only",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "f16bacee-4cd9-4801-bba7-914f5072d25c"
                            }
                        ]
                    },
                    {
                        "order": 8,
                        prompt: "Smoke Detectors:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fc5d4ac4-9f87-45b2-a3da-a4c03b82cb77",
                                "recommendation": "<p>Evaluation by a fire alarm certified licensed electrician is recommended</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6b4304bf-e5e6-4ea7-ad7b-5c7686321bac",
                                "recommendation": "<p>Not tested due to lack of electricity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3eab204a-8813-4f58-9693-c8681f242350",
                                "recommendation": "<p>Battery missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6a5ee723-863a-42b8-9a4b-ea85fe95eb7f",
                                "recommendation": "<p>Improperly wired</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e042a88d-1c8e-40e5-bd06-f8306fdee0d4",
                                "recommendation": "<p>Inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "5a0cba79-f773-48eb-b9c1-f430da1cf6b9",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Battery operated with light",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Battery operated",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hard wired with battery back up and light",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hard wired",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hard wired with battery back up",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "0b36c332-5895-4f30-930b-9b66025e9ce8"
                            }
                        ]
                    },
                    {
                        "order": 9,
                        prompt: "Carbon Monoxide Detectors:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "268bfdfe-8f10-452c-847d-8d5e32ce63c4",
                                "recommendation": "<p>Not tested due to lack of electricity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8a0f50d2-de11-44f6-a4bf-b1c3ecab7827",
                                "recommendation": "<p>Battery missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "53d30c28-bd6c-4888-80ac-041a798f64a4",
                                "recommendation": "<p>Improperly wired</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "45163506-af59-4ec8-b47b-10706e312ec2",
                                "recommendation": "<p>Inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "56b8347c-c7c5-48bb-8067-1aac014d8775",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Battery operated with light",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Battery operated",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hard wired with battery back up and light",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hard wired",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hard wired with battery back up",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "38b67e49-d4fc-481b-9299-bbd39807f8ae"
                            }
                        ]
                    },
                    {
                        "order": 10,
                        prompt: "Manufacturer:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "595cb28d-b802-4437-92b0-aa12e8cdef65",
                                "recommendation": "<p>Corrosion on breakers from water penetration</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ca5dc50e-699d-46cf-b105-fda84101bac3",
                                "recommendation": "<p>Double taps present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3c855c12-dbd3-41d9-baf2-60e3ee72ce1f",
                                "recommendation": "<p>Open ground noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "aec385c4-c7e3-4362-bef7-0f9499a2aa23",
                                "recommendation": "<p>Recommend existing panel be replaced</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "954e03af-1639-4a35-9c6c-6a79729594a4",
                                "recommendation": "<p>Panel too small for the current needs of the home</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c4e4921b-e2d8-4b61-8c8c-9c477edbbef4",
                                "recommendation": "<p>Recommend fuse style panel be updated</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6f7eed22-4364-45c2-9ae6-70fee26b5cb8",
                                "recommendation": "<p>Loose or missing wire retaining screw at breaker connection</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "823ce5fc-1c3b-436e-8620-bc897f9e0998",
                                "recommendation": "<p>Signs of arcing in cover</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "67617930-073a-4385-9399-23da70647af1",
                                "recommendation": "<p>Improper cover screws</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8c0d9c44-04f5-4190-a41e-5ade984249fb",
                                "recommendation": "<p>Direct tap present, wires not protected by breaker or fuse at the panel</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "dbd52144-c8c8-4c53-819c-f1817961a071",
                                "recommendation": "<p>Evaluation by a licensed electrician is recommended</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d699698b-4119-4326-b1fe-e07ae4a8dc81",
                                "recommendation": "<p>Double tap present at main panel lugs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a734e2f5-7073-474d-8153-1a4b71272e7f",
                                "recommendation": "<p>Federal Pacific panel breakers have a history of not tripping when circuit overloading exists</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ca5d956d-682c-4c09-9bf5-187182a0d84c",
                                "recommendation": "<p>Open 'knock-out' hole- insert 'blank-out' cap to close off opening</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0b81cf8f-094f-4a63-b741-472392737ff3",
                                "recommendation": "<p>Panel is rusted inside</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "1f480dc9-31a4-4c2b-b25a-2a90dc322b3f",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Square D",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "General Electric",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Federal Pacific",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Push-O-Matic",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Cutler-Hammer",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Seimens",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wadsworth",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Murray",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Crouse-Hines",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "General",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "I-T-E",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Westinghouse",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Gould",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Challenger",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Sylvania",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Bulldog",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Varies",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "572897d4-b175-416c-b9e8-a74dd66ae5b5"
                            }
                        ]
                    },
                    {
                        "order": 11,
                        prompt: "Maximum Capacity:",
                        comments: [],
                        "line_uuid": "542bb828-399a-4216-b636-e409007c8d30",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "30 Amp",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "60 Amps",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "70 Amps",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "100 Amps",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "125 Amps",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "175 Amps",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "200 Amps",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "400 Amp",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "No single main breaker exists",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "No single main breaker exists, Split bus design",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "78332a6b-f3ac-461e-abb6-5608f4d67940"
                            }
                        ]
                    },
                    {
                        "order": 12,
                        prompt: "Main Breaker Size:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d3e668d3-6447-4e92-8762-c1cd4311e511",
                                "recommendation": "<p>Breaker undersized</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9fae9082-997a-4a5b-83ff-4768fe562d8f",
                                "recommendation": "<p>Corrosion on breakers</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d7ebf880-45bc-4076-ac3a-07940f10a4c9",
                                "recommendation": "<p>Replace breakers</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "c6133da6-c517-49de-a214-b0e0773dd589",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "30 Amp",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "60 Amps",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "70 Amps",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "100 Amps",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "125 Amps",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "175 Amps",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "200 Amps",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "400 Amp",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "No single main breaker exists",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "No single main breaker exists, Split bus design",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "89a0a0d3-8bc4-4fdf-8d27-fed747abb587"
                            }
                        ]
                    },
                    {
                        "order": 13,
                        prompt: "Breakers:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a08a5cd9-c03d-4904-bd9b-e72d987f41ff",
                                "recommendation": "<p>Breakers not rated for aluminum</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c0990136-bcc1-4b6a-b14d-3318c38f2279",
                                "recommendation": "<p>Corrosion on breakers</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "71741ee5-c6e4-4a64-a2c9-e9df9ca3ca65",
                                "recommendation": "<p>Double tap wiring</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "07d73a2f-a964-4ea3-b3c7-66b65c971910",
                                "recommendation": "<p>Improper breaker for application</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "eef23927-1efa-4ba9-a965-6e0bbb23c1b5",
                                "recommendation": "<p>Loose breakers</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d205827e-3d1a-4c5b-9cf2-b8510291eeb7",
                                "recommendation": "<p>Overheated breakers</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2d81d50b-b4a9-4fbc-9c8e-96b89bfca254",
                                "recommendation": "<p>Corrosion on breakers from water penetration-require replacement of affected breakers</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a6aae2a8-807d-4cde-a130-3ad83b6f09eb",
                                "recommendation": "<p>Double taps are present at breakers. These circuits need to be moved to their own circuit breaker and cannot share a breaker. It is recommended that a qualified electrician inspect the new circuits and properly connect the new circuits to an individual breaker for each circuit</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "37deeab7-ed79-46a1-8cfb-af271343fb0f",
                                "recommendation": "<p>Loose/missing wire retaining screw at breaker connection-requires correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "5e99a033-7d4e-4779-9a83-bfc31417b4a8",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper and Aluminum",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "150e742b-a889-421e-a3d3-3d9f056863cd"
                            }
                        ]
                    },
                    {
                        "order": 14,
                        prompt: "Fuses:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fc501a80-9fe5-464a-bf90-4124fbd88bdf",
                                "recommendation": "<p>Frayed cloth covered wiring present in fuse panel</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3b91c21d-46d8-4785-b4f4-8d3f0caeeba8",
                                "recommendation": "<p>Evidence of overheating- recommend further review of circuit by licensed electrician</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "35a62f33-a62c-419b-8bdd-c5e39e3de4ec",
                                "recommendation": "<p>Fuses blown- recommend further review of circuit by licensed electrician</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "62e858c6-5462-4fec-98bc-d0b055b1e4ed",
                                "recommendation": "<p>Improper fuse size for circuit- requires correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d4bd169f-795c-4f8e-b21a-c8dd563b2555",
                                "recommendation": "<p>Loose fuses- properly resecure</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6f019b98-b16f-4804-9113-43e21e175d86",
                                "recommendation": "<p>Loose wires- properly resecure</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "65d4b60e-08cc-4ae6-848f-1403f441333b",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Blade type",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Screw type",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "a22f215b-0cdd-457a-b6eb-77f8901e491a"
                            }
                        ]
                    },
                    {
                        "order": 15,
                        prompt: "AFCI:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f1c529ca-77cb-43cc-9c0b-9646cfb42caf",
                                "recommendation": "<p>AFCI does not trip when tested</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5aadc1fd-3eb1-4a79-b1b1-9b8da0980a64",
                                "recommendation": "<p>An evaluation by a licensed electrician is recommended</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1ad90225-aff3-481b-9f27-79e65a8cf986",
                                "recommendation": "<p>Loose connection</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "481d8929-2391-4715-9917-d6de1f048fa0",
                                "recommendation": "<p>Faulty operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "49fa4c60-7f34-4b3d-a77c-b092c67a041e",
                                "recommendation": "<p>Improperly installed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a331d531-cda0-4c15-b732-4aff522100cd",
                                "recommendation": "<p>Not present as required</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b7ea17c1-216e-41d2-8fcb-fa3163528bed",
                                "recommendation": "<p>Improper protection size </p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "25f772ac-7b75-43f3-9101-85d658643dc2",
                                "recommendation": "<p>Improper wiring connection</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9afe2fb2-a82d-48b3-8859-88c16406f6e6",
                                "recommendation": "<p>Improper socket tension</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bdc3a335-ceff-4309-8843-0906db938f71",
                                "recommendation": "<p>Loose or improper attachment</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "c3f42464-b053-4595-8493-40ac79eb15b8",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "110 volt",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "220 volt",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "a63fa291-1835-401f-889a-a29fc5dea93c"
                            }
                        ]
                    },
                    {
                        "order": 16,
                        prompt: "GFCI:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "07ae27f6-86e1-4889-8429-d7afa0945022",
                                "recommendation": "<p>GFCI does not trip when tested</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ebd5fad5-e275-4fc0-a995-34f576f88116",
                                "recommendation": "<p>GFCI not present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "aee1405e-9a61-4748-afdd-373e102b598b",
                                "recommendation": "<p>Improper wiring connection</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ac7945fa-1997-4dc5-a3b2-e8972ac7e482",
                                "recommendation": "<p>Improper socket tension</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1f472648-d95e-4ac5-9b5c-d010af6bb1de",
                                "recommendation": "<p>Loose or improper attachment</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c47abbdb-627b-4b5b-9e31-c64e6363e5af",
                                "recommendation": "<p>Evaluation by a licensed electrician is recommended</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "a831dc7c-f382-475f-874f-392d06588f83",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "At GFCI receptacles only",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Kitchen and bathrooms",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not present",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Basement, garage, kitchen, bathrooms",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Present",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "5330aa9c-2028-49e2-a17c-0d5d9df748a1"
                            }
                        ]
                    },
                    {
                        "order": 17,
                        prompt: "Is the panel bonded?",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "df12703c-53ec-4d0a-b37c-4333b0a0b4c0",
                                "recommendation": "<p>Panel bonding straps loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5f0be5fe-7c94-479a-bb39-a6570ef23e8a",
                                "recommendation": "<p>Panel bonding straps missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "5c5ef60a-d41e-4f76-bc7c-953df10374c7",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Yes",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "No",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "2d48645d-a305-4eec-ad3c-3727cabe7de8"
                            }
                        ]
                    }
                ],
                "order": 1,
                "is_deleted": 0,
                "created_by": 686,
                "updated_by": 686,
                "created_at": "2022-11-08T18:05:40.000000Z",
                "updated_at": "2022-11-08T18:05:40.000000Z"
            },
            {
                uuid: "baa56b5d-c689-4353-bfd3-907306c91102",
                prompt: "Structure",
                "introductory_text": null,
                lines: [
                    {
                        "order": 1,
                        prompt: "Structure Type:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "28608706-b5d2-4507-b721-2bab95ce06ca",
                                "recommendation": "<p>Evaluation of the structure by an architect is recommended</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0e0cc33a-e8b0-4800-ad7e-1ce57a965f9d",
                                "recommendation": "<p>Evaluation of this structure by a structural engineer is recommended</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7fae1703-3ee2-40d9-9328-543a2f8f6272",
                                "recommendation": "<p>Substandard building materials were used in the construction of this structure</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7442bfb8-d97f-49ae-9ffd-87e9503c6fb8",
                                "recommendation": "<p>Substandard building techniques were used in the construction of this structure</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "4b24b917-c3a2-4b90-bba9-d97a9d4e6d0e",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Wood frame",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Masonry",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Metal frame",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "dcb5398b-0484-4866-a074-20d4033b5948"
                            }
                        ]
                    },
                    {
                        "order": 2,
                        prompt: "Foundation:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "05ab2979-0f84-40cf-891e-2e4ff972ae93",
                                "recommendation": "<p>Cracked with displacement</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "297b0b96-bab8-4034-8ff8-9cb7b5b81d44",
                                "recommendation": "<p>Stair step cracks</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c7189961-4388-4707-801d-e5cf94d9d029",
                                "recommendation": "<p>Floor settled</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4cfc3922-e95a-4675-bd59-896dea2a1ed3",
                                "recommendation": "<p>Floor heaving</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9ceb3f52-ec08-4de6-9624-0afd9622106f",
                                "recommendation": "<p>A structural engineer is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "f4aa9193-2a57-4bdf-9a1f-cec25d1aa6de",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Block",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Poured",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Stone",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Earth",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Poured slab",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Other",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "78a0b0be-a656-4323-909c-dc3171c930a0"
                            }
                        ]
                    },
                    {
                        "order": 3,
                        prompt: "Differential Movement:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1df92e7d-a720-4b51-a622-40300f205438",
                                "recommendation": "<p>Cracks will require monitoring</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d6947878-f42c-4bb0-8248-a9c9b4171d95",
                                "recommendation": "<p>A structural engineer is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "fce192fc-4c1a-4fb0-ad95-61c84e2a4888",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Crack with displacement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Horizontal crack with displacement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Lateral crack with displacement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "No movement or displacement noted",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Stair step crack with displacement",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "4614e0b3-6d54-46ce-8421-96d9c2e1acdd"
                            }
                        ]
                    },
                    {
                        "order": 4,
                        prompt: "Beams:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c388914a-844a-4224-8e59-57488a53edc5",
                                "recommendation": "<p>A structural engineer is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ac67b80d-4240-46d0-a78d-ade5827e9c2c",
                                "recommendation": "<p>Beam not properly supported</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4470d3a6-dec3-4d11-89d7-6411d7624517",
                                "recommendation": "<p>Beams do not extend properly into the pockets</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "cc478f36-f24a-40d6-9a32-f6593c5eaf31",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Steel I-Beam",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Bonded wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Solid wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Laminated",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "597f4338-ca1c-47f6-be30-b56d202b785d"
                            }
                        ]
                    },
                    {
                        "order": 5,
                        prompt: "Bearing Walls:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "207d400e-6239-4e93-84df-93c82ce31cc1",
                                "recommendation": "<p>Bearing wall has structural damage and will require evaluation and repair</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3e562e5c-b969-41db-857e-bca039d742b5",
                                "recommendation": "<p>A structural engineer is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "91de9890-a663-4dfe-b577-a401280aa034",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Brick",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Block",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Frame",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rock",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "813711aa-bf90-43bd-9e0b-b94ed38e4914"
                            }
                        ]
                    },
                    {
                        "order": 6,
                        prompt: "Joists/Trusses:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "97b124b7-5355-40fa-be14-20269ca6f189",
                                "recommendation": "<p>Joists have been modified and will require evaluation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ad29b0d4-e72a-4ff9-a695-7d1d09a82a1f",
                                "recommendation": "<p>A structural engineer is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "7680394e-e0c4-43bb-88a5-bce4483c9d9b",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "2x8",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "2x10",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "2x12",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "e46ad67c-a320-47e4-92a9-8bf5a135064f"
                            }
                        ]
                    },
                    {
                        "order": 7,
                        prompt: "Piers/Posts:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "dddf4bef-7533-41da-998a-7ba81c2e90aa",
                                "recommendation": "<p>Posts improperly installed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bbfc04f8-1e65-4190-81ed-ca5ce1120b0c",
                                "recommendation": "<p>A structural engineer is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d86bcc0c-6b08-436a-845a-b3b088fe0d9b",
                                "recommendation": "<p>Improper post type for load.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "c3c845b0-313f-45b1-8de7-c3d81c593675",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Steel posts",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Poured piers and steel posts",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Poured piers and wood posts",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Block piers and steel posts",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Block piers and posts",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Block piers and wood posts",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "b0f60e6c-b191-4e78-b5e4-31675ce82a1c"
                            }
                        ]
                    },
                    {
                        "order": 8,
                        prompt: "Floor/Slab:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ae3da38f-05ca-4f52-bb47-cc9ff366ff79",
                                "recommendation": "<p>Floor has moisture damage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fd82db81-79f1-4742-97dc-9e89b2470349",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "bb48eeb0-c6c1-4970-aed9-a2890ec3e3a3",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Composite wood or plywood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Dimensional wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Poured slab",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "4730b691-4893-491e-a8d6-0a23a6bb0e31"
                            }
                        ]
                    },
                    {
                        "order": 9,
                        prompt: "Stairs/Handrails:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6f0e30b9-8ba6-4c6f-894c-fbb4ec86a642",
                                "recommendation": "<p>Handrails missing (Safety)</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7b8bdf4c-b636-4e66-9698-0f80bec18a64",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "ed95d54d-a693-4d41-a1a5-b413ad934f6f",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Wood stairs with wood handrails",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Metal stairs with wood handrails",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood stairs with metal handrails",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood stairs with no handrails",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Metal stairs with metal handrails",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "2ed83761-af1d-4f73-8831-6ecf33fc0b52"
                            }
                        ]
                    },
                    {
                        "order": 10,
                        prompt: "Subfloor:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a70a8c02-20d6-4f83-a97b-d046b13013f3",
                                "recommendation": "<p>Moisture damage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0de60bd0-b242-44f3-a5ee-8be67202a392",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e8da5b29-3f50-4c5d-baa3-08b1fd19aba2",
                                "recommendation": "<p>Loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ab222719-38ab-4c23-b7bf-51e4209ed385",
                                "recommendation": "<p>Squeak in flooring</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "05fb513f-b239-41de-9f28-68796d3303d9",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Composite manufactured materials",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Dimensional wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Plywood",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "4792efcf-8610-4b6f-ae05-672f0fb548fe"
                            }
                        ]
                    }
                ],
                "order": 1,
                "is_deleted": 0,
                "created_by": 686,
                "updated_by": 686,
                "created_at": "2022-11-08T18:05:40.000000Z",
                "updated_at": "2022-11-08T18:05:40.000000Z"
            },
            {
                uuid: "17929cfb-ab77-41e1-af0e-33ef3b4d1879",
                prompt: "Attic",
                "introductory_text": null,
                lines: [
                    {
                        "order": 1,
                        prompt: "Method of Inspection:",
                        comments: [],
                        "line_uuid": "f6704d5a-019a-4444-8e88-10faa1aacde2",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "From the attic access",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "In the attic",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "b965dfd7-330e-4415-b86b-cddc0b469ce1"
                            }
                        ]
                    },
                    {
                        "order": 2,
                        prompt: "Unable to Inspect:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a6e74398-0217-44be-809a-3f7277a9806b",
                                "recommendation": "<p>Roof line</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "65ad1018-caf7-45d4-926a-5018ee750d52",
                                "recommendation": "<p>Cathedral or vaulted ceiling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d2dce7a7-0216-42eb-b7b6-2c1d6287dde8",
                                "recommendation": "<p>Storage or personal items</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3985aa68-8a6a-428f-9468-ea76e8b32269",
                                "recommendation": "<p>Safety and footing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9ef602bd-9d10-43b4-aa56-787d920cdbdf",
                                "recommendation": "<p>No access or entry</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b2b63d1a-0dbc-4d95-82ca-e1ec4fd05504",
                                "recommendation": "<p>Insulation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "cf71b47c-cac0-4a81-98b2-f2274324ac2d",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "10%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "15%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "20%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "25%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "30%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "40%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "45%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "50%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "55%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "60%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "65%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "70%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "75%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "80%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "85%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "90%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "95%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "100%",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "511fe65d-08ae-46e1-9f99-f97ad05ca489"
                            }
                        ]
                    },
                    {
                        "order": 3,
                        prompt: "Roof Framing:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cfa25ed8-ce97-4752-8e83-bd2449368e07",
                                "recommendation": "<p>Water damage present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d355a5d5-aecf-4a11-bacb-bffb58e8bf8b",
                                "recommendation": "<p>Broken truss or rafter</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b02c367c-51d3-4aa2-a6f7-b02a57357f03",
                                "recommendation": "<p>Water stains present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8c3e1fb0-56b9-41b9-9f32-23867163e4ec",
                                "recommendation": "<p>A structural engineer is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "521408b8-bc11-46e2-9312-4504c12304e9",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "2x4 Truss",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "2x10 Truss",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "2x12 Truss",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "2x4 Rafter",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "2x10 Rafter",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "2x12 Rafter",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "2x6 Truss",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "2x6 Rafter",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rafter",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Truss",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "33c6245a-f4d6-42b3-9d93-d7e70e6a66fa"
                            }
                        ]
                    },
                    {
                        "order": 4,
                        prompt: "Sheathing:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "08e4c733-eddf-4215-9fff-380438a35130",
                                "recommendation": "<p>Water damage present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c410faf1-ac51-459c-bd1a-f0990a4ac3b1",
                                "recommendation": "<p>Water stains present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "25146f17-8bfe-4148-9d7f-cc4385639b7f",
                                "recommendation": "<p>Wood rot present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f4d62c30-92ad-476a-a833-162f13f24c6d",
                                "recommendation": "<p>Sheathing will require replacement due to damage or wood rot</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0dd9a8d4-043b-455e-b4fb-34e82bf8e550",
                                "recommendation": "<p>A qualified roofing contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ec9ce731-8d77-4139-9995-19eef920ae1a",
                                "recommendation": "<p>Sheathing does not extend down to gutter board and allows opening for bees birds etc.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "91f1c953-3a6c-48b8-b910-9aefdf263eb9",
                                "recommendation": "<p>Advisory note: Roof will require stripping of all layers of existing roof products including wood shake/shingles and new decking will be required increasing replacement costs when re shingling.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6559fd1a-56eb-4138-b4b5-5c835669e5f5",
                                "recommendation": "<p>Discoloring due to moisture</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "60aa3c3a-5363-4744-855f-f90dd46b616e",
                                "recommendation": "<p>Discoloring due to moisture from poor ventilation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "0394054d-2ef5-4984-9b91-21d706637b44",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Dimensional wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Particle board",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Plywood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Strand board",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "3e37a9ae-a1f0-45c2-bcc9-7bdb436fddbb"
                            }
                        ]
                    },
                    {
                        "order": 5,
                        prompt: "Ventilation:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "145678ec-9a3d-4ece-947f-6635ebad784f",
                                "recommendation": "<p>A qualified roofing contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "55d3aa4e-0030-4f33-baaf-bc7655d0bb64",
                                "recommendation": "<p>Recommend additional ventilation be installed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e4bc3488-d646-4521-94e0-2b411664342e",
                                "recommendation": "<p>Insufficient ventilation for size of structure</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4426294b-d657-4f2b-9917-232fb3f89029",
                                "recommendation": "<p>Vents covered or blocked</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "0299b33f-163d-4361-99d4-ae8b2ce6ca65",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Gable and soffit vents",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Gable, roof and soffit vents",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Roof and soffit vents",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Soffit vents",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Attic fan",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Ridge and soffit vents",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Turbine fan",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Roof only",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "f567d12b-7421-4c9e-a25f-4db26fa68922"
                            }
                        ]
                    },
                    {
                        "order": 6,
                        prompt: "Insulation:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c9c73b08-9418-4393-ac13-4b376e945716",
                                "recommendation": "<p>Insulation has been damaged by moisture.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5a0937c3-a49e-4950-9aab-a2b54f27b0d5",
                                "recommendation": "<p>Insulation compressed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9b8ee10e-5c91-4a95-8158-6a60f5aa7f14",
                                "recommendation": "<p>Insufficient insulation present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "896aa1fb-5f29-4974-818e-8e76cbbb0412",
                                "recommendation": "<p>No insulation present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d8b71ba4-003d-46de-b6e0-a165899505bb",
                                "recommendation": "<p>Recommend additional insulation be installed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8e7c0268-550e-4b7f-af86-8e7de5f0a72f",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7c4b7eae-6a17-4f1c-9b61-ae489b01939b",
                                "recommendation": "<p>Insulation batts have fallen out of position-properly reinstall</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "de4f1326-582c-4c76-ae7d-26182d8b8472",
                                "recommendation": "<p>Exposed kraft insulation vapor barrier, protect per manufacturer's recommendations</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "82c5335b-6d84-4de8-891a-641cef0ea770",
                                "recommendation": "<p>No insulation present at garage attic area</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6ef700f4-6a79-49b5-af31-03c9587b3f9d",
                                "recommendation": "<p>Loose fill altered, redistribute evenly</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "dfae3aa5-a1f4-4333-9dc7-cb4105b64e31",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Batts",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Blown in",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fiber fill",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rockwool",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Cellulose",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fiberglass",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Loose",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "ae34b0f2-ab10-49cc-8b47-3197ae11da4f"
                            }
                        ]
                    },
                    {
                        "order": 7,
                        prompt: "Insulation Depth:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7bd680af-597e-496e-81c8-a53b1dea4a2b",
                                "recommendation": "<p>Insulation has been damaged by moisture.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "73645aa9-71bf-4d84-9037-79660057e752",
                                "recommendation": "<p>Insulation compressed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a38fed8e-9ad3-4772-bd00-c652b4ea53ef",
                                "recommendation": "<p>Insufficient insulation present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4417e518-5ddf-48f1-a309-a03ee5b098a7",
                                "recommendation": "<p>No insulation present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "afcdf431-d65d-4d9e-8d9e-611f8a064429",
                                "recommendation": "<p>Recommend additional insulation be installed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1269ba9c-ede6-49f6-b6f1-1620270ea7c8",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "1d41c668-39ba-4d42-82c5-23465b444ce1",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "1'",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "3'",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "6'",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "8'",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "10'",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "12'",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "5bd91559-7438-4963-a9ca-cc68a72c4804"
                            }
                        ]
                    },
                    {
                        "order": 8,
                        prompt: "Vapor Barrier:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a4369bc6-c975-4cdf-ac53-09ea307c6c35",
                                "recommendation": "<p>Torn</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2946a107-7ac5-492a-be65-fcd5b4fa55f2",
                                "recommendation": "<p>Penetrations noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "467b043b-b834-411a-903a-efaa410ae3b1",
                                "recommendation": "<p>None present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "fde9dcda-7068-4d8b-a5d9-f278b9d2adaf",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Plastic",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Paper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "98a9fffc-f1f7-4e7f-bb98-30ebb545cd89"
                            }
                        ]
                    },
                    {
                        "order": 9,
                        prompt: "Attic Fan:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "88cc0ac3-9ece-4bbe-a72c-0c9ca5e0243a",
                                "recommendation": "<p>Broken or damaged belt</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5d86e153-a941-4aa9-b59d-9d78eaa5c22b",
                                "recommendation": "<p>Inoperative at time of inspection</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "270d875c-5b15-4669-9c35-78fbedb4dca1",
                                "recommendation": "<p>Improper wiring</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2c7e09cf-a59d-416a-a80a-2db76d26f0d0",
                                "recommendation": "<p>Loose mounting</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b3e86d20-d7b6-4edb-994f-0af2d9f07106",
                                "recommendation": "<p>Water penetration noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "53006406-1be8-4f94-b264-4aeb0aa6f471",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Direct drive",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Belt drive",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Thermostat controlled",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Humidistat controlled",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "0723b968-7531-40ee-8df9-00ae992bd501"
                            }
                        ]
                    },
                    {
                        "order": 10,
                        prompt: "House Fan:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8e59648c-6ccf-43e5-9ebb-33fc39fcf63e",
                                "recommendation": "<p>Inoperative at time of inspection</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f894a04f-09e7-46ea-acf2-bf4647c097c9",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fb547ce4-13e4-48f0-b961-678f78a834a5",
                                "recommendation": "<p>Broken or missing belt</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "77fa846c-1b80-486f-aba8-ca373aed9c7a",
                                "recommendation": "<p>Misaligned pulleys</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "c9cfacbf-57b1-4c4e-809a-9784d4ed40e9",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Belt drive with manual controls",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Direct drive with manual controls",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "7d05de3a-2ddf-4d5e-a21a-03d2dfad3c9d"
                            }
                        ]
                    },
                    {
                        "order": 11,
                        prompt: "Wiring/Lighting:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "af10ccc5-424c-48ee-a34c-c8e62e37af6f",
                                "recommendation": "<p>Improper routing of attic wiring</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "dfb906b3-ef25-4f19-87d7-23ea9b5cf917",
                                "recommendation": "<p>Exposed wiring</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7a62f2f9-8f21-4384-9f5f-1b315abe80bf",
                                "recommendation": "<p>Exposed wire splices</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f1ed624f-74c5-4755-b227-3183e47ce961",
                                "recommendation": "<p>Open junction boxes</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0e2c7484-6035-4f51-802e-8e2f5c3497df",
                                "recommendation": "<p>Evaluation by a licensed electrician is recommended</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "d987ad67-ae99-4d75-bfa8-43243a3d1bda",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "110 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC lighting circuit",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "a1bcf912-96be-460d-8107-1d7d134b9c35"
                            }
                        ]
                    },
                    {
                        "order": 12,
                        prompt: "Moisture Penetration:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "17ab500d-ae11-4af3-b46a-0cd7740e9566",
                                "recommendation": "<p>Moisture present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "abc9260d-0900-46f0-96a8-20bb70b7d112",
                                "recommendation": "<p>Mildew or moisture stains visible</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "12f7bc3c-fbd0-4d77-ae59-201e55d1dbd9",
                                "recommendation": "<p>Wood rot</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6a531bbd-2092-4ddd-a6f9-ff340de132f3",
                                "recommendation": "<p>Moisture damage present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2434b8bc-ddb4-4347-9dcc-1ae7717a3faa",
                                "recommendation": "<p>Moisture stains at type 'B' vent piping penetration</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "63246b55-29b0-4bdd-9ada-db7d3ca4ac82",
                                "recommendation": "<p>Moisture stains in attic insulation at plumbing vent penetration</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "45f7ec34-e95e-454c-be87-9540448f0939",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Ongoing water penetration noted",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Previous water penetration noted",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "5f1278d4-9059-4f5b-b07a-2024d3493ba2"
                            }
                        ]
                    },
                    {
                        "order": 13,
                        prompt: "Bathroom Fan Venting:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c60455e5-640e-496e-a52a-fc60de64ff6c",
                                "recommendation": "<p>Bathroom improperly vents into attic and may cause moisture damage to the insulation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e8ca3de5-bac4-48d2-bfa9-d370a89f4009",
                                "recommendation": "<p>The services of a qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "565c863d-d9d8-42f6-9ab3-885cc0e6ea96",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Electric fan",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "500b086f-4b72-480c-aa0c-a3d43dd9c1b4"
                            }
                        ]
                    }
                ],
                "order": 1,
                "is_deleted": 0,
                "created_by": 686,
                "updated_by": 686,
                "created_at": "2022-11-08T18:05:40.000000Z",
                "updated_at": "2022-11-08T18:05:40.000000Z"
            },
            {
                uuid: "3ea87574-3fa5-4472-9199-11d31b033a09",
                prompt: "Basement",
                "introductory_text": null,
                lines: [
                    {
                        "order": 1,
                        prompt: "Unable to Inspect:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a3c24ab8-3263-4ea6-bce1-99af5c6d1d7a",
                                "recommendation": "<p>Storage shelves</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f421e0f4-186f-4878-ac99-dc742ce6ab7e",
                                "recommendation": "<p>Access blocked</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "95b1e816-fb1f-4ad0-ac1f-47a7029c61ee",
                                "recommendation": "<p>Storage boxes</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "51515583-0040-4203-b356-c0dc41099509",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "10%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "15%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "20%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "25%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "30%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "40%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "45%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "50%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "55%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "60%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "65%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "70%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "75%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "80%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "85%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "90%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "95%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "100%",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "4b16fca8-4d7c-4288-bcb7-ab114e463326"
                            }
                        ]
                    },
                    {
                        "order": 2,
                        prompt: "Ceiling:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "50a90216-79a7-4150-a407-6aea69067f9a",
                                "recommendation": "<p>Cracks present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5734694c-524e-4aba-98d5-ef40cd3ecc20",
                                "recommendation": "<p>Loose or peeling paint</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "18a61a79-2383-4df3-8424-d67479a2fac3",
                                "recommendation": "<p>Loose paneling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6547ddb2-95ad-4693-895f-25b389c64de5",
                                "recommendation": "<p>Loose plaster</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7023d68a-1334-441b-99e6-ae37cdb87a57",
                                "recommendation": "<p>Water stains present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "be5d26e7-e2a0-4434-a2c3-be66666508b2",
                                "recommendation": "<p>Stress cracking, see structural notes</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "407707c7-6638-4405-a232-d5b451b28ab3",
                                "recommendation": "<p>Evidence of possible truss uplift-repair as required, monitor for further movement</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2d4ae792-884b-4ef2-be40-4cfd8e1add0e",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "301c0bc5-af2a-4023-bd0f-2a58451196f1",
                                "recommendation": "<p>Mold is present and is a potential health concern. Removal and/or correction is required.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "98afe72e-8246-4a21-94fe-0e220dca1271",
                                "recommendation": "<p>Evidence of past or present water staining</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "f5544653-24be-4031-90eb-3e7a62bd54ce",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Paint",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Exposed framing",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Mirrors",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Texture paint",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Suspended ceiling",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "4e2cc328-0546-41af-8bbb-1dee4eb591aa"
                            }
                        ]
                    },
                    {
                        "order": 3,
                        prompt: "Walls:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1b4aa0f7-d065-4c49-b3b3-821ad7685a7f",
                                "recommendation": "<p>Cracks present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e3999842-97b6-474f-a756-92349dbcbfc4",
                                "recommendation": "<p>Evidence of past or present water leakage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ae35d555-47a9-430c-a13e-a7fb3a01b560",
                                "recommendation": "<p>Evidence of possible truss uplift</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "06500b18-f33d-4024-a2ba-8a8555b55929",
                                "recommendation": "<p>Loose or peeling paint</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b200c71b-1609-4a1a-a54f-36116cd1fa92",
                                "recommendation": "<p>Loose paneling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "26a8c293-9dd9-4612-b385-fb97b1dbedc9",
                                "recommendation": "<p>Water stains present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "06ad0326-f921-40bb-8c79-dbb7bb0d4362",
                                "recommendation": "<p>Evidence of past or present water staining</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ce453b4c-6019-456c-b658-aa09e3c2fda1",
                                "recommendation": "<p>Loose or missing tile</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f559b460-0579-43d7-9cda-926be805fae6",
                                "recommendation": "<p>Mold present-potential health concern-needs removal/correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e36cbf4b-65e5-499e-a08f-9398baf0609e",
                                "recommendation": "<p>Paneling loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "92e95990-d9be-4c02-8c80-555b03f01404",
                                "recommendation": "<p>Some ceiling pads damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0890700d-f1e6-4c27-b042-febe6c9eb6bb",
                                "recommendation": "<p>Some evidence of previous truss uplift</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d6a31a43-dbbe-4ac0-9702-7a1c93493237",
                                "recommendation": "<p>Inspection does not cover any damage concealed by rugs, carpeting, wall paneling, furniture</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9e57921e-bbb1-457b-a347-f7b9bdaf0d2b",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "8460f2d5-e0e7-45fd-88d5-a95a7e257bfa",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Paint and paneling",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Exposed framing",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Paint and tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wallpaper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Paint",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Concrete",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wallpaper and tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fire rated",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "8e34c0f7-530b-4090-8aa5-aa24c8a84c4f"
                            }
                        ]
                    },
                    {
                        "order": 4,
                        prompt: "Floor:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c8564719-378d-4aac-87a9-4fc92bf068d1",
                                "recommendation": "<p>Broken floorboard</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e88dbe56-63d2-42f4-b07d-4e7e0caa7ced",
                                "recommendation": "<p>Damaged carpet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4396878e-4f12-46e3-b331-0d57c9d6e484",
                                "recommendation": "<p>Loose carpet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "82453b52-476e-4d0f-bbf0-d3939ebfe6e5",
                                "recommendation": "<p>Loose flooring</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d30b7d2d-e721-43bd-acdf-15b796400131",
                                "recommendation": "<p>The floor squeaks when walked on</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e4b615e9-ee4a-4fe8-86e8-f19da668c9ea",
                                "recommendation": "<p>This inspection does not cover any damage concealed by carpeting, rugs or furniture</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0f84a743-bd36-4c45-9baf-633ee984d424",
                                "recommendation": "<p>Water damage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7468f9b7-6d05-42b3-be80-7952f08d0531",
                                "recommendation": "<p>Noted uneven floor due to settlement</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b23afd72-c5d6-477e-8ff0-eff4ecb38309",
                                "recommendation": "<p>Carpet Stained/soiled</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b368144a-9401-43e3-81ac-da287b344767",
                                "recommendation": "<p>Worn wood floor finish</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ce8d7afa-c579-4e5c-91b0-535fabfd1695",
                                "recommendation": "<p>Cuts or minor damage in flooring, repair/replace as required. Restretch carpets- trip hazard</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9d23c4e6-e969-4f51-850a-9f99d359c68f",
                                "recommendation": "<p>Floor shows evidence of previous water penetration/stains</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2cc6550f-34d3-4cab-9bfb-f1732ee3e06f",
                                "recommendation": "<p>Worn carpets</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d085a88f-9a65-40a6-83f3-acefe86852cc",
                                "recommendation": "<p>Termite damage noted in wood flooring, repairs required</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e393bf29-d651-4f52-82d2-375f4fdd7aae",
                                "recommendation": "<p>Gapping in hardwood flooring noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ee995945-edf8-4ac4-851e-142bdf13e3f6",
                                "recommendation": "<p>Floor squeaks noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "642a0792-a535-465f-ab54-65b83d8607d5",
                                "recommendation": "<p>Raised seams in vinyl flooring-repair</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c7338fba-287d-46d5-9879-88c0cbbfd147",
                                "recommendation": "<p>Uneven wood floor finish (difference under carpets/rugs) due to sunlight</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "eac47814-7a6d-4e90-b6fc-6630383cae50",
                                "recommendation": "<p>Loose tile/grout, repair as required</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "3774025d-a5d7-48e3-8ad4-53c836d2737a",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Hardwood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Ceramic tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Linoleum",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Carpet",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl floor covering",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Poured",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "dadc19e3-1cd1-487d-8816-97e9572f025f"
                            }
                        ]
                    },
                    {
                        "order": 5,
                        prompt: "Floor Drain:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cb30a84a-505d-4f7f-a2a7-9c4e49319955",
                                "recommendation": "<p>Blocked by debris</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d0068f17-f9f4-4d21-984b-a0cff7404f90",
                                "recommendation": "<p>Evidence of poor drainage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6b3c48c5-67da-4815-b201-2806d1d37580",
                                "recommendation": "<p>Water pooling with stains visible</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "66550f5d-5488-4f9f-8b24-945f9faa1297",
                                "recommendation": "<p>Undersized for surface area</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7a0676a3-e6e2-4356-aa10-0cc141d07bac",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "8b67c300-d832-4e75-bf41-a18566f3bfb7",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Covered",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "French drain",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Surface drain",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not visible",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "e3ae3e2e-07be-4a4f-82c6-1cb565106230"
                            }
                        ]
                    },
                    {
                        "order": 6,
                        prompt: "Doors:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1faf02d7-93a2-4a96-9149-909ad334ed2f",
                                "recommendation": "<p>Loose hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "277656c0-3e9a-474d-a07f-eeb3ae3d3981",
                                "recommendation": "<p>Missing hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2e878114-d61d-473c-af9f-1526721c0fe4",
                                "recommendation": "<p>The door has moisture damage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "900560cc-4c7f-4b26-9f35-c5c4440653d4",
                                "recommendation": "<p>The door is sticking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7092a0c4-0fab-4cf5-a34c-e109e1f78b3e",
                                "recommendation": "<p>The surface laminate is peeling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "84323993-5100-4b14-8a39-872d62c624eb",
                                "recommendation": "<p>Wood rot</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9bbf3b36-29fe-4294-abc3-5ce787f1fb81",
                                "recommendation": "<p>Lockset(s) not latching-requires adjustment</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a21003ee-63c8-4ad8-9f13-6747601f6b96",
                                "recommendation": "<p>Door(s) has minor damage from door stop-repair/replace</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "489ef721-e821-4e70-a543-8e5d42062649",
                                "recommendation": "<p>Lockset(s) not functioning-repair</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fb161010-41f6-41c3-b510-da42c1c23285",
                                "recommendation": "<p>Replace missing door(s)</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cac4acdf-8eb0-41c1-96c9-1c5fb20eaa73",
                                "recommendation": "<p>Door damaged by moisture</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "61d141b0-1844-498c-b420-662f6913a019",
                                "recommendation": "<p>Doors need trimming/adjustment</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d78064e9-6d19-472d-952f-7b97eaf61c86",
                                "recommendation": "<p>Replace missing bypass hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f6a42703-1d17-4d14-9296-d514c5c05363",
                                "recommendation": "<p>Lockset hardware installed opposite with locking knob to outside of room-needs correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a7ecaaa7-7f4a-4679-beb8-27c15718645d",
                                "recommendation": "<p>Align/adjust closet doors</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d3fde00c-93ed-4d1f-ad55-4a24f8dee2ae",
                                "recommendation": "<p>Undercut where rubbing on carpet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "a80eb892-4c05-47ee-b370-5c5342a4b4c6",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Hollow wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "French",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Bi-fold",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Solid wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Dutch",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "f201e397-79c7-4d2b-b5c7-ae458531a5b3"
                            }
                        ]
                    },
                    {
                        "order": 7,
                        prompt: "Windows:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1ac57071-137d-44a0-b795-9e38645cf867",
                                "recommendation": "<p>Window seal broken or damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b9a927fe-f8dc-40f7-8f7a-816284076e36",
                                "recommendation": "<p>Window lintels rusting</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8780a1e6-4bda-4aa1-a458-bd084608a6f6",
                                "recommendation": "<p>Cracked, broken or missing glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "22fd3577-daf0-4cdd-b5c2-9e208690f0ec",
                                "recommendation": "<p>Will not open</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "43085f99-d51b-4474-832a-0a61a69b2f61",
                                "recommendation": "<p>Hard operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e260adb2-ec4b-4b60-8a74-0d8b155a3cf9",
                                "recommendation": "<p>Sash cords broken or missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "efd37d11-e110-4bb9-87b5-9f6e21784ee2",
                                "recommendation": "<p>A qualified glazier is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "7f44f1bc-f0d3-4825-94ab-8e898645f781",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Aluminum slider",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Aluminum double hung",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Non-opening",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood double hung",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Aluminum casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl double hung",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl slider",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steel casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steel frame",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood slider",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "5d3506ee-f8ad-43b8-843f-5fb0fce61b53"
                            }
                        ]
                    },
                    {
                        "order": 8,
                        prompt: "Electrical:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0af1eec9-0630-44ce-a076-1d1b244993b9",
                                "recommendation": "<p>Outlets not working</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "359b82b7-0403-4da5-9e4f-25f28529d3bc",
                                "recommendation": "<p>Reversed polarity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3e87ee19-d477-4afc-b4ce-d85502e501b1",
                                "recommendation": "<p>A licensed electrician is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3b29a0f4-1c59-47aa-8b76-33ede2bd7cb4",
                                "recommendation": "<p>Ceiling fan inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b82ff5ec-a723-46fc-a0a4-0fecaf3e0d07",
                                "recommendation": "<p>Insufficient outlets for space</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f67a3289-8f59-4458-b8bb-6458f10e7b51",
                                "recommendation": "<p>Open ground</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "091e8a11-3c68-42f0-a748-08b799157a3d",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "110 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC GFCI",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC/220 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "220 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC outlets and lighting circuits",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "bc6f15c7-0d0c-425a-aa8a-7c1635c9a330"
                            }
                        ]
                    },
                    {
                        "order": 9,
                        prompt: "Smoke Detector:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d04d4bd9-06ab-47d6-af14-2ccc3803e6d7",
                                "recommendation": "<p>Evaluation by a fire alarm certified licensed electrician is recommended</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5bbc33c6-768f-4b8e-8656-c388e3a9365d",
                                "recommendation": "<p>Not tested due to lack of electricity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bcc58eb6-aa9f-4aab-8360-754b713804b3",
                                "recommendation": "<p>Battery missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f30a39df-4d54-48f4-9a70-1f20373a7c70",
                                "recommendation": "<p>Improperly wired</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "96453965-22ef-4a6e-8b90-bf04499356bf",
                                "recommendation": "<p>Inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "277567ab-2bbf-4d84-a970-4afb64297327",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Battery operated with light",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Battery operated",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hard wired with battery back up and light",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hard wired",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hard wired with battery back up",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "7aa57848-8dee-4965-9e48-3d45710d806d"
                            }
                        ]
                    },
                    {
                        "order": 10,
                        prompt: "HVAC Source:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f7ac6b5b-e6f8-45ed-a604-ece76dda8d34",
                                "recommendation": "<p>No heat ducts</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9995771e-5f18-4783-871d-8301133bfcd2",
                                "recommendation": "<p>Heating system inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a8b65c6b-3124-476d-91e6-ef6012eedfb9",
                                "recommendation": "<p>Low air flow</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b6bb539d-6519-4982-8c8d-84477da64935",
                                "recommendation": "<p>A qualified heating system contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "72d775e5-967d-4704-9057-964c57eff570",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Heating system register",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Convection baseboard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Air exchange ventilation",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Radiant heat",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "3daa0390-16c8-4115-b275-4367b302631d"
                            }
                        ]
                    },
                    {
                        "order": 11,
                        prompt: "Vapor Barrier:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "854ec058-d9f4-44e9-a74a-8324f98cd1d1",
                                "recommendation": "<p>Torn</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "071cd261-59b4-46b7-8c35-cacceaec6bb2",
                                "recommendation": "<p>Penetrations noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ca254fd2-d0c7-444d-bcad-44339b7d6242",
                                "recommendation": "<p>Missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "6f857e9a-7c52-4dbc-b4f6-a4d12d7376c9",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Plastic",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Paper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "652ab7d5-5978-4e6f-a973-83e414e29b21"
                            }
                        ]
                    },
                    {
                        "order": 12,
                        prompt: "Insulation:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ad079489-4300-4e55-a6b1-6238ea35da2a",
                                "recommendation": "<p>No insulation present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8645a202-1677-45e4-8daf-fda5ddba336e",
                                "recommendation": "<p>Insulation is loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5be00fdb-1731-4354-901e-217f2819ca19",
                                "recommendation": "<p>Insulation damaged due to moisture</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c92ee2f3-68a5-483c-827d-8d870b3ac72d",
                                "recommendation": "<p>Improperly installed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2c2fb2f8-2030-4e5f-b44b-bf5a0ec14c28",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "1a6f74de-79f4-42c7-99ee-10ae3d3c0e03",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Batting",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fiberglass",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Styrofoam",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Celotex",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "cd2c8c31-583d-4893-93ed-cede34a5fdc0"
                            }
                        ]
                    },
                    {
                        "order": 13,
                        prompt: "Ventilation:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e87c825d-1100-46c4-9839-8e99376a3b3e",
                                "recommendation": "<p>No ventilation present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "452895f7-e990-4a5b-ac8a-d35018e65905",
                                "recommendation": "<p>Inadequate ventilation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "64abe0ff-0dc9-4c60-9eac-a6b8708fbd57",
                                "recommendation": "<p>Vents blocked</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5113c32c-bf6d-4a2f-ac3b-87827c163b2f",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "d4f10c4a-fca0-4539-804b-872472b1e015",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Windows",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vents",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "29993d86-2825-4c70-88d3-8576b1371490"
                            }
                        ]
                    },
                    {
                        "order": 14,
                        prompt: "Sump Pump:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cb41d830-fa3d-4a9d-888a-f61113c194af",
                                "recommendation": "<p>Improper wiring</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "950ef61d-d790-4dee-8319-da011a0a6e15",
                                "recommendation": "<p>Sump pump not connected to dedicated circuit</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5d2c16d8-4abd-49b4-a14d-d229b826ff31",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "246987c8-4c5c-40e4-98f7-72e832f2f939",
                                "recommendation": "<p>Disconnected from electric source</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "610da60e-fd0e-48c1-a304-cab69fbcac2b",
                                "recommendation": "<p>Drain line not properly connected or not draining away from the foundation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "debfdc57-cedf-469d-a9b7-b223ada55b49",
                                "recommendation": "<p>Inoperative at time of inspection</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "011c193c-b06a-4f00-af4a-dd052418e247",
                                "recommendation": "<p>Extension cords present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "be4a2809-80a9-4386-9ac4-26c520db318b",
                                "recommendation": "<p>Not tested due to sealed radon installation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1df9b76f-ada3-4f54-88bb-3b9dd199de4d",
                                "recommendation": "<p>Recommend installation of water-powered or battery powered back-up sump pump to protect basement finishes</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1e181195-e470-451b-a875-e622e33746a0",
                                "recommendation": "<p>Battery powered back up sump pump to protect basement finishes is installed but not tested. Testing is not within the scope of this inspection.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b2810fdb-d432-42c1-ba6c-c3572966f243",
                                "recommendation": "<p>Razor cut back corrugated drain tile piping so as not to dump onto sump pump float. (may not allow pump float to function and cause backup of storm water)</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9e1cea9a-ad73-407b-8b0a-960fea6ccf77",
                                "recommendation": "<p>Leaking at discharge piping (inside basement), repair leak</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7cd34bf6-f9f3-4c39-84e7-e7ced02f8fdd",
                                "recommendation": "<p>Clean out debris from crock-may restrict operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1a289f65-533d-49a8-91c6-e9d8288cb9b1",
                                "recommendation": "<p>Properly position sump pump so as not to be obstructed and unable to operate</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "59069466-9973-46ce-9c98-1bfc93e163f3",
                                "recommendation": "<p>Sump crock was completely dry/empty-unable to test pump operation/piping for leaks</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "4ea8e6cd-b066-4fd7-8cf5-f3bd82e3c7a1",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Pedestal",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Submerged",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "afa12a50-88f7-495e-a864-b7bb571759d6"
                            }
                        ]
                    },
                    {
                        "order": 15,
                        prompt: "Moisture Location:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a15d8a19-a9e3-405c-9294-5044f3c89311",
                                "recommendation": "<p>Water stains present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e9cee518-7f92-4466-aae1-56582c2054e0",
                                "recommendation": "<p>Pooling water</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b3d7336c-dd4a-4a92-acaa-e04033f920c3",
                                "recommendation": "<p>Mildew present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d9ceb18e-48d3-4d55-a7a7-0d499e23f9f3",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "d9c9fd7c-097a-4c94-a787-c2b50421d0ee",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Near the entrance",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Under the entire home",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Southern half",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Northern half",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Eastern half",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Western half",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "c417bd47-7116-412a-a31c-f1a9f62c8d7c"
                            }
                        ]
                    },
                    {
                        "order": 16,
                        prompt: "Basement Stairs/Railings:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7222d79d-b917-410a-a125-f276b310ba43",
                                "recommendation": "<p>Loose railing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9337c730-dd2d-42c3-a34d-c3757268633a",
                                "recommendation": "<p>Missing railing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "60a605d2-e032-40af-b3d4-ab037b5c884a",
                                "recommendation": "<p>Improper baluster spacing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d1cf3e06-bde9-4b1d-be21-93b86e7262e7",
                                "recommendation": "<p>Loose step</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "58179cb8-691a-460b-92c7-ea9f94e47d39",
                                "recommendation": "<p>Improper riser height</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e80772f7-cc0e-42dc-b336-004bdd61af43",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "1daec78a-448c-417d-ba9f-96d056612a43",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Wood stairs with wood handrails",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Metal stairs with wood handrails",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood stairs with metal handrails",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood stairs with no handrails",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Metal stairs with metal handrails",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "1f8c97da-16a6-4b2e-96a6-09920b1ffdf6"
                            }
                        ]
                    }
                ],
                "order": 1,
                "is_deleted": 0,
                "created_by": 686,
                "updated_by": 686,
                "created_at": "2022-11-08T18:05:40.000000Z",
                "updated_at": "2022-11-08T18:05:40.000000Z"
            },
            {
                uuid: "bdf25030-3688-427c-9968-c69d4c43b977",
                prompt: "Crawl Space",
                "introductory_text": null,
                lines: [
                    {
                        "order": 1,
                        prompt: "Method of Inspection:",
                        comments: [],
                        "line_uuid": "a67e442d-b91c-4589-9f4a-cbb17d276835",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "From the access",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "In the crawl space",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "506aa0c8-273d-484f-a24e-fa45930b9ba9"
                            }
                        ]
                    },
                    {
                        "order": 2,
                        prompt: "Unable to Inspect:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7729bf0f-58dc-45da-9292-bf8a90e9d9d3",
                                "recommendation": "<p>Access blocked</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c1287d22-1529-4434-87df-799b588b334d",
                                "recommendation": "<p>No access</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "53fc82d6-80f1-491b-b734-cdcbd91b4e49",
                                "recommendation": "<p>Storage boxes</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d6f72089-65a2-4774-9bc1-cd1c4aa57b0c",
                                "recommendation": "<p>Storage shelves</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c489293d-1783-43cd-b020-eb68df1d3b1f",
                                "recommendation": "<p>The crawl space was not inspected. Be advised that because we could not enter the crawlspace, hidden problems may exist that are not documented in this report. It is recommended that after obstructions are removed a full examination of the space be made prior to commitment.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "f1301fa5-f896-404a-96fe-47bdf6f00195",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "10%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "15%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "20%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "25%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "30%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "40%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "45%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "50%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "55%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "60%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "65%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "70%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "75%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "80%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "85%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "90%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "95%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "100%",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "4a0296d6-9355-4582-9610-f322d35e34eb"
                            }
                        ]
                    },
                    {
                        "order": 3,
                        prompt: "Access:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1bffd581-0c76-47f0-968e-93a19724f142",
                                "recommendation": "<p>Not accessible</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5a6f8f28-ae58-4998-b985-c65509b761e0",
                                "recommendation": "<p>Blocked by HVAC ducts</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bd33591d-b87d-444b-9acc-5b34567b8fff",
                                "recommendation": "<p>Blocked by plumbing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "558f2ae2-9de4-4940-9bf7-c405d32a6f1f",
                                "recommendation": "<p>Access too small</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "d8d1db68-fced-49d8-ac6c-dea2456d3720",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Open",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Small",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Partially blocked",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood door",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Window",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "No access",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Nailed shut",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "82415d34-464e-46be-9484-262f2fbe85f8"
                            }
                        ]
                    },
                    {
                        "order": 4,
                        prompt: "Moisture Penetration:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b5421b79-26a5-4af1-9e39-005f6bf46120",
                                "recommendation": "<p>Water stains present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "79d05b40-be53-457b-ae83-60429771311a",
                                "recommendation": "<p>Pooling water</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0f836d2c-4dde-4cba-88fa-8fd6629cae76",
                                "recommendation": "<p>Mildew present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b6f74fb9-2e69-4aa2-90ab-9092e2b5eb14",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c174ce99-a52e-4b5c-9073-12a00bdc8370",
                                "recommendation": "<p>High humidity present at time of inspection. Readings in excess of 50% humidity are conducive to mold growth. Mold testing is recommended.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "f76b10f1-d5df-4fc7-9c4b-e5b604c9cad1",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "No moisture present at time of inspection",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "8462ea9e-b219-4ba8-ba4e-1eba571e1458"
                            }
                        ]
                    },
                    {
                        "order": 5,
                        prompt: "Moisture Location:",
                        comments: [],
                        "line_uuid": "40955b8a-c1d2-4853-87e7-e929aec37470",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Near entrance",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Under entire home",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Southern half",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Northern half",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Eastern half",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Western half",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "894d3f5f-d086-4c83-b809-47fbfe44ab18"
                            }
                        ]
                    },
                    {
                        "order": 6,
                        prompt: "Moisture Barrier:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "32ed5498-2695-4e94-8ec5-ed5d8e1ffc1f",
                                "recommendation": "<p>Plastic moisture or vapor barrier torn</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2f5e3965-825a-4421-9ae1-e506369ff71f",
                                "recommendation": "<p>No moisture or vapor barrier present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "92385936-bb07-4a7e-9206-6f3e3a0735f4",
                                "recommendation": "<p>Poured floor in crawl</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8f17cc56-6fa8-4e07-aee3-38f06f9e9db3",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1351962b-e023-4dd5-8859-89d08e9f2e41",
                                "recommendation": "<p>Penetrations noted in barrier</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "f45021c4-67ee-424f-a6bd-9432863c51fa",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Plastic under gravel",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl covering gravel as part of a radon mitigation system",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "f0aeb2e9-0196-4ae4-bc15-2b33e68101ec"
                            }
                        ]
                    },
                    {
                        "order": 7,
                        prompt: "Ventilation:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4223260d-ef53-4138-a7e0-828d320ae61b",
                                "recommendation": "<p>No ventilation present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4ac110b5-1ed6-4708-8e3f-712a6d574fa3",
                                "recommendation": "<p>Inadequate ventilation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c79695b1-062b-44ac-810d-9f01025efaf9",
                                "recommendation": "<p>Vents blocked</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "339d334c-ffaa-405e-b27e-3acd1ed2e521",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "4583f14b-e0b9-4181-9878-22982e886434",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Windows",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vents",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "0c73fa11-9901-45f6-96bb-36670b46303d"
                            }
                        ]
                    },
                    {
                        "order": 8,
                        prompt: "Insulation:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4c80ebcb-d34b-4424-b79f-61dd1f1e0eb3",
                                "recommendation": "<p>Insulation missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "78821a2c-2bec-4e4c-9ffe-a576ba85a2b9",
                                "recommendation": "<p>Missing in areas</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "52b68f74-74a1-48fa-b9cf-1527d47734ee",
                                "recommendation": "<p>Improper installation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "ab9a1158-2c81-4293-a334-6eed8209c948",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Fiberglass",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Styrofoam",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rigid polystyrene",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "db935fba-065b-454d-ad32-62eb60473db8"
                            }
                        ]
                    },
                    {
                        "order": 9,
                        prompt: "Vapor Barrier:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bd9c5e45-3688-4e85-91e7-ec20d5458fd4",
                                "recommendation": "<p>Plastic moisture or vapor barrier torn</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cfc1cec9-9d75-4559-beda-31eddd7d5074",
                                "recommendation": "<p>No moisture or vapor barrier present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "46a9c262-f982-4d03-926b-909d0c9f10be",
                                "recommendation": "<p>Poured floor in crawl</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bfdb3c6b-f415-4ffa-80e7-96c623d93c12",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e3ec4df3-f58c-4edd-abf4-f04f846dcbaf",
                                "recommendation": "<p>Penetrations noted in barrier</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "82da388a-cd0d-49e9-a5b8-38f5f4d244aa",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Near entrance",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Under entire home",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Southern half",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Northern half",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Eastern half",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Western half",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "66762524-c64e-491e-ad1a-79772f5708d6"
                            }
                        ]
                    },
                    {
                        "order": 10,
                        prompt: "Sump Pump:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "383b8f24-b1fb-4a56-9ed2-e992c70b1806",
                                "recommendation": "<p>Not tested due to sealed radon installation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a419667d-9f7e-4451-b44f-709bccd1ee95",
                                "recommendation": "<p>Drain line not properly connected or not draining away from the foundation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f56aee78-a7c3-4af0-8d77-c8894a4747e9",
                                "recommendation": "<p>Inoperative at time of inspection</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fab0ec49-8915-47a0-a690-af371f8fd120",
                                "recommendation": "<p>Leaking at discharge piping(inside basement)-repair leak</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d5b29d7a-841a-4341-949e-5a50ce1f6e69",
                                "recommendation": "<p>Sump pump not connected to dedicated circuit</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d2c9c0b7-ea7d-4bdc-a093-fda39e9ba81c",
                                "recommendation": "<p>Clean out debris from crock-may restrict operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9c1db8d4-1a25-4910-a2ca-c6c414521807",
                                "recommendation": "<p>Properly position sump pump so as not to be obstructed and unable to operate</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bc02ec54-f859-4017-bb8c-a339b15a4b43",
                                "recommendation": "<p>Sump crock was completely dry/empty-unable to test pump operation/piping for leaks</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "243790a0-624b-4b1a-829c-ac9948253f9e",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d0075cf5-ab2c-4604-9c0e-41b0f48e07e7",
                                "recommendation": "<p>Disconnected from electric source</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8ed541ae-6163-4a30-b440-9fae71c9b092",
                                "recommendation": "<p>Improper wiring/Extension cords present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "433a854f-9e6d-4525-be03-437a2941ab98",
                                "recommendation": "<p>Sump pump used for gray water from laundry and floor drain due to 'hung sewer' main</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5a574a4c-8679-4a1e-b7d8-4ab0b287a3a7",
                                "recommendation": "<p>Recommend installation of water-powered or battery powered back-up sump pump to protect basement finishes</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4025ef84-74f9-489b-af05-11fe5997bcb7",
                                "recommendation": "<p>Installation of battery powered back-up sump pump to protect basement finishes is installed but not tested-not part of inspection scope</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "af23b0da-1269-45d4-affd-3d244b98868a",
                                "recommendation": "<p>Razor cut back corrugated drain tile piping so as not to dump onto sump pump float. (may not allow pump float to function and cause backup of storm water)</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "0ad5a1a6-6883-4297-afc8-7dd33a7a02a9",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Submerged",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not Present",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Pedestal",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "4c080a1b-98b5-4394-bdae-74b69ddf78d2"
                            }
                        ]
                    },
                    {
                        "order": 11,
                        prompt: "Electrical:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9048e09e-1c7f-4a9c-8ec4-cf4707270e78",
                                "recommendation": "<p>Open or missing ground</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0df56323-992e-4fa7-b098-34a9ab854e66",
                                "recommendation": "<p>Suggest evaluation by licensed electrician</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d6adf20f-6da8-417b-aa3a-5626b1638b4a",
                                "recommendation": "<p>Non-GFCI circuit</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d392d2b1-4005-4c1d-901b-10d1e340088c",
                                "recommendation": "<p>Reversed polarity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "399ac087-ebc1-41a9-95d6-bcf9215146a3",
                                "recommendation": "<p>Install missing junction box cover plates</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3b8a7992-6048-4ed8-9069-6737b4bf9cf1",
                                "recommendation": "<p>Install missing junction boxes and cover plates at open splice connections</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9a23f182-d840-4c80-955d-9303a58cac83",
                                "recommendation": "<p>Protect exposed surface mounted wiring in conduit to 7'-0' above floor</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4aa53fff-a78d-4ca2-ac02-17add840ad6a",
                                "recommendation": "<p>Faulty GFCI outlet-replace</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "71fdbd89-4498-4e3b-8ee5-571eaad2b6b9",
                                "recommendation": "<p>Reversed polarity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f11e5159-984a-4e88-a6fd-15feaccfaac1",
                                "recommendation": "<p>Missing outlet/switch cover plates</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "020f0397-212a-4875-81a3-1fa5cf772c10",
                                "recommendation": "<p>Loose switch or outlet should be resecured</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5d801446-53a1-471b-91e2-4183dce783c7",
                                "recommendation": "<p>Ceiling fan inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "109e19e5-54c6-4031-b2bc-51a883b6a06f",
                                "recommendation": "<p>Worn outlets creating intermittent ground- replace outlets</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8210d1be-3896-440e-931c-192cde502c04",
                                "recommendation": "<p>Light fixture not functional-repair/replace</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "56343df3-45f4-44ed-9b28-0c3f701585e6",
                                "recommendation": "<p>Open grounds noted at 3 prong outlets</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4884c001-7cbd-4e31-9599-3f5f306f93b1",
                                "recommendation": "<p>Remove temporary wiring running through walls -amateur installation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "eb880018-b1d2-4eed-a2de-a9abc5c9f9f2",
                                "recommendation": "<p>Outlets not working</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b17a051c-13f7-43d1-852c-7e0b74b5eb17",
                                "recommendation": "<p>Non-GFCI circuit -recommend GFCI circuit be installed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7f489881-7313-4e7a-9668-3f2749fa1d9f",
                                "recommendation": "<p>Insufficient outlets for space</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3d6b4b58-170b-4aca-ba99-1d18715cbe59",
                                "recommendation": "<p>A licensed electrician is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "99c407d3-3bd1-4918-8dc4-c5139d4bfa37",
                                "recommendation": "<p>Replace damaged outlet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "23735728-3002-4072-8a96-190b8d66ff53",
                                "recommendation": "<p>110 VAC Outlets, Lighting Circuit and ceiling fan</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ffd82f11-9455-4a31-a288-ced7fe8b49e3",
                                "recommendation": "<p>Install missing outlet/switch cover plate(s)</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "41f24057-e5a5-4bb6-b58d-c3000170dbc2",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "110 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC GFCI",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC/220 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "220 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC outlets and lighting circuits",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "4f0cd489-c226-476d-a1ad-f40854e173b6"
                            }
                        ]
                    },
                    {
                        "order": 12,
                        prompt: "HVAC Source:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "84905cce-9762-4b20-ae7a-c6c2c14e3472",
                                "recommendation": "<p>Heating system inoperable</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "181669ab-dc13-4eb1-abb5-822f2b72e3b9",
                                "recommendation": "<p>Low air flow</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cf20df21-3d3b-44d6-ba8f-8ab45872ff0e",
                                "recommendation": "<p>No heat duct</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9a548975-6ef6-4268-afbd-794172275427",
                                "recommendation": "<p>Register cover damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7fcc49f5-3644-471a-917e-078204fae835",
                                "recommendation": "<p>Register cover missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "10d51572-8c05-4d57-be04-0044e05fde5e",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Heating system register",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Convection baseboard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Air exchange ventilation",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Radiant heat",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "da281e57-e588-44ad-a55a-484a112844cc"
                            }
                        ]
                    }
                ],
                "order": 1,
                "is_deleted": 0,
                "created_by": 686,
                "updated_by": 686,
                "created_at": "2022-11-08T18:05:40.000000Z",
                "updated_at": "2022-11-08T18:05:40.000000Z"
            },
            {
                uuid: "2380755c-c545-4f3d-a927-42a87469ce3e",
                prompt: "Air Conditioning",
                "introductory_text": null,
                lines: [
                    {
                        "order": 1,
                        prompt: "A/C System Operation:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "debddf2b-d3d1-4ae4-94aa-0b443a667a2c",
                                "recommendation": "<p>The unit is currently in service beyond the manufactures stated design life</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "93633b43-e0b1-492a-8b08-534f015a7fd0",
                                "recommendation": "<p>A qualified air conditioning contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "483d6659-d050-43f8-bf31-4a92237b0233",
                                "recommendation": "<p>Due to the season, the heat pump was tested in heating mode only. The cooling mode uses the same components in reverse cycle.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1c0f4d62-f875-4aa7-acc3-f4dda916253d",
                                "recommendation": "<p>To avoid possible compressor damage due to outside temperature below 60 degrees, the unit was not tested.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3f4c5e92-422f-4ec6-adaf-49fcb48db13a",
                                "recommendation": "<p>Unit appeared to be operating normally.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "65fd6407-84c3-4534-84eb-356feb8a62f5",
                                "recommendation": "<p>Improper differential temperature</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4f9a6f53-04c2-4e0f-84ea-13b758756dce",
                                "recommendation": "<p>The unit is nearing the end of the manufactures stated design life</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "3c14ea16-96be-46c3-9e1d-2a71a1d2f2bb",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Appears serviceable",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Limited cooling",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Functional",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Inoperative",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not inspected",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "b9446b74-4ec0-4257-b7a1-1bbaa00c832a"
                            }
                        ]
                    },
                    {
                        "order": 2,
                        prompt: "Condensate Removal:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bcd7a3a5-e9fe-4c81-bcef-e24eafd3c5b3",
                                "recommendation": "<p>Clogged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d4493ca9-2eeb-4b29-b5d0-f80ee5b175b4",
                                "recommendation": "<p>Crimped</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "99d96bcc-2527-4977-81cf-0a8b6c3f9460",
                                "recommendation": "<p>Crushed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "aeae0ef5-6313-41a5-929d-d771432198ec",
                                "recommendation": "<p>Negative slope</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "016cd93c-0d54-425d-9c14-c60e83ab838e",
                                "recommendation": "<p>Pump motor has failed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "34d1c009-6f68-4d7c-8590-c2b0d5746cd9",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "PVC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Plastic tubing",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rubber",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Electric pump",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "a2be64ed-e8b6-4745-8329-bf51815e6039"
                            }
                        ]
                    },
                    {
                        "order": 3,
                        prompt: "Exterior Unit:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2511b231-aedf-46d0-be39-8c3ff32b5b13",
                                "recommendation": "<p>Exterior box has excessive rust.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1cdcfbc6-57aa-49c9-9c56-d6fe46a52eff",
                                "recommendation": "<p>Not properly supported</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "30afc41c-2b3c-4fb6-be90-12d9ce967c08",
                                "recommendation": "<p>Unit not level</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "95caba41-136c-44bb-b38c-bb68a8691a98",
                                "recommendation": "<p>Pad needs to be replaced</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "479f66bf-3cdb-4fe7-9efe-3c89a3da0e98",
                                "recommendation": "<p>Noisy operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "b2d597df-a859-4bc8-a9e2-cbaf612a96ff",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Pad mounted",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Suspended",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "d5e426ab-cee9-4cc4-a59c-67618fa53915"
                            }
                        ]
                    },
                    {
                        "order": 4,
                        prompt: "Manufacturer:",
                        comments: [],
                        "line_uuid": "d682cb10-34cd-470d-ae33-5984c55f4dc9",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Carrier",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Coleman",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Trane",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Unknown manufacturer",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Label not readable",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Goodman",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Janitrol",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Snyder General",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Arco-aire",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Comfortmaker",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Coleman Evcon",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tempstar",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Bryant",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "York",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Williamson",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fedders",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Bard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rybolt",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Johnson",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Payne",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Unitary Products",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Lennox",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Luxaire",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Allegiance",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Ruud",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "International",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Crysler-Airtemp",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Moncreif",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "a653aa42-b029-4646-80e3-7f78f36ea9a8"
                            }
                        ]
                    },
                    {
                        "order": 5,
                        prompt: "Model Number:",
                        comments: [],
                        "line_uuid": "27e5efd9-3c61-4c44-9f67-b680e80365a8",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [],
                                "line_input_control_uuid": "a7c2c983-a5b4-4657-892c-dd3296a7cf84"
                            },
                            {
                                "type": "Additional input",
                                prompt: "Serial Number:",
                                "values": [],
                                "line_input_control_uuid": "ee0b2c82-d78b-46d5-8bf3-961177a886cc"
                            }
                        ]
                    },
                    {
                        "order": 6,
                        prompt: "Area Served:",
                        comments: [],
                        "line_uuid": "2638844e-f8f6-4fd8-9184-bc194330eb7a",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Whole building",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "1st floor and basement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "2nd floor only",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "1st floor",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "ef922d91-05df-47cd-b839-834771679095"
                            },
                            {
                                "type": "Additional input",
                                prompt: "Approximate Age:",
                                "values": [
                                    {
                                        "value": "New",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "001d0526-dcda-4534-b076-14ebfb93df62"
                            }
                        ]
                    },
                    {
                        "order": 7,
                        prompt: "Fuel Type:",
                        comments: [],
                        "line_uuid": "431d4bb6-46a5-40d5-9101-b52cb0376ee3",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "220 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "220-240 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110-120 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "120-240 VAC",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "835ee07c-bcf8-430c-a6f5-e9de17eced54"
                            },
                            {
                                "type": "Additional input",
                                prompt: "Temperature Differential:",
                                "values": [],
                                "line_input_control_uuid": "de12dbcf-d13a-4369-b254-48c395a3c44e"
                            }
                        ]
                    },
                    {
                        "order": 8,
                        prompt: "Type:",
                        comments: [],
                        "line_uuid": "fa1b9bde-5de0-47f7-bfda-617ff49bab5c",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Central A/C",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Heat pump",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Evaporative",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wall mounted air conditioning unit",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "508114a8-9dc4-4d05-bba4-645ee0364435"
                            },
                            {
                                "type": "Additional input",
                                prompt: "Capacity:",
                                "values": [
                                    {
                                        "value": "2.5 Ton",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not listed",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "1.5 Ton",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "5 Ton",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "3.5 Ton",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "2 Ton",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "3 Ton",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "4 Ton",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "566b7ddc-04c6-4549-b052-e804f1b9b6d1"
                            }
                        ]
                    },
                    {
                        "order": 9,
                        prompt: "Visible Coil:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d13b6a59-8dfa-424f-84d4-22745a8dfe8f",
                                "recommendation": "<p>Possible leakage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "331ae322-0566-4a92-a756-7c802396b73a",
                                "recommendation": "<p>Oil on coil</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f358390d-3638-4d70-9ceb-7798e96ed909",
                                "recommendation": "<p>Fins damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "97442642-9cea-441d-afed-ad1fc10360ba",
                                "recommendation": "<p>A qualified air conditioning contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "ea949ca7-87d4-425e-8c73-41b8fcc3500e",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Aluminum",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper core with aluminum fins",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "170b9f41-13da-4967-aede-a81dc0741ded"
                            }
                        ]
                    },
                    {
                        "order": 10,
                        prompt: "Refrigerant Lines:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0636bf64-ac85-455f-b2e3-8c3ae89438bf",
                                "recommendation": "<p>Torn insulation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "99176135-380e-43c3-a872-d39e7f278a3b",
                                "recommendation": "<p>Entry point not sealed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6e5d0ed5-40f9-4283-ac8e-d791d14d125f",
                                "recommendation": "<p>Evidence of leakage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9496dea7-966e-452f-8314-f7f24264b3b4",
                                "recommendation": "<p>Cuts in exterior surface of line</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f0263f45-fa16-4570-b43d-ae61556760db",
                                "recommendation": "<p>Crimped</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "db42deda-79fe-4398-8ef9-4df92ea76f9e",
                                "recommendation": "<p>Lines covered by landscaping</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "27d82d95-f098-40bf-b3d2-156a63ad72e7",
                                "recommendation": "<p>A qualified air conditioning contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "d3305ba3-5788-4adf-b87e-760e8c90295f",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Serviceable condition",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "High pressure",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Liquid line",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Low pressure",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Low pressure and high pressure",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Suction line",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Suction line and liquid line",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "8ab1945b-4640-4cb6-a43a-d03c13967f63"
                            }
                        ]
                    },
                    {
                        "order": 11,
                        prompt: "Electrical Disconnect:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "23aa854b-c36c-4c0a-a669-84a6a271b747",
                                "recommendation": "<p>Evidence of water penetration in box</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5cae0f94-d628-460d-a0f1-20be789a0c98",
                                "recommendation": "<p>Corrosion present on breaker</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f45c8bd6-1782-4517-916f-9bf678eefa3a",
                                "recommendation": "<p>Recommend evaluation by a licensed electrician</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "13b361ab-2684-470e-bee5-63ca36eed825",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Breaker disconnect",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tumble switch",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fused",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "0bfa9b46-5d52-4803-b52f-f8aaed617754"
                            }
                        ]
                    },
                    {
                        "order": 12,
                        prompt: "Exposed Ductwork:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bf4946c1-068d-432c-8c3f-b0383a976bf3",
                                "recommendation": "<p>Loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "82e9fd6d-543d-4591-b3b9-36905a2b7d18",
                                "recommendation": "<p>Cap missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c206fb28-6a7b-4ae0-b057-a5ca6db320c3",
                                "recommendation": "<p>Asbestos present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "da844869-69e5-4d5d-a926-306b4d952e88",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "6567be9a-451e-447f-86ff-d591240f3397",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Metal",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Insulated flex",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Metal ducts with corrugated cold air return",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "92e98111-f4a9-4319-b2c6-944595c5b07d"
                            }
                        ]
                    },
                    {
                        "order": 13,
                        prompt: "Blower Fan/Filters:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "af867621-7b74-4f8f-bb98-514f43b33184",
                                "recommendation": "<p>Blower fan makes noise</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "057f6354-6b85-4a2f-beb8-7589a3155975",
                                "recommendation": "<p>Filter is undersized for unit</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8144e730-2e54-469a-89fa-338bf065b9b4",
                                "recommendation": "<p>Filter is extremely dirty</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0ecdd751-3968-4e8a-9581-e15be4820734",
                                "recommendation": "<p>Requires maintenance</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2803871d-7223-48c3-8445-f00476679e02",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ac4cff13-59aa-43f1-a939-ba15d216acb9",
                                "recommendation": "<p>Loose belt</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4ad6635c-f178-421f-9880-69056eecf8c7",
                                "recommendation": "<p>Broken belt</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "616ab804-97bd-48cb-ba06-8bff0da586d1",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Belt drive with electronic filter",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Direct drive with reusable filter",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Belt drive with disposable filter",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Direct drive with disposable filter",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Direct drive with electronic filter",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Belt drive with reusable filter",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "1bfe2a92-4645-4b06-a6ac-3b751a3a38ed"
                            }
                        ]
                    },
                    {
                        "order": 14,
                        prompt: "Thermostats:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8bc467b1-1ded-40a9-8f15-847db8e2ae61",
                                "recommendation": "<p>Loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "84e72b52-3c2c-4af5-b778-fc9b1445d931",
                                "recommendation": "<p>Not level</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "de347305-d2cb-4a3b-a58f-c209fd540a0b",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "568b8ce4-b5c3-46bd-b52a-452c2fa14c05",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Individual",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Multi-zone",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Programmable",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "52193769-96a5-46c1-a371-03a167c7dc90"
                            }
                        ]
                    }
                ],
                "order": 1,
                "is_deleted": 0,
                "created_by": 686,
                "updated_by": 686,
                "created_at": "2022-11-08T18:05:40.000000Z",
                "updated_at": "2022-11-08T18:05:40.000000Z"
            },
            {
                uuid: "6f5bea6b-c1a1-41d6-b8c2-234620df6bb3",
                prompt: "Fireplace/Wood Stove",
                "introductory_text": null,
                lines: [
                    {
                        "order": 1,
                        prompt: "Freestanding Stove:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2d693222-174e-4cd4-9a10-059ea87f8b1b",
                                "recommendation": "<p>Recommend cleaning and inspection prior to use</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fd0c137b-6ffd-4804-bba4-8fe0cc07103a",
                                "recommendation": "<p>Improper chimney pipe</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6679818b-5b7c-43e6-9b7d-efa359040eb8",
                                "recommendation": "<p>Installation may not meet standards for fire safety</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fb86adbd-8055-4d97-a0aa-dcacf97ebac7",
                                "recommendation": "<p>Rust holes present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "28a24ee1-253d-4236-b58f-50edecff957e",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "11ad77de-619e-45df-bb4e-c519c3ece9f6",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Wood burning",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Decorative",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "8510f004-9b8e-403f-bbce-4dd479d289e5"
                            }
                        ]
                    },
                    {
                        "order": 2,
                        prompt: "Fireplace Construction:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "35a4fdc8-4483-4a2c-aff2-855447c399a1",
                                "recommendation": "<p>Loose or broken fire brick</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d6f97a38-5ad9-4b69-94ed-18574ecdd366",
                                "recommendation": "<p>Fireplace and components need cleaning and inspection prior to use</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "13f2c9ab-3796-469c-9d05-31ae26e66b58",
                                "recommendation": "<p>Fill voids around fire brick</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8f317822-054c-4ac4-8508-4603236913a3",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "a3ed8c6d-51c6-4e8a-b190-bfbfa5e0e9f3",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Stone",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Brick",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Block",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Prefab",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "830e1bcc-6066-41ac-b33d-e71671c2ee13"
                            }
                        ]
                    },
                    {
                        "order": 3,
                        prompt: "Type:",
                        comments: [],
                        "line_uuid": "95372c47-3569-4b09-9d6b-b317e7488335",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Gas log",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood burning",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Electric",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Ornamental",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Free standing",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "2f69c164-0743-47ab-9886-26a997b720f2"
                            }
                        ]
                    },
                    {
                        "order": 4,
                        prompt: "Fireplace Insert:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "075bcea3-3934-4644-84f2-5aebbcc4b652",
                                "recommendation": "<p>Loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5ba2f023-45aa-4fc0-9cd0-40c8995dc798",
                                "recommendation": "<p>Not properly attached</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bca78e49-7397-47c1-b414-12a469b01cfa",
                                "recommendation": "<p>Damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "27ef3bc4-7300-4afa-b665-49d4aad9d089",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "81ecdcb0-1cac-4174-bd6d-270fc5b0fab4",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "With blower fan",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Standard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "With thermostatically controlled fan",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "bb5fa3b4-5bde-47e5-a507-d8b5e9d7aac2"
                            }
                        ]
                    },
                    {
                        "order": 5,
                        prompt: "Smoke Chamber:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3656436f-6368-4d70-a95f-5dc23a3b7370",
                                "recommendation": "<p>Fill voids in smoke chamber</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0a6af552-d675-4e91-8850-4960bedc2fc8",
                                "recommendation": "<p>Needs repair prior to use</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "906f53fd-80be-4a8c-9e98-3850a3e0dc18",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "8a71d9a1-fe51-4bba-bd76-ad8215f6397c",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Brick",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Block",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Metal",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "a259b1b3-f3e0-4e3d-9d37-ab1954fc71d6"
                            }
                        ]
                    },
                    {
                        "order": 6,
                        prompt: "Flue:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8bb0e883-1830-43e0-a3bd-9d5cdd6c5ce3",
                                "recommendation": "<p>Recommend cleaning and reinspection</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e93e931e-e715-4f9c-ab9f-054109c1d147",
                                "recommendation": "<p>Creosote build-up</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "32c093b0-0f23-4811-8a65-4cf44639cdd9",
                                "recommendation": "<p>Damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "05cd090f-92f4-40a8-b2a4-3f5dd49c53f0",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b1493b65-c7f3-409a-91fa-725d26ca84f6",
                                "recommendation": "<p>Gaps present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "96ed914d-4140-4f63-abba-b8716f20d297",
                                "recommendation": "<p>Voids present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "886f8d63-c76f-4166-9b28-25266695b876",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Metal",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tile",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "ec31df93-c994-4342-ac42-5d4833ce0bb5"
                            }
                        ]
                    },
                    {
                        "order": 7,
                        prompt: "Damper:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "211fac2b-cf5b-4364-a9ce-590e183693b0",
                                "recommendation": "<p>Loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ff9fedeb-7225-419c-8f70-65f9fda34bb3",
                                "recommendation": "<p>Missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "f09540c1-66c2-47ea-a089-ea268a5f61ba",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Metal",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "54e27c57-a1ff-4654-81bd-a12a8f97158a"
                            }
                        ]
                    },
                    {
                        "order": 8,
                        prompt: "Hearth:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4e71396a-6151-4c6f-8541-24d5d4472f31",
                                "recommendation": "<p>Cracked</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "775be6b6-5512-44a4-8db5-975ae49d7a9e",
                                "recommendation": "<p>Fire hazard</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "8a129ebb-0b59-4043-8eb5-38f7b3ebba5c",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Raised",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "No hearth",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Flush mounted",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "4eb7e18f-eb8a-4d86-ace6-1596774ecfde"
                            }
                        ]
                    }
                ],
                "order": 1,
                "is_deleted": 0,
                "created_by": 686,
                "updated_by": 686,
                "created_at": "2022-11-08T18:05:40.000000Z",
                "updated_at": "2022-11-08T18:05:40.000000Z"
            },
            {
                uuid: "52681d9b-7bd1-44db-9a5d-62627700c60e",
                prompt: "Heating System",
                "introductory_text": null,
                lines: [
                    {
                        "order": 1,
                        prompt: "Heating System Operation:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e5159ece-70b7-4356-a293-8eef9bd5072d",
                                "recommendation": "<p>Blower fan noisy</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0d5268e2-5097-4251-8032-5ca402a454fe",
                                "recommendation": "<p>Evidence of flue back drafting</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e45e2b12-9912-4ef8-ace6-1bd42e496727",
                                "recommendation": "<p>Furnace existing beyond design life</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2c32c11f-4db2-4a09-b904-583fde1e5dae",
                                "recommendation": "<p>Furnace nearing the end of it's design life</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "95cd4164-50dd-4dfd-bce2-1493d36d2d91",
                                "recommendation": "<p>Gas leak detected</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7bf4a26f-8fc2-4ae9-9bfd-815ffa63aa05",
                                "recommendation": "<p>Heat exchanger rusting</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "98acca1a-69e3-400e-9416-a26de3c24e13",
                                "recommendation": "<p>Poor routing of flue pipe</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "759d4b1b-adfc-4a45-b61b-7c0c2f43b25a",
                                "recommendation": "<p>Service recommended</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "99091c7c-31ca-4e93-a8be-16ef90914155",
                                "recommendation": "<p>Suspected asbestos present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9d43430c-19ec-49d4-81fc-313c925e9dba",
                                "recommendation": "<p>Flame roll out noted at the combustion chamber</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "47eb8542-d338-4063-91ce-e35784c0b051",
                                "recommendation": "<p>Cleaning recommended</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "d89ee60c-f088-4de1-80f3-1923217f873f",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Adequate",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Inadequate",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Appears functional",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Recommend replacement",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "9e107ddf-ca44-4a4e-b1b0-d809d48e353c"
                            }
                        ]
                    },
                    {
                        "order": 2,
                        prompt: "Manufacturer:",
                        comments: [],
                        "line_uuid": "b2f566ff-23de-4e23-b584-bcd4183a00c7",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Borg Warner",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Carrier",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Trane",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "General Electric",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Unknown manufacturer",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Label not readable",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Goodman",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Janitrol",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Snyder General",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Arco-aire",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Comfortmaker",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Coleman Evcon",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tempstar",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Bryant",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Coleman",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "York",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Williamson",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fedders",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Bard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rybolt",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Dunkirk",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rheem",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Ruud",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "International",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Crysler-Airtemp",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Moncreif",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Johnson",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Payne",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Unitary Products",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Lennox",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Luxaire",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Allegiance",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "25da80ca-52e8-4be8-b5be-44ec5bf180df"
                            }
                        ]
                    },
                    {
                        "order": 3,
                        prompt: "Model Number:",
                        comments: [],
                        "line_uuid": "b8e13344-ac2f-43a8-9acd-27a633e190dc",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [],
                                "line_input_control_uuid": "8daf7122-b285-45e0-b56c-9c44780bfc63"
                            },
                            {
                                "type": "Additional input",
                                prompt: "Serial Number:",
                                "values": [],
                                "line_input_control_uuid": "bc2f497c-a356-4d1a-9eaa-b626f8f0a4a0"
                            }
                        ]
                    },
                    {
                        "order": 4,
                        prompt: "Type:",
                        comments: [],
                        "line_uuid": "4b5b7c45-ec73-4d5f-8f2f-7757b2295160",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Forced air",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Boiler system",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Gravity heat",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Convection baseboard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Radiant ceiling or panel",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wall recessed resistance",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Passive storage cells",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Active solar",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Geo-thermal",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "bb52e8d4-1e5e-48d8-906c-95fc5a148879"
                            },
                            {
                                "type": "Additional input",
                                prompt: "Capacity:",
                                "values": [
                                    {
                                        "value": "91,000 BTUHR",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "100,000 BTUHR",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "73aeae48-a5dd-4563-b4c4-439f599de7ae"
                            }
                        ]
                    },
                    {
                        "order": 5,
                        prompt: "Area Served:",
                        comments: [],
                        "line_uuid": "f4ceeabf-9620-4416-8c20-daf09411e689",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Whole building",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "1st floor and basement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "2nd floor only",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "1st floor",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "1b1abbbe-5cbe-41b1-bae8-e9c05234725a"
                            },
                            {
                                "type": "Additional input",
                                prompt: "Approximate Age:",
                                "values": [
                                    {
                                        "value": "New",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "fbc63362-d96d-424a-bb91-b495df6c446e"
                            }
                        ]
                    },
                    {
                        "order": 6,
                        prompt: "Fuel Type:",
                        comments: [],
                        "line_uuid": "4041b8cf-ca81-4127-9d60-99a500dac56c",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Electric",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Natural gas",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Propane gas",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Coal",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Geo-thermal",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Solar",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "2787b4ce-8a31-4feb-9415-d07891ef0f09"
                            }
                        ]
                    },
                    {
                        "order": 7,
                        prompt: "Heat Exchanger:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "49020057-1cd9-4ce4-be24-cc6b0dfc79be",
                                "recommendation": "<p>Excessive rust</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7b692411-5831-4dd5-8037-bd06823e997e",
                                "recommendation": "<p>Recommend inspection by a qualified heating specialist</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "208b3c22-3505-4dfd-8780-2805b467ac4a",
                                "recommendation": "<p>Rollout</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "278ee730-7d19-42e8-8391-f3f900ca65b6",
                                "recommendation": "<p>Carbon monoxide leakage detected</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4769734c-734f-47d9-863c-c534f477cd1a",
                                "recommendation": "<p>Evidence of backdrafting</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "99591725-7745-4519-9134-07c2024578ff",
                                "recommendation": "<p>Requires replacement</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "15d050a8-baaa-4760-a94a-949a545bb6d9",
                                "recommendation": "<p>Severe rust</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "9ae826c9-f787-4651-88c4-7ee37cfa53ab",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "1 Burner",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "2 Burner",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "3 Burner",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "4 Burner",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "5 Burner",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "6 Burner",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "69dd45d7-33f5-468a-a6ee-1bbedd229b21"
                            }
                        ]
                    },
                    {
                        "order": 8,
                        prompt: "Unable to Inspect:",
                        comments: [],
                        "line_uuid": "b3432922-213d-4b9d-be4d-7540df2fcf59",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "10%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "15%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "20%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "25%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "30%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "40%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "45%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "50%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "55%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "60%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "65%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "70%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "75%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "80%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "85%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "90%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "95%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "100%",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "d08cd630-ad8b-436a-b3e4-d5169b392036"
                            }
                        ]
                    },
                    {
                        "order": 9,
                        prompt: "Blower Fan/Filter:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "23e7d52c-f650-4e14-9f17-cb6dbe8934aa",
                                "recommendation": "<p>Blower fan makes noise</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bf7258f5-b53e-4b2b-bc2c-d0fb747f2431",
                                "recommendation": "<p>Filter is undersized for unit</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5a2bb29d-d273-4e25-a842-2b107b051ce8",
                                "recommendation": "<p>Filter is extremely dirty</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cb26fb6a-4819-48b2-bcf6-9727c012f88b",
                                "recommendation": "<p>Requires maintenance</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fa905643-6ee8-4434-b88c-78f0653b829f",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3231999e-9a76-4bab-980c-27c2438679c1",
                                "recommendation": "<p>Loose belt</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e749f733-5039-4c3a-b30c-264611dbd298",
                                "recommendation": "<p>Broken belt</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "7fefefdd-7c93-4162-a7ce-ba3cf072c7ca",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Belt drive with electronic filter",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Direct drive with reusable filter",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Belt drive with disposable filter",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Direct drive with disposable filter",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Direct drive with electronic filter",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Belt drive with reusable filter",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "2bf3052c-c5eb-46d6-b8a6-3e45685015f9"
                            }
                        ]
                    },
                    {
                        "order": 10,
                        prompt: "Distribution:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "009fb6ac-eeb8-49e7-8dc6-15985bd5f3f0",
                                "recommendation": "<p>Inadequate air flow</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7d58c49e-a287-4a32-b728-b46a5a623f74",
                                "recommendation": "<p>No air flow</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bc89f206-de9f-4afc-ba85-90054c43b1e7",
                                "recommendation": "<p>A qualified heating system contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "8f5a82fe-857e-4687-9fd5-714514e902a3",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Hot water",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steam",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Baseboard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Radiator",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Metal duct",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Insulflex duct",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "One pipe",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Two pipe",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "d2f09235-92e0-45a1-9a53-ba819cb76585"
                            }
                        ]
                    },
                    {
                        "order": 11,
                        prompt: "Circulator:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8fd414ef-4d72-4faa-945e-a585b9f9be9f",
                                "recommendation": "<p>Inadequate flow</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5aa5bd66-2832-4bc3-b1de-c8846279f9ae",
                                "recommendation": "<p>No flow</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7552b9b2-8105-449d-af3f-990a502fca6e",
                                "recommendation": "<p>A qualified heating system contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "b5965966-775d-4b21-a3df-daa736cdc702",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Pump",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Gravity",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "N/A",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "cdc1fa6c-279d-4eff-af40-ce656e9a1ff8"
                            }
                        ]
                    },
                    {
                        "order": 12,
                        prompt: "Draft Control:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "490fd9ec-48f5-43b2-87d8-c7a77b5d2ba4",
                                "recommendation": "<p>Inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "112b0c5f-36bc-4aab-84b0-83235972b7dd",
                                "recommendation": "<p>Missing handle</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4d533592-0580-413c-a42e-e6d171945ef2",
                                "recommendation": "<p>Frozen</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c53d550c-a086-4ed7-b19c-7faeae23e264",
                                "recommendation": "<p>Needs service</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "47e38e26-e5e2-4183-8d0f-86ac63c46149",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Manual",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Automatic",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "79d5c5b1-c7fd-4851-bb91-f7ac9a578463"
                            }
                        ]
                    },
                    {
                        "order": 13,
                        prompt: "Flue Pipe:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "32267042-da2c-4e3c-8d1d-4d981987f7a8",
                                "recommendation": "<p>Negative slope</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4d78e92f-254a-4150-88de-5d9ce220f3e8",
                                "recommendation": "<p>Evidence of backdrafting</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a015b567-9871-4cde-8a28-9ba08294a408",
                                "recommendation": "<p>Rust holes</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b56fd00e-efdd-4b8e-8d71-3681578dcfe1",
                                "recommendation": "<p>Pipe does not meet minimum requirements for conditions</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9edc6814-ede1-42eb-80e3-e9b95b945164",
                                "recommendation": "<p>Unit venting into unlined chimney causing condensation and rusting exhaust piping and exchanger</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f519557d-415c-4277-85ee-619f272f6cd9",
                                "recommendation": "<p>Pipe does not meet minimum pitch requirements</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "68558f5e-3a47-4dd5-b4fd-f9e13281910b",
                                "recommendation": "<p>Install screws at exhaust vent piping fittings</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "93cc1527-5e07-4f07-ae70-02c9679be165",
                                "recommendation": "<p>Improper flue routing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "37d20fc0-19fd-4b79-9bb6-32c0cdb66a22",
                                "recommendation": "<p>Seal vent penetration into wall/chimney</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bd747ff5-4f03-46c6-b3e5-b6e2b022586f",
                                "recommendation": "<p>Rust holes in piping-replace rusted sections</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "04bd416d-a8df-4ea8-b073-4b94821167dc",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Single wall",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Double wall",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "PVC",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "e948f724-6e29-4744-82f6-2f8dcd23bb29"
                            }
                        ]
                    },
                    {
                        "order": 14,
                        prompt: "Controls:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e2637444-8c2e-4693-b823-9cd283f1bf7b",
                                "recommendation": "<p>Gauge inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "eefc1a46-0b5c-4dac-8fbc-1de3106a2286",
                                "recommendation": "<p>Pressure gauge is leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "28c3668b-53e9-4bdc-b1a4-85fc322b83d4",
                                "recommendation": "<p>Moisture present in gauge</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7113690b-963a-4059-93d5-bdcaf5a5e067",
                                "recommendation": "<p>Leakage noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f4b0bdb7-116b-4438-a8b3-0c824d113d58",
                                "recommendation": "<p>Expansion tank leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e55ec622-cba0-4bec-aabc-190a4d273375",
                                "recommendation": "<p>Expansion tank rusting</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "08e8536c-d849-46c9-85fb-43cf7210490b",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Relief valve",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Limit switch",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "5d9b4605-08d1-4f05-aab2-5de8988a8e05"
                            }
                        ]
                    },
                    {
                        "order": 15,
                        prompt: "Devices:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1a386d2b-00eb-47e5-9e36-31be6983cdbb",
                                "recommendation": "<p>Gauge inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "05a20822-adce-47c9-a317-45422e213300",
                                "recommendation": "<p>Pressure gauge is leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7bc2cedc-3b2b-4f7b-9684-3e7f641ea5b3",
                                "recommendation": "<p>Moisture present in gauge</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0fd83c17-8af6-4bce-9065-920168ce8f62",
                                "recommendation": "<p>Leakage noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f1414d2f-3da5-47b4-bfc7-d45951557168",
                                "recommendation": "<p>Expansion tank leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7b402719-1e16-4993-b4c6-4ef48e3f0900",
                                "recommendation": "<p>Expansion tank rusting</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "767b14d8-8e33-4109-9375-9f34cdefc685",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Temp gauge",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Expansion tank",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "N/A",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Pressure gauge",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "bd243574-8c1d-4a42-8e32-36c013d1cbd2"
                            }
                        ]
                    },
                    {
                        "order": 16,
                        prompt: "Humidifier:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fc52381b-e9cc-4303-bcfe-e2737549e791",
                                "recommendation": "<p>Needs cleaning</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3bbcebed-b499-4ada-af75-0419c9aead4e",
                                "recommendation": "<p>Requires service</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "12630300-33db-4255-8311-af9d0987be17",
                                "recommendation": "<p>Motor inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "906a586a-0c45-4a3e-8ae0-c368ec948887",
                                "recommendation": "<p>Disconnected at time of inspection</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "98976a12-fb5d-4d35-9283-705f185accce",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "dd898574-a775-48ea-88ca-92da72b28903",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "April-Aire",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Honeywell",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "648a77bf-7661-4604-967a-8ebf3f27c70c"
                            }
                        ]
                    },
                    {
                        "order": 17,
                        prompt: "Thermostats:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "318ba92f-e8e5-403e-8c95-040729636220",
                                "recommendation": "<p>Loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ddecb6ad-c868-4265-80ac-e0a605cfc7f9",
                                "recommendation": "<p>Not level</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "43fdbf5d-043b-4eee-bb08-8e121bd6f889",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "c3ad5c85-8545-4f26-90cc-608f9a056fca",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Individual",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Multi-zone",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Programmable",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "a272d899-ee4f-4fd0-b437-3229d5020202"
                            }
                        ]
                    },
                    {
                        "order": 18,
                        prompt: "Fuel Tank:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a1f255da-e09e-468d-beeb-c841e6683158",
                                "recommendation": "<p>Abandoned with fuel remaining in tank</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1f8b5bb4-746e-4ff6-bee4-ae0b79453734",
                                "recommendation": "<p>Leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6240ccaf-77e8-4483-9b95-8a849c5b15ea",
                                "recommendation": "<p>Tank appears empty</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c0551c5c-2b10-48e6-93d6-34af027e0300",
                                "recommendation": "<p>Recommend removal by qualified contractor</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "e98e7f46-c7b6-4929-b19d-83ce4448a222",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Abandoned oil tank",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Abandoned propane tank",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Oil tank",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Propane tank",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "d1093192-02cf-46ee-9444-5636b7dad603"
                            }
                        ]
                    },
                    {
                        "order": 19,
                        prompt: "Tank Location:",
                        comments: [],
                        "line_uuid": "425c7a72-d35d-48b8-b882-6257141358f8",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Basement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rear yard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Side yard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Underground",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "3d651d5d-0302-4195-8095-8429a38b16f1"
                            }
                        ]
                    },
                    {
                        "order": 20,
                        prompt: "Suspected Asbestos:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6f5f13b5-b202-45e3-96bf-0982a0fac468",
                                "recommendation": "<p>Friable</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "81828342-29d6-4c40-b464-435379462630",
                                "recommendation": "<p>Lab testing of the suspected asbestos material is required to determine the presence of asbestos</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7ebf130d-96cf-4d92-8c54-a154fe200548",
                                "recommendation": "<p>In satisfactory condition at the time of inspection</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ce7a31a4-4a73-4df0-bd88-351df75d154b",
                                "recommendation": "<p>Recommend removal by a qualified asbestos contractor</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "38e764a3-1b27-4d9b-831e-3e96ee950bac",
                                "recommendation": "<p>Suspected asbestos present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "231bf669-2545-46a5-a82e-95489030687c",
                                "recommendation": "<p>Recommend inspection by a qualified asbestos contractor</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "b01796a0-6b19-493f-8fac-3629efd6a94b",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Yes",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "No",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "55227847-dc5e-4844-ae3c-eb66f56ad54b"
                            }
                        ]
                    }
                ],
                "order": 1,
                "is_deleted": 0,
                "created_by": 686,
                "updated_by": 686,
                "created_at": "2022-11-08T18:05:40.000000Z",
                "updated_at": "2022-11-08T18:05:40.000000Z"
            },
            {
                uuid: "ac6c1147-43c2-45dd-9fff-77c2b9c276a3",
                prompt: "Plumbing",
                "introductory_text": null,
                lines: [
                    {
                        "order": 1,
                        prompt: "Service Line:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e3f00c38-a964-421c-be63-ba97cdc9c509",
                                "recommendation": "<p>Crushed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f8a1fc17-5f94-4135-9cd0-b2e588a3f36f",
                                "recommendation": "<p>Leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a9e2d672-f7b4-4363-b788-542aa78bb90d",
                                "recommendation": "<p>Polybutelene line present as main service line. No leakage visible at the time of inspection.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3ac93a6c-d36a-4ca6-a1c8-7f40a8150503",
                                "recommendation": "<p>Polybutelene lines are susceptible to leakage and have been involved in litigation. Inspection and possible replacement is recommended.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "141fed9d-95eb-468b-a358-102c73451826",
                                "recommendation": "<p>Lead pipe present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bc5c2b50-af9b-4013-b3e8-12cb077fdfcf",
                                "recommendation": "<p>Galvanized supply piping corrodes form inside causing decreased flow rates and will eventually require updating</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d1afab34-bea2-42ad-ad14-777b5583c84a",
                                "recommendation": "<p>Repair/replace faulty pressure regulator</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "eb141429-7db5-45ae-94b5-1f26a85bbc95",
                                "recommendation": "<p>Excessive pressure noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "d639a6ce-aec7-49b9-9013-c1e62b44b6ba",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "ABS",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Cast iron",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "PVC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Lead",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Galvanized",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "PVC and copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Polybutelene",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "7a8eee1f-bd28-473d-8900-fd92702f9f69"
                            }
                        ]
                    },
                    {
                        "order": 2,
                        prompt: "Main Water Shutoff:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "328a7326-6ac7-4a93-8908-8c86c05b29b7",
                                "recommendation": "<p>Water off at time of inspection</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3e0cda50-d1de-40b3-bddc-51830965504f",
                                "recommendation": "<p>Water system winterized</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "756a32c9-1803-48f9-9360-f87f14887563",
                                "recommendation": "<p>Active leaks</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fc5255b3-1977-44ba-9c47-c8eaa9db1d6a",
                                "recommendation": "<p>Main service line is lead</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2aab32a0-27a2-4c7f-911f-ee7443618c66",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7c57f558-1884-48c3-8c17-f1bd850e8b08",
                                "recommendation": "<p>Make shut-off more accessible</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a93db3e4-748a-444c-8d43-0195353df021",
                                "recommendation": "<p>Pressure regulator and back flow preventer (check valve) installed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "db60b8ce-d3a6-456c-b62b-b29405764c84",
                                "recommendation": "<p>Pressure regulator installed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "f7faf619-9a1d-4f4d-9a7e-348a0d2b4cfd",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Front of house",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Basement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Recessed in wall",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not accessible",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Garage",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Utility closet",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Closet",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "2f5a5d4d-d7ec-480d-9983-ff7cbed10067"
                            }
                        ]
                    },
                    {
                        "order": 3,
                        prompt: "Water Lines:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9afddced-1ad1-47a3-85d8-dba937fc388b",
                                "recommendation": "<p>Evidence of previous leakage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8a620754-95ec-41cc-8e97-cacab1c9c8f4",
                                "recommendation": "<p>Leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9d2f08ae-f9ed-4750-a463-378851ba8755",
                                "recommendation": "<p>Not properly supported in hangers</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bd71bd43-fdbe-4268-ba6e-7d156abcb59f",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "dd9a8e15-564c-41da-b55b-6af2e810f407",
                                "recommendation": "<p>Galvanized supply piping corrodes from inside causing decreased flow-rates and will eventually require updating</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5605a1f4-2063-4156-aefd-af49711d7140",
                                "recommendation": "<p>Leaking supply pipe at fitting</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "860c3377-faae-4838-aea9-85794dbb1f95",
                                "recommendation": "<p>Evidence of previous leakage-monitor for further leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5443cbe6-7bf0-48bd-a90e-748383b514b1",
                                "recommendation": "<p>Leaking valve in supply piping</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b1de7893-a4dc-42b1-a4a6-142c0bf76b09",
                                "recommendation": "<p>Leaking saddle valve in supply pipe</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "28b8c135-c4d9-450d-b6a5-99b4c5ac01d4",
                                "recommendation": "<p>Leaking supply at pressure regulator</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "600d249a-a920-486a-936d-dd6517913ea6",
                                "recommendation": "<p>Leaking water meter</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8fb4f1ee-53b1-4734-947a-56609eddbecc",
                                "recommendation": "<p>Noted several areas along water supply lines where pitting/leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "5941a7d5-d0fb-465e-a005-059c127d2b8f",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "ABS",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Cast iron",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "PVC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Lead",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Galvanized",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "PVC and copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Galvanized and copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Possible Polybutelene",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Polybutelene",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "cb08e7b9-3344-4c26-ade0-977eb13f840f"
                            }
                        ]
                    },
                    {
                        "order": 4,
                        prompt: "Drain Pipes:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d4197753-ee6c-4e96-acfa-68c3612491ea",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3cbe015b-cd7d-43e5-98ea-b011b20f9bb5",
                                "recommendation": "<p>Galvanized drainpipe present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7035e816-23e1-4143-8659-4fba311c4f92",
                                "recommendation": "<p>Sewer odor noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8359253a-8fda-4aea-9c10-e13cc6c61890",
                                "recommendation": "<p>Slow drain</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "439dd7b0-da8b-4c60-a603-ec629b12e4ac",
                                "recommendation": "<p>Leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e487b254-8d1a-4e59-8b29-a5e8a234a0c8",
                                "recommendation": "<p>Pitting cast iron drain piping</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "a956e2e2-8fd0-4fae-91f9-41cbf7d5eb7b",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "PVC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "ABS",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Cast iron",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Galvanized",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Lead",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "47f522a7-6d20-4956-8fab-9e4db094a847"
                            }
                        ]
                    },
                    {
                        "order": 5,
                        prompt: "Service Caps:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2a1211ea-ba1d-4e5b-80c5-653b42add133",
                                "recommendation": "<p>Clean out cap missing.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "74a88c26-0ce5-425f-bc80-a934134b1839",
                                "recommendation": "<p>Clean out cap not accessible</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9d8830a6-8cbe-49c9-8e88-8c5470d5cc83",
                                "recommendation": "<p>Cap obstructed and can not be removed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "50778cc2-18c4-44a2-8080-27f8b18c99fa",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Accessible",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not visible",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "e085fed6-717c-492e-b20b-2ae7edd91db7"
                            }
                        ]
                    },
                    {
                        "order": 6,
                        prompt: "Vent Pipes:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a3ae5d54-5b4b-408c-8fe6-4fe5b32d86c5",
                                "recommendation": "<p>Improper or blocked vent</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "30845309-f35f-417b-a41c-60fbdf4d13fb",
                                "recommendation": "<p>System not vented</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "eb04845c-d8e5-4b5f-abb1-7be6cd3feca7",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "22c304a4-3325-42b8-a77a-cc32b79ae80d",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "PVC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "ABS",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Cast iron",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Galvanized",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Lead",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "PVC and copper",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "137827b7-1239-466f-a699-747356cb928c"
                            }
                        ]
                    },
                    {
                        "order": 7,
                        prompt: "Gas Service Lines:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4d60ddc1-f403-407c-821f-2e6a79d710e1",
                                "recommendation": "<p>Leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c2036316-7506-4247-9143-0d95caaf1932",
                                "recommendation": "<p>Line does meet acceptable standards</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "42128b35-6347-490a-9510-e0c66084a835",
                                "recommendation": "<p>Lines not properly supported</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "46000009-e018-454f-9bc0-e0ff8627bf3b",
                                "recommendation": "<p>Propane regulator leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b5eddbe4-9379-49c1-b2ef-d656ebc6cb27",
                                "recommendation": "<p>Regulator not properly attached</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c8b5c1ba-cf22-4eae-b0ca-ec141b525c35",
                                "recommendation": "<p>Unable to inspect underground lines to propane tank</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "971016f9-2fe3-476b-9327-e5fe62d18b37",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Cast iron",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Insulflex",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "27ea5215-5fb3-4d9f-b383-bbb3717b0a4d"
                            }
                        ]
                    },
                    {
                        "order": 8,
                        prompt: "Water Heater Operation:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7014bd00-343e-426e-acf9-4d7add9ba2a8",
                                "recommendation": "<p>Evidence of past or present water leakage in drain tube from pressure relief valve</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "026019b0-8d84-4732-a15a-ddf6ed3ae275",
                                "recommendation": "<p>Improper flue routing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f189c318-7731-4bf3-9ec2-5a61372ac964",
                                "recommendation": "<p>Insufficient heating</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4aed8340-5d9a-4d26-b9e7-a1e7c1125e04",
                                "recommendation": "<p>Water heater has exceeded design life</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9582eeeb-9fad-4b0f-a2ec-6729c9f07cda",
                                "recommendation": "<p>Drain tube opening is not within six inches of the floor</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b9a4d33e-037d-435d-8c2b-65b28bd8eb32",
                                "recommendation": "<p>Earthquake support straps missing or not properly installed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "70eed316-d6e2-4ad2-9c9b-b9546997a51f",
                                "recommendation": "<p>Flue pipe backdrafting</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "aa12c50c-99c4-41e3-8a01-7e35f13d6bb0",
                                "recommendation": "<p>Gas leakage detected</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7402f729-9316-4d40-bea5-a2a53fa62945",
                                "recommendation": "<p>Heating element inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d02ca35c-b2b1-4815-adf5-6a2dd00f95d6",
                                "recommendation": "<p>Improper or poor gas lines</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "14353a39-e98f-455c-9df1-792654aa4fa5",
                                "recommendation": "<p>Leakage present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a039e76a-9c50-4b61-89d2-2537ccb7aac3",
                                "recommendation": "<p>Water heater is nearing the end of it's design life</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f293ddc6-e3f8-47df-883e-270c282a842d",
                                "recommendation": "<p>Back draft detected at the</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2bc290de-790a-4a55-b995-6650b70ff9c8",
                                "recommendation": "<p>Water heater was not installed properly</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "f11247e4-5665-406d-8a8c-a8664704b015",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Adequate",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Inadequate",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Functional at time of inspection",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "4f312226-97a6-4e91-921d-21d723a103b3"
                            }
                        ]
                    },
                    {
                        "order": 9,
                        prompt: "Manufacturer:",
                        comments: [],
                        "line_uuid": "9b03b23d-dc1d-4f70-9ce7-64f71584ac45",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "A.O. Smith",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "State",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "American",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Standard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rheem",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Ruud",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Bradford-White",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "American Standard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Lochinvar",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "General Electric",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Kenmore",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Sears",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Enviro-Temp",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Craftmaster",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Mor-flo",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Richmond",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Reliance",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "82e12c73-4fc5-469c-926d-e5653e08af7d"
                            }
                        ]
                    },
                    {
                        "order": 10,
                        prompt: "Model Number:",
                        comments: [],
                        "line_uuid": "27f6cfd3-185b-43d7-b291-01855125853f",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [],
                                "line_input_control_uuid": "0e5bc890-e057-4c05-a8db-fa2a5aef145d"
                            },
                            {
                                "type": "Additional input",
                                prompt: "Serial Number:",
                                "values": [],
                                "line_input_control_uuid": "96f9f02b-4049-4c45-991d-cc48bcc354d5"
                            }
                        ]
                    },
                    {
                        "order": 11,
                        prompt: "Type:",
                        comments: [],
                        "line_uuid": "9ac55108-c5c0-4c6b-88cd-3a950abeaff4",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Electric",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Natural gas",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Propane",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hybrid",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Instantaneous",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "d68aeb06-2e7b-4bc1-a1a0-6264868451d4"
                            },
                            {
                                "type": "Additional input",
                                prompt: "Capacity:",
                                "values": [
                                    {
                                        "value": "30 Gal.",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "40 Gal.",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "50 Gal.",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "75 Gal.",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "9251dd4a-225d-4be4-a287-9733795a9e7c"
                            }
                        ]
                    },
                    {
                        "order": 12,
                        prompt: "Approximate Age:",
                        comments: [],
                        "line_uuid": "9964453a-f978-4ac6-a937-6ab119e93c41",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "New",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "e7c6ab1c-ee65-483e-9640-de8c1c1f0ab7"
                            },
                            {
                                "type": "Additional input",
                                prompt: "Area Served:",
                                "values": [
                                    {
                                        "value": "Whole building",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "1st floor and basement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "2nd floor only",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "1st floor",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "2fb2783c-c7b3-4cff-b384-bdaaf055aa4d"
                            }
                        ]
                    },
                    {
                        "order": 13,
                        prompt: "Flue Pipe:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3fbb4092-a8a9-4130-bda8-b20940147ec3",
                                "recommendation": "<p>Negative slope</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "699e5bb0-ac2e-469c-bd25-35084626fa0d",
                                "recommendation": "<p>Evidence of backdrafting</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c9d54ff8-b48a-4e79-8a9c-0b0469cf9dd4",
                                "recommendation": "<p>Rust holes</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f4f78227-bfdb-496a-a805-0aac6bba6b4d",
                                "recommendation": "<p>Pipe does not meet minimum requirements for conditions</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "16873878-769d-4fb0-bdb1-1586ace72909",
                                "recommendation": "<p>Unit venting into unlined chimney causing condensation and rusting exhaust piping and exchanger</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "63165388-17ba-4526-8711-68a822fefcfd",
                                "recommendation": "<p>Pipe does not meet minimum pitch requirements</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1a5d889e-8cf5-4dda-8dd3-4746526aa5cc",
                                "recommendation": "<p>Install screws at exhaust vent piping fittings</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0ce4ca28-68b1-4f0c-af95-bfe7eee2aa7f",
                                "recommendation": "<p>Improper flue routing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "be01065c-975b-4cdc-8751-67fffd947944",
                                "recommendation": "<p>Seal vent penetration into wall/chimney</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3c72ca81-6309-42bf-8bb1-f317c59c8d4d",
                                "recommendation": "<p>Rust holes in piping-replace rusted sections</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "2d630f83-746a-480b-8a93-b7a5f908b2f7",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Single wall",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Double wall",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "PVC",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "0854a9b8-4422-4690-842e-a4a3ca1a81fa"
                            }
                        ]
                    },
                    {
                        "order": 14,
                        prompt: "TPRV and Drain Tube:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "49a49852-0600-4c29-9046-cdbf143722ee",
                                "recommendation": "<p>Drain tube opening is not within six inches of the floor</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b388ec8c-8c54-4749-baa0-e0881ea7f2c9",
                                "recommendation": "<p>Improper installation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "335d24fc-b0b7-4e2c-9a1d-ee7d61802229",
                                "recommendation": "<p>Leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "01b54896-22af-4b0b-8f93-0bf2cf1df3ac",
                                "recommendation": "<p>Missing drain tube</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "d58990dd-e7a3-4c16-8114-43a8ae7fdd0f",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "PVC",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "a0be0d7f-1252-4c3d-99ba-1e610d071add"
                            }
                        ]
                    }
                ],
                "order": 1,
                "is_deleted": 0,
                "created_by": 686,
                "updated_by": 686,
                "created_at": "2022-11-08T18:05:40.000000Z",
                "updated_at": "2022-11-08T18:05:40.000000Z"
            },
            {
                uuid: "7331dd8b-85ab-45a3-8655-ac8b8d4a52bd",
                prompt: "Bathroom",
                "introductory_text": null,
                lines: [
                    {
                        "order": 1,
                        prompt: "Closet:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0cb40cf4-2f9f-4cda-812c-736d1b2bfdb1",
                                "recommendation": "<p>Missing clothes pole</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8ba62a93-3d59-4977-8b67-d02a976dbc60",
                                "recommendation": "<p>Walls in closet have cracks not seen in room</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "eadda235-3345-4429-a5ec-12dc03dd7f18",
                                "recommendation": "<p>Water stains noted at closet interior wall</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "63b87cdd-5860-4f2c-84ff-c1f7ca2e4c7f",
                                "recommendation": "<p>Repairs needed at bypass closet doors</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "b291dd64-59b4-4b0a-a085-1e5e2100eec3",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Single small",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Walk In",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Small & Walk-in",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Large",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Single",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Small",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Walk In and Large",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "bf51e0d1-b498-450c-a381-7d7d6ef721ec"
                            }
                        ]
                    },
                    {
                        "order": 2,
                        prompt: "Ceiling:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f0b8fe4b-5e6b-4034-ab14-f66fd0b6116f",
                                "recommendation": "<p>Cracks present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5ed8fada-6278-4aa9-85b8-4fd63c702ffd",
                                "recommendation": "<p>Loose or peeling paint</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a033a9b3-aaf3-4fa8-82b3-38eb18ffed10",
                                "recommendation": "<p>Loose paneling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2f00b828-4c54-4189-9f9c-a9cbb0da4bce",
                                "recommendation": "<p>Loose plaster</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b3be1984-6a8e-45a9-9f87-6eed02cf3f31",
                                "recommendation": "<p>Water stains present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "795c4282-cf51-4414-a365-09d059b7dfaa",
                                "recommendation": "<p>Stress cracking, see structural notes</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "399dec03-5839-4a75-9727-bd54ff0e6c11",
                                "recommendation": "<p>Evidence of possible truss uplift-repair as required, monitor for further movement</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0d74d4d0-6e74-435b-becd-0982119647cf",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0dbf2234-89c8-4bac-a70f-c4f21ead111d",
                                "recommendation": "<p>Mold is present and is a potential health concern. Removal and/or correction is required.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3f50a37a-b9a4-4457-9d3c-6bb36e96880c",
                                "recommendation": "<p>Evidence of past or present water staining</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "39b05f68-b320-4bc2-a9fb-6af6fbfda72f",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Paint",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Exposed framing",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Mirrors",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Texture paint",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Suspended ceiling",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "c9cef8cf-f4d7-4c2f-8bc6-71650581aac1"
                            }
                        ]
                    },
                    {
                        "order": 3,
                        prompt: "Walls:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f3e879ab-b15f-4187-9da7-5539e64d26e9",
                                "recommendation": "<p>Cracks present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5c5f529e-a5a5-4e1e-9a5d-0db88fcda82f",
                                "recommendation": "<p>Evidence of past or present water leakage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "634558f6-e0e8-4dec-a2aa-c53064073a39",
                                "recommendation": "<p>Evidence of possible truss uplift</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "218549b9-93aa-4d91-a8fc-e28797e9b36e",
                                "recommendation": "<p>Loose or peeling paint</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e62a2278-4d28-4af3-82a7-f818e0abf028",
                                "recommendation": "<p>Loose paneling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ca664437-9207-407f-85b4-7adbeabbee3d",
                                "recommendation": "<p>Water stains present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "07aa4955-88ba-433b-be71-99133c15bd66",
                                "recommendation": "<p>Evidence of past or present water staining</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "163cfcf4-d309-41c4-a141-310405bb721b",
                                "recommendation": "<p>Loose or missing tile</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "dd1746a5-101e-4c9f-b028-62cef0bd3b86",
                                "recommendation": "<p>Mold present-potential health concern-needs removal/correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e9b67c1f-3fef-49d6-978c-70304d40195d",
                                "recommendation": "<p>Paneling loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2f3f20be-f07b-47d7-aa0f-b5a4df43bfab",
                                "recommendation": "<p>Some ceiling pads damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e6f37f4b-4a7a-42b9-8f99-1662054bef26",
                                "recommendation": "<p>Some evidence of previous truss uplift</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cd31acf0-ab09-4bef-867d-b67eca8ce89b",
                                "recommendation": "<p>Inspection does not cover any damage concealed by rugs, carpeting, wall paneling, furniture</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "79c4427a-cb6b-42e4-968d-237a14ada06d",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "db102db8-7ea4-431f-b5f8-a8bfc56d1e78",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Paint and paneling",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Exposed framing",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Paint and tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wallpaper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Paint",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Concrete",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wallpaper and tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fire rated",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "cfe48fbb-f486-43b5-8185-6e6fbeb6683c"
                            }
                        ]
                    },
                    {
                        "order": 4,
                        prompt: "Floor:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "35dc2c7f-8843-4ee0-97ab-e3be4c0f05ac",
                                "recommendation": "<p>Broken floorboard</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b2e0ffb6-2275-43d7-a03a-7cbfd0bc3a71",
                                "recommendation": "<p>Damaged carpet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7a2cfb32-7614-4e7c-95fe-428b573d0ee0",
                                "recommendation": "<p>Loose carpet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "55c31b00-6b1a-415e-84f1-5d8c319d02b7",
                                "recommendation": "<p>Loose flooring</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "37f126d1-8a20-4366-af6a-8902f8710d87",
                                "recommendation": "<p>The floor squeaks when walked on</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d3dd5d27-66be-40bf-a6e6-3f8d56f781a5",
                                "recommendation": "<p>This inspection does not cover any damage concealed by carpeting, rugs or furniture</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "00f74e8c-0dca-4860-bf22-16fe4d123232",
                                "recommendation": "<p>Water damage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a4310c07-0f3c-49c6-944a-dc9b5f63e930",
                                "recommendation": "<p>Noted uneven floor due to settlement</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4a4de6e5-5dec-4c0b-9307-d9f4033aba53",
                                "recommendation": "<p>Carpet Stained/soiled</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0c654b7b-855e-48f2-a61c-e6bb08f28a74",
                                "recommendation": "<p>Worn wood floor finish</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e8194f05-a72e-43c8-9281-cc7e4c67996c",
                                "recommendation": "<p>Cuts or minor damage in flooring, repair/replace as required. Restretch carpets- trip hazard</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "dff0dba5-0685-426e-9e0b-604e61ce2e8f",
                                "recommendation": "<p>Floor shows evidence of previous water penetration/stains</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "30528e7d-40d5-4bec-bfb3-8e3afcea2c08",
                                "recommendation": "<p>Worn carpets</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2119c3ad-2ce3-41b9-8c50-2f5b7d9593c6",
                                "recommendation": "<p>Termite damage noted in wood flooring, repairs required</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "83f76c0e-6f56-4161-83f0-a64c0796a5ce",
                                "recommendation": "<p>Gapping in hardwood flooring noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "54378c22-76c6-4317-a81f-7eb02f2d52ed",
                                "recommendation": "<p>Floor squeaks noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ed3cd00b-d2f2-4f19-bbcb-a7fa1d1ce0a3",
                                "recommendation": "<p>Raised seams in vinyl flooring-repair</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5f5a7d1d-c808-4dee-bd2e-f7f5cb414ecd",
                                "recommendation": "<p>Uneven wood floor finish (difference under carpets/rugs) due to sunlight</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2803211b-9f3f-4a50-9ba2-a85ba8c82f8d",
                                "recommendation": "<p>Loose tile/grout, repair as required</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "0e3a5d86-bd6b-4bc1-a23a-4ebdbb854171",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Hardwood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Ceramic tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Linoleum",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Carpet",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl floor covering",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Poured",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "e9eb5299-f03e-40af-9e65-ad495027859a"
                            }
                        ]
                    },
                    {
                        "order": 5,
                        prompt: "Doors:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f5856d11-d016-44da-bcc2-f15794c99924",
                                "recommendation": "<p>Loose hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ecb6aa19-95ab-4376-aae4-9fbb870b1120",
                                "recommendation": "<p>Missing hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8d28c5cc-3678-4b6d-af18-70373ec70b90",
                                "recommendation": "<p>The door has moisture damage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9b18c4c2-1b0f-4391-b50d-c6eab7c2d529",
                                "recommendation": "<p>The door is sticking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f9ae6b24-66ad-46d1-84ff-e1a81b546bcc",
                                "recommendation": "<p>The surface laminate is peeling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d114d180-8fda-4486-8091-e0a3e08ec564",
                                "recommendation": "<p>Wood rot</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5e457416-b0eb-4491-90f1-f66bae2729b0",
                                "recommendation": "<p>Lockset(s) not latching-requires adjustment</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "678267ed-0672-4eeb-ab64-00d82f88eec3",
                                "recommendation": "<p>Door(s) has minor damage from door stop-repair/replace</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "40cfb5d8-4f93-44e2-8a89-f1b4d6c90ec5",
                                "recommendation": "<p>Lockset(s) not functioning-repair</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8adb848f-1d52-4376-982f-8325fc5d80a7",
                                "recommendation": "<p>Replace missing door(s)</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "75647b2d-45bb-4e41-9751-29a06aa9f249",
                                "recommendation": "<p>Door damaged by moisture</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a83f43e0-f4a2-4899-ac00-24e01f9cc7c6",
                                "recommendation": "<p>Doors need trimming/adjustment</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "29c1a1ec-25c4-4925-9c3a-5b4255263ace",
                                "recommendation": "<p>Replace missing bypass hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6eea061e-ea21-45be-b4da-8ac790ec52e7",
                                "recommendation": "<p>Lockset hardware installed opposite with locking knob to outside of room-needs correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "55ceef06-4fc5-443f-a28c-8ff202106936",
                                "recommendation": "<p>Align/adjust closet doors</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9c38d6eb-4ffc-411e-8873-b6ca45c6cfe5",
                                "recommendation": "<p>Undercut where rubbing on carpet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "6ebef4af-df4a-408a-bc39-e9973f177ee2",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Hollow wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "French",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Bi-fold",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Solid wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Dutch",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "bd4e5478-0603-4ba3-9193-84a07f936cfe"
                            }
                        ]
                    },
                    {
                        "order": 6,
                        prompt: "Windows:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bd318267-0bfd-440f-aa9a-964728cb699f",
                                "recommendation": "<p>Broken glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3494d31a-ed31-4511-bcb3-b7065b948ecc",
                                "recommendation": "<p>Repair broken sash cords</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1b52b007-74f6-40bc-9de3-d05949490d31",
                                "recommendation": "<p>Cracked glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "580b00ac-10b1-45c5-964e-a435fd87da31",
                                "recommendation": "<p>Hard operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8791301a-c147-4d3f-a31b-50d584898b21",
                                "recommendation": "<p>Moisture present inside the layers of glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "03a95247-4636-4866-9bd7-a3070780483c",
                                "recommendation": "<p>The window will not open</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "05c427f9-5440-4cb8-bca3-4f5a5d41e7a0",
                                "recommendation": "<p>Window has fogging between the layers of glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6a5de061-8be1-4018-b199-4b14ec3aed4f",
                                "recommendation": "<p>Window seal broken</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1936e5dd-c8be-4537-a5d1-e004f8bca5dc",
                                "recommendation": "<p>Window sticks</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2ab07d30-b64f-42ef-8675-b7dd8f76d8b9",
                                "recommendation": "<p>Replace missing lock hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "542fa5f4-1c88-44dc-84e1-931439b409d1",
                                "recommendation": "<p>Exterior glazing repairs required</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0b4b63b5-fbb0-4e9f-9c42-6a040810297e",
                                "recommendation": "<p>Painted shut</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "af011541-d713-435f-8e0e-96bffd53fc8c",
                                "recommendation": "<p>Window sticks-improve operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a8b385c6-686d-4105-ba2a-a36c238926bd",
                                "recommendation": "<p>Will not open-requires correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6f47251e-ff9d-4b4b-9cbc-a167dbc220e6",
                                "recommendation": "<p>Repair faulty operating hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1a7ecb34-a9ba-415e-8c49-eb10d32e9978",
                                "recommendation": "<p>Locks do not align/lock-need correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0fd0ef0c-cc20-48dd-bf61-0e6791acebb5",
                                "recommendation": "<p>Replace missing casement crank hardware handles</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6835ac02-8e52-468b-97e6-bc4806a9d4a2",
                                "recommendation": "<p>A qualified glazier is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0e3c1a8f-87f2-4829-b6af-cc7775dba7d5",
                                "recommendation": "<p>Replace weather stripping</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9e91c79e-2eec-4623-b8f2-eb7f73d1826b",
                                "recommendation": "<p>Replace missing pull hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "854f1973-11c7-475b-afc6-ebf9e937fb64",
                                "recommendation": "<p>Hard operation-needs improvements for ease of operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a6390a53-0e38-40c0-9969-a36c607671e7",
                                "recommendation": "<p>Repair/replace damaged colonial grids</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c86e8467-4452-43b3-a24b-7e9a762797ef",
                                "recommendation": "<p>Crack in marble sill noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "9347260c-25be-47f1-9c6a-e2bae3c20d98",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Aluminum slider",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Aluminum double hung",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Non-opening",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood double hung",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Aluminum casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl double hung",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl slider",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steel casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steel frame",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood slider",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "c9e5fc21-674a-478d-b99d-3693d38c8585"
                            }
                        ]
                    },
                    {
                        "order": 7,
                        prompt: "Electrical:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "028cbf16-afeb-41e0-bcc6-d67b9c718093",
                                "recommendation": "<p>Open or missing ground</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2d575096-9c12-4586-a527-873ca06bff22",
                                "recommendation": "<p>Suggest evaluation by licensed electrician</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "491c5b10-0bda-4c77-95e0-6f79cbf8cb4a",
                                "recommendation": "<p>Non-GFCI circuit</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9c84daa8-25f4-4c46-8525-38cf07e9caca",
                                "recommendation": "<p>Reversed polarity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "192a6f4e-b6db-4677-ba0a-4265ac9e4f9e",
                                "recommendation": "<p>Install missing junction box cover plates</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2eebca0b-207b-4e8a-b654-9ff5d1bef91b",
                                "recommendation": "<p>Install missing junction boxes and cover plates at open splice connections</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8cba0ad9-1051-4dab-b399-bb8ec82aaa35",
                                "recommendation": "<p>Protect exposed surface mounted wiring in conduit to 7'-0' above floor</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "64a44d7e-4cf2-43fb-b55c-029941416950",
                                "recommendation": "<p>Faulty GFCI outlet-replace</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "66521b8f-b1d5-413b-8e06-15674806429b",
                                "recommendation": "<p>Reversed polarity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "21a1e506-cc55-42ec-ad84-7ef9f53c087a",
                                "recommendation": "<p>Missing outlet/switch cover plates</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f10cc94f-1de7-403c-92b5-efd936708e87",
                                "recommendation": "<p>Loose switch or outlet should be resecured</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f08d0c2c-9ab6-47af-b2c9-2912548897d6",
                                "recommendation": "<p>Ceiling fan inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "123545b0-be04-4aee-b54a-b33a0f0a6a6f",
                                "recommendation": "<p>Worn outlets creating intermittent ground- replace outlets</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "78ec2dfe-6c92-4fd2-9dbf-f6fdda0d18ce",
                                "recommendation": "<p>Light fixture not functional-repair/replace</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "34444b6b-ba6a-4ed5-aa56-16bfe0c7adfa",
                                "recommendation": "<p>Open grounds noted at 3 prong outlets</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9c867646-d8ac-4600-8f1d-2ab24fec4ddb",
                                "recommendation": "<p>Remove temporary wiring running through walls -amateur installation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "17627de1-15bd-4b30-91c9-beec9a86978d",
                                "recommendation": "<p>Outlets not working</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7e2cb288-5b30-4a50-87af-c50b70996277",
                                "recommendation": "<p>Non-GFCI circuit -recommend GFCI circuit be installed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bd0a2bfe-e0a9-4d81-8ca4-d378d0931300",
                                "recommendation": "<p>Insufficient outlets for space</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "187829fb-9eee-490e-8601-981810ea1bfe",
                                "recommendation": "<p>A licensed electrician is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0158f3aa-5bbd-4a65-903c-2744ba13e165",
                                "recommendation": "<p>Replace damaged outlet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2772d655-8e28-479d-a9ff-2b4ce6eb0480",
                                "recommendation": "<p>110 VAC Outlets, Lighting Circuit and ceiling fan</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c333b57c-1f51-4c84-bc91-1b59a3947ee9",
                                "recommendation": "<p>Install missing outlet/switch cover plate(s)</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "53186278-a907-4f5f-a5da-09cdaf9d7d4f",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "110 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC GFCI",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC/220 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "220 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC outlets and lighting circuits",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "a6765599-9e5b-48af-8403-f3df43a1d36f"
                            }
                        ]
                    },
                    {
                        "order": 8,
                        prompt: "Counter/Cabinet:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "78e2ef8a-13e9-4723-add8-92905a28c1f2",
                                "recommendation": "<p>Cabinet not properly mounted to wall</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "336f2cd8-ac22-4460-ab52-eb0722964534",
                                "recommendation": "<p>Loose counter top</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "07527aa6-7b90-4d46-a677-53dfb0cf7929",
                                "recommendation": "<p>Moisture damage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d1e812b1-3de1-45f7-93f4-a405d22ee538",
                                "recommendation": "<p>Needs caulking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "38f20a49-7223-4ea9-8781-ac665ee463ae",
                                "recommendation": "<p>Peeling laminate</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "755d36f1-fa39-4c0d-ae19-104675c444f3",
                                "recommendation": "<p>Resecure vanity base cabinet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e527f5e1-84e7-435b-a374-5f0ae6163829",
                                "recommendation": "<p>Resecure vanity sink bowl to base cabinet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1ed78eae-525a-4564-bdc5-4e202549f960",
                                "recommendation": "<p>Water damage present under the counter</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c3fb7985-1559-4aa4-baeb-94fdc0a42c64",
                                "recommendation": "<p>Surface laminate loose or peeling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e96dd16d-3760-4533-a090-bfc6bf510f55",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "046de4e0-d15b-435a-918a-ba71ce12156a",
                                "recommendation": "<p>Worn finish</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3f464471-21b4-4aee-8f09-95d142f4c4c5",
                                "recommendation": "<p>Minor nicks/scratches in finish noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b4ca52f5-c4c4-458e-a92a-3c24c7d80bb9",
                                "recommendation": "<p>Install drawer/door hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fddc5dc7-457a-4b95-87ef-612f86b144ea",
                                "recommendation": "<p>Mold present under the counter(possible health concern)-needs removal/correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "e955d585-6d73-4ec8-b1d3-3d26e4c5946e",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Composite and wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Laminate",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Laminate and wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "d40016ff-80c5-458d-a00e-00c629aa2459"
                            }
                        ]
                    },
                    {
                        "order": 9,
                        prompt: "Sink/Basin:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c43fac52-c647-42f1-bd0b-d6faff6568ef",
                                "recommendation": "<p>Cracks present in the sink bowl</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9304fff9-8c17-414b-a232-59df2ce3b8f9",
                                "recommendation": "<p>Drain trap leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b4429b3b-931a-4155-a40b-1f00a6ccdddf",
                                "recommendation": "<p>Leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bfc14e1a-e29e-4b86-b689-97bde47401f1",
                                "recommendation": "<p>Rust present in the sink bowl</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "72cdf9a3-012a-4793-ba79-34c4d1a67c77",
                                "recommendation": "<p>Stopper missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2bca3082-3af0-480c-bb57-d3abd78d7793",
                                "recommendation": "<p>The sink is loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "8db467d1-5b09-414d-a74e-31968e646f20",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Molded dual bowl",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Porcelain coated",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Molded single bowl",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Pedestal",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "One piece sink/counter top",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Porcelain",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Stainless Steel",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "One piece sink/counter tops",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Corian",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Granite",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Granite/Porcelain",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Synthetic",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "7b5e4016-389f-4ca2-b878-863deb5fdd25"
                            }
                        ]
                    },
                    {
                        "order": 10,
                        prompt: "Faucets/Traps:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7caffa45-22ac-4888-b3c1-1ff93377e3a9",
                                "recommendation": "<p>A licensed plumber recommended to estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "67d84e5a-6f9c-404f-88b2-331c6dfa051d",
                                "recommendation": "<p>Hot and cold operation reversed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "377e6e67-c41e-4a93-843c-429234e31feb",
                                "recommendation": "<p>Leaking faucet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "927cd753-22c3-429a-bac5-e2a42f968dbc",
                                "recommendation": "<p>Leaking trap</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bd78b6c0-b865-4ef3-abb8-523f7d612686",
                                "recommendation": "<p>Slow draining noted-further review/repair</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "08694956-ffbd-4a3a-9575-d6edbbf38f23",
                                "recommendation": "<p>Leaking drain</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "96b7ef9a-e732-472e-bd50-3b26b39598a6",
                                "recommendation": "<p>Leaking supply line</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "26077cfc-7627-40c7-8273-982d5af3265c",
                                "recommendation": "<p>Faulty fill valve at toilet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "86ed2f15-acec-48c3-8c25-ce1010bd8a43",
                                "recommendation": "<p>Shower diverter not functioning properly, all water is not flowing to showerhead</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "09bff191-b2d5-4ccb-9e64-ee3b665bb99c",
                                "recommendation": "<p>Damage to drain trap-may cause leak</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "06c68d0f-121a-435e-94d2-b4dd70669e7e",
                                "recommendation": "<p>Clogged or non-functioning vegetable sprayer</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b70a3992-8587-4664-a2b1-83e463928a99",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6da591aa-970d-438b-a12e-209c97e2698a",
                                "recommendation": "<p>Unable to test due to water being shut off to house. Recommend having water service turned on and have qualified plumbing contractor certify function</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d6d92fe0-44b8-453c-a385-e74bc8d80086",
                                "recommendation": "<p>Build up found in aerator of sink fixture possibly caused by deteriorated water heater dip tube, further review is required</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7a81fbec-86e1-4956-ba61-187d5effcc63",
                                "recommendation": "<p>Pitting at drain line/trap-recommend replace</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b639f74c-cbcc-4514-b262-6e5e62763fb3",
                                "recommendation": "<p>'S' Type drain traps do not meet today's standards</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "045862bd-21e3-400a-9472-559e5f6ec620",
                                "recommendation": "<p>Leaking tub faucet at stem</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "16a90de8-764e-4ec0-8274-81be08082ef5",
                                "recommendation": "<p>Missing or faulty drain stopper</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "54c098d0-07ed-46d0-bbcc-f57524fa2e40",
                                "recommendation": "<p>Resecure loose tub overflow plate</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "342c0385-8d91-4d92-8230-abd9c0bc0067",
                                "recommendation": "<p>Leaking sink faucet at stem-repair</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "357909c6-7199-444d-a866-d2821b47cbdf",
                                "recommendation": "<p>Low pressure or flow rate at faucet, further review and/or repair is indicated</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5db52288-3ea0-44d8-9c48-565771df8ee8",
                                "recommendation": "<p>Worn finish</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0804b73c-1efc-467e-b9db-a92dd380acab",
                                "recommendation": "<p>Leaking shower head</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "375d3a31-6b2b-4045-b22a-565527485438",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Delta fixtures with a metal trap",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Delta fixtures with a PVC trap",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Moen fixtures with a metal trap",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Moen fixtures with a PVC trap",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not installed to date",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not visible",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Winterized",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "d9447293-0214-4362-8be6-fcd563ac36c6"
                            }
                        ]
                    },
                    {
                        "order": 11,
                        prompt: "Tub/Surround:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "50271694-4264-445c-8263-1c438248c300",
                                "recommendation": "<p>Chipped or rusting tub</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5d939168-f289-4321-9174-d4daff867173",
                                "recommendation": "<p>Loose grout</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "026d6681-1624-421b-98db-8dff4dab1540",
                                "recommendation": "<p>Loose or damaged tile</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "19b95ae9-d7ca-4277-8ffe-377a80c5d839",
                                "recommendation": "<p>Low water pressure</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d71200a9-4cb6-469b-9930-c9404d95f905",
                                "recommendation": "<p>Obvious water damage due to water penetration</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "acdee9b0-f4f6-442d-825b-cd75da4af6ba",
                                "recommendation": "<p>Replace caulking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1ffc4438-764e-4885-b656-9dae73f07a60",
                                "recommendation": "<p>Undulations observed in surround wall, possible damage to wood members behind the wall may have occurred</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "4fa40e70-9fdd-47e5-aaa7-1948fb866b6f",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Porcelain tub and ceramic tile surround",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fiberglass tub and ceramic tile surround",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fiberglass tub and fiberglass surround",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Porcelain tub and fiberglass surround",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "59e4ed3c-7d92-4728-bade-775ee484ff92"
                            }
                        ]
                    },
                    {
                        "order": 12,
                        prompt: "Shower/Surround:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d67cda8e-0a35-4b32-b59d-f3b9ffaab8ae",
                                "recommendation": "<p>Cracked or leaking shower pan</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6c3ea84c-7158-4025-bf51-caca44e62172",
                                "recommendation": "<p>Loose grout</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8e620554-1922-4543-a470-d5fbd9c2abc9",
                                "recommendation": "<p>Loose surround wall</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9f8b3b84-e447-4b95-870c-3470b11e8900",
                                "recommendation": "<p>Loose tile</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3fc99afa-77bf-444f-937d-effc5b58d2e3",
                                "recommendation": "<p>Mildew present above shower</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c16d4561-2a69-4fae-bebe-392affa23b49",
                                "recommendation": "<p>Replace old caulking at the shower pan</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "77651fdf-aeed-433b-b6e1-8d3b29f2c739",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Fiberglass pan and ceramic tile surround",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fiberglass pan and fiberglass surround",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Plastic pan and metal surround",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Plastic pan and plastic surround",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Porcelain pan and ceramic tile surround",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Porcelain pan and fiberglass surround",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "200735fb-8043-4601-912e-5a58261abac2"
                            }
                        ]
                    },
                    {
                        "order": 13,
                        prompt: "Spa Tub/Surround:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9331fd6c-54b9-4b54-ac1f-a674d3b1e9c4",
                                "recommendation": "<p>Jets clogged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f8e6f1c6-04fb-49ae-9f4f-e50b8c546f7e",
                                "recommendation": "<p>Pump inoperable</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2c2cd4b4-7509-4cb7-89f1-9d86325e9c38",
                                "recommendation": "<p>Aerators inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "8bb346e8-4466-4386-8743-8526e7c6ef88",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Porcelain tub and ceramic tile surround",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fiberglass tub and ceramic tile surround",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fiberglass tub and fiberglass surround",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Porcelain tub and fiberglass surround",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "7b300a89-46ab-4c41-82e0-271a155ccee2"
                            }
                        ]
                    },
                    {
                        "order": 14,
                        prompt: "Toilets:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "78beb2f7-f1eb-43a7-85e0-3c8d5f130e49",
                                "recommendation": "<p>The tank is loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5f211d59-1f27-4b51-90bd-5fc6b6126353",
                                "recommendation": "<p>The toilet is loose at the floor and will require replacement of the wax seal</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "da833444-7d4b-46e2-969d-b622d71e4284",
                                "recommendation": "<p>Water continues to run after flushing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "afc8f4b0-9999-412e-a045-b2dfdd0c432c",
                                "recommendation": "<p>Water leaking at tank seal</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "999abc18-3f7f-47a3-af81-0dfbbc7cb47e",
                                "recommendation": "<p>Water leaking at wax seal</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "80de494f-93c4-4fd7-a09b-e7da981667ab",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "American Standard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Moen",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Koehler",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "1 1/2 Gallon Tank",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "3 Gallon Tank",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "1656914d-c874-49e4-9433-ae763f0e7d28"
                            }
                        ]
                    },
                    {
                        "order": 15,
                        prompt: "HVAC Source:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1556e352-00c1-425d-8b56-7db283c8aa82",
                                "recommendation": "<p>Heating system inoperable</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "23aa89ca-cf67-4bb8-92ec-f02df298f411",
                                "recommendation": "<p>Low air flow</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "570db1db-64d9-4beb-92a9-0bc14da7ad1b",
                                "recommendation": "<p>No heat duct</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1ecda770-0a9e-4b5e-a21e-1120f6ee0f29",
                                "recommendation": "<p>Register cover damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5edb2bba-2d2c-42e8-b4a6-33919d2b598a",
                                "recommendation": "<p>Register cover missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "0521712d-9e41-4e69-a5a5-1ce6f3e55b7e",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Heating system register",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Convection baseboard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Air exchange ventilation",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Radiant heat",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "525cfdd2-00bf-4227-99f7-a57409df0caa"
                            }
                        ]
                    },
                    {
                        "order": 16,
                        prompt: "Ventilation:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "075d134e-3f3b-4d70-9c24-b178f3730a76",
                                "recommendation": "<p>Fan inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3df1662f-67d6-4f42-8bdf-db27b6a77344",
                                "recommendation": "<p>No ventilation present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9f12201c-43c5-4a10-88a0-64f9fe359734",
                                "recommendation": "<p>Window painted shut</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "ff57458c-d1f9-4219-a2f5-c4346fe6ffc2",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Electric ventilation fan",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Electric ventilation fan and window",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Electric ventilation fan with heater",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "No ventilation",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Window",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "8eba2796-9938-493f-9f18-b82a5cca24ee"
                            }
                        ]
                    },
                    {
                        "order": 17,
                        prompt: "Closet:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e1988314-fa81-40ec-b64e-c120f8e45c2d",
                                "recommendation": "<p>Missing clothes pole</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c34e0798-acd3-420d-8384-5f3e9150f9ae",
                                "recommendation": "<p>Walls in closet have cracks not seen in room</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7e293319-446e-4937-bd36-0d2696a1d663",
                                "recommendation": "<p>Water stains noted at closet interior wall</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9213490b-0241-4ce2-b3ad-7f534815da52",
                                "recommendation": "<p>Repairs needed at bypass closet doors</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "3c0a8dce-14fd-4dd6-8b8e-1e72d57e744f",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Single small",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Walk In",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Small & Walk-in",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Large",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Single",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Small",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Walk In and Large",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "be607f76-bef1-4164-89d3-4df0856afaf3"
                            }
                        ]
                    },
                    {
                        "order": 18,
                        prompt: "Ceiling:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f492ffb6-c988-41ee-9830-e525680d2551",
                                "recommendation": "<p>Cracks present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0cff899d-ddc4-4e66-bc68-e7e4d01ee5af",
                                "recommendation": "<p>Loose or peeling paint</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "eed92afb-5140-4721-822e-99025266a4a6",
                                "recommendation": "<p>Loose paneling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "877adf40-939f-471f-97c5-9eb182b385c7",
                                "recommendation": "<p>Loose plaster</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "587911fc-0760-4e6c-b673-cbccbf625aac",
                                "recommendation": "<p>Water stains present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "333f9435-4f47-4778-a0d1-8c077f5b6875",
                                "recommendation": "<p>Stress cracking, see structural notes</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "dc161088-6222-4eb9-9d6f-3ae6af84d79a",
                                "recommendation": "<p>Evidence of possible truss uplift-repair as required, monitor for further movement</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4b07052d-a0d9-4889-a799-9c23b3452432",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "71705507-bf3c-470b-99e9-b9684b61616b",
                                "recommendation": "<p>Mold is present and is a potential health concern. Removal and/or correction is required.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "78dedb15-f4a3-4846-9f1d-650726f634df",
                                "recommendation": "<p>Evidence of past or present water staining</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "bc3bffb7-0369-464f-aa53-df47cc53d434",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Paint",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Exposed framing",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Mirrors",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Texture paint",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Suspended ceiling",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "f5609b2e-d980-47c8-afbc-287acb7517d7"
                            }
                        ]
                    },
                    {
                        "order": 19,
                        prompt: "Walls:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8661f6fc-d6b7-4317-aae0-a85c057f8e56",
                                "recommendation": "<p>Cracks present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "083b481b-ed2c-4ec4-b8cc-9fdc56ccd9e3",
                                "recommendation": "<p>Evidence of past or present water leakage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f029d046-b0f8-4135-b75d-44c9dccac3c7",
                                "recommendation": "<p>Evidence of possible truss uplift</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1cafa686-4cd8-4649-a547-ac5711638349",
                                "recommendation": "<p>Loose or peeling paint</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "636a7584-eb01-4173-a7db-975d1ee290bf",
                                "recommendation": "<p>Loose paneling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0db66f8d-c5ef-4469-a95e-4d757ca2b7d2",
                                "recommendation": "<p>Water stains present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6897ada6-4e2b-4ea0-b813-dc464627bd44",
                                "recommendation": "<p>Evidence of past or present water staining</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e1db76e6-1bd4-4375-a6ed-e3615afe7fab",
                                "recommendation": "<p>Loose or missing tile</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "de8fc2ea-1c19-4ee9-868f-6eb917df93c1",
                                "recommendation": "<p>Mold present-potential health concern-needs removal/correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "76fcd2dc-98d3-4fc6-bf9a-3126c17c6d2a",
                                "recommendation": "<p>Paneling loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8b409721-9af2-4f7c-bf59-dd04cd4342fd",
                                "recommendation": "<p>Some ceiling pads damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cbe80667-b7bb-4e12-b1b9-260fafc4ea1b",
                                "recommendation": "<p>Some evidence of previous truss uplift</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f3f917fa-fc28-4e89-b2ff-579a62ff90d6",
                                "recommendation": "<p>Inspection does not cover any damage concealed by rugs, carpeting, wall paneling, furniture</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "362266e6-fde4-48aa-bd8d-42175544f6d4",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "7474508a-c01d-49cb-8fd2-f7a8eca57bb2",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Paint and paneling",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Exposed framing",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Paint and tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wallpaper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Paint",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Concrete",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wallpaper and tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fire rated",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "ff323711-4a69-456b-8ca7-c28842b5b50d"
                            }
                        ]
                    },
                    {
                        "order": 20,
                        prompt: "Floor:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5d90a60c-491a-4a8e-a202-ad992ebed5ce",
                                "recommendation": "<p>Broken floorboard</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "827be367-b5e5-4801-a61c-31a81cb7e685",
                                "recommendation": "<p>Damaged carpet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "056c850f-415c-4a0e-a576-66ed5a1d3b20",
                                "recommendation": "<p>Loose carpet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "74825800-bcf5-4f74-9ace-4d3ffe3f2cca",
                                "recommendation": "<p>Loose flooring</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "384af84d-6bc9-472d-b39c-f92b593da6c2",
                                "recommendation": "<p>The floor squeaks when walked on</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d424442a-e65c-401e-b9a6-7d81f12e4856",
                                "recommendation": "<p>This inspection does not cover any damage concealed by carpeting, rugs or furniture</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "67055da7-87bc-4490-ae04-aa3cee2a24f0",
                                "recommendation": "<p>Water damage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6cb0f69c-38d0-440f-8b97-5f12859e6c59",
                                "recommendation": "<p>Noted uneven floor due to settlement</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bea41124-c190-4852-84ef-4448941b426c",
                                "recommendation": "<p>Carpet Stained/soiled</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a606cf50-f526-4b58-a04b-a72d0abe6d03",
                                "recommendation": "<p>Worn wood floor finish</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cf62caac-8cfc-4874-96a6-a24ed2c558bb",
                                "recommendation": "<p>Cuts or minor damage in flooring, repair/replace as required. Restretch carpets- trip hazard</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f4659b65-c348-44f7-b3eb-197653a44b6d",
                                "recommendation": "<p>Floor shows evidence of previous water penetration/stains</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1d069146-bd08-4b44-b2d7-e20b233fd8b6",
                                "recommendation": "<p>Worn carpets</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5dcc353b-bdd8-445e-84d1-124f35c68dbc",
                                "recommendation": "<p>Termite damage noted in wood flooring, repairs required</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "276778b3-70cc-4d6c-8eb9-c348f902db21",
                                "recommendation": "<p>Gapping in hardwood flooring noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "77bbe437-352a-4bb6-8c5e-11ee5c567521",
                                "recommendation": "<p>Floor squeaks noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2d270bb7-d122-4e71-9c10-5b90da3d0b31",
                                "recommendation": "<p>Raised seams in vinyl flooring-repair</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e07be85a-e1c1-4784-8618-70a9c3db5693",
                                "recommendation": "<p>Uneven wood floor finish (difference under carpets/rugs) due to sunlight</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9b64a479-2a65-46e2-aaa7-0eddc9a2595d",
                                "recommendation": "<p>Loose tile/grout, repair as required</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "3f609100-3c7a-460b-a224-d287e7a237d9",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Hardwood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Ceramic tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Linoleum",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Carpet",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl floor covering",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Poured",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "9cfc80ec-fee4-46a9-bb41-b3ad5e985506"
                            }
                        ]
                    },
                    {
                        "order": 21,
                        prompt: "Doors:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f06bb9ce-0179-48cd-8dd4-2eb213febc4c",
                                "recommendation": "<p>Loose hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d0fae14a-e08d-4072-9653-cac710108080",
                                "recommendation": "<p>Missing hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d9fec1fb-8f6d-4909-8eba-cf0d4f8a04ff",
                                "recommendation": "<p>The door has moisture damage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b37c19ca-9497-407a-aed6-77cb9f6c54ea",
                                "recommendation": "<p>The door is sticking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6d799cef-ba39-47be-81d8-5ff6cb127466",
                                "recommendation": "<p>The surface laminate is peeling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0f1d9c40-af56-4300-bf55-d463f13d0b40",
                                "recommendation": "<p>Wood rot</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4d7cc519-28c8-404d-856d-bf7630fda7c0",
                                "recommendation": "<p>Lockset(s) not latching-requires adjustment</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cc5b1968-6e1b-475a-a794-4d1c7c74e791",
                                "recommendation": "<p>Door(s) has minor damage from door stop-repair/replace</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "666c63c7-ea0c-4ab9-a3aa-f51d598ad70a",
                                "recommendation": "<p>Lockset(s) not functioning-repair</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "66786f63-7a4a-4646-982e-c8a4319859fc",
                                "recommendation": "<p>Replace missing door(s)</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9988a03b-c975-4f55-9d9f-b9064a7ab9dd",
                                "recommendation": "<p>Door damaged by moisture</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "23fe3789-a3da-4b1c-abc4-11248faa0737",
                                "recommendation": "<p>Doors need trimming/adjustment</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0f0e4bfe-9fda-498f-b3ae-416061bf3c30",
                                "recommendation": "<p>Replace missing bypass hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bdeb50ee-6011-4be6-a965-efe7e57eb5ac",
                                "recommendation": "<p>Lockset hardware installed opposite with locking knob to outside of room-needs correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1e355883-a2bd-4d91-9437-36853556caa5",
                                "recommendation": "<p>Align/adjust closet doors</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "734f0d62-681d-4bee-9858-f05232432527",
                                "recommendation": "<p>Undercut where rubbing on carpet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "66b777ae-4bcc-487f-9385-0c7b5802c7c5",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Hollow wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "French",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Bi-fold",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Solid wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Dutch",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "49c3b2a1-1041-4d63-bda2-424bd0bd982f"
                            }
                        ]
                    },
                    {
                        "order": 22,
                        prompt: "Windows:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "880c2302-5244-41b3-8d9e-e7d93b745fcc",
                                "recommendation": "<p>Broken glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "81d58148-c479-4ac5-aaa0-5c069e4c9a26",
                                "recommendation": "<p>Repair broken sash cords</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "76175f7e-6548-46f5-9f43-9ceb0bb43afe",
                                "recommendation": "<p>Cracked glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3e40eaed-9772-4721-8ae6-72856804faee",
                                "recommendation": "<p>Hard operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8834cedf-1061-4a03-b1a6-76e0e04daef5",
                                "recommendation": "<p>Moisture present inside the layers of glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fad66bc3-a706-46ed-bc37-68fde73192ec",
                                "recommendation": "<p>The window will not open</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "18f028bd-aabb-48c3-b919-ab1743b3db6e",
                                "recommendation": "<p>Window has fogging between the layers of glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6916e85e-403b-47ba-802b-9f3482e645d0",
                                "recommendation": "<p>Window seal broken</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d24a063e-73e1-4d87-b667-1a14348bdaa5",
                                "recommendation": "<p>Window sticks</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bcee45d8-a72f-4db4-8a83-c5c80f24bd83",
                                "recommendation": "<p>Replace missing lock hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8cd8f0f3-0502-401a-9fba-64ff612a3fc2",
                                "recommendation": "<p>Exterior glazing repairs required</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "80071f23-a886-4e39-833c-e74ca722a772",
                                "recommendation": "<p>Painted shut</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "eea118ba-a513-4c4f-b085-552d144a7939",
                                "recommendation": "<p>Window sticks-improve operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0f0fc01f-52dd-4f0d-b450-77f5c456926d",
                                "recommendation": "<p>Will not open-requires correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "33e69e3c-9670-4ce1-8779-0dbdae4d0371",
                                "recommendation": "<p>Repair faulty operating hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "07c77f92-8813-4c57-8c93-d70408e10596",
                                "recommendation": "<p>Locks do not align/lock-need correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8d5ae669-2bab-4f9f-941b-c5f8345c857f",
                                "recommendation": "<p>Replace missing casement crank hardware handles</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "587cedab-2e71-405f-a631-60e95a6b1c7f",
                                "recommendation": "<p>A qualified glazier is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6e6a15b5-5399-4099-a0ba-8f5d8be881d0",
                                "recommendation": "<p>Replace weather stripping</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a6af95b8-1e8f-443c-bb7e-a66e6ab86e7f",
                                "recommendation": "<p>Replace missing pull hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7fa2fc6d-e599-431b-bdf8-d0ab4e0d7482",
                                "recommendation": "<p>Hard operation-needs improvements for ease of operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e1fc4594-0a94-4163-a9e7-b3dc44b57ee7",
                                "recommendation": "<p>Repair/replace damaged colonial grids</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "52cb8bab-f0c8-4846-952c-58ea1ea5bf1e",
                                "recommendation": "<p>Crack in marble sill noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "896b2a97-911e-4e84-9a03-a076a64f5802",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Aluminum slider",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Aluminum double hung",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Non-opening",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood double hung",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Aluminum casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl double hung",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl slider",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steel casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steel frame",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood slider",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "4a9e4e84-3819-4826-aa7f-778f15ecc7f0"
                            }
                        ]
                    },
                    {
                        "order": 23,
                        prompt: "Electrical:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1b0556cd-0c47-48f2-9bf1-cf90ec17bbbf",
                                "recommendation": "<p>Open or missing ground</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0e3bd58b-21a5-40fe-a484-2e2609c9ad89",
                                "recommendation": "<p>Suggest evaluation by licensed electrician</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "aa7dff91-2168-43fc-923d-80255c06c7ab",
                                "recommendation": "<p>Non-GFCI circuit</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4badcd52-5659-4a7b-b98d-56abf878420b",
                                "recommendation": "<p>Reversed polarity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "220c6ce2-2f8c-4cda-b83f-564e292bca44",
                                "recommendation": "<p>Install missing junction box cover plates</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5481100a-dce0-4b91-9f31-39eebb271f40",
                                "recommendation": "<p>Install missing junction boxes and cover plates at open splice connections</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6679ca36-a532-42a2-862e-afc0dcbe8b99",
                                "recommendation": "<p>Protect exposed surface mounted wiring in conduit to 7'-0' above floor</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "28515042-5a5a-40db-8318-901abc1c2cbd",
                                "recommendation": "<p>Faulty GFCI outlet-replace</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "77c5acb2-5aeb-43f4-a7f2-1a44d51597cc",
                                "recommendation": "<p>Reversed polarity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8ae028b6-3932-4f1f-aeac-11370c30798f",
                                "recommendation": "<p>Missing outlet/switch cover plates</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a3f6a608-8ff1-4471-8474-4f5426536873",
                                "recommendation": "<p>Loose switch or outlet should be resecured</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2a17706d-af43-4b87-b3c2-65b0ee8abf81",
                                "recommendation": "<p>Ceiling fan inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "00613570-7287-47b5-a4ac-c9170417f868",
                                "recommendation": "<p>Worn outlets creating intermittent ground- replace outlets</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f26926e8-81c1-4b33-b203-814ffa8b5c8e",
                                "recommendation": "<p>Light fixture not functional-repair/replace</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b217a993-9e60-453d-884a-a69474355554",
                                "recommendation": "<p>Open grounds noted at 3 prong outlets</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3c62d8e0-7cee-4c9e-900a-d448f8ff7469",
                                "recommendation": "<p>Remove temporary wiring running through walls -amateur installation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ad548624-0654-4bf3-8a87-ca16feb507fa",
                                "recommendation": "<p>Outlets not working</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ada7a399-6c2f-4cec-a4fc-d9f37a798ac8",
                                "recommendation": "<p>Non-GFCI circuit -recommend GFCI circuit be installed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5cd81169-c075-4fed-b61c-700540e30871",
                                "recommendation": "<p>Insufficient outlets for space</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b1a3a200-1a8e-4fa5-80db-2a4510e52534",
                                "recommendation": "<p>A licensed electrician is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "21faea3a-b6c7-47cd-a387-cf0a976a013a",
                                "recommendation": "<p>Replace damaged outlet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fa52b4e5-ab3c-487d-b258-6ffdfa404dfd",
                                "recommendation": "<p>110 VAC Outlets, Lighting Circuit and ceiling fan</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c9d60558-f3ae-4496-80cc-a90d94c0b86c",
                                "recommendation": "<p>Install missing outlet/switch cover plate(s)</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "8783e340-5299-42aa-9685-abcdfbb6c333",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "110 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC GFCI",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC/220 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "220 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC outlets and lighting circuits",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "74befb27-55cf-4c6d-9276-4f2ccf48a0b3"
                            }
                        ]
                    },
                    {
                        "order": 24,
                        prompt: "Counter/Cabinet:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7391c9c5-d035-46c3-81f1-680d3ca7559e",
                                "recommendation": "<p>Cabinet not properly mounted to wall</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cb3242e0-2667-411e-8b16-519924415302",
                                "recommendation": "<p>Loose counter top</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c5b09dd8-a183-4e7b-9b6d-7083e727aa50",
                                "recommendation": "<p>Moisture damage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "eff6a1c7-d6c3-4669-91c7-2407f9d3973c",
                                "recommendation": "<p>Needs caulking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "81522f4b-24e2-49d3-b97a-83d9e8597663",
                                "recommendation": "<p>Peeling laminate</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "91dccc2f-4edf-4084-89a6-20fc7e17e0fd",
                                "recommendation": "<p>Resecure vanity base cabinet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c929e679-43e7-41eb-b2f3-0c1a5dd085e4",
                                "recommendation": "<p>Resecure vanity sink bowl to base cabinet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "256b4a3c-dffa-4643-bbe5-6fb4076ab66e",
                                "recommendation": "<p>Water damage present under the counter</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b6c06f53-3b7b-40a5-a3bc-bb0e7183579d",
                                "recommendation": "<p>Surface laminate loose or peeling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "da001c86-0b23-4a1e-a90b-58a509942a28",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c3c53d69-9011-4ead-b259-f47b5d46bd86",
                                "recommendation": "<p>Worn finish</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bc4999bf-350a-4e5b-9374-45f0b12477d3",
                                "recommendation": "<p>Minor nicks/scratches in finish noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3c217e0b-4c39-4f26-926e-1fef9eef6f69",
                                "recommendation": "<p>Install drawer/door hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e375925d-a40a-485a-9b55-16ec0577db78",
                                "recommendation": "<p>Mold present under the counter(possible health concern)-needs removal/correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "b2cd658e-b549-49eb-bb25-84caf77f34e0",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Composite and wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Laminate",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Laminate and wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "f77dc356-bae2-472f-ace5-13f7013bec21"
                            }
                        ]
                    },
                    {
                        "order": 25,
                        prompt: "Sink/Basin:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "78385b5d-45d3-455f-8600-1501e796d068",
                                "recommendation": "<p>Cracks present in the sink bowl</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "98197bc4-cbd1-43d0-b7f2-3249333a4484",
                                "recommendation": "<p>Drain trap leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9ba16bae-b69d-4905-9081-0b2fbf0de8d3",
                                "recommendation": "<p>Leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c5abb006-a5a7-476c-ab5a-1a0cd61ff41e",
                                "recommendation": "<p>Rust present in the sink bowl</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "16472a2b-7511-4672-9c02-3ab31bcbd68e",
                                "recommendation": "<p>Stopper missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ae3fa387-2c57-4bec-9902-69a7160067b6",
                                "recommendation": "<p>The sink is loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "1fafb11b-241d-45a0-bcd6-d9ca510dc1e1",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Molded dual bowl",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Porcelain coated",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Molded single bowl",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Pedestal",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "One piece sink/counter top",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Porcelain",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Stainless Steel",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "One piece sink/counter tops",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Corian",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Granite",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Granite/Porcelain",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Synthetic",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "b9584ae3-5610-4c5a-861b-124363f2dfc3"
                            }
                        ]
                    },
                    {
                        "order": 26,
                        prompt: "Faucets/Traps:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "62212ffd-892b-46d4-bf5d-76c86c7d25ad",
                                "recommendation": "<p>A licensed plumber recommended to estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c4b499c0-4076-4e72-a8d3-3a1ab8068801",
                                "recommendation": "<p>Hot and cold operation reversed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b90aaf63-eacf-4c7c-8a64-cd32f3981a0a",
                                "recommendation": "<p>Leaking faucet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5d4d5c29-6c0a-496d-9afc-227143efe7a4",
                                "recommendation": "<p>Leaking trap</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "df34fdd6-2bd8-4bb1-98b0-575ad9cfe0af",
                                "recommendation": "<p>Slow draining noted-further review/repair</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "971d0f97-a195-4db8-9415-1faacf5b96d5",
                                "recommendation": "<p>Leaking drain</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "330c0b4b-6c8e-4e2a-99af-4cad64844eb9",
                                "recommendation": "<p>Leaking supply line</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "643982df-438b-4d1b-b593-5df3a4e89ec8",
                                "recommendation": "<p>Faulty fill valve at toilet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8b89f795-c36e-4e64-aea7-cf66f2925b8c",
                                "recommendation": "<p>Shower diverter not functioning properly, all water is not flowing to showerhead</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0a6c6a77-c8d4-4973-b0c1-5f50d34f5ab5",
                                "recommendation": "<p>Damage to drain trap-may cause leak</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "942fdc2d-3d4d-4e96-9170-d7df32c787ea",
                                "recommendation": "<p>Clogged or non-functioning vegetable sprayer</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8ace432e-6959-4f49-9514-cc338cf03e9f",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8bc2db52-ce82-404f-a3ba-358a5b786942",
                                "recommendation": "<p>Unable to test due to water being shut off to house. Recommend having water service turned on and have qualified plumbing contractor certify function</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "39f671e7-300d-4402-a3bb-ae4b01406127",
                                "recommendation": "<p>Build up found in aerator of sink fixture possibly caused by deteriorated water heater dip tube, further review is required</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0b766341-80b3-4338-89d1-dbace99fd632",
                                "recommendation": "<p>Pitting at drain line/trap-recommend replace</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "097faa4e-4138-4c64-830b-ba660a98de01",
                                "recommendation": "<p>'S' Type drain traps do not meet today's standards</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d5e218fe-9ceb-42a8-9d0a-0b60c3e457a8",
                                "recommendation": "<p>Leaking tub faucet at stem</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "26033cbd-d680-4a38-91ec-48ecb6a6b62d",
                                "recommendation": "<p>Missing or faulty drain stopper</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "76c7b29d-515e-40b9-ae7f-90728c553af5",
                                "recommendation": "<p>Resecure loose tub overflow plate</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b3728c04-9059-461b-b8ff-ad53dc8c1436",
                                "recommendation": "<p>Leaking sink faucet at stem-repair</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "49b8b73c-1e82-407b-bfb9-1bdbb0319efb",
                                "recommendation": "<p>Low pressure or flow rate at faucet, further review and/or repair is indicated</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f49fe6c6-79c9-4f4a-b869-b75e484e8530",
                                "recommendation": "<p>Worn finish</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d3b0a487-dbc6-4912-ae63-52d82f2f4c26",
                                "recommendation": "<p>Leaking shower head</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "eb9943db-c880-409b-90f0-d7d3314221ca",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Delta fixtures with a metal trap",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Delta fixtures with a PVC trap",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Moen fixtures with a metal trap",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Moen fixtures with a PVC trap",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not installed to date",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not visible",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Winterized",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "0e13bb58-2fbb-42b5-a384-936fb3f3f9cc"
                            }
                        ]
                    },
                    {
                        "order": 27,
                        prompt: "Toilets:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cc9d8a78-b40b-40f1-8243-72e98892fdaa",
                                "recommendation": "<p>The tank is loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4cf839b7-ef7c-4b59-9d81-9e16060910b3",
                                "recommendation": "<p>The toilet is loose at the floor and will require replacement of the wax seal</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ffc3d43c-d3b4-4a36-b687-054fc35d5687",
                                "recommendation": "<p>Water continues to run after flushing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0b4c1f7c-1084-4c76-8a56-2310bcbffc2a",
                                "recommendation": "<p>Water leaking at tank seal</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c1059a94-4235-4e90-b925-2eebe89e35dd",
                                "recommendation": "<p>Water leaking at wax seal</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "83c16629-0aa1-4476-8a8f-0f6ba7bcf574",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "American Standard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Moen",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Koehler",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "1 1/2 Gallon Tank",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "3 Gallon Tank",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "571c7133-853d-45ca-bf59-9dc36e35be31"
                            }
                        ]
                    },
                    {
                        "order": 28,
                        prompt: "HVAC Source:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "44804b1d-81b7-48e7-acfa-b669a8231d13",
                                "recommendation": "<p>Heating system inoperable</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e95ce5f5-4b33-4b5b-b3fd-3ad97432a9fc",
                                "recommendation": "<p>Low air flow</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6183ee8b-310f-47b4-a2a9-1872347bcc58",
                                "recommendation": "<p>No heat duct</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "67dbd15e-428f-492d-ac1f-21812e9009a8",
                                "recommendation": "<p>Register cover damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3ce163a2-ec7b-43b5-a386-db66bc48ab6d",
                                "recommendation": "<p>Register cover missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "041093fb-fbb6-468f-ad8a-e22231f49082",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Heating system register",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Convection baseboard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Air exchange ventilation",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Radiant heat",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "18368627-f46c-46d8-a5bd-7edae52b2dcb"
                            }
                        ]
                    },
                    {
                        "order": 29,
                        prompt: "Ventilation:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "69453103-aff0-4687-a544-79fb5d9435c3",
                                "recommendation": "<p>Fan inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "125dc47e-25f5-419f-8ade-c88cdb3cc49e",
                                "recommendation": "<p>No ventilation present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4993fcf3-766b-48a7-8294-0d9ade45bb51",
                                "recommendation": "<p>Window painted shut</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "ff3c35d2-2693-45a5-9c31-46ba15b7b5a6",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Electric ventilation fan",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Electric ventilation fan and window",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Electric ventilation fan with heater",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "No ventilation",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Window",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "1b1a2c92-42c3-4a7d-941e-be6b8a0548ab"
                            }
                        ]
                    }
                ],
                "order": 1,
                "is_deleted": 0,
                "created_by": 686,
                "updated_by": 686,
                "created_at": "2022-11-08T18:05:40.000000Z",
                "updated_at": "2022-11-08T18:05:40.000000Z"
            },
            {
                uuid: "c48879ee-d4cf-4374-8418-7872d491b350",
                prompt: "Kitchen",
                "introductory_text": null,
                lines: [
                    {
                        "order": 1,
                        prompt: "Cooking Appliances:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a0c8cba6-dca1-456b-a58d-902b140df25e",
                                "recommendation": "<p>Burner inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7dce79db-de50-40f8-a283-20090b2d9cf4",
                                "recommendation": "<p>Broiler inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "93241a46-9fd9-4b4b-8923-37405494f431",
                                "recommendation": "<p>Oven heating element inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a130cebc-05ed-4ff0-8cad-0e68c961fd19",
                                "recommendation": "<p>Oven door loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9932f6b4-16b9-49b7-9e72-e132cd9b99de",
                                "recommendation": "<p>Loose or missing hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a54d44eb-559f-4fee-ad28-79f6930b1673",
                                "recommendation": "<p>Gas leak</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5acbfde4-5e23-43bc-b340-022668f30e72",
                                "recommendation": "<p>220 VAC power cord damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0a0679a0-5aef-4964-8084-fbf049155248",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "a41a247b-9aae-4393-994d-65165df6473c",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Jenn-Air",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Whirlpool",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "General Electric",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Kenmore",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Sunray",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Thermadore",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wolf Gourmet",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Sears",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hotpoint",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Roper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Modern Maid",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tappan",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Sharp",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "LG",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fisher & Paykel",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Frigidaire",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Amana",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Kichenaid",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Maytag",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Asko",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Viking",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "White Westinghouse",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Westinghouse",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Magic Chef",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Decor",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Thermador",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "9d3dfcaa-6491-4dad-a9fb-73303fbad655"
                            }
                        ]
                    },
                    {
                        "order": 2,
                        prompt: "Ventilator:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "997bbf20-f2eb-4bbb-9ad6-4ff786035d6e",
                                "recommendation": "<p>Fan inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b872cec9-42d4-497e-ac37-a7c50a683037",
                                "recommendation": "<p>Fan blade out of balance/broken causing vibration</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "993e230b-70ff-4ffc-9ece-82d15b86a85b",
                                "recommendation": "<p>Grease filter needs to be cleaned</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f02b5125-fe25-42b6-a1da-498904985ffa",
                                "recommendation": "<p>Not properly vented</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0abc64cf-8bb2-4ed8-8794-dc9334474cdb",
                                "recommendation": "<p>A licensed electrician is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "3ee11de2-f28d-473f-abb0-6f4cb77ad412",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Broan",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Kenmore",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Jenn-air",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Whirlpool",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Miami Carey",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hotpoint",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rangeaire",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Nautilus",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Sharp",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rangemaster",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Thermador",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Air Care",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Frigidaire",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Amana",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "MVP",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not part of sale",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tappan",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Victor",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Caloric",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Kitchenaid",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Modern Maid",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Sanyo",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Dacor",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Puriton",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "No Vent",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Roper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Mercury",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Maytag",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Nutone",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "General Electric",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "da380956-d54e-4369-b673-a90165ecf86a"
                            }
                        ]
                    },
                    {
                        "order": 3,
                        prompt: "Disposal:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "47441c7f-eea7-4e3f-9db3-137ebafb8ab9",
                                "recommendation": "<p>Inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d4b40506-2adc-4fbd-8b0e-104a02192b19",
                                "recommendation": "<p>Loud</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "eb5d648e-d79d-4332-85d2-c9da5ed48499",
                                "recommendation": "<p>Jammed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "55af6164-9fe2-435d-b130-4bb90fb10a2f",
                                "recommendation": "<p>Leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e2f9c17c-1463-4968-8fce-543ea6836bac",
                                "recommendation": "<p>Binding</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bf774f97-7733-4a16-88b5-b02b7f212735",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "2392f28e-6338-4eec-81a5-4c9d38d0df54",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "In-Sinkerator",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "General Electric",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "f0eb20d7-4c8f-412d-9506-388aeac0e459"
                            }
                        ]
                    },
                    {
                        "order": 4,
                        prompt: "Dishwasher:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "77aeea14-9b37-4435-8624-f3f8232fbe60",
                                "recommendation": "<p>Dishwasher existing beyond typical design life-limited life</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cecfac6e-076e-4c73-8c4d-01e6ac163252",
                                "recommendation": "<p>Needs new dish rack</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "45c76682-6098-40d0-a78e-e7a43ad993c6",
                                "recommendation": "<p>Air gap clogged and back up into into sink- needs correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "22d60ef5-7348-4178-b86c-52dae31aa24a",
                                "recommendation": "<p>Door loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1784d58f-f330-4b90-82a5-5eb1e7ec6898",
                                "recommendation": "<p>Inoperative at time of inspection</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4316b049-37bb-4a10-a537-fbb213397350",
                                "recommendation": "<p>Rust evident at drain basin in bottom of dishwasher-assume limited life,recommend</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "44dfdc6b-2d8d-4133-a78a-db9d3c356a57",
                                "recommendation": "<p>Dishwasher failed to drain during sump cycle</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2b5e9feb-4ae8-4ab1-88c3-49f91d6b591d",
                                "recommendation": "<p>Leaking water</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bf4df395-39c4-4d09-8aee-3557e07e13a2",
                                "recommendation": "<p>Roll away models are not in scope of inspection. Only built in models are inspected</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "23cb8aee-7b09-4ba7-8a7c-6de579ca328b",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "52865022-95ae-4e6b-87b7-634a812d7491",
                                "recommendation": "<p>Worn door seals</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bf4aec64-a6fa-41ea-af72-195f5078ed42",
                                "recommendation": "<p>Dishwasher may be part of GE/Hotpoint recall due to faulty switch causing fire hazard. Call 1-800-599-2929 for affected models and recall information and correction.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7b4a7b21-eab7-44c5-96d4-23c0ad481279",
                                "recommendation": "<p>Loose or missing hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f0f8b6c2-2f35-465c-bb59-5317260fd61c",
                                "recommendation": "<p>Soap dispenser won't latch closed-repair</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5dd36edc-9cbe-491d-825d-fb56bec3c9d0",
                                "recommendation": "<p>Properly secure to counter/base cabinets</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "910c75a2-c0b5-4d2c-8613-489019c09efe",
                                "recommendation": "<p>Missing rinse-aid cap in door</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "75e65d4d-6120-40c0-846c-4540f448e8cf",
                                "recommendation": "<p>Damaged rinse-aid cap in door</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "59af151a-639c-4c70-bd80-dc1a85389324",
                                "recommendation": "<p>Damaged soap dispenser in door</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c2d62b5c-9234-49db-8258-5f7b891e9df5",
                                "recommendation": "<p>Dented face panel noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "1d6b426e-a496-4c98-9bc6-9282198dcfd8",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Whirlpool",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Kenmore",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "General Electric",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Kitchenaid",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Maytag",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hotpoint",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Frigidaire",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Jenn-air",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Majic Chef",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Asko",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "N/a",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "RCA",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Caloric",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Insinkerator",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Bosch",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Roper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fisher & Paykel",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "adb53d1c-3f26-4a26-af0f-8022ee3042ea"
                            }
                        ]
                    },
                    {
                        "order": 5,
                        prompt: "Air Gap Present?",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cce426ee-4ff5-4ca9-b7ef-a334e5bc34c1",
                                "recommendation": "<p>Air gap hose improperly connected</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9f54dd40-9463-4a10-85a8-23965e77c050",
                                "recommendation": "<p>Air gap hose loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "faea35b4-6ade-4f50-a2e3-b150a6df92e5",
                                "recommendation": "<p>Air gap hose leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "98f245ba-c2a8-4a71-b8ed-843e5efd25e8",
                                "recommendation": "<p>Air gap hose cracked</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8c255ac1-82fc-467d-a9c3-a2492a0ea061",
                                "recommendation": "<p>Air gap hose missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "e1741599-25cf-4fb7-860a-de17ddbc5830",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Yes",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "No",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "9c0631c6-2e67-419d-abcc-abebdfc0169d"
                            }
                        ]
                    },
                    {
                        "order": 6,
                        prompt: "Trash Compactor:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0cb583b2-99e1-45aa-be18-2150f7b9663f",
                                "recommendation": "<p>Inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a903c884-98ec-4b3a-b2e8-e8d5a54d09eb",
                                "recommendation": "<p>Noisy</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "12e0948e-dfa3-41e8-b183-86a8b2635aa6",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "General Electric",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Kenmore",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "In-Sinkerator",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Maytag",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Jenn-air",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Disposall",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Kichenaid",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Whirlpool",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Sinkmaster",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "a00e8359-88d6-44cd-87cc-1caf8c94c49f"
                            }
                        ]
                    },
                    {
                        "order": 7,
                        prompt: "Refrigerator:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "66f27188-6c14-4111-876f-7276982fa01b",
                                "recommendation": "<p>Appears serviceable</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "38db8610-d957-4df1-ad74-dd7213fd8a21",
                                "recommendation": "<p>Disconnected from power at time of inspection</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ed09240d-b542-48e9-a071-9b3dd8750eb7",
                                "recommendation": "<p>Loose hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f80a910c-d141-466a-8bcd-d8922c2661a2",
                                "recommendation": "<p>Loose or damaged door seal</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6f68292e-70c6-4119-b628-9b8c1f95003f",
                                "recommendation": "<p>Noisy</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "c70484ce-2522-42ea-a487-fbd3199d1c32",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "General Electric",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hotpoint",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Kenmore",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Amana",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Frigidaire",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Whirlpool",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not part of sale",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Kitchenaid",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Subzero",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Majic Chef",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Maytag",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Sears",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "RCA",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Admiral",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Functioning",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Jenn-air",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Marvel",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Roper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not installed to date",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Stainless",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Norge",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Gibson",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "419ced58-dcb7-43d0-af59-ab0027110dc0"
                            }
                        ]
                    },
                    {
                        "order": 8,
                        prompt: "Microwave:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4140e62d-c3c1-4a50-a98c-ca30136c5192",
                                "recommendation": "<p>Inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8d627ed4-b6f7-4699-a227-25dd3ba114cf",
                                "recommendation": "<p>Leakage detected at the door seal</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3ee87a29-8c42-44f5-9829-03bfac62a333",
                                "recommendation": "<p>Noisy</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "c6269296-223f-41c5-bbed-c0217196ac80",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Amana",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "General Electric",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Gold Star",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Whirlpool",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Sharp",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Compactor",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Kenmore",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Kitchenaid",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Frigidaire",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hotpoint",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tappan",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Caloric",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Jenn-air",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Sanyo",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Roper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Maytag",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Ewave",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "MVP",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "d75b8933-4d4e-4d72-b94f-fa8061ec3314"
                            }
                        ]
                    },
                    {
                        "order": 9,
                        prompt: "Sink:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "dd9dddec-d87f-4f45-9011-f7be2f5dbf42",
                                "recommendation": "<p>Leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "90d24a8a-61b1-4842-bf77-3ca3df348c21",
                                "recommendation": "<p>Pitting stem</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4ef627cf-e0f5-410a-b42d-e6853b13cd40",
                                "recommendation": "<p>Loose fixture</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8b7330bc-75b6-4818-a353-1c64d6c85eab",
                                "recommendation": "<p>Missing parts</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "02c9351e-cc2d-4b6b-91f5-1ab5044c6dc3",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Stainless Steel",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Corian",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Swanstone",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Porcelain Coated",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Porcelain",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "1adb7a5d-7d67-427d-9e54-023bf5f25872"
                            }
                        ]
                    },
                    {
                        "order": 10,
                        prompt: "Electrical:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4368048d-76db-4134-ab4a-746a9c4bf36c",
                                "recommendation": "<p>Open or missing ground</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5747d40d-5204-40b6-97b4-7603e5803e2c",
                                "recommendation": "<p>Suggest evaluation by licensed electrician</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8d2743be-aedb-4ef8-9121-26bdecbfb003",
                                "recommendation": "<p>Non-GFCI circuit</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "654f2623-3ca1-493d-9467-b4bf920cc6ea",
                                "recommendation": "<p>Reversed polarity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "111b06f2-5ed4-475d-b1f6-c73e1944da02",
                                "recommendation": "<p>Install missing junction box cover plates</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1982d935-0a93-41ba-90b6-28c9a2a6a53b",
                                "recommendation": "<p>Install missing junction boxes and cover plates at open splice connections</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9aec0998-f3a2-4715-bdfd-69ba4da6889a",
                                "recommendation": "<p>Protect exposed surface mounted wiring in conduit to 7'-0' above floor</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c1bd7c2a-9521-42ca-8349-860a4498705d",
                                "recommendation": "<p>Faulty GFCI outlet-replace</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2e2f4d10-52a8-4fae-a33e-c670bd682a38",
                                "recommendation": "<p>Reversed polarity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "80fa7f68-69eb-408f-b809-571b54999c8a",
                                "recommendation": "<p>Missing outlet/switch cover plates</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a2bc3b10-db02-45bf-9629-f7de60bfbaf5",
                                "recommendation": "<p>Loose switch or outlet should be resecured</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "82357def-b9bb-471b-ab9f-f017d6e38c45",
                                "recommendation": "<p>Ceiling fan inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b8414e3b-b299-4bc8-8b82-9a3bf5b0aec2",
                                "recommendation": "<p>Worn outlets creating intermittent ground- replace outlets</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4b5cf9bf-fce7-4030-8c76-81b3aac4567e",
                                "recommendation": "<p>Light fixture not functional-repair/replace</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3e622030-3fff-402a-9f38-5c28030ed832",
                                "recommendation": "<p>Open grounds noted at 3 prong outlets</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2b1b3a67-9823-4248-9cb0-ca0bcf3cab41",
                                "recommendation": "<p>Remove temporary wiring running through walls -amateur installation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6d6f756a-42a5-4fa3-8ac6-480a7efcf05b",
                                "recommendation": "<p>Outlets not working</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "069b5462-6fde-4e92-b76c-4cc85770ebf1",
                                "recommendation": "<p>Non-GFCI circuit -recommend GFCI circuit be installed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "287c6f1a-41bc-4b50-a769-787a34d08232",
                                "recommendation": "<p>Insufficient outlets for space</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "38efaf8d-a5ba-4c90-8554-6fd9dcfcead6",
                                "recommendation": "<p>A licensed electrician is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b5c7c6b3-2661-4e22-b4b4-affb111b81f2",
                                "recommendation": "<p>Replace damaged outlet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1a2e411c-1cfe-492c-a557-114d304b3344",
                                "recommendation": "<p>110 VAC Outlets, Lighting Circuit and ceiling fan</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0b6844c4-2b50-4a73-bfd3-1c517c2e5a95",
                                "recommendation": "<p>Install missing outlet/switch cover plate(s)</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "cc197802-b023-41c8-8aba-84937c68f615",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "110 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC GFCI",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC/220 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "220 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC outlets and lighting circuits",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "b2090761-c6f3-4684-a9ab-1839a38d6539"
                            }
                        ]
                    },
                    {
                        "order": 11,
                        prompt: "Plumbing/Fixtures:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4c00dc09-93d1-4fef-9b7f-4561a1a4ceb3",
                                "recommendation": "<p>An 'S' trap has been used.  S traps should be replaced during any new plumbing work as they are subject to siphoning problems.  Replacement is sometimes difficult and thus the S traps are usually tolerated.  Care should be taken to keep the trap 'primed.'  Fixtures should be monitored for sewer odor.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "affcaf78-a919-4972-ba84-7b7ff05e41c4",
                                "recommendation": "<p>Mineral build up was observed at faucets.  This may suggest 'hard' water.  Continued build up at faucets and within pipes could affect the performance of the supply plumbing system.  Water conditioning equipment could be added.  This situation should be monitored.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1feefdba-015f-4b49-af00-77905f04a0b7",
                                "recommendation": "<p>The faucet has poor pressure on the cold side.  The faucet should be repaired or replaced.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5bd5a04d-d37b-49e4-a3bf-f09e0ed7568c",
                                "recommendation": "<p>The faucet has poor pressure on the cold side.  The faucet should be repaired or replaced.The faucet has poor pressure on the hot side.  The faucet should be repaired or replaced. The faucet has poor pressure.  The faucet should be repaired or replaced. The faucet has the hot and cold supply piping reversed.  The hot is typically controlled by the left side of the faucet and the cold by the right side.  This could cause scalding of someone, especially a child, not familiar with this faucet. The faucet is damaged.  It should be repaired or replaced. The faucet is inoperative. The faucet is leaking. The faucet is loose. The faucet is stiff. The faucets are showing signs of age.  Updating faucets over time should be anticipated. The installation of shut off valves under sinks would be wise. The plumbing drain line is not trapped.  A trap should be installed to prevent sewer gas from entering the house. The plumbing drain line is not trapped.  A trap should be installed to prevent sewer gas from entering the house. The plumbing drain line is not trapped.  A trap should be installed to prevent sewer gas from entering the house. The sink drain trap is corroded.  This area should be monitored for leakage and repairs made as needed. The sink sprayer is inoperative. The supply piping under the sink is corroded.  It may be desirable to replace it before leaks develop. The supply piping under the sink is leaking.  Repair or replacement should be undertaken to prevent structural damage. The trap is leaking.  Repairs should be made to prevent damage to the cabinet.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "884df0f7-4157-40cd-aefa-edf98bc07157",
                                "recommendation": "<p>The faucet has poor pressure on the hot side.  The faucet should be repaired or replaced.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f943062a-a1f1-440f-899c-f16a85df2d55",
                                "recommendation": "<p>The faucet has poor pressure.  The faucet should be repaired or replaced.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e227ca84-f247-48f4-86a3-67c8a2e4d2f8",
                                "recommendation": "<p>The faucet has the hot and cold supply piping reversed.  The hot is typically controlled by the left side of the faucet and the cold by the right side.  This could cause scalding of someone, especially a child, not familiar with this faucet.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fe098ccc-9798-4623-bfba-46e88588efd8",
                                "recommendation": "<p>The faucet is damaged.  It should be repaired or replaced.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "52955f83-3ab2-4ba5-8591-7377e5858baf",
                                "recommendation": "<p>The faucet is inoperative.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "028ef8bf-16b4-4874-aa40-4f54dc2ce4b9",
                                "recommendation": "<p>The faucet is leaking.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "91466480-12e5-4aba-968d-f5e3dd331bfa",
                                "recommendation": "<p>The faucet is loose.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6d801a23-abe1-45aa-86b0-0b0edfdb8b80",
                                "recommendation": "<p>The faucet is stiff.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "219ac47c-59a5-4d6a-ac86-355a285f5711",
                                "recommendation": "<p>The faucets are showing signs of age.  Updating faucets over time should be anticipated.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a7da21d1-cc7b-4141-89d6-e3e654b2f1b6",
                                "recommendation": "<p>The installation of shut off valves under sinks would be wise. The plumbing drain line is not trapped.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2d0f3e46-fb4d-423a-b677-b1d4dfb9445e",
                                "recommendation": "<p>A trap should be installed to prevent sewer gas from entering the house. The plumbing drain line is not trapped.  A trap should be installed to prevent sewer gas from entering the house.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e781a11b-d60a-47be-b1fd-1aca8aec6c9b",
                                "recommendation": "<p>The plumbing drain line is not trapped.  A trap should be installed to prevent sewer gas from entering the house.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6046ffc0-7f51-4575-9a4a-9b04693682bf",
                                "recommendation": "<p>The sink drain trap is corroded.  This area should be monitored for leakage and repairs made as needed.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "50ff0771-f475-4387-b322-995b9d89cd63",
                                "recommendation": "<p>The sink sprayer is inoperative.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ef80cfe8-02dc-4f50-934e-5221fee32d00",
                                "recommendation": "<p>The supply piping under the sink is corroded.  It may be desirable to replace it before leaks develop.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d0bbc041-e253-4782-b4e3-a6ff6ad24478",
                                "recommendation": "<p>The supply piping under the sink is leaking.  Repair or replacement should be undertaken to prevent structural damage.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "bbb9cca6-dfc5-485d-bfe8-7df9ed5fe5e6",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "PVC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Chrome",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "CPVC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Stainless Steel",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Lead",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "4411413f-2a07-422e-8bce-cc9b86199b2f"
                            }
                        ]
                    },
                    {
                        "order": 12,
                        prompt: "Counter Tops:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a2efc927-0bdb-45fb-9eb0-aa8108c05f96",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7961b43c-2dc5-49cd-8120-f44dd1d6a5b6",
                                "recommendation": "<p>Needs caulking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f4c249ed-b6c0-4b6f-9d03-7c360430ef5b",
                                "recommendation": "<p>Surface laminate loose or peeling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "21eb663b-c7c3-4c60-95ce-7da316e96769",
                                "recommendation": "<p>Water damage present under the counter</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b3354186-0a38-439d-9d31-f0d39926e0ec",
                                "recommendation": "<p>Counter top not secured to cabinet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "640a1cbe-3091-456b-9505-bb2ea11d7efb",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Formica",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Marble",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Laminate",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Laminate and composite materials",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Laminate and wood",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "7e0d45be-a5e6-4eab-acb1-8dc788a84cf0"
                            }
                        ]
                    },
                    {
                        "order": 13,
                        prompt: "Cabinets:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "24a8e113-0b6c-4493-b4f7-b20d68423221",
                                "recommendation": "<p>Water damage present under the counter</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e5e7aa1a-9926-47e0-b1f6-9e51fbf7e34b",
                                "recommendation": "<p>Surface laminate loose or peeling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b6aa233d-f53e-46b8-b476-50ad3ca4ad61",
                                "recommendation": "<p>Needs caulking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3a737c58-e9f6-46ab-a6d0-97d9facecea9",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "de428fe8-eae0-459f-9a2f-a51d3300c355",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Laminate and composite materials",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Metal",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Formica",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Laminate",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Laminate and wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "e6c4c9a5-635e-44f5-a9ad-d2900e0e6213"
                            }
                        ]
                    },
                    {
                        "order": 14,
                        prompt: "Pantry:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e1665f33-430c-461d-be50-848b422bdde6",
                                "recommendation": "<p>Missing clothes pole</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "948a5f57-2236-4b7b-a254-9342ed47013e",
                                "recommendation": "<p>Walls in closet have cracks not seen in room</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "eaf5121f-208e-43c7-af6e-29436504de0e",
                                "recommendation": "<p>Water stains noted at closet interior wall</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8edb51b4-a69a-4068-bb44-0867e00a8315",
                                "recommendation": "<p>Repairs needed at bypass closet doors</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "25423046-bc9e-45d2-aa06-f2ba5f7a9551",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Single small",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Walk In",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Small & Walk-in",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Large",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Single",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Small",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Walk In and Large",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "ec1352fe-2029-4e1b-ac64-ae9fca927198"
                            }
                        ]
                    },
                    {
                        "order": 15,
                        prompt: "Ceiling:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a9a4e1f6-7b9a-4bb6-b7ef-3cdeae606adb",
                                "recommendation": "<p>Cracks present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "48317f14-85d3-47b2-8ce1-16c46ebe94f3",
                                "recommendation": "<p>Loose or peeling paint</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f117be18-b1ee-4633-9cea-46fee9b7d711",
                                "recommendation": "<p>Loose paneling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "493c4172-bb1e-43d1-9f61-31f4af866bd0",
                                "recommendation": "<p>Loose plaster</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fe242f50-e13b-48a1-8ff4-c41f835d3c03",
                                "recommendation": "<p>Water stains present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "82057fce-ceb2-4ac3-be8b-e3046aab769f",
                                "recommendation": "<p>Stress cracking, see structural notes</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1c7a2122-ffe8-4f3c-b474-4deec62291c2",
                                "recommendation": "<p>Evidence of possible truss uplift-repair as required, monitor for further movement</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "055d1e1a-de92-417b-ac3a-af190007b601",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "33acbcd6-b7b1-42a1-8115-6956ff797df2",
                                "recommendation": "<p>Mold is present and is a potential health concern. Removal and/or correction is required.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7046c941-a172-4f08-bfa5-d3ecf5487f14",
                                "recommendation": "<p>Evidence of past or present water staining</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "54f4f61a-0090-4a17-bf3a-3199182cf197",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Paint",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Exposed framing",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Mirrors",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Texture paint",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Suspended ceiling",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "79929faa-3236-4c12-8b37-489970e19a9b"
                            }
                        ]
                    },
                    {
                        "order": 16,
                        prompt: "Walls:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c2f424e4-dd6b-4a0c-a8de-b3567fef057a",
                                "recommendation": "<p>Cracks present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b0c1a45f-93db-44b0-ad37-c045c51d73f8",
                                "recommendation": "<p>Evidence of past or present water leakage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cec500e3-9a0f-4bc8-b027-24f48605d4bc",
                                "recommendation": "<p>Evidence of possible truss uplift</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2373e47a-075b-41c4-aa4c-5e787ae2ebf3",
                                "recommendation": "<p>Loose or peeling paint</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3010285a-6668-4eaa-989f-95d1ba2631ec",
                                "recommendation": "<p>Loose paneling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a0b6d690-2953-4d11-b00d-f2f27183d552",
                                "recommendation": "<p>Water stains present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4b6bdb16-2f97-4544-a116-0e5262d33e03",
                                "recommendation": "<p>Evidence of past or present water staining</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d0c9c96a-527a-44f4-a8c9-f45ac0c6325d",
                                "recommendation": "<p>Loose or missing tile</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ab90b12f-789e-4aed-b208-5984bfdd5b42",
                                "recommendation": "<p>Mold present-potential health concern-needs removal/correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8b3e5723-261c-47ad-9501-ea7268a7b639",
                                "recommendation": "<p>Paneling loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4f8a610f-4608-4f3d-a71d-f517d269ba9a",
                                "recommendation": "<p>Some ceiling pads damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "34c8e6ee-8b82-4847-8a0f-283c3e657e8e",
                                "recommendation": "<p>Some evidence of previous truss uplift</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "59515308-2c44-47ba-aa0d-3332c4d8f63f",
                                "recommendation": "<p>Inspection does not cover any damage concealed by rugs, carpeting, wall paneling, furniture</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "00699e25-6c6b-4d74-82bf-a514120f6211",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "c0ab6cdf-4b43-4b38-98d9-b7c5d1f7524f",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Paint and paneling",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Exposed framing",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Paint and tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wallpaper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Paint",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Concrete",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wallpaper and tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fire rated",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "e77366f8-cb09-42af-8735-17ae39cf027c"
                            }
                        ]
                    },
                    {
                        "order": 17,
                        prompt: "Floor:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8fd5862a-6f1d-4271-87b1-d2228e4d04d3",
                                "recommendation": "<p>Broken floorboard</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fcb0979c-48dd-4ef1-be8d-c083259b0222",
                                "recommendation": "<p>Damaged carpet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "189d80c8-71cb-4ad8-80c0-a66704fd3aca",
                                "recommendation": "<p>Loose carpet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2d92724c-3a8d-4802-9254-aba74c64c8bb",
                                "recommendation": "<p>Loose flooring</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4c6479ba-daff-48da-88e9-199c0bc40d15",
                                "recommendation": "<p>The floor squeaks when walked on</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bd7f4103-156e-4981-9ed8-1db5817929f0",
                                "recommendation": "<p>This inspection does not cover any damage concealed by carpeting, rugs or furniture</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "43e05dc4-e685-4498-9734-c350c87e195d",
                                "recommendation": "<p>Water damage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7c3b3b4b-e2a3-4efd-b226-309681a51c66",
                                "recommendation": "<p>Noted uneven floor due to settlement</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "036aa120-2b2d-47a7-b0c8-78b7a5f08902",
                                "recommendation": "<p>Carpet Stained/soiled</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cc4472a5-2292-4146-9c4b-ab8244c3dc7b",
                                "recommendation": "<p>Worn wood floor finish</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "245dcf2f-d3fc-4108-89d4-61ffc220b2fd",
                                "recommendation": "<p>Cuts or minor damage in flooring, repair/replace as required. Restretch carpets- trip hazard</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4b2c1fc8-b89a-4beb-8acc-3d0a777634ed",
                                "recommendation": "<p>Floor shows evidence of previous water penetration/stains</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0642ba6d-60ea-473e-a8a0-cb949dc4e49e",
                                "recommendation": "<p>Worn carpets</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "45fa0ab5-e6d7-442e-af72-d6c537da7135",
                                "recommendation": "<p>Termite damage noted in wood flooring, repairs required</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "60b7afd3-f390-4ebc-904a-ce8aab24bba5",
                                "recommendation": "<p>Gapping in hardwood flooring noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "79667fc4-a106-4b0c-88a3-d9ae19c8425e",
                                "recommendation": "<p>Floor squeaks noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "285972eb-59e9-4649-9ae1-135b2bf5489e",
                                "recommendation": "<p>Raised seams in vinyl flooring-repair</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4e41c609-f0e8-4635-a230-80ebb67a437e",
                                "recommendation": "<p>Uneven wood floor finish (difference under carpets/rugs) due to sunlight</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "34859ea3-6d55-440f-b29e-0e773a6f75fe",
                                "recommendation": "<p>Loose tile/grout, repair as required</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "62004b8a-5e4d-484e-9419-f6cddf7fd689",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Hardwood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Ceramic tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Linoleum",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Carpet",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl floor covering",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Poured",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "49a65e94-f51e-437b-9eb9-f457aeabb007"
                            }
                        ]
                    },
                    {
                        "order": 18,
                        prompt: "Doors:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "08a815e8-84d1-442f-9fb7-fc7f9985af73",
                                "recommendation": "<p>Loose hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6a4bdbd4-7320-4c8d-a410-a1e2890595a9",
                                "recommendation": "<p>Missing hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4ce415cd-3c98-43a2-b11d-38fa0059fd00",
                                "recommendation": "<p>The door has moisture damage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1009f3aa-b494-4fe0-9be0-c117f680a405",
                                "recommendation": "<p>The door is sticking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e7ba2b39-73cb-4f17-a680-eff10ff32c5a",
                                "recommendation": "<p>The surface laminate is peeling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "be62dace-8900-420d-b53a-fca473ee289f",
                                "recommendation": "<p>Wood rot</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5fd2b5a7-210d-4483-9270-f2fb50029617",
                                "recommendation": "<p>Lockset(s) not latching-requires adjustment</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3a7208db-9b53-420d-9d88-d56af57a04e9",
                                "recommendation": "<p>Door(s) has minor damage from door stop-repair/replace</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fd62116e-91ff-4640-b710-e935c232bd37",
                                "recommendation": "<p>Lockset(s) not functioning-repair</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "95e88b3f-8485-45d9-8f23-3cf109efee9b",
                                "recommendation": "<p>Replace missing door(s)</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "633f4fee-d12c-4edd-ad65-61e5055a2e66",
                                "recommendation": "<p>Door damaged by moisture</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "eeb3fe19-2ac6-4343-973b-ad1c3d009605",
                                "recommendation": "<p>Doors need trimming/adjustment</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c46137c1-ab70-41f2-b798-88782028f84c",
                                "recommendation": "<p>Replace missing bypass hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "06ef2ec2-5ec0-4529-8754-e7875c7426c4",
                                "recommendation": "<p>Lockset hardware installed opposite with locking knob to outside of room-needs correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fa861804-a356-4754-bac9-a0f0e9a316c1",
                                "recommendation": "<p>Align/adjust closet doors</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a43a769d-994b-4732-a389-9858fb7800d9",
                                "recommendation": "<p>Undercut where rubbing on carpet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "e09225d9-0745-410b-b8fc-42ec9213fa1c",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Hollow wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "French",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Bi-fold",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Solid wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Dutch",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "c9dd572e-81bd-474a-b9a2-ab2299c47c71"
                            }
                        ]
                    },
                    {
                        "order": 19,
                        prompt: "Windows:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cccd1a22-a7d8-402a-84fd-c50738ca32a5",
                                "recommendation": "<p>Broken glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0bb11f39-59b5-403f-9419-e592f84c357e",
                                "recommendation": "<p>Repair broken sash cords</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "53a6034b-9866-4b6d-afdf-968c7cf0da5a",
                                "recommendation": "<p>Cracked glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "25aec21a-bf13-4790-b7af-71429d1ae824",
                                "recommendation": "<p>Hard operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "10bfe1ee-53f7-447f-adcb-94b267df4054",
                                "recommendation": "<p>Moisture present inside the layers of glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5e289e9e-c624-4ab1-883c-a6b8b1612aeb",
                                "recommendation": "<p>The window will not open</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "908017ea-9881-4944-8330-f260f85a84dc",
                                "recommendation": "<p>Window has fogging between the layers of glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "43116826-bda4-4d63-9e1f-6c2d9ff5536a",
                                "recommendation": "<p>Window seal broken</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b8551c48-69e8-498c-8b43-f78295ac23e6",
                                "recommendation": "<p>Window sticks</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "24fd3a41-ab43-4e6c-9f85-1278588a971c",
                                "recommendation": "<p>Replace missing lock hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2936481b-c896-4de3-be0b-9171134ccd98",
                                "recommendation": "<p>Exterior glazing repairs required</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c87ba4bd-6bbb-48c0-a441-4192601b3601",
                                "recommendation": "<p>Painted shut</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5ae61d67-d3dd-4787-bd69-a2d50e049438",
                                "recommendation": "<p>Window sticks-improve operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d95a3d5d-5ae7-4c49-996b-df94c76f131b",
                                "recommendation": "<p>Will not open-requires correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9a21d00e-752f-4f46-b8a5-3032c873a8a8",
                                "recommendation": "<p>Repair faulty operating hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "15dde82c-29de-4b34-997b-fa426cbdac0b",
                                "recommendation": "<p>Locks do not align/lock-need correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "307bffbd-ba56-4975-96e7-3bfa8b6fb09d",
                                "recommendation": "<p>Replace missing casement crank hardware handles</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4d2e9341-236a-426b-87bb-7a5b81bf7da5",
                                "recommendation": "<p>A qualified glazier is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "567f8144-4335-48cf-9b54-3aa0047b316e",
                                "recommendation": "<p>Replace weather stripping</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5412a91a-82f7-417d-adaf-0ded6569383c",
                                "recommendation": "<p>Replace missing pull hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "95b2969f-a3c4-4675-9a94-1c79fecd8ee2",
                                "recommendation": "<p>Hard operation-needs improvements for ease of operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "aac51506-70d1-4d8a-b353-67e4f060e945",
                                "recommendation": "<p>Repair/replace damaged colonial grids</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c2a40649-13fc-4786-b539-864074b06753",
                                "recommendation": "<p>Crack in marble sill noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "188981fe-ba2b-40eb-bdb1-a4be455abaef",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Aluminum slider",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Aluminum double hung",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Non-opening",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood double hung",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Aluminum casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl double hung",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl slider",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steel casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steel frame",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood slider",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "5827a92e-65bf-4e63-ae70-12f0c79db93f"
                            }
                        ]
                    },
                    {
                        "order": 20,
                        prompt: "HVAC Source:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "331bf20a-f460-44c4-8360-1b1fed70a4de",
                                "recommendation": "<p>Heating system inoperable</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9f079b08-8ede-4a83-bdfd-703d79fec357",
                                "recommendation": "<p>Low air flow</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "063d47b8-ae8b-431f-93ab-2cdc19dfe968",
                                "recommendation": "<p>No heat duct</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "88da3ebb-b2a3-419d-a008-a2ab25f12e0f",
                                "recommendation": "<p>Register cover damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3758dc19-cdec-4a65-b04e-3b3723eaa186",
                                "recommendation": "<p>Register cover missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "9b8c5231-d72a-4073-b5a8-0c349e3061eb",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Heating system register",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Convection baseboard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Air exchange ventilation",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Radiant heat",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "c53344c6-88e0-4c94-9d64-d94fa872c892"
                            }
                        ]
                    }
                ],
                "order": 1,
                "is_deleted": 0,
                "created_by": 686,
                "updated_by": 686,
                "created_at": "2022-11-08T18:05:40.000000Z",
                "updated_at": "2022-11-08T18:05:40.000000Z"
            },
            {
                uuid: "77767024-8019-4c53-9bd3-dfdf9f49abac",
                prompt: "Bedroom",
                "introductory_text": null,
                lines: [
                    {
                        "order": 1,
                        prompt: "Closet:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "529c170b-bc64-4728-a54f-4c0f25c0a95c",
                                "recommendation": "<p>Missing clothes pole</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5cc74128-4937-4619-986a-289d621eb9a2",
                                "recommendation": "<p>Walls in closet have cracks not seen in room</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "37468fbb-f335-4fd1-abc4-fb36e95dcf4c",
                                "recommendation": "<p>Water stains noted at closet interior wall</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "be23364a-024f-4ec4-b7aa-cc0693a01511",
                                "recommendation": "<p>Repairs needed at bypass closet doors</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "289c642a-27fa-4e53-bc55-f21b0882c4fe",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Single small",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Walk In",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Small & Walk-in",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Large",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Single",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Small",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Walk In and Large",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "012e634a-83a0-4de8-b82b-9263b68829af"
                            }
                        ]
                    },
                    {
                        "order": 2,
                        prompt: "Ceiling:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "827dac27-91cf-41dc-882a-a32d7cfd93db",
                                "recommendation": "<p>Cracks present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "14032338-a9ab-4bf5-bf44-9606200dcd28",
                                "recommendation": "<p>Loose or peeling paint</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "510f9a5e-e9de-42df-a875-dfba9898da4c",
                                "recommendation": "<p>Loose paneling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d600c25a-2884-4baf-abbf-f2a4d5dad7a5",
                                "recommendation": "<p>Loose plaster</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7fe730b0-253f-49e7-99e4-8155687522ca",
                                "recommendation": "<p>Water stains present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "621ca544-c2cf-4731-b6f4-600e21bfa393",
                                "recommendation": "<p>Stress cracking, see structural notes</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d281ae5c-a169-40f6-a8bf-54152868e21c",
                                "recommendation": "<p>Evidence of possible truss uplift-repair as required, monitor for further movement</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b5e49934-26ce-4cc0-971e-3c1395109110",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "65e6299d-6c5a-4ea1-9148-7cedd892356e",
                                "recommendation": "<p>Mold is present and is a potential health concern. Removal and/or correction is required.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6fd9a542-cb01-433a-aa8f-72f2f00ad737",
                                "recommendation": "<p>Evidence of past or present water staining</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "b67323da-b4ef-4052-83bd-a2370f63b75f",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Paint",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Exposed framing",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Mirrors",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Texture paint",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Suspended ceiling",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "a44a55a9-5b49-48aa-83ae-539074316c9d"
                            }
                        ]
                    },
                    {
                        "order": 3,
                        prompt: "Walls:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1a07ac34-051d-4f78-930a-d19a6c2d8829",
                                "recommendation": "<p>Cracks present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7ee8dd35-d32d-4ad0-99e9-afa646c87026",
                                "recommendation": "<p>Evidence of past or present water leakage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "be976bf7-ca1b-4dd8-844e-c076c1bd7756",
                                "recommendation": "<p>Evidence of possible truss uplift</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "92613eaf-b60c-42ef-8dcd-15908011e29f",
                                "recommendation": "<p>Loose or peeling paint</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2ed254bf-fc5e-4dd5-83a6-6e4bca43b8a1",
                                "recommendation": "<p>Loose paneling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f033e00e-0504-4271-a409-8a90bbdf48fb",
                                "recommendation": "<p>Water stains present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4c7de966-fab5-44b2-b07c-f8fc77538d3c",
                                "recommendation": "<p>Evidence of past or present water staining</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "41983194-70cd-45df-ae4a-34f526285dd7",
                                "recommendation": "<p>Loose or missing tile</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fc111cf9-d486-4179-ace9-bc7d6f510d8e",
                                "recommendation": "<p>Mold present-potential health concern-needs removal/correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "51a2d8bd-5c50-408f-b520-f97a7346fd98",
                                "recommendation": "<p>Paneling loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6944280f-ab5e-4d9c-a0bf-2e8e4083c26c",
                                "recommendation": "<p>Some ceiling pads damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0f6bf81a-7a85-4b50-8e66-6c95f08fd409",
                                "recommendation": "<p>Some evidence of previous truss uplift</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1b253aed-afab-4858-87ff-a8f0fa1f6dcf",
                                "recommendation": "<p>Inspection does not cover any damage concealed by rugs, carpeting, wall paneling, furniture</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0671b896-b030-4b2a-8d68-e3cd3839ad16",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "34a57730-b29b-480f-a107-a5bd82157b05",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Paint and paneling",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Exposed framing",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Paint and tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wallpaper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Paint",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Concrete",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wallpaper and tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fire rated",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "7dd9cd16-a668-43f7-bb75-fba6084d1762"
                            }
                        ]
                    },
                    {
                        "order": 4,
                        prompt: "Floor:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4a273177-fee5-4bf7-a380-1a68880e9c4e",
                                "recommendation": "<p>Broken floorboard</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "389e4efd-ba0b-4bac-b309-242836c595c1",
                                "recommendation": "<p>Damaged carpet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "79ebb50c-b9a0-494e-ab60-768bd6e4e7a6",
                                "recommendation": "<p>Loose carpet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2b1c324e-82bc-4a1f-819b-9e9d345aa2b3",
                                "recommendation": "<p>Loose flooring</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "80c0aa5d-095c-42c9-8ad6-cf3ce8dba425",
                                "recommendation": "<p>The floor squeaks when walked on</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1394ebcf-b666-4a63-b452-0b18d773cc33",
                                "recommendation": "<p>This inspection does not cover any damage concealed by carpeting, rugs or furniture</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ad1f3726-1493-4d3c-a811-562777376a4d",
                                "recommendation": "<p>Water damage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1e1bcc96-41fa-4055-aaa7-307f269a6d35",
                                "recommendation": "<p>Noted uneven floor due to settlement</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "32401ce7-be2e-44b9-aaf9-1d8a9c71b81f",
                                "recommendation": "<p>Carpet Stained/soiled</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ccaee7c3-52b5-4c06-88b6-2272d6809be9",
                                "recommendation": "<p>Worn wood floor finish</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "732818ea-605f-45d6-8845-f1098c0a5860",
                                "recommendation": "<p>Cuts or minor damage in flooring, repair/replace as required. Restretch carpets- trip hazard</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4bf063b5-a5b7-4cc5-8c27-a68553aaac9c",
                                "recommendation": "<p>Floor shows evidence of previous water penetration/stains</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "69b21dbc-a76d-48ad-bc9f-7cde7e2231a9",
                                "recommendation": "<p>Worn carpets</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ae750d10-220c-45ae-9629-0d88ba0a6cfd",
                                "recommendation": "<p>Termite damage noted in wood flooring, repairs required</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1bd0db65-4460-4e6f-a842-fdf502153ed2",
                                "recommendation": "<p>Gapping in hardwood flooring noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ebca66c1-c241-4b94-b0fb-98b29fc13681",
                                "recommendation": "<p>Floor squeaks noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2a9360d4-7f11-46d5-958a-3c410a08ff58",
                                "recommendation": "<p>Raised seams in vinyl flooring-repair</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3590cd52-7fd3-46c8-957d-c46250ece11c",
                                "recommendation": "<p>Uneven wood floor finish (difference under carpets/rugs) due to sunlight</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "290ec196-0551-4558-87a7-e15b0a507f7d",
                                "recommendation": "<p>Loose tile/grout, repair as required</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "b570c14a-7508-4446-80ee-7ebe7b80250c",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Hardwood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Ceramic tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Linoleum",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Carpet",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl floor covering",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Poured",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "805ad327-00a3-4853-99c8-030bc703d825"
                            }
                        ]
                    },
                    {
                        "order": 5,
                        prompt: "Doors:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "20dfc820-f49e-4646-afe6-09c2f9103ec8",
                                "recommendation": "<p>Loose hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a82245ea-1a11-4487-829f-ded2ead68940",
                                "recommendation": "<p>Missing hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3a142c10-14bf-42ac-9791-9260770eefdc",
                                "recommendation": "<p>The door has moisture damage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "10eda443-d6c6-4a62-b204-90c1df6c97af",
                                "recommendation": "<p>The door is sticking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "57fa6d08-e92d-44f7-8931-28e996b0a285",
                                "recommendation": "<p>The surface laminate is peeling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6fd5318c-3561-4d5b-b490-f42fce157ce1",
                                "recommendation": "<p>Wood rot</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1b52aa4d-af87-483d-9812-7a317f57369b",
                                "recommendation": "<p>Lockset(s) not latching-requires adjustment</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e5550ccc-229d-4eb3-b0bd-a4d3d3b269d5",
                                "recommendation": "<p>Door(s) has minor damage from door stop-repair/replace</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "003b3174-5cb7-4bb8-90db-6fc1176b4ac6",
                                "recommendation": "<p>Lockset(s) not functioning-repair</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "937ec167-a1e5-4a7c-9c7e-86d5aeff3b00",
                                "recommendation": "<p>Replace missing door(s)</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "99965f6a-ccf8-400c-b5bb-a5d25e9851e3",
                                "recommendation": "<p>Door damaged by moisture</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a6dca6b6-23df-4414-8905-637a210e9bb8",
                                "recommendation": "<p>Doors need trimming/adjustment</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c937c05d-8c4c-4630-9458-4634f0d883e4",
                                "recommendation": "<p>Replace missing bypass hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3cef484f-bc3d-4116-a039-aecee6a21d75",
                                "recommendation": "<p>Lockset hardware installed opposite with locking knob to outside of room-needs correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "dede56ef-3b3d-4435-8206-7a4f2efc7f51",
                                "recommendation": "<p>Align/adjust closet doors</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5a5e1992-5def-4b3d-83e7-f803780fe24d",
                                "recommendation": "<p>Undercut where rubbing on carpet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "5274e9b6-e2b3-4a53-840d-1bd3e05ae56b",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Hollow wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "French",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Bi-fold",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Solid wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Dutch",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "e0749e42-d219-4701-b48e-92be2c13c228"
                            }
                        ]
                    },
                    {
                        "order": 6,
                        prompt: "Windows:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cc9f7ce8-1bea-4d96-893e-8c3de3b9c980",
                                "recommendation": "<p>Broken glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5cc1c8e4-80fa-4f06-bd71-d7142dec48da",
                                "recommendation": "<p>Repair broken sash cords</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1414f74b-cd2c-49b9-a70b-7b3639ccb18e",
                                "recommendation": "<p>Cracked glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d1b12ee8-50e9-45d3-861c-75904b47d144",
                                "recommendation": "<p>Hard operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "14292a0d-f01b-4635-ba4d-8967fdc95cb5",
                                "recommendation": "<p>Moisture present inside the layers of glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2cce693c-7a2f-434f-874f-6d3ad9b4e010",
                                "recommendation": "<p>The window will not open</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9ecb1b27-0124-46ed-803e-9d4a91aa3411",
                                "recommendation": "<p>Window has fogging between the layers of glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "678b5948-6a18-4175-845c-d534adf87071",
                                "recommendation": "<p>Window seal broken</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bd9a1262-ad0c-4691-899d-1c19ad493ea4",
                                "recommendation": "<p>Window sticks</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f36244dd-f4a8-42ef-a020-7cc7c2637f1f",
                                "recommendation": "<p>Replace missing lock hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0cfc6aa8-daa1-4dbd-aa93-c22036ec7c6a",
                                "recommendation": "<p>Exterior glazing repairs required</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "52a41293-0aa4-4e7e-82e0-1ed9d465a2c7",
                                "recommendation": "<p>Painted shut</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "00676519-d0d5-42b6-a5ca-fc34abdcac83",
                                "recommendation": "<p>Window sticks-improve operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "354d4404-8855-4509-9b27-d9c68be5ecf0",
                                "recommendation": "<p>Will not open-requires correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6617db89-9167-4c56-a307-3b37d4d385fd",
                                "recommendation": "<p>Repair faulty operating hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d694be31-d31b-491a-a3da-edc937ef6b42",
                                "recommendation": "<p>Locks do not align/lock-need correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9afbbd01-52db-4336-9fe3-71d4c10d5b42",
                                "recommendation": "<p>Replace missing casement crank hardware handles</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5cf1c192-11f5-4856-a289-e2cdf57d0f36",
                                "recommendation": "<p>A qualified glazier is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c57a3d67-848f-44f4-a48d-fc822d41b792",
                                "recommendation": "<p>Replace weather stripping</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "13e68113-f7be-4346-b7ee-7bccaf0a6f7d",
                                "recommendation": "<p>Replace missing pull hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e5c59e8f-1f1a-4536-890d-9a67cda13083",
                                "recommendation": "<p>Hard operation-needs improvements for ease of operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e4caf9ed-0b2c-424a-9acc-a8e7bff46817",
                                "recommendation": "<p>Repair/replace damaged colonial grids</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "442436e4-3307-4ce9-922a-abcd2be52ce4",
                                "recommendation": "<p>Crack in marble sill noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "1e426df8-347d-48c6-8c92-f7c79fd813fb",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Aluminum slider",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Aluminum double hung",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Non-opening",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood double hung",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Aluminum casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl double hung",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl slider",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steel casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steel frame",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood slider",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "2e3c357a-7378-4174-acc4-96d04870350b"
                            }
                        ]
                    },
                    {
                        "order": 7,
                        prompt: "Electrical:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5a6a32c6-8401-445c-9469-c41bdf888383",
                                "recommendation": "<p>Open or missing ground</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cc987d6a-17a8-4ef3-8c88-85948d12cc72",
                                "recommendation": "<p>Suggest evaluation by licensed electrician</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f57d1e21-fd3b-463d-9bc1-1446285e6145",
                                "recommendation": "<p>Non-GFCI circuit</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "eaa7abc9-44c7-4a80-bec1-6d71daebec28",
                                "recommendation": "<p>Reversed polarity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "16d2bb22-bb72-4321-87fc-58c963236ceb",
                                "recommendation": "<p>Install missing junction box cover plates</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7212b29a-0149-43cd-b0df-d28c28bdbbe6",
                                "recommendation": "<p>Install missing junction boxes and cover plates at open splice connections</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "02ff96d8-6588-424f-b186-0ba9a3072720",
                                "recommendation": "<p>Protect exposed surface mounted wiring in conduit to 7'-0' above floor</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4a5a1a2e-cf62-42f9-8c5d-d6e0b683a3e7",
                                "recommendation": "<p>Faulty GFCI outlet-replace</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1f8013ed-1524-494e-988e-1a1f7ee49aa1",
                                "recommendation": "<p>Reversed polarity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "31139995-5689-4b56-a2bc-7d3a993ad58b",
                                "recommendation": "<p>Missing outlet/switch cover plates</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a6a990c0-2631-43ff-91e5-d12334d92535",
                                "recommendation": "<p>Loose switch or outlet should be resecured</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e4958d3d-2156-4429-bad9-c4350f972e18",
                                "recommendation": "<p>Ceiling fan inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3394ef71-8ba3-4af0-bcf9-ca52eb6df70f",
                                "recommendation": "<p>Worn outlets creating intermittent ground- replace outlets</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f7f3df56-4e7c-4511-becd-fc092de00de7",
                                "recommendation": "<p>Light fixture not functional-repair/replace</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0a9b199d-41ae-4269-a70c-df5c29a42be3",
                                "recommendation": "<p>Open grounds noted at 3 prong outlets</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "82f2bde7-4169-4cc3-8c23-943de5ce3cfc",
                                "recommendation": "<p>Remove temporary wiring running through walls -amateur installation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a91b4eed-647c-41d8-9f07-90a0668edfe0",
                                "recommendation": "<p>Outlets not working</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8ef0d5bf-3638-42c9-862d-60d31e1e9e14",
                                "recommendation": "<p>Non-GFCI circuit -recommend GFCI circuit be installed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "296d7b17-71ca-4e3b-a407-1dcf97cc2309",
                                "recommendation": "<p>Insufficient outlets for space</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9f979772-e49a-4367-9ddc-7646758ceace",
                                "recommendation": "<p>A licensed electrician is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2fd242f7-f0fe-4588-b159-062dbefc539a",
                                "recommendation": "<p>Replace damaged outlet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "aa936e12-a25f-450c-8c90-be6b7d454666",
                                "recommendation": "<p>110 VAC Outlets, Lighting Circuit and ceiling fan</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "286a9510-e25d-4bd4-931e-cbd09f13d4c4",
                                "recommendation": "<p>Install missing outlet/switch cover plate(s)</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "85dae70a-c915-45f0-a028-7edbf087a0f4",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "110 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC GFCI",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC/220 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "220 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC outlets and lighting circuits",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "c2a4dec3-d258-488b-b2ae-fdc1832aa928"
                            }
                        ]
                    },
                    {
                        "order": 8,
                        prompt: "HVAC Source:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7fb38995-f042-4109-a6e2-b9f56b3d18cf",
                                "recommendation": "<p>Heating system inoperable</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2d5da6ce-80ed-40e0-b510-ada805b5fbb0",
                                "recommendation": "<p>Low air flow</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a0fda959-2d52-4af5-bb88-fea648573ba8",
                                "recommendation": "<p>No heat duct</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "dbe86eb1-2dfe-490d-bed8-452558d806e3",
                                "recommendation": "<p>Register cover damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2cbbc81c-5c82-4742-bede-a823305275cc",
                                "recommendation": "<p>Register cover missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "198d0750-f5cf-489c-b8a1-79a935b1a8e0",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Heating system register",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Convection baseboard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Air exchange ventilation",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Radiant heat",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "e84106da-ca60-46e3-a07e-a0af57d9a102"
                            }
                        ]
                    },
                    {
                        "order": 9,
                        prompt: "Smoke Detector:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7fe52360-519f-4f9a-8481-e4748f3fe687",
                                "recommendation": "<p>Evaluation by a fire alarm certified licensed electrician is recommended</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "035cb9af-f3bf-441e-81ef-c9af9700a7b0",
                                "recommendation": "<p>Not tested due to lack of electricity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f7bcf71c-c49f-4cf0-9cfd-1762b64ac4f3",
                                "recommendation": "<p>Battery missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "164644f8-c7c9-45ce-a5cb-609113bc4571",
                                "recommendation": "<p>Improperly wired</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "03f63a69-9dd1-41ac-9dad-8fc4446484fc",
                                "recommendation": "<p>Inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "d65817a0-27d0-4c30-8461-93132f563b3b",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Battery operated with light",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Battery operated",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hard wired with battery back up and light",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hard wired",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hard wired with battery back up",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "06f70d40-bc02-409f-8992-0f07cd25e980"
                            }
                        ]
                    },
                    {
                        "order": 10,
                        prompt: "Carbon Monoxide Detector:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "229bf6a0-5c04-4ec9-861c-68c88278169e",
                                "recommendation": "<p>Not tested due to lack of electricity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "060fb661-5dee-4f2c-8800-88eaacf4fe1a",
                                "recommendation": "<p>Battery missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e039363d-f98b-4146-8716-f543af27d62e",
                                "recommendation": "<p>Improperly wired</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "818a6506-0c72-412c-b3a0-3235a3a249db",
                                "recommendation": "<p>Inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "948e7100-555b-40a1-b67f-f1440b7c1897",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Battery operated with light",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Battery operated",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hard wired with battery back up and light",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hard wired",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hard wired with battery back up",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "dea0d2be-869f-4916-beed-8f2b754e8c3a"
                            }
                        ]
                    }
                ],
                "order": 1,
                "is_deleted": 0,
                "created_by": 686,
                "updated_by": 686,
                "created_at": "2022-11-08T18:05:40.000000Z",
                "updated_at": "2022-11-08T18:05:40.000000Z"
            },
            {
                uuid: "f4dd141c-21bf-45c9-8a4c-10a73e350b2d",
                prompt: "Living Space",
                "introductory_text": null,
                lines: [
                    {
                        "order": 1,
                        prompt: "Closet:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "efae7781-03a5-42c4-a933-ba2499072239",
                                "recommendation": "<p>Missing clothes pole</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6c2d693d-d86e-488c-bfa5-53b2b084b937",
                                "recommendation": "<p>Walls in closet have cracks not seen in room</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f53cc162-7d1d-4918-9f3f-5e297d84698e",
                                "recommendation": "<p>Water stains noted at closet interior wall</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9ad8515e-4fa5-4bb7-9611-ba514a235d81",
                                "recommendation": "<p>Repairs needed at bypass closet doors</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "dcf541ce-8285-42b6-9a5b-78b81f24c205",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Single small",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Walk In",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Small & Walk-in",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Large",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Single",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Small",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Walk In and Large",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "b058bf4a-2559-46f4-97d2-16f2552030f7"
                            }
                        ]
                    },
                    {
                        "order": 2,
                        prompt: "Ceiling:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "04c33cd5-0e34-48b3-86cb-b8257841e03c",
                                "recommendation": "<p>Cracks present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fdfb2d4a-a118-4914-ae88-536ade23ca0d",
                                "recommendation": "<p>Loose or peeling paint</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "090c7523-5fec-4b2d-8f01-7f9391c8baee",
                                "recommendation": "<p>Loose paneling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cda967e8-bdae-4dc1-8893-68eb51ccbae9",
                                "recommendation": "<p>Loose plaster</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ba7ea691-07d9-4c93-b25e-c5ac3c53b6aa",
                                "recommendation": "<p>Water stains present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "92734039-162c-467a-adab-2c6f2f75941e",
                                "recommendation": "<p>Stress cracking, see structural notes</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "06bde52f-378d-4c2c-b642-f0d4092315a7",
                                "recommendation": "<p>Evidence of possible truss uplift-repair as required, monitor for further movement</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2ad6d8a1-ce46-496c-879e-fae6ecd1b4cf",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f881e1d8-a012-4e45-b0d3-41921a0fe792",
                                "recommendation": "<p>Mold is present and is a potential health concern. Removal and/or correction is required.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "943c5779-11ec-42eb-83be-3bdfe47ca181",
                                "recommendation": "<p>Evidence of past or present water staining</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "12086ef3-e172-4582-bed1-676ca961953a",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Paint",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Exposed framing",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Mirrors",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Texture paint",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Suspended ceiling",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "5f44cc19-6221-4e86-ac5b-8fcbea19b127"
                            }
                        ]
                    },
                    {
                        "order": 3,
                        prompt: "Walls:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a073e48b-a67c-4797-909b-1d8e8a40b0b4",
                                "recommendation": "<p>Cracks present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c03f8a23-9ce3-4c6b-9941-57f252a19151",
                                "recommendation": "<p>Evidence of past or present water leakage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "19fec952-3043-4d31-b2dd-40444f058536",
                                "recommendation": "<p>Evidence of possible truss uplift</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "27443264-288d-49d6-889c-bde4585c1906",
                                "recommendation": "<p>Loose or peeling paint</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "dc2cf99a-6334-4ff3-acce-00cce056365b",
                                "recommendation": "<p>Loose paneling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a06ccd64-2cae-42db-b175-2299a8e8f821",
                                "recommendation": "<p>Water stains present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cd650bfc-bf5e-46f2-a519-67cfced032bc",
                                "recommendation": "<p>Evidence of past or present water staining</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ef0d5f39-44f9-433f-8202-65f13d5ef7bb",
                                "recommendation": "<p>Loose or missing tile</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c5f560b2-23b4-491a-98d3-e4e414e1524d",
                                "recommendation": "<p>Mold present-potential health concern-needs removal/correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b060fb54-50be-485e-a9fb-f9a74418e740",
                                "recommendation": "<p>Paneling loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3a1ed283-2dd8-4b75-8076-dc81565147fd",
                                "recommendation": "<p>Some ceiling pads damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5fa8fd60-804c-4a4c-86f9-745352e47226",
                                "recommendation": "<p>Some evidence of previous truss uplift</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4b05e953-f709-48ca-9121-3ff329e641f8",
                                "recommendation": "<p>Inspection does not cover any damage concealed by rugs, carpeting, wall paneling, furniture</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "17040985-3bc4-4098-90f9-4f9ebdb57941",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "48e71b89-9142-4f3b-bc3c-4d0af19548ed",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Paint and paneling",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Exposed framing",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Paint and tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wallpaper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Paint",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Concrete",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wallpaper and tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fire rated",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "2ef9fd84-8092-4f86-8046-38f453ddcc53"
                            }
                        ]
                    },
                    {
                        "order": 4,
                        prompt: "Floor:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3c3d385c-de2f-42a9-a233-8d62dcbcc883",
                                "recommendation": "<p>Broken floorboard</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f6787522-c1bb-42f2-9979-c43be94bf33f",
                                "recommendation": "<p>Damaged carpet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e843f7c3-6aa6-4dcd-97e9-330ead1b7ab8",
                                "recommendation": "<p>Loose carpet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5fd1960e-8f18-4265-925c-bd305e6738ec",
                                "recommendation": "<p>Loose flooring</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6dd3ef15-1d4b-43a1-86b8-c8db3aaf4a7c",
                                "recommendation": "<p>The floor squeaks when walked on</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "40390b8f-f77a-41cc-a2d2-f1c13703a250",
                                "recommendation": "<p>This inspection does not cover any damage concealed by carpeting, rugs or furniture</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1228237f-193b-45dd-ab09-cdee9532d097",
                                "recommendation": "<p>Water damage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "27900f60-6115-4068-bc8f-0e96b262d86a",
                                "recommendation": "<p>Noted uneven floor due to settlement</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6fa5d7a3-799d-48ff-bf0b-e3f58096e127",
                                "recommendation": "<p>Carpet Stained/soiled</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b48ad376-bc82-4969-a255-a712e7e9bb07",
                                "recommendation": "<p>Worn wood floor finish</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d4e19c64-b29e-4f08-8bf9-a6900aaff275",
                                "recommendation": "<p>Cuts or minor damage in flooring, repair/replace as required. Restretch carpets- trip hazard</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "96567d6f-c861-4877-97e8-87c8805670b9",
                                "recommendation": "<p>Floor shows evidence of previous water penetration/stains</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5f9c60ba-7652-4468-8397-bde4eff94958",
                                "recommendation": "<p>Worn carpets</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "76fbb4e5-ce4f-4d3d-8c24-f0b258aa6d34",
                                "recommendation": "<p>Termite damage noted in wood flooring, repairs required</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e872b709-e243-4dec-b58a-2a6d2e81027e",
                                "recommendation": "<p>Gapping in hardwood flooring noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "015985e1-31a7-41bb-a763-b464e8488dea",
                                "recommendation": "<p>Floor squeaks noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "43a75aa4-ca16-40d5-8473-bb36f8c19124",
                                "recommendation": "<p>Raised seams in vinyl flooring-repair</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4008b917-2720-4b94-8752-11c422639abc",
                                "recommendation": "<p>Uneven wood floor finish (difference under carpets/rugs) due to sunlight</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3e8ad0ef-59bd-4088-a944-8562266ccefd",
                                "recommendation": "<p>Loose tile/grout, repair as required</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "17acc22b-cfe3-4077-a3d0-f6f8ce8c054a",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Hardwood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Ceramic tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Linoleum",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Carpet",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl floor covering",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Poured",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "8d21301b-752a-45ff-9a8f-8804edbbd5c6"
                            }
                        ]
                    },
                    {
                        "order": 5,
                        prompt: "Doors:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "dca39b55-ce81-400d-ad37-188a76839489",
                                "recommendation": "<p>Loose hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6ae245e4-765c-40ff-93e8-89699d19e0ea",
                                "recommendation": "<p>Missing hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0a997961-9cd4-4d59-8c9a-18baaaf745a4",
                                "recommendation": "<p>The door has moisture damage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "920d7563-21a1-4daf-85c2-067027f1f8f6",
                                "recommendation": "<p>The door is sticking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5f12b20d-7008-4959-8b7a-da28325d7ae9",
                                "recommendation": "<p>The surface laminate is peeling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5d336ef6-b7bf-4338-9bd9-41ff2deaebb2",
                                "recommendation": "<p>Wood rot</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "67a0ad8d-c3d9-43dd-90ac-095ae86951b7",
                                "recommendation": "<p>Lockset(s) not latching-requires adjustment</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4550aa15-e0ec-4cf7-84d6-7036d66a4920",
                                "recommendation": "<p>Door(s) has minor damage from door stop-repair/replace</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c75983cb-7fa0-413b-97cd-83b0c78d9187",
                                "recommendation": "<p>Lockset(s) not functioning-repair</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c65b0bee-af3a-4645-bb57-1b6900ef958c",
                                "recommendation": "<p>Replace missing door(s)</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "26f29e5e-2e1f-43d8-9730-8edc4da0532a",
                                "recommendation": "<p>Door damaged by moisture</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "68d0f74d-48da-4e24-bbe6-2a4e8d65433b",
                                "recommendation": "<p>Doors need trimming/adjustment</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a13f0fab-40e0-4dac-97b5-ada733b2bc8f",
                                "recommendation": "<p>Replace missing bypass hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "666a1b73-bc4d-41db-86d7-bdc0f5a487db",
                                "recommendation": "<p>Lockset hardware installed opposite with locking knob to outside of room-needs correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b461ee74-f374-4710-be31-31d3fe3ba9e0",
                                "recommendation": "<p>Align/adjust closet doors</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "08e23be5-f1ca-479a-bfdd-e210fef4d8be",
                                "recommendation": "<p>Undercut where rubbing on carpet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "52e37ceb-dc5e-45ef-8e37-95f18ee6e8b7",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Hollow wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "French",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Bi-fold",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Solid wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Dutch",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "0bb99d31-96f1-4533-b77c-4e4ad5f4ab08"
                            }
                        ]
                    },
                    {
                        "order": 6,
                        prompt: "Windows:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b5c6de1a-da53-4d9a-a18c-21188abc0208",
                                "recommendation": "<p>Broken glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "dbf6d35c-6047-497c-91d1-70e9f378a3c4",
                                "recommendation": "<p>Repair broken sash cords</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "dc81b7fa-3345-4d2b-8671-f2e85bba2bc9",
                                "recommendation": "<p>Cracked glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8bd5e7c2-6420-4be9-b130-cd574c3a2b11",
                                "recommendation": "<p>Hard operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "22479315-2cab-4432-ba78-3847c7b4d45e",
                                "recommendation": "<p>Moisture present inside the layers of glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f2e4e333-66a3-4b04-864e-c2764c7d6125",
                                "recommendation": "<p>The window will not open</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f56ea6f8-ec57-4457-bc14-8d0a1204cff1",
                                "recommendation": "<p>Window has fogging between the layers of glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4debe7d5-786b-403c-ae33-72f8429e561e",
                                "recommendation": "<p>Window seal broken</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "630e7b2e-b083-4fbf-a3bd-7875a89e69fb",
                                "recommendation": "<p>Window sticks</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "15393ae8-1f38-4857-8866-ab3952d35146",
                                "recommendation": "<p>Replace missing lock hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4c63cd8a-7cc5-40a4-8515-1dce7c214c69",
                                "recommendation": "<p>Exterior glazing repairs required</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2649c630-855d-4beb-9101-1d3eda1f6629",
                                "recommendation": "<p>Painted shut</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f3f4133c-b724-4d65-bf0e-0a27599dbf4f",
                                "recommendation": "<p>Window sticks-improve operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7c58b123-203a-4b84-8cb5-57f8f27bc873",
                                "recommendation": "<p>Will not open-requires correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2351a23a-7f3b-4274-a594-509a031e421a",
                                "recommendation": "<p>Repair faulty operating hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b85c70f2-477f-4920-80c3-0d32dd69e3e1",
                                "recommendation": "<p>Locks do not align/lock-need correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f2e0880c-a601-474c-a629-7a2510a015bd",
                                "recommendation": "<p>Replace missing casement crank hardware handles</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e445138a-9c07-4f39-ae24-30771990b9cd",
                                "recommendation": "<p>A qualified glazier is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9e0a58ef-fe9d-4990-ad4f-dcedb6c07c41",
                                "recommendation": "<p>Replace weather stripping</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ef0b4aa9-40eb-4ed3-8a2a-40bbdbd21f37",
                                "recommendation": "<p>Replace missing pull hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1bafb079-ff49-415d-b3ba-5599bb6d9c30",
                                "recommendation": "<p>Hard operation-needs improvements for ease of operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b0dfbdc3-be2d-486e-bda3-1d96289b09d3",
                                "recommendation": "<p>Repair/replace damaged colonial grids</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e7fc80b8-e6cd-4cb3-a844-3d904012e3ce",
                                "recommendation": "<p>Crack in marble sill noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "a86fab98-4179-44a5-b059-10ff65bf8df9",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Aluminum slider",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Aluminum double hung",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Non-opening",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood double hung",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Aluminum casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl double hung",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl slider",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steel casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steel frame",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood slider",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "052c278b-d61c-42f9-b43c-f5464c83e678"
                            }
                        ]
                    },
                    {
                        "order": 7,
                        prompt: "Electrical:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "01175011-1236-4406-ab7d-ef334456c09c",
                                "recommendation": "<p>Open or missing ground</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f82fbe4c-dd89-479a-bef4-d979482dd19e",
                                "recommendation": "<p>Suggest evaluation by licensed electrician</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8cd5117f-198a-4183-8031-01c8665b276e",
                                "recommendation": "<p>Non-GFCI circuit</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8a6f4129-b704-46de-be77-929ec18bb727",
                                "recommendation": "<p>Reversed polarity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1fa74470-a218-4d7e-bc37-4a1a2e1bc15f",
                                "recommendation": "<p>Install missing junction box cover plates</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b3609c6b-7941-45ce-99ce-2e6a5f6a0ee3",
                                "recommendation": "<p>Install missing junction boxes and cover plates at open splice connections</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "84565447-89f0-4300-a6a1-46bcd990fd1c",
                                "recommendation": "<p>Protect exposed surface mounted wiring in conduit to 7'-0' above floor</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "aa09cf02-69b4-45f0-8ee4-66b9453c024f",
                                "recommendation": "<p>Faulty GFCI outlet-replace</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e26239d7-d00b-4f39-a1d1-5c449c8e5004",
                                "recommendation": "<p>Reversed polarity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "71da5333-61f9-40e6-875f-0be82d1220f8",
                                "recommendation": "<p>Missing outlet/switch cover plates</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "80405155-3404-4a4a-838d-3cc36944a902",
                                "recommendation": "<p>Loose switch or outlet should be resecured</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e731f041-2519-413f-b6bc-136bff754128",
                                "recommendation": "<p>Ceiling fan inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "deb0731b-f67c-4bdb-9d37-b87402756ad5",
                                "recommendation": "<p>Worn outlets creating intermittent ground- replace outlets</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "af24297a-0682-430c-a763-97ae2d30d52a",
                                "recommendation": "<p>Light fixture not functional-repair/replace</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3deb35e8-841a-4aa8-9e00-125b8aa27e3f",
                                "recommendation": "<p>Open grounds noted at 3 prong outlets</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "722f3e2f-9d10-4674-a5dc-a8fd68deeb75",
                                "recommendation": "<p>Remove temporary wiring running through walls -amateur installation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c51031c6-ca46-44d8-a168-4452645e6214",
                                "recommendation": "<p>Outlets not working</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ad049434-0fc3-4a02-9426-175a75609203",
                                "recommendation": "<p>Non-GFCI circuit -recommend GFCI circuit be installed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6706ec5c-5ca2-46af-84ee-8964dc593ba5",
                                "recommendation": "<p>Insufficient outlets for space</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "44fe9b12-8d78-4bc0-ad68-ca3e09ed8dd6",
                                "recommendation": "<p>A licensed electrician is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f771ea72-fd97-43b0-8fee-7d352be1de19",
                                "recommendation": "<p>Replace damaged outlet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7d5e281c-0998-4f34-bd44-326fee3f4346",
                                "recommendation": "<p>110 VAC Outlets, Lighting Circuit and ceiling fan</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7f9e8c37-0401-48b8-a328-b0d6e712f344",
                                "recommendation": "<p>Install missing outlet/switch cover plate(s)</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "b3be5f53-cb39-45c6-b140-11e3d50ca965",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "110 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC GFCI",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC/220 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "220 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC outlets and lighting circuits",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "b887a6b4-2af6-40ba-8664-73d81beed02c"
                            }
                        ]
                    },
                    {
                        "order": 8,
                        prompt: "HVAC Source:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "717318fe-70f8-4b84-888e-f057427578f3",
                                "recommendation": "<p>Heating system inoperable</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "06dabca2-2e0b-4131-aa8b-b38540c618a0",
                                "recommendation": "<p>Low air flow</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "32c9dc43-35c6-47f0-8859-8b401fe43947",
                                "recommendation": "<p>No heat duct</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5eea8ea7-013c-4aae-afb4-97622b5fa5d0",
                                "recommendation": "<p>Register cover damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8422ef9e-c879-4ea3-902b-bc5f0302570c",
                                "recommendation": "<p>Register cover missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "cbca1081-4af0-441e-a026-45aa8bbf8e32",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Heating system register",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Convection baseboard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Air exchange ventilation",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Radiant heat",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "b00401e5-a92d-42f4-aea8-2bb8bcb6c11b"
                            }
                        ]
                    },
                    {
                        "order": 9,
                        prompt: "Smoke Detector:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3750cc52-a943-4928-bb66-1df02e792400",
                                "recommendation": "<p>Evaluation by a fire alarm certified licensed electrician is recommended</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7c7354f1-81a3-4960-9dbb-4122d89aad4d",
                                "recommendation": "<p>Not tested due to lack of electricity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e8f4e152-fffb-48dd-9277-8eba66593668",
                                "recommendation": "<p>Battery missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b2ae807a-b38e-4e32-83fd-92b96167dcde",
                                "recommendation": "<p>Improperly wired</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "15c9577a-e9da-4965-8f16-986f43f6ecc7",
                                "recommendation": "<p>Inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "1f03f56f-377d-4060-bc66-97196dca2205",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Battery operated with light",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Battery operated",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hard wired with battery back up and light",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hard wired",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hard wired with battery back up",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "37b113b6-7943-4aff-841f-5992c6893575"
                            }
                        ]
                    },
                    {
                        "order": 10,
                        prompt: "Carbon Monoxide Detector:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4243659c-7441-45d7-a21c-b7ff0c15d582",
                                "recommendation": "<p>Not tested due to lack of electricity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a799419c-2627-4bd7-8708-e62a8cc76efc",
                                "recommendation": "<p>Battery missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "732fee6b-6013-4d34-996d-820826490534",
                                "recommendation": "<p>Improperly wired</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d2bbe151-374c-4e0c-8d44-f72f039ddd58",
                                "recommendation": "<p>Inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "cca25c83-9091-4925-bc73-748331931709",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Battery operated with light",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Battery operated",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hard wired with battery back up and light",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hard wired",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hard wired with battery back up",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "687911f4-2b6b-41aa-81f9-0d32aa60711e"
                            }
                        ]
                    }
                ],
                "order": 1,
                "is_deleted": 0,
                "created_by": 686,
                "updated_by": 686,
                "created_at": "2022-11-08T18:05:40.000000Z",
                "updated_at": "2022-11-08T18:05:40.000000Z"
            },
            {
                uuid: "1fe528d5-d917-44f1-bbc1-8e6838582b96",
                prompt: "Laundry Room/Area",
                "introductory_text": null,
                lines: [
                    {
                        "order": 1,
                        prompt: "Closet:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "65b25fd5-19af-4e16-8988-8626271497cf",
                                "recommendation": "<p>Missing clothes pole</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ff21fb9a-bfca-4ef5-a80d-772c7883c11c",
                                "recommendation": "<p>Walls in closet have cracks not seen in room</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "89faddf9-cfa5-4529-b684-5eee415b81ba",
                                "recommendation": "<p>Water stains noted at closet interior wall</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2e4e0170-75d5-4cc4-9ea3-f14a7dc25db8",
                                "recommendation": "<p>Repairs needed at bypass closet doors</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "3daf78a2-7d06-4842-87ad-f27320af4482",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Single small",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Walk In",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Small & Walk-in",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Large",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Single",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Small",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Walk In and Large",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "3df5e9c6-acc2-4f72-a2a0-2c2bbb07c66e"
                            }
                        ]
                    },
                    {
                        "order": 2,
                        prompt: "Ceiling:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "630ea85f-754d-41b8-91ce-108b780f1321",
                                "recommendation": "<p>Cracks present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e21e12ce-f3be-430c-b1c0-d1495594f58f",
                                "recommendation": "<p>Loose or peeling paint</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f9a9fb80-767a-411b-9f7b-232f86f7ba75",
                                "recommendation": "<p>Loose paneling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d6c8f294-d8c2-488a-a042-ef986cf86fe3",
                                "recommendation": "<p>Loose plaster</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3a08aa40-5d48-4500-9567-b318e4f49b67",
                                "recommendation": "<p>Water stains present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "45c829d0-4a7c-47cd-b59f-20f1a336ee5a",
                                "recommendation": "<p>Stress cracking, see structural notes</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d1d2fb5e-e84b-4395-b585-81b2034550d9",
                                "recommendation": "<p>Evidence of possible truss uplift-repair as required, monitor for further movement</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0e3660a3-8bb0-432e-ac77-6046dd5ab812",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "07659f89-cd31-46bd-9f60-241f7b8c5c8f",
                                "recommendation": "<p>Mold is present and is a potential health concern. Removal and/or correction is required.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f75f9ac9-f0b5-4d9c-a9d9-0a3ad78f8820",
                                "recommendation": "<p>Evidence of past or present water staining</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "95b5accc-4902-4d88-bd2b-9da35338f173",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Paint",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Exposed framing",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Mirrors",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Texture paint",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Suspended ceiling",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "bc1f63fe-2111-4dea-883c-a0078f4a022c"
                            }
                        ]
                    },
                    {
                        "order": 3,
                        prompt: "Walls:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fce4a5ef-f341-4324-a7ed-c0fc5300f7b2",
                                "recommendation": "<p>Cracks present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2fff28d8-79ce-40e3-a9ad-346da990b177",
                                "recommendation": "<p>Evidence of past or present water leakage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d97d919f-88cc-4702-b676-67dbe6f0f17f",
                                "recommendation": "<p>Evidence of possible truss uplift</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "dd822515-d02e-4b6f-a9e1-55ff4105bad8",
                                "recommendation": "<p>Loose or peeling paint</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1c6cef8f-b0fa-43a6-b822-fbc5c003e10b",
                                "recommendation": "<p>Loose paneling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "eb6ad642-94ba-43a2-b237-0a002f9782ac",
                                "recommendation": "<p>Water stains present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bf84a157-5d9e-4c10-ab3c-c57b9abd8d6e",
                                "recommendation": "<p>Evidence of past or present water staining</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "975ac50b-861a-4680-9b88-f709918ff250",
                                "recommendation": "<p>Loose or missing tile</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1f15d8aa-7e90-402c-9c43-4c2665af556f",
                                "recommendation": "<p>Mold present-potential health concern-needs removal/correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ba476575-074f-468b-a7c0-7547bc33afde",
                                "recommendation": "<p>Paneling loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d79fecc4-bcf6-4c77-a158-d231a4826843",
                                "recommendation": "<p>Some ceiling pads damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "47628b4c-d7ab-4e27-9b2c-0f03b42bfb22",
                                "recommendation": "<p>Some evidence of previous truss uplift</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "57cbb373-3ecf-486c-a94a-4fadfc50480f",
                                "recommendation": "<p>Inspection does not cover any damage concealed by rugs, carpeting, wall paneling, furniture</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2990425e-11d1-4d48-810d-cd373d4f7aeb",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "b75493ec-9816-45d7-b344-f42ab83a8edb",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Paint and paneling",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Exposed framing",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Paint and tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wallpaper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Paint",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Concrete",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wallpaper and tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fire rated",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "79323918-8cc6-444f-9b1c-0653c58f9987"
                            }
                        ]
                    },
                    {
                        "order": 4,
                        prompt: "Floor:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f088943c-513d-4b55-8864-80ca698db55e",
                                "recommendation": "<p>Broken floorboard</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1a8ded25-a93a-4384-8b7e-7c7acb514c94",
                                "recommendation": "<p>Damaged carpet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b8467fd4-7f28-4939-9a1d-2978c3919eba",
                                "recommendation": "<p>Loose carpet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "50b213cc-1fe6-41e7-a5fe-5ce7f24f029d",
                                "recommendation": "<p>Loose flooring</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4a5411b5-5083-4d48-83bb-b9178877ae7c",
                                "recommendation": "<p>The floor squeaks when walked on</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3440d84c-a7b8-4483-9a6c-92473a435522",
                                "recommendation": "<p>This inspection does not cover any damage concealed by carpeting, rugs or furniture</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f7dcca16-8c2f-4a58-8dbf-338897449b7e",
                                "recommendation": "<p>Water damage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b75bcc06-d7a0-48db-96b7-963a9780d4d4",
                                "recommendation": "<p>Noted uneven floor due to settlement</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e3c39ca7-b5a3-4f14-80f1-a90da2e015ac",
                                "recommendation": "<p>Carpet Stained/soiled</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f94473f9-3897-4fdf-b74f-559a5cf15d0e",
                                "recommendation": "<p>Worn wood floor finish</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b6a0490e-e2a1-4664-b817-cb5ac3df2aa4",
                                "recommendation": "<p>Cuts or minor damage in flooring, repair/replace as required. Restretch carpets- trip hazard</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bffaaf51-7c3d-4f1c-8700-b087a65121af",
                                "recommendation": "<p>Floor shows evidence of previous water penetration/stains</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e2faacd3-1f6f-42e8-83d2-16a40d075e4f",
                                "recommendation": "<p>Worn carpets</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7634aab6-090b-4e6b-8c01-5baf4daffde4",
                                "recommendation": "<p>Termite damage noted in wood flooring, repairs required</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "34d495b6-2910-44ff-b15e-f79d2288149a",
                                "recommendation": "<p>Gapping in hardwood flooring noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "97d7dbab-0eb3-41b1-a86f-8a5c09c28ab5",
                                "recommendation": "<p>Floor squeaks noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7a34d07f-8d93-4d76-ae80-ed991c70b945",
                                "recommendation": "<p>Raised seams in vinyl flooring-repair</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f0dd2342-f77e-4e4a-90f5-bd9210e10b0c",
                                "recommendation": "<p>Uneven wood floor finish (difference under carpets/rugs) due to sunlight</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4070214d-9820-4857-9446-e959eaa91c77",
                                "recommendation": "<p>Loose tile/grout, repair as required</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "baceca3b-de81-462b-8c15-f6bcbbd7275a",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Hardwood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Ceramic tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Linoleum",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Carpet",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl floor covering",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Poured",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "fdec8a30-808e-4580-a396-a4f3257f2e74"
                            }
                        ]
                    },
                    {
                        "order": 5,
                        prompt: "Doors:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7bf91142-62a8-4d0f-a9e1-7f4c2d3d80d4",
                                "recommendation": "<p>Loose hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cb491d7c-1cd0-464d-b8bb-82c6f79a1d46",
                                "recommendation": "<p>Missing hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7faf6c8d-0eb6-4b77-8d9b-c75d0f286a5c",
                                "recommendation": "<p>The door has moisture damage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a4fc8bce-3879-43a6-bae1-0693dd902400",
                                "recommendation": "<p>The door is sticking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3f629d5c-1fc0-4e5b-b202-d4399fcdc4ad",
                                "recommendation": "<p>The surface laminate is peeling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2d24ebfe-06c7-4c39-85a6-823f22294642",
                                "recommendation": "<p>Wood rot</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "619610bf-86b1-4ad6-badf-c386a35b9ce0",
                                "recommendation": "<p>Lockset(s) not latching-requires adjustment</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b0eea154-e458-4825-8b2e-96e6b3f00d11",
                                "recommendation": "<p>Door(s) has minor damage from door stop-repair/replace</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f71e6326-f0de-4aca-a68c-4485cc4ce6bc",
                                "recommendation": "<p>Lockset(s) not functioning-repair</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5e854ff5-6917-4d69-adcb-4f454e0e8a7f",
                                "recommendation": "<p>Replace missing door(s)</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ae221577-c815-4df4-b599-99f0c17b1c91",
                                "recommendation": "<p>Door damaged by moisture</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e00eef89-a9c8-4633-9a33-ccece93cf1a8",
                                "recommendation": "<p>Doors need trimming/adjustment</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8ba47b93-2e7e-479c-8252-45ed50319668",
                                "recommendation": "<p>Replace missing bypass hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c3b608d6-ea45-4e0c-b444-a831edd419d5",
                                "recommendation": "<p>Lockset hardware installed opposite with locking knob to outside of room-needs correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b1c5e3ea-2fb4-405e-b0e6-fc1f8551a2de",
                                "recommendation": "<p>Align/adjust closet doors</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f50eaa36-887a-447a-8d96-b25bf2d6dffa",
                                "recommendation": "<p>Undercut where rubbing on carpet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "190e31aa-d337-4c02-bdf3-8684b791dede",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Hollow wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "French",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Bi-fold",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Solid wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Dutch",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "9eeb2a07-2cfe-4509-a513-966568f990d7"
                            }
                        ]
                    },
                    {
                        "order": 6,
                        prompt: "Windows:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "35aa1860-3960-4e34-8810-146db7e6b874",
                                "recommendation": "<p>Broken glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "06d9d005-2fd9-48c4-903c-484a785b404e",
                                "recommendation": "<p>Repair broken sash cords</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "408cfa5c-674e-487b-a82a-9f6b4f96214f",
                                "recommendation": "<p>Cracked glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ef2ee613-a301-49c2-b062-c4dcb3c93229",
                                "recommendation": "<p>Hard operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "95f601bc-1dd8-4394-b768-8affbda49004",
                                "recommendation": "<p>Moisture present inside the layers of glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5b8768ba-326d-4412-ac2e-c91100fd2e67",
                                "recommendation": "<p>The window will not open</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b1f5ceb4-b7bb-4267-ab8f-77c5dc6f0670",
                                "recommendation": "<p>Window has fogging between the layers of glass</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "be124e84-7f8f-44f0-b494-5f164b3d1087",
                                "recommendation": "<p>Window seal broken</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "92ae49ec-2fb1-4591-b17d-280b23268b97",
                                "recommendation": "<p>Window sticks</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4a79983f-fc27-44cf-a5ad-845743686132",
                                "recommendation": "<p>Replace missing lock hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "62b5c9df-eba6-41d5-bb26-dd2d452acdaa",
                                "recommendation": "<p>Exterior glazing repairs required</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "373a53f1-1333-46fb-8cde-f337a12b9ca3",
                                "recommendation": "<p>Painted shut</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f14217d4-5673-46c9-aa9c-d2e14318450b",
                                "recommendation": "<p>Window sticks-improve operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ddfdb215-34e4-4e00-aed5-a1415369714c",
                                "recommendation": "<p>Will not open-requires correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e07f2bfa-87ce-4938-ad8e-afa6d247b96f",
                                "recommendation": "<p>Repair faulty operating hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "99ff538f-6e74-464f-b327-1d2555938186",
                                "recommendation": "<p>Locks do not align/lock-need correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e33026e4-6771-4958-b78b-505b2768e6e8",
                                "recommendation": "<p>Replace missing casement crank hardware handles</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9fdd58f4-d162-4706-86f5-284f30bd0d4c",
                                "recommendation": "<p>A qualified glazier is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2b9cda34-a276-4889-9b0a-c4b76f1b3803",
                                "recommendation": "<p>Replace weather stripping</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1f3dcf82-1a07-4a10-b10e-bc3ba86c3b32",
                                "recommendation": "<p>Replace missing pull hardware</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7d6ae45e-0219-4592-b108-139c7089c519",
                                "recommendation": "<p>Hard operation-needs improvements for ease of operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8243933d-827f-4b86-b583-154871c6d0ea",
                                "recommendation": "<p>Repair/replace damaged colonial grids</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5254593a-50b5-4d92-9548-720a26e4f225",
                                "recommendation": "<p>Crack in marble sill noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "6de0db0b-b6b6-46f1-b9b2-1e8b6c15bb5f",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Aluminum slider",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Aluminum double hung",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Non-opening",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood double hung",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Aluminum casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl double hung",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vinyl slider",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steel casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steel frame",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood casement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood slider",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "ba99ced6-f14c-4b9d-a62d-7de2823b2436"
                            }
                        ]
                    },
                    {
                        "order": 7,
                        prompt: "Electrical:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b9653f5f-5427-4e61-8cde-7fed44618fbc",
                                "recommendation": "<p>Open or missing ground</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "219e107f-3216-4155-b121-f7fb9852bc78",
                                "recommendation": "<p>Suggest evaluation by licensed electrician</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a4cb43a9-63b6-4d50-82f4-95f009febc02",
                                "recommendation": "<p>Non-GFCI circuit</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b7469d6d-0f5b-48a9-a558-fcf6933f0eba",
                                "recommendation": "<p>Reversed polarity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6b61a3cf-ea23-42b3-87b1-f106c40988e0",
                                "recommendation": "<p>Install missing junction box cover plates</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "602c55ef-01bf-4649-893a-1b9fd2252b41",
                                "recommendation": "<p>Install missing junction boxes and cover plates at open splice connections</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "eb9a4f12-4a4c-499f-9d4d-b6d7e49ad564",
                                "recommendation": "<p>Protect exposed surface mounted wiring in conduit to 7'-0' above floor</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "25b59bee-4d49-43ec-9efc-0c96e210ba95",
                                "recommendation": "<p>Faulty GFCI outlet-replace</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "87a74718-d9df-4134-856a-dae3b1191666",
                                "recommendation": "<p>Reversed polarity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f3dd9dd0-bf8d-4026-8ca4-d61534eb737a",
                                "recommendation": "<p>Missing outlet/switch cover plates</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "63fd268d-d17c-4eb3-9750-4f6efd455c84",
                                "recommendation": "<p>Loose switch or outlet should be resecured</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e2f48a55-11e5-43dd-83fe-f57da5ff1909",
                                "recommendation": "<p>Ceiling fan inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c9e43722-c96a-4f6c-be80-8019a74be22f",
                                "recommendation": "<p>Worn outlets creating intermittent ground- replace outlets</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d352d49a-f87d-4419-989f-aca93a2447e4",
                                "recommendation": "<p>Light fixture not functional-repair/replace</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "869aa4fa-1757-4aa7-aa30-90980935c5a4",
                                "recommendation": "<p>Open grounds noted at 3 prong outlets</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1ab7c2eb-6405-4bb6-b47d-bc69e2105737",
                                "recommendation": "<p>Remove temporary wiring running through walls -amateur installation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "72176c26-88b2-48c5-9352-8df03913d335",
                                "recommendation": "<p>Outlets not working</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "83a06096-3722-41c2-864b-1f22c7497785",
                                "recommendation": "<p>Non-GFCI circuit -recommend GFCI circuit be installed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "56bf1366-226c-49fb-97a9-deb8254cb8e1",
                                "recommendation": "<p>Insufficient outlets for space</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "00130aa4-f69e-460a-9778-2f96684a5418",
                                "recommendation": "<p>A licensed electrician is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f00e896c-485e-4429-89df-6dfd5ca5b1a6",
                                "recommendation": "<p>Replace damaged outlet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5b60c156-aaea-4f13-80e6-781ca9640f3d",
                                "recommendation": "<p>110 VAC Outlets, Lighting Circuit and ceiling fan</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9b5839ac-2d57-4b5d-b600-fb3732045dda",
                                "recommendation": "<p>Install missing outlet/switch cover plate(s)</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "2f84805a-3ca2-42b2-974c-d679a79856be",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "110 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC GFCI",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC/220 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "220 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC outlets and lighting circuits",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "444eb78c-7929-4ec5-90fc-034b9f3f67f8"
                            }
                        ]
                    },
                    {
                        "order": 8,
                        prompt: "Smoke Detector:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "75535586-ae7a-48be-bddc-5b84df145c8d",
                                "recommendation": "<p>Evaluation by a fire alarm certified licensed electrician is recommended</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "77e3e07d-bb09-4017-8908-bfc760f80db9",
                                "recommendation": "<p>Not tested due to lack of electricity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b77e3da5-bc06-41a3-a65d-f6540af610cc",
                                "recommendation": "<p>Battery missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e5584247-b9eb-454a-a6f1-ba155a697d9f",
                                "recommendation": "<p>Improperly wired</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "19780b23-feef-4af6-9d67-4f7c3340c113",
                                "recommendation": "<p>Inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "baedb630-a3c4-4a0e-88be-b8e897411857",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Battery operated with light",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Battery operated",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hard wired with battery back up and light",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hard wired",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hard wired with battery back up",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "cd6de39a-d121-438a-9a01-eba258e7dcf9"
                            }
                        ]
                    },
                    {
                        "order": 9,
                        prompt: "HVAC Source:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6b5d256c-3386-44ad-8dbb-d00122f24d97",
                                "recommendation": "<p>Heating system inoperable</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9ca12067-e2e7-4a2e-9fa3-0153cc6d9e2d",
                                "recommendation": "<p>Low air flow</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e44ed7a2-44cd-4f9c-8e11-513d60efe8ff",
                                "recommendation": "<p>No heat duct</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6efb245c-e2b9-41da-826c-92912e9581b4",
                                "recommendation": "<p>Register cover damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "de54fcaa-c629-4114-af57-a45e62355cf9",
                                "recommendation": "<p>Register cover missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "b0f0fba8-852a-4694-9355-5e07bbbee59e",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Heating system register",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Convection baseboard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Air exchange ventilation",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Radiant heat",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "33159668-37f5-42a4-a74b-8ba954adb13c"
                            }
                        ]
                    },
                    {
                        "order": 10,
                        prompt: "Laundry Tub:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "37b218c9-3cc2-4781-9f18-7f517850219c",
                                "recommendation": "<p>Not mounted to floor or wall</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d1fb2548-6b54-4129-b934-55b7e74efb84",
                                "recommendation": "<p>Faucet or plumbing loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ee43e488-21ec-4c09-8e44-baedf35bda60",
                                "recommendation": "<p>Improper drain system</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d9e071a9-4cd3-4463-95c3-875962a1c678",
                                "recommendation": "<p>Drain pump inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "01f15899-7322-4970-9926-5071195d0877",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "f973c5da-5a56-442a-bc79-5c142d49512d",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Concrete",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "PVC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Metal",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "e9979e23-f0fd-46fb-90c7-6b59cfb96719"
                            }
                        ]
                    },
                    {
                        "order": 11,
                        prompt: "Laundry Tub Drain:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c0025d18-e5f4-4359-a6d7-56c1482d23dd",
                                "recommendation": "<p>An 'S' trap has been used.  S traps should be replaced during any new plumbing work as they are subject to siphoning problems.  Replacement is sometimes difficult and thus the S traps are usually tolerated.  Care should be taken to keep the trap 'primed.'  Fixtures should be monitored for sewer odor.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d795056f-3802-4765-bcea-0b695b3504b4",
                                "recommendation": "<p>The plumbing drain line is not trapped.  A trap should be installed to prevent sewer gas from entering the house</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "90bca674-4fbb-4469-aad4-62bd321694f2",
                                "recommendation": "<p>The sink drain trap is corroded.  This area should be monitored for leakage and repairs made as needed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2275e49c-2ba4-4e22-88c0-7d11ab2f5639",
                                "recommendation": "<p>The sink drain trap is disconnected.  Repair should be made</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "57e7e70a-91b6-42b4-a76e-a39a8cf37fe7",
                                "recommendation": "<p>The trap is leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "7312f549-e308-4f91-a0e6-ec33aefce5c8",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Electric pump",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "PVC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Galvanized",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "36ec241a-df64-4f40-b647-bcecfc3d0a86"
                            }
                        ]
                    },
                    {
                        "order": 12,
                        prompt: "Washer Hose Bib:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5769a1ac-1ff7-4bee-a768-b9dfa975caa1",
                                "recommendation": "<p>Faucet leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "034037c4-337a-4d7d-8436-6435fc38dc48",
                                "recommendation": "<p>Missing handle</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "44793314-dc31-4bbd-9d55-b7be0388b9f5",
                                "recommendation": "<p>Inoperative at time of inspection</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0de90799-cf27-4f37-9f93-a30042606674",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "31773232-03a9-4494-b308-b2655cb454ee",
                                "recommendation": "<p>Signs of previous leakage-monitor</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3878bf98-d56b-4dab-ab19-2c7190c3a4c6",
                                "recommendation": "<p>Properly secure fixture(s) to exterior wall with fasteners</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d49cae7d-86c6-4e0e-a61d-6255ede915b3",
                                "recommendation": "<p>Leaking at stem</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "642ccd1a-9a93-4793-9d00-061ddc449ffd",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Gate valves",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Multi-port",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Ball valves",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rotary",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not visible",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not present",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "e7f0ba45-615b-4598-b2db-6cf526a3be6b"
                            }
                        ]
                    },
                    {
                        "order": 13,
                        prompt: "Washer and Dryer Electrical:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d2660624-14e3-414f-bc7a-a4a2396016c7",
                                "recommendation": "<p>Open ground circuit</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "70faff45-3ce4-484f-8f36-5116f4ded72b",
                                "recommendation": "<p>Reversed polarity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b05e3570-481f-40ab-9b75-829226f7aefa",
                                "recommendation": "<p>Wires terminate outside protective box</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "069d78c8-a9ee-4972-85a3-8f16269d6480",
                                "recommendation": "<p>Improper routing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f1a5b6ca-aa73-45c4-bc4b-bf861bd32a1f",
                                "recommendation": "<p>Covers missing from boxes</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d6be8999-635b-48ca-b0c2-2bf3cdff56ba",
                                "recommendation": "<p>Pigtails added for aluminum wiring</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a4a35fb8-bf03-4c94-be22-e9411ef680f1",
                                "recommendation": "<p>Evaluation by a licensed electrician is recommended</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "c8c2f48c-a1d1-4c98-aa80-62c9849c68da",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "110 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "220 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "220-240 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110-120 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110-240 VAC",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "66b0bb13-0c96-4afe-b419-8b09700ad98f"
                            }
                        ]
                    },
                    {
                        "order": 14,
                        prompt: "Dryer Vent:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9462b9f0-2fdf-4675-8a09-4e830a31984c",
                                "recommendation": "<p>Missing small animal cover</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "abf7787f-a82a-4f39-b3d8-94df6461d37d",
                                "recommendation": "<p>Loose connections</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a03f2473-25ec-4b84-b255-2f8e2af943f8",
                                "recommendation": "<p>Needs cleaning</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "62a8b636-8dab-4c2a-9a3e-e65f12eeb26d",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Metal flex",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Plastic flex",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rigid metal",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "1839cabe-c58b-4f77-9c1c-22cad5a3abe4"
                            }
                        ]
                    },
                    {
                        "order": 15,
                        prompt: "Dryer Gas Line:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2a204abf-1d80-41d8-ab71-1f8ac5a8eb7f",
                                "recommendation": "<p>Leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a73fee6d-e533-4e57-88f3-f8b9a2e08199",
                                "recommendation": "<p>Crimped</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "8adac93a-2a6d-4012-a74a-7e0688c7404f",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Cast iron",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Insulflex",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "f024516f-2b13-4ed8-a1d2-cc8a72962fa4"
                            }
                        ]
                    },
                    {
                        "order": 16,
                        prompt: "Washer Drain:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8fe0457d-5b9a-48f4-9c2b-496d1bcea43d",
                                "recommendation": "<p>Slow drain</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0f57cc88-1ccd-4f1a-a6cf-93e37d74ffe8",
                                "recommendation": "<p>Sewer odor noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2ae78b27-9a11-4ff1-b0cc-b6d5b56ff5d6",
                                "recommendation": "<p>Galvanized drain pipe present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8bf47d94-92ce-41f5-b5ad-94c9364585d7",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "71d3318e-cbdb-471f-9383-6bf424f086a4",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Floor drain",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Drain pan to main drain system",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wall mounted drain",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Drains to laundry tub",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "89790924-cbb5-4b1d-95cc-853efe2a24b7"
                            }
                        ]
                    },
                    {
                        "order": 17,
                        prompt: "Floor Drain:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9836c031-6648-41a5-ab5f-ca2b28f0bdc8",
                                "recommendation": "<p>Blocked by debris</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "855c3371-669a-46cb-9432-5fde5ddf381e",
                                "recommendation": "<p>Evidence of poor drainage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8a5574fb-65f5-437e-a550-a70eafe466b5",
                                "recommendation": "<p>Water pooling with stains visible</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6b7381f2-3891-42e0-8cbf-48231eafeab3",
                                "recommendation": "<p>Undersized for surface area</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7b0237fa-d534-4816-ab72-a93c35c1a7b3",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "e3fdb30a-a08d-434d-8d2a-2936ae0e424e",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Covered",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "French drain",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Surface drain",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not visible",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "8de5a405-5a80-4af3-be57-a9fb5961548f"
                            }
                        ]
                    }
                ],
                "order": 1,
                "is_deleted": 0,
                "created_by": 686,
                "updated_by": 686,
                "created_at": "2022-11-08T18:05:40.000000Z",
                "updated_at": "2022-11-08T18:05:40.000000Z"
            }
        ]
    },
    {
        "inspection_id": 97,
        "template_id": 130,
        uuid: "385afb74-cd38-4081-8a05-8de8c100f9eb",
        name: "4pt Insurance",
        "rating_data": [
            {
                Id: 1,
                name: "Acceptable",
                Color: "#000000",
                "Definition": "Functional with no obvious signs of defect.",
                "is_default": 0,
                "include_in_summary": 0
            },
            {
                Id: 2,
                name: "Not Present",
                Color: "#000000",
                "Definition": "Item not present or not found.",
                "is_default": 0,
                "include_in_summary": 0
            },
            {
                Id: 3,
                name: "Not Inspected",
                Color: "#000000",
                "Definition": "Item was unable to be inspected for safety reasons or due to lack of power, inaccessible, or disconnected at time of inspection.",
                "is_default": 0,
                "include_in_summary": 0
            },
            {
                Id: 4,
                name: "Marginal",
                Color: "#000000",
                "Definition": "Item is not fully functional and requires repair or servicing.",
                "is_default": 0,
                "include_in_summary": 1
            },
            {
                Id: 5,
                name: "Defective",
                Color: "#000000",
                "Definition": "Item needs immediate repair or replacement. It is unable to perform its intended function.",
                "is_default": 0,
                "include_in_summary": 1
            }
        ],
        "location_data": [
            {
                "col": "1",
                "order": "1",
                "value": "North"
            },
            {
                "col": "2",
                "order": "1",
                "value": "Kitchen"
            },
            {
                "col": "3",
                "order": "1",
                "value": "1st Floor"
            },
            {
                "col": "1",
                "order": "2",
                "value": "South"
            },
            {
                "col": "2",
                "order": "2",
                "value": "Bedroom"
            },
            {
                "col": "3",
                "order": "2",
                "value": "2nd Floor"
            },
            {
                "col": "1",
                "order": "3",
                "value": "East"
            },
            {
                "col": "2",
                "order": "3",
                "value": "Dinning room"
            },
            {
                "col": "3",
                "order": "3",
                "value": "3rd Floor"
            },
            {
                "col": "1",
                "order": "4",
                "value": "West"
            },
            {
                "col": "2",
                "order": "4",
                "value": "Garage"
            },
            {
                "col": "3",
                "order": "4",
                "value": "Living room"
            },
            {
                "col": "1",
                "order": "5",
                "value": "Northwest"
            },
            {
                "col": "2",
                "order": "5",
                "value": "Closet"
            },
            {
                "col": "3",
                "order": "5",
                "value": "Bathroom"
            },
            {
                "col": "1",
                "order": "6",
                "value": "Northeast"
            },
            {
                "col": "2",
                "order": "6",
                "value": "Master"
            },
            {
                "col": "3",
                "order": "6",
                "value": "Attic"
            },
            {
                "col": "1",
                "order": "7",
                "value": "Southwest"
            },
            {
                "col": "2",
                "order": "7",
                "value": "Basement"
            },
            {
                "col": "3",
                "order": "7",
                "value": "Utility room"
            },
            {
                "col": "1",
                "order": "8",
                "value": "Southeast"
            },
            {
                "col": "2",
                "order": "8",
                "value": "Crawlspace"
            },
            {
                "col": "3",
                "order": "8",
                "value": "Laundry"
            }
        ],
        categories: [
            {
                uuid: "a6127564-a676-447f-9368-79f53a297bbb",
                prompt: "Plumbing",
                "introductory_text": null,
                lines: [
                    {
                        "order": 1,
                        prompt: "Service Line:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0577f915-0e5f-43e4-bc91-9fa958846b97",
                                "recommendation": "<p>Crushed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e44cc047-8831-4c84-b4c5-5d8a1434c354",
                                "recommendation": "<p>Leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7aa4b6ad-2d80-483b-bbd8-db3a2f369d87",
                                "recommendation": "<p>Polybutelene line present as main service line. No leakage visible at the time of inspection.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7639d800-7281-458e-a611-b6e250bbf508",
                                "recommendation": "<p>Polybutelene lines are susceptible to leakage and have been involved in litigation. Inspection and possible replacement is recommended.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e036101e-34a2-4e93-8bf6-58d2dfcc11aa",
                                "recommendation": "<p>Lead pipe present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "33ce933a-b5ec-4e9c-af69-98483b417512",
                                "recommendation": "<p>Galvanized supply piping corrodes form inside causing decreased flow rates and will eventually require updating</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "065ad66d-b2b5-4143-b659-e73274f03c7f",
                                "recommendation": "<p>Repair/replace faulty pressure regulator</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e9e04ce3-0f8f-450d-8d82-ef75e0188390",
                                "recommendation": "<p>Excessive pressure noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "fe60ca1c-d97b-475e-a897-00fad56c4f77",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "ABS",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Cast iron",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "PVC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Lead",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Galvanized",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "PVC and copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Polybutelene",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "0fdaf855-2fc1-4851-bf1f-6ca111862240"
                            }
                        ]
                    },
                    {
                        "order": 2,
                        prompt: "Main Water Shutoff:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ee61ada9-fa74-46ae-a3b1-c3d9ea9849d5",
                                "recommendation": "<p>Water off at time of inspection</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ce3647d5-94a2-46ab-918c-09f3d4136cae",
                                "recommendation": "<p>Water system winterized</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "94fb070c-3e1b-470e-b8bf-67420d73d6e9",
                                "recommendation": "<p>Active leaks</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d560d809-20f6-40f4-aeee-bb86eba48821",
                                "recommendation": "<p>Main service line is lead</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8c53a4d8-27c7-4d95-802e-54516b64d0ab",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b3483c0a-df35-486e-a960-7b4f847173be",
                                "recommendation": "<p>Make shut-off more accessible</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "52aad481-0b3b-496d-8da4-57bcff480684",
                                "recommendation": "<p>Pressure regulator and back flow preventer (check valve) installed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "558606ac-f38b-4d7f-bd07-d52a66946393",
                                "recommendation": "<p>Pressure regulator installed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "91a2388f-8320-4989-ab1c-d7b111da0c68",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Front of house",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Basement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Recessed in wall",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not accessible",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Garage",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Utility closet",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Closet",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "907d09e8-2bb9-442c-9f01-50cc9b5e096d"
                            }
                        ]
                    },
                    {
                        "order": 3,
                        prompt: "Water Lines:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "eac23ed8-fc61-4141-a598-e4058d445ca7",
                                "recommendation": "<p>Evidence of previous leakage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ad1fdf7a-725a-4c75-ad3e-9a263ac106e4",
                                "recommendation": "<p>Leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2ce8c3ad-a4f7-49f2-9ef6-8b00e5d66e87",
                                "recommendation": "<p>Not properly supported in hangers</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8a9971a0-0e72-4297-830a-2d2813e493d7",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9b9d6640-a722-4f91-af16-1d82fea45c75",
                                "recommendation": "<p>Galvanized supply piping corrodes from inside causing decreased flow-rates and will eventually require updating</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "76101f70-d07c-45e8-9620-be27d288aa2b",
                                "recommendation": "<p>Leaking supply pipe at fitting</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1c36aa77-3866-4827-96aa-763d0f2844e7",
                                "recommendation": "<p>Evidence of previous leakage-monitor for further leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c144ecb1-6c32-4cfa-b099-e052a4019bea",
                                "recommendation": "<p>Leaking valve in supply piping</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "60679d0f-a237-4995-ab38-a97b64eebe26",
                                "recommendation": "<p>Leaking saddle valve in supply pipe</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b46c2f3b-8352-4184-b60b-8ae3bd7fddee",
                                "recommendation": "<p>Leaking supply at pressure regulator</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0732ac1d-9645-4871-ad3a-5d746c13f875",
                                "recommendation": "<p>Leaking water meter</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cf152fc8-28ee-4b12-a279-ad74a0775f4d",
                                "recommendation": "<p>Noted several areas along water supply lines where pitting/leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "e45b6406-a615-4a3b-b42b-d10fd8da6fbb",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "ABS",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Cast iron",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "PVC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Lead",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Galvanized",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "PVC and copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Galvanized and copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Possible Polybutelene",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Polybutelene",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "1a0a3958-c50d-4b3c-9d8c-ad89494f298a"
                            }
                        ]
                    },
                    {
                        "order": 4,
                        prompt: "Vent Pipes:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "235ae2bc-3167-4adc-b6af-a9a933991360",
                                "recommendation": "<p>Improper or blocked vent</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c5d16440-4120-412d-9333-21a4a9f2d676",
                                "recommendation": "<p>System not vented</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f7a7e174-0239-42c7-b62f-850b8046c9bb",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "5ae6799f-3590-4d13-9c02-4799861752bc",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "PVC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "ABS",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Cast iron",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Galvanized",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Lead",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "PVC and copper",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "c305853a-7722-4d0d-a5c7-74fabf5f3da9"
                            }
                        ]
                    },
                    {
                        "order": 5,
                        prompt: "Basement Floor Drain:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6bb1becc-081a-478b-9fe7-8bdfacb3d28e",
                                "recommendation": "<p>Blocked by debris</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ad97567e-a4be-4c4f-b4fb-d9fb60a5792c",
                                "recommendation": "<p>Evidence of poor drainage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "72a2e3f7-26e9-4f1b-9e80-4f7d4eb85e83",
                                "recommendation": "<p>Water pooling with stains visible</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "93dfa2dd-53af-4a8b-b534-b66b1b02b98e",
                                "recommendation": "<p>Undersized for surface area</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "52eafe79-8c90-4151-9098-e5582ed9d248",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "e6defd84-1f41-4cca-8d49-081e84a46fa4",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Covered",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "French drain",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Surface drain",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not visible",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "443430d9-b090-49d4-9412-c9cf19302d16"
                            }
                        ]
                    },
                    {
                        "order": 6,
                        prompt: "Drain Pipes:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f1348c61-df33-4deb-88df-c622d3db9014",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a67a5ab2-9bfc-4e4a-8404-12d47f77c966",
                                "recommendation": "<p>Galvanized drainpipe present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "023d0b38-c49a-4a40-88f9-0af6a844fa24",
                                "recommendation": "<p>Sewer odor noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5394e2b1-0f72-4d9b-a1ca-2c694e71a718",
                                "recommendation": "<p>Slow drain</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c8f744cd-d327-4999-b9b6-fd528d74d05d",
                                "recommendation": "<p>Leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "25a9a41e-b587-4f8c-adea-0ed3f168a069",
                                "recommendation": "<p>Pitting cast iron drain piping</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "d2f213da-e461-4b7e-a387-5577df22ebef",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "PVC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "ABS",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Cast iron",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Galvanized",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Lead",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "263eb65e-7921-41ea-9b1c-9dbcb6f39a5c"
                            }
                        ]
                    },
                    {
                        "order": 7,
                        prompt: "Service Caps:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3c72f195-1d38-4249-ba1e-cf3f7b3f2601",
                                "recommendation": "<p>Clean out cap missing.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "111ec319-f544-49c1-9814-074a6992e858",
                                "recommendation": "<p>Clean out cap not accessible</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f2b2be1f-ccc9-4e21-b0ce-e0e30d5e231f",
                                "recommendation": "<p>Cap obstructed and can not be removed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "224b9920-cfe9-47c1-9cf4-8129b029bd0b",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Accessible",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not visible",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "f48574ee-7480-4af4-a697-19ac6376865a"
                            }
                        ]
                    },
                    {
                        "order": 8,
                        prompt: "Kitchen Sink:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "58958c2f-49d7-42a9-89fa-4ece7f7041f6",
                                "recommendation": "<p>Leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b3a2d105-a511-4a19-a70a-c5b0ecabeb90",
                                "recommendation": "<p>Pitting stem</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e4b7ee51-42e3-4fb3-8be8-d281b13366aa",
                                "recommendation": "<p>Loose fixture</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5adde1da-4348-4c07-b46d-322b1ef405cf",
                                "recommendation": "<p>Missing parts</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "17f513d0-cc7d-4568-98cf-3cb010441f44",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Stainless Steel",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Corian",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Swanstone",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Porcelain Coated",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Porcelain",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "15d8078d-1722-4642-95e0-e1792a823707"
                            }
                        ]
                    },
                    {
                        "order": 9,
                        prompt: "Bathroom Sink/Basin:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "19539c45-e066-4648-8849-25ba3a81334e",
                                "recommendation": "<p>Cracks present in the sink bowl</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d64d4ac1-7c64-4269-b10c-21b901198bac",
                                "recommendation": "<p>Drain trap leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "24ac1e9a-68ca-4d63-9069-b17d2e1eced4",
                                "recommendation": "<p>Leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "84ad44b7-3e9f-4791-8b42-40d4969d1454",
                                "recommendation": "<p>Rust present in the sink bowl</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "245bd166-0024-4756-a861-9603f969c55e",
                                "recommendation": "<p>Stopper missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "83496ea1-10f0-407c-9c98-58b120a374ab",
                                "recommendation": "<p>The sink is loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "33c00f9b-3cce-4b5a-b891-47af7aa05e7c",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Molded dual bowl",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Porcelain coated",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Molded single bowl",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Pedestal",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "One piece sink/counter top",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Porcelain",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Stainless Steel",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "One piece sink/counter tops",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Corian",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Granite",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Granite/Porcelain",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Synthetic",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "c84bfbdf-5ddd-4d9b-8dee-d11f089868db"
                            }
                        ]
                    },
                    {
                        "order": 10,
                        prompt: "Faucets/Traps:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "638e408e-ec67-451a-b9cc-c343ca76fcbb",
                                "recommendation": "<p>A licensed plumber recommended to estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "33f8995c-04ac-460b-b5bc-3fc3fc1dc883",
                                "recommendation": "<p>Hot and cold operation reversed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2bc902ac-0c0e-44c4-b850-b31f7ad245d3",
                                "recommendation": "<p>Leaking faucet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d20bebaf-c375-4743-bdf8-fac2dffe6259",
                                "recommendation": "<p>Leaking trap</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "19c2f659-57d4-4129-8b69-0c1ce3d05dd4",
                                "recommendation": "<p>Slow draining noted-further review/repair</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fcd7b833-d861-4adb-88fe-d56bddd06762",
                                "recommendation": "<p>Leaking drain</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "44202940-1af8-423d-b690-8dd7ae2ae0cc",
                                "recommendation": "<p>Leaking supply line</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3b3f4501-d78a-4be1-8b37-7c1821359399",
                                "recommendation": "<p>Faulty fill valve at toilet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ffceddff-f935-44a4-9591-09c047220c25",
                                "recommendation": "<p>Shower diverter not functioning properly, all water is not flowing to showerhead</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "22ebe7e5-ddbf-4d6f-8b3c-c2f5a5757a7c",
                                "recommendation": "<p>Damage to drain trap-may cause leak</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "375dfec1-0986-440d-84ff-c8c8be672063",
                                "recommendation": "<p>Clogged or non-functioning vegetable sprayer</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8cbce398-20bc-40bb-833d-712ac721861e",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "692c8e3c-d996-42d7-aacf-9a85149f1336",
                                "recommendation": "<p>Unable to test due to water being shut off to house. Recommend having water service turned on and have qualified plumbing contractor certify function</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3690865c-3cfb-45de-abf6-c50c978e4975",
                                "recommendation": "<p>Build up found in aerator of sink fixture possibly caused by deteriorated water heater dip tube, further review is required</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "12da17c0-d593-4385-9ad6-85f612664cf7",
                                "recommendation": "<p>Pitting at drain line/trap-recommend replace</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "07785a8b-2e88-4323-bc5f-318c891c6a9b",
                                "recommendation": "<p>'S' Type drain traps do not meet today's standards</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "202cc6db-9b93-4ffd-a494-023686438e90",
                                "recommendation": "<p>Leaking tub faucet at stem</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "aa1b78a8-ce26-4f56-961c-9a3e6030e694",
                                "recommendation": "<p>Missing or faulty drain stopper</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "234792f3-b642-4ba4-a0b8-09424f00ec78",
                                "recommendation": "<p>Resecure loose tub overflow plate</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "37d0c095-689b-43db-aaf7-baf397bbd19e",
                                "recommendation": "<p>Leaking sink faucet at stem-repair</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ebd15059-df2c-486f-8edf-e60921054119",
                                "recommendation": "<p>Low pressure or flow rate at faucet, further review and/or repair is indicated</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0c8dedee-5f65-4f04-a3e9-92bad4e9ab48",
                                "recommendation": "<p>Worn finish</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cf1c7685-782c-4462-8bc2-e5948aefa18b",
                                "recommendation": "<p>Leaking shower head</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "a0fa4619-5b7c-4e9f-a068-7929c5239740",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Delta fixtures with a metal trap",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Delta fixtures with a PVC trap",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Moen fixtures with a metal trap",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Moen fixtures with a PVC trap",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not installed to date",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not visible",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Winterized",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "10575a8c-e7ff-42da-9fb8-8288b1c093cb"
                            }
                        ]
                    },
                    {
                        "order": 11,
                        prompt: "Plumbing/Fixtures:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9f5b6ab6-236a-4c7b-ab04-69fe23f5c8b5",
                                "recommendation": "<p>Master</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "17cd4f1a-f854-4ab9-bbd3-6754942fe200",
                                "recommendation": "<p>1st floor main</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "839f49e6-e743-43d9-bb09-6a509e868494",
                                "recommendation": "<p>2nd floor main</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4c5a9fee-93ff-44e5-99d3-a7e2199361ca",
                                "recommendation": "<p>Basement</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d48f23cd-74b3-4d94-883f-dbb5c047c2dc",
                                "recommendation": "<p>Basement half bath</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "17513a0d-1eaf-44ab-aa22-a4172f268209",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "PVC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Chrome",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "CPVC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Stainless Steel",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Lead",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "4cb4f69c-7c91-411e-9a17-bc8ac971fc4b"
                            }
                        ]
                    },
                    {
                        "order": 12,
                        prompt: "Sump Pump:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a8d4ff71-1f02-41b6-af81-600bed9bf70d",
                                "recommendation": "<p>Improper wiring</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ab0b2c3b-8d47-4357-8673-d1d7a261f435",
                                "recommendation": "<p>Sump pump not connected to dedicated circuit</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "60b81046-e0f0-42ba-9c98-4f37f6d19dba",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "63df63b6-b9a6-4ad8-8378-e7c1c7465a72",
                                "recommendation": "<p>Disconnected from electric source</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b2da1fdb-a882-47b0-b6bb-f9bfd35464f0",
                                "recommendation": "<p>Drain line not properly connected or not draining away from the foundation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "26170fe3-ee86-434e-9d66-9331a7cf9bce",
                                "recommendation": "<p>Inoperative at time of inspection</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b9b30c79-b69d-4c5e-b9f8-602cde8e224c",
                                "recommendation": "<p>Extension cords present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cc52ef5d-6510-44ec-8da4-d561023b2eae",
                                "recommendation": "<p>Not tested due to sealed radon installation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a1b30601-d08c-4995-9cb4-a76ce4fe4cb4",
                                "recommendation": "<p>Recommend installation of water-powered or battery powered back-up sump pump to protect basement finishes</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e6d37ec0-4957-4c53-8c81-21864f9cda54",
                                "recommendation": "<p>Battery powered back up sump pump to protect basement finishes is installed but not tested. Testing is not within the scope of this inspection.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b4d24ae3-2203-480e-ab5e-6f3897c53fca",
                                "recommendation": "<p>Razor cut back corrugated drain tile piping so as not to dump onto sump pump float. (may not allow pump float to function and cause backup of storm water)</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7929d82f-ab83-4853-8036-7cf599c3fe18",
                                "recommendation": "<p>Leaking at discharge piping (inside basement), repair leak</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cc11a8ad-8a48-4989-ab6f-083505b2c61c",
                                "recommendation": "<p>Clean out debris from crock-may restrict operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c3e116b5-c196-4176-85df-afd18248182b",
                                "recommendation": "<p>Properly position sump pump so as not to be obstructed and unable to operate</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d3c88159-fac3-4f10-9def-ab465af83d95",
                                "recommendation": "<p>Sump crock was completely dry/empty-unable to test pump operation/piping for leaks</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "015a6a0c-9fae-4aaa-9031-13086604a328",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Pedestal",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Submerged",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "8c14e261-4705-4976-8fb0-cea942a5adc7"
                            }
                        ]
                    },
                    {
                        "order": 13,
                        prompt: "Tub/Surround:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7d09147b-93ae-4ade-aa50-a3546ca2c397",
                                "recommendation": "<p>Chipped or rusting tub</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7af46f59-802b-44b1-81f7-b471a9df5d83",
                                "recommendation": "<p>Loose grout</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d9cb125c-85c6-4f57-bb38-f591b6b1548c",
                                "recommendation": "<p>Loose or damaged tile</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0c14daf9-4d31-4c1f-86d0-87d04b2a0751",
                                "recommendation": "<p>Low water pressure</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1242e855-bbb8-49c5-a46d-620c8ec9fa30",
                                "recommendation": "<p>Obvious water damage due to water penetration</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a5b1d125-c45f-4a11-b7c2-9c57f36a6d55",
                                "recommendation": "<p>Replace caulking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "39658785-e867-4286-b9d1-25a0681cb4c3",
                                "recommendation": "<p>Undulations observed in surround wall, possible damage to wood members behind the wall may have occurred</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "857a1035-49e4-4a51-8570-9aa233e4dad8",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Porcelain tub and ceramic tile surround",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fiberglass tub and ceramic tile surround",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fiberglass tub and fiberglass surround",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Porcelain tub and fiberglass surround",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "62502bda-8418-429e-9276-43a4fb94fb38"
                            }
                        ]
                    },
                    {
                        "order": 14,
                        prompt: "Toilets:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "94499dc2-9eb6-4290-b5c7-2f739435a291",
                                "recommendation": "<p>The tank is loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d2c40142-082d-47f2-a711-510c970620dc",
                                "recommendation": "<p>The toilet is loose at the floor and will require replacement of the wax seal</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c3f0b111-b172-4781-ae86-ef97f9e82474",
                                "recommendation": "<p>Water continues to run after flushing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "93620eb8-28a2-4d09-8c09-ec43df935f08",
                                "recommendation": "<p>Water leaking at tank seal</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "78c59bea-e7e2-427b-a687-297f2c480152",
                                "recommendation": "<p>Water leaking at wax seal</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "33cf8bed-abed-45d3-9411-1a1e7cb7ab3f",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "American Standard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Moen",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Koehler",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "1 1/2 Gallon Tank",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "3 Gallon Tank",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "f1c38106-3c8d-4ad3-bf23-c3876ed7eee2"
                            }
                        ]
                    },
                    {
                        "order": 15,
                        prompt: "Spa Tub/Surround:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0956ccd6-36f9-47e3-8762-31959e6d0d90",
                                "recommendation": "<p>Jets clogged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ee4d1ba8-0752-4289-a730-c0a4865f2f8a",
                                "recommendation": "<p>Pump inoperable</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1cb81c8a-869c-49ba-9ce1-7a4e29fcdc6d",
                                "recommendation": "<p>Aerators inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "e72e0665-5bb9-43fe-adee-d3572dec482e",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Porcelain tub and ceramic tile surround",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fiberglass tub and ceramic tile surround",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fiberglass tub and fiberglass surround",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Porcelain tub and fiberglass surround",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "44f49ebf-0d04-463f-9535-4aaefd8e7e15"
                            }
                        ]
                    },
                    {
                        "order": 16,
                        prompt: "Shower/Surround:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d995e8df-146e-4cab-ab4c-ed6ff3afd9f5",
                                "recommendation": "<p>Cracked or leaking shower pan</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "142008db-a604-4ac0-947a-201ff3e04613",
                                "recommendation": "<p>Loose grout</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e3c5a471-7e5c-4c2a-8be6-b5c1f8a745e8",
                                "recommendation": "<p>Loose surround wall</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4a94c720-4193-4ae1-8f44-ae68cc7ce75e",
                                "recommendation": "<p>Loose tile</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "330b2954-d1e4-412b-a9bb-0d708ccf0039",
                                "recommendation": "<p>Mildew present above shower</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "483392dc-1689-4500-b47f-744c79cece69",
                                "recommendation": "<p>Replace old caulking at the shower pan</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "fb8cae7a-e4c9-46c9-80fe-407255656f24",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Fiberglass pan and ceramic tile surround",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fiberglass pan and fiberglass surround",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Plastic pan and metal surround",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Plastic pan and plastic surround",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Porcelain pan and ceramic tile surround",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Porcelain pan and fiberglass surround",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "8069bee3-e5a4-48fc-ae55-8cd5648a15fb"
                            }
                        ]
                    },
                    {
                        "order": 17,
                        prompt: "Water Heater Operation:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e377d1fb-f0d4-46b6-8c37-33f8d5c886d4",
                                "recommendation": "<p>Evidence of past or present water leakage in drain tube from pressure relief valve</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "effc4dbd-71f6-40d4-8a47-ba206fb3ae0b",
                                "recommendation": "<p>Improper flue routing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4aba7f91-7dab-470e-928e-89191a63121f",
                                "recommendation": "<p>Insufficient heating</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a60e043d-bcb7-45ac-bbc2-d7a52ca269dd",
                                "recommendation": "<p>Water heater has exceeded design life</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "88983ee0-6622-411e-a6ce-fbcb161a05e9",
                                "recommendation": "<p>Drain tube opening is not within six inches of the floor</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "537ce384-7f8d-4fe4-a422-fea00678ae7d",
                                "recommendation": "<p>Earthquake support straps missing or not properly installed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2ae1486e-c3ba-46a3-b405-8979b88e5c9e",
                                "recommendation": "<p>Flue pipe backdrafting</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "343be951-516e-40e1-9fb5-ded1436f9f5a",
                                "recommendation": "<p>Gas leakage detected</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "26b3fc9a-171b-4243-b69c-0ba1738f48cb",
                                "recommendation": "<p>Heating element inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fa75d2f3-5170-45a9-b129-04a633b38ac0",
                                "recommendation": "<p>Improper or poor gas lines</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9859a98f-0791-479a-8ccc-cec51290fc2a",
                                "recommendation": "<p>Leakage present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "388ae778-8a91-414e-a49a-b758a341a40f",
                                "recommendation": "<p>Water heater is nearing the end of it's design life</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bf792a37-b117-425b-b3b3-84c778c76ddc",
                                "recommendation": "<p>Back draft detected at the</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "48bcf991-146f-49bc-af03-83bbf37947a1",
                                "recommendation": "<p>Water heater was not installed properly</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "f57f7f6c-defd-4db1-9e9a-e1d9854cc69c",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Adequate",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Inadequate",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Functional at time of inspection",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "be8dcb1e-bd50-476f-8c30-890948f15e1c"
                            }
                        ]
                    },
                    {
                        "order": 18,
                        prompt: "Manufacturer:",
                        comments: [],
                        "line_uuid": "de26f478-9b3f-45f8-9158-b3b4446c0c97",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "A.O. Smith",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "State",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "American",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Standard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rheem",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Ruud",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Bradford-White",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "American Standard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Lochinvar",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "General Electric",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Kenmore",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Sears",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Enviro-Temp",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Craftmaster",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Mor-flo",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Richmond",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Reliance",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "d676c44b-cb6d-4bc1-8c05-0f9f4ab3e76e"
                            }
                        ]
                    },
                    {
                        "order": 19,
                        prompt: "Model Number:",
                        comments: [],
                        "line_uuid": "662f3350-18cb-4b81-9fee-145f8a2ec451",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [],
                                "line_input_control_uuid": "12fa3b9e-20ff-4bf2-9dd0-7227ccd100d4"
                            },
                            {
                                "type": "Additional input",
                                prompt: "Serial Number:",
                                "values": [],
                                "line_input_control_uuid": "9250dfa0-ad2c-49e1-b8b6-77e23bf726e8"
                            }
                        ]
                    },
                    {
                        "order": 20,
                        prompt: "Type:",
                        comments: [],
                        "line_uuid": "a8b653d3-f43f-493b-8245-9b67b68821c1",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Electric",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Natural gas",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Propane",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hybrid",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Instantaneous",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "fa7cc5a6-f29e-4d9d-94ec-e2c511171223"
                            },
                            {
                                "type": "Additional input",
                                prompt: "Capacity:",
                                "values": [
                                    {
                                        "value": "30 Gal.",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "40 Gal.",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "50 Gal.",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "75 Gal.",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "67e58051-bf00-4ae6-bae3-843a6a1fda1e"
                            }
                        ]
                    },
                    {
                        "order": 21,
                        prompt: "Approximate Age:",
                        comments: [],
                        "line_uuid": "7ceefff2-4191-4edd-aab4-9370a90afdc3",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "New",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "130b1f87-ed06-4b2f-90c6-db09f58ea7b9"
                            },
                            {
                                "type": "Additional input",
                                prompt: "Area Served:",
                                "values": [
                                    {
                                        "value": "Whole building",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "1st floor and basement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "2nd floor only",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "1st floor",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "8fe19e4d-9131-4709-a90f-c22f0a19f926"
                            }
                        ]
                    },
                    {
                        "order": 22,
                        prompt: "Flue Pipe:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0629bd9a-2493-4657-90ff-a93ea36ca7ea",
                                "recommendation": "<p>Negative slope</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e6d3bd4d-2198-47ed-8398-9231c882018b",
                                "recommendation": "<p>Evidence of backdrafting</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2f7d9ac2-3e3b-4cad-85b9-6a218fa65c35",
                                "recommendation": "<p>Rust holes</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "00c75812-77c6-4470-8518-ff784a1a508d",
                                "recommendation": "<p>Pipe does not meet minimum requirements for conditions</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fd591599-3187-4420-a33b-487054ed01f0",
                                "recommendation": "<p>Unit venting into unlined chimney causing condensation and rusting exhaust piping and exchanger</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7d2a7944-a6c3-4304-a406-e445815e02bb",
                                "recommendation": "<p>Pipe does not meet minimum pitch requirements</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f8f5481d-47f3-470a-b929-5aeefacceedd",
                                "recommendation": "<p>Install screws at exhaust vent piping fittings</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b6c3ff22-bbcc-4bab-8eea-db02f4ecea67",
                                "recommendation": "<p>Improper flue routing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b8c61183-037c-4e68-a428-a814ee55428c",
                                "recommendation": "<p>Seal vent penetration into wall/chimney</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e0d7cdd0-7da3-4636-8b5e-0f7f048d2ca0",
                                "recommendation": "<p>Rust holes in piping-replace rusted sections</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "a7891783-712f-40cc-821e-34662eb34913",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Single wall",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Double wall",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "PVC",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "a7e36a1e-505d-4fc5-b726-f761dc015fad"
                            }
                        ]
                    },
                    {
                        "order": 23,
                        prompt: "TPRV and Drain Tube:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a937d806-fb27-433d-bfc0-0f1ae5ade7e0",
                                "recommendation": "<p>Drain tube opening is not within six inches of the floor</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6f869849-3074-4d35-b260-f6fed8b06de8",
                                "recommendation": "<p>Improper installation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e1b7652c-9e6e-4946-a8c3-f5330bf80436",
                                "recommendation": "<p>Leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4a108365-032f-41e0-b256-9e64d77216b1",
                                "recommendation": "<p>Missing drain tube</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "8226b6e5-75d5-4a47-9546-e5419614845c",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "PVC",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "2a404092-3a04-4c73-9167-394c66f0ca3a"
                            }
                        ]
                    },
                    {
                        "order": 24,
                        prompt: "Hose Bibs:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "59346194-eb62-4d37-b888-45c487520ac4",
                                "recommendation": "<p>Signs of past or present leakage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4bd59eaa-a4eb-447d-a2de-a3f9074f8b0b",
                                "recommendation": "<p>Missing handle</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6b6b99d1-ab41-4ef8-bda7-1ae4db5abbaf",
                                "recommendation": "<p>Frozen or inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f96d0b87-0ab5-47ff-a6e6-9b41645d0059",
                                "recommendation": "<p>Note: Valves are not operated as part of this inspection, any reference to any valve inspected is visual only unless otherwise noted.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "54b58cd8-b383-4fb3-9d47-19ec0ac3e056",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "3b21fdf7-9ca7-42b9-b5e4-f2d7f7524760",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Gate",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rotary",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Ball",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Multi-port",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "428f9e07-1a07-4255-8bf4-cf2cca0ab532"
                            }
                        ]
                    },
                    {
                        "order": 25,
                        prompt: "Exterior Surface Drain:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1a65ad2c-3a80-43cc-a8e6-dc41132ece6e",
                                "recommendation": "<p>Blocked by debris</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bfec4743-fcb9-4c37-a40c-db39e97bc7ec",
                                "recommendation": "<p>Evidence of poor drainage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d57605c4-3a44-45de-8a94-d57ca571b414",
                                "recommendation": "<p>Water pooling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9cde33f2-9c23-48db-887e-f60705256f0c",
                                "recommendation": "<p>Undersized for surface area</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "5eb2a82d-03cf-429c-b188-c5cbee7f6c3d",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Surface drain",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Covered drain",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Open drain pipe",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Curtain drain",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Culvert drain",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "d31ecb68-6c61-4796-9180-c0c23cd72c67"
                            }
                        ]
                    },
                    {
                        "order": 26,
                        prompt: "Basement Stairwell Drain:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d6cc211e-0736-4e6c-860b-2e65fd9aca3c",
                                "recommendation": "<p>Blocked by debris</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "66d1b6f3-1317-4ac7-824e-216fd019fda6",
                                "recommendation": "<p>Evidence of poor drainage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9f08e0b1-5976-4975-8269-4167280e7979",
                                "recommendation": "<p>Water pooling</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e6666aaf-1a7d-4313-ae9d-217b8c2867b8",
                                "recommendation": "<p>Undersized for surface area</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0936582b-dae8-414c-ba69-44c19bf0dec6",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "8074f80a-0a8a-439a-8c81-2444cc9a3389",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "French drain",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "No drain",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Surface drain",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "68d518dc-a572-4520-a4eb-48841bb76ab6"
                            }
                        ]
                    },
                    {
                        "order": 27,
                        prompt: "Lawn Sprinklers:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "93932f5c-0009-4bde-8de5-1fd5cf9a433c",
                                "recommendation": "<p>Improper spray pattern</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c89349f9-e4e6-41ae-b4ee-6ab7a824f176",
                                "recommendation": "<p>Leakage noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "88f5fe78-ed61-4477-8a7e-3d4c71692377",
                                "recommendation": "<p>Not tested due to season</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0e51c146-4918-49e7-810f-f5e0379d1405",
                                "recommendation": "<p>Sprinkler head damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "db471865-10d1-416a-b224-85d8a1f48444",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Front and back yard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Front yard only",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Back yard only",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "ea5d1496-d636-4560-83cd-c3a22a99fb90"
                            }
                        ]
                    },
                    {
                        "order": 28,
                        prompt: "Gas Meter:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4d94cd56-3962-4123-a971-8793742c951b",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fce1f26c-4f93-43e3-a9e8-cf3094972b2a",
                                "recommendation": "<p>Mounting brackets loose or broken</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c1536e77-ec2e-4a80-83b5-8e0307790e70",
                                "recommendation": "<p>Not properly mounted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "29fe00ca-5252-4812-bfd6-8066eaa42441",
                                "recommendation": "<p>Severe rust present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "c6e5d893-d1b7-46e8-9710-6be35904cf9b",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Front of house",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Basement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Recessed in wall",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not accessible",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Garage",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Exterior surface mount at side of home",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "a1d55ffe-b6e7-4a1a-b3a8-c96407b9b007"
                            }
                        ]
                    },
                    {
                        "order": 29,
                        prompt: "Main Gas Valve:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "263df365-60fd-4dae-96fc-e20df1cdeeb2",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "166b519b-2325-4650-88d9-06ae6caaef4e",
                                "recommendation": "<p>Severe rust</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b7f26736-60d2-4923-b7e3-510908d771e3",
                                "recommendation": "<p>Leakage noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "79d5aaa6-5426-4af0-aaca-754fe93b6fe4",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Located at gas meter",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Located at main line",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not visible or not present",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "4cace9be-35f2-43f4-b40f-6d5190be6e9c"
                            }
                        ]
                    },
                    {
                        "order": 30,
                        prompt: "Gas Service Line:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "560cac13-1179-4cf3-805a-aa306808d10d",
                                "recommendation": "<p>Leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b8857cfd-402f-4a1d-882d-450d41470f78",
                                "recommendation": "<p>Line does meet acceptable standards</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "253ce821-4af7-4343-851b-744c88fb7327",
                                "recommendation": "<p>Lines not properly supported</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "98bf6439-fa5c-4789-9aca-88680b4ec083",
                                "recommendation": "<p>Propane regulator leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "dcdd3db6-2e2e-4d4d-95a5-4f8d4e24b882",
                                "recommendation": "<p>Regulator not properly attached</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9dd80624-1677-4836-93b7-008835c27902",
                                "recommendation": "<p>Unable to inspect underground lines to propane tank</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "5405e1b9-3f08-45ce-9756-b1a33fa2d1d5",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Cast iron",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Insulflex",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "c641738a-f742-4cd4-9873-2e4994f0bc5f"
                            }
                        ]
                    },
                    {
                        "order": 31,
                        prompt: "Dryer Gas Line:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0e888a8f-66c0-4504-b2b9-30aefab7fdf1",
                                "recommendation": "<p>Leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "64bc9ee0-e68a-4c99-a410-0a80454b4509",
                                "recommendation": "<p>Crimped</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "bd483dc3-9998-4975-95b8-218dc35f881c",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Cast iron",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Insulflex",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "e3b80817-b91d-43b4-90c3-de4f2a5f2c99"
                            }
                        ]
                    },
                    {
                        "order": 32,
                        prompt: "Laundry Tub:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "aa82b302-baa9-4d01-aa56-6cc25d7af2a5",
                                "recommendation": "<p>Not mounted to floor or wall</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e6602fdf-325b-43b3-8e7d-01a506203491",
                                "recommendation": "<p>Faucet or plumbing loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f0f0effd-1810-4429-841c-8252f2addff4",
                                "recommendation": "<p>Improper drain system</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "126a0438-b820-485c-b397-660999df47a4",
                                "recommendation": "<p>Drain pump inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ad22aa1c-e988-4857-b1f1-202f15990578",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "de844c94-0886-4ac5-b967-ccd4b7164ebc",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Concrete",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "PVC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Metal",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "156e2d98-6e25-43e5-ac47-706374230630"
                            }
                        ]
                    },
                    {
                        "order": 33,
                        prompt: "Laundry Tub Drain:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ecee00f6-a1e9-4615-a493-68c172891ee7",
                                "recommendation": "<p>Pump disconnected</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d5abf3fe-ce06-4cfb-9dda-a0036ba73f24",
                                "recommendation": "<p>No power to pump</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cb6fd444-869b-48c5-8541-662501a2aeef",
                                "recommendation": "<p>Pump line leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "330177ec-08fd-49e4-b749-919aa294d04a",
                                "recommendation": "<p>Improper wiring with extension cord</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "637aa85d-f03b-401c-b86d-588bc2bbc03c",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "16160c29-d4a4-4109-97ec-6d65aa6fe3ef",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Electric pump",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "PVC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Galvanized",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "418c4f7d-6dcf-4ead-8a2d-8cedbb88417f"
                            }
                        ]
                    },
                    {
                        "order": 34,
                        prompt: "Washer Hose Bib:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2903445e-6dcb-4994-bdc5-e8e897ef0ecf",
                                "recommendation": "<p>Faucet leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7b40d34f-9071-4046-8a3d-442783381648",
                                "recommendation": "<p>Missing handle</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "31de404f-2c96-4605-ade4-5c1e91fe0afa",
                                "recommendation": "<p>Inoperative at time of inspection</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2abbf35c-29bc-4198-ad96-fc4122afdc13",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2b605786-7c88-4ef1-808f-38978deff8a8",
                                "recommendation": "<p>Signs of previous leakage-monitor</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "968f3d63-13e4-4878-a7ba-59a8c7bc1f7f",
                                "recommendation": "<p>Properly secure fixture(s) to exterior wall with fasteners</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8e67f6b0-bbd3-4d86-9967-742784683d17",
                                "recommendation": "<p>Leaking at stem</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "275e9c40-e3a8-487e-9d0c-12247918663c",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Gate valves",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Multi-port",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Ball valves",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rotary",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not visible",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not present",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "e0690867-2ce4-4b5d-8457-668284bea8ad"
                            }
                        ]
                    },
                    {
                        "order": 35,
                        prompt: "Washer Drain:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3fe316b5-15d1-4d62-b622-86b864430689",
                                "recommendation": "<p>Slow drain</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bbe6ec0e-0f0c-44f8-8743-408212d822ec",
                                "recommendation": "<p>Sewer odor noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "337820e1-816a-4cf4-939d-2c75aba44208",
                                "recommendation": "<p>Galvanized drain pipe present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0922309e-0d11-432c-a35f-abbc7897c49e",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "c1e1bf25-65dd-4b84-b64a-83333a2da0cd",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Floor drain",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Drain pan to main drain system",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wall mounted drain",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Drains to laundry tub",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "f2c1af84-9d7e-4d8a-ac32-e7bbe025a7e3"
                            }
                        ]
                    },
                    {
                        "order": 36,
                        prompt: "Floor Drain:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6b17ad22-c231-4a99-bb13-8663e7131d77",
                                "recommendation": "<p>Blocked by debris</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0fea84e4-068f-401b-a99c-153d94a0854d",
                                "recommendation": "<p>Evidence of poor drainage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8c4fda00-ce2a-4d54-a9ec-324437e334c7",
                                "recommendation": "<p>Water pooling with stains visible</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7c335bd3-8c47-420f-9388-0570223392ab",
                                "recommendation": "<p>Undersized for surface area</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0cc781e0-f448-4e7c-b643-e1cc8afb853c",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "879a7a94-4848-445a-a078-fff44819d59b",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Covered",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "French drain",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Surface drain",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not visible",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "930f5be8-c069-407e-8ce0-854a30827b71"
                            }
                        ]
                    }
                ],
                "order": 1,
                "is_deleted": 0,
                "created_by": 686,
                "updated_by": 686,
                "created_at": "2022-11-08T18:05:40.000000Z",
                "updated_at": "2022-11-08T18:05:40.000000Z"
            },
            {
                uuid: "557ae474-8757-4cc1-9bfa-2c83d1d28065",
                prompt: "Inspection Details",
                "introductory_text": null,
                lines: [
                    {
                        "order": 1,
                        prompt: "Inspection Details",
                        comments: [],
                        "line_uuid": "7c1e9c4f-7438-4b38-a665-06ff324984bd",
                        "is_deleted": 0,
                        "defaultRating_id": 1,
                        "default_location": null,
                        "line_input_controls": [
                            {
                                "type": "CheckBox",
                                prompt: "Others Present",
                                "values": [
                                    {
                                        "value": "Buyer",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Seller",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Buyer\\'s Agent",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Seller\\'s Agent",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    }
                                ],
                                "lookup_id": null,
                                "input_type_id": 8,
                                "line_input_control_uuid": "087f468f-5727-4af1-b5b7-5bc03344fa4a"
                            },
                            {
                                "type": "CheckBox",
                                prompt: "Property Occupied",
                                "values": [
                                    {
                                        "value": "Occupied",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Vacant",
                                        "is_selected": false
                                    }
                                ],
                                "lookup_id": null,
                                "input_type_id": 8,
                                "line_input_control_uuid": "13f7b651-250d-412a-aaf0-0b353ee2081a"
                            },
                            {
                                "type": "CheckBox",
                                prompt: "Building Type",
                                "values": [
                                    {
                                        "value": "Single Family",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Condo",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Townhouse",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Duplex",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Multi-Family",
                                        "is_selected": false
                                    }
                                ],
                                "lookup_id": null,
                                "input_type_id": 8,
                                "line_input_control_uuid": "636c1900-0fe1-42cb-88c5-93560927a495"
                            },
                            {
                                "type": "CheckBox",
                                prompt: "Weather",
                                "values": [
                                    {
                                        "value": "Clear",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Sunny",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Partly Cloudy",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rain",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Sleet",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Snow",
                                        "is_selected": false
                                    }
                                ],
                                "lookup_id": null,
                                "input_type_id": 8,
                                "line_input_control_uuid": "af97ab84-277e-4760-b8b2-761d9f03ef61"
                            },
                            {
                                "type": "Temperature",
                                prompt: "Temperature",
                                "values": [],
                                "lookup_id": null,
                                "input_type_id": 7,
                                "line_input_control_uuid": "c4daa5bc-86a8-4247-b1ee-ebf246d4730d"
                            },
                            {
                                "type": "TextBox",
                                prompt: "Year Built",
                                "values": [],
                                "lookup_id": null,
                                "input_type_id": 9,
                                "line_input_control_uuid": "08dddae5-50bf-4598-bbbc-b0d8ad97450c"
                            },
                            {
                                "type": "CheckBox",
                                prompt: "Water Source",
                                "values": [
                                    {
                                        "value": "City",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Well",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Cistern",
                                        "is_selected": false
                                    }
                                ],
                                "lookup_id": null,
                                "input_type_id": 8,
                                "line_input_control_uuid": "b828a1d7-6ce5-42f4-9896-fe5cd2b34159"
                            },
                            {
                                "type": "CheckBox",
                                prompt: "Sweage/Disposal",
                                "values": [
                                    {
                                        "value": "City",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Septic",
                                        "is_selected": false
                                    }
                                ],
                                "lookup_id": null,
                                "input_type_id": 8,
                                "line_input_control_uuid": "2baa7b25-29b9-45e0-b0ca-0229ae28ac7c"
                            }
                        ]
                    }
                ],
                "order": 1,
                "is_deleted": 0,
                "created_by": 686,
                "updated_by": 686,
                "created_at": "2022-11-08T18:05:40.000000Z",
                "updated_at": "2022-11-08T18:05:40.000000Z"
            },
            {
                uuid: "e3b12fff-e4e1-4a01-92f7-d761a9563e1b",
                prompt: "Electrical",
                "introductory_text": null,
                lines: [
                    {
                        "order": 1,
                        prompt: "Service Entrance:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7e1fec4d-33a7-4ffa-8032-e7820a1b23ae",
                                "recommendation": "<p>Surface coating of service cable is cracked and may allow water to penetrate inside the cable.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b54d33c1-39e5-405a-9554-b5d5088634e9",
                                "recommendation": "<p>Trim trees for clearance around entrance cable</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d599e11a-6c7a-4136-a069-0a05bb03f7ae",
                                "recommendation": "<p>No drip loop present for wires entering masthead</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7262a485-07ad-4c37-9aa1-e22afba1a415",
                                "recommendation": "<p>Weather head not properly secured</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "56983f04-bdcf-4e2a-b0dd-ce486746caba",
                                "recommendation": "<p>Evaluation by a licensed electrician is recommended</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "64ee6378-7932-446b-b01a-8b3df060b77c",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Above Ground From Mast",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Under ground utilities",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "1b03581b-da52-4665-b2d3-3b36d7b573a6"
                            }
                        ]
                    },
                    {
                        "order": 2,
                        prompt: "Service Size Amps:",
                        comments: [],
                        "line_uuid": "5430b38a-c122-4306-8927-56f4ce4249bc",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "60",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "100",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "125",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "150",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "175",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "200",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "40",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "ba1564e2-bd6c-4ea0-aac3-0f562416c150"
                            },
                            {
                                "type": "Additional input",
                                prompt: "Volts:",
                                "values": [
                                    {
                                        "value": "110 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "220 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "220-240 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110-120 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110-240 VAC",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "a374a59c-3bed-4f2c-9315-367fe691cb7a"
                            }
                        ]
                    },
                    {
                        "order": 3,
                        prompt: "Service:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e969b6d6-769f-4650-b72c-a6503df8c211",
                                "recommendation": "<p>Surface coating of service cable is cracked and may allow water to penetrate inside the cable.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "be1a70ae-169d-4048-a5d3-9daf6b00e712",
                                "recommendation": "<p>Trim trees for clearance around entrance cable</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cf16b310-b8d0-41d3-99dd-91c04f41e6f2",
                                "recommendation": "<p>No drip loop present for wires entering masthead</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "dcec5fdf-e465-4e70-9d31-4b16b9247002",
                                "recommendation": "<p>Weather head not properly secured</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "516186ed-5deb-4354-882f-df919c2481b2",
                                "recommendation": "<p>Evaluation by a licensed electrician is recommended</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "7a32d45e-0395-4960-a05f-6067c67ba1b8",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Aluminum",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper and aluminum",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "9fc3e5d9-b565-4aeb-9db6-11b55bfcf986"
                            }
                        ]
                    },
                    {
                        "order": 4,
                        prompt: "Ground:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7f0ccb77-bd9e-4a1b-b452-370d3efe26f7",
                                "recommendation": "<p>Missing ground strap at water meter</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8760907b-3201-4496-8de3-8cee70383115",
                                "recommendation": "<p>Open ground circuit</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "442eb84a-359e-4efa-995e-7aad4894f334",
                                "recommendation": "<p>Ground wire loose or broken</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e91f38d5-bd8e-402a-bf7c-306af3f5eb3a",
                                "recommendation": "<p>Evaluation by a licensed electrician is recommended</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "0f11b909-b7ed-403f-ad9d-7487cf4a27e3",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Plumbing and rod in ground",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rod in ground only",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Plumbing ground only",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "b97b575a-1913-412e-91b6-32cd42319ba0"
                            }
                        ]
                    },
                    {
                        "order": 5,
                        prompt: "120 VAC Branch Circuits:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fb3c0a52-f88a-4725-b165-3fff99abe77c",
                                "recommendation": "<p>Covers missing from boxes</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "80fbbfe2-837e-480f-af49-bb4aa9df1827",
                                "recommendation": "<p>Evaluation by a licensed electrician is recommended</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a5fe9c31-acfa-4256-a25b-6ca42c4cb518",
                                "recommendation": "<p>Improper routing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "45ceab11-8e4b-44b0-a862-7d0d1eeebd7d",
                                "recommendation": "<p>Open ground circuit</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9f851e52-d817-4253-8b4d-d1388edde3ef",
                                "recommendation": "<p>Pigtails added for aluminum wiring</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "095dcb77-0732-424b-9dbb-2c06561ef85c",
                                "recommendation": "<p>Reversed polarity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3cb0a778-1ee6-4a33-a248-72ffdf8fdff6",
                                "recommendation": "<p>Wires terminate outside protective box</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "b4d6ddf3-419e-4a6b-b8ac-484a1177ad18",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Aluminum",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper and aluminum",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "8fbd1615-5337-4e5b-b4f7-cb636546bf11"
                            }
                        ]
                    },
                    {
                        "order": 6,
                        prompt: "240 VAC Branch Circuits:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "19029b17-7bd8-409f-a3a6-907cebec752a",
                                "recommendation": "<p>Covers missing from boxes</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b4be8a5a-6fa2-466e-8ebc-3a62d656ffa2",
                                "recommendation": "<p>Evaluation by a licensed electrician is recommended</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "290c5637-59af-476e-a094-1a6011d5354d",
                                "recommendation": "<p>Improper routing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "af227187-c24b-4112-8a6c-5c61a6bd4b90",
                                "recommendation": "<p>Open ground circuit</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "529e7d28-f41d-447e-b6f1-ac50d5122d38",
                                "recommendation": "<p>Pigtails added for aluminum wiring</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a65194be-319e-45af-b084-748930fecc96",
                                "recommendation": "<p>Reversed polarity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0be045e9-24ba-41e4-a61d-7f9b5a1d9524",
                                "recommendation": "<p>Wires terminate outside protective box</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "cd6d3c63-94ed-4bf8-a83f-4bd42980dbfa",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Aluminum",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper and aluminum",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "ff65f473-ab1b-41f9-a76b-9824731301ed"
                            }
                        ]
                    },
                    {
                        "order": 7,
                        prompt: "Aluminum Wiring:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "76a5d238-f8f4-4f80-908b-a26ad465e733",
                                "recommendation": "<p>Circuit has been upgraded with ALU/CU breakers and pigtails at the receptacles</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "51f12be0-989f-42fb-8a14-89fc2ec5bf3f",
                                "recommendation": "<p>Recommend wiring update for aluminum wiring by a licensed electrician</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "f6c270ef-64b8-4d4e-bfc0-348a9605cb79",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Present through out the home",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Localized circuits 1st Floor",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Localized circuits 2nd floor",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Old wiring not presently in use",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not present",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "59c60cb6-3700-4610-8d2b-e67b7224f495"
                            }
                        ]
                    },
                    {
                        "order": 8,
                        prompt: "Conductor Type:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c95e7dae-71f0-4019-a81d-1aeea2576f57",
                                "recommendation": "<p>Improper routing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8b43305d-fec3-4b9a-995a-3c38d0b5ab33",
                                "recommendation": "<p>Conduit not secured</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fb176aa7-f25f-4c33-b739-e0c25feb3832",
                                "recommendation": "<p>Frayed covering</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fa1b7d0c-9cef-436d-8506-72636688179e",
                                "recommendation": "<p>Improper application</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2ea692e5-a188-4bea-81b9-6f3d1f365dce",
                                "recommendation": "<p>Evaluation by a licensed electrician is recommended</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "addc2c84-1425-4bde-a6d6-1ae0249df172",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Armored cable",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Non-metallic sheathed cable",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "BX",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Knob and tube",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Romex",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Romex, armored cable and knob and tube",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Romex and aluminum cloth wrapped",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "0ae0c7fa-883b-4578-bae3-b1645f489f81"
                            }
                        ]
                    },
                    {
                        "order": 9,
                        prompt: "Interior Lighting:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "99f894c6-8644-4e1e-acdb-0996e1f440b7",
                                "recommendation": "<p>Open or missing ground</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8dcfb199-0700-42a3-b1bf-14ffb4d58155",
                                "recommendation": "<p>Suggest evaluation by licensed electrician</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f808d72f-f26b-4d3f-9c21-42c32dfb41eb",
                                "recommendation": "<p>Non-GFCI circuit</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "62b68e16-97cb-44dc-8b15-e8e462fdc2a2",
                                "recommendation": "<p>Reversed polarity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8836c828-26f3-4cea-a7a1-cbad71d1d780",
                                "recommendation": "<p>Install missing junction box cover plates</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e6751000-f826-4e0f-bf4c-28bc8739a262",
                                "recommendation": "<p>Install missing junction boxes and cover plates at open splice connections</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "58a47349-0042-4c6f-ae57-ddffe20615f7",
                                "recommendation": "<p>Protect exposed surface mounted wiring in conduit to 7'-0' above floor</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7a769ee8-b8b6-4439-845c-5ef5b5ffda21",
                                "recommendation": "<p>Faulty GFCI outlet-replace</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3264d2c5-b123-4e27-b329-f94beaf9f0e1",
                                "recommendation": "<p>Reversed polarity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a37c960b-8d82-42ef-babb-a6ab573bbf83",
                                "recommendation": "<p>Missing outlet/switch cover plates</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6d94c27e-64bb-4b8a-8c76-3b25e61d5361",
                                "recommendation": "<p>Loose switch or outlet should be resecured</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5523c729-1427-4de1-aca1-5a1e65cbcc0e",
                                "recommendation": "<p>Ceiling fan inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "21fcfdf7-9b92-410a-839c-1ef15b2a87f8",
                                "recommendation": "<p>Worn outlets creating intermittent ground- replace outlets</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7026d51e-f6a2-4f34-932f-6a69e310390d",
                                "recommendation": "<p>Light fixture not functional-repair/replace</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3bf4503a-104a-4daf-bc5c-a34535f03da3",
                                "recommendation": "<p>Open grounds noted at 3 prong outlets</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7b606fcd-6402-48e7-819e-5b3396d638ca",
                                "recommendation": "<p>Remove temporary wiring running through walls -amateur installation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "97b2e53c-1425-45d8-b2c1-33d16325a956",
                                "recommendation": "<p>Outlets not working</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "77cea504-925b-47d4-a3db-10ebba067c5c",
                                "recommendation": "<p>Non-GFCI circuit -recommend GFCI circuit be installed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8a2935d6-3d1a-4aea-acc1-244d48225aae",
                                "recommendation": "<p>Insufficient outlets for space</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "12f8d26f-287a-41f0-a3c7-ddee1bfb9dab",
                                "recommendation": "<p>A licensed electrician is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "acbad331-e043-4a86-960c-9db23d843516",
                                "recommendation": "<p>Replace damaged outlet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "92ae07c1-0afa-4cca-88ee-f069054d9673",
                                "recommendation": "<p>110 VAC Outlets, Lighting Circuit and ceiling fan</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ab1b868a-7a29-4fd8-8036-755f5c5ac511",
                                "recommendation": "<p>Install missing outlet/switch cover plate(s)</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "cc862e23-08d6-4435-87f6-18ee81c5e99d",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "110 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC GFCI",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC/220 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "220 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC outlets and lighting circuits",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "1ffa38fe-a421-41ec-966d-d0dd27734725"
                            }
                        ]
                    },
                    {
                        "order": 10,
                        prompt: "120 VAC Outlets:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b5a725a6-6925-4f5f-966c-3e27063bae27",
                                "recommendation": "<p>Improper spray pattern,</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8e4b49b2-de12-40db-a97b-3f1fd21443c1",
                                "recommendation": "<p>Leakage noted,</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "82d24cba-5cdf-42eb-8181-b93df08f15e5",
                                "recommendation": "<p>Not tested due to season,</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "91339d31-d031-4217-bc79-62cb550294ac",
                                "recommendation": "<p>Sprinkler head damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "449d2432-d93e-44be-9ca4-af8d924d93fb",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Aluminum",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper and aluminum",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "1cb570b9-4557-4fb3-b851-618f5f013a7a"
                            }
                        ]
                    },
                    {
                        "order": 11,
                        prompt: "240 VAC Outlets:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8c3c427d-8853-4e63-b1f7-b76cbc4266bd",
                                "recommendation": "<p>Loose connections</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b757e081-2b38-4873-b75d-fb666676ff42",
                                "recommendation": "<p>Evidence of arching</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d37c0bd4-e845-475f-a0d3-d5f471e9273b",
                                "recommendation": "<p>Outlet not securely mounted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "a2201be8-5cc3-4f7b-9ba9-62573a4cc040",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "3-pole 3-wire grounded",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "3-pole 4-wire grounded",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "3-pole 3-wire Non grounded",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "29747774-b679-4e63-879c-c35663133761"
                            }
                        ]
                    },
                    {
                        "order": 12,
                        prompt: "Manufacturer:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "08726432-012b-43c9-975e-54c0265279f0",
                                "recommendation": "<p>Corrosion on breakers from water penetration</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5a7479be-943c-45c5-977a-0ccfaca69c04",
                                "recommendation": "<p>Double taps present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "eb56037f-5b3b-4b08-b30c-1e423f1ea5ae",
                                "recommendation": "<p>Open ground noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c6e2edd5-3249-46b3-b645-2c84b33b06c4",
                                "recommendation": "<p>Recommend existing panel be replaced</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8fdc1c86-902e-4297-b794-9caba07653ce",
                                "recommendation": "<p>Panel too small for the current needs of the home</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a533c0a6-6465-4663-97aa-9492587aa00d",
                                "recommendation": "<p>Recommend fuse style panel be updated</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "202cc333-7ed6-486a-96f6-056a1d214b2c",
                                "recommendation": "<p>Loose or missing wire retaining screw at breaker connection</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "af2235c9-becc-4c49-bc4e-d0df4c3e991f",
                                "recommendation": "<p>Signs of arcing in cover</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "616df282-adc2-4b4e-af32-bde9fb9d834e",
                                "recommendation": "<p>Improper cover screws</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "738ef6c1-cef1-480d-bfc1-3284cb1b6216",
                                "recommendation": "<p>Direct tap present, wires not protected by breaker or fuse at the panel</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d828770e-2706-4130-b33b-c959190cb61a",
                                "recommendation": "<p>Evaluation by a licensed electrician is recommended</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ee1a5321-abe7-4228-a0aa-ed0981b18011",
                                "recommendation": "<p>Double tap present at main panel lugs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7cf233c5-efde-4cbc-90d2-2ddd2eac6814",
                                "recommendation": "<p>Federal Pacific panel breakers have a history of not tripping when circuit overloading exists</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6720b69e-1330-4aae-aea7-538cf9143471",
                                "recommendation": "<p>Open 'knock-out' hole- insert 'blank-out' cap to close off opening</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b0b2f3a4-fbcd-4fcc-b82d-a55f2a252f23",
                                "recommendation": "<p>Panel is rusted inside</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "d5efdbe6-6eaf-421c-8737-9a4dc944825d",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Square D",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "General Electric",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Federal Pacific",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Push-O-Matic",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Cutler-Hammer",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Seimens",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wadsworth",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Murray",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Crouse-Hines",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "General",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "I-T-E",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Westinghouse",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Gould",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Challenger",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Sylvania",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Bulldog",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Varies",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "024fb6c3-039b-49bf-ac03-8745cb59445e"
                            }
                        ]
                    },
                    {
                        "order": 13,
                        prompt: "Maximum Capacity:",
                        comments: [],
                        "line_uuid": "7a74f18f-ed08-4d14-9a55-1a96874cf8bf",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "30 Amp",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "60 Amps",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "70 Amps",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "100 Amps",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "125 Amps",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "175 Amps",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "200 Amps",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "400 Amp",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "No single main breaker exists",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "No single main breaker exists, Split bus design",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "af18960c-79b7-41d1-b951-5afa2f4e29d8"
                            }
                        ]
                    },
                    {
                        "order": 14,
                        prompt: "Main Breaker Size:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ed536790-51e4-4cca-846d-4546b45e86fa",
                                "recommendation": "<p>Breaker undersized</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "13f0da46-8caf-442e-8a8f-9bf57acdc9df",
                                "recommendation": "<p>Corrosion on breakers</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3762ffce-02a3-4bc7-ac53-67f9fdd5192a",
                                "recommendation": "<p>Replace breakers</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "497175da-531f-4503-9040-93695b26bfbc",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "30 Amp",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "60 Amps",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "70 Amps",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "100 Amps",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "125 Amps",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "175 Amps",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "200 Amps",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "400 Amp",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "No single main breaker exists",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "No single main breaker exists, Split bus design",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "3c80e120-0b67-49e7-b213-262435163699"
                            }
                        ]
                    },
                    {
                        "order": 15,
                        prompt: "Breakers:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "98bccf66-06e5-42a6-9664-01d3f31079da",
                                "recommendation": "<p>Breakers not rated for aluminum</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f25d7f54-08b6-4cbe-a1d2-8a49ea5aadce",
                                "recommendation": "<p>Corrosion on breakers</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8b5ea3f9-9ed4-43c1-addb-8f32333b1103",
                                "recommendation": "<p>Double tap wiring</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3cbb7745-9e7a-4537-ace1-70a20e041c37",
                                "recommendation": "<p>Improper breaker for application</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bd49eade-a691-4a59-b64d-c379183f5799",
                                "recommendation": "<p>Loose breakers</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "441125cd-daac-4661-820e-b14902aa8c4a",
                                "recommendation": "<p>Overheated breakers</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e33edc01-dbf1-498e-bc4d-d4d2cabb63b0",
                                "recommendation": "<p>Corrosion on breakers from water penetration-require replacement of affected breakers</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1413c418-ac47-4fcf-b503-05fda5f33b10",
                                "recommendation": "<p>Double taps are present at breakers. These circuits need to be moved to their own circuit breaker and cannot share a breaker. It is recommended that a qualified electrician inspect the new circuits and properly connect the new circuits to an individual breaker for each circuit</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6e90b113-aacb-4025-89f2-d5c87bf68e47",
                                "recommendation": "<p>Loose/missing wire retaining screw at breaker connection-requires correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "3636294f-5196-467f-9350-0e5fb722954d",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper and Aluminum",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "a1986aed-8ed8-4cde-835d-299c2bf24efb"
                            }
                        ]
                    },
                    {
                        "order": 16,
                        prompt: "Fuses:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "64f2bcbc-7d35-4893-8108-75f1d7888bd2",
                                "recommendation": "<p>Frayed cloth covered wiring present in fuse panel</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ef70eeb5-f893-4cd0-9a7e-4ae6fca821c8",
                                "recommendation": "<p>Evidence of overheating- recommend further review of circuit by licensed electrician</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ec33da6c-7d62-4eb7-850c-18bfe2aa159b",
                                "recommendation": "<p>Fuses blown- recommend further review of circuit by licensed electrician</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "74da2b89-2a05-4381-b149-e377f476a357",
                                "recommendation": "<p>Improper fuse size for circuit- requires correction</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "eb66e05b-ada8-47c1-8e4a-e668007fac89",
                                "recommendation": "<p>Loose fuses- properly resecure</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "73dc91e1-fb12-4d3c-9a11-924cecd2856c",
                                "recommendation": "<p>Loose wires- properly resecure</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "9d39de10-156d-486f-b1c4-1f9c4ac022d0",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Blade type",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Screw type",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "dee5d657-e0aa-4326-9c8f-471a19ecf439"
                            }
                        ]
                    },
                    {
                        "order": 17,
                        prompt: "AFCI:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4d7335cb-a5b7-4b62-b01d-c360fcebc315",
                                "recommendation": "<p>AFCI does not trip when tested</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c8bdc5f8-0039-4ace-9e12-fc9e67a5a2c9",
                                "recommendation": "<p>An evaluation by a licensed electrician is recommended</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "705250ff-4574-41e3-9cf2-c5992de9e537",
                                "recommendation": "<p>Loose connection</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "23e7fd5f-5e8b-45b2-9484-17d7fd6ee6c4",
                                "recommendation": "<p>Faulty operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a930270c-7e94-4941-9c3d-b881ae5ef6df",
                                "recommendation": "<p>Improperly installed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5bd6cd45-461c-49af-b64c-f0431f8c0f54",
                                "recommendation": "<p>Not present as required</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5d5a9d43-b616-4517-a8e5-28d9b7df64c6",
                                "recommendation": "<p>Improper protection size </p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bdd32f0d-fdb1-4ad5-a515-1fb8ac96fd4b",
                                "recommendation": "<p>Improper wiring connection</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a084eeb0-10b1-4b39-b2e5-e387092bd2b4",
                                "recommendation": "<p>Improper socket tension</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ae1ec0ce-733e-4123-9192-5de956068a2f",
                                "recommendation": "<p>Loose or improper attachment</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "8fdcb5c6-9192-4138-9fb6-11c7d7a914ed",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "110 volt",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "220 volt",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "e5ce25ac-d3a7-48f8-b9dd-8c583d1f2b07"
                            }
                        ]
                    },
                    {
                        "order": 18,
                        prompt: "GFCI:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "55ca9541-a9f3-4aad-90fe-7a071314db6d",
                                "recommendation": "<p>GFCI does not trip when tested</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "870cff3d-38ff-48c0-a9cd-77e11aeec546",
                                "recommendation": "<p>GFCI not present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e53910c8-c1c3-4746-afa2-7df57e06dc1b",
                                "recommendation": "<p>Improper wiring connection</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "49cbbb23-0a70-4965-ba5e-b54635ac9a25",
                                "recommendation": "<p>Improper socket tension</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ddae4f9c-4ffd-40b4-992d-e1689e0ce76d",
                                "recommendation": "<p>Loose or improper attachment</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "70864a0b-a240-4d2d-bef8-2158d2a9e615",
                                "recommendation": "<p>Evaluation by a licensed electrician is recommended</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "31216e6b-d3bc-4cc2-bf33-ded5bc182b31",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "At GFCI receptacles only",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Kitchen and bathrooms",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not present",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Basement, garage, kitchen, bathrooms",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Present",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "a0d037f7-80db-43b2-bfaa-07d641d1fe6c"
                            }
                        ]
                    },
                    {
                        "order": 19,
                        prompt: "Is the panel bonded?",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cbb77836-91b6-4a20-8b1f-e061b41c9133",
                                "recommendation": "<p>Panel bonding straps loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "63351f1e-e789-472c-8aa1-d9e12b6606bf",
                                "recommendation": "<p>Panel bonding straps missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "e82b3f83-36fb-4ca6-a3de-f69380eabcb5",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Yes",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "No",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "286a584c-852c-4fb4-890b-580f3298a4a9"
                            }
                        ]
                    },
                    {
                        "order": 20,
                        prompt: "Door Bell:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7e0a2aa7-6eb7-44c2-afc3-d079016bf843",
                                "recommendation": "<p>Cover broken</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2cce41fd-5990-4b07-ae6c-6a99aa6907d9",
                                "recommendation": "<p>Exposed wires</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "efd11f83-26a6-4dd0-8ccb-526819d030a7",
                                "recommendation": "<p>Inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "d6768a9e-63c2-4ff6-a2ea-4f241497ba9d",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Hard wired",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Battery operated",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "22423e7d-68fb-44e1-8822-2685cabaf0e1"
                            }
                        ]
                    },
                    {
                        "order": 21,
                        prompt: "Smoke Detectors:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "23b8bd48-b300-4459-a3f8-2d2a0666099a",
                                "recommendation": "<p>Evaluation by a fire alarm certified licensed electrician is recommended</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8e286f19-4bda-4f22-ab99-04b02a4be041",
                                "recommendation": "<p>Not tested due to lack of electricity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e6f41e74-b1ff-4751-835c-b0c98566e838",
                                "recommendation": "<p>Battery missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "34c80a6d-963f-4c4f-8f01-3b09d8b6c6b4",
                                "recommendation": "<p>Improperly wired</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "14e916b9-c54e-4ebd-8bb5-f6cbb767c423",
                                "recommendation": "<p>Inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "2c178949-fbfa-4431-8b4c-b005b7f6994b",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Battery operated with light",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Battery operated",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hard wired with battery back up and light",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hard wired",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hard wired with battery back up",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "504a47ed-bad6-45ae-8f7d-e6cfd47b5746"
                            }
                        ]
                    },
                    {
                        "order": 22,
                        prompt: "Carbon Monoxide Detectors:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "337d77b1-60e7-464a-9fad-90bcad4f91b7",
                                "recommendation": "<p>Not tested due to lack of electricity</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5900c5f8-adf4-48e1-8312-06520cd9df17",
                                "recommendation": "<p>Battery missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4f740302-638e-4b90-aba4-297586272d29",
                                "recommendation": "<p>Improperly wired</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "51023b8e-7ce6-405b-8825-5374bfbb53f0",
                                "recommendation": "<p>Inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "404d24fa-ffab-4948-afdd-24160a948399",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Battery operated with light",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Battery operated",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hard wired with battery back up and light",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hard wired",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hard wired with battery back up",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "33086211-5b80-4f5d-ba4c-233ce6239be2"
                            }
                        ]
                    },
                    {
                        "order": 23,
                        prompt: "Exterior Lighting:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1da57e5e-d246-455f-b0e1-43b3918ad881",
                                "recommendation": "<p>Bulbs burned out at time of inspection</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5ec8b060-2f73-416c-8390-283e3037542e",
                                "recommendation": "<p>Exposed wiring</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8ddb17f0-8f26-4838-9b71-cad8d35f1069",
                                "recommendation": "<p>Improperly mounted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fcf16a85-862b-4a8b-a415-ae689053e990",
                                "recommendation": "<p>Light bulb missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4ee149f8-d682-420b-94bb-ff2544f3f769",
                                "recommendation": "<p>Loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "0f5d3b51-55b6-490d-b36f-a80ad7df973e",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Pole light",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Surface mount",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Surface mounted lamps front and rear",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "604877ed-e640-4503-8576-030dfe8f029d"
                            }
                        ]
                    },
                    {
                        "order": 24,
                        prompt: "Exterior Electric Outlets:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "78e7195c-61b2-4c49-8014-7803f528189e",
                                "recommendation": "<p>Loose or missing moisture cover</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e26c122c-37bc-49d4-905e-7947cf5c5f6a",
                                "recommendation": "<p>Exposed wiring present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a127b67c-8b43-4e4f-8dba-5b1b87311196",
                                "recommendation": "<p>Outlets have visible corrosion</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1cff0630-36c0-4abf-82da-ccfa4243caf4",
                                "recommendation": "<p>Inoperative at time of inspection</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1ece2b11-4550-4619-af73-33c70cf35223",
                                "recommendation": "<p>Non-GFCI outlet</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "23de49bd-9b4b-4c1a-9008-a4e60821cb14",
                                "recommendation": "<p>Improper wiring for exterior application</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "78453dc1-a56f-4132-ac16-817d1b645bfc",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "110 VAC GFCI",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "03e03751-b180-4d67-a80d-82ebaa4ddedc"
                            }
                        ]
                    }
                ],
                "order": 1,
                "is_deleted": 0,
                "created_by": 686,
                "updated_by": 686,
                "created_at": "2022-11-08T18:05:40.000000Z",
                "updated_at": "2022-11-08T18:05:40.000000Z"
            },
            {
                uuid: "3078fa15-b52c-421a-a3cd-e5b0cb11895c",
                prompt: "Roof",
                "introductory_text": null,
                lines: [
                    {
                        "order": 1,
                        prompt: "Method of Inspection:",
                        comments: [],
                        "line_uuid": "9b674330-0e68-47a9-a83c-d31274d2c90d",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Ladder at eaves",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Ground level",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "On roof",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "18a93468-e896-4f92-a720-4ed74c771715"
                            }
                        ]
                    },
                    {
                        "order": 2,
                        prompt: "Unable to Inspect:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "773cc7db-4c01-4119-a20d-662309050cce",
                                "recommendation": "<p>Snow</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7059a235-bd4b-4024-8d63-287c2c4d0958",
                                "recommendation": "<p>Height of roof</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9092e6cf-2ac7-4ca6-b912-8fa2801fac7c",
                                "recommendation": "<p>Leaves</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c1b6b662-2b16-4334-901d-87e33bc3945a",
                                "recommendation": "<p>N/A</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "35f32cf9-b305-4b76-9ff6-94a38b6a54c8",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "10%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "15%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "20%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "25%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "30%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "40%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "45%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "50%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "55%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "60%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "65%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "70%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "75%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "80%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "85%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "90%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "95%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "100%",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "9a154385-327e-40af-acd7-938411019337"
                            }
                        ]
                    },
                    {
                        "order": 3,
                        prompt: "Material:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8837465d-4ecc-4555-8ba4-d44a1462317a",
                                "recommendation": "<p>Loose or damaged shingles</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0c137e38-c577-4b6a-8598-ce98643af25f",
                                "recommendation": "<p>Shingles nailed improperly</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1fdc0a10-c4fb-45eb-90de-0ab997e622a3",
                                "recommendation": "<p>Pitch of roof too low for shingles</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "86574611-e5b2-45ff-a51e-4b3fac0bdb7c",
                                "recommendation": "<p>Shingles not sealing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "698881a3-e7d4-46b1-b42d-51a1a9cfaa15",
                                "recommendation": "<p>Roof shows signs of deterioration</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b08fe975-6dda-4bdd-b933-a9edb30cab99",
                                "recommendation": "<p>Signs of cracking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2592af1e-71e0-425d-8442-f4f15d48b1c1",
                                "recommendation": "<p>Roof spongy to walk on</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a8924071-e764-424c-b1e0-2effd83a1873",
                                "recommendation": "<p>Roof at end of useful life</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "78929600-cea8-44b8-9d7e-5bbb7c7cb4c9",
                                "recommendation": "<p>Roof near end of useful life</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "681d8b1f-42ef-4416-98e4-da8db2a7d732",
                                "recommendation": "<p>Roof existing beyond design life</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c8dea093-e153-4b22-9dda-f1d5e6f21044",
                                "recommendation": "<p>A qualified roofing contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "452d502b-f375-430b-96c7-da1791f40401",
                                "recommendation": "<p>Signs of curling or cupping</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "15fc35d3-d60c-4bde-9f98-a15a3bf66ccb",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Asphalt shingle",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Clay tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Built-up",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Cement tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tar and gravel",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Asbestos",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fiberglass shingle",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rolled roof material",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Slate",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tin",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wood shake",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "4db50aac-bf09-420f-9b7f-799b1a47ffb4"
                            }
                        ]
                    },
                    {
                        "order": 4,
                        prompt: "Type:",
                        comments: [],
                        "line_uuid": "574a457e-88c1-400b-b77d-a76df321344b",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Gable",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Gambrel",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Hip",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Shed",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Mansard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Flat",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "2280b465-1bf4-46de-8793-7c8150d71600"
                            }
                        ]
                    },
                    {
                        "order": 5,
                        prompt: "Approximate Age:",
                        comments: [],
                        "line_uuid": "1f303110-8b3b-4005-965f-cb826cc4ee11",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "0-3 years",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "4-7 years",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "8-10 years",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "10-15 years",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "15+ years",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Unknown",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "New",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "1ac6faeb-4515-42cc-bd05-3594e937afb0"
                            }
                        ]
                    },
                    {
                        "order": 6,
                        prompt: "Flashing:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e567a270-826f-49f1-89d5-5c7af8cd8348",
                                "recommendation": "<p>Prone to possible leaks</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "784dd83b-d364-448e-99fe-976717fe868e",
                                "recommendation": "<p>Loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9da032ad-e9c8-42e7-a7ed-6f6ae393e0f2",
                                "recommendation": "<p>Improper installation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1573b3e6-02a7-4432-a46d-36f38baba53f",
                                "recommendation": "<p>A qualified roofing contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "7e3e1f22-5482-4d2b-bc72-be7dc3d02681",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Aluminum",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Galvanized",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "2f6f3e25-34a2-474b-b18f-481efa461d96"
                            }
                        ]
                    },
                    {
                        "order": 7,
                        prompt: "Valleys:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d2ad3ab6-5bfd-42e2-9332-003402726474",
                                "recommendation": "<p>Valley shingles not trimmed and sealed properly</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "51b1ba19-0283-475a-ae84-b15055fec6c0",
                                "recommendation": "<p>A qualified roofing contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "e0904688-be34-4c0c-b227-67a8a8f9a4ff",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Asphalt shingle",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rolled roof material",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Asbestos shingle",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tile",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Preformed metal",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "6be090e4-eadc-40ed-9e05-d6aa16462dd8"
                            }
                        ]
                    },
                    {
                        "order": 8,
                        prompt: "Skylights:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6a4e22e8-c4d4-4237-bc02-33d29d1e0e07",
                                "recommendation": "<p>Moisture stains at drywall near skylight</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6fe93938-005c-4f21-80ff-e6a77d2e93bf",
                                "recommendation": "<p>Water leakage present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3e2c03b0-41c1-4258-a18a-09f80487475f",
                                "recommendation": "<p>Evidence of past water leakage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "50bb43b4-5155-4813-9de4-4be29887627b",
                                "recommendation": "<p>Moisture present in the glass due to broken seal</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "62ef5e47-446b-47ef-892b-aca636b9f786",
                                "recommendation": "<p>A qualified roofing contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "08d888a2-6b72-4187-9930-a3af33179eae",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Insulated glass",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Plastic",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "07a88482-6bc1-4c10-9f13-c2415656dcb5"
                            }
                        ]
                    },
                    {
                        "order": 9,
                        prompt: "Plumbing Vents:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3b85da2e-bd64-47c8-8d8b-ee177f111e8b",
                                "recommendation": "<p>Improper or blocked vent</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c3d85686-d835-4087-888e-f5674c198d2e",
                                "recommendation": "<p>System not vented</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "090ac1a1-bcbb-46a3-b249-ae85cc8f38d0",
                                "recommendation": "<p>A licensed plumber is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "f0fa06bf-cb43-40e1-ac94-6df0fc3249c4",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "PVC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "ABS",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Galvanized",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Cast Iron",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "12869cb8-fe28-42c6-9699-dfd625092995"
                            }
                        ]
                    },
                    {
                        "order": 10,
                        prompt: "Sheathing:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ecfa7863-ef43-4816-b511-a7665e147162",
                                "recommendation": "<p>Moisture damage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2a34e808-7c09-4a2f-a970-159b40159590",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "155e0a9d-caa9-47d7-9294-9135a902149a",
                                "recommendation": "<p>Loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "99bbb9dd-feaa-4592-83cb-34c7a0258f7e",
                                "recommendation": "<p>Squeak in flooring</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "31eaa74b-5893-42d7-b61c-3294783f2096",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Composite manufactured materials",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Dimensional wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Plywood",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "7643e5f1-9d25-40eb-8ce9-52cc1f5cd9a2"
                            }
                        ]
                    },
                    {
                        "order": 11,
                        prompt: "Roof Trusses:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6fd53ec0-043d-41d1-a787-4e2940980b08",
                                "recommendation": "<p>Joists have been modified and will require evaluation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6a394aea-d356-4bf7-96a5-bc048592e9d3",
                                "recommendation": "<p>A structural engineer is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "308fca9a-1677-43d2-9d70-ce7283e093e1",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "2x8",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "2x10",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "2x12",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "74b75d68-31ff-4d2e-b512-88a66c6d6f53"
                            }
                        ]
                    },
                    {
                        "order": 12,
                        prompt: "Roof Joists:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "51556527-87dc-4bf6-923c-3baa3bef01ff",
                                "recommendation": "<p>Joists have been modified and will require evaluation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8df6abfc-e5b2-4965-84e7-8ec41e1e6ab0",
                                "recommendation": "<p>A structural engineer is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "685732df-79d2-43f5-9756-0b7e2e829e23",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "2x8",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "2x10",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "2x12",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "55322dab-d3df-499a-ada2-dccbd36cb05d"
                            }
                        ]
                    },
                    {
                        "order": 13,
                        prompt: "Support Beams:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f79c8f78-4128-41ba-a757-adfd84544c78",
                                "recommendation": "<p>A structural engineer is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c531608f-cdf9-45da-8cbb-fb520690860b",
                                "recommendation": "<p>Beam not properly supported</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a2090c79-f1f3-4972-8c7b-b731947ad796",
                                "recommendation": "<p>Beams do not extend properly into the pockets</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "909daac4-68fb-472a-ae18-03a340948903",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Steel I-Beam",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Bonded wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Solid wood",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Laminated",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "53cc54a0-70c5-4e05-bf32-43caa76c5dc5"
                            }
                        ]
                    },
                    {
                        "order": 14,
                        prompt: "Roof Bearing Walls:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5996301c-e51b-4bad-96bb-8bf0e43ec373",
                                "recommendation": "<p>Bearing wall has structural damage and will require evaluation and repair</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5c5a5939-267d-4c1a-8346-e4084202683b",
                                "recommendation": "<p>A structural engineer is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "7983a811-f246-436e-b0db-7b247387f64a",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Brick",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Block",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Frame",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rock",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "7f745796-c864-42b5-bfce-0b54197c9150"
                            }
                        ]
                    },
                    {
                        "order": 15,
                        prompt: "Roof Differential Movement:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cdbd5a73-5c6d-4f60-89ce-5b6d7e114a95",
                                "recommendation": "<p>Cracks will require monitoring</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cdbc76d2-7380-4644-bda4-54be690d549a",
                                "recommendation": "<p>A structural engineer is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "c7430766-f214-4c79-b922-d8db4985d765",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Crack with displacement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Horizontal crack with displacement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Lateral crack with displacement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "No movement or displacement noted",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Stair step crack with displacement",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "0506f103-0607-4b22-8444-704f6d06d083"
                            }
                        ]
                    },
                    {
                        "order": 16,
                        prompt: "Gutters:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0ec1acd9-5dd7-4fe1-a059-277990f0228a",
                                "recommendation": "<p>Gutters loose, spikes or straps missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "58598444-bee7-42ab-bd90-bbe556442103",
                                "recommendation": "<p>Gutters have negative flow and are holding water</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "89ffb78c-8fda-4f50-a1e3-dcf88b03e499",
                                "recommendation": "<p>Loose nails</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e596a965-ddae-4a30-9299-3c9e84612101",
                                "recommendation": "<p>Need cleaning</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e276b70f-6b6e-474b-91e0-953c0abc1b0d",
                                "recommendation": "<p>Gutters appear to be undersized for roof pitch or conditions</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "60cf193e-f202-4e28-8da3-fe09ee3f4f0c",
                                "recommendation": "<p>Leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1eef9495-a840-49ed-8092-f27d6f6f9301",
                                "recommendation": "<p>Loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4df544ed-2cf0-405c-82ee-f85576f47c62",
                                "recommendation": "<p>Missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "b7122b4b-48ef-4d84-a299-d37322195f64",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Aluminum",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Boxed",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Galvanized",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Splash blocks",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Plastic",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steel",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "1743b90c-1fbd-4a49-8842-220d9668606a"
                            }
                        ]
                    },
                    {
                        "order": 17,
                        prompt: "Downspouts:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "db432f0d-553f-49cd-9e34-95df3f183d17",
                                "recommendation": "<p>Loose or missing clamps</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6a6c3f82-c282-409e-b9db-06ffb7701469",
                                "recommendation": "<p>Missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1143f581-f646-4d5b-932f-c33b49a0e79d",
                                "recommendation": "<p>Leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "07f440e8-34ce-4387-b96c-1201fca49eb9",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Aluminum",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Boxed",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Galvanized",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Splash blocks",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Plastic",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steel",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "cf062cda-c677-434c-a29d-c258734f42f3"
                            }
                        ]
                    },
                    {
                        "order": 18,
                        prompt: "Leader/Extension:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d1730ddf-a369-4ddd-8157-1ebbb3da098f",
                                "recommendation": "<p>Damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b6aca880-497c-4810-9db6-c1a811dbc49b",
                                "recommendation": "<p>Not properly draining/clogged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "dc56a4cd-7113-442b-9bff-a905ebb4f94e",
                                "recommendation": "<p>Leaders do not extend 6 feet beyond the foundation perimeter</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a6d70f21-a1fc-4d7f-b534-ec8c5fcae712",
                                "recommendation": "<p>Splash block(s) missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2c77ff0d-8a52-46d0-958c-6570b0ee49f1",
                                "recommendation": "<p>Extend runoff drains to move water away from foundation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6e263c01-95f4-4924-afd5-2753e40ddbb2",
                                "recommendation": "<p>Splash block(s) not properly installed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4bdfc0da-2626-4c65-95a3-3ca033bb4eb1",
                                "recommendation": "<p>Reconnect to downspout where pulling loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "689a5210-b99b-4180-839b-955ecbf9f042",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Loose",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Leaking",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Missing",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "6afdf824-654a-4521-9133-405dd4016bec"
                            }
                        ]
                    }
                ],
                "order": 1,
                "is_deleted": 0,
                "created_by": 686,
                "updated_by": 686,
                "created_at": "2022-11-08T18:05:40.000000Z",
                "updated_at": "2022-11-08T18:05:40.000000Z"
            },
            {
                uuid: "a0eb0a67-0ade-4eae-b6ea-1a62552e854e",
                prompt: "HVAC System",
                "introductory_text": null,
                lines: [
                    {
                        "order": 1,
                        prompt: "Heating System Operation:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7ba66519-f03a-466c-abee-6891b6025e3e",
                                "recommendation": "<p>Blower fan noisy</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a611213d-119c-4bb5-9b94-3cf2aa4db1ec",
                                "recommendation": "<p>Evidence of flue back drafting</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1cef279b-fc93-4a2c-aaf2-86b15c382aee",
                                "recommendation": "<p>Furnace existing beyond design life</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "58c9c680-27a5-489b-b521-54539b24ee6d",
                                "recommendation": "<p>Furnace nearing the end of it's design life</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "5383bd67-cbc2-429b-98b9-f1b3ccbd231b",
                                "recommendation": "<p>Gas leak detected</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0bd26580-7846-491f-925a-220ffe5ace71",
                                "recommendation": "<p>Heat exchanger rusting</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "29d8c0dc-bb3c-41c4-aba9-acc77cefa3fb",
                                "recommendation": "<p>Poor routing of flue pipe</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "141860ea-fb14-4973-a76b-556a03120879",
                                "recommendation": "<p>Service recommended</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1754f2ae-eadb-41ec-b48a-2c0de4c53ea0",
                                "recommendation": "<p>Suspected asbestos present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1c8d1b13-7c99-483a-8dd0-d2330e716e3a",
                                "recommendation": "<p>Flame roll out noted at the combustion chamber</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8c01bf02-5534-46ae-969d-2ea480a84344",
                                "recommendation": "<p>Cleaning recommended</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "657fb12f-abb3-40f7-a624-a22e94371788",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Adequate",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Inadequate",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Appears functional",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Recommend replacement",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "2c0fc58e-f8f8-4f8c-a5d1-acf43da8a2a9"
                            }
                        ]
                    },
                    {
                        "order": 2,
                        prompt: "Manufacturer:",
                        comments: [],
                        "line_uuid": "bd6691cf-f38a-41fc-8e00-67eee5f85fe3",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Borg Warner",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Carrier",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Trane",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "General Electric",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Unknown manufacturer",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Label not readable",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Goodman",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Janitrol",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Snyder General",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Arco-aire",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Comfortmaker",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Coleman Evcon",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tempstar",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Bryant",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Coleman",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "York",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Williamson",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fedders",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Bard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rybolt",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Dunkirk",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rheem",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Ruud",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "International",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Crysler-Airtemp",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Moncreif",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Johnson",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Payne",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Unitary Products",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Lennox",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Luxaire",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Allegiance",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "60c22546-8b3d-4d89-9fcc-583de836408b"
                            }
                        ]
                    },
                    {
                        "order": 3,
                        prompt: "Model Number:",
                        comments: [],
                        "line_uuid": "ec50a11a-cdbf-484a-84ea-1f65f399f95f",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [],
                                "line_input_control_uuid": "11f5e31c-59dd-490f-a6c3-cfe060a113d3"
                            },
                            {
                                "type": "Additional input",
                                prompt: "Serial Number:",
                                "values": [],
                                "line_input_control_uuid": "1939cf85-7aeb-495c-8445-9dcc9b1a5d33"
                            }
                        ]
                    },
                    {
                        "order": 4,
                        prompt: "Type:",
                        comments: [],
                        "line_uuid": "a1658a46-d471-4913-b0dd-d6b5837749a8",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Forced air",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Boiler system",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Gravity heat",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Convection baseboard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Radiant ceiling or panel",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wall recessed resistance",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Passive storage cells",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Active solar",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Geo-thermal",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "dfc7563c-77eb-436c-9322-3cde55d5ffb0"
                            },
                            {
                                "type": "Additional input",
                                prompt: "Capacity:",
                                "values": [
                                    {
                                        "value": "91,000 BTUHR",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "100,000 BTUHR",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "3b963a5d-fc2d-4440-85f8-df4051d5f904"
                            }
                        ]
                    },
                    {
                        "order": 5,
                        prompt: "Area Served:",
                        comments: [],
                        "line_uuid": "6bfd2dd6-b1c9-4eef-9685-5d65f74ef986",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Whole building",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "1st floor and basement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "2nd floor only",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "1st floor",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "cc1424c5-d0a2-470f-b548-a5b59d7b4fea"
                            },
                            {
                                "type": "Additional input",
                                prompt: "Approximate Age:",
                                "values": [
                                    {
                                        "value": "New",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "9273914d-eef1-4c6b-a2e0-6c03cf79fe63"
                            }
                        ]
                    },
                    {
                        "order": 6,
                        prompt: "Fuel Type:",
                        comments: [],
                        "line_uuid": "aae23698-9f74-4b8b-90f2-7c7a10d731ab",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Electric",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Natural gas",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Propane gas",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Coal",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Geo-thermal",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Solar",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "81b9c439-4e76-445a-8bb5-babffb8b51b2"
                            }
                        ]
                    },
                    {
                        "order": 7,
                        prompt: "Heat Exchanger:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a3a401c5-5ece-4fd0-8a0d-55fe6c7728a1",
                                "recommendation": "<p>Excessive rust</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9bddc4d5-1fc6-4da2-8ac2-c3bdc7c9afe4",
                                "recommendation": "<p>Recommend inspection by a qualified heating specialist</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "06bc3fed-31c2-4dd5-a3d8-37952a0ed5ec",
                                "recommendation": "<p>Rollout</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9af1c4d4-3778-45f0-8089-3088cefc02fd",
                                "recommendation": "<p>Carbon monoxide leakage detected</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cf82a9d7-cf66-45cd-b9c0-87cf7a4f3954",
                                "recommendation": "<p>Evidence of backdrafting</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "1e598432-23fa-4a3c-9112-6bf421735527",
                                "recommendation": "<p>Requires replacement</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2096fbfd-797f-405a-a1c9-b085688e44e4",
                                "recommendation": "<p>Severe rust</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "3dd413a0-a14d-42d9-a545-36bcaa13cda9",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "1 Burner",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "2 Burner",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "3 Burner",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "4 Burner",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "5 Burner",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "6 Burner",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "be544029-6f25-445d-a2f4-7727154fb290"
                            }
                        ]
                    },
                    {
                        "order": 8,
                        prompt: "Unable to Inspect:",
                        comments: [],
                        "line_uuid": "8bd9e8d4-7001-419e-98f8-402b4356c91e",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "10%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "15%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "20%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "25%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "30%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "40%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "45%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "50%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "55%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "60%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "65%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "70%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "75%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "80%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "85%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "90%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "95%",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "100%",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "9d8d33e6-23ed-4341-adeb-c4731973806c"
                            }
                        ]
                    },
                    {
                        "order": 9,
                        prompt: "Blower Fan/Filter:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "293b9d66-8905-44ad-a2f1-0a85150f4f02",
                                "recommendation": "<p>Blower fan makes noise</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ddf56639-67da-4bf6-a1b8-6393e33c465e",
                                "recommendation": "<p>Filter is undersized for unit</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "07bd723f-e2a0-4fd7-9215-c4334d86ba98",
                                "recommendation": "<p>Filter is extremely dirty</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8565dfb8-c91d-473f-9485-56225b6bf5b3",
                                "recommendation": "<p>Requires maintenance</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "50dd0b78-0a4c-44d7-9f8d-8f05ad66c7ac",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "60f2e7a1-ee9c-4fa6-9148-1d03fdd25eb4",
                                "recommendation": "<p>Loose belt</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7a81b7b1-f9ee-41d8-9550-f07b301d56ec",
                                "recommendation": "<p>Broken belt</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "d0d37060-e1ed-4995-8495-c37c681978da",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Belt drive with electronic filter",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Direct drive with reusable filter",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Belt drive with disposable filter",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Direct drive with disposable filter",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Direct drive with electronic filter",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Belt drive with reusable filter",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "54d6795a-3f6d-484b-adab-d39ce7992872"
                            }
                        ]
                    },
                    {
                        "order": 10,
                        prompt: "Distribution:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e0db1620-6856-49f2-895e-4bfd106ad75f",
                                "recommendation": "<p>Inadequate air flow</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "733d2e41-552a-4bae-b414-d298fe3053a0",
                                "recommendation": "<p>No air flow</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "537d8fc4-44d4-4e7c-a678-832068603188",
                                "recommendation": "<p>A qualified heating system contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "0098ab81-4fdb-45d0-8edb-7274a58eab94",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Hot water",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Steam",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Baseboard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Radiator",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Metal duct",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Insulflex duct",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "One pipe",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Two pipe",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "3a86d1e8-68df-474e-b3d6-7881eca74647"
                            }
                        ]
                    },
                    {
                        "order": 11,
                        prompt: "Circulator:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ce4605d4-5144-4cb5-8ab3-2611f5be157d",
                                "recommendation": "<p>Inadequate flow</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "63525c05-02d3-40e6-8976-bd98222e24bc",
                                "recommendation": "<p>No flow</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3156458d-3cf2-4e6f-88aa-c584ca84a207",
                                "recommendation": "<p>A qualified heating system contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "bfdd6396-766e-46a8-9090-eb7bcc6f1fee",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Pump",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Gravity",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "N/A",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "c2e438b2-6d9f-4a39-8633-ec3cb083cf71"
                            }
                        ]
                    },
                    {
                        "order": 12,
                        prompt: "Draft Control:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6c6fefe4-5e07-44da-8718-56fcba079110",
                                "recommendation": "<p>Inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "bb3ae9f1-31c9-4f6a-a616-32ce5a5bad03",
                                "recommendation": "<p>Missing handle</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "32911796-2cf8-49fc-8b6c-6d911bc5a4c3",
                                "recommendation": "<p>Frozen</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "03e57eb9-befb-4824-8dd4-14382254c34f",
                                "recommendation": "<p>Needs service</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "08a962ac-10a5-4fb2-a211-7d9254f2dc39",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Manual",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Automatic",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "8d9ed038-7ee3-4f4a-99e6-713b4e70fb81"
                            }
                        ]
                    },
                    {
                        "order": 13,
                        prompt: "Flue Pipe:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "eccdf6c2-5d2c-4c2d-be61-fb4c6eb37bbd",
                                "recommendation": "<p>Negative slope</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e7f2a781-0f5d-4a57-bff2-9fffbb5c6340",
                                "recommendation": "<p>Evidence of backdrafting</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "73959f80-b074-4234-9ffe-467929c5024c",
                                "recommendation": "<p>Rust holes</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "619fa952-f7a7-4a94-946e-930611bb152c",
                                "recommendation": "<p>Pipe does not meet minimum requirements for conditions</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "14f9f101-cc60-4231-84d7-f415e3092eff",
                                "recommendation": "<p>Unit venting into unlined chimney causing condensation and rusting exhaust piping and exchanger</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8242439a-6993-46c1-bf78-7751d1a60a58",
                                "recommendation": "<p>Pipe does not meet minimum pitch requirements</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "79f42c90-bb02-44f8-8ca0-2bf9548eaaec",
                                "recommendation": "<p>Install screws at exhaust vent piping fittings</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "12418a6b-9ef6-4154-b192-5c72e5ec833f",
                                "recommendation": "<p>Improper flue routing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6004f3b7-b635-416b-9848-4c76a31b958f",
                                "recommendation": "<p>Seal vent penetration into wall/chimney</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "df040e3c-5662-4b28-8397-27453244f86d",
                                "recommendation": "<p>Rust holes in piping-replace rusted sections</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "1ff4565d-cbca-4fdd-bbd1-49af758601d8",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Single wall",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Double wall",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "PVC",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "ae714f54-e054-4158-a862-08cb529cf9e2"
                            }
                        ]
                    },
                    {
                        "order": 14,
                        prompt: "Controls:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d59b4169-aa56-4453-bdf2-f975aec9374b",
                                "recommendation": "<p>Gauge inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4c6bf908-092a-49a1-b27f-512ed153aafc",
                                "recommendation": "<p>Pressure gauge is leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e6bde5f9-937f-4ad7-bda3-78591e86d490",
                                "recommendation": "<p>Moisture present in gauge</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b9dc5cb4-2c3d-475a-b089-4118629d0410",
                                "recommendation": "<p>Leakage noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "85f2b69e-8f5b-4c0e-a90b-7afbabdb42b2",
                                "recommendation": "<p>Expansion tank leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "a5c47f76-d598-4748-a3f7-432a3ea4520a",
                                "recommendation": "<p>Expansion tank rusting</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "7e546cd3-db1d-4d91-8324-0851339e4f60",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Relief valve",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Limit switch",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "5f184efd-a5d3-48b2-9c69-9575cebefe43"
                            }
                        ]
                    },
                    {
                        "order": 15,
                        prompt: "Devices:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "c20c119b-bf16-4b3a-99fb-dfb0403387dd",
                                "recommendation": "<p>Gauge inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "710daaa9-0fc7-4e0c-8c44-4176ff490255",
                                "recommendation": "<p>Pressure gauge is leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "82bca094-bcbb-49bf-96c2-78866a12fe60",
                                "recommendation": "<p>Moisture present in gauge</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e5325ac0-eb8f-4d7a-9cd5-cefa192f26e3",
                                "recommendation": "<p>Leakage noted</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "6d994dfb-9044-45cf-a210-64bb70828dd7",
                                "recommendation": "<p>Expansion tank leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d161f079-555f-46df-99b7-d484de2451d1",
                                "recommendation": "<p>Expansion tank rusting</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "ffed23c8-cea5-4d7d-80d4-b7896a407087",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Temp gauge",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Expansion tank",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "N/A",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Pressure gauge",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "5d6eb0e6-0aba-4c68-9058-91b54484f560"
                            }
                        ]
                    },
                    {
                        "order": 16,
                        prompt: "Humidifier:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7306f144-b34c-4b14-a1bf-b925eb8a1b0d",
                                "recommendation": "<p>Needs cleaning</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "447fbd26-cd26-4667-83f8-dab2d8d773af",
                                "recommendation": "<p>Requires service</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ba5d8d6f-a5bd-4297-9eeb-2aa4fef25ab7",
                                "recommendation": "<p>Motor inoperative</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "782534d4-ebe6-4391-a372-81652bc56758",
                                "recommendation": "<p>Disconnected at time of inspection</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "002dc5c7-0982-453c-93d0-4294f2d0a760",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "7896b06f-d7d4-4fe6-8efc-75068cc05a02",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "April-Aire",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Honeywell",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "f6e5d995-913a-4945-808f-89e852c9b765"
                            }
                        ]
                    },
                    {
                        "order": 17,
                        prompt: "Thermostats:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7d9843d9-afee-4427-aff3-f5887ca64671",
                                "recommendation": "<p>Loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8e34fd73-aa89-488b-8b6e-f7f351d66b92",
                                "recommendation": "<p>Not level</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "44213491-4b94-437a-b8f5-408d855c0842",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "6e2999dc-20ab-4f8c-bee1-c0fa7646db9d",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Individual",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Multi-zone",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Programmable",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "51ae8f4b-9d56-47ae-87eb-df1de55994bd"
                            }
                        ]
                    },
                    {
                        "order": 18,
                        prompt: "Fuel Tank:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cddf8795-c505-4303-85b3-529b3519abc6",
                                "recommendation": "<p>Abandoned with fuel remaining in tank</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2aa310a2-d19d-4447-a9cf-f3066d6fa570",
                                "recommendation": "<p>Leaking</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b0d4e8b3-12fd-41be-b187-c48cf4f29fd8",
                                "recommendation": "<p>Tank appears empty</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d543de7f-c255-4d0d-a2c9-6286512bd6b2",
                                "recommendation": "<p>Recommend removal by qualified contractor</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "f3fe7ed0-3836-4bc0-96bf-46d9f179a819",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Abandoned oil tank",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Abandoned propane tank",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Oil tank",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Propane tank",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "b6a1466b-79dd-4823-b68f-427601f73680"
                            }
                        ]
                    },
                    {
                        "order": 19,
                        prompt: "Tank Location:",
                        comments: [],
                        "line_uuid": "bfe5a478-db0b-4368-a9eb-e3213548e4d8",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Basement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rear yard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Side yard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Underground",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "bed36d47-3de7-4004-abf8-504b16964c2c"
                            }
                        ]
                    },
                    {
                        "order": 20,
                        prompt: "A/C System Operation:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7a1993e8-838a-4c62-8cab-ef303780e5d0",
                                "recommendation": "<p>The unit is currently in service beyond the manufactures stated design life</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3f33b791-ee75-4c1d-ac84-b66d723d6dab",
                                "recommendation": "<p>A qualified air conditioning contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4b48e6a0-4297-414d-9a02-1053ab9a1954",
                                "recommendation": "<p>Due to the season, the heat pump was tested in heating mode only. The cooling mode uses the same components in reverse cycle.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4b9c1104-e7ad-4d07-be69-55761322dff9",
                                "recommendation": "<p>To avoid possible compressor damage due to outside temperature below 60 degrees, the unit was not tested.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f635de86-e4ab-4292-be7b-c648ea3dc486",
                                "recommendation": "<p>Unit appeared to be operating normally.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "794798c8-8aff-4c98-ad94-7a464ba5890e",
                                "recommendation": "<p>Improper differential temperature</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3ab3a81f-63b8-4554-84f3-4552422632aa",
                                "recommendation": "<p>The unit is nearing the end of the manufactures stated design life</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "e36cd901-e873-4d07-8cc7-ccd76b2e7d56",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Appears serviceable",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Limited cooling",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Functional",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Inoperative",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not inspected",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "eb9ef706-9c98-49e7-9f3f-a5e6731b7459"
                            }
                        ]
                    },
                    {
                        "order": 21,
                        prompt: "Condensate Removal:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4e5167e7-4417-400f-893f-8cb804813e73",
                                "recommendation": "<p>Clogged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "ac613088-7840-49f1-80f3-006067a90423",
                                "recommendation": "<p>Crimped</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "76045cd7-9d3e-404c-80dc-fb754ac816a6",
                                "recommendation": "<p>Crushed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "f38bc279-5cce-4046-b044-14fffd2011df",
                                "recommendation": "<p>Negative slope</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "953423ca-b232-4a3f-8fd8-df6e8c1ece1c",
                                "recommendation": "<p>Pump motor has failed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "d52b7781-fa54-4ea1-a0ae-98525288cf3f",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "PVC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Plastic tubing",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rubber",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Electric pump",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "ab8ddd05-c99f-46b7-91b7-50ddabc6b027"
                            }
                        ]
                    },
                    {
                        "order": 22,
                        prompt: "Exterior Unit:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "4ccf77cd-7fd5-4b47-85ae-abe5708c1641",
                                "recommendation": "<p>Exterior box has excessive rust.</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "13db3dcc-257d-4f73-b0ae-55f53f6950e8",
                                "recommendation": "<p>Not properly supported</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "82491f62-ef4e-4ec6-913e-6b7bff4d2306",
                                "recommendation": "<p>Unit not level</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d5368493-dd8a-4f62-9045-dcaad63f4ed6",
                                "recommendation": "<p>Pad needs to be replaced</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "8595bbde-8904-4c9d-b888-7c97e84a4563",
                                "recommendation": "<p>Noisy operation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "b748e96b-f989-4953-8b2b-9babdf8daf37",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Pad mounted",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Suspended",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "ff05e40c-fff8-41cb-b009-0d08208d7af4"
                            }
                        ]
                    },
                    {
                        "order": 23,
                        prompt: "Manufacturer:",
                        comments: [],
                        "line_uuid": "c5fa5628-bd66-4e0f-b301-6939a6bed260",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Carrier",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Coleman",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Trane",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Unknown manufacturer",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Label not readable",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Goodman",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Janitrol",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Snyder General",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Arco-aire",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Comfortmaker",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Coleman Evcon",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tempstar",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Bryant",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "York",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Williamson",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fedders",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Bard",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Rybolt",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Johnson",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Payne",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Unitary Products",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Lennox",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Luxaire",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Allegiance",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Ruud",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "International",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Crysler-Airtemp",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Moncreif",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "295e27af-caac-4003-8662-a116c0d186f4"
                            }
                        ]
                    },
                    {
                        "order": 24,
                        prompt: "Model Number:",
                        comments: [],
                        "line_uuid": "1175c548-48ec-4bd4-a305-20634afba758",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [],
                                "line_input_control_uuid": "834d4255-2b7c-498a-ad0b-fafcf5d58c5b"
                            },
                            {
                                "type": "Additional input",
                                prompt: "Serial Number:",
                                "values": [],
                                "line_input_control_uuid": "b013c62a-cf43-40e3-a0c1-cdb5ed77c072"
                            }
                        ]
                    },
                    {
                        "order": 25,
                        prompt: "Area Served:",
                        comments: [],
                        "line_uuid": "c957ccf3-f2de-4214-bc2f-99ca14d8376f",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Whole building",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "1st floor and basement",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "2nd floor only",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "1st floor",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "f08d6c34-3d24-4d61-8369-fbfc29df9377"
                            },
                            {
                                "type": "Additional input",
                                prompt: "Approximate Age:",
                                "values": [
                                    {
                                        "value": "New",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "0cdaed0f-2aa1-43a7-9cec-a0dd86bf5aa4"
                            }
                        ]
                    },
                    {
                        "order": 26,
                        prompt: "Fuel Type:",
                        comments: [],
                        "line_uuid": "3ed87cd0-ca3b-4f79-8a16-e89cbc828212",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "220 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "220-240 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "110-120 VAC",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "120-240 VAC",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "d12de807-7155-4414-8d48-2cf7b3ae5331"
                            },
                            {
                                "type": "Additional input",
                                prompt: "Temperature Differential:",
                                "values": [],
                                "line_input_control_uuid": "ed5de121-78f7-4e5f-9a84-d6347c201ead"
                            }
                        ]
                    },
                    {
                        "order": 27,
                        prompt: "Type:",
                        comments: [],
                        "line_uuid": "95082d49-dd06-40a2-8b1d-147d7b164193",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Central A/C",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Heat pump",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Evaporative",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Wall mounted air conditioning unit",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "eeaeeae4-b953-45a0-9d7f-231765f9e217"
                            },
                            {
                                "type": "Additional input",
                                prompt: "Capacity:",
                                "values": [
                                    {
                                        "value": "2.5 Ton",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Not listed",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "1.5 Ton",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "5 Ton",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "3.5 Ton",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "2 Ton",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "3 Ton",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "4 Ton",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "e43ccfe5-a3f6-4169-a37d-9647886eb405"
                            }
                        ]
                    },
                    {
                        "order": 28,
                        prompt: "Visible Coil:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e129be74-996b-46bc-9967-d20ac2a54bd3",
                                "recommendation": "<p>Possible leakage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "724f1c1f-5236-4c9e-b4e3-7316bf0732aa",
                                "recommendation": "<p>Oil on coil</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "94653281-2b8c-4658-bb74-38416fe9826c",
                                "recommendation": "<p>Fins damaged</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d48bc8c0-4bfd-4a5a-bbfb-0e2649dabe4a",
                                "recommendation": "<p>A qualified air conditioning contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "5e50a610-d7e7-45eb-8d5a-455c336b2c8f",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Aluminum",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Copper core with aluminum fins",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "562cfd7c-4d3e-4ff9-ae2a-2a5074567998"
                            }
                        ]
                    },
                    {
                        "order": 29,
                        prompt: "Refrigerant Lines:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9f10b510-1aa7-49be-9329-24d7938f4511",
                                "recommendation": "<p>Torn insulation</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "60bd82f9-d069-4631-8c48-1e80efca847a",
                                "recommendation": "<p>Entry point not sealed</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "b41a5809-0ca8-41e8-948f-4d1a88b29e93",
                                "recommendation": "<p>Evidence of leakage</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0b6a6335-2a0b-4c9d-8ba2-758e32728767",
                                "recommendation": "<p>Cuts in exterior surface of line</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "44a1b5da-0b2c-4adc-9453-734f5db4def7",
                                "recommendation": "<p>Crimped</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "2a99f889-bf18-415c-a09c-6a87a7016005",
                                "recommendation": "<p>Lines covered by landscaping</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "0a9280c4-045f-4421-9cc0-63950c208099",
                                "recommendation": "<p>A qualified air conditioning contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "3df6aff6-38d9-42d2-9875-de1b3af7b056",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Serviceable condition",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "High pressure",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Liquid line",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Low pressure",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Low pressure and high pressure",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Suction line",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Suction line and liquid line",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "560d9c73-2489-4c86-8913-c966ca5879ca"
                            }
                        ]
                    },
                    {
                        "order": 30,
                        prompt: "Electrical Disconnect:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "52c4d9f4-2ca6-40a9-945c-7757d4d76fb4",
                                "recommendation": "<p>Evidence of water penetration in box</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "52d095a3-05b8-47c9-b7ce-672068978fe9",
                                "recommendation": "<p>Corrosion present on breaker</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e7524617-544a-4f92-9c33-fad6858657b5",
                                "recommendation": "<p>Recommend evaluation by a licensed electrician</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "4464a15f-a945-4ad6-897f-9310fcdd04b2",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Breaker disconnect",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "None",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Tumble switch",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Fused",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "9c713b9c-f414-407a-a4d6-6a935e6dc908"
                            }
                        ]
                    },
                    {
                        "order": 31,
                        prompt: "Suspected Asbestos:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e6e26562-1d80-4033-b686-73792277877e",
                                "recommendation": "<p>Friable</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "95b76912-5305-491b-9dda-30022bfbf2a3",
                                "recommendation": "<p>Lab testing of the suspected asbestos material is required to determine the presence of asbestos</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "9b72119e-526c-4a7f-82e0-113bf95ed70f",
                                "recommendation": "<p>In satisfactory condition at the time of inspection</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "fb5873e5-94d2-4447-b7e3-007917d229e4",
                                "recommendation": "<p>Recommend removal by a qualified asbestos contractor</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "80553d1a-3f7a-4ed8-8f02-cf9cc514803d",
                                "recommendation": "<p>Suspected asbestos present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "e8f653cb-f803-4f8c-bb47-1b24fde2b965",
                                "recommendation": "<p>Recommend inspection by a qualified asbestos contractor</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "d337c186-661d-4332-8478-109745c61efe",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Yes",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "No",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "af4ca933-c7c4-4c3b-a160-c98e56993d3d"
                            }
                        ]
                    },
                    {
                        "order": 32,
                        prompt: "Blower Fan/Filters:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "89d649f4-0b6f-40ab-87fd-8bc3ad823137",
                                "recommendation": "<p>Blower fan makes noise</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "935374eb-2894-4183-ac57-491ded63629a",
                                "recommendation": "<p>Filter is undersized for unit</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "56675ad0-d0d9-4e68-a630-f1380c28603d",
                                "recommendation": "<p>Filter is extremely dirty</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "7441fb64-c718-4a41-bd65-838dbfb3b8a8",
                                "recommendation": "<p>Requires maintenance</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3aa156ff-2be7-4e1f-b4ed-a7f267487138",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3a42a857-432f-45df-bf52-d31810b8b5b6",
                                "recommendation": "<p>Loose belt</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "d7fd7299-1d04-4d1d-be40-2e7ed11c8a25",
                                "recommendation": "<p>Broken belt</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "512fce04-a975-4278-a05b-f02ebc6b17f4",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Belt drive with electronic filter",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Direct drive with reusable filter",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Belt drive with disposable filter",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Direct drive with disposable filter",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Direct drive with electronic filter",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Belt drive with reusable filter",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "eeb9f750-b09e-48fb-a5db-edcbacb9af48"
                            }
                        ]
                    },
                    {
                        "order": 33,
                        prompt: "Exposed Ductwork:",
                        comments: [
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "cde6e953-602a-40ec-a3ee-20ef5af32039",
                                "recommendation": "<p>Loose</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "13c15793-b101-4da3-b7c8-cbc2f82a3172",
                                "recommendation": "<p>Cap missing</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "3d2a7aa5-19c5-42f6-b2a0-e44f26226a69",
                                "recommendation": "<p>Asbestos present</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            },
                            {
                                prompt: null,
                                "is_deleted": 0,
                                "is_selected": false,
                                "comment_uuid": "928fb958-b7c8-4bef-b9b4-22378717bc32",
                                "recommendation": "<p>A qualified contractor is recommended to evaluate and estimate repairs</p>",
                                "defaultRating_id": null,
                                "default_location": null,
                                "comment_input_controls": []
                            }
                        ],
                        "line_uuid": "10ccd5ed-ecb2-4ee0-b9dc-e7c679084b97",
                        "line_input_controls": [
                            {
                                "type": "Description",
                                prompt: "Description",
                                "values": [
                                    {
                                        "value": "Metal",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Insulated flex",
                                        "is_selected": false
                                    },
                                    {
                                        "value": "Metal ducts with corrugated cold air return",
                                        "is_selected": false
                                    }
                                ],
                                "line_input_control_uuid": "7cdd5a3b-f979-4f63-9cb9-aa26e1cfcf97"
                            }
                        ]
                    }
                ],
                "order": 1,
                "is_deleted": 0,
                "created_by": 686,
                "updated_by": 686,
                "created_at": "2022-11-08T18:05:40.000000Z",
                "updated_at": "2022-11-08T18:05:40.000000Z"
            }
        ]
    }
]