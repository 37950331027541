import Header from "../../Components/Header/Header";
import { motion } from "framer-motion";
import CollapsibleHeader from "../../Common/CollapseHeader/CollapsibleHeader";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  summeryItemCount,
  totalCommentCounter,
  totalLineCountCalulateForReview,
  totalMediaCount,
} from "../../Helpers/HelperFunctions";
import { Box } from "@mui/material";

const Review = () => {
  const [inspectionResponse, setInspectionResponse] = useState();
  const [pdfTemplate, setPdfTemplate] = useState([]);
  const [narrativeTemplate, setNarrativeTemplate] = useState([]);
  const [defaultRating, setDefaultRating] = useState([]);
  const [summeryRating, setSummeryRating] = useState([]);

  const storeData = useSelector((state) => state.CommonReducer);
  useEffect(() => {
    if (storeData.inspectionData) {
      setInspectionResponse(storeData?.inspectionData?.data);
    }
  }, [storeData?.inspectionData]);

  useEffect(() => {
    SummeryRatingData();
  }, [defaultRating]);

  useEffect(() => {
    DefaultRatingData();
    setPdfTemplate(
      inspectionResponse?.inspectiontemplates?.map(
        (template) => template.is_pdf_template
      )
    );
    setNarrativeTemplate(
      inspectionResponse?.inspectiontemplates?.map(
        (template) => template.is_narrative_template
      )
    );
  }, [storeData?.inspectionData, inspectionResponse?.inspectiontemplates]);

  const DefaultRatingData = () => {
    let temp = [];
    inspectionResponse?.inspectiontemplates
      ?.filter(
        (template) =>
          template?.is_pdf_template !== 1 &&
          template?.is_narrative_template !== 1
      )
      ?.map((template) => {
        temp?.push(...template?.rating_data);
      });
    const ratingName = temp?.map(({ Name }) => Name);
    const filteredRating = temp?.filter(
      ({ Name }, index) => !ratingName?.includes(Name, index + 1)
    );
    const sortedRating = filteredRating?.sort((a, b) => {
      return a?.Id - b?.Id;
    });
    setDefaultRating(sortedRating);
  };

  const SummeryRatingData = () => {
    const summeryData = defaultRating?.filter(
      (item) => item?.include_in_summary == 1
    );
    setSummeryRating(summeryData);
  };

  return (
    <motion.div
      className="h_100 w_bg"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <Header title="Review" />
      <Box className="review_pannel">
        {pdfTemplate?.every((element) => element == 1) ? (
          ""
        ) : (
          <CollapsibleHeader
            heading="Incomplete items"
            subHeading={`${
              totalLineCountCalulateForReview(
                inspectionResponse?.inspectiontemplates
              )?.overallLinesPresent -
              totalLineCountCalulateForReview(
                inspectionResponse?.inspectiontemplates
              )?.overallLinesCompleted
            } incomplete lines of ${
              totalLineCountCalulateForReview(
                inspectionResponse?.inspectiontemplates
              )?.overallLinesPresent
            } total`}
            inspectionResponse={inspectionResponse}
          />
        )}

        <CollapsibleHeader
          heading="Media"
          subHeading={`${
            totalMediaCount(inspectionResponse?.inspectiontemplates)
              ?.totalImageCount
          } photos, ${
            totalMediaCount(inspectionResponse?.inspectiontemplates)
              ?.totalVideoCount
          } videos`}
          inspectionResponse={inspectionResponse}
        />

        {pdfTemplate?.every((element) => element == 1) ? (
          ""
        ) : narrativeTemplate?.every((element) => element == 1 || element == undefined) ? (" ") : (
          <CollapsibleHeader
            heading="Comments"
            subHeading={`${totalCommentCounter(
              inspectionResponse?.inspectiontemplates
            )} comments`}
            inspectionResponse={inspectionResponse}
            defaultRating={defaultRating}
          />
        )}
        {pdfTemplate?.every((element) => element == 1) ? (
          ""
        ) : narrativeTemplate?.every((element) => element == 1 || element == undefined) ? (" ") : (
          <CollapsibleHeader
            heading="Summary items"
            subHeading={`${summeryItemCount(
              summeryRating,
              inspectionResponse?.inspectiontemplates
            )}`}
            inspectionResponse={inspectionResponse}
            summeryRating={summeryRating}
          />
        )}
      </Box>
    </motion.div>
  );
};

export default Review;
