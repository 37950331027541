import ReactPlayer from "react-player";
import CloseIcon from "@mui/icons-material/Close";
import "./_carousel.scss";
import React, { useRef, useState } from "react";
import { Box, Grid, IconButton, Modal, Typography } from "@mui/material";

const ImageCarousel = ({ mediaData, videoEditor, setVideoEditor, videolink, inspectionResponse, videoId, mediaName, mediaLineName }) => {
  const handleClose = () => {
    setVideoEditor(false);
  };

  return (
    <Modal
      keepMounted
      open={videoEditor}
      onClose={handleClose}
      className="carousel-modal"
      sx={{ backgroundColor: "rgb(25,25,25,0.8)" }}
    >
      <Box className="carousel-modal-layout">
        <Grid container sx={{ p: 2 }}>
          <Grid item xs={12}>
            <CloseIcon className="carousel-close-icon" onClick={handleClose} />
          </Grid>
          <Grid item xs={12}>

            {/* Video player section */}
            <Grid container>
              <Grid item xs={2} sx={{ verticalAlign: "middle" }}>
                <IconButton sx={{ float: "right !important" }}></IconButton>
              </Grid>
              <Grid item xs={8}>
                <div style={{ width: "100%", justifyContent: "center", display: "flex" }}>
                  <ReactPlayer controls height="65vh" width="fit-content" url={videolink} />
                </div>
              </Grid>
              <Grid item xs={2}>
                <IconButton></IconButton>
              </Grid>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-end"
                sx={{ position: "absolute", bottom: 0, left: 0 }}
              >
                <Typography variant="h6" sx={{ color: "#fff",marginLeft:"15px", marginBottom:"15px" }}>
                  {mediaLineName !== "" && mediaLineName !== undefined ? `${mediaName}: ${mediaLineName}` : mediaName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* Top left corner with the name */}

        </Grid>
      </Box>
    </Modal>
  );
};

export default ImageCarousel;
