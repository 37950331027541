import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import CallMissedOutgoingIcon from "@mui/icons-material/CallMissedOutgoing";
import parse from "html-react-parser";
import { useLocation, useNavigate } from "react-router-dom";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import {
  commentInputCounter,
  noRatingCounter,
  totalCommentCounter,
} from "../../Helpers/HelperFunctions";
import {
  activateLines,
  getInspectionData,
} from "../../Redux/Actions/CommonActions";
import { useDispatch } from "react-redux";
import FlagIcon from '@mui/icons-material/Flag';


function CommentReview({ inspectionResponse, inspectionData, defaultRating }) {
  const navigate = useNavigate();
  useEffect(() => { }, [inspectionResponse]);
  const dispatch = useDispatch();
  const activateLine = (uuid) => {
    dispatch(activateLines(uuid));
  };

  return (
    <Grid container className="coll_inner_div">
      <div className="coll_div right_padding">
        {inspectionResponse?.filter((pdf) => pdf?.is_pdf_template !== 1)?.map((template) =>
          <Grid container className="coll_inner_div" id={template?.template_id}>
            <span className="incomplete_temp">{template.name}</span>
            <div className="coll_div right_padding">
              <div className="card_wrapper">
                {template?.rating_data?.map((rating) => (
                  <>
                    <div className="coll_heading">
                      {commentInputCounter(rating, template)} {rating.Name}
                      <div className="div_header_rating rating_square_container"><span className="review_rating_square" style={{ background: `${rating.Color}` }}>{rating.include_in_summary == "1" && <FlagIcon />}</span></div>
                    </div>
                    {template?.is_narrative_template !== 1 && template?.categories?.map((category) => {
                      if (category?.prompt == "Inspection Details") return;
                      return category?.lines?.map((lines) => (
                        <>
                          {lines?.comments?.map((comment) =>
                            comment?.comment_input_controls
                              ?.filter((item) => item?.type === "Rating")[0]
                              ?.data?.map((el) =>
                                el?.Name === rating?.Name && comment?.is_selected ? (
                                  <>
                                    <div className="coll_content">
                                      <div>
                                        <p
                                          className="coll_content_text"
                                          onClick={() => {
                                            navigate(
                                              `/inspection/${category?.prompt !== null
                                                ? encodeURIComponent(
                                                  category.prompt
                                                )
                                                : "Untitled"
                                              }/${lines.prompt !== null
                                                ? encodeURIComponent(lines.prompt)
                                                : "Untitled"
                                              }`,
                                              {
                                                state: {
                                                  line: lines,
                                                  template_id: category?.template_id,
                                                  category_id: category?.id,
                                                  category_uuid: category?.uuid,
                                                  line_uuid: lines?.uuid,
                                                  isNarrative:template?.is_narrative_template === 1? true:false,
                                                  target: "comment",
                                                },
                                              }
                                            );
                                            activateLine(lines?.uuid);
                                          }}
                                        >
                                          {category.prompt === null
                                            ? "Untitled"
                                            : category.prompt}
                                          : {lines.prompt === null
                                            ? "Untitled"
                                            : lines.prompt}
                                        </p>
                                        <p
                                          className="coll_content_text"
                                          onClick={() => {
                                            navigate(
                                              `/inspection/${category?.prompt !== null
                                                ? encodeURIComponent(
                                                  category.prompt
                                                )
                                                : "Untitled"
                                              }/${lines.prompt !== null
                                                ? encodeURIComponent(lines.prompt)
                                                : "Untitled"
                                              }`,
                                              {
                                                state: {
                                                  line: lines,
                                                  template_id: category?.template_id,
                                                  category_id: category?.id,
                                                  category_uuid: category?.uuid,
                                                  line_uuid: lines?.uuid,
                                                  isNarrative:template?.is_narrative_template === 1? true:false,
                                                  target: "comment",
                                                },
                                              }
                                            );
                                            activateLine(lines?.uuid);
                                          }}
                                        >
                                          Comment:{"  "}
                                          {(comment?.recommendation === null ||
                                            comment?.recommendation?.replace(/<\/?[^>]+(>|$)/g, "") === "") &&
                                            (comment?.prompt === null ||
                                              comment?.prompt === "")
                                            ? "Untitled"
                                            : comment?.prompt === null ||
                                              comment?.prompt === ""
                                              ?
                                              //  parse(comment?.recommendation?.replace(/<\/?[^>]+(>|$)/g, ""))
                                              <div dangerouslySetInnerHTML={{ __html: comment?.recommendation }}></div>
                                              : comment.prompt}
                                        </p>
                                        <div className="review_image_container" style={{ display: "flex !important", flexFlow: "wrap !important" }}>
                                          {comment?.comment_input_controls?.map(
                                            (commentData) => {
                                              if (commentData?.type == "Media" && commentData?.is_disabled !==1)
                                                return commentData?.data?.map(
                                                  (media) => (
                                                    <div className="video_thumb_container">
                                                      {media.filetype === "image" ? "" : <PlayCircleIcon className="comment_play_icon" />}
                                                      <img
                                                        src={
                                                          media.filetype === "image"
                                                            ? `${inspectionData?.bucket_url}${media?.onlineUrl}`
                                                            : `${inspectionData?.bucket_url}${media?.thumbnail}`
                                                        }
                                                        onClick={() => {
                                                          navigate(
                                                            `/inspection/${category?.prompt !==
                                                              null
                                                              ? encodeURIComponent(
                                                                category.prompt
                                                              )
                                                              : "Untitled"
                                                            }/${lines.prompt !== null
                                                              ? encodeURIComponent(
                                                                lines.prompt
                                                              )
                                                              : "Untitled"
                                                            }`,
                                                            {
                                                              state: {
                                                                line: lines,
                                                                template_id:
                                                                  category?.template_id,
                                                                category_id:
                                                                  category?.id,
                                                                category_uuid:
                                                                  category?.uuid,
                                                                line_uuid:
                                                                  lines?.uuid,
                                                                  isNarrative:template?.is_narrative_template === 1? true:false,
                                                                target: "comment",
                                                              },
                                                            }
                                                          );
                                                          activateLine(lines?.uuid);
                                                        }}
                                                      />
                                                    </div>
                                                  )
                                                );
                                            }
                                          )}
                                        </div>
                                      </div>
                                      <div>
                                        <CallMissedOutgoingIcon
                                          className="updrop_icon"
                                          onClick={() => {
                                            navigate(
                                              `/inspection/${category?.prompt !== null
                                                ? encodeURIComponent(
                                                  category.prompt
                                                )
                                                : "Untitled"
                                              }/${lines.prompt !== null
                                                ? encodeURIComponent(lines.prompt)
                                                : "Untitled"
                                              }`,
                                              {
                                                state: {
                                                  line: lines,
                                                  template_id: category?.template_id,
                                                  category_id: category?.id,
                                                  category_uuid: category?.uuid,
                                                  line_uuid: lines?.uuid,
                                                  isNarrative:template?.is_narrative_template === 1? true:false,
                                                  target: "comment",
                                                },
                                              }
                                            );
                                            activateLine(lines?.uuid);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )
                              )
                          )}
                        </>
                      ));
                    })}
                  </>
                ))}
                <div className="coll_heading">
                  {noRatingCounter(template,template?.is_narrative_template === 1?true:false)} Unrated{" "}
                  <div className="div_header_rating"></div>
                </div>
                {template?.categories?.map((category) => {
                  if (category?.prompt == "Inspection Details") return;
                  return category?.lines?.map((lines) => (
                    <>
                      {lines?.comments?.map((comment) => {
                        if (comment?.is_selected) {
                          if (!comment?.comment_input_controls?.length) {
                            return (
                              <>
                                <div className="coll_content">
                                  <div>
                                    <p
                                      className="coll_content_text"
                                      onClick={() => {
                                        navigate(
                                          `/inspection/${category?.prompt !== null
                                            ? encodeURIComponent(category.prompt)
                                            : "Untitled"
                                          }/${lines.prompt !== null
                                            ? encodeURIComponent(lines.prompt)
                                            : "Untitled"
                                          }`,
                                          {
                                            state: {
                                              line: lines,
                                              template_id: category?.template_id,
                                              category_id: category?.id,
                                              category_uuid: category?.uuid,
                                              line_uuid: lines?.uuid,
                                              isNarrative:template?.is_narrative_template === 1? true:false,
                                              target: "comment",
                                            },
                                          }
                                        );
                                        activateLine(lines?.uuid);
                                      }}
                                    >
                                      {category.prompt === null
                                        ? "Untitled"
                                        : category.prompt}
                                      : {lines.prompt === null
                                        ? "Untitled"
                                        : lines.prompt}
                                    </p>
                                    <p
                                      className="coll_content_text"
                                      onClick={() => {
                                        navigate(
                                          `/inspection/${category?.prompt !== null
                                            ? encodeURIComponent(category.prompt)
                                            : "Untitled"
                                          }/${lines.prompt !== null
                                            ? encodeURIComponent(lines.prompt)
                                            : "Untitled"
                                          }`,
                                          {
                                            state: {
                                              line: lines,
                                              template_id: category?.template_id,
                                              category_id: category?.id,
                                              category_uuid: category?.uuid,
                                              line_uuid: lines?.uuid,
                                              isNarrative:template?.is_narrative_template === 1? true:false,
                                              target: "comment",
                                            },
                                          }
                                        );
                                        activateLine(lines?.uuid);
                                      }}
                                    >
                                      Comment:{"  "}
                                      {(comment?.recommendation === null ||
                                        comment?.recommendation?.replace(/<\/?[^>]+(>|$)/g, "") === "") &&
                                        (comment?.prompt === null ||
                                          comment?.prompt === "")
                                        ? "Untitled"
                                        : comment?.prompt === null ||
                                          comment?.prompt === ""
                                          ? 
                                          // parse(comment?.recommendation?.replace(/<\/?[^>]+(>|$)/g, ""))
                                          <div dangerouslySetInnerHTML={{ __html: comment?.recommendation }}></div>
                                          : comment.prompt}
                                    </p>
                                    <div className="review_image_container" style={{ display: "flex !important", flexFlow: "wrap !important" }}>
                                      {comment?.comment_input_controls?.map(
                                        (commentData) => {
                                          if (commentData?.type == "Media" && commentData?.is_disabled !==1)
                                            return commentData?.data?.map((media) => (
                                              <div className="video_thumb_container">
                                                {media.filetype === "image" ? "" : <PlayCircleIcon className="comment_play_icon" />}
                                                <img
                                                  src={
                                                    media.filetype === "image"
                                                      ? `${inspectionData?.bucket_url}${media?.onlineUrl}`
                                                      : `${inspectionData?.bucket_url}${media?.thumbnail}`
                                                  }
                                                  onClick={() => {
                                                    navigate(
                                                      `/inspection/${category?.prompt !==
                                                        null
                                                        ? encodeURIComponent(
                                                          category.prompt
                                                        )
                                                        : "Untitled"
                                                      }/${lines.prompt !== null
                                                        ? encodeURIComponent(
                                                          lines.prompt
                                                        )
                                                        : "Untitled"
                                                      }`,
                                                      {
                                                        state: {
                                                          line: lines,
                                                          template_id:
                                                            category?.template_id,
                                                          category_id:
                                                            category?.id,
                                                          category_uuid:
                                                            category?.uuid,
                                                          line_uuid:
                                                            lines?.uuid,
                                                            isNarrative:template?.is_narrative_template === 1? true:false,
                                                          target: "comment",
                                                        },
                                                      }
                                                    );
                                                    activateLine(lines?.uuid);
                                                  }}
                                                />
                                              </div>
                                            ));
                                        }
                                      )}
                                    </div>
                                  </div>
                                  <div>
                                    <CallMissedOutgoingIcon
                                      className="updrop_icon"
                                      onClick={() => {
                                        navigate(
                                          `/inspection/${category?.prompt !== null
                                            ? encodeURIComponent(category.prompt)
                                            : "Untitled"
                                          }/${lines.prompt !== null
                                            ? encodeURIComponent(lines.prompt)
                                            : "Untitled"
                                          }`,
                                          {
                                            state: {
                                              line: lines,
                                              template_id: category?.template_id,
                                              category_id: category?.id,
                                              category_uuid: category?.uuid,
                                              line_uuid: lines?.uuid,
                                              isNarrative:template?.is_narrative_template === 1? true:false,
                                              target: "comment",
                                            },
                                          }
                                        );
                                        activateLine(lines?.uuid);
                                      }}
                                    />
                                  </div>
                                </div>
                              </>
                            );
                          }
                          if (
                            ((comment?.comment_input_controls?.filter(
                              (item) => item?.type === "Rating"
                            )[0]?.data?.length === 0 ||
                              comment?.comment_input_controls?.filter(
                                (item) => item?.type === "Rating"
                              )[0]?.data?.length === undefined) &&
                              comment?.comment_input_controls?.filter(
                                (item) => item?.type === "Media"
                              )[0]?.data?.length !== 0) ||
                            ((comment?.comment_input_controls?.filter(
                              (item) => item?.type === "Rating"
                            )[0]?.data?.length === 0 ||
                              comment?.comment_input_controls?.filter(
                                (item) => item?.type === "Rating"
                              )[0]?.data?.length === undefined) &&
                              comment?.comment_input_controls?.filter(
                                (item) => item?.type === "Media"
                              )[0]?.data?.length === 0)
                          ) {
                            return (
                              <div className="coll_content">
                                <div>
                                  <p
                                    className="coll_content_text"
                                    onClick={() => {
                                      navigate(
                                        `/inspection/${category?.prompt !== null
                                          ? encodeURIComponent(category.prompt)
                                          : "Untitled"
                                        }/${lines.prompt !== null
                                          ? encodeURIComponent(lines.prompt)
                                          : "Untitled"
                                        }`,
                                        {
                                          state: {
                                            line: lines,
                                            template_id: category?.template_id,
                                            category_id: category?.id,
                                            category_uuid: category?.uuid,
                                            line_uuid: lines?.uuid,
                                            isNarrative:template?.is_narrative_template === 1? true:false,
                                            target: "comment",
                                          },
                                        }
                                      );
                                      activateLine(lines?.uuid);
                                    }}
                                  >
                                    {category.prompt === null
                                      ? "Untitled"
                                      : category.prompt}
                                    : {lines.prompt === null
                                      ? "Untitled"
                                      : lines.prompt}
                                  </p>
                                  <p
                                    className="coll_content_text"
                                    onClick={() => {
                                      navigate(
                                        `/inspection/${category?.prompt !== null
                                          ? encodeURIComponent(category.prompt)
                                          : "Untitled"
                                        }/${lines.prompt !== null
                                          ? encodeURIComponent(lines.prompt)
                                          : "Untitled"
                                        }`,
                                        {
                                          state: {
                                            line: lines,
                                            template_id: category?.template_id,
                                            category_id: category?.id,
                                            category_uuid: category?.uuid,
                                            line_uuid: lines?.uuid,
                                            isNarrative:template?.is_narrative_template === 1? true:false,
                                            target: "comment",
                                          },
                                        }
                                      );
                                      activateLine(lines?.uuid);
                                    }}
                                  >
                                    Comment:{"  "}
                                    {(comment?.recommendation === null ||
                                      comment?.recommendation?.replace(/<\/?[^>]+(>|$)/g, "") === "") &&
                                      (comment?.prompt === null ||
                                        comment?.prompt === "")
                                      ? "Untitled"
                                      : comment?.prompt === null ||
                                        comment?.prompt === ""
                                        ? 
                                        // parse(comment?.recommendation?.replace(/<\/?[^>]+(>|$)/g, ""))
                                        <div dangerouslySetInnerHTML={{ __html: comment?.recommendation }}></div>
                                        : comment.prompt}
                                  </p>
                                  <div className="review_image_container" style={{ display: "flex !important", flexFlow: "wrap !important" }}>
                                    {comment?.comment_input_controls?.map(
                                      (commentData) => {
                                        if (commentData?.type == "Media" && commentData?.is_disabled !==1)
                                          return commentData?.data?.map((media) => (
                                            <div className="video_thumb_container" >
                                              {media.filetype === "image" ? "" : <PlayCircleIcon className="comment_play_icon" />}
                                              <img
                                                src={
                                                  media.filetype === "image"
                                                    ? `${inspectionData?.bucket_url}${media?.onlineUrl}`
                                                    : `${inspectionData?.bucket_url}${media?.thumbnail}`
                                                }
                                                onClick={() => {
                                                  navigate(
                                                    `/inspection/${category?.prompt !==
                                                      null
                                                      ? encodeURIComponent(
                                                        category.prompt
                                                      )
                                                      : "Untitled"
                                                    }/${lines.prompt !== null
                                                      ? encodeURIComponent(
                                                        lines.prompt
                                                      )
                                                      : "Untitled"
                                                    }`,
                                                    {
                                                      state: {
                                                        line: lines,
                                                        template_id:
                                                          category?.template_id,
                                                        category_id:
                                                          category?.id,
                                                        category_uuid:
                                                          category?.uuid,
                                                        line_uuid:
                                                          lines?.uuid,
                                                          isNarrative:template?.is_narrative_template === 1? true:false,
                                                        target: "comment",
                                                      },
                                                    }
                                                  );
                                                  activateLine(lines?.uuid);
                                                }}
                                              />
                                            </div>
                                          ));
                                      }
                                    )}
                                  </div>
                                </div>
                                <div>
                                  <CallMissedOutgoingIcon
                                    className="updrop_icon"
                                    onClick={() => {
                                      navigate(
                                        `/inspection/${category?.prompt !== null
                                          ? encodeURIComponent(category?.prompt)
                                          : "Untitled"
                                        }/${lines.prompt !== null
                                          ? encodeURIComponent(lines?.prompt)
                                          : "Untitled"
                                        }`,
                                        {
                                          state: {
                                            line: lines,
                                            template_id: category?.template_id,
                                            category_id: category?.id,
                                            category_uuid: category?.uuid,
                                            line_uuid: lines?.uuid,
                                            isNarrative:template?.is_narrative_template === 1? true:false,
                                            target: "comment",
                                          },
                                        }
                                      );
                                      activateLine(lines?.uuid);
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          }
                        }
                      })}
                    </>
                  ));
                })}
              </div>
            </div>
          </Grid>
        )
        }
      </div>
    </Grid>
  );
}

export default CommentReview;
