import { Grid, FormControl, OutlinedInput, InputAdornment, IconButton, Divider } from '@mui/material'
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from 'react';
import AddLocationModal from '../../Lines/AddLocationModal';
const Location = ({ data, title, action, templateId, categoryId, lineUuid, type, commentUuid, commentIndex }) => {
  const [location, setLocation] = useState('');
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [addNewLocationModal, setAddNewLocationModal] = useState(false)

  useEffect(() => {
    setLocation(data !== null && data !== undefined && data.data !== null && data.data.length !== 0 ? data.data : '')
  }, [data])

  return (
    <>
      {showLocationModal && <AddLocationModal showLocationModal={showLocationModal} setShowLocationModal={setShowLocationModal} data={data}
        templateId={templateId} categoryId={categoryId} lineUuid={lineUuid} type={type} commentUuid={commentUuid} title={title} addNewLocationModal={addNewLocationModal} setAddNewLocationModal={setAddNewLocationModal} />}
     {data?.is_disabled != 1 ? <Grid container className={`${action === "line" ? 'container' : 'inner_container'}`} >
        <Grid item xs={12} className="line_detail_des_title">
          <span className={`${action === "line" ? 'details_title' : 'inner_details_title'}`}>{data != undefined ? data?.prompt : title}</span>
        </Grid>
        <FormControl sx={{ m: 1, width: '100%', margin: '0' }} variant="outlined">
          <OutlinedInput
            // disabled
            id={`${action === "comment" ? `edit_comment_location_${commentIndex + 1}` : "edit_location"}`}
            className='location-input'
            size="small"
            type="text"
            fullWidth
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "black",
              },
            }}
            value={location}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  id={`${action === "comment" ? `edit_comment_location_icon_${commentIndex + 1}` : "edit_location_icon"}`}
                  disableRipple
                  aria-label="toggle password visibility"
                  onClick={() => setShowLocationModal(true)}
                  edge="end"
                >
                  {<EditIcon sx={{ color: "#252525 !important" }} />} <span className='edit-text'></span>
                </IconButton>
              </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
            placeholder="Add a descriptive location"
            onClick={() => setShowLocationModal(true)}
          />

        </FormControl>
        {/* <Divider style={{width:"100%",marginTop:'30px'}}/> */}
      </Grid> :""}
    </>
  )
}

export default Location