import React, { useEffect, useState } from "react";
import Froalaeditor from "froala-editor";
import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/css/plugins/image.min.css";
import "froala-editor/js/plugins/word_paste.min.js";
import uuid from "react-uuid";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import "./FroalaEditorStyles.scss";
import BulbIcon from "../../../Assets/Images/bulbIcon.png";
import { decodeEntities } from "../../Components/LineDetails/CommentDetails/CommentLibraryHelper";

const FroalaEditorComponent = (props) => {
  const { editor, id, onChange, editorData, onBlur, minHeight, maxHeight, commentData, copy } = props;
  const [state, setState] = useState({
    open: false,
    selectedDropdown: null,
    newOption: "",
    hoverOption: null,
    textfield: false,
    editedOption: null,
    dialogOptionList: [],
    dropdownList: [],
    openHelp: false,
  });
  const { openHelp, open, dropdownList, selectedDropdown, newOption, hoverOption, textfield, editedOption, dialogOptionList } = state;

  const handleDelete = (event, option) => {
    event.stopPropagation();
    let options = dialogOptionList || [];
    let newDropdownOptions = options.filter((obj) => obj !== option);
    setState((prevState) => ({
      ...prevState,
      dialogOptionList: newDropdownOptions,
      textfield: false,
    }));
  };

  const handleEdit = (event, option) => {
    event.stopPropagation();
    setState((prevState) => ({
      ...prevState,
      newOption: option,
      editedOption: option,
    }));
  };

  const handleEditSave = (event, option) => {
    event.stopPropagation();
    let options = dialogOptionList || [];
    const optionIndex = options.findIndex((obj) => obj === option);
    options[optionIndex] = newOption;
    setState((prevState) => ({
      ...prevState,
      dialogOptionList: options,
      editedOption: null,
      newOption: "",
    }));
  };

  const handleAdd = (event) => {
    event.stopPropagation();
    let newDropdownOptions = dialogOptionList || [];
    if (newOption.trim() !== "" && !newDropdownOptions.includes(newOption)) {
      newDropdownOptions.push(newOption);
    }
    setState((prevState) => ({
      ...prevState,
      dialogOptionList: newDropdownOptions,
      newOption: "",
      textfield: false,
    }));
  };

  useEffect(() => {
    const editorInstance = editor?.current?.editor;
    let editorContent = editorInstance?.html?.get();
    const replacePlaceholdersWithDropdowns = () => {
      const dropdowns = id === "intro-text-editor" ? commentData?.introductory_text_dropdowns : id === "commentText" ? commentData?.comment_dropdowns : commentData?.summary_comment_dropdowns;
      // Regex to match [dropdown UUID] within a span tag
      const regexSpanDropdown = /<span contenteditable="false">\[dropdown [a-f0-9\-]+\]<\/span>/g;
      // Regex to match [dropdown UUID] without a span tag
      const regexPlainDropdown = /\[dropdown [a-f0-9\-]+\]/g;
      if (Array?.isArray(dropdowns) && dropdowns?.length > 0) {
      dropdowns?.forEach((dropdown) => {
        const placeholder = `\\[dropdown ${dropdown.dropdown_uuid}\\]`;
        const regexSpan = new RegExp(`<span contenteditable="false">${placeholder}<\/span>`, "g");
        const regexPlain = new RegExp(placeholder, "g");
        const selectElement = document.createElement("select");
        const option = document.createElement("option");
        option.text = "-------------";
        option.value = "";
        selectElement.appendChild(option);
        if (Array?.isArray(dropdown?.dropdowns_values?.values)) {
          dropdown?.dropdowns_values?.values?.forEach((value) => {
            const option = document.createElement("option");
            if (value?.length > 40) option.text = `${value?.substring(0, 40)}...`;
            else option.text = value || "";
            option.value = value || "";
            option.title = value || "";
            selectElement.appendChild(option);
            if (dropdown?.selected_value && dropdown?.selected_value === value) {
              option.setAttribute("selected", dropdown);
            }
          });
        } else if (Array?.isArray(dropdown?.dropdowns_values)) {
          dropdown?.dropdowns_values?.forEach((value) => {
            const option = document.createElement("option");
            if (value?.length > 40) option.text = `${value?.substring(0, 40)}...`;
            else option.text = value || "";
            option.value = value || "";
            option.title = value || "";
            selectElement.appendChild(option);
            if (dropdown?.selected_value && dropdown?.selected_value === value) {
              option.setAttribute("selected", dropdown);
            }
          });
        }
        selectElement.classList.add(`${id === "intro-text-editor" ? "intro" : id === "commentText" ? "comment" : "summary"}-cl-dropdown`);
        selectElement.setAttribute("id", `${dropdown.dropdown_uuid}`);
        editorData.innerHTML = editorData.innerHTML.replace(regexSpan, selectElement.outerHTML).replace(regexPlain, selectElement.outerHTML);
      });} else {
        const outputString = editorData?.innerHTML?.replace(regexPlainDropdown, (match, selectId) => {
          return `<select id="${selectId}" class="${id === "intro-text-editor" ? "intro" : id === "commentText" ? "comment" : "summary"}-cl-dropdown"><option>--------------</option></select>`;
        });
        editorData.innerHTML = outputString;
      }
      return decodeEntities(editorData.innerHTML?.replace(regexSpanDropdown, "").replace(regexPlainDropdown, ""));
    };

    setTimeout(() => {
      if ((!editorContent || editorContent === undefined || copy) && editorInstance && editorData?.innerHTML) {
        const newHtmlData = replacePlaceholdersWithDropdowns();
        editorInstance?.html?.set(commentData?.comment_dropdowns?.length || commentData?.summary_comment_dropdowns?.length ? newHtmlData : editorData?.innerHTML);
      }
    }, 100);
  }, [editorData, editor, commentData, id]);

  const handleModelChange = (model) => {
    onChange(id, model);
  };

  let isHandlingImage = false;

  const insertImageToEditor = (base64String) => {
    const editorInstance = editor.current.editor;
    const cursorPosition = editorInstance.selection.get();
    if (cursorPosition) editorInstance.image.insert(base64String, true);
    isHandlingImage = false;
  };

  const handleImageBeforeUpload = (images) => {
    if (images.length && !isHandlingImage) {
      isHandlingImage = true;
      const reader = new FileReader();
      reader.onload = (e) => {
        insertImageToEditor(e.target.result);
      };
      reader.readAsDataURL(images[0]);
    }
    return false;
  };

  const handlePasteBefore = (e) => {
    if (isHandlingImage) return;
    const clipboardData = e.clipboardData || window.clipboardData;
    const items = clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") !== -1) {
        isHandlingImage = true;
        const blob = items[i].getAsFile();
        if (blob) {
          const reader = new FileReader();
          reader.onload = (event) => {
            insertImageToEditor(event.target.result);
          };
          reader.readAsDataURL(blob);
        }
      } else if (items[i].type.indexOf("text/html") !== -1) {
        items[i].getAsString((html) => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(html, "text/html");
          const images = doc.querySelectorAll("img");
          images.forEach((img) => {
            const src = img.src;
            if (src.startsWith("data:image")) {
              insertImageToEditor(src);
            }
          });
        });
      }
    }
  };

  const handleOpenDialog = (event) => {
    event.stopPropagation();
    let newButtonId = event.target.id.replace("myButton", "");
    if (event.target.tagName === "BUTTON") {
      const dropdown = dropdownList.find((obj) => obj?.dropdown_uuid === newButtonId);
      setState((prevState) => ({
        ...prevState,
        dialogOptionList: dropdown?.dropdowns_values || [],
        open: true,
        selectedDropdown: newButtonId,
      }));
    }
  };

  useEffect(() => {
    const editorInstance = editor.current?.editor;
    if (editorInstance) {
      const contentArea = editorInstance.$el.get(0);
      contentArea.addEventListener("click", handleOpenDialog);
      return () => contentArea.removeEventListener("click", handleOpenDialog);
    }
  }, [editorData]);

  const btnclick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    let newDropdownList = [...dropdownList];
    const editorInstance = editor.current.editor;
    const cursorPosition = editorInstance.selection.get();
    const newSelectUuid = uuid();
    if (cursorPosition) {
      editorInstance.html.insert(
        `<span contenteditable="false" id="[dropdown ${newSelectUuid}]">
            <select id="${newSelectUuid}"
                style="width: 96px;
                background-color: #f1f9fe;
                height: 24px;
                padding: 4px 6px 0px 6px;
                border-radius: 4px 0px 0px 4px;
                border: 1px solid #0F75BC;
               " >
                <option disabled selected value="-------"></option>
            </select>
            <button
                style="
                background-color: #f1f9fe;
                width: 24px;
                height: 24px;
                padding: 0px;
                border-radius: 0px 4px 4px 0px;
                border: 1px solid #0F75BC;
                top: -1.5px;
                position: relative;
                margin-left: -4px;"
                contenteditable="false"
                id="myButton${newSelectUuid}" name="${newSelectUuid}" type="button"
                onclick="handleOpenDialog(event, '${newSelectUuid}');"
            >
              +
            </button>
        </span>
    `,
        true
      );
    }
    newDropdownList.push({
      dropdown_uuid: newSelectUuid,
      name: `dropdown ${newSelectUuid}`,
      selected_value: "",
      dropdowns_values: [],
    });
    setState((prevState) => ({ ...prevState, dropdownList: newDropdownList }));
    setTimeout(() => {
      const button = editor.current.editor.$el.find(`#myButton${newSelectUuid}`);
      button.on("click", function (event) {
        const customEvent = new CustomEvent("openDialog", { detail: { id: newSelectUuid } });
        document.dispatchEvent(customEvent);
      });
    }, 0);
  };

  const handleSave = () => {
    const index = dropdownList.findIndex((obj) => obj?.dropdown_uuid === selectedDropdown);
    const editorInstance = editor.current.editor;
    let editorContent = editorInstance.html.get();
    const optionElements = dialogOptionList.map((option) => `<option>${option}</option>`);
    const optionsString = optionElements.join("");
    const regex = new RegExp(`<select id="${selectedDropdown}"[\\s\\S]*?<\\/select>`, "g");
    const newSelectHtml = `<select id="${selectedDropdown}" style="width: 96px;
        background-color: #f1f9fe;
        height: 24px;
        padding: 4px 6px 0px 6px;
        border-radius: 4px 0px 0px 4px;
        border: 1px solid #0F75BC;
     "><option disabled selected value="-------"></option>
        ${optionsString} </select>`;
    const replacedContent = editorContent.replace(regex, newSelectHtml);
    editorInstance.html.set(replacedContent);
    let newDropdownOptions = dropdownList;
    if (newDropdownOptions.length === 0) {
      newDropdownOptions.push({ dropdown_uuid: selectedDropdown, selected_value: "", dropdowns_values: dialogOptionList });
    } else {
      newDropdownOptions[index] = {
        ...newDropdownOptions[index],
        dropdowns_values: dialogOptionList,
      };
    }
    setState((prevState) => ({ ...prevState, open: false, dialogOptionList: [], dropdownList: newDropdownOptions }));
  };

  useEffect(() => {
    document.addEventListener("openDialog", handleOpenDialog);
    return () => {
      document.removeEventListener("openDialog", handleOpenDialog);
    };
  }, []);

  const handleCloseDialog = () => {
    setState((prevState) => ({ ...prevState, open: false, dialogOptionList: [], textfield: false, editedOption: null }));
  };
  const openHelpDialog = () => {
    setState((prevState) => ({ ...prevState, openHelp: !openHelp }));
  };

  Froalaeditor.DefineIcon("aiHelper", { SRC: BulbIcon, ALT: "Image button", height: 50, width: "50px", template: "image" });
  Froalaeditor.RegisterCommand("aiHelper", {
    title: "AI helper",
    focus: false,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
      openHelpDialog();
    },
  });
  const handleFocus = () => {
    setState((prevState) => ({ ...prevState, isFocused: true }));
  };

  const handleBlur = () => {
    setState((prevState) => ({ ...prevState, isFocused: false }));
    const editorInstance = editor.current?.editor;
    const editorContent = editorInstance?.html?.get();
    if (onBlur) {
      setTimeout(() => {
        onBlur(id, editorContent);
      }, 100);
    }
  };

  return (
    <div id={id}>
      <FroalaEditor
        id={id}
        ref={editor}
        value={editorData}
        onModelChange={handleModelChange}
        config={{
          key: "1CC3kA6C7A5A3B3A3E3cWHNGGDTCWHId1Eb1Oc1Yh1b2Ld1POkE3D3F3C7A4A4A4G3H3B1==",
          placeholderText: " ",
          charCounterCount: false,
          quickInsertEnabled: false,
          dragInline: true,
          spellCheck: true,
          imageInsertButtons: ["imageUpload"],
          imageEditButtons: ["imageAlign"],
          videoInsertButtons: ["videoByURL", "videoUpload"],
          videoEditButtons: ["videoAlign"],
          heightMin: minHeight,
          heightMax: maxHeight,
          toolbarButtons: [
            "bold",
            "italic",
            "underline",
            "strikeThrough",
            "subscript",
            "superscript",
            "|",
            "fontfamily",
            "fontSize",
            "fontColor",
            // "paragraphStyle",
            "|",
            "paragraphFormat",
            "align",
            "formatOL",
            "formatUL",
            "outdent",
            "indent",
            // "quote",
            // "|",
            // "insertLink",
            // "insertImage",
            // "insertVideo",
            // "insertTable",
            "|",
            // "emoticons",
            // "specialCharacters",
            // "insertHR",
            "selectAll",
            "clearFormatting",
            "|",
            // "print",
            "undo",
            "redo",
            // "aiHelper",
          ],
          pluginsEnabled: ["wordPaste", "spellChecker", "codeBeautifier", "fontFamily", "fontSize", "colors", "align", "lists"],
          events: {
            "image.beforeUpload": handleImageBeforeUpload,
            "paste.before": handlePasteBefore,
            focus: handleFocus,
            blur: handleBlur,
          },
        }}
      />
      {/* This is required for future implementation
      <Dialog open={openHelp} onClose={() => openHelpDialog()}>
        <DialogContent>hi</DialogContent>
      </Dialog> */}
      <Dialog
        open={open}
        sx={{
          "& .MuiDialog-paper": {
            height: "300px",
            width: "350px",
            borderRadius: "7px",
          },
        }}
        onClose={() => handleCloseDialog()}
        maxWidth="md"
      >
        <DialogTitle>Dialog</DialogTitle>
        <DialogContent>
          {textfield ? (
            <Grid container display={"flex"} alignItems={"center"} mb={1} gap={1.4}>
              <Grid item xs={9}>
                <TextField
                  fullWidth={true}
                  value={newOption}
                  size="small"
                  onChange={(e) =>
                    setState((prevState) => ({
                      ...prevState,
                      newOption: e.target.value,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={1} display={"flex"} justifyContent={"center"}>
                <IconButton onClick={(event) => handleAdd(event)}>
                  <DoneOutlinedIcon sx={{ color: "green" }} />
                </IconButton>
              </Grid>
              <Grid item xs={1} display={"flex"} justifyContent={"center"}>
                <IconButton
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      textfield: false,
                      newOption: "",
                    }))
                  }
                >
                  <CloseOutlinedIcon sx={{ color: "red" }} />
                </IconButton>
              </Grid>
            </Grid>
          ) : null}
          {dialogOptionList.map((option, index) => (
            <Box
              key={index}
              onMouseEnter={() =>
                setState((prevState) => ({
                  ...prevState,
                  hoverOption: option,
                }))
              }
              onMouseLeave={() => setState((prevState) => ({ ...prevState, hoverOption: null }))}
            >
              <Grid
                container
                sx={{
                  backgroundColor: hoverOption === option ? "#f1f9fe" : "",
                  paddingY: "10px",
                  paddingX: "20px",
                }}
              >
                {editedOption === option ? (
                  <Grid container display={"flex"} alignItems={"center"} mb={1} gap={1}>
                    <Grid item xs={9}>
                      <TextField
                        fullWidth={true}
                        value={newOption}
                        size="small"
                        onChange={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setState((prevState) => ({
                            ...prevState,
                            newOption: e.target.value,
                          }));
                        }}
                      />
                    </Grid>
                    <Grid item xs={1} display={"flex"} justifyContent={"center"}>
                      <IconButton onClick={(event) => handleEditSave(event, option)}>
                        <DoneOutlinedIcon sx={{ color: "green" }} />
                      </IconButton>
                    </Grid>
                    <Grid item xs={1} display={"flex"} justifyContent={"center"}>
                      <IconButton
                        onClick={() =>
                          setState((prevState) => ({
                            ...prevState,
                            editedOption: null,
                            newOption: "",
                          }))
                        }
                      >
                        <CloseOutlinedIcon sx={{ color: "red" }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={9} display={"flex"} alignItems={"center"}>
                      <Typography>{option}</Typography>
                    </Grid>
                    {hoverOption === option && (
                      <>
                        <Grid item xs={1.5} display={"flex"} alignItems={"center"}>
                          <IconButton
                            sx={{
                              padding: "4px",
                              borderRadius: "2px",
                              "&:hover": { borderRadius: "2px" },
                            }}
                            onClick={(event) => handleEdit(event, option)}
                          >
                            <EditIcon
                              sx={{
                                fontSize: "16px",
                                "&:hover": { color: "black" },
                              }}
                            />
                          </IconButton>
                        </Grid>
                        <Grid item xs={1.5} display={"flex"} alignItems={"center"}>
                          <IconButton
                            sx={{
                              padding: "4px",
                              borderRadius: "2px",
                              "&:hover": { borderRadius: "2px" },
                            }}
                            onClick={(event) => handleDelete(event, option)}
                          >
                            <DeleteIcon
                              sx={{
                                fontSize: "16px",
                                "&:hover": { color: "red" },
                              }}
                            />
                          </IconButton>
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </Grid>
            </Box>
          ))}
          <Button
            fullWidth
            id="add_new_client"
            sx={{
              marginTop: "10px",
              padding: "0px",
              display: "flex",
              justifyContent: "flex-start",
              gap: "8px",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "24px",
              textAlign: "left",
              textTransform: "none",
              color: "#4282E2",
              "&:hover": { backgroundColor: "transparent" },
            }}
            onClick={() => setState((prevState) => ({ ...prevState, textfield: true }))}
          >
            <AddCircleOutlineOutlinedIcon sx={{ fontSize: "18px" }} />
            Add option
          </Button>
        </DialogContent>
        <DialogActions>
          <Grid display={"flex"} justifyContent={"center"} gap={2}>
            <Button variant="contained" onClick={() => handleSave()}>
              Save
            </Button>
            <Button onClick={() => handleCloseDialog()}>Cancel</Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FroalaEditorComponent;