import Router from "./Router/Router";
import { Toaster } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "./Redux/Store/Store";
const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <Toaster position="bottom-left" />
      <div className="app_container">
        <div className="main__container">
          <Toaster position="bottom-left" />
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </div>
      </div>
    </Provider>
  );
}
export default App;
