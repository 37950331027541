// file imports *****************************************************************************
import { useEffect, useState } from "react";
import { ROUTES } from "../Config/App-constants";
import { Routes as Switch, Route, useLocation } from "react-router-dom";
import { Grid, Box, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Base64 } from "js-base64";
import { clearInspectionDataError, getInspectionData, staticBackground } from "../Redux/Actions/CommonActions";
import LineDetails from "../Pages/LineDetails/LineDetails";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import isn_favicon from '../../Assets/Images/isn_favicon.ico';
import palmtech_favicon from '../../Assets/Images/favicon.ico';

//Component imports *************************************************************************
import Dashboard from "../Pages/Dashboard/Dashboard";
import Review from "../Pages/Review/Review";
import Inspection from "../Pages/Inspection/Inspection";
import Lines from "../Pages/Lines/Lines";
import SideMenu from "../Components/SideMenu/SideMenu";
import Preloader from "../Helpers/Preloader";
import PageNotFound from "../Common/Alert/PageNotFound";
import ReportLibrary from "../Pages/ReportLibrary/ReportLibrary";
import PinturaImageEditor from "../Components/LineDetails/PinturaEditor";

const Router = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.CommonReducer);
  const matches = useMediaQuery("(max-width:900px)");
  const [onDragtoggle, setOnDragToggle] = useState(false); //Drag toggle
  const [selectedMediaFiles, setSelectedMediaFiles] = useState([]) //RML selected files
  const [selectedImages, setSelectedImages] = useState([]); // Array of selected image indices
  const location = useLocation()
  const loadInspectionData = (guid) => {
    let sessionAssociateCompanyKey = Base64.decode(sessionStorage.getItem('AC'));
    let sessionUserId = Base64.decode(sessionStorage.getItem('UI'));
    dispatch(getInspectionData({ guid: guid, associate_company: sessionAssociateCompanyKey, user_id: sessionUserId })); //line test
  };


  useEffect(() => {
    if (sessionStorage.getItem("guid") === null || sessionStorage.getItem("guid") === "null") {
      dispatch(staticBackground(true))
      let urlParam = searchParams.get("q");
      let decodedParam = Base64.decode(urlParam);
      let decodedParamAarray = decodedParam.split('&')
      let parameters = {};
      decodedParamAarray.forEach(item => {
        let keyValue = item.split('=')
        return parameters[keyValue[0]] = keyValue[1];
      })
      sessionStorage.setItem("guid", parameters.guid);
      sessionStorage.setItem("AC", Base64.encode(parameters.associate_company));
      sessionStorage.setItem("CK", Base64.encode(parameters.company_key));
      sessionStorage.setItem("asoCom", parameters.associate_company);
      sessionStorage.setItem("comKey", parameters.company_key);
      sessionStorage.setItem("SK", Base64.encode(parameters.secret_key));
      sessionStorage.setItem("UI", Base64.encode(parameters.user_id));
      searchParams.delete("q");
      setSearchParams(searchParams);
    }
    loadInspectionData(sessionStorage.getItem("guid"));
  }, [])

  useEffect(() => {
    if (storeData.inspectionDataError) {
      setLoader(false)
      toast.error(storeData?.inspectionDataError?.response?.data?.message)
      dispatch(clearInspectionDataError())
    }
  }, [storeData.inspectionDataError])

  useEffect(() => {
    if (!storeData.inspectionData) setLoader(true)
    else {
      setLoader(false)
    }
  }, [storeData.inspectionData])

  useEffect(() => {
    if (storeData?.inspectionData?.data?.favicon) {
      const timestamp = Date.now();
      const randomValue = Math.random().toString(36).substring(7);
      const faviconUrl = `${storeData?.inspectionData?.data?.favicon}?t=${timestamp}_${randomValue}`;
      const link = document.querySelector("link[rel~='icon']") || document.createElement('link');
      link.rel = 'icon';
      link.href = faviconUrl;
      const headElement = document.querySelector('head');
      const existingFavicon = document.querySelector('link[rel="icon"]');
      if (existingFavicon) {
        headElement.removeChild(existingFavicon);
      }
      headElement.appendChild(link);
    }
  }, [storeData?.inspectionData?.data?.favicon]); // Empty dependency array ensures that this effect runs only once


  // calling initiate wb-rw api after updating ISN template
  useEffect(() => {
    if (storeData?.isnTempateUpdate && storeData?.isnTempateUpdate === 'success') {
      loadInspectionData(sessionStorage.getItem("guid"));
    }
  }, [storeData?.isnTempateUpdate])

  useEffect(() => {
    if (storeData.templateUpdateLoader && storeData.templateUpdateLoader === true) setLoader(true)
    else {
      setLoader(false)
    }
  }, [storeData.templateUpdateLoader])

  useEffect(() => {
    if (storeData?.loader) setLoader(true)
    else
      setLoader(false)
  }, [storeData?.loader])

  useEffect(() => {
    if (storeData?.inspectionData?.data?.user_email !== undefined) {
      window.pendo.initialize({
        visitor: {
          id: storeData?.inspectionData?.data?.user_id !== null && storeData?.inspectionData?.data?.user_id !== undefined ? storeData?.inspectionData?.data?.user_id : '',
          Name: storeData?.inspectionData?.data?.user_displayname,
          Address: `${storeData?.inspectionData?.data?.location_address !== null && storeData?.inspectionData?.data?.location_address !== undefined ? storeData?.inspectionData?.data?.location_address : ''}${storeData?.inspectionData?.data?.location_address_2 !== null && storeData?.inspectionData?.data?.location_address_2 !== undefined ? `, ${storeData?.inspectionData?.data?.location_address_2}` : ''}`,
          Email: storeData?.inspectionData?.data?.user_email,
          PropertyCity: storeData?.inspectionData?.data?.location_city !== null && storeData?.inspectionData?.data?.location_city !== undefined ? storeData?.inspectionData?.data?.location_city : '',
          PropertyState: storeData?.inspectionData?.data?.location_state !== null && storeData?.inspectionData?.data?.location_state !== undefined ? storeData?.inspectionData?.data?.location_state : '',
          PropertyZip: storeData?.inspectionData?.data?.location_zip !== null && storeData?.inspectionData?.data?.location_zip !== undefined ? storeData?.inspectionData?.data?.location_zip : '',
          Role: storeData?.inspectionData?.data?.role_id !== null && storeData?.inspectionData?.data?.role_id !== undefined ? storeData?.inspectionData?.data?.role_id === 5 ? "Owner" : storeData?.inspectionData?.data?.role_id === 6 ? "Admin" : storeData?.inspectionData?.data?.role_id === 7 ? "Inspector" : "" : "",
        },
        account: {
          id: Base64.decode(sessionStorage.getItem('CK')),
          ParentCompany: sessionStorage.getItem('asoCom'),
          CompanyName: storeData?.inspectionData?.data?.company?.company_name,
          SubscriptionType: storeData?.inspectionData?.data?.company?.subscription_type,
          SubcriptionActive: storeData?.inspectionData?.data?.company?.is_subscription_active === 1 ? "Yes" : "No",
          SubscriptionStart: storeData?.inspectionData?.data?.company?.subscription_start_date,
        }
      });
    }
  }, [storeData])

  return (
    <>
      <Preloader showPreloader={loader} />
      <Box sx={{ flexGrow: 1 }} className="page-background">
        <Grid container spacing={5}  >
          {location.pathname != "/pinturaeditor" &&
            <Grid item xs={12} sm={12} md={3.5} >
              <SideMenu />
            </Grid>}
          <Grid item xs={12} sm={12} md={8.5} className={matches ? "mobile_page_layout" : "page__layout"}>
            <br />
            <Switch>
              {/* public routes **********************************************************/}
              <Route path={ROUTES.HOME} exact element={<Dashboard />} />
              <Route path={ROUTES.REVIEW} exact element={<Review />} />
              <Route path={ROUTES.INSPECTION} exact element={<Inspection />} />
              <Route path={ROUTES.LINE} exact element={<Lines onDragtoggle={onDragtoggle} setOnDragToggle={setOnDragToggle} selectedImages={selectedImages} setSelectedImages={setSelectedImages} selectedMediaFiles={selectedMediaFiles} setSelectedMediaFiles={setSelectedMediaFiles}/>} />
              <Route path={ROUTES.LINE_DETAILS} exact element={<LineDetails onDragtoggle={onDragtoggle} setOnDragToggle={setOnDragToggle} selectedImages={selectedImages} setSelectedImages={setSelectedImages} selectedMediaFiles={selectedMediaFiles} setSelectedMediaFiles={setSelectedMediaFiles} />} />
              <Route path={ROUTES.REPORT_MEDIA_LIBRARY} exact element={<ReportLibrary />} />
              <Route path={ROUTES.PINTURAEDITOR} exact element={<PinturaImageEditor />} />
              <Route path={ROUTES.FALLBACK} exact element={<PageNotFound type="404" message="Page not found"
                action="Redirect" />} />
            </Switch>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Router;
