import React, { useEffect, useState } from "react";
import "@pqina/pintura/pintura.css";
import "./_imageEdit.scss"
import {
  createMarkupEditorToolbar,
  getEditorDefaults,
  plugin_crop_locale_en_gb,
  plugin_finetune_locale_en_gb,
  plugin_annotate_locale_en_gb,
  locale_en_gb,
  plugin_redact_locale_en_gb,
  markup_editor_locale_en_gb,
  plugin_finetune_defaults,
  setPlugins,
  plugin_crop,
  plugin_finetune,
  plugin_annotate,
  createMarkupEditorToolStyles,
  createMarkupEditorToolStyle,
  createDefaultColorOptions,
} from "@pqina/pintura";
import { PinturaEditor } from "@pqina/react-pintura";
import { useLocation, useNavigate } from "react-router-dom";
import { GetUTCTime } from "../../Helpers/GetUTCTime";
import {
  filterObjectsUptoLine,
  filterObjectsUptoTemplate,
  getImageDimensions,
  resizeImage,
} from "../../Helpers/HelperFunctions";
import {
  UpdateMedia,
  UpdateTemplateDataSpecific,
  UpdateTemplateDataSpecificWithLoader,
} from "../../Helpers/ApiCall";
import { useDispatch, useSelector } from "react-redux";
import { Box, Modal } from "@mui/material";
import Compressor from "compressorjs";
const editorConfig = getEditorDefaults();
setPlugins(plugin_crop, plugin_finetune, plugin_annotate);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
};
export default function PinturaImageEditor() {
  const [pinturaOpen, setPinturaOpen] = React.useState(true);
  const [categoryData, setCategoryData] = useState();
  const [pdfCategoryData, setPdfCategoryData] = useState();
  const [lineData, setLineData] = useState();

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.CommonReducer);
  const {
    imageUrl,
    image,
    inspectionResponse,
    isPdf,
    element,
    templateId,
    categoryId,
    categoryUuid,
    lineUuid,
    commentUuid,
    action,
    isNarrative,
    addCommentFromLine,
  } = location.state;
  const handleClose = () => {
    if(isPdf == 0){
    if (action == "comment") {
      navigate(
        `/inspection/${
          categoryData?.prompt !== null
            ? encodeURIComponent(categoryData?.prompt)
            : "Untitled"
        }/${
          lineData.prompt !== null
            ? encodeURIComponent(lineData.prompt)
            : "Untitled"
        }`,
        {
          replace: true,
          state: {
            template_id: templateId,
            category_id: categoryId,
            category_uuid: categoryUuid,
            line_uuid: lineUuid,
            isNarrative: isNarrative,
            commentUuid: commentUuid,
            target: "comment",
            add_comment_from_line: addCommentFromLine,
          },
        }
      );
    } else if (action == "line") {
      navigate(
        `/inspection/${
          categoryData?.prompt !== null
            ? encodeURIComponent(categoryData?.prompt)
            : "Untitled"
        }/${
          lineData.prompt !== null
            ? encodeURIComponent(lineData.prompt)
            : "Untitled"
        }`,
        {
          replace: true,
          state: {
            template_id: templateId,
            category_id: categoryId,
            category_uuid: categoryUuid,
            line_uuid: lineUuid,
            isNarrative: false,
            target: "line",
            add_comment_from_line: false,
          },
        }
      );
    } else {
      navigate(-1);
    }}
    else if(isPdf == 1){
      navigate(
        `/inspection/${
          pdfCategoryData?.prompt !== null
            ? encodeURIComponent(pdfCategoryData?.prompt)
            : "Untitled"
        }`,
        {
          replace: true,
          state: {
            template_id: templateId,
            category_id: categoryId,
            category_uuid: pdfCategoryData?.uuid,
            pdfcategory:pdfCategoryData,
            pdfcategoryType:pdfCategoryData.type,
            element: element,
            element_uuid: element?.uuid,
            isPDF: "1",
            action:"pdfElement",
            target:"pdf",
            isNarrative: false,
          },
        }
      );
      // navigate(-1)
    }else{
      navigate(-1)
    }
    setPinturaOpen(false);
    // navigate(-1)
  };

  async function compressImage(file) {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.6,
        success(result) {
          resolve(result);
        },
        error(err) {
          reject(err);
        },
      });
    });
  }

  async function compressImage(file) {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.6,
        success(result) {
          resolve(result);
        },
        error(err) {
          reject(err);
        },
      });
    });
  }
  const handleDoneSubmit = async (ImageObject, imageState) => {
    let file = ImageObject;
    if (isPdf == 0) {
      let formData = new FormData();
      let imgObj;
      let compressedFile;
      let thumbImage;
      const { imgWidth, imgHeight } = await getImageDimensions(ImageObject);
      const maxWidth = 320;
      const maxHeight = 320;
      const quality = 0.8;
      let newWidth, newHeight;
      if (imgWidth > imgHeight) {
        // Landscape orientation
        newHeight = maxHeight;
        newWidth = (maxHeight / imgHeight) * imgWidth;
      } else {
        // Portrait orientation
        newWidth = maxWidth;
        newHeight = (maxWidth / imgWidth) * imgHeight;
      }
      let thumbnailImage = await resizeImage(
        file,
        newWidth,
        newHeight,
        quality
      );
      thumbImage = new File([thumbnailImage], `${thumbnailImage.name}`, {
        type: "image/jpeg",
      });
      if (file?.size > 512000) {
        try {
          const compressedtemporaryFile = await compressImage(file);
          compressedFile = new File(
            [compressedtemporaryFile],
            `${compressedtemporaryFile.name}`,
            { type: "image/jpeg" }
          );
        } catch (error) {
          console.error("Error compressing image:", error);
        }
      } else {
        compressedFile = ImageObject;
      }
      imgObj = {
        id: image.id,
        url: null,
        name: compressedFile.name,
        size: compressedFile.size,
        type: compressedFile.type,
        edited: true,
        newUrl: null,
        online: "online",
        filetype: "image",
        localUrl: null,
        OriginUrl: image.onlineUrl,
        editCount: Number(image.editCount) + 1,
        onlineUrl: null,
        orginName: image.originName,
        thumbnail: null,
        is_deleted: 0,
        order: image?.order,
        updated_at: await GetUTCTime(),
        description: image?.description,
        OriginlocalUrl: null,
        isOriginUploaded: true,
      };
      let imageObjectString = JSON.stringify(imgObj);
      let params = {};
      // when Editing comment media Image
      if (action === "comment") {
        params = {
          image: compressedFile,
          video_thumbnail: thumbImage,
          inspection_guid: sessionStorage.getItem("guid"),
          template_id: templateId,
          category_id: categoryUuid,
          line_id: lineUuid,
          comment_id: commentUuid,
          img_object: imageObjectString,
          img_type: "comment",
        };
      }
      // when editing line media Image
      else {
        params = {
          image: compressedFile,
          video_thumbnail: thumbImage,
          inspection_guid: sessionStorage.getItem("guid"),
          template_id: templateId,
          category_id: categoryUuid,
          line_id: lineUuid,
          img_object: imageObjectString,
          img_type: "details",
        };
      }
      Object.keys(params).forEach((key) => formData.append(key, params[key]));
      let prevData = inspectionResponse?.inspectionData?.data;
      let templateIndex =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates?.findIndex(
          (itm) => itm.template_id === templateId
        );
      let categoryIndex =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories?.findIndex((itm) => itm.id === categoryId);
      let lineIndex =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates[
          templateIndex
        ]?.categories[categoryIndex]?.lines?.findIndex(
          (line) => line.uuid === lineUuid
        );
      // api call for updating image for line details
      if (action === "line") {
        let lineInputIndex =
          inspectionResponse?.inspectionData?.data?.inspectiontemplates[
            templateIndex
          ]?.categories[categoryIndex]?.lines[
            lineIndex
          ]?.line_input_controls?.findIndex(
            (lineInput) => lineInput.type === "Media"
          );
        let imageIndex =
          inspectionResponse?.inspectionData?.data?.inspectiontemplates[
            templateIndex
          ]?.categories[categoryIndex]?.lines[lineIndex]?.line_input_controls[
            lineInputIndex
          ]?.data.findIndex((img) => img.id === image.id);
        if (lineInputIndex !== -1) {
          prevData.inspectiontemplates[templateIndex].categories[
            categoryIndex
          ].lines[lineIndex].line_input_controls[lineInputIndex].updated_at =
            await GetUTCTime();
          prevData.inspectiontemplates[templateIndex].categories[
            categoryIndex
          ].lines[lineIndex].line_input_controls[lineInputIndex].data[
            imageIndex
          ] = imgObj;
        }
      }
      // api call for updating image for comment
      else if (action === "comment") {
        let commentIndex =
          inspectionResponse?.inspectionData?.data?.inspectiontemplates[
            templateIndex
          ]?.categories[categoryIndex]?.lines[lineIndex]?.comments?.findIndex(
            (itm) => itm.uuid == commentUuid
          );
        let mediaIndex =
          inspectionResponse?.inspectionData?.data?.inspectiontemplates[
            templateIndex
          ]?.categories[categoryIndex]?.lines[lineIndex]?.comments[
            commentIndex
          ]?.comment_input_controls?.findIndex((itm) => itm.type == "Media");
        let imageIndex =
          inspectionResponse?.inspectionData?.data?.inspectiontemplates[
            templateIndex
          ]?.categories[categoryIndex]?.lines[lineIndex]?.comments[
            commentIndex
          ]?.comment_input_controls[mediaIndex]?.data.findIndex(
            (img) => img.id === image.id
          );
        if (commentIndex !== -1) {
          prevData.inspectiontemplates[templateIndex].categories[
            categoryIndex
          ].lines[lineIndex].comments[commentIndex].comment_input_controls[
            mediaIndex
          ].updated_at = await GetUTCTime();
          prevData.inspectiontemplates[templateIndex].categories[
            categoryIndex
          ].lines[lineIndex].comments[commentIndex].comment_input_controls[
            mediaIndex
          ].data[imageIndex] = imgObj;
        }
      }
      let filteredObject = filterObjectsUptoLine(
        prevData,
        templateIndex,
        categoryIndex,
        lineIndex
      );
      UpdateTemplateDataSpecific(dispatch, prevData, filteredObject);
      setTimeout(function () {
        UpdateMedia(dispatch, formData);
      }, 3000);
    } else {
      let templateIndex =
        inspectionResponse?.inspectionData?.data?.inspectiontemplates?.findIndex(
          (itm) => itm.template_id === templateId
        );
      let prevData = inspectionResponse?.inspectionData?.data;
      let pdfTemplate = prevData?.inspectiontemplates[templateIndex];
      let templateGuid = pdfTemplate?.template_guid;
      let mergeId = element?.merge_id;
      let ElementpdfData = pdfTemplate?.pdf_data?.filter(
        (item) => item?.merge_id == mergeId
      );
      let newPrevData = JSON?.parse(
        JSON?.stringify(inspectionResponse?.inspectionData?.data)
      );
      let newPdfTemplate = newPrevData?.inspectiontemplates[templateIndex];
      let formData = new FormData();
      let imgObj;
      let compressedFile;
      let thumbImage;
      const { imgWidth, imgHeight } = await getImageDimensions(ImageObject);
      const maxWidth = 320;
      const maxHeight = 320;
      const quality = 0.8;
      let newWidth, newHeight;
      if (imgWidth > imgHeight) {
        // Landscape orientation
        newHeight = maxHeight;
        newWidth = (maxHeight / imgHeight) * imgWidth;
      } else {
        // Portrait orientation
        newWidth = maxWidth;
        newHeight = (maxWidth / imgWidth) * imgHeight;
      }
      let thumbnailImage = await resizeImage(
        file,
        newWidth,
        newHeight,
        quality
      );
      thumbImage = new File([thumbnailImage], `${thumbnailImage.name}`, {
        type: "image/jpeg",
      });
      if (file?.size > 512000) {
        try {
          const compressedtemporaryFile = await compressImage(file);
          compressedFile = new File(
            [compressedtemporaryFile],
            `${compressedtemporaryFile.name}`,
            { type: "image/jpeg" }
          );
        } catch (error) {
          console.error("Error compressing image:", error);
        }
      } else {
        compressedFile = ImageObject;
      }
      imgObj = {
        id: image.id,
        url: null,
        name: compressedFile.name,
        size: compressedFile.size,
        type: compressedFile.type,
        edited: true,
        newUrl: null,
        online: "online",
        filetype: "image",
        localUrl: null,
        OriginUrl: image.onlineUrl,
        editCount: Number(image.editCount) + 1,
        onlineUrl: null,
        orginName: image.originName,
        thumbnail: null,
        is_deleted: 0,
        order: image?.order,
        updated_at: await GetUTCTime(),
        description: image?.description,
        OriginlocalUrl: null,
        isOriginUploaded: true,
      };
      let imageObjectString = JSON.stringify(imgObj);
      let params = {};
      params = {
        type: element?.type,
        image: compressedFile,
        video_thumbnail: thumbImage,
        inspection_guid: sessionStorage.getItem("guid"),
        template_id: templateId,
        img_object: imageObjectString,
        template_guid: templateGuid,
        img_type: "element",
        merge_id: mergeId,
        is_pdf_template: 1,
        category_id: categoryId,
      };
      Object.keys(params).forEach((key) => formData.append(key, params[key]));
      let mediaObject = ElementpdfData?.[0]?.value;
      let selectedIndex = mediaObject?.findIndex(
        (media) => media?.id == image?.id
      );
      let newMediaData = mediaObject?.filter((item) => item.id == image?.id);
      mediaObject[selectedIndex] = {
        ...mediaObject[selectedIndex],
        updated_at: await GetUTCTime(),
      };
      const itemIndex = pdfTemplate?.pdf_data?.findIndex(
        (item) => item?.merge_id == mergeId
      );
      if (itemIndex != -1) {
        mediaObject[selectedIndex] = imgObj;
        mediaObject[selectedIndex] = newMediaData;
        newPdfTemplate.pdf_data[itemIndex] = {
          ...newPdfTemplate.pdf_data[itemIndex],
          updated_at: await GetUTCTime(),
        };
        pdfTemplate.pdf_data[itemIndex] = {
          ...pdfTemplate.pdf_data[itemIndex],
          updated_at: await GetUTCTime(),
        };
      }
      let filteredObject = filterObjectsUptoTemplate(prevData, templateIndex);
      UpdateTemplateDataSpecificWithLoader(
        dispatch,
        newPrevData,
        filteredObject
      );
      setTimeout(function () {
        UpdateMedia(dispatch, formData);
      }, 3000);
    }
    if(isPdf == 0){
      if (action === "comment") {
        navigate(
          `/inspection/${
            categoryData?.prompt !== null
              ? encodeURIComponent(categoryData?.prompt)
              : "Untitled"
          }/${
            lineData.prompt !== null
              ? encodeURIComponent(lineData.prompt)
              : "Untitled"
          }`,
          {
            replace: true,
            state: {
              template_id: templateId,
              category_id: categoryId,
              category_uuid: categoryUuid,
              line_uuid: lineUuid,
              isNarrative: isNarrative,
              commentUuid: commentUuid,
              target: "comment",
              add_comment_from_line: addCommentFromLine,
            },
          }
        );
      } else if (action === "line") {
        navigate(
          `/inspection/${
            categoryData?.prompt !== null
              ? encodeURIComponent(categoryData?.prompt)
              : "Untitled"
          }/${
            lineData.prompt !== null
              ? encodeURIComponent(lineData.prompt)
              : "Untitled"
          }`,
          {
            replace: true,
            state: {
              template_id: templateId,
              category_id: categoryId,
              category_uuid: categoryUuid,
              line_uuid: lineUuid,
              isNarrative: false,
              target: "line",
              add_comment_from_line: false,
            },
          }
        );
      } else {
        navigate(-1);
      }
    } else{
      navigate(
        `/inspection/${
          pdfCategoryData?.prompt !== null
            ? encodeURIComponent(pdfCategoryData?.prompt)
            : "Untitled"
        }`,
        {
          replace: true,
          state: {
            template_id: templateId,
            category_id: categoryId,
            category_uuid: pdfCategoryData?.uuid,
            pdfcategory:pdfCategoryData,
            pdfcategoryType:pdfCategoryData.type,
            element: element,
            element_uuid: element?.uuid,
            isPDF: "1",
            action:"pdfElement",
            target:"pdf",
            isNarrative: false,
          },
        }
      );
    }
  };
  useEffect(() => {
    setCategoryData(
      storeData?.inspectionData?.data?.inspectiontemplates
        ?.find((template) => template?.template_id === templateId)
        ?.categories?.find((category) => category?.id === categoryId)
    );
    setLineData(
      storeData?.inspectionData?.data?.inspectiontemplates
        ?.find((template) => template?.template_id === templateId)
        ?.categories?.find((category) => category?.id === categoryId)
        ?.lines?.find((line) => line?.uuid === lineUuid)
    );
    setPdfCategoryData(
      storeData?.inspectionData?.data?.inspectiontemplates
        ?.find((template) => template.template_id === templateId)
        ?.pdf_categories?.find((category) => category.id === categoryId)
    );
  }, []);
  return (
    <>
      <Modal
        open={pinturaOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <PinturaEditor
            {...editorConfig}
            src={imageUrl}
            enableToolbar={true}
            util={"annotate"}
            enableButtonClose={true}
            onClose={handleClose}
            utils={[
              "annotate",
              "trim",
              "finetune",
              "crop",
              "rotate",
              "flip",
              "flipRotate",
              "draw",
              "text",
              "redact",
            ]}
            locale={{
              ...locale_en_gb,
              ...plugin_crop_locale_en_gb,
              ...plugin_finetune_defaults,
              ...plugin_finetune_locale_en_gb,
              ...plugin_annotate_locale_en_gb,
              ...plugin_redact_locale_en_gb,
              ...markup_editor_locale_en_gb,
              labelButtonExport: "Save",
              redactLabel: "Blur",
            }}
            markupEditorToolbar={createMarkupEditorToolbar([
              "arrow",
              "rectangle",
              [
                "ellipse",
                "Circle",
                {
                  icon: '<path stroke-width=".125em" stroke="currentColor" d="M1.21 9.853a11.054 11.054 0 0 0 0 4.294m1.643 3.965a11.054 11.054 0 0 0 3.035 3.035m3.965 1.644a11.054 11.054 0 0 0 4.294 0m3.965-1.644a11.054 11.054 0 0 0 3.035-3.035m1.644-3.965a11.054 11.054 0 0 0 0-4.294m-1.644-3.965a11.054 11.054 0 0 0-3.035-3.035m-3.965-1.644a11.054 11.054 0 0 0-4.294 0M5.888 2.853a11.054 11.054 0 0 0-3.035 3.035"/>',
                },
              ],
              ["sharpie", "Pen"],
              "text",
              "eraser",
            ])}
            markupEditorToolStyles={createMarkupEditorToolStyles({
              // key, style
              rectangle: createMarkupEditorToolStyle("rectangle", {
                strokeColor: createDefaultColorOptions().red,
                strokeWidth: "1%",
              }),
              ellipse: createMarkupEditorToolStyle("ellipse", {
                strokeColor: createDefaultColorOptions().red,
                strokeWidth: "1%",
              }),
              sharpie: createMarkupEditorToolStyle("path", {
                strokeColor: createDefaultColorOptions().red,
                strokeWidth: "1%",
              }),
              arrow: createMarkupEditorToolStyle("line", {
                strokeColor: createDefaultColorOptions().red,
                lineEnd: "arrow",
                strokeWidth: "1%",
              }),
              text: createMarkupEditorToolStyle("text", {
                fontSize: "10%",
              }),
            })}
            onProcess={({ dest, imageState }) => {
              handleDoneSubmit(dest, imageState);
            }}
          />
        </Box>
      </Modal>
    </>
  );
}
