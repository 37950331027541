import CreativeEditorSDK from '@cesdk/cesdk-js';
import React, { useEffect, useRef, useState } from 'react';
// import 'https://cdn.img.ly/packages/imgly/cesdk-js/1.19.0/cesdk.umd.js';
import imagelink from '../../../Assets/Images/img2.png';
const licenseJson = require("./Media/pesdk_licence/pesdk_html5_license.json")

const CreativeEditorSDKComponent = ({image}) => {
  const cesdkContainer = useRef(null);
  /** @type {[import("@cesdk/cesdk-js").default, Function]} cesdk */
//   const [image, setImage] = useState();
console.log("image",image)
  useEffect(() => {
    const config = {
        license: 'Nz5dFR8lul0T0i1YJ2QlCpHK05L6gpG5cPN-o8cixP1kiV2Gj6vY1FvENucskBT0',
        userId: 'guides-user',
        baseURL: 'https://cdn.img.ly/packages/imgly/cesdk-js/1.19.0/assets',
        // docs-theming-theme
        theme: 'light', // 'light' or 'dark'
        ui: {
          scale: ({ containerWidth, isTouch }) => {
            if (containerWidth < 600 || isTouch) {
              return 'large';
            } else {
              return 'normal';
            }
          }, // or 'normal' or 'large'
          // docs-theming-theme
          // docs-theming-generator
          elements: {
            panels: {
              settings: true
            }
          }
          // docs-theming-generator
        },
        callbacks: { onUpload: 'local' } // Enable local uploads in Asset Library.
      };
    // const config = {
    // //   role: 'Adopter',
    //   theme: 'light',
    //   callbacks: {
    //     onExport: 'download',
    //     onUpload: 'local'
    //   },
    //   ui: {
    //     elements: {
    //       panels: {
    //         settings: true
    //       },
    //       navigation: {
    //         action: {
    //           export: {
    //             show: true,
    //             format: ['image/png', 'application/pdf']
    //           }
    //         }
    //       }
    //     }
    //   },
    //   license: 'vERESgSXbYj5Rs-FF4DzkMvhdQLh0Mxe6AD8V-doP6wqe_gmYmx_oUKqIlMkwpMu',
    //   userId: 'guides-user',
    // };
    let cesdk;
    if (image && cesdkContainer.current) {
      CreativeEditorSDK.create(cesdkContainer.current, config).then(
        async (instance) => {
          instance.addDefaultAssetSources();
          instance.addDemoAssetSources({ sceneMode: 'Design' });
          cesdk = instance;
          // Preselect the loaded Image
          await cesdk.createFromImage(imagelink);
          const blocks = cesdk.engine.block.findByType('image');
          if (blocks.length > 0) {
            cesdk.engine.block.setSelected(blocks[0], true);
          }
        }
      );
    }
    return () => {
      if (cesdk) {
        cesdk.dispose();
      }
    };
  }, [cesdkContainer, image]);

  return (
    <div className="gap-sm flex h-full w-full flex-row" style={{width: `${100}vw`, height: `${100}vh`,position:"fixed",left:"0",right:'0',top:'0',bottom:'0',zIndex:'2' }}>
     

     
        <div ref={cesdkContainer} style={cesdkStyle}></div>
   
    </div>
  );
};

const caseAssetPath = (path, caseId = 'start-with-image') =>
  `${window.location.protocol + "//" + window.location.host}/cases/${caseId}${path}`;
// https://unsplash.com/photos/ePpaQC2c1xA
// https://unsplash.com/photos/6qqwAsB22_M
// https://unsplash.com/photos/y-GMWtWW_H8
const IMAGE_URLS = [
  {
    full: caseAssetPath('../../../Assets/Images/img2.png'),
    thumb: caseAssetPath('../../../Assets/Images/img2.png'),
    alt: 'mountain'
  },
  {
    full: caseAssetPath('../../../Assets/Images/img2.png'),
    thumb: caseAssetPath('../../../Assets/Images/img2.png'),
    alt: 'sea'
  },
  {
    full: caseAssetPath('../../../Assets/Images/img2.png'),
    thumb: caseAssetPath('../../../Assets/Images/img2.png'),
    alt: 'surf'
  }
];

const cesdkStyle = {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0
};

const cesdkWrapperStyle = {
  position: 'relative',
  minHeight: '640px',
  overflow: 'hidden',
  flexGrow: 1,
  display: 'flex',
  borderRadius: '0.75rem',
  boxShadow:
    '0px 0px 2px rgba(22, 22, 23, 0.25), 0px 4px 6px -2px rgba(22, 22, 23, 0.12), 0px 2px 2.5px -2px rgba(22, 22, 23, 0.12), 0px 1px 1.75px -2px rgba(22, 22, 23, 0.12)'
};

const selectImageWrapper = {
  width: '150px',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem'
};
const imageSelectionWrapper = {
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem'
};
const imageStyle = {
  width: '100%',
  borderRadius: '6px',
  objectFit: 'cover',
  cursor: 'pointer'
};
const imageButtonStyle = {
  height: '100%',
  boxShadow:
    '0px 4px 6px -2px rgba(22, 22, 23, 0.12), 0px 2px 2.5px -2px rgba(22, 22, 23, 0.12), 0px 1px 1.75px -2px rgba(22, 22, 23, 0.12)',
  filter: 'drop-shadow(0px 0px 2px rgba(22, 22, 23, 0.25))'
};
const imageActiveState = {
  outline: '2px solid #471aff'
};

export default CreativeEditorSDKComponent;