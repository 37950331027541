import React from 'react';
import { Button, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

function ProgressBox({ storeData, handleCancelBulkUploadMedia, handleRetryFailMedia, handleErrorDoneButton }) {
    return (
        <>
            {storeData?.isbulkUploading && <div className='progress_box'>
                <LinearProgress className='linear_progress_bar' />
                <Typography className='upload_progress'>Upload in progress. Please wait.</Typography>
                <Button variant="text" className='report_library_cancel_button' onClick={handleCancelBulkUploadMedia}>CANCEL</Button>
            </div>}
            {storeData?.bulkUploadMediaLength > 0 && storeData?.successfulBulkUploadMediaLength > 0 && storeData?.bulkUploadMediaLength == storeData?.successfulBulkUploadMediaLength && !storeData?.isbulkUploading && <div className='progress_box'>
                <Typography className='check_circle_icon'><CheckCircleOutlineIcon /></Typography>
                <Typography className='upload_progress' >Upload complete.</Typography>
                <Typography className='upload_progress' >{storeData?.bulkUploadMediaLength} of {storeData?.bulkUploadMediaLength} media have been added to library.</Typography>
            </div>}
            {storeData?.failBulkUploadMediaLength > 0 && !storeData?.isbulkUploading && <div className='progress_box'>
                <Typography className='error_icon'><ErrorOutlineIcon className='error_icon' /></Typography>
                <Typography className='upload_progress'>Upload failed.</Typography>
                <Typography className='upload_progress'>{storeData?.failBulkUploadMediaLength} file(s) were not uploaded. Make sure the image or media file is</Typography>
                <Typography className='upload_progress'> the right format (PNG, JPG, JPEG, MP4, MOV) and try again.</Typography>
                {storeData?.failedBulkUploadMedia?.length > 0 &&
                    <Button variant="contained" className='report_library_retry_button' onClick={() => handleRetryFailMedia()}>RETRY ALL FAILED IMAGES</Button>}
                <Button variant="outlined" className='report_library_error_modal_done_button' onClick={handleErrorDoneButton}>DONE</Button>
            </div>}
        </>
    );
}

export default ProgressBox;
