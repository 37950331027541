import { FormControl, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import {useState,useEffect } from 'react';
import AddLineDescriptionModal from './AddLineDescriptionModal';
import CreateNewDescriptionModal from './CreateNewDescriptionModal';
import { GetUTCTime } from '../../Helpers/GetUTCTime';

const InspectionDetailsLineDescription = ({line,templateId,categoryId,categoryData,line_index,lineUuid}) => {
    // states
    const [descriptionArray,setDescriptionArray] = useState([]);
    const [descriptionArrayCopy,setDescriptionArrayCopy] = useState([]);
    const [selectedDescription,setSelectedDescription] = useState([])
    const [showAddDescriptionModal,setShowAddDescriptionModal] = useState(false);
    const [showTemperatureModal,setShowTemperatureModal] = useState(false);
    const [showTextBoxModal,setShowTextBoxModal] = useState(false);
    const [is_saved,setISSaved] = useState(false);
    const [descriptionPresent, setDescriptionPresent] = useState(false);
    const [showCreateDescriptionModal,setShowCreateDescriptionModal] = useState(false);
    const [newEmptyDescription,setNewEmptyDescription] = useState(false);
    const [refresh,setRefresh] = useState(false)

    const setTemperatureData = (data) => {
      if(data?.value === null || data?.value === undefined || data?.value === '') return [];
      else if(data?.type) return [`${data?.value} ${data?.type}`] 
      else return data;
    }

    const setTextBoxData = (data) => {
      if(data === null || data === undefined || data === '') return [];
      else return [`${data}`];
    }

    const getLineDesValue=(line)=>{
        const selected = line?.line_input_controls?.filter(lineData=>lineData.type !== "Temperature")[0]?.data
        const selected_temp = line?.line_input_controls?.filter(lineData=>lineData.type === "Temperature")[0]?.data
        const selected_text_val = line?.line_input_controls?.filter(lineData=>lineData.type === "TextBox")[0]?.data
        
        if(line?.type === "TextBox") {
          setSelectedDescription(setTextBoxData(selected_text_val));
          if(selected_text_val !== undefined && selected_text_val !== null ) setDescriptionPresent(true)
          else setDescriptionPresent(false)
          setRefresh(prev=>!prev)
        }
        else if(line?.type === "Temperature") {
          setSelectedDescription(setTemperatureData(selected_temp));
          if(selected_temp!== undefined && selected_temp!== null) setDescriptionPresent(true);
          else setDescriptionPresent(false)
          setRefresh(prev=>!prev)
        }
        else {
          setSelectedDescription(selected === undefined ? [] : selected);  
          if(selected?.length !== 0 && selected !== undefined) setDescriptionPresent(true)
          else setDescriptionPresent(false);
          setRefresh(prev=>!prev)
        }
      }
    const handleEditDescription=(line)=>{
        setShowAddDescriptionModal(true);
        let temp_values = [];
        let descriptionValues = line?.line_input_controls?.find(data=>(
            data.type !== "Temperature" && data.type !== "TextBox"
        ))?.values
        setDescriptionArray(descriptionValues !== undefined?descriptionValues:[])
        setDescriptionArrayCopy(descriptionValues !== undefined?descriptionValues:[])
        setShowTemperatureModal(line?.type === "Temperature" ? true : false);
        setShowTextBoxModal(line?.type === "TextBox" ? true : false);
    }
    const handleDescriptionSelection=async(description)=>{
      let tempData;
      if(selectedDescription.length === 0){
         tempData = descriptionArray;
      }else{
        tempData = JSON.parse(JSON.stringify(descriptionArray));
      }
      let slctdDesc = selectedDescription ? [...selectedDescription] : [];

    if(slctdDesc.length === 0){
      setSelectedDescription(description?.type ? setTemperatureData(description) : [description]);
    } 
    else if(description?.type) setSelectedDescription(setTemperatureData(description)) 
    else {
      let sltdDesc = slctdDesc;
      let sltd_desc = [];

      let desIndex = sltdDesc.findIndex(item=> item === description)
      if(desIndex !== -1){
        sltd_desc =  sltdDesc.filter(prevDescription=>prevDescription !== description)
      } else if(line?.type === "TextBox") sltd_desc = [description];
      else sltd_desc = [...sltdDesc , description]

      setSelectedDescription(sltd_desc)
    }   
          let idx = tempData.findIndex(itm=> itm.value === description)
          if(idx !== -1){
            tempData[idx].is_selected = tempData[idx].is_selected === true || tempData[idx].is_selected === 'true'? false:true;
            tempData[idx].updated_at = await GetUTCTime();
            setDescriptionArray(tempData) 
            setRefresh(prev=>!prev)
          }
          else{
            if(tempData.findIndex((x) => x !== description)) {
              tempData.push({
                "uuid": "",
                "value": description?.type ? setTemperatureData(description) : description,
                "is_deleted": 0,
                "updated_at": "",
                "is_selected": true
              });
              setDescriptionArray(tempData) 
              setRefresh(prev=>!prev)
            }
          }
    }
    useEffect(() => {
        setISSaved(false);
        getLineDesValue(line)
    }, [is_saved,categoryData])
    
  return (
    <>
    <AddLineDescriptionModal 
      showAddDescriptionModal={showAddDescriptionModal} 
      setShowAddDescriptionModal={setShowAddDescriptionModal} 
      setShowCreateDescriptionModal={setShowCreateDescriptionModal} 
      descriptionArray={descriptionArray} 
      setDescriptionArray={setDescriptionArray}
      selectedDescription={selectedDescription}
      handleDescriptionSelection={handleDescriptionSelection} 
      showTemperatureModal={showTemperatureModal} 
      setShowTemperatureModal={setShowTemperatureModal}
      showTextBoxModal={showTextBoxModal} 
      setShowTextBoxModal={setShowTextBoxModal}
      is_saved={is_saved}
      setISSaved={setISSaved}
      templateId={templateId} 
      categoryId={categoryId}
      lineUuid={lineUuid}
      line={line}
      descriptionPresent={descriptionPresent}
      addInputChanged={false}
      addInputPresent={false}
      isInspDetails={true}
      newEmptyDescription={newEmptyDescription}
      setNewEmptyDescription={setNewEmptyDescription}
      showCreateDescriptionModal={showCreateDescriptionModal}
    /> 
    <CreateNewDescriptionModal showCreateDescriptionModal={showCreateDescriptionModal} descriptionArrayCopy={descriptionArrayCopy} selectedDescription={selectedDescription} isInspDetails={true} setShowCreateDescriptionModal={setShowCreateDescriptionModal} showAddDescriptionModal={showAddDescriptionModal} setShowAddDescriptionModal={setShowAddDescriptionModal} descriptionArray={descriptionArray} setDescriptionArray={setDescriptionArray} handleDescriptionSelection={handleDescriptionSelection} showTemperatureModal={showTemperatureModal} setShowTemperatureModal={setShowTemperatureModal} newEmptyDescription={newEmptyDescription} setNewEmptyDescription={setNewEmptyDescription}
     templateId={templateId} categoryId={categoryId} lineUuid={lineUuid}/>
    <div className='linecontent_body'>
    <FormControl sx={{ m: 1,width:'100%',margin:'0'}} variant="outlined">
        <OutlinedInput            
            id={`edit_description_${line_index}`}
            className='location-input'
            size="small"
            type="text"
            fullWidth
            autoComplete='off'
            onClick={()=>handleEditDescription(line)}
            sx={{"& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "black",
                },
              }}
            value={selectedDescription?.join(", ")}
            endAdornment={
                <InputAdornment position="end">
                <IconButton   
                    id={`edit_description_icon_${line_index}`} 
                    disableRipple               
                    aria-label="edit decription"
                    onClick={()=>handleEditDescription(line)}
                    edge="end"
                >
                    {<EditIcon/>} 
                    {/* <span className='edit-text'>Edit</span> */}
                </IconButton>
                </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
            placeholder="Select Description"
        />
    </FormControl>
  </div>
  </>
  )
}

export default InspectionDetailsLineDescription