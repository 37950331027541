import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import "../../../Assets/Styles/Containers/_button.scss";

const LoadButton = ({ width, text, onClick, disabled,loading }) => {
  return (
    <>
    <LoadingButton 
    size="small" variant="contained"
    loading={loading}
    id={`${text}_btn`}
    name="add_new_company"
    disabled={disabled} onClick={onClick} className="loader_btn" style={{ width }}
                >
          {text}      
    </LoadingButton>
    </>
  );
};

export default LoadButton;
