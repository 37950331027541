import React, { useState } from 'react'
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { Button, Grid, LinearProgress, Typography,Box } from '@mui/material';
import ImageCarousel from '../../Components/LineDetails/ImageCarousel';
import noImage from "../../../Assets/Images/no-photo.png"
import "./report_library.scss";
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ProgressBox from './ProgressBox';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { RiDeleteBin6Line } from "react-icons/ri";
import DeleteMediaModal from './DeleteMediaModal';

function ReportLibraryVideo({ storeData, mediaList, handleCancelBulkUploadMedia, handleErrorDoneButton, handleRetryFailMedia,setHoveredDeletedMediaUuid, hoveredDeletedMediaUuid, handleClose, openDeleteMediaModal, setOpenDeleteMediaModal, setDeleteMediaName, deleteMediaName }) {
    const [mediaPlayer, setMediaPlayer] = useState(false);
    const [link, setLink] = useState("")
    const [videoId, SetVideoId] = useState("")
    const [mediaName, setMediaName] = useState("")
    const [mediaLineName, setMediaLineName] = useState("")
    return (
        <>
        <Box  sx={{
          height: `${
            storeData?.isbulkUploading
              ? "calc(100vh - 288px)"
              : storeData?.failBulkUploadMediaLength > 0 &&
                !storeData?.isbulkUploading
              ? "calc(100vh - 374px)"
              : storeData?.bulkUploadMediaLength > 0 &&
                storeData?.successfulBulkUploadMediaLength > 0 &&
                storeData?.bulkUploadMediaLength ===
                  storeData?.successfulBulkUploadMediaLength &&
                !storeData?.isbulkUploading
              ? "calc(100vh - 262px)"
              : "calc(100vh - 141px)"
          }`,
          overflow: "scroll",
        }}>
           <Grid container sx={{ display: "flex", padding: "6px 0px 0px 14px"}}>
                {mediaList?.length != 0 ?
                    mediaList?.map((media, index) => {
                        let thumbnailUrl = `${storeData.inspectionData?.data?.bucket_url}${(media?.thumbnail == null || media?.thumbnail_url == null) ? (media?.onlineUrl || media?.online_url) : (media?.thumbnail || media?.thumbnail_url)}`
                        let onlineUrl = `${storeData.inspectionData?.data?.bucket_url}${media?.onlineUrl || media?.online_url}`
                        return ( media?.online === "offline" ? "" :
                            <>
                                {(media?.onlineUrl == null && media?.online_url == null) && (media?.type === "video/mov" || media?.type === "video/mp4" || media?.type === "video/quicktime") ? <div style={{ position: 'relative' }}>
                                    <img src={noImage} className='report_media'
                                        style={{ cursor: 'pointer', objectFit: 'scale-down', backgroundColor: '#FAFAFA' }} />
                                    <CircularProgress className='bulk_upload_circular_bar' />
                                </div> :
                                    (media?.type?.startsWith("image/") || media?.media_type?.startsWith("image/")) ?
                                        (
                                            ""
                                        ) :  (media?.type?.startsWith("video/") || media?.media_type?.startsWith("video/")) ? (
                                            <Grid item xs={12} sm={2.4} sx={{position: "relative"}}>
                                                <div className="report_media_thumb_wrapper_review report_video_thumb_container" key={index}  onMouseEnter={() => {
                                                setHoveredDeletedMediaUuid(media?.uuid || media?.id);
                                              }}
                                              onMouseLeave={() => {
                                                setHoveredDeletedMediaUuid("");
                                              }}>
                                                    <div style={{ position: 'relative' }}>
                                                        <img
                                                            className='report_media'
                                                            style={{ cursor: 'pointer', backgroundColor: '#FAFAFA' }}
                                                            src={thumbnailUrl}
                                                            alt="video"
                                                            onClick={() => {
                                                                setMediaPlayer(true);
                                                                setLink(onlineUrl);
                                                            }}
                                                        />
                                                        <PlayCircleIcon
                                                            className="report_media_play_icon"
                                                            onClick={() => {
                                                                setMediaPlayer(true);
                                                                setLink(onlineUrl);
                                                            }}
                                                        />
                                                    </div>
                                                    {/* {hoveredDeletedMediaUuid == media?.uuid && <RiDeleteBin6Line className='report_media_delete_icon' onClick={()=>{setOpenDeleteMediaModal(true);setDeleteMediaName(media?.name)}}/>} */}
                                                </div>
                                            </Grid>
                                        ):<div style={{ position: 'relative' }}>
                                        <img src={noImage} className='report_media'
                                        style={{ cursor: 'pointer', objectFit: 'scale-down', backgroundColor: '#FAFAFA' }} />
                                   <WarningAmberIcon className='bulk_upload_circular_bar' sx={{ color: "#E02020", width: "19.17px", height: "16.67px" }} />
                                </div>}
                                {mediaPlayer && <ImageCarousel videolink={link} videoEditor={mediaPlayer} setVideoEditor={setMediaPlayer} videoId={videoId} mediaName={mediaName} mediaLineName={mediaLineName} />}

                            </>);
                    }) : <div className='no_media_found'>
                        <DoDisturbAltIcon sx={{ color: "#979797" }} />
                        <Typography sx={{ marginLeft: "-80px" }}>No images or videos are in the</Typography>
                        <Typography sx={{ marginLeft: "-26px" }}>report library.</Typography>
                    </div>
                }
            </Grid>
            </Box>
            <ProgressBox
                storeData={storeData}
                handleCancelBulkUploadMedia={handleCancelBulkUploadMedia}
                handleRetryFailMedia={handleRetryFailMedia}
                handleErrorDoneButton={handleErrorDoneButton}
            />
            <DeleteMediaModal
            open={openDeleteMediaModal}
            handleClose={handleClose}
            deleteMediaName={deleteMediaName}
            cancelBtnAction={handleClose}
            submitBtnAction={handleClose}
            />
        </>
    )
}

export default ReportLibraryVideo