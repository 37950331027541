import React, { useRef } from "react";
import ExpandingCheckbox from "./PdfElements/ExpandingCheckbox";
import CheckBoxElement from "./PdfElements/CheckBoxElement";
import DigitalSignature from "./PdfElements/DigitalSignature";
import MediaUploadElement from "./PdfElements/MediaUploadElement";
// import Divider from './PdfElements/Divider'
import TableElement from "./PdfElements/TableElement";
import DateElement from "./PdfElements/DateElement";
import NumberElement from "./PdfElements/NumberElement";
import PlanTextElement from "./PdfElements/PlanTextElement";
import YearElement from "./PdfElements/YearElement";
import { Divider, Grid } from "@mui/material";
import LayoutGroupElement from "./PdfElements/LayoutGroupElement";
import RadioElement from "./PdfElements/RadioElement";
import InformationalTextHTMLElement from "./PdfElements/InformationalTextHTMLElement";
import TextElement from "./PdfElements/TextElement";
import MultiLinesInputElement from "./PdfElements/MultiLinesInputElement";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { motion } from "framer-motion";
import { clearUpdatedInspectionData } from "../../Redux/Actions/CommonActions";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

function PdfCategories({
  pdfCategoryData,
  templateId,
  templateGuid,
  templateData,
  onDragtoggle, setOnDragToggle, selectedMediaFiles, setSelectedMediaFiles, selectedImages, setSelectedImages

}) {
  const location = useLocation();
  const elementRef = useRef();
  let elementUuid = location?.state?.element_uuid;
  const target = location.state.target
  const pdfCategoriesType = location?.state?.pdfcategoryType;
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  let level = 0

  const scrollToElement = () => {
    const Element = document.getElementById(elementUuid);
    if (Element) {
      Element.scrollIntoView({ behavior: "smooth", block: "start" });
      elementUuid = null
    }
  };

  useEffect(() => {
    if (target == "pdf" && elementUuid) {
      const timeout = setTimeout(() => {
        scrollToElement();
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, []);

  return (
    <motion.div
      // className="h_100 w_bg"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <div
        id="editor"
        style={{
          width: `${width}vw`,
          height: `${height}vh`,
          position: "fixed",
          left: "0",
          right: "0",
          top: "0",
          bottom: "0",
          zIndex: "2",
        }}
      ></div>
      <Grid container className={"container"} sx={{ paddingBottom: "34px" }}>
        <Grid
          item
          xs={12}
          sx={{ lineHeight: "22px" }}
        >
          <span
            style={{ fontSize: "16px" }}
          >
            {pdfCategoryData?.information_text !== null &&
              pdfCategoryData?.information_text !== ""
              ? pdfCategoryData?.information_text
              : " "}
          </span>
        </Grid>
      </Grid>
      <Divider style={{ width: "100%" }} sx={{ borderBottomWidth: 8 }} />
      {pdfCategoryData?.elements?.map((element, index) => (
        <div ref={elementRef} key={element?.uuid} id={element?.uuid}>
          {element?.type === "Layout/ExpandingCheckBox" && (
            <>
              <ExpandingCheckbox
                element={element}
                elementType="outer"
                templateId={templateId}
                width={setWidth}
                setWidth={setWidth}
                onDragtoggle={onDragtoggle}
                setOnDragToggle={setOnDragToggle}
                selectedMediaFiles={selectedMediaFiles}
                setSelectedMediaFiles={setSelectedMediaFiles}
                selectedImages={selectedImages}
                setSelectedImages={setSelectedImages}
                templateGuid={templateGuid}
                templateData={templateData}
                height={height}
                setHeight={setHeight}
                pdfCategoriesType={pdfCategoriesType}
                pdfCategoryData={pdfCategoryData}
                level={level + 1}
                orderForMedia={element.order === undefined ? '' : element.order}
              />
              <Divider
                style={{ width: "100%" }}
                sx={{ borderBottomWidth: 8 }}
              />
            </>
          )}
          {element?.type === "Layout/Group" && (
            <>
              <LayoutGroupElement
                element={element}
                elementType="outer"
                templateId={templateId}
                templateGuid={templateGuid}
                templateData={templateData}
                onDragtoggle={onDragtoggle}
                setOnDragToggle={setOnDragToggle}
                selectedMediaFiles={selectedMediaFiles}
                setSelectedMediaFiles={setSelectedMediaFiles}
                selectedImages={selectedImages}
                setSelectedImages={setSelectedImages}
                width={setWidth}
                setWidth={setWidth}
                height={height}
                setHeight={setHeight}
                pdfCategoriesType={pdfCategoriesType}
                pdfCategoryData={pdfCategoryData}
                level={level + 1}
                orderForMedia={element.order === undefined ? '' : element.order}
              />
              <Divider
                style={{ width: "100%" }}
                sx={{ borderBottomWidth: 8 }}
              />
            </>
          )}
          {element?.type === "Input/Number" && (
            <>
              <NumberElement
                element={element}
                elementType="outer"
                templateId={templateId}
                pdfCategoriesType={pdfCategoriesType}
                pdfCategoryData={pdfCategoryData}
                level={level + 1}
              />
              <Divider
                style={{ width: "100%" }}
                sx={{ borderBottomWidth: 8 }}
              />
            </>
          )}
          {element?.type === "Input/Date" && (
            <>
              <DateElement
                element={element}
                elementType="outer"
                templateId={templateId}
                pdfCategoriesType={pdfCategoriesType}
                pdfCategoryData={pdfCategoryData}
                level={level + 1}
              />
              <Divider
                style={{ width: "100%" }}
                sx={{ borderBottomWidth: 8 }}
              />
            </>
          )}
          {element?.type === "Input/Year" && (
            <>
              <YearElement
                element={element}
                elementType="outer"
                templateId={templateId}
                pdfCategoriesType={pdfCategoriesType}
                pdfCategoryData={pdfCategoryData}
                level={level + 1}
              />
              <Divider
                style={{ width: "100%" }}
                sx={{ borderBottomWidth: 8 }}
              />
            </>
          )}
          {element?.type === "InformationText/Plain" && (
            <>
              <PlanTextElement element={element} elementType="outer" />
              <Divider
                style={{ width: "100%" }}
                sx={{ borderBottomWidth: 8 }}
              />
            </>
          )}
          {element?.type === "Layout/Table" && (
            <>
              <TableElement
                element={element}
                elementType="outer"
                templateId={templateId}
                pdfCategoryData={pdfCategoryData}
              />
              <Divider
                style={{ width: "100%" }}
                sx={{ borderBottomWidth: 8 }}
              />
            </>
          )}
          {element?.type === "MediaUploader" && (
            <>
              <MediaUploadElement
                element={element}
                action="pdf"
                elementType="outer"
                templateId={templateId}
                onDragtoggle={onDragtoggle}
                setOnDragToggle={setOnDragToggle}
                selectedMediaFiles={selectedMediaFiles}
                setSelectedMediaFiles={setSelectedMediaFiles}
                selectedImages={selectedImages}
                setSelectedImages={setSelectedImages}
                width={setWidth}
                setWidth={setWidth}
                height={height}
                setHeight={setHeight}
                pdfCategoriesType={pdfCategoriesType}
                pdfCategoryData={pdfCategoryData}
                orderForMedia={element.order === undefined ? '' : element.order}
              />
              <Divider
                style={{ width: "100%" }}
                sx={{ borderBottomWidth: 8 }}
              />
            </>
          )}
          {(element?.type === "Signature/Any" ||
            element?.type === "Signature/Inspector") && (
              <>
                <DigitalSignature
                  element={element}
                  templateId={templateId}
                  templateGuid={templateGuid}
                  templateData={templateData}
                  pdfCategoriesType={pdfCategoriesType}
                  pdfCategoryData={pdfCategoryData}
                />
                <Divider
                  style={{ width: "100%" }}
                  sx={{ borderBottomWidth: 8 }}
                />
              </>
            )}
          {element?.type === "CheckBox" && (
            <>
              <CheckBoxElement
                element={element}
                elementType="outer"
                templateId={templateId}
                pdfCategoriesType={pdfCategoriesType}
                pdfCategoryData={pdfCategoryData}
                level={level + 1}
              />
              <Divider
                style={{ width: "100%" }}
                sx={{ borderBottomWidth: 8 }}
              />
            </>
          )}
          {element?.type === "Radio" && (
            <>
              <RadioElement
                element={element}
                elementType="outer"
                templateId={templateId}
                pdfCategoryData={pdfCategoryData}
                pdfCategoriesType={pdfCategoriesType}
              />
              <Divider
                style={{ width: "100%" }}
                sx={{ borderBottomWidth: 8 }}
              />
            </>
          )}
          {element?.type === "InformationText/Html" && (
            <>
              <InformationalTextHTMLElement element={element} />
              <Divider
                style={{ width: "100%" }}
                sx={{ borderBottomWidth: 8 }}
              />
            </>
          )}
          {element?.type === "Input/Text" && (
            <>
              <TextElement
                element={element}
                templateId={templateId}
                // elementType="outer"
                pdfCategoriesType={pdfCategoriesType}
                level={level + 1}
              />
              <Divider
                style={{ width: "100%" }}
                sx={{ borderBottomWidth: 8 }}
              />
            </>
          )}
          {element?.type === "Input/Multiline" && (
            <>
              <MultiLinesInputElement
                element={element}
                templateId={templateId}
                pdfCategoriesType={pdfCategoriesType}
                level={level + 1}
              />
              <Divider
                style={{ width: "100%" }}
                sx={{ borderBottomWidth: 8 }}
              />
            </>
          )}
        </div>
      ))}
    </motion.div>
  );
}

export default PdfCategories;
